import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DashboardContent, PageHeader } from '../tailwind';
import { getProgramData } from '../../actions/program';
import { getTargetInterpretationsForProgram } from '../../actions/targetInterpretation';

class ProgramDetailPage extends React.Component {
    componentDidMount = () => {
        const { authUser } = this.props;
        // fetch the user based on the state!
        this.props.getProgramData(this.props.location.state.programId, authUser.user_id);
        this.props.getTargetInterpretationsForProgram(this.props.location.state.programId);
    }

    handleClickTarget = (targetData) => {
        const programId = this.props.location.state.programId
        let targetInterpretationData = null;
        Object.keys(this.props.targetInterpretationProgramData).forEach(target => {
            const interpretations = this.props.targetInterpretationProgramData[target];
            if (interpretations.target_id === targetData.target_id){
                targetInterpretationData = interpretations;
            }
        });

        this.props.history.push({
            pathname: `/lessons/targets/${targetData.target_id}`,
            state: { 
                targetData,
                programId,
                targetInterpretationData
            }
        });
    }

    handleCreateTarget = (e) => {
        this.props.history.push({
            pathname: '/lessons/targets/create',
            state: { 
                targetInterpretationData: this.props.targetInterpretationData,
                programId: this.props.location.state.programId
            }
        });
    }

    renderRows = () => {
        const { programData } = this.props;
        return programData 
            ? programData.map(record => {
                // get the date...
                // const dateCreated = new Date(record.date_timestamp);
                return (
                    <tr key={record.program_target_ref_id}>
                        <td className="px-6 py-4 flex-wrap border-b border-gray-200">
                            <div className="flex flex-wrap break-normal items-center">
                                <div className="ml-4">
                                    <div onClick={() => this.handleClickTarget(record)} className="text-base leading-5 font-medium text-gray-600 cursor-pointer">
                                        <div className="text-lg font-bold mb-2">{record.display_name}</div>
                                        <div className="flex-wrap text-sm  break-normal">{record.text_definition}</div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            {record.status === 2 ? (
                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Active
                                </span> 
                            ):(
                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                    Inactive
                                </span>
                            )}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                            <button 
                                onClick={() => this.handleTargetClick(record)} 
                                type="button" 
                                className="text-white inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-white bg-gray-400 hover:text-white focus:outline-none focus:shadow-outline-red focus:border-gray-300 active:text-white active:bg-gray transition duration-150 ease-in-out"
                            >
                                View Details
                            </button>
                        </td>
                    </tr>
            );
        }) : null;
    }

    render() {
        return(
            <DashboardContent backTitle="Back to Lessons">
                <PageHeader 
                    title={'Lesson Details'}
                    backTitle="Back to Lessons"
                    onClick={() => this.handleCreateTarget()}
                    buttonTitle="Create Target"
                />
               {/* begin table */}
               <div className="flex flex-col mt-4">
                <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                        <thead>
                        <tr>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Target
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Status
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                        </tr>
                        </thead>
                        <tbody className="bg-white">
                            {this.renderRows()}
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>
                {/* end table */}
            </DashboardContent>
        );
    }
}

const mapStateToProps = ({ program, auth, targetInterpretation }) => {
    const { programData } = program;
    const { authUser } = auth;
    const { 
        targetInterpretations,
        targetInterpretationProgramData,
        getTargetInterpretationProgramLoading,
        getTargetInterpretationProgramError
    } = targetInterpretation;
    return {
        programData,
        authUser,
        targetInterpretations,
        targetInterpretationProgramData,
        getTargetInterpretationProgramLoading,
        getTargetInterpretationProgramError
    };
}

const mapDispatchToProps = {
    getProgramData,
    getTargetInterpretationsForProgram
}
const programDetailPageWithRouter = withRouter(ProgramDetailPage);
export default connect(mapStateToProps, mapDispatchToProps)(programDetailPageWithRouter);