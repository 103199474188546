import * as actionCreators from './actions';
import * as services from './services';

export const getWebsiteForLicensee = (licenseeId) => dispatch => {
    console.log('GETTING WEBSITE');
    dispatch(actionCreators.getWebsiteForLicensee(licenseeId));
    return services.getWebsiteForLicensee(licenseeId)
        .then(response => dispatch(actionCreators.getWebsiteForLicenseeSuccess(response.data))).catch(e => dispatch(actionCreators.createWebsiteForLicenseeFail(e)));
}

export const createWebsiteForLicensee = (subdomain, licenseeId, displayName, history) => dispatch => {
    dispatch(actionCreators.createWebsiteForLicensee(subdomain, licenseeId, displayName));
    return services.createWebsiteForLicensee(subdomain, licenseeId, displayName)
        .then(response => {
            dispatch(actionCreators.createWebsiteForLicenseeSuccess(response.data));
            if (history) {
                history.go(0);
            }
        }).catch(e => dispatch(actionCreators.createWebsiteForLicenseeFail(e)));
}

export const updateWebsiteForLicensee = (title, theme_color, logo_url, job_title, display_name, twitter_url, linkedin_url, profile_image_url, bio, licenseeId) => dispatch => {
    dispatch(actionCreators.updateWebsiteForLicensee(title, theme_color, logo_url, job_title, display_name, twitter_url, linkedin_url, profile_image_url, bio, licenseeId));
    return services.updateWebsiteForLicensee(title, theme_color, logo_url, job_title, display_name, twitter_url, linkedin_url, profile_image_url, bio, licenseeId)
        .then(response => dispatch(actionCreators.updateWebsiteForLicenseeSuccess(response.data)))
        .catch(e => dispatch(actionCreators.updateWebsiteForLicenseeFail(e)));
}

export const resetStripeConnectedAccount = () => dispatch => {
    dispatch(actionCreators.resetStripeConnectedAccount());
}

export const getStripeConnectedAccount = (licenseeId, accountId, sandbox) => dispatch => {
    dispatch(actionCreators.getStripeConnectedAccount(licenseeId, accountId, sandbox));
    return services.getStripeConnectedAccount(licenseeId, accountId, sandbox)
        .then(response => {
            if (response.success === true) {
                dispatch(actionCreators.getStripeConnectedAccountSuccess(response));
            } else {
                dispatch(actionCreators.getStripeConnectedAccountFail(response));
            }  
        }).catch(e => dispatch(actionCreators.getStripeConnectedAccountFail(e)));
}

export const createStripeConnectedAccount = (licenseeId, email, sandbox) => dispatch => {
    dispatch(actionCreators.createStripeConnectedAccount(licenseeId, email, sandbox));
    return services.createStripeConnectedAccount(licenseeId, email, sandbox)
        .then(response => dispatch(actionCreators.createStripeConnectedAccountSuccess(response)))
        .catch(e => dispatch(actionCreators.createStripeConnectedAccountFail(e)));
}

export const linkStripeConnectedAccount = (licenseeId, accountId, sandbox) => dispatch => {
    dispatch(actionCreators.linkStripeConnectedAccount(licenseeId, accountId, sandbox));
    return services.linkStripeConnectedAccount(licenseeId, accountId, sandbox)
        .then(response => {
            dispatch(actionCreators.linkStripeConnectedAccountSuccess(response));
            if (response) {
                window.open(response.data.url);
            }
        })
        .catch(e => dispatch(actionCreators.linkStripeConnectedAccountFail(e)));
}

