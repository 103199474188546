import * as actionTypes from './actionTypes';

/**
 * registerLicensee
 * @param {string} email the email 
 * @param {string} password the password
 */
export const registerLicensee = (email, password) => ({
    type: actionTypes.REGISTER_LICENSEE,
    payload: { email, password }
});

export const registerLicenseeSuccess = (data) => ({
    type: actionTypes.REGISTER_LICENSEE_SUCCESS,
    payload: data
});

export const registerLicenseeFail = (error) => ({
    type: actionTypes.REGISTER_LICENSEE_FAIL,
    payload: error
});

/**
 * registerAndActivateLicensee
 */
export const registerAndActivateLicensee = (firstName, lastName, email, password, nonce) => ({
    type: actionTypes.REGISTER_AND_ACTIVATE_LICENSEE,
    payload: { firstName, lastName, email, password, nonce }
});

export const registerAndActivateLicenseeSuccess = (data) => ({
    type: actionTypes.REGISTER_AND_ACTIVATE_LICENSEE_SUCCESS,
    payload: data
});

export const registerAndActivateLicenseeFail = (error) => ({
    type: actionTypes.REGISTER_AND_ACTIVATE_LICENSEE_FAIL,
    payload: error
});

export const registerAndActivateLicenseeReset = () => ({
    type: actionTypes.REGISTER_AND_ACTIVATE_LICENSEE_RESET,
    payload: null
});

/**
 * activateLicensee
 */
export const activateLicensee = (nonce) => ({
    type: actionTypes.ACTIVATE_LICENSEE,
    payload: nonce
})
export const activateLicenseeSuccess = (data) => ({
    type: actionTypes.ACTIVATE_LICENSEE_SUCCESS,
    payload: data
});

export const activateLicenseeFail = (error) => ({
    type: actionTypes.ACTIVATE_LICENSEE_FAIL,
    payload: error
});

/**
 * loginUser
 * @param {string} email 
 * @param {string} password 
 */
export const loginUser = (email, password) => ({
    type: actionTypes.LOGIN_USER,
    payload: { email, password }
});

export const loginUserSuccess = (data) => ({
    type: actionTypes.LOGIN_USER_SUCCESS,
    payload: data
});

export const loginUserFail = (error) => ({
    type: actionTypes.LOGIN_USER_FAIL,
    payload: error
});

export const loginUserReset = () => ({
    type: actionTypes.LOGIN_USER_RESET,
    payload: null
});

/**
 * logout
 */
export const logoutUser = () => ({
    type: actionTypes.LOGOUT_USER,
    payload: null
})

export const resetRegisterLicensee = () => ({
    type: actionTypes.REGISTER_LICENSEE_RESET,
    payload: null
})

/**
 * forgotPassword
 */
export const forgotPassword = (email) => ({
    type: actionTypes.FORGOT_PASSWORD,
    payload: { email }
});

export const forgotPasswordSuccess = (data) => ({
    type: actionTypes.FORGOT_PASSWORD_SUCCESS,
    payload: data
});

export const forgotPasswordFail = (error) => ({
    type: actionTypes.FORGOT_PASSWORD_FAIL,
    payload: error
});

export const forgotPasswordReset = () => ({
    type: actionTypes.FORGOT_PASSWORD_RESET,
});

/**
 * resetPassword
 */
export const resetPassword = (password, confirmPassword, nonce) => ({
    type: actionTypes.RESET_PASSWORD,
    payload: { password, confirmPassword, nonce }
});

export const resetPasswordSuccess = (data) => ({
    type: actionTypes.RESET_PASSWORD_SUCCESS,
    payload: data
});

export const resetPasswordFail = (error) => ({
    type: actionTypes.RESET_PASSWORD_FAIL,
    payload: error
});

export const resetPasswordReset = () => ({
    type: actionTypes.RESET_PASSWORD_RESET,
    payload: null
});

// when we update the user's subscription
// we should update the user data
export const updateSubscriptionPlan = (userData) => ({
    type: actionTypes.USER_SUBSCRIPTION_UPDATE,
    payload: userData
});