import React from 'react';
import { DashboardContent, PageHeader } from '../tailwind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import { faKey } from '@fortawesome/free-solid-svg-icons';
class WebhookPage extends React.Component {
    render() {
        return(
            <DashboardContent>
                <PageHeader title="Webhook" />
                <div className="bg-white shadow overflow-hidden  sm:rounded-lg">
                    <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Webhook
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                        The webhook is the server endpoint where we will send the analysis results.
                        </p>
                    </div>
                    <div className="px-4 py-5 sm:p-0">
                        <dl>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                            <dt className="text-sm leading-5 font-medium text-gray-500">
                                URL
                                <div className="mt-2 text-xs leading-5 font-medium text-gray-400">
                                    Enter the URL where you will receive the post data.
                                </div>
                            </dt>
                            <dd className="mt-1 leading-5 text-gray-900 sm:mt-0 sm:col-span-2 font-bold">
                                <div className="mt-1 flex flex-grow rounded-md shadow-sm">
                                    <div className="relative flex-grow focus-within:z-10">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <FontAwesomeIcon icon={faKey} className="text-gray-300 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                                        </div>
                                        <input id="api-key" value="https://" className="form-input pl-10 block w-full rounded-none rounded-l-md transition ease-in-out duration-150 sm:text-sm sm:leading-5 text-cool-gray-500" placeholder="https://" />
                                    </div>
                                    <button onClick={this.handleCopyToClipboard} className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                                        <FontAwesomeIcon icon={faClipboard} className="text-gray-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                                    </button>
                                </div>
                            </dd>
                        </div>
                        </dl>
                    </div>
                </div>
            </DashboardContent>
        );
    }
}

export default WebhookPage;