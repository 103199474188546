import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DashboardContent, Container } from '../tailwind';
import { getProgramsForLicenseeCampaign } from '../../actions/program';
import { getLicenseeDataByUserId } from '../../actions/licensee';
import { 
    getLicenseeCampaignsByLicenseeId, 
    updateLicenseeCampaignStatus,
    resetUpdateLicenseeCampaignStatus
} from '../../actions/licenseeCampaign';
import { getTargetInterpretations, getTargetInterpretationsForProgram } from '../../actions/targetInterpretation';
import { removeTargetFromProgram } from '../../actions/program';
import ApiSample from '../common/ApiSample';
import Webhook from '../common/Webhook';
import Loading from '../common/Loading';
import DragDropFile from '../common/DragDropFile';
import { selectors as authSelectors } from '../../reducers/auth';
import { Button, LearnMore } from '../common';
import CameraAngleNav from '../common/CameraAngleNav';
import ModalConfirmAction from '../common/modal/ModalConfirmAction';
import LessonTargetCreateModal from './lesson/LessonTargetCreateModal';
import LessonDuplicateModal from './lesson/LessonDuplicateModal';
import LessonInfoPanel from './lesson/LessonInfoPanel';
import { getOutputStatus, getLiveStatus } from '../../util/swingbot';
import ModalInviteLessonCredit from './modals/ModalInviteLessonCredit';
class LessonDetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 1,
            selectedMainTab: 0,
            filter: '',
            showArchiveModal: false,
            showActivateModal: false,
            showCreateModal: false,
            showInviteModal: false,
            showDuplicateModal: false
        }
    }

    componentDidCatch = (e) => {
        this.props.history.push({
            pathname: '/500',
            state: e
        });
    }

    componentDidMount = () => {
        const { authUser, licenseeData } = this.props;
        const licenseeCampaignId = parseInt(this.props.match.params.lessonId);
        if (licenseeCampaignId !== null) {
            // fetch the programs for this campaign
            this.props.getProgramsForLicenseeCampaign(licenseeCampaignId, authUser.licensee_id);

            // now trying to get the campaigns by licensee id...
            // this is basically a refresh in case we changed anything!
            this.props.getLicenseeCampaignsByLicenseeId(authUser.licensee_id);

            if (!licenseeData) {
                this.props.getLicenseeDataByUserId(authUser.user_id);
            }
        } else {
            this.props.history.push({
                pathname: '/404'
            });
        }
    }

    componentDidUpdate = (nextProps) => {
        if (nextProps.match.params.lessonId !== this.props.match.params.lessonId) {
            // refresh the page...
            this.props.history.go(0);
        }
    }

    handleChangeCameraAngle = (e) => {
        const newAngle = this.state.selectedTab === 1 ? 2 : 1;
        this.setState({
            selectedTab: newAngle
        });
    }

    handleClickTarget = (program, targetData) => {
        const licenseeCampaignId = parseInt(this.props.match.params.lessonId);
        const licenseeCampaignData = this.getLicenseeCampaignData(licenseeCampaignId);
        const programId = targetData.program_id;
        const { planInformation } = this.props;
        this.props.history.push({
            pathname: `/lessons/targets/${targetData.target_id}`,
            state: {
                status: licenseeCampaignData.licensee_campaign_status,
                targetId: targetData.target_id,
                targetData,
                programId,
                readOnly: licenseeCampaignData.licensee_campaign_status !== 'INACTIVE',
                isEditable: planInformation.planFeatureName === 'Elite',
                program
            }
        });
    }

    /**
     * handleCreateTarget
     * Show the modal if the users allowed targets exceeds the current targets
     */
    handleCreateTarget = () => {
        const { programsForCampaign, planInformation, history } = this.props;
        
        let programSelected = null;
        programsForCampaign.forEach(program => {
            if (program.swing_type_id === this.state.selectedTab) {
                programSelected = program;
            }
        });

        if (planInformation.targetsAllowed > programSelected.targets.length) {
            this.setState({
                showCreateModal: true,
            });
        } else {
            history.push({
                pathname: '/cart',
            });
        }
    }

    handleCreateTargetFromLibrary = () => {
        // navigate to the library page with the program id?
        // we have to determine the program based on the selected Tab..
        const { programsForCampaign, planInformation} = this.props;
        let programId = null;
        let programSelected = null;
        programsForCampaign.forEach(program => {
            if (program.swing_type_id === this.state.selectedTab) {
                programId = program.program_id;
                programSelected = program;
            }
        });
        if (planInformation.targetsAllowed > programSelected.targets.length) {
            this.props.history.push({
                pathname: '/lessons/targets/library',
                state: { 
                    targetInterpretationData: this.props.targetInterpretationData,
                    programId,
                    program: programSelected,
                    programsForCampaign,
                    isEditable: false,
                    swingTypeId: this.state.selectedTab
                }
            });
        } else {
            alert('You may only create ' + planInformation.targetsAllowed + ' targets under this plan.');
        }
    }

    handleClickProgram = (programId, cameraAngle) => {
        // const licenseeCampaignData = this.props.location.state.data;
        const licenseeCampaignId = parseInt(this.props.match.params.lessonId);
        const licenseeCampaignData = this.getLicenseeCampaignData(licenseeCampaignId);
        const cameraAngleString = cameraAngle === 1 ? 'Face On' : 'Down the Line';
        this.props.history.push({
            pathname: `/lessons/${licenseeCampaignId}/${programId}`,
            state: { 
                programId,
                licenseeCampaignData,
                cameraAngle: cameraAngleString
            }
        });
    }

    handleActivate = () => {}

    handleUploadError = (e) => {
        // console.log(e);
    }

    handleUploadSuccess = (data) => {
        // console.log(data);
    }

    handleRemoveProgram = (program, target) => {
        const { authUser, history} = this.props;
        const licenseeCampaignId = parseInt(this.props.match.params.lessonId);
        const licenseeCampaignData = this.getLicenseeCampaignData(licenseeCampaignId);
        const userId = authUser.user_id;
        if (target && program && userId && licenseeCampaignData.licensee_campaign_status === 'INACTIVE') {
            const historyPage = 0;
            this.props.removeTargetFromProgram(
                target.target_id, 
                program.program_id, 
                userId,
                history, // we do not want to go BACK
                historyPage
            );
        }
    }

    handleDuplicateProgram = () => {
        const { planInformation, licenseeCampaigns } = this.props;
        if (planInformation !== null && licenseeCampaigns !== null) {
            if (planInformation.lessonsAllowed > licenseeCampaigns.length && planInformation.lessonsAllowed !== undefined) {
                this.setState({ showDuplicateModal: true });
            } else {
                this.handleUpdatePlan()
            }
        }
    }

    handleUpdatePlan = () => {
        this.props.history.push({
            pathname: '/cart'
        });
    }

    renderPrograms = () => {
        try {
            const { programsForCampaign } = this.props;
            if (programsForCampaign) {
                const programToRender = programsForCampaign.filter(program => program.swing_type_id === this.state.selectedTab)[0] 
                return programToRender !== undefined ? (
                    programToRender.targets.length > 0 ? this.renderProgramGrid(programToRender) : this.renderNoTargets()): null;
            } else {
                return <Loading />;
            }
        } catch(e) {
            // console.log(e);
            // this.props.history.push({
            //     pathname: '/500',
            //     state: e
            // });
        }
    }

    targetsForProgram(program) {
        return program.targets.filter(target => this.state.selectedTab === target.swing_type_id);
    }

    renderNoTargets = () => {
        const { programsForCampaign } = this.props;
        return (
            <Container className="bg-gray-800">
                <div className="flex flex-col w-full">
                    <div className="flex flex-col w-full text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                        <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10 text-white">
                        Create a Lesson Target
                        <br />
                        <span className="text-gray-300 text-xl font-normal">A Lesson Target is the item that will be analyzed in your program. You may have multiple Lesson Targets.</span>
                        </h2>
                        <div className="mt-8 flex justify-center">
                        {programsForCampaign !== null && (
                            <div className="inline-flex rounded-md shadow space-x-2">
                            <Button 
                                title="Click to Create Lesson Target" 
                                onClick={this.handleCreateTarget} 
                                bgColor="green"
                                hoverColor="green"
                                size="lg"
                            />
                            <Button 
                                title="Click to Learn More" 
                                onClick={() => this.props.history.push('/doc/lesson-target')} 
                                bgColor="indigo"
                                hoverColor="indigo"
                                size="lg"
                            />
                        </div>
                        )}
                        </div>
                    </div>
                    </div>
            </Container>
        );
    }

    isInProgram = (targetDisplayName, targets) => {
        let isInProgram = false;
        targets.forEach(target => {
            if (target.display_name === targetDisplayName) {
                isInProgram = true;
            }
        });
        return isInProgram;
    }


    getProgramId = () => {
        try {
            const { programsForCampaign } = this.props;
            if (programsForCampaign) {
                const program = programsForCampaign
                    .filter(program => program.swing_type_id === this.state.selectedTab);
                if (program.length > 0) {
                    console.log(program);
                    return program[0].program_id;
                }
            }
            return null;
        } catch(e) {
            console.log(e.message);
            return null;
        }
        // return programsForCampaign && this.state.selectedTab
        //     ? programsForCampaign.filter(program => program.swing_type_id === this.state.selectedTab)[0].program_id
        //     : null;
    }

    renderProgramGrid = (program) => {
        return (
            <div key={`render-programs-${program.name}`} className="cursor-pointer">
                <div className="flex w-full">
                    <div className="grid grid-flow-row grid-cols-1 auto-cols-max w-full col-gap-3 row-gap-3">
                        {this.renderTargets(program, program.targets)}
                    </div>
                </div>
            </div>
        )
    }

    renderTargets = (program, targets) => {
        const sorted = targets.sort((a, b) => {
            if (a.lexicon_name > b.lexicon_name) {
                return 1;
            } 
            if (a.lexicon_name < b.lexicon_name) {
                return -1;
            } 
            return 0;
        }).filter(record => {
            return this.state.filter !== null
                ? record.display_name.toLowerCase().indexOf(this.state.filter) > -1
                : true;
        });
        
        const targetsToDisplay = sorted
            .filter(target => this.state.selectedTab === target.swing_type_id)
            .map((target, index) => {
            return (
                <div key={`${target.lexicon_name}-${index}`} className="flex flex-1 flex-wrap break-normal bg-white shadow border border-gray-100 rounded p-0">
                        <div className="flex flex-1 flex-col justify-between">
                            <div 
                                onClick={() => this.handleClickTarget(program, target)}
                                className="text-base leading-5 font-medium text-gray-800 cursor-pointer p-8"
                            >
                                <div className="flex flex-row justify-between">
                                    <div className="">
                                        <div className="text-2xl font-bold mb-2 leading-7">{target.display_name}</div>
                                        {/* <div className="text-xs mb-4 text-gray-600"><span className="px-2 py-1 rounded-lg bg-indigo-500 text-white font-bold">{(target.swing_type_id === 1 ? 'Face On' : 'Down the Line')}</span></div> */}
                                    </div>
                                </div>
                            <div className="flex-wrap text-base break-normal font-normal text-gray-700 leading-6">{target.text_definition}</div>
                        </div>
                    </div>
                </div>
            );
        });

        return targetsToDisplay.length > 0 ? targetsToDisplay : (this.state.filter !== '' ? [] : this.renderNoTargets());
    }

    renderMainTabs = () => {
        const licenseeCampaignId = parseInt(this.props.match.params.lessonId);
        const licenseeCampaignData = this.getLicenseeCampaignData(licenseeCampaignId);
        const { selectedMainTab } = this.state;
        const classTab1 = selectedMainTab === 1
            ? 'cursor-pointer whitespace-no-wrap py-4 px-1 border-b-2 border-green-500 font-medium text-md leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 font-medium text-gray-900'
            : 'cursor-pointer whitespace-no-wrap py-4 px-1 border-b-2 border-transparent font-medium text-md leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 font-medium'
        const classTab2 = selectedMainTab === 2
            ? 'cursor-pointer whitespace-no-wrap ml-8 py-4 px-1 border-b-2 border-green-500 font-medium text-md leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 font-medium text-gray-900'
            : 'cursor-pointer whitespace-no-wrap ml-8 py-4 px-1 border-b-2 border-transparent font-medium text-md leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 font-medium'
        const classTab3 = selectedMainTab === 3
            ? 'cursor-pointer whitespace-no-wrap ml-8 py-4 px-1 border-b-2 border-green-500 font-medium text-md leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 font-medium text-gray-900'
            : 'cursor-pointer whitespace-no-wrap ml-8 py-4 px-1 border-b-2 border-transparent font-medium text-md leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 font-medium'
        const classTab4 = selectedMainTab === 4
            ? 'cursor-pointer whitespace-no-wrap ml-8 py-4 px-1 border-b-2 border-green-500 font-medium text-md leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 font-medium text-gray-900'
            : 'cursor-pointer whitespace-no-wrap ml-8 py-4 px-1 border-b-2 border-transparent font-medium text-md leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 font-medium'
        return (
            <div className="mb-2">
                <div className="sm:hidden">
                    <select 
                        onChange={this.handleChangeCameraAngle} 
                        aria-label="Selected tab" 
                        value={selectedMainTab}
                        className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150"
                    >
                        <option value={1}>Lesson Targets</option>
                        {(licenseeCampaignData.licensee_campaign_status === 'INACTIVE' || licenseeCampaignData.licensee_campaign_status === 'ACTIVE') && (<option value={2}>Uploading Swings</option>)}
                        {(licenseeCampaignData.licensee_campaign_status === 'INACTIVE' || licenseeCampaignData.licensee_campaign_status === 'ACTIVE') && (<option value={3}>Receiving Results</option>)}
                        {(licenseeCampaignData.licensee_campaign_status === 'INACTIVE' || licenseeCampaignData.licensee_campaign_status === 'ACTIVE') && (<option value={4}>Upload File</option>)}
                    </select>
                </div>
                <div className="hidden sm:block mb-4">
                    <div className="border-b border-gray-200">
                    <nav className="-mb-px flex flex-col">
                        <div onClick={() => this.setState({ selectedMainTab: 1 })} className={classTab1}>
                        Lesson Targets
                        </div>
                        {(licenseeCampaignData.licensee_campaign_status === 'INACTIVE' || licenseeCampaignData.licensee_campaign_status === 'ACTIVE') &&  (
                            <div onClick={() => this.setState({ selectedMainTab: 2 })} className={classTab2}>
                                Uploading Video
                            </div>
                        )}
                        {(licenseeCampaignData.licensee_campaign_status === 'INACTIVE' || licenseeCampaignData.licensee_campaign_status === 'ACTIVE') && (
                            <div onClick={() => this.setState({ selectedMainTab: 3 })} className={classTab3}>
                                Receiving Results
                            </div>
                        )}
                        {(licenseeCampaignData.licensee_campaign_status === 'INACTIVE' || licenseeCampaignData.licensee_campaign_status === 'ACTIVE') && (
                            <div onClick={() => this.setState({ selectedMainTab: 4 })} className={classTab4}>
                                Upload Sample
                            </div>
                        )}
                    </nav>
                    </div>
                </div>
            </div>
        );
    }

    renderMainTabsSide = () => {
        const licenseeCampaignId = parseInt(this.props.match.params.lessonId);
        const licenseeCampaignData = this.getLicenseeCampaignData(licenseeCampaignId);
        const { selectedMainTab } = this.state;
        const currentClass = "font-bold bg-gray-100 text-gray-700 flex items-center px-3 py-2 text-sm font-medium rounded-md";
        const defaultClass = "cursor-pointer text-gray-600 hover:bg-gray-50 hover:text-gray-900 flex items-center px-3 py-2 text-sm font-medium rounded-md";

        return (

            <div className="bg-white text-sm rounded-lg shadow p-4">
                <div className="sm:hidden">
                    <select 
                        onChange={this.handleChangeCameraAngle} 
                        aria-label="Selected tab" 
                        value={selectedMainTab}
                        className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150"
                    >
                        <option value={0}>Lesson Info</option>
                        <option value={1}>Lesson Targets</option>
                        {(licenseeCampaignData.licensee_campaign_status === 'INACTIVE' || licenseeCampaignData.licensee_campaign_status === 'ACTIVE') && (<option value={2}>Uploading Swings</option>)}
                        {(licenseeCampaignData.licensee_campaign_status === 'INACTIVE' || licenseeCampaignData.licensee_campaign_status === 'ACTIVE') && (<option value={3}>Receiving Results</option>)}
                        {(licenseeCampaignData.licensee_campaign_status === 'INACTIVE' || licenseeCampaignData.licensee_campaign_status === 'ACTIVE') && (<option value={4}>Upload File</option>)}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <div className="flex flex-col space-y-2">
                        <nav className="-mb-px flex flex-col">
                            <div className={selectedMainTab === 0 ? currentClass : defaultClass } onClick={() => this.setState({ selectedMainTab: 0 })}>
                                Lesson Info
                            </div>

                            <div className={selectedMainTab === 1 ? currentClass : defaultClass } onClick={() => this.setState({ selectedMainTab: 1 })}>
                                Lesson Targets
                            </div>
                            
                            {(licenseeCampaignData.licensee_campaign_status === 'INACTIVE' || licenseeCampaignData.licensee_campaign_status === 'ACTIVE') &&  (
                                <div className={selectedMainTab === 2 ? currentClass : defaultClass } onClick={() => this.setState({ selectedMainTab: 2 })}>
                                    Uploading Video
                                </div>
                            )}
                            {(licenseeCampaignData.licensee_campaign_status === 'INACTIVE' || licenseeCampaignData.licensee_campaign_status === 'ACTIVE') && (
                                <div className={selectedMainTab === 3 ? currentClass : defaultClass } onClick={() => this.setState({ selectedMainTab: 3 })}>
                                    Receiving Results
                                </div>
                            )}
                            {(licenseeCampaignData.licensee_campaign_status === 'INACTIVE' || licenseeCampaignData.licensee_campaign_status === 'ACTIVE') && (
                                <div className={selectedMainTab === 4 ? currentClass : defaultClass } onClick={() => this.setState({ selectedMainTab: 4 })}>
                                    Upload Sample
                                </div>
                            )}
                        </nav>
                        <div className="border-t border-gray-200 mt-4 pt-4 space-y-2">
                            <Button 
                                title="Invite User"
                                onClick={() => this.setState({ showInviteModal: true })}
                                bgColor="green"
                                size="sm"
                                block
                                disabled={licenseeCampaignData.licensee_campaign_status !== 'ACTIVE'}
                            />
                            <Button 
                                title="Duplicate Program"
                                onClick={this.handleDuplicateProgram}
                                bgColor="blue"
                                size="sm"
                                block
                            />
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    }


    handleChangeFilter = (e) => {
        this.setState({
            filter: e.target.value
        });
    }

    getLicenseeCampaignData = (licenseeCampaignId) => {
        const { licenseeCampaigns } = this.props;
        return licenseeCampaignId !== null && licenseeCampaigns !== null && licenseeCampaigns !== undefined 
            ? licenseeCampaigns.filter(rec => parseInt(rec.licensee_campaign_id) === parseInt(licenseeCampaignId))[0]
            : null;
    }

    confirmClickArchiveLessonProgram = () => {
        this.props.resetUpdateLicenseeCampaignStatus();
        this.setState({
            showArchiveModal: true,
        });
    }

    confirmClickActivateLessonProgram = () => {
        this.props.resetUpdateLicenseeCampaignStatus();
        this.setState({
            showActivateModal: true,
        })
    }   

    handleClickArchiveLessonProgram = () => {
        const { authUser, history } = this.props;
        const licenseeCampaignId = parseInt(this.props.match.params.lessonId);
        const licenseeId = authUser.licensee_id;
        const status = 'ARCHIVED';
        this.props.updateLicenseeCampaignStatus(licenseeCampaignId, licenseeId, status, history);
    }

    handleClickActivateLessonProgram = () => {
        const { authUser, history } = this.props;
        const licenseeCampaignId = parseInt(this.props.match.params.lessonId);
        const licenseeId = authUser.licensee_id;
        const status = 'ACTIVE';
        this.props.updateLicenseeCampaignStatus(licenseeCampaignId, licenseeId, status, history);
    }   

    getButtonTitle = (licenseeCampaignData) => {
        if (licenseeCampaignData) {
            const status = licenseeCampaignData.licensee_campaign_status.toLowerCase();
            if (status === 'active') {
                return 'Archive Lesson Program';
            }
            if (status === 'inactive') {
                return 'Activate Lesson Program';
            }
            if (status === 'archived') {
                return null;
            }
            return null;
        } 
    }
    
    renderWarningContainerResults = (licenseeCampaignData) => {
        return licenseeCampaignData && licenseeCampaignData.post_endpoint === '' && licenseeCampaignData.licensee_campaign_status.toLowerCase() !== 'archived' ? (
            <div className="rounded-md bg-blue-50 p-4 mb-4">
            <div className="flex">
                <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                </svg>
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between cursor:pointer" onClick={() => this.setState({ selectedMainTab: 3 })}>
                    <p className="flex-1 text-sm text-blue-700">
                        Please specify an endpoint for us to send you analysis results for this program.
                    </p>
                    <p className="mt-3 text-sm md:mt-0 md:ml-6 cursor-pointer">
                        <span className="whitespace-nowrap font-medium text-white rounded-lg bg-blue-600 p-2 px-4">Click to Edit</span>
                    </p>
                </div>
            </div>
            </div>
        ) : null;
    }

    renderTargetsForSwingType = (programsForCampaign, targetsAllowed, swingTypeId) => {
        if (programsForCampaign && targetsAllowed) {
            const targetCountUsed = programsForCampaign
                .filter(program => program.swing_type_id === swingTypeId)
                .filter(program => {
                    return this.state.filter !== '' ? program.display_name.indexOf(this.state.filter) > -1 : true
                });
            const cameraAngle = this.state.selectedTab === 1 ? 'Face On':'Down the Line';
            return targetCountUsed !== undefined && targetCountUsed.length > 0 ? (
                <div className="rounded-md bg-green-50 p-4 mb-2">
                    <div className="flex">
                        <div className="flex-shrink-0">
                        <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                        </svg>
                        </div>
                        <div className="ml-3 flex-1 md:flex md:justify-between cursor:pointer">
                            <p className="flex-1 text-sm text-green-700">
                                You have used <span className="font-bold">{targetCountUsed[0].targets.length}</span> out of <span className="font-bold">{targetsAllowed}</span> Lesson Targets for the {cameraAngle} Camera Angle .
                            </p>
                            {/* <p className="mt-3 text-sm md:mt-0 md:ml-6 cursor-pointer">
                                <span className="whitespace-nowrap font-medium text-white rounded-lg bg-blue-600 p-2 px-4">Click to Edit</span>
                            </p> */}
                        </div>
                    </div>
            </div>
            ) : null;
        } 
        return null;
    }

    render() {
        const licenseeCampaignId = parseInt(this.props.match.params.lessonId);
        const licenseeCampaignData = this.getLicenseeCampaignData(licenseeCampaignId);
        console.log('licensee campaign data ', licenseeCampaignData);
        const { 
            authUser, 
            licenseeData, 
            programsForCampaign,
            updateLicenseeCampaignStatusSuccess,
            updateLicenseeCampaignStatusLoading,
            updateLicenseeCampaignStatusError,
            planInformation
        } = this.props;

        let buttonColor;
        let buttonAction;
        let buttonTitle;
        let programId;
        const { selectedMainTab } = this.state;

        if (licenseeCampaignData) {
            buttonTitle = this.getButtonTitle(licenseeCampaignData);
            buttonColor = licenseeCampaignData !== null ? (
                licenseeCampaignData.licensee_campaign_status.toLowerCase() === 'inactive' 
                    ? 'green' 
                    : 'red'
                ) : null;
            buttonAction = licenseeCampaignData !== null ? (
                licenseeCampaignData.licensee_campaign_status.toLowerCase() === 'inactive' 
                    ? this.confirmClickActivateLessonProgram 
                    : this.confirmClickArchiveLessonProgram
                ) : null;
            programId = this.getProgramId();
        }

        return licenseeCampaignData !== null && licenseeCampaignData !== undefined && licenseeData ? (
            <DashboardContent 
                title={`${licenseeCampaignData.display_name}`}
                subtitle={`${getOutputStatus(licenseeCampaignData.licensee_campaign_status)} and ${getLiveStatus(licenseeCampaignData.licensee_campaign_type)}`}
                backTitle="Back to Lessons"
                buttonTitle={buttonTitle}
                buttonClassColor={buttonColor}
                onClick={buttonAction}
                onClickBackButton={() => {
                    this.props.history.push({
                        pathname: '/lessons',
                    });
                }}
            >
                <div className="flex flex-col w-full">
                    {this.renderWarningContainerResults(licenseeCampaignData)}
                </div>

                <div className="flex flex-col w-full">

                <div className="flex flex-col lg:flex-row w-full space-x-0 lg:space-x-4 xl:space-x-4">
                    <div className="w-full xl:w-1/4 lg:w-1/4 space-y-4 text-sm text-gray-600 mb-4">
                        {this.renderMainTabsSide()}
                    </div>

                {selectedMainTab === 0 && (
                    <LessonInfoPanel 
                        title={licenseeCampaignData.display_name}
                        description={licenseeCampaignData.description}
                        status={licenseeCampaignData.licensee_campaign_status}
                        price={licenseeCampaignData.licensee_campaign_price}
                        licenseeCampaignId={licenseeCampaignData.licensee_campaign_id}
                        campaignType={licenseeCampaignData.licensee_campaign_type}
                        lessonOutput={licenseeCampaignData.lesson_output}
                        endpoint={licenseeCampaignData.post_endpoint}
                    />
                )}

                {selectedMainTab === 1 && (
                    <div className="flex flex-col w-full">
                        <Container>
                            <h3 className="text-lg leading-6 font-bold text-gray-800">
                            Lesson Targets<LearnMore docPage="/doc/lesson-target" className="px-2" />
                            </h3>
                            <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                            The following Lesson Targets will be analyzed.
                            </p>
                        </Container>
                        {programsForCampaign && (
                            <div className="bg-white px-4 mb-2 rounded shadow">
                                <CameraAngleNav
                                    status={licenseeCampaignData.licensee_campaign_status}
                                    filter={this.state.filter}
                                    onChangeFilter={this.handleChangeFilter}
                                    onChangeCameraAngle={this.handleChangeCameraAngle}
                                    onCreateNewTarget={this.handleCreateTarget}
                                    cameraAngle={this.state.selectedTab}
                                    showSwingFilters={true}
                                />
                            </div>
                        )}
                        <div className="">
                            {planInformation && this.renderTargetsForSwingType(programsForCampaign, planInformation.targetsAllowed, this.state.selectedTab)}
                        </div> 
                        <div>
                            {this.renderPrograms()}
                        </div>
                    </div>
                )}

                {selectedMainTab === 2 && (licenseeCampaignData.licensee_campaign_status === 'INACTIVE' || licenseeCampaignData.licensee_campaign_status === 'ACTIVE' ) && (
                    <Container>
                        <ApiSample 
                            campaignId={licenseeCampaignData.licensee_campaign_id} 
                            email={authUser.email} 
                            apiKey={licenseeData.api_key} 
                            forTitle={licenseeCampaignData.display_name}
                        />
                    </Container>
                )}

                {selectedMainTab === 3 && (licenseeCampaignData.licensee_campaign_status === 'INACTIVE' || licenseeCampaignData.licensee_campaign_status === 'ACTIVE' ) && (
                    <Container>
                        <Webhook 
                            endpoint={licenseeCampaignData.post_endpoint}
                            licenseeCampaignId={licenseeCampaignData.licensee_campaign_id} 
                            forTitle={licenseeCampaignData.display_name}
                            licenseeCampaignData={licenseeCampaignData}
                        />
                    </Container>
                )}

                {selectedMainTab === 4 && (
                    <Container>
                        <div className="p-2 mb-2">
                            <h3 className="text-lg leading-6 font-bold text-gray-600">
                            Test your Lesson Program
                            </h3>
                            <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                            Drag and drop a swing video below to process your video.
                            </p>
                        </div>
                        <div className="">
                            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                <DragDropFile
                                    apiKey={licenseeData.api_key}
                                    email={authUser.email}
                                    licenseeCampaignId={licenseeCampaignData.licensee_campaign_id}
                                    onError={this.handleUploadError}
                                    onSuccess={this.handleUploadSuccess}
                                />
                            </dd>
                        </div>
                    </Container>
                )}
                </div>
                </div>
                <ModalConfirmAction 
                    show={this.state.showArchiveModal}
                    title="Confirm Archival"
                    body="Are you sure you wish to archive this Lesson Program?"
                    history={this.props.history}
                    onCancel={() => {
                        this.setState({ showArchiveModal: false });
                    }}
                    onConfirm={() => {
                        this.handleClickArchiveLessonProgram();
                    }}
                    isLoading={updateLicenseeCampaignStatusLoading}
                    isSuccess={updateLicenseeCampaignStatusSuccess}
                    isError={updateLicenseeCampaignStatusError}
                />
                <ModalConfirmAction 
                    show={this.state.showActivateModal} 
                    title="Confirm Activate"
                    buttonTitleConfirm="Yes, Activate Lesson Program"
                    buttonTitleCancel="Cancel"
                    body="Are you sure you wish to activate this Lesson Program?"
                    history={this.props.history}
                    onCancel={() => {
                        this.setState({ showActivateModal: false });
                    }}
                    onConfirm={() => {
                        this.handleClickActivateLessonProgram();
                    }}
                    isLoading={updateLicenseeCampaignStatusLoading}
                    isSuccess={updateLicenseeCampaignStatusSuccess}
                    isError={updateLicenseeCampaignStatusError}
                >
                    <ul>
                        <li>You will no longer be able to make any changes to this program.</li>
                        <li>Your Lesson Program will be available to your students.</li>
                    </ul>
                </ModalConfirmAction>
                {/* <ModalConfirmAction 
                    show={this.state.showDuplicateModal} 
                    title="Confirm Duplicate"
                    buttonTitleConfirm="Yes, Duplicate Lesson Program"
                    buttonTitleCancel="Cancel"
                    body="Are you sure you wish to make a copy of this Lesson Program?"
                    history={this.props.history}
                    onCancel={() => {
                        this.setState({ showActivateModal: false });
                    }}
                    onConfirm={() => {
                        this.handleClickActivateLessonProgram();
                    }}
                    isLoading={updateLicenseeCampaignStatusLoading}
                    isSuccess={updateLicenseeCampaignStatusSuccess}
                    isError={updateLicenseeCampaignStatusError}
                >
                    <ul>
                        <li>This will make a copy of the program so you may edit the program as you wish.</li>
                    </ul>
                </ModalConfirmAction> */}
                <LessonTargetCreateModal 
                    programId={programId}
                    cameraAngle={this.state.selectedTab}
                    show={this.state.showCreateModal} 
                    onClickCancel={() => this.setState({ showCreateModal: false })}
                />
                <LessonDuplicateModal 
                    licenseeCampaignId={licenseeCampaignData.licensee_campaign_id}
                    licenseeCampaignDisplayName={licenseeCampaignData.display_name}
                    show={this.state.showDuplicateModal} 
                    onClickCancel={() => this.setState({ showDuplicateModal: false })}
                    buttonConfirmColor="blue"
                />
                <ModalInviteLessonCredit 
                    show={this.state.showInviteModal}
                    licenseeCampaignId={licenseeCampaignData.licensee_campaign_id}
                    onClickCancel={() => this.setState({ showInviteModal: false })}
                    onClose={() => this.setState({ showInviteModal: false })}
                />
            </DashboardContent>
        ) : (
            <DashboardContent 
                title={``}
                subtitle={``}
                backTitle="Back to Lessons"
            >
                <div className="flex flex-col w-full">
                    <Loading />
                </div>
            </DashboardContent>
        );
    }
}

LessonDetailPage.defaultProps = {
    licenseeCampaigns: [],
}

const mapStateToProps = ({ program, auth, targetInterpretation, licensee, licenseeCampaign }) => {
    const { 
        programData,
        programsForCampaign,
        getProgramsForCampaignLoading,
        getProgramsForCampaignError,
    } = program;
    const { 
        licenseeData
    } = licensee;
    const { 
        licenseeCampaigns,
        loadingLicenseeCampaigns,
        loadingLicenseeCampaignsError,
        duplicateLicenseeCampaignSuccess,
        duplicateLicenseeCampaignLoading,
        duplicateLicenseeCampaignError,
        updateLicenseeCampaignStatusSuccess,
        updateLicenseeCampaignStatusLoading,
        updateLicenseeCampaignStatusError
    } = licenseeCampaign;
    const { 
        targetInterpretations,
        targetInterpretationProgramData,
        getTargetInterpretationProgramLoading,
        getTargetInterpretationProgramError
    } = targetInterpretation;
    const { authUser } = auth;
    return {
        planInformation: authSelectors.planInformation(auth),
        programData,
        programsForCampaign,
        getProgramsForCampaignLoading,
        getProgramsForCampaignError,
        authUser,
        targetInterpretations,
        targetInterpretationProgramData,
        getTargetInterpretationProgramLoading,
        getTargetInterpretationProgramError,
        licenseeData,
        licenseeCampaigns,
        loadingLicenseeCampaigns,
        loadingLicenseeCampaignsError,
        duplicateLicenseeCampaignSuccess,
        duplicateLicenseeCampaignLoading,
        duplicateLicenseeCampaignError,
        updateLicenseeCampaignStatusSuccess,
        updateLicenseeCampaignStatusLoading,
        updateLicenseeCampaignStatusError
    };
}

const mapDispatchToProps = {
    getProgramsForLicenseeCampaign,
    getTargetInterpretations,
    getTargetInterpretationsForProgram,
    getLicenseeDataByUserId,
    getLicenseeCampaignsByLicenseeId,
    removeTargetFromProgram,
    updateLicenseeCampaignStatus,
    resetUpdateLicenseeCampaignStatus
}
const lessonDetailPageWithRouter = withRouter(LessonDetailPage);
export default connect(mapStateToProps, mapDispatchToProps)(lessonDetailPageWithRouter);