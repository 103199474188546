import React from 'react';
import { withRouter } from 'react-router-dom';
import Logout from '../auth/Logout';

class LogoutPage extends React.Component {
    handleLogoutSuccess = (res) => {
        this.props.history.push('/login');
    }

    handleLogoutError = (e) => {
    }

    render() {
        return(<Logout onLogoutSuccess={this.handleLogoutSuccess} onLogoutError={this.handleLogoutError} />);
    }
}

export default withRouter(LogoutPage);