import * as actionTypes from './types';

export const getPlans = () => ({
    type: actionTypes.GET_PLANS,
    payload: null
});

export const getPlansSuccess = (data) => ({
    type: actionTypes.GET_PLANS_SUCCESS,
    payload: data
});

export const getPlansFail = (error) => ({
    type: actionTypes.GET_PLANS_FAIL,
    payload: error
});
