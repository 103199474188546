import React from 'react';

class TargetLinePositionNavigationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPosition: this.props.originPosition
    }
  }
  
  handleClick = (position) => {
    this.setState({selectedPosition: position.name});
    this.props.onClick(position.name);
  }

  render () {
    const { positions, lines } = this.props;
    return lines && positions !== null ? (
      <div className="mb-2">
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {positions.map(position => {
            const isSelected = this.state.selectedPosition === position.name;
            const linesForPosition = lines[position.name] !== undefined ? lines[position.name].length : 0;
            const tabTheme = isSelected === true
              ? 'border-indigo-500 text-indigo-600 whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200 whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
              return (
                <div onClick={() => this.handleClick(position)} key={`${position.name}-key`} className={tabTheme}>
                  {position.display}
                  <span className="bg-gray-100 text-gray-900 hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block">{linesForPosition}</span>
                </div>
              );
          })}
        </nav>
      </div>
    </div>
    ) : null;
  }
}

TargetLinePositionNavigationBar.defaultProps = {
  positions: [],
  lines: [],
  originPosition: null,
  onClick(){} // change the position
}

export default TargetLinePositionNavigationBar;