/**
 * Login component
 */
import React from 'react';
import { connect } from 'react-redux';
import { acceptApplication } from '../../actions/user';
import { withRouter } from 'react-router-dom';

class ApplicationAccept extends React.Component {
    
    componentDidMount = () => {
        const { code } = this.props;
        if (code !== null) {
            // lets activate!
            this.props.acceptApplication(this.props.match.params.code, this.props.history);
        }
    }

    render() {
        const {
            acceptApplicationComplete,
            acceptApplicationLoading,
            acceptApplicationError,
            code,
        } = this.props;
        return (
            <div className="min-h-screen bg-gray-800 flex flex-col justify-center py-6 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <img className="mx-auto h-12 w-auto mb-2" src={require('../../img/logo.png')} alt="Swingbot SaaS" />

                    {acceptApplicationLoading === false && acceptApplicationComplete === null && acceptApplicationError === null && (
                        <div className="text-center">
                        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                            Please check your email.
                        </h2>
                        <p className="mb-4 text-sm text-gray-500">We have sent you an activation email. Please click the link in the email to complete your registration.</p>
                        <div className="mt-6">
                                <span className="block w-full rounded-md shadow-sm">
                                    <button onClick={() => this.props.history.push('/login')} type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out">
                                        Back to login
                                    </button>
                                </span>
                            </div>
                        </div>
                    )}

                    {acceptApplicationLoading === true && (
                        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                            Activating Your Account...
                        </h2>
                    )}

                    {acceptApplicationLoading === false && acceptApplicationLoading !== false && code !== null && acceptApplicationComplete === null && (
                        <div className="text-center">
                            <h2 className="mt-4 mb-2 text-center text-3xl leading-9 font-extrabold text-gray-900">
                                There was an error
                            </h2>
                            <p className="mb-4 text-sm text-gray-500">There was an error accepting this application.</p>
                        </div>
                    )}
                    {acceptApplicationComplete && acceptApplicationError === null && acceptApplicationLoading === false && (
                        <div className="text-center">
                            <h2 className="mt-6 mb-2 text-center text-3xl leading-9 font-extrabold text-gray-900">
                                Application Accepted
                            </h2>
                            <p className="mb-4 text-sm text-gray-500">The application has been accepted</p>
                            <div className="mt-6">
                                <span className="block w-full rounded-md shadow-sm">
                                    <button onClick={() => this.props.history.push('/login')} type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out">
                                        Click here to login
                                    </button>
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

ApplicationAccept.defaultProps = {
    code: null
}

const mapStatetoProps = ({ auth, user }) => {
    const { 
       acceptApplicationComplete,
       acceptApplicationLoading,
       acceptApplicationError
    } = user;
    return { 
       acceptApplicationComplete,
       acceptApplicationLoading,
       acceptApplicationError
    };
};

const mapDispatchToProps = {
    acceptApplication
}
const activateWithRouter = withRouter(ApplicationAccept);
export default connect(mapStatetoProps, mapDispatchToProps)(activateWithRouter);