import React from 'react';
import { 
    bodyPointOptions,
    positionOptions,
    lineTypeOptions,
    lineColorOptions
} from '../../DropDowns';

class TargetLineCreatePanel extends React.Component {

    componentDidMount = () => {
    }

    handleLineTypeChange = (e, number) => {
        this.props.onLineTypeChange(e, number);
    }

    handleLineColorChange = (e, number) => {
        this.props.onLineColorChange(e, number);
    }

    handlePosition1Change = (e, number) => {
        this.props.onPosition1Change(e, number);
    }

    handlePosition2Change = (e, number) => {
        this.props.onPosition2Change(e, number);
    }

    handleBodyPoint1Change = (e, number) => {
        this.props.onBodyPoint1Change(e, number);
    }

    handleBodyPoint2Change = (e, number) => {
        this.props.onBodyPoint2Change(e, number);
    }

    handleDeleteLine = (number) => {
        this.props.onDelete(number);
    }

    handleCreateLine = () => {
        this.props.onCreate();
    }

    renderLines = () => {
        const { lines, lineIndex, readOnly, originPosition } = this.props;
        try {
            const disabledClass = readOnly === true ? "bg-gray-100 cursor-not-allowed":"";             
            const line = lines[originPosition][lineIndex];
            if (line === null) {
                // lets make a line!
                
            }
            
            // set some variables that will propogate this form
            const lineType = line !== null ? line.type : 'vertical';
            const lineColor = line !== null ? line.color : null;
            const linePosition1Lexicon = line !== null ? line.p1_lexicon : null;
            const linePosition2Lexicon = line !== null ? line.p2_lexicon : null;
            const linePhaseOrigin = line !== null ? line.phase_origin : null;
            const linePhaseOrigin2 = line !== null ? line.phase_origin_2 : null;

            return (
                <div className="rounded grid grid-cols-1 w-full">
                    <div className="grid lg:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 gap-2">
                        <div className="col-span-1 max-w-lg rounded-md sm:max-w-full w-full mb-1">
                            <label htmlFor="country" className="block text-sm font-bold leading-5 text-white sm:mt-px sm:pt-2 mb-1">
                                Line Type
                            </label>
                            <div className="col-span-2 max-w-lg rounded-md sm:max-w-full w-full mb-1">
                                <select 
                                    onChange={(e) => this.handleLineTypeChange(e, lineIndex)}
                                    className={`block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${disabledClass}`}
                                    disabled={readOnly}
                                    value={lineType}
                                >
                                    {lineTypeOptions(lineType)}
                                </select>
                            </div>
                        </div>
                        <div className="col-span-1 max-w-lg rounded-md sm:max-w-full w-full mb-1">
                            <label htmlFor="country" className="block text-sm font-bold leading-5 text-white sm:mt-px sm:pt-2 mb-1">
                                Line Color
                            </label>
                            <div className="col-span-2 max-w-lg rounded-md sm:max-w-full w-full mb-1">
                                <select 
                                    value={lineColor}
                                    onChange={(e) => this.handleLineColorChange(e, lineIndex)}
                                    className={`block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${disabledClass}`}
                                    disabled={readOnly}
                                >
                                    {lineColorOptions(lineColor)}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="grid lg:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 gap-2">
                        <div className="col-span-1 max-w-lg rounded-md sm:max-w-full w-full mb-1">
                            <label htmlFor="country" className="block text-sm font-bold leading-5 text-white sm:mt-px sm:pt-2 mb-1">
                                Body Point #1
                            </label>
                            <select 
                                value={linePosition1Lexicon}
                                onChange={(e) => this.handleBodyPoint1Change(e, lineIndex)}
                                className={`form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${disabledClass}`}
                                disabled={readOnly}
                            >
                                <option value="">Select a Body Point</option>
                                {bodyPointOptions(linePosition1Lexicon)}
                            </select>
                        </div>
                        <div className="col-span-1 max-w-lg rounded-md sm:max-w-full w-full mb-1">
                        <label htmlFor="country" className="block text-sm font-bold leading-5 text-white sm:mt-px sm:pt-2 mb-1">
                            in Position
                        </label>
                            <select 
                                value={linePhaseOrigin}
                                disabled={readOnly}
                                onChange={(e) => this.handlePosition1Change(e, lineIndex)}
                                className={`form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${disabledClass}`}
                            >
                                <option value="">Select a Position</option>
                                {positionOptions(linePhaseOrigin)}
                            </select>
                        </div>
                    </div>
                    {lineType === 'p2p' && (
                        <div className="grid lg:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 gap-2">
                            <div className="col-span-1 max-w-lg rounded-md sm:max-w-full w-full mb-1">
                                <label htmlFor="country" className="block text-sm font-bold leading-5 text-white sm:mt-px sm:pt-2 mb-1">
                                    Body Point #2
                                </label>
                                <select 
                                    value={linePosition2Lexicon}
                                    onChange={(e) => this.handleBodyPoint2Change(e, lineIndex)}
                                    className={`block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${disabledClass}`}
                                    disabled={readOnly}
                                >
                                    <option value="">Select a Body Point</option>
                                    {bodyPointOptions(linePosition2Lexicon)}
                                </select>
                            </div>
                            <div className="col-span-1 max-w-lg rounded-md sm:max-w-full w-full mb-1">
                                <label htmlFor="country" className="block text-sm font-bold leading-5 text-white sm:mt-px sm:pt-2 mb-1">
                                    in Position
                                </label>
                                <select 
                                    value={linePhaseOrigin2}
                                    onChange={(e) => this.handlePosition2Change(e, lineIndex)}
                                    className={`block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${disabledClass}`}
                                    disabled={readOnly}
                                >
                                    <option value="">Select a Position</option>
                                    {positionOptions(linePhaseOrigin2)}
                                </select>
                            </div>
                        </div>
                    )}

                    <div className="grid lg:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 justify-end">
                        <div className="col-span-1 max-w-lg rounded-md sm:max-w-full w-full mb-1 justify-end flex py-4">
                            {readOnly === false && (
                                <button onClick={() => this.handleDeleteLine(lineIndex)} type="submit" className="flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out">
                                    Delete Line Annotation
                                </button>
                            )}
                        </div>
                    </div>

                </div>
            );
        } catch(e) {
            console.log(e.message);
        }
    }

    render() {
        const { analysisResults } = this.props;
        return analysisResults !== null ? (
            <div className="flex w-full border rounded-lg p-4 bg-gray-800">
                <div className="flex w-full px-4 flex-col">
                    <div className="grid grid-cols-1 gap-3 w-full">
                        {this.renderLines()}
                    </div>
                </div>
            </div>
        ) : (<div className="flex w-full"></div>);
    }
}

TargetLineCreatePanel.defaultProps = {
    readOnly: false,
    position: null,
    bodyPointsToRender: null,
    analysisResults: null,
    line: null,
    lineIndex: 0,
    onLineTypeChange(){},
    onLineColorChange(){},
    onPosition1Change(){},
    onPosition2Change(){},
    onBodyPoint1Change(){},
    onBodyPoint2Change(){},
    onDelete(){},
    onCreate(){}
}

export default TargetLineCreatePanel;