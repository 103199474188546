import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { updateWebhookForLicenseeCampaign } from '../../actions/settings';
import { sendToWebhook, sendToWebhookReset } from '../../actions/licenseeCampaign';
import { Button, Loading } from '../common';
class Webhook extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            useCustom: this.isCustom(props.endpoint),
            endpointValue: props.endpoint === '' ? process.env.REACT_APP_DEFAULT_WEBHOOK : props.endpoint,
            outputSelectedIndex: this.webhookSelected(props.licenseeCampaignData),
            dirtyEndpoint: props.endpoint === '' ? process.env.REACT_APP_DEFAULT_WEBHOOK : props.endpoint,
        }
    }

    isCustom = (endpoint) => {
        return endpoint !== process.env.REACT_APP_DEFAULT_WEBHOOK;
    }

    activeEndpoint = () => {
        const { dirtyEndpoint, useCustom } = this.state;
        return useCustom === true ? dirtyEndpoint : process.env.REACT_APP_DEFAULT_WEBHOOK;
    }

    componentDidMount = () => {
        this.props.sendToWebhookReset();
    }

    handleSave = (e) => {
        const { licenseeCampaignId } = this.props;
        console.log('ACTIVE ', this.activeEndpoint());
        const active = this.activeEndpoint();
        if (active !== null) {
            this.props.updateWebhookForLicenseeCampaign(licenseeCampaignId, active);
        }
    }

    handleOnChange = (e) => {
        this.setState({
            dirtyEndpoint: e.target.value
        });
        // reset testing
        this.props.sendToWebhookReset();
    }

    handleWebhookItem = (number) => {
        this.setState({
            outputSelectedIndex: number
        });
    }

    handleTestWebhook = () => {
        if (this.state.endpointValue !== '') {
            const data = { 'test': 'something'};
            this.props.sendToWebhook(data, this.state.endpointValue);
        }
    }

    handleChangeType = (e) => {
        console.log(e.target.name, e.target.value);
        this.setState({
            useCustom: e.target.value === 'custom' ? true : false,
        });
        this.props.sendToWebhookReset();
    }

    renderForTitle = () => {
        return this.props.forTitle !== ''
            ? ` for "${this.props.forTitle}"`
            : null;
    }

    webhookSelected = (licenseeCampaignData) => {
        if(licenseeCampaignData !== undefined && licenseeCampaignData !== null) {
            let outputSelectedIndex = 0;
            // 'BOTH','ONLINE','EMAIL','POINTS','POINTS_AND_POSITIONS','ANALYSIS','BRANDED_EMAIL','STITCH'
            switch(licenseeCampaignData.lesson_output) {
                case 'BOTH':
                    outputSelectedIndex = 1;
                break;

                case 'ONLINE':
                    outputSelectedIndex = 2;
                break;

                case 'EMAIL':
                    outputSelectedIndex = 3;
                break;

                case 'POINTS':
                    outputSelectedIndex = 4;
                break;

                case 'POINTS_AND_POSITIONS':
                    outputSelectedIndex = 5;
                break;

                case 'ANALYSIS':
                    outputSelectedIndex = 6;
                break;

                case 'BRANDED_EMAIL':
                    outputSelectedIndex = 7;
                break;

                case 'STITCH':
                    outputSelectedIndex = 8;
                break;

                default:
                    break;
            }
            return outputSelectedIndex;
        }
    }

    render() {
        const {
            sendToWebhookLoading,
            sendToWebhookSuccess,
            sendToWebhookError
        } = this.props;
        const { useCustom, dirtyEndpoint, endpointValue } = this.state;

        const selectedExistingClass = useCustom === false 
            ? "bg-green-50 border-green-200 z-10"
            : "bg-white border-gray-200";
        const selectedCustomClass = useCustom === true 
            ? "bg-green-50 border-green-200 z-10"
            : "bg-white border-gray-200";

        const selectedExistingLabel = useCustom === false
            ? "text-green-900 font-bold"
            : "text-gray-700";
        const selectedCustomLabel = useCustom === true
            ? "text-green-900 font-bold"
            : "text-gray-700";

        const selectedExistingDescription = useCustom === false 
            ? "text-green-700"
            : "text-gray-500";
        const selectedCustomDescription = useCustom === true 
            ? "text-green-700"
            : "text-gray-500";

        const canSave = (useCustom === true && (dirtyEndpoint !== endpointValue)) || (useCustom === false && (endpointValue !== process.env.REACT_APP_DEFAULT_WEBHOOK));
        return (
            <div className="text-sm p-2">

                <div className="mb-4">
                    <h3 className="text-lg text-gray-600 mb-2 font-bold">Receiving the Results{this.renderForTitle()}</h3>
                    <p className="text-gray-500">A <a className="text-green-500" href="https://en.wikipedia.org/wiki/Webhook" target="blank">webhook</a> can be specified in order to receive the resulting analysis data from your video uploads.</p>
                </div>
                
                <fieldset>
                    <legend className="sr-only">
                        Privacy setting
                    </legend>
                    <div className="bg-white rounded-md -space-y-px">

                        <label className={`${selectedExistingClass} rounded-tl-md rounded-tr-md relative border p-4 flex cursor-pointer focus:outline-none`}>
                            <input type="radio" name="webhook" value="existing" className="h-4 w-4 mt-0.5 cursor-pointer text-green-600 border-gray-300 focus:ring-green-500" aria-labelledby="webhook-1-label" aria-describedby="webhook-1-description" onChange={this.handleChangeType} checked={!useCustom} />
                            <div className="ml-3 flex flex-col">
                                <span id="webhook-1-label" className={`block text-sm font-medium ${selectedExistingLabel}`}>
                                Use Existing Swingbot Pro Webhook
                                </span>
                                <span id="webhook-1-description" className={`block text-sm ${selectedExistingDescription}`}>
                                SwingbotPro will send emails to golfers when their analysis is complete.
                                </span>
                            </div>
                        </label>

                        <label className={`${selectedCustomClass} relative rounded-bl-md rounded-br-md border p-4 flex cursor-pointer focus:outline-none`}>
                            <input type="radio" name="webhook" value="custom" className="h-4 w-4 mt-0.5 cursor-pointer text-green-600 border-gray-300 focus:ring-green-500" aria-labelledby="webhook-1-label" aria-describedby="webhook-2-description" onChange={this.handleChangeType} checked={useCustom} />
                            <div className="ml-3 flex flex-col w-full">
                                <span id="webhook-2-label" className={`block text-sm font-medium ${selectedCustomLabel}`}>
                                Use a Custom Webhook
                                </span>
                                <span id="webhook-2-description" className={`block text-sm ${selectedCustomDescription}`}>
                                Specify the URL where SwingbotPro will send the processing results for your Lesson Program.
                                </span>
                                {useCustom === true && (
                                    <div className="mt-4 mb-4">
                                        <div className="mt-1 flex rounded-md shadow-sm w-full">
                                            <div className="relative flex-grow focus-within:z-10">
                                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                    <FontAwesomeIcon icon={faLink} className="text-green-700 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                                                </div>
                                                <input id="webhook" onChange={this.handleOnChange} value={this.state.dirtyEndpoint} className="bg-gray-50 form-input block w-full rounded pl-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5" placeholder="https://" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </label>

                </div>
                </fieldset>

                <div className="mt-4">
                    <Button 
                        title="Save Changes"
                        onClick={this.handleSave}
                        bgColor="green"
                        hoverColor="green"
                        size="lg"
                        disabled={!canSave}
                        block
                    />
                    <div className="flex justify-end w-full mt-2">
                        {sendToWebhookLoading === false && this.state.endpointValue !== '' && (
                            <div 
                                onClick={this.handleTestWebhook}
                                className="flex flex-end cursor-pointer underline text-green-500"
                            >test webhook</div>
                        )}
                        {sendToWebhookLoading === true && <Loading />}
                    </div>
                    {sendToWebhookError !== null && (
                        <div className="flex flex-end w-full p-4 rounded bg-red-50 shadow mt-2">
                            {sendToWebhookError !== null && (
                                <div className="text-red-500 font-bold">Error </div>
                            )}
                        </div>
                    )}
                    {(sendToWebhookSuccess !== null && sendToWebhookSuccess !== undefined && sendToWebhookError === null) && (
                        <div className="flex flex-end w-full p-4 rounded bg-green-50 shadow mt-2">
                            <div className="text-green-500 font-bold">
                                Success! {sendToWebhookSuccess.status}
                            </div>
                        </div>
                    )}
                </div>
        </div>
        );
    }
}

Webhook.defaultProps = {
    title: '',
    endpoint: '',
    forTitle: '',
    licenseeCampaignData: null,
}

const mapStateToProps = ({ settings, licenseeCampaign }) => {
    const { webhookUpdateSuccess } = settings;
    const {
        sendToWebhookLoading,
        sendToWebhookSuccess,
        sendToWebhookError
    } = licenseeCampaign;
    return { 
        webhookUpdateSuccess,
        sendToWebhookLoading,
        sendToWebhookSuccess,
        sendToWebhookError
    };
}

const mapDispatchToProps = {
    updateWebhookForLicenseeCampaign,
    sendToWebhook,
    sendToWebhookReset
}


export default connect(mapStateToProps, mapDispatchToProps)(Webhook);