import React from 'react';
import { connect } from 'react-redux';
import { DashboardContent, Container } from '../tailwind';
import { statisticsByLicenseeId, getLessonCountsByDay } from '../../actions/licensee';
import { getWebsiteForLicensee } from '../../actions/website';
import { getLicenseeCampaignsByLicenseeId } from '../../actions/licenseeCampaign';
import Loading from '../common/Loading';
import Button from '../common/Button';
import { Bar } from 'react-chartjs-2';
import { selectors as authSelectors } from '../../reducers/auth';
import { selectors as websiteSelectors } from '../../reducers/website';
import { selectors as licenseeCampaignSelectors } from '../../reducers/licenseeCampaign';
import AccountRenewalBanner from '../common/banner/AccountRenewalBanner';
import Onboarding from './onboarding/Onboarding';
class OverviewPage extends React.Component {

    componentDidMount = () => {
        const { authUser } = this.props;
        this.props.statisticsByLicenseeId(authUser.licensee_id);
        this.props.getLessonCountsByDay(authUser.licensee_id);
        this.props.getWebsiteForLicensee(authUser.licensee_id);
        this.props.getLicenseeCampaignsByLicenseeId(authUser.licensee_id);
    }

    totalCountForMonth = () => {
        const { countsByDay } = this.props;
        let total = 0;
        if (countsByDay !== null) {
            countsByDay.forEach(record => {
                total += record.count;
            });
        }
        return total;
    }

    renderUsageBar = () => {
        const { countsByDay, planInformation } = this.props;
        const totalCount = this.totalCountForMonth(countsByDay);
        const percentUsed = totalCount > 0 
            ? (totalCount / planInformation.videosPerMonth) * 100
            : 0;

        // set the color of the bar
        let barColor = 'bg-green-500';
        if (percentUsed < 75) {
            barColor = 'bg-green-500';
        } else if( percentUsed < 90) {
            barColor = 'bg-yellow-300';
        } else {
            barColor = 'bg-red-500';
        }

        return (
            <Container>
                <div className="flex flex-1 flex-row justify-between w-full">
                    <div className="flex flex-col">
                        <h3 className="font-bold text-lg text-gray-700">Plan Usage ({Math.round(percentUsed,2)}%)</h3>
                        <p className="text-sm text-gray-600">We have processed {totalCount} videos of your {planInformation.videosPerMonth} quota this month.</p>
                    </div>
                    <div className="flex items-center">
                        <Button 
                            onClick={() => this.props.history.push('/account')} 
                            textColor="white"
                            bgColor="green"
                            hoverColor="green"
                            title="View Account"
                        />
                    </div>
                </div>
                <div className="w-full flex flex-row border border-gray-100 bg-gray-200 rounded-lg mt-4">
                    <div className={`${barColor} h-4 rounded-lg rounded-r-none`} style={{ width: `${percentUsed}%`}} />
                </div>
            </Container>
        )
    }

    renderBarChart = () => {
        const { countsByDay } = this.props;

        const data1 = countsByDay !== null
            ? countsByDay.map(record => {
                return record.count;
            }): null;

        var now = new Date();
        const daysInMonth = new Date(now.getFullYear(), now.getMonth()+1, 0).getDate();
        const monthName = now.toLocaleString('default', { month: 'long' })

        const labels = [];
        for (var i=0; i < daysInMonth; i++) {
            labels.push(i+1);
        }

        var data = {
            labels: labels,
            datasets: [{
                label: 'Video Uploads',
                backgroundColor: 'rgba(58, 156, 84, 0.2)',
                borderColor: 'rgba(25, 84, 41,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(58, 156, 84, 0.2)',
                hoverBorderColor: 'rgba(25, 84, 41,1)',
                data: data1
            }]
          };

        return data !== null ? (
            <Bar
                data={data}
                height={300}
                options={{
                    maintainAspectRatio: false,
                    title:{
                      display:false,
                      text:`Video Uploads for ${monthName}`,
                      fontSize:20
                    },
                    legend:{
                      display:false,
                      position:'right'
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false,
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                display: true,
                            },
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    },
                    gridLines: {
                        display: false,
                        drawOnChartArea: false,
                    }
                  }}
            />
        ) : null;
    }

    getCurrentMonthName = () => {
        var now = new Date();
        const monthName = now.toLocaleString('default', { month: 'long' })
        return monthName;
    }

    renderStatItem = (title, value, fromValue = null) => {
        return (
            <div className="border-t border-gray-200 md:border-0 md:border-l">
                <div className="px-4 py-5 sm:p-6">
                    <dl>
                    <dt className="text-base leading-6 font-bold text-gray-700">
                        {title}
                    </dt>
                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div className="flex items-baseline text-2xl leading-8 font-semibold text-green-600">
                        {value}
                        </div>
                    </dd>
                    </dl>
                </div>
            </div>
        );
    }

    renderStatistics = (statistics) => {
        return statistics            
            ? statistics.map(stat => {
                if (stat.value !== null) {
                    const statisticName = stat['name'];
                    const isFloat = parseInt(stat['value']) !== stat['value'];
                    const statisticValue = isFloat === true ? stat['value'].toFixed(5) : stat['value'];
                    return (<div key={statisticName}>{this.renderStatItem(statisticName, statisticValue, null)}</div>);
                }
                return null;
            }).filter(rec => rec !== null) : null;
    }

    renderMonthOptions = () => {
        const months = [
            'January', 
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
        return months.map(month => {
            return (<option value={month}>{month}</option>);
        })
    }

    render() {
        const { 
            licenseeStatistics, 
            loadingLicenseeStatistics,
            countsByDayLoading,
            website,
            lessons
         } = this.props;
        const stats1 = licenseeStatistics !== null 
            ?   Object.keys(licenseeStatistics).slice(0,2).map(key => {
                    return { 
                            name: key,
                            value: licenseeStatistics[key] 
                        };
                }) 
            : null;

        const stats2 = licenseeStatistics !== null 
            ?   Object.keys(licenseeStatistics).slice(3,6).map((key, value) => {
                    return { 
                        name: key,
                        value: licenseeStatistics[key] 
                    };
                })
            : null;

        const currentMonth = this.getCurrentMonthName();
        const hasLessons = lessons !== null && lessons.length > 0;
        const hasWebsite = website.success !== null && website.success.length > 0;
        const needsWebsiteOnboarding = hasLessons === false || hasWebsite === false;
        const isLoading = website.loading;
        return needsWebsiteOnboarding === false && isLoading === false ? (
            <DashboardContent>
                {countsByDayLoading === false && loadingLicenseeStatistics === false && (<AccountRenewalBanner />)}
                {countsByDayLoading === false && loadingLicenseeStatistics === false && this.renderUsageBar()}
                <Container>
                    {(countsByDayLoading === true || loadingLicenseeStatistics === true) && <Loading />}
                    {countsByDayLoading === false && (
                        <div className="">
                            <div className="flex flex-row justify-between">
                                <h3 className="text-xl text-gray-600 font-bold">Video Uploads for {currentMonth}</h3>
                            </div>
                            <div className="mt-2">
                                {this.renderBarChart()}
                            </div>
                        </div>
                    )}
                </Container>
                {countsByDayLoading === false && loadingLicenseeStatistics === false && (
                    <div className="flex flex-col w-full">
                        <div className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow md:grid-cols-2">
                            {this.renderStatistics(stats1)}
                        </div>

                        <div className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow md:grid-cols-3">
                            {this.renderStatistics(stats2)}
                        </div>
                    </div>
                )}
            </DashboardContent>
        ) : (
            <DashboardContent>
                <Onboarding 
                    website={website}
                    lessons={lessons}
                    history={this.props.history}
                />
            </DashboardContent>
        );
    }
}

const mapStateToProps = ({ program, auth, targetInterpretation, licensee, website, licenseeCampaign }) => {
    const { 
        programData,
        programsForCampaign,
        getProgramsForCampaignLoading,
        getProgramsForCampaignError,
    } = program;
    const { 
        licenseeData,
        licenseeStatistics,
        loadingLicenseeStatistics,
        loadingLicenseeStatisticsError,
        countsByDay,
        countsByDayLoading,
        countsByDayError,
    } = licensee;
    const { targetInterpretations } = targetInterpretation;
    const { authUser } = auth;
    return {
        planInformation: authSelectors.planInformation(auth),
        website: websiteSelectors.websiteConfig(website),
        lessons: licenseeCampaignSelectors.lessons(licenseeCampaign),
        programData,
        programsForCampaign,
        getProgramsForCampaignLoading,
        getProgramsForCampaignError,
        authUser,
        targetInterpretations,
        licenseeData,
        licenseeStatistics,
        loadingLicenseeStatistics,
        loadingLicenseeStatisticsError,
        countsByDay,
        countsByDayLoading,
        countsByDayError,
    };
}

const mapDispatchToProps = {
    statisticsByLicenseeId,
    getLessonCountsByDay,
    getWebsiteForLicensee,
    getLicenseeCampaignsByLicenseeId
}

export default connect(mapStateToProps, mapDispatchToProps)(OverviewPage);