export const ANALYZE_VIDEO = 'analyze/analyze-video';
export const ANALYZE_VIDEO_SUCCESS = 'analyze/analyze-video-success';
export const ANALYZE_VIDEO_FAIL = 'analyze/analyze-video-fail';
export const ANALYZE_VIDEO_RESET = 'analyze/analyze-video-reset';

export const CONVERT_CALCULATION_TO_ENGLISH = 'analyze/convert-calc-to-english';
export const CONVERT_CALCULATION_TO_ENGLISH_SUCCESS = 'analyze/convert-calc-to-english-success';
export const CONVERT_CALCULATION_TO_ENGLISH_FAIL = 'analyze/convert-calc-to-english-fail';

export const CONVERT_EQUATION_TO_ENGLISH = 'analyze/convert-equation-to-english';
export const CONVERT_EQUATION_TO_ENGLISH_SUCCESS = 'analyze/convert-equation-to-english-success';
export const CONVERT_EQUATION_TO_ENGLISH_FAIL = 'analyze/convert-equation-to-english-fail';