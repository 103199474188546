import React from 'react';

/**
 * basic Search UI
 */
class SearchBar extends React.Component {
  render() {
    const { term, onChange, placeholder } = this.props;
    return (
      <div className="flex w-full">
        <input 
          className="p-4 border border-gray-100 rounded shadow w-full text-xl font-bold text-gray-600" 
          type="text" 
          value={term || ""} 
          onChange={onChange}
          placeholder={placeholder}
        />
      </div>
    )
  }
}

SearchBar.defaultProps = { 
  placeholder: 'Search',
  term: '',
  onChange(){}
}

export default SearchBar;