import React from 'react';
import { DashboardContent, Container } from '../../tailwind';
import { connect } from 'react-redux';
import Loading from '../../common/Loading';
import './CheckoutPage.css';
// braintree
import DropIn from "braintree-web-drop-in-react";
import { getPlans } from '../../../actions/plan';
import { getLicenseeDataByUserId } from '../../../actions/licensee';
import { selectors as authSelectors } from '../../../reducers/auth';
import {
    subscribeToPlan,
    subscribeToPlanReset,
    createBraintreeCustomerGetToken,
    updateSubscriptionPlan,
} from '../../../actions/subscribe';
import { selectors as licenseeSelectors } from '../../../reducers/licensee';
import Button from '../../common/Button';
class CheckoutPage extends React.Component {
    // braintree instance
    instance;

    componentDidMount = () => {
        const { sandbox } = this.props.history.location.state;
        const isSandbox = sandbox === 'true' ? true : false;
        this.props.subscribeToPlanReset();
        const { authUser} = this.props;
        if (authUser) {
            const { user_id, f_name, l_name, email } = authUser;

            // lets get the licensee data to determine if the user 
            // already has an active subscription
            this.props.getLicenseeDataByUserId(user_id);

            // and now let's get a user token
            this.props.createBraintreeCustomerGetToken(
                user_id, 
                f_name, 
                l_name, 
                email, 
                isSandbox
            );
        }
    }

    /**
     * handleBuyButton
     * 
     * 1. Request a payment nonce from braintree based on the 
     *      payment information that we have typed in.
     * 2. Receive a nonce from braintree if all is well with the payment
     *      information, and send that nonce along with the plan information
     *      to our server to make a subscription purchase.
     */
    handleBuyButton = () => {
        const { planData, sandbox } = this.props.history.location.state;
        const { planInformation, authUser } = this.props;
        // 1. Request the nonce from braintree
        this.instance.requestPaymentMethod()
            .then(data => {
                const paymentNonce = data.nonce;
                if (paymentNonce) {
                    // get the plan name from the plans object
                    let planName = planData.name;
                    // get the user id as well
                    const userId = authUser.user_id;
                    const planStatus = planInformation.subscriptionStatus;

                    // CREATE or UPGRADE?
                    // let hasActiveSubscription = false;
                    // let activeSubscriptionId = null;
                    // this.props.licenseeData.transactions.forEach(transaction => {
                    //     const subscription = transaction.subscription;
                    //     if (subscription.status.toLowerCase() === 'active') {
                    //         console.log('we should upgrade!');
                    //         hasActiveSubscription = true;
                    //         activeSubscriptionId = subscription.id;
                    //         console.log(subscription);
                    //     }
                    // });

                    // console.log('has active: ', hasActiveSubscription, planData);
                    // we have to make sure the user is active, the plan is Active
                    // and the id is not null...
                    if (authUser.is_subscribed === true && planStatus === true && planInformation.subscriptionId !== null) {
                        // console.log(planInformation);
                        // console.log('update subscription plan', {
                        //     userId, 
                        //     paymentNonce, 
                        //     planId: planData.name, 
                        //     id: planInformation.subscriptionId, 
                        //     price: planData.price_plan, 
                        //     sandbox
                        // });
                        this.props.updateSubscriptionPlan(
                            userId, 
                            paymentNonce, 
                            planData.name, 
                            planInformation.subscriptionId, 
                            planData.price_plan, 
                            sandbox
                        );
                    } else {
                        // console.log('create a new plan');
                        // now we can make the request to our server
                        // to make the purchase.
                        this.props.subscribeToPlan(
                            userId, 
                            paymentNonce, 
                            planName, 
                            sandbox
                        );
                    }
                }
            })
            .catch(e => {
                this.setState({
                    paymentNonce: null
                })
            });
    }

    renderBuyButton = () => {
        const { planData } = this.props.history.location.state;
        const {
            subscriptionSuccess,
            subscriptionLoading,
            subscriptionError
        } = this.props;
        return planData.price_plan < 10000 && subscriptionLoading === false && subscriptionSuccess === null && subscriptionError === null ? (
            <button onClick={this.handleBuyButton} type="button" className="block w-full text-center rounded-lg border border-transparent bg-gray-700 px-6 py-4 text-2xl leading-6 font-medium text-white hover:bg-gray-800 focus:outline-none focus:shadow-outline transition ease-in-out duration-150 cursor-pointer">
                Click to Buy Now
            </button>
        ) : <Loading />;
    };

    renderBraintree = () => {
        const { planData } = this.props.history.location.state;
        const {
            subscriptionSuccess,
            subscriptionLoading,
            subscriptionError,
            createBraintreeCustomerLoading,
        } = this.props;
        return this.props.clientToken !== null && planData !== null && planData.price_plan < 10000 && createBraintreeCustomerLoading === false ? ( 
                <Container>
                    <div id="braintree">
                    {subscriptionError === null && subscriptionLoading === false && subscriptionSuccess === null && (
                        <DropIn
                            options={{ 
                                authorization: this.props.clientToken,
                                paypal: {
                                    flow: 'checkout',
                                    amount: planData.price_plan,
                                    currency: 'USD'
                                },
                                planId: planData.name
                            }}
                            onInstance={instance => (this.instance = instance)}
                        />
                    )}
                    {subscriptionError === null && subscriptionLoading === false && subscriptionSuccess === null && this.renderBuyButton()}

                    {subscriptionLoading === true && <Loading />}
                    {createBraintreeCustomerLoading === true && <Loading />}

                    {subscriptionSuccess !== null && (
                        <div className="flex flex-col">
                            <div className="flex flex-col">
                                <h3 className="text-2xl font-bold text-green-700">Your transaction was successful!</h3>
                                <p className="text-lg text-gray-600">Your transaction id is: <span className="font-bold">{subscriptionSuccess.data.data.subscription.id}</span></p>
                            </div>
                            <div className="">
                                <Button
                                    title="Click to go to Account"
                                    onClick={() => this.props.history.push('/account')}
                                    bgColor="green"
                                    hoverColor="green"
                                />
                            </div>
                        </div>
                    )}

                    {subscriptionError !== null && (
                        <div className="">
                            <h3 className="text-2xl font-bold text-gray-800">There was an error processing your payment, pelase try again later.</h3>
                            <div className="">
                                <Button
                                    title="Click to go to Account"
                                    onClick={() => this.props.history.push('/account')}
                                    bgColor="green"
                                    hoverColor="green"
                                />
                            </div>
                        </div>
                    )}


                    </div>
                </Container>
        ) : (<Loading />);
    }

    renderTermType = () => {
        return (
            <div className="mb-4 text-center">
                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                    <button 
                        onClick={() => this.handleSelectTerm('monthly')} 
                        type="button" 
                        className={`relative inline-flex items-center px-8 py-4 rounded-l-md text-xl leading-5 font-medium focus:z-10 transition ease-in-out duration-150 outline-none ${this.state.term === 'monthly' ? 'bg-gray-800 text-white' : 'bg-white text-gray-700'}`}
                    >
                        Monthly
                    </button>
                    <button type="button" onClick={() => this.handleSelectTerm('annually')} className={`relative inline-flex items-center px-8 py-4 rounded-r-md text-lg leading-5 font-medium focus:z-10 transition ease-in-out duration-150 outline-none ${this.state.term === 'annually' ? 'bg-gray-800 text-white' : 'bg-white text-gray-700'}`}>
                        Annually
                    </button>
                </span>
            </div>
        )
    }

    // getPricePerVideoForPackage = (packageName, term) => {
    //     const { plans } = this.props;
    //     let price = null;
    //     if (plans !== null) {
    //         plans.forEach(plan => {
    //             if (plan.feature_package_name.toLowerCase() === packageName.toLowerCase() &&
    //                 plan.term === term) {
    //                 // set the plan price per video
    //                 price = plan.price_per_video;
    //             }
    //         });
    //     }
    //     return price;
    // }

    getPlanDataPackage = (packageName, term, videos) => {
        const { plans } = this.props;
        let planSelected = null;
        const termMultiplier = term === 'annually' ? 12 : 1;
        if (plans !== null) {
            plans.forEach((plan,index) => {
                if (plan.feature_package_name.toLowerCase() === packageName.toLowerCase() && plan.term === term) {
                    if (planSelected === null) {
                        if (videos <= (plan.videos_per_month * termMultiplier)) {
                            planSelected = plan;
                        }
                    } else {
                        if (videos <= (plan.videos_per_month * termMultiplier) && (plan.videos_per_month * termMultiplier) < (planSelected.videos_per_month * termMultiplier)) {
                            planSelected = plan;
                        }
                    }
                }
            });
        }
        return planSelected;
    }

    renderPriceSheet = () => {
        const planData = this.props.history.location.state.planData || null;//this.getPlanDataPackage(this.state.featurePackage, this.state.term, this.state.videos);
        const price = planData.price_per_video; //this.getPricePerVideoForPackage(this.state.featurePackage, this.state.term);
        const termMultiplier = planData.term === 'annually' ? 12 : 1;
        const totalPrice = planData.price_plan //this.getTotalPrice(this.state.featurePackage);
        return (
            <div>
                <div className="bg-green-600 px-0 mb-4 rounded shadow">
                  <div className="grid grid-cols-2 gap-8 p-8">
                    <div className="px-6 col-span-1">
                        {planData && (
                            <div>
                                <h1 
                                    className="text-3xl text-white font-bold"
                                >
                                    {planData.video_package_name} {planData.feature_package_name}
                                </h1>
                                <h2 
                                    className="text-2xl font-md text-gray-200"
                                >
                                    {planData.videos_per_month * termMultiplier} videos{planData.term === 'annually' ? ' per year' : ' per month'}
                                </h2>
                            </div>
                        )}
                    </div>
                    {totalPrice !== null && totalPrice < 10000 && (
                        <div className="flex items-center justify-end px-6">
                        <span className="px-3 flex items-start text-6xl leading-none tracking-tight text-white">
                            <span className="mt-2 mr-2 text-4xl font-medium">
                            $
                            </span>
                            <span className="font-extrabold">
                                {planData.price_plan}
                            </span>
                        </span>
                        <span className="text-xl leading-7 font-medium text-gray-200">
                            {planData.term === 'annually' ? '/yr' : '/mo'}
                        </span>
                        </div>
                    )}
                    {totalPrice !== null && totalPrice > 10000 && (
                        <div className="flex items-center justify-end px-6">
                        <span className="px-3 flex items-start text-6xl leading-none tracking-tight text-white flex-col">
                            <div className="my-2 mr-2 text-2xl font-medium">
                            Contact Us for Pricing
                            </div>
                        </span>
                        </div>
                    )}
                    {planData && (
                        <div className="px-6 col-span-2">
                            <p className="text-gray-200 text-sm">This subscription will recur {planData.term}. You may cancel anytime. All lessons per package must be used in the term of the subscription. Video processing credits may not be redeemed for cash value.  All videos will be processed at a price of <span className="font-bold">${price}</span> per video. This subscription plan will process up to <span className="font-bold">{planData.videos_per_month}</span> videos per month.</p>
                        </div>
                    )}
                  </div>
                </div>

                </div>
        );
    }

    render() {
        const {
            plans,
            getPlansLoading,
            subscriptionSuccess,
            subscriptionError,
        } = this.props;
        const planData = this.props.history.location.state || null;
        return planData ? (
            <DashboardContent 
                backTitle="Back"
                title={'Checkout'} 
            >
                {plans !== null && getPlansLoading === false && (
                    <Container className="border">
                        {subscriptionError === null && subscriptionSuccess === null && this.renderPriceSheet()}
                        {this.renderBraintree()}
                    </Container>
                )}
                {getPlansLoading === true && <Loading />}
            </DashboardContent>
        ) : null;
    }
}

const mapStateToProps = ({ auth, plan, licensee, subscribe }) => {
    const { authUser } = auth;
    const {
        plans,
        getPlansSuccess,
        getPlansError,
        getPlansLoading
    } = plan;
    const {
        clientToken,
        subscriptionSuccess,
        subscriptionLoading,
        subscriptionError,
        createBraintreeCustomerLoading,
        createBraintreeCustomerSuccess,
        createBraintreeCustomerError,
    } = subscribe;
    return {
        planInformation: authSelectors.planInformation(auth),
        authUser,
        plans,
        getPlansError,
        getPlansSuccess,
        getPlansLoading,
        licenseeData: licenseeSelectors.licenseeData(licensee),
        clientToken,
        subscriptionSuccess,
        subscriptionLoading,
        subscriptionError,
        createBraintreeCustomerLoading,
        createBraintreeCustomerSuccess,
        createBraintreeCustomerError,
    };
}

const mapDispatchToProps = {
    subscribeToPlan,
    subscribeToPlanReset,
    createBraintreeCustomerGetToken,
    getPlans,
    getLicenseeDataByUserId,
    updateSubscriptionPlan
};



export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);