/**
 * Braintree Payment Subscription Actions
 * 
 */

import * as actions from './actions';
// import * as authActions from '../auth/actions';
import * as services from './services';

/**
 * Create a Customer AND get the token
 * @param {int} userId 
 * @param {string} firstName 
 * @param {string} lastName 
 * @param {string} email 
 * @param {bool} sandbox 
 */
export const createBraintreeCustomerGetToken = (userId, firstName, lastName, email, sandbox = false) => dispatch => {
    return services.createBraintreeCustomer(userId, firstName, lastName, email, sandbox)
        .then(result => {
            // first dispatch the result of the customer creation...
            dispatch(actions.createBraintreeCustomerSuccess(result));
            // based on the result, we still need to get the token using the 
            // latest customer id, which is the user id anyway...
            return services.getBraintreeClientToken(userId, sandbox)
                .then(result => dispatch(actions.getBraintreeClientTokenSuccess(result)))
                .catch(error => dispatch(actions.getBraintreeClientTokenFail(error)));
        })
        .catch(error => dispatch(actions.createBraintreeCustomerFail(error)));
};
/**
 * Create a new customer in the Braintree Vault
 * This is necessary to make a purchase (get a token)
 * @param {int} userId 
 * @param {string} firstName 
 * @param {string} lastName 
 * @param {string} email 
 * @param {boolean} sandbox 
 */
export const createBraintreeCustomer = (userId, firstName, lastName, email, sandbox = false) => dispatch => {
    return services.createBraintreeCustomer(userId, firstName, lastName, email, sandbox)
        .then(result => dispatch(actions.createBraintreeCustomerSuccess(result)))
        .catch(error => dispatch(actions.createBraintreeCustomerFail(error)));
};
/**
 * Subscribe a user to a subscription plan in Braintree
 * 
 * @param {int} paymentNonce 
 * @param {int} planId 
 */
export const subscribeToPlan = (userId, paymentNonce, planId, sandbox = false) => dispatch => {
    dispatch(actions.subscribeToPlan(userId, paymentNonce, planId, sandbox));
    return services.subscribeToPlan(userId, paymentNonce, planId, sandbox)
        .then(result => dispatch(actions.subscribeToPlanSuccess(result)))
        .catch(error => dispatch(actions.subscribeToPlanFail(error)));
}

export const subscribeToPlanReset = () => dispatch => {
    return dispatch(actions.subscribeToPlanReset());
}

export const updateSubscriptionPlan = (userId, paymentNonce, planId, subscriptionId, price, sandbox = false) => dispatch => {
        dispatch(actions.updateSubscriptionPlan(userId, paymentNonce, planId, subscriptionId, price, sandbox));
        return services.updateSubscriptionPlan(
            userId, paymentNonce, planId, subscriptionId, price, sandbox
        )
            .then(result => {
                // success on the plan
                dispatch(actions.updateSubscriptionPlanSuccess(result));
                // ok now lets update the user account!
                // dispatch(authActions.updateSubscriptionPlan(result));
            })
            .catch(error => dispatch(actions.updateSubscriptionPlanFail(error)));
};

export const getBraintreeClientToken = (userId, sandbox = false) => dispatch => {
    return services.getBraintreeClientToken(userId, sandbox)
        .then(result => dispatch(actions.getBraintreeClientTokenSuccess(result)))
        .catch(error => dispatch(actions.getBraintreeClientTokenFail(error)));
};

export const getSubscriptionsByUserId = (userId, sandbox = false) => dispatch => {
    dispatch(actions.getSubscriptionsByUserId(userId, sandbox));
    return services.getSubscriptionsByUserId(userId, sandbox)
        .then(result => dispatch(actions.getSubscriptionsByUserIdSuccess(result)))
        .catch(error => dispatch(actions.getSubscriptionsByUserIdFail(error)));
};

export const cancelSubscription = (userId, subscriptionId, sandbox = false) => dispatch => {
    dispatch(actions.cancelSubscription(userId, subscriptionId, sandbox));
    return services.cancelSubscription(userId, subscriptionId, sandbox)
        .then(result => dispatch(actions.cancelSubscriptionSuccess(result)))
        .catch(error => dispatch(actions.cancelSubscriptionFail(error)));
};

export const cancelSubscriptionReset = () => dispatch => {
    dispatch(actions.cancelSubscriptionReset());
};
