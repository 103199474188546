import React from 'react';

class WizardContainer extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            dirty: false,
        }
    }

    goToPrevious = () => {
        this.props.previousStep();
    }

    render() {
        return (
            <div className="flex flex-1 flex-col overflow-hidden p-10" >
                <div className="text-xl flex-1 flex-col justify-center items-center font-bold">
                    <div className="text-2xl text-gray-800 font-bold flex justify-center flex-col">
                        <div className="flex flex-row items-center justify-between">
                            <div className="flex flex-1">
                                {this.props.title}
                            </div>
                            {this.props.step > 1 && (
                                <div onClick={this.goToPrevious} className="flex text-gray-600 text-sm underline cursor-pointer">
                                    back
                                </div>
                            )}
                        </div>
                        {this.props.text && (
                            <div className="flex-row text-base text-gray-500 py-2 font-normal">
                                {this.props.text}
                            </div>
                        )}
                    </div>
                </div>
                {this.props.children}
            </div>
        );
    }
}

WizardContainer.defaultProps = {
    step: 1,
    name: 'input',
    type: 'text',
    placeholder: '',
    title: '',
    color: 'green',
    onSubmit(){},
    loading: false,
    complete: null,
    error: null
}

export default WizardContainer;