// main API configuration file containing the key, endpoint base
// session that will talk to the local storage
// to retrieve the token (or not)

import axios from 'axios';
import { getTokenAsync, timeUntilExpiration, saveToken } from '../util/jwt';
// environment variables
// const Config = process.env;
/**
 * fetch helper to inject headers etc
 * for each request to the API
 */
// 'account/login', { email, password }, 'post', true, 'account'
export const swingbotApi = (endpoint, payload = {}, method = 'get', auth = true, base = 'www') => {
  // fetch the base depending on the api we need.
  // we have to get the token first...
    return getTokenAsync()
      .then((tokenData) => {
        // if there is a token....
        if (tokenData !== null && tokenData !== "null" && tokenData !== 'undefined' && tokenData !== undefined) {
          // lets check the time left on this token
          let timeLeft = null;
          try {
            if ('token' in tokenData) {
              // lets check to see if the token is close to expiring...
              timeLeft = timeUntilExpiration(tokenData.token);
            }
          } catch(e) {

          }
          // 1. We have to check to see if the token is CLOSE to expiring, say, 5 minutes...
          //    if this is the case, let's use the refresh token string to go verify and 
          //    get a new access token (Need to change this to use the refresh token!)
          if (timeLeft && timeLeft.minutesLeft < 5) {
            // first we have to get the new access token...
            // by sending over the refresh to the open endpoint...
            return axios(`${getBaseUrl('licensee-2')}auth/refresh`, {
              method: 'post',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Api-Key': getApiKey('licensee-2')
              },
              data: { 'refresh': tokenData.refreshToken }
            })
            .then(result => {
              if (result.data.success === true) {
                // we should have a result and we have to store the token now....
                // and then make the REAL request with the new token!
                // const newToken = result.data.token;
                const tokenData = { 
                  token: result.data.token,
                  refreshToken: result.data.refreshToken
                };
                // store it...
                saveToken(tokenData);
                // and now make the request with the new token
                return sendNormalRequest(endpoint, payload, method, auth, base, tokenData.token);
              } else {
                return Promise.reject('Session expired due to inactivity');
              }
            }).catch(e => Promise.reject(e.message));
          }else {
            return sendNormalRequest(endpoint, payload, method, auth, base, tokenData.token);
          }
        } else {
          return sendNormalRequest(endpoint, payload, method, auth, base, null);
        }
    }).catch(e => {
      return Promise.reject(e);
    });
};

const sendNormalRequest = (endpoint, payload = {}, method = 'get', auth = true, base = 'www', token) => {
  // no token yet, so we have to send along the request as usual...
  const headers = getHeaders(auth, token || '', base);
  const baseUrl = getBaseUrl(base);
  const body = generateBody(payload);
  // now we can fetch...
  return axios(`${baseUrl}${endpoint}`, {
    method: method.toLowerCase(),
    headers,
    data: body
  })
  .then(result => {
    if (result.status === 401) {
      throw new Error('Unauthorized');
    } else {
      return result.data;
    }
  }).catch(err => {
    return Promise.reject(err);
  });
}
/**
 * get the headers
 * @param boolean auth boolean to use the Authorization header
 * @param string  token the authorization token
 */
const getHeaders = (auth, token = null, base) => (
  auth === true ? (
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token || '',
      'X-Api-Key': getApiKey(base)
    }
  ) : (
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': getApiKey(base)
    }
  )
);
/**
 * generate the body of the request IF there is a payload
 */
const generateBody = (payload) => (
  Object.keys(payload).length > 0 ? JSON.stringify(payload) : null
);

const getBaseUrl = (base) => {
  let baseUrl = process.env.REACT_APP_LICENSEE_2_API_URL || null;
  switch (base) {
    case 'www':
      baseUrl = process.env.REACT_APP_API_URL;
    break;
    case 'account':
      baseUrl = process.env.REACT_APP_ACCOUNT_API_URL;
    break;
    case 'licensee':
      baseUrl = process.env.REACT_APP_LICENSEE_API_URL;
    break;
    case 'licensee-2':
      baseUrl = process.env.REACT_APP_LICENSEE_2_API_URL;
    break;
    case 'licensee-3':
      baseUrl = process.env.REACT_APP_LICENSEE_3_API_URL;
    break;
    case 'program':
      baseUrl = process.env.REACT_APP_PROGRAM_API_URL;
    break;
    case 'transaction':
      baseUrl = process.env.REACT_APP_TRANSACTION_API_URL;
    break;
    case 'video':
      baseUrl = process.env.REACT_APP_VIDEO_API_URL;
    break;
    case 'user':
      baseUrl = process.env.REACT_APP_USER_API_URL;
    break;
    case 'game':
      baseUrl = process.env.REACT_APP_GAME_API_URL;
    break;
    case 'feedback':
      baseUrl = process.env.REACT_APP_FEEDBACK_API_URL;
    break;
    case 'analysis':
      baseUrl = process.env.REACT_APP_WWW_ANALYSIS_API_URL;
    break;
    default:
      baseUrl = process.env.REACT_APP_API_URL;
    break;
  }
  return baseUrl;
};

const getApiKey = (base) => {
  let key = process.env.REACT_APP_API_KEY;
  switch (base) {
    case 'www':
      key = process.env.REACT_APP_API_KEY;
    break;
    case 'licensee':
      key = process.env.REACT_APP_LICENSEE_API_KEY;
    break;
    case 'licensee-2':
      key = process.env.REACT_APP_LICENSEE_2_API_KEY;
    break;
    case 'licensee-3':
      key = process.env.REACT_APP_LICENSEE_3_API_KEY;
    break;
    case 'account':
      key = process.env.REACT_APP_ACCOUNT_API_KEY;
    break;
    case 'program':
      key = process.env.REACT_APP_PROGRAM_API_KEY;
    break;
    case 'transaction':
      key = process.env.REACT_APP_TRANSACTION_API_KEY;
    break;
    case 'video':
      key = process.env.REACT_APP_VIDEO_API_KEY;
    break;
    case 'user':
      key = process.env.REACT_APP_USER_API_KEY;
    break;
    case 'game':
      key = process.env.REACT_APP_GAME_API_KEY;
    break;
    case 'feedback':
      key = process.env.REACT_APP_FEEDBACK_API_KEY;
    break;
    case 'analysis':
      key = process.env.REACT_APP_WWW_ANALYSIS_API_KEY;
    break;
    default:
      key = process.env.REACT_APP_API_KEY;
    break;
  }
  return key;
};

export const post = (data, endpoint) => {
  return axios(endpoint, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: data
  })
    .then(data => data)
    .catch(e => e.message);
}