import React from 'react';
import { connect } from 'react-redux';
import { Container } from '../../tailwind';
import DisabledTargetContainer from '../analysis/DisabledTargetContainer';
import ImageDrawItem from './ImageDrawItem';
import { removeLineFromInterpretation } from '../../../actions/line';
import { autoGenerateLines, getDisplayForLineType, getBodyPointByName, getPositionByName } from '../../../util/swingbot';
class TargetLineOptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          count: 0
        }
    }

    /**
     * generateLinesForCondition
     * We want to generate the line options AUTOMATICALLY for the user
     * This will allow the user to just "choose" from the list and give them ideas on what
     * to select for the lines
     * 
     * @param {*} position 
     * @param {*} bodyPoints 
     */
    generateLinesForCondition = (position, bodyPoints) => {
      const lineOption1 = autoGenerateLines(position, bodyPoints);
      const lineOption2 = autoGenerateLines(position, bodyPoints, "vertical", "#76EE00", "solid");
      const lineOption3 = autoGenerateLines(position, bodyPoints, "vertical", "#FFFF00", "dotted");
      const lineOption4 = autoGenerateLines(position, bodyPoints, "horizontal", "#76EE00", "dotted");
      const lineOption5 = autoGenerateLines(position, bodyPoints, "horizontal", "#FFFF00", "dotted");
      return [
        lineOption1,
        lineOption2,
        lineOption3,
        lineOption4,
        lineOption5
      ];
    }

    handleLineOptionClick = (lineOption, position, isSelected) => {
      this.props.onCreate(lineOption, position);
      this.setState({
        count: this.state.count + 1
      });
    }
    /**
     * renderImageItem
     * We will use the lines to determine if we already have a line selected
     * 
     * @param {object} lines 
     * @returns 
     */
    renderImageItemWithLines = (lines) => {
        try {
            const { bodyPointsToRender } = this.props;
            const { analysisResults } = this.props;
            

            return lines.map((lineOption, index) => {
              const p = Object.keys(lineOption)[0];
              const imageUrl = this.getImageUrlForPosition(analysisResults, p, 1);
              const lineType = getDisplayForLineType(lineOption[p][0].type);
              // const lineColor = lineOption[p][0].color;
              const lineBodyPoint = getBodyPointByName(lineOption[p][0].p1_lexicon);
              const linePosition = getPositionByName(lineOption[p][0].phase_origin).display;
              return (
                <div className="cursor-pointer rounded-lg overflow-clip border shadow" key={`line-option-${p}=${index}`} onClick={() => this.handleLineOptionClick(lineOption[p][0], p)}>
                  <div className="flex justify-center align-middle p-4 bg-white rounded-t-lg opacity-70 hover:opacity-100 ">
                    <ImageDrawItem
                        position={p}
                        imageUrl={imageUrl}
                        points={analysisResults.points}
                        rightHanded={analysisResults.right_handed}
                        bodyPointsToRender={bodyPointsToRender}
                        lines={[lineOption[p][0]]}
                        ratio={.60}
                        className="rounded-lg object-cover justify-center items-center"
                    />
                  </div>
                  <div className="bg-gray-100 rounded-b-lg p-4 text-sm border-t text-gray-700">
                    <span className="font-bold">{lineType} Line</span><br />
                    <span className="">{lineBodyPoint.display} at {linePosition}</span>
                  </div>
                </div>
              );
            });
        } catch(e) {
        }
    }
    /**
     * isLineSelected
     * Want to determine if the line is already selected
     * @param {object} line 
     * @param {array} lines 
     */
    isLineSelected = (line, lines) => {
      let isMatch = false;
      if (lines) {
        lines.forEach(l => {
          if( l.type === line.type && l.color === line.color && l.p1_lexicon === line.p1_lexicon) {
            isMatch = true;
          } 
        });
      }
      return isMatch;
    }

    renderOptionList = (allLines, lines) => {
      try {
        const { analysisResults } = this.props;
        
        return analysisResults !== null ? allLines.map((lineOption, index) => {
          const p = Object.keys(lineOption)[0];
          // const imageUrl = this.getImageUrlForPosition(analysisResults, p, 1);
          const lineType = getDisplayForLineType(lineOption[p][0].type);
          // const lineColor = lineOption[p][0].color;
          const lineBodyPoint = getBodyPointByName(lineOption[p][0].p1_lexicon);
          const linePosition = getPositionByName(lineOption[p][0].phase_origin).display;
          const isSelected = this.isLineSelected(lineOption[p][0], lines[p]);
          return isSelected === true ? (
            <div 
              className="cursor-pointer rounded-lg overflow-clip border shadow" 
              key={`line-option-${p}=${index}`} 
              onClick={() => {
                this.handleLineOptionClick(lineOption[p][0], p, isSelected);
              }}>
              <div className="bg-gray-100 rounded-b-lg p-4 text-sm border-t text-gray-700">
                <span className="font-bold">{lineType} Line {isSelected === true ? 'SELECTED': null}</span><br />
                <span className="">{lineBodyPoint.display} at {linePosition}</span>
              </div>
            </div>
          ) : null;
        }) : null;
      } catch(e) {
        // throw e;
      }
    }

    renderImageItem = (lines, position) => {
      try {
          const { bodyPointsToRender} = this.props;
          const { analysisResults } = this.props;
          const imageUrl = this.getImageUrlForPosition(analysisResults, position, 1);
          const linesToRender = lines[position];
          return (
            <div className="col-span-1 cursor-pointer rounded-lg overflow-clip border shadow" key={`line-option-${position}`}>
              <div className="flex justify-center align-middle p-4 bg-white rounded-t-lg opacity-100 ">
                <ImageDrawItem
                    position={position}
                    imageUrl={imageUrl}
                    points={analysisResults.points}
                    rightHanded={analysisResults.right_handed}
                    bodyPointsToRender={bodyPointsToRender}
                    lines={linesToRender}
                    ratio={.85}
                    className="rounded-lg object-cover justify-center items-center"
                />
              </div>
            </div>
          );
      } catch(e) {
        return null;
      }
  }

    getImageUrlForPosition = (results, position, number) => {
        if (results !== null) {
            if (results.frame_images !== null) {
                if (position !== null) {
                    return results.frame_images[position];
                }
            }
        }
        return null;
    }


    render() {
        const { position1, position2, bodyPoints2, bodyPoints1, lines, originPosition } = this.props;
        // auto-generate the line options?
        const lineOptions = this.generateLinesForCondition(position1, bodyPoints1);
        const lineOptions2 = this.generateLinesForCondition(position2, bodyPoints2);
        const allOptions = lineOptions.concat(lineOptions2).filter(lineOption => lineOption !== null);

        return position1 !== null && bodyPoints1 && lines !== null ? (
          <Container>
            <div className="flex flex-row bg-red-600 space-x-2">
              <div className="flex-1">
                {this.renderImageItem(lines, originPosition)}
              </div>
              <div className="flex flex-col overflow-y-auto w-full">
                {lines !== null && originPosition !== null && (
                    <div className="w-full h-full">
                        <div className="flex flex-row w-full">
                            <div className="flex flex-col h-full w-full overflow-y-auto pb-4">
                                {lines && this.renderOptionList(allOptions, lines)}
                            </div>
                        </div>
                    </div>
                )}
                </div>
            </div>
          </Container>
        ) : (<DisabledTargetContainer onClick={this.handleCreateLine} title="Click to Create Line Annotation" />);
    }
}

TargetLineOptions.defaultProps = {
    show: false,
    readOnly: false,
    textMessage: '',
    lines: null,
    position1: null,
    bodyPoints1: null,
    position2: null,
    bodyPoints2: null,
    originPosition: 'address',
    onCreate(){},
    onDelete(){},
    onChange(){}
}

const mapStateToProps = ({ line }) => {
    return line;
}

const mapDispatchToProps = {
    removeLineFromInterpretation
}


export default connect(mapStateToProps, mapDispatchToProps)(TargetLineOptions);