import React from 'react';
import { handleScrollTo } from '../../util/web';

class Hero2 extends React.Component {
    
    render() {
        return (
            <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
            <div className="text-center">
                <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                Analyze Golf Videos
                <br className="" />
                <span className="text-green-600">while you sleep</span>
                </h2>
                <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                Create lesson programs that will analyze the swings of golf students from anywhere in the world, 24 hours a day.
                </p>
                <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                <div className="rounded-md shadow">
                    <div onClick={() => handleScrollTo('get-started')} className="w-full flex items-center justify-center px-8 py-3 border border-transparent leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 transition duration-150 ease-in-out text-lg md:py-4 xl:text-xl md:text-lg md:px-10 cursor-pointer">
                    Click to Get Started
                    </div>
                </div>
                {/* <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                    <a href="#" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-green-600 bg-white hover:text-green-500 focus:outline-none focus:border-green-300 focus:shadow-outline-green transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                    Live demo
                    </a>
                </div> */}
                </div>
            </div>
            </main>

        );
    }
};

export default Hero2;