import * as actionTypes from '../actions/program/types';
import * as authActionTypes from '../actions/auth/actionTypes';

const initialState = {
    programs: null,
    getProgramsLoading: false,
    getProgramsError: false,

    programsForCampaign: null,
    getProgramsForCampaignLoading: false,
    getProgramsForCampaignError: false,

    programsData: null,
    getProgramDataLoading: false,
    getProgramDataError: false,

    createProgramLoading: false,
    createProgramSuccess: false,
    createProgramError: null,

    duplicateProgramLoading: false,
    duplicateProgramSuccess: false,
    duplicateProgramError: null,

    removeTargetFromProgramLoading: false,
    removeTargetFromProgramSuccess: null,
    removeTargetFromProgramError: null,

    removeAllTargetsFromProgramLoading: false,
    removeAllTargetsFromProgramSuccess: false,
    removeAllTargetsFromProgramError: null,
};

function api(state = initialState, action) {
    switch(action.type) {

        case authActionTypes.LOGOUT_USER:
            return {
                ...state,
                programs: null,
                getProgramsLoading: false,
                getProgramsError: false,
            
                programsForCampaign: null,
                getProgramsForCampaignLoading: false,
                getProgramsForCampaignError: false,
            
                programsData: null,
                getProgramDataLoading: false,
                getProgramDataError: false,
            
                createProgramLoading: false,
                createProgramSuccess: false,
                createProgramError: null,
            
                duplicateProgramLoading: false,
                duplicateProgramSuccess: false,
                duplicateProgramError: null,
            
                removeTargetFromProgramLoading: false,
                removeTargetFromProgramSuccess: false,
                removeTargetFromProgramError: null,
            
                removeAllTargetsFromProgramLoading: false,
                removeAllTargetsFromProgramSuccess: false,
                removeAllTargetsFromProgramError: null,
            }

        case actionTypes.GET_PROGRAMS:
            return {
                ...state,
                programs: null,
                getProgramsLoading: true,
                getProgramsError: false,
            };

        case actionTypes.GET_PROGRAMS_SUCCESS:
            return {
                ...state,
                programs: action.payload,
                getProgramsLoading: false,
                getProgramsError: false,
            };

        case actionTypes.GET_PROGRAMS_FAIL:
            return {
                ...state,
                programs: null,
                getProgramsLoading: false,
                getProgramsError: action.payload,
            };


        case actionTypes.GET_PROGRAMS_FOR_CAMPAIGN:
            return {
                ...state,
                programsForCampaign: null,
                getProgramsForCampaignLoading: true,
                getProgramsForCampaignError: false,
            };

        case actionTypes.GET_PROGRAMS_FOR_CAMPAIGN_SUCCESS:
            return {
                ...state,
                programsForCampaign: action.payload,
                getProgramsForCampaignLoading: false,
                getProgramsForCampaignError: false,
            };

        case actionTypes.GET_PROGRAMS_FOR_CAMPAIGN_FAIL:
            return {
                ...state,
                programsForCampaign: null,
                getProgramsForCampaignLoading: true,
                getProgramsForCampaignError: action.payload,
            };

        case actionTypes.GET_PROGRAM_DATA:
            return {
                ...state,
                programData: null,
                getProgramDataLoading: true,
                getProgramDataError: false,
            };

        case actionTypes.GET_PROGRAM_DATA_SUCCESS:
            return {
                ...state,
                programData: action.payload,
                getProgramDataLoading: false,
                getProgramDataError: false,
            };

        case actionTypes.GET_PROGRAM_DATA_FAIL:
            return {
                ...state,
                programData: null,
                getProgramDataLoading: false,
                getProgramDataError: action.payload,
            };

        case actionTypes.CREATE_PROGRAM:
            return {
                ...state,
                createProgramLoading: true,
                createProgramSuccess: false,
                createProgramError: null
            };

        case actionTypes.CREATE_PROGRAM_SUCCESS:
            return {
                ...state,
                createProgramLoading: false,
                createProgramSuccess: action.payload,
                createProgramError: null
            };

        case actionTypes.CREATE_PROGRAM_FAIL:
            return {
                ...state,
                createProgramLoading: false,
                createProgramSuccess: false,
                createProgramError: action.payload
            };

        case actionTypes.DUPLICATE_PROGRAM:
            return {
                ...state,
                duplicateProgramLoading: true,
                duplicateProgramSuccess: false,
                duplicateProgramError: null
            };

        case actionTypes.DUPLICATE_PROGRAM_SUCCESS:
            return {
                ...state,
                duplicateProgramLoading: false,
                duplicateProgramSuccess: action.payload,
                duplicateProgramError: null
            };

        case actionTypes.DUPLICATE_PROGRAM_FAIL:
            return {
                ...state,
                duplicateProgramLoading: false,
                duplicateProgramSuccess: false,
                duplicateProgramError: action.payload
            };

        case actionTypes.REMOVE_TARGET_RESET:
            return {
                ...state,
                removeTargetFromProgramLoading: false,
                removeTargetFromProgramSuccess: null,
                removeTargetFromProgramError: null
            };

        case actionTypes.REMOVE_TARGET:
            return {
                ...state,
                removeTargetFromProgramLoading: true,
                removeTargetFromProgramSuccess: null,
                removeTargetFromProgramError: null
            };

        case actionTypes.REMOVE_TARGET_SUCCESS:
            return {
                ...state,
                removeTargetFromProgramLoading: false,
                removeTargetFromProgramSuccess: action.payload,
                removeTargetFromProgramError: null
            };

        case actionTypes.REMOVE_TARGET_FAIL:
            return {
                ...state,
                removeTargetFromProgramLoading: false,
                removeTargetFromProgramSuccess: false,
                removeTargetFromProgramError: action.payload
            };

        case actionTypes.REMOVE_TARGETS:
            return {
                ...state,
                removeAllTargetsFromProgramLoading: true,
                removeAllTargetsFromProgramSuccess: false,
                removeAllTargetsFromProgramError: null
            };

        case actionTypes.REMOVE_TARGETS_SUCCESS:
            return {
                ...state,
                removeAllTargetsFromProgramLoading: false,
                removeAllTargetsFromProgramSuccess: action.payload,
                removeAllTargetsFromProgramError: null
            };

        case actionTypes.REMOVE_TARGETS_FAIL:
            return {
                ...state,
                removeAllTargetsFromProgramLoading: false,
                removeAllTargetsFromProgramSuccess: false,
                removeAllTargetsFromProgramError: action.payload
            };
        default:
            return state;
    }
}

export default api;