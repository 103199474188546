import * as services from './services';
import * as actionCreators from './actions';

/**
 * getLicenseeCampaignsByLicenseeId
 * @param {int} licenseeId 
 */
export const getLicenseeCampaignsByLicenseeId = (licenseeId) => dispatch => {
    dispatch(actionCreators.getLicenseeCampaignsByLicenseeId(licenseeId));
    return services.getLicenseeCampaignsByLicenseeId(licenseeId)
        .then(data => dispatch(actionCreators.getLicenseeCampaignsByLicenseeIdSuccess(data.data)))
        .catch(e => dispatch(actionCreators.getLicenseeCampaignsByLicenseeIdFail(e)));
}
/**
 * createLicenseeCampaign (Lesson)
 * This method will create TWO programs to associate with the campaign
 * 1 for Face on
 * 1 for Down the Line 
 * @param {*} name 
 */
export const createLicenseeCampaign = (licenseeId, userId, name, description="", history) => dispatch => {
    dispatch(actionCreators.createLicenseeCampaign(licenseeId, userId, name, description=""));
    return services.createLicenseeCampaign(licenseeId, userId, name, description="")
        .then(data => {
            dispatch(actionCreators.createLicenseeCampaignSuccess(data.data));
            if (data) {
                // now redirect the user to the lesson program page?
                history.push({
                    pathname: `/lessons/${data.data.licensee_campaign_id}`,
                    state: {
                        licenseeCampaignId: data.data.licensee_campaign_id
                    }
                });
            }
        }).catch(e => dispatch(actionCreators.createLicenseeCampaignFail(e)));
}

/**
 * updateLicenseeCampaign
 * 
 * We want the user to be able to update the metadata surrounding the licensee campaign
 * 
 * @param {int} licenseeId the licensee id
 * @param {int} licenseeCampaignId the licensee campaign id we are updating 
 * @param {string} title the title of the campaign
 * @param {float} price the price of the campaign
 * @param {string} licenseeCampginType the status of the campaign (PUBLIC|PRIVATE)
 * @param {string} description the description of the campaign
 * @param {object} history the navigation object
 * @returns 
 */
export const updateLicenseeCampaign = (licenseeId, licenseeCampaignId, title, price, licenseeCampaignType, status, lessonOutput, endpoint, description, history) => dispatch => {
    dispatch(actionCreators.updateLicenseeCampaign(licenseeId, licenseeCampaignId, title, price, licenseeCampaignType, status, lessonOutput, endpoint, description));
    return services.updateLicenseeCampaign(licenseeId, licenseeCampaignId, title, price, licenseeCampaignType, status, lessonOutput, endpoint, description, history)
        .then(data => {
            dispatch(actionCreators.updateLicenseeCampaignSuccess(data.data));
            if (data) {
                // dispatch(actionCreators.getLicenseeCampaignsByLicenseeId(licenseeId));
                // // now redirect the user to the lesson program page?
                // history.push({
                //     pathname: `/lessons/${data.data.licensee_campaign_id}`,
                //     state: {
                //         licenseeCampaignId: data.data.licensee_campaign_id
                //     }
                // });
            }
        }).catch(e => dispatch(actionCreators.updateLicenseeCampaignFail(e)));
}
/**
 * duplicateLessonCampaign
 * 
 * @param {int} licenseeCampaignId 
 * @param {int} licenseeId 
 * @param {int} userId 
 */
export const duplicateLicenseeCampaign = (licenseeCampaignId, licenseeId, userId, status = 'ACTIVE', history) => dispatch => {
    dispatch(actionCreators.duplicateLicenseeCampaign(licenseeCampaignId, licenseeId, userId, status));
    return services.duplicateLicenseeCampaign(licenseeCampaignId, licenseeId, userId, status)
        .then(data => {
            dispatch(actionCreators.duplicateLicenseeCampaignSuccess(data.data));
            console.log(data.data.licenseeCampaign.copy.licenseeCampaignDuplicateId);
            const copyId = data.data.licenseeCampaign.copy.licenseeCampaignDuplicateId;
            if (history) {
                // we want to refresh the page to show the lesson program!
                // history.go(0);
                history.push({
                    pathname: `/lessons/${copyId}`,
                });
            }
        })
        .catch(e => dispatch(actionCreators.duplicateLicenseeCampaignFail(e)));
}

/**
 * updateLicenseeCampaignStatus
 * Update the status of a campaign (INACTIVE|ACTIVE)
 * 
 * @param {int} licenseeCampaignId the licensee campaign id
 * @param {int} licenseeId the licensee id
 * @param {string} status the status of the campaign
 * @param {object} history the history navigation object
 */
export const updateLicenseeCampaignStatus = (licenseeCampaignId, licenseeId, status, history) => dispatch => {
    dispatch(actionCreators.updateLicenseeCampaignStatus(licenseeCampaignId, licenseeId, status));
    return services.updateLicenseeCampaignStatus(licenseeCampaignId, licenseeId, status)
        .then(data => {
            dispatch(actionCreators.updateLicenseeCampaignStatusSuccess(data.data));
            if (history) {
                history.go(-1);
            }
        })
        .catch(e => dispatch(actionCreators.updateLicenseeCampaignStatusFail(e)));
}

export const resetUpdateLicenseeCampaignStatus = () => dispatch => {
    dispatch(actionCreators.resetUpdateLicenseeCampaignStatus());
}

export const resetCreateLicenseeCampaign = () => dispatch => {
    dispatch(actionCreators.resetCreateLicenseeCampaign());
}

export const resetUpdateLicenseeCampaign = () => dispatch => {
    dispatch(actionCreators.resetUpdateLicenseeCampaign());
}

export const resetDuplicateLicenseeCampaign = () => dispatch => {
    dispatch(actionCreators.resetDuplicateLicenseeCampaign());
}


export const sendToWebhook = (data, endpoint) => dispatch => {
    dispatch(actionCreators.sendToWebhook(data, endpoint));
    return services.sendToWebhook(data, endpoint)
        .then(data => dispatch(actionCreators.sendToWebhookSuccess(data)))
        .catch(e => dispatch(actionCreators.sendToWebhookFail(e)));
};

export const sendToWebhookReset = () => dispatch => {
    dispatch(actionCreators.sendToWebhookReset());
};