import React from 'react';
import { connect } from 'react-redux';
import Button from '../common/Button';
import Container from '../tailwind/Container';
// import { selectors as authSelectors } from '../../reducers/auth';

class ConfirmEmailPage extends React.Component {

    render() {
        return (
            <div className="min-h-screen bg-gray-800 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <Container>
                    <h1 className="font-bold text-2xl">Thank you for confirming your email.</h1>
                    <p>You are all set!</p>
                    <div className="py-4">
                        <Button 
                        size="lg"
                        bgColor="green"
                        title="Back to Login"
                        onClick={() => this.props.history.push({ pathname: '/login' })}
                        />
                    </div>
                    </Container>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {}

export default connect(null, mapDispatchToProps)(ConfirmEmailPage);