import { swingbotApi } from '../../util/api';

export const createTarget = (programId, userId, displayName, swingTypeId, textDefinition) => {
    return swingbotApi(
            'target', 
            { programId, userId, displayName, swingTypeId, textDefinition }, 
            'post', 
            true, 
            'licensee-2'
        )
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const getTargetsForUser = (userId) => {
    return swingbotApi(`user/${userId}/target`, {}, 'get', true, 'licensee-2')
        .then(response => {
            return response;
        })
        .catch(err => Promise.reject(err));
};

export const addTargetFromLibrary = (targetId, programId, userId) => {
    return swingbotApi(`library/target`, { targetId, programId, userId }, 'post', true, 'licensee-2')
        .then(response => response)
        .catch(err => Promise.reject(err));
};



