import React from 'react';
import BeatLoader from "react-spinners/BeatLoader";

const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
`;
class Loading extends React.Component {
    render() {
        const { showSpinner, textSizeClass, textColorClass } = this.props;
        return (
            <div className={`w-full p-6 ${textSizeClass} ${textColorClass} font-bold flex justify-center items-center flex-col`}>
                <div className="p-4">
                    {this.props.title}
                </div>
                {showSpinner === true && (
                    <BeatLoader
                    css={override}
                    size={15}
                    color={"green"}
                    loading={true}
                    />
                )}
            </div>
        );
    }
}

Loading.defaultProps = {
    title: 'Loading',
    textSizeClass: 'text-xl',
    textColorClass: 'text-gray-700',
    showSpinner: true,
}

export default Loading;