import React from 'react';
import QualifyWizard from '../common/wizard/qualify/QualifyWizard';
import './GetStarted.css';
class GetStarted extends React.Component {
    handleSubmitWizard = (data) => {
    }

    render() {
        return (
        <div id="get-started" className="relative bg-gray-800 overflow-hidden">
            <div className="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
                <div className="max-w-screen-lg mx-auto px-4 sm:px-6">
                <div className="pt-12 sm:pt-16 lg:pt-24">
                    <div className="max-w-screen-xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                    <div className="max-w-3xl mx-auto lg:max-w-none">
                        <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
                       Limited Availability
                        </h2>
                        <p className="mt-2 text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
                        Join the Program
                        </p>
                        <p className="mt-4 text-xl leading-7 text-gray-300">
                        We are currently accepting applications from golf instructors to participate. 
                        </p>
                    </div>
                    </div>
                </div>
                    <div className="px-0 lg:px-10">
                        <div className="p-10">
                            <QualifyWizard onSubmit={this.handleSubmitWizard} />
                        </div>
                    </div>
                    {/* <div className="px-0 lg:px-10">
                    <div id="mauticform_wrapper_interest" className="mauticform_wrapper">
                    <form autoComplete="false" method="post" action="https://mautic.swingbotpro.com/mautic/form/submit?formId=2" id="mauticform_interest" data-mautic-form="interest" encType="multipart/form-data" name="form-interest">
                        <div className="mauticform-error" id="mauticform_interest_error"></div>
                        <div className="mauticform-message" id="mauticform_interest_message"></div>
                        <div className="mauticform-innerform">
                        <div className="mauticform-page-wrapper mauticform-page-1" data-mautic-form-page="1">

                            <div id="mauticform_interest_first_name" data-validate="first_name" data-validation-type="text" className="mauticform-row mauticform-text mauticform-field-1 mauticform-required">
                                <label id="mauticform_label_interest_first_name" htmlFor="mauticform_input_interest_first_name" className="mauticform-label">First Name</label>
                                <input id="mauticform_input_interest_first_name" name="mauticform[first_name]" className="mauticform-input" type="text" />
                                <span className="mauticform-errormsg">Please enter your First Name</span>
                            </div>

                            <div id="mauticform_interest_last_name" data-validate="last_name" data-validation-type="text" className="mauticform-row mauticform-text mauticform-field-2 mauticform-required">
                                <label id="mauticform_label_interest_last_name" htmlFor="mauticform_input_interest_last_name" className="mauticform-label">Last Name</label>
                                <input id="mauticform_input_interest_last_name" name="mauticform[last_name]" className="mauticform-input" type="text" />
                                <span className="mauticform-errormsg">Please enter your Last Name</span>
                            </div>

                            <div id="mauticform_interest_email_address" data-validate="email_address" data-validation-type="email" className="mauticform-row mauticform-email mauticform-field-3 mauticform-required">
                                <label id="mauticform_label_interest_email_address" htmlFor="mauticform_input_interest_email_address" className="mauticform-label">Email Address</label>
                                <input id="mauticform_input_interest_email_address" name="mauticform[email_address]" placeholder="your@email.com" className="mauticform-input" type="email" />
                                <span className="mauticform-errormsg">Please enter your email</span>
                            </div>

                            <div id="mauticform_interest_company_name" className="mauticform-row mauticform-text mauticform-field-4">
                                <label id="mauticform_label_interest_company_name" htmlFor="mauticform_input_interest_company_name" className="mauticform-label">Company Name</label>
                                <input id="mauticform_input_interest_company_name" name="mauticform[company_name]" className="mauticform-input" type="text" />
                                <span className="mauticform-errormsg"></span>
                            </div>

                            <div id="mauticform_interest_submit" className="mauticform-row mauticform-button-wrapper mauticform-field-5">
                                <button type="submit" name="mauticform[submit]" id="mauticform_input_interest_submit" value="" className="mauticform-button btn btn-default">Submit</button>
                            </div>
                            </div>
                        </div>

                        <input type="hidden" name="mauticform[formId]" id="mauticform_interest_id" value="2" />
                        <input type="hidden" name="mauticform[return]" id="mauticform_interest_return" value="" />
                        <input type="hidden" name="mauticform[formName]" id="mauticform_interest_name" value="interest" />

                        </form>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
        );
    }
}

export default GetStarted;