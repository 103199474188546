import React from 'react';
import ImageWithError from '../../common/ImageWithError';
import { LessonStatusBubble } from '../../common';
class VideoPanel extends React.Component {
  render() {
    const { record, onVideoClick, onUserClick } = this.props;
    return (
      <div className="col-span-1 bg-white rounded-lg shadow overflow-hidden" key={`video-${record.videoData.video_id}`}>
          <div onClick={() => onVideoClick(record.videoData.video_id, record)} className="relative flex bg-gray-800 pb-11/12 overflow-hidden cursor-pointer">
              <ImageWithError 
                  className="absolute w-full object-cover overflow-hidden" 
                  src={record.videoData.zen_thumb} 
                  alt={record.credit_code}
              />
              <div className="flex flex-row relative top-0 right-0 p-2 w-full justify-start">
                <LessonStatusBubble record={record} />
              </div>
          </div>
          <div className="p-6">
              <h3 className="text-lg font-bold text-gray-700">{record['licensee_campaign_display_name']}</h3>
              <h4 className="font-semibold leading-tight truncate text-sm text-gray-500">{record.credit_code }</h4>
              <div className="text-xs text-gray-500">
                  <div onClick={() => onUserClick(record.videoData.user_id)} className="underline text-gray-600 cursor-pointer">
                      {record.email}
                  </div>
              </div>
          </div>
      </div>
    );
  }
}

VideoPanel.defaultProps = {
  record: null,
  index: 0,
  onVideoClick(){},
  onUserClick(){}
}

export default VideoPanel;