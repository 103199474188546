import React from 'react';
import { connect } from 'react-redux';
import { convertDateTimeToDate, isEarlierThanToday } from '../../../util/dates';
import { capitalizeFirstLetter } from '../../../util/strings';
import { selectors as authSelectors } from '../../../reducers/auth';

class LessonTargetsBanner extends React.Component {
  renderBillingInfo = () => {
    try {
        const { planInformation } = this.props;
        
        if (!planInformation.subscriptionId) throw new Error("No subscription found");

        console.log('plan information ', planInformation);
        // Braintree payments apparently stores dates in Central time, so we have to convert them
        const dateNextBilling = convertDateTimeToDate(planInformation.planNextBillingDate, 'America/Chicago', 'America/New_York').toLocaleDateString();
        const datePaidThrough = convertDateTimeToDate(planInformation.planPaidThroughDate, 'America/Chicago', 'America/New_York').toLocaleDateString();
        console.log('DATES ', dateNextBilling, datePaidThrough, new Date().toLocaleDateString());
        const isPaidUp = isEarlierThanToday(convertDateTimeToDate(planInformation.planPaidThroughDate));
        console.log('is paid up ', isPaidUp);
        const planFeatureName = planInformation.planFeatureName;
        const planName = planInformation.planName;
        const planTerm = capitalizeFirstLetter(planInformation.term);
        const planStatus = planInformation.subscriptionStatus;
        // we have to determine the FREE plan because there is no billing 
        // and no renewal
        const planString = planName.substring(0,4).toLowerCase() === 'free'
            ? `${planName} ${planFeatureName}`
            : `${planName} ${planFeatureName} ${planTerm}`;
        const isFreePlan = planName.substring(0,4).toLowerCase() === 'free';
        const planBgColor = planStatus === true ? 'bg-green-50':'bg-red-200 text-white';
        const planTextColor = planStatus === true ? 'text-green-500':'text-red-500';
        return (
            <div className={`rounded-md ${planBgColor} p-4 shadow mb-4 w-full`}>
                <div className="flex">
                    <div className="flex-shrink-0">
                    <svg className={`h-5 w-5 ${planTextColor}`} viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                    </svg>
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                    
                    </div>
                </div>
            </div>
        );
    } catch (e) {
        return null;
    }
}
  render() {
    return this.renderBillingInfo();
  }
}


const mapStateToProps = ({ auth, plan, licensee, subscribe }) => {
  const { authUser } = auth;
  const {
      plans,
      getPlansSuccess,
      getPlansError,
      getPlansLoading
  } = plan;
  const {
      clientToken,
      subscriptionSuccess,
      cancelSubscriptionLoading,
      cancelSubscriptionSuccess,
      cancelSubscriptionError,
  } = subscribe;
  return {
      planInformation: authSelectors.planInformation(auth),
      // licenseeData: licenseeSelectors.licenseeData(licensee),
      authUser,
      plans,
      getPlansError,
      getPlansSuccess,
      getPlansLoading,
      clientToken,
      subscriptionSuccess,
      cancelSubscriptionLoading,
      cancelSubscriptionSuccess,
      cancelSubscriptionError,
  };
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(LessonTargetsBanner);