import React from 'react';
// import { getOperandByPseudo } from '../../../util/swingbot';
import Button from '../Button';
class TargetCodes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newCondition: null,
        }
    }

    handleCodeChange = (e) => {
        const v = e.target.value;
        if (v !== '') {
            if (v !== 'pass' && v !== 'fail') {
                this.props.onSelectCode(v);
            } else {
                this.props.onCreateCode(v);
            }
        }
    }

    handleCreateNewCondition = (type) => {
        this.setState({
            newCondition: type
        });
    }

    /**
     * renderCodePills
     * @param string type the type of pass/fail
     */
    renderCodesPills = (type=null) => {
        const { code, targetInterpretationData } = this.props;
        return targetInterpretationData !== undefined && targetInterpretationData !== null
            ? Object.keys(targetInterpretationData.equations)
                .map(c => {
                    const fail = c.substr(0,1).toUpperCase() === 'F';
                    const selected = code === c ? (fail === true ? "red":"green") : "lightgray";
                return (
                    <Button 
                        bgColor={selected}
                        hoverColor={selected}
                        outlineColor={'transparent'}
                        size="small"
                        key={`k-${c}`}
                        onClick={() => this.props.onSelectCode(c)}  
                        title={this.renderCodeFormat(c, targetInterpretationData.equations[c][0])}
                        className="mr-2"
                     />
                );
            }) : null;
    }

    renderCodeFormat = (code) => {
        const name =  code.substr(0,1).toUpperCase() === 'F'
            ? `Failing Condition ${code.substr(1,1)}`
            : `Passing Condition ${code.substr(1,1)}`
        return name;
    }

    renderCodeMenu = () => {
        const { code, targetInterpretationData } = this.props;
        return (
            <select 
                value={code} 
                className={` text-xl block form-select transition duration-150 ease-in-out sm:text-xl sm:leading-5 w-full bg-white text-gray-800 p-6`}
                onChange={this.handleCodeChange}
            >
                <option key={'code-select-1'} value="">Select a Target Condition</option>
                <option key={'code-select-2'} value="">-----------</option>
                <option key={'code-select-3'} value="pass">Create New Passing Condition</option>
                <option key={'code-select-4'} value="fail">Create New Failing Condition</option>
                <option key={'code-select-5'} value="">-----------</option>
                {targetInterpretationData !== undefined && targetInterpretationData !== null
                    ? Object.keys(targetInterpretationData.equations)
                        .sort()
                        .map(c => {
                            return (
                                <option key={`code-select-${c}`} value={c}>{this.renderCodeFormat(c, targetInterpretationData.equations[c][0])}</option>
                            );
                        })
                    : null
                }
            </select>
        );
    }

    renderTabs = () => {
        return(
            <div class="hidden sm:block">
                <div class="border-b border-gray-200">
                    <nav class="-mb-px flex">
                        {this.renderConditionTabs()}
                    </nav>
                </div>
            </div>
        );
    }

    renderConditionTabs = () => {
        const { code, targetInterpretationData } = this.props;
        const selectedTabClass = "whitespace-no-wrap py-4 px-1 border-b-2 border-green-600 font-medium text-base leading-5 text-gray-700 hover:text-gray-700 hover:border-green-600  focus:border-gray-300 ml-2";
        const unselectedTabClass = "whitespace-no-wrap py-4 px-1 border-b-2 border-transparent font-medium text-base leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 cursor-pointer ml-2";
        return targetInterpretationData !== undefined && targetInterpretationData !== null
            ? Object.keys(targetInterpretationData.equations)
                .sort()
                .reverse()
                .map(c => {
                    return (
                        <div 
                            onClick={() => this.props.onSelectCode(c)} 
                            className={`${code === c ? selectedTabClass : unselectedTabClass}`}
                        >
                            {this.renderCodeFormat(c)}
                        </div>
                    )
                })
            : null;
    }

    render() {
        const { newCondition, readOnly } = this.props;
        const fail = newCondition !== null 
            ? newCondition.code.substr(0,1).toUpperCase() === 'F'
            : false;
        return (
            <div className="flex flex-wrap items-center mb-4 w-full">
                {/* dropdown to choose which condition */}
                {newCondition === null && readOnly === false && (
                    <div className="flex flex-row space-x-2 mr-2">
                    <Button 
                        bgColor={'lightgray'}
                        hoverColor="green"
                        size="small"
                        key={`k-${newCondition}`}
                        onClick={() => this.props.onCreateCode('pass')}  
                        title={'Passing'}
                        iconLeft={"new"}
                        tooltip="New Passing Condition"
                    />
                    <Button 
                        bgColor="lightgray"
                        hoverColor="red"
                        size="small"
                        key={`k-new`}
                        onClick={() => this.props.onCreateCode('fail')}  
                        title={'Failing'}
                        iconLeft={"new"}
                        tooltip="New Failing Condition"
                    />
                    </div>
                )}
                {newCondition !== null && (
                    <Button 
                        bgColor={fail === true ? "red":"green"}
                        hoverColor={fail === true ? "red":"green"}
                        size="small"
                        key={`k-${newCondition.code}`}
                        title={newCondition.name}
                        disabled
                    />  
                )}
                {this.renderCodesPills()}
            </div>
        );
    }
}

TargetCodes.defaultProps = { 
    code: 1,
    targetInterpretationData: null,
    newCondition: null,
    readOnly: true,
    onSelectCode(){},
    onCreateCode(){}
}

export default TargetCodes;