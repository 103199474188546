export const CREATE_BRAINTREE_CUSTOMER = 'subscribe/create-braintree-customer';
export const CREATE_BRAINTREE_CUSTOMER_SUCCESS = 'subscribe/create-braintree-customer-success';
export const CREATE_BRAINTREE_CUSTOMER_FAIL = 'subscribe/create-braintree-customer-fail';
export const CREATE_BRAINTREE_CUSTOMER_RESET = 'subscribe/create-braintree-customer-reset';

export const SUBSCRIBE_TO_PLAN = 'subscribe/subscribe-to-plan';
export const SUBSCRIBE_TO_PLAN_SUCCESS = 'subscribe/subscribe-to-plan-success';
export const SUBSCRIBE_TO_PLAN_FAIL = 'subscribe/subscribe-to-plan-fail';
export const SUBSCRIBE_TO_PLAN_RESET = 'subscribe/subscribe-to-plan-reset';

export const UPDATE_SUBSCRIPTION_PLAN = 'subscribe/update-subscription-plan';
export const UPDATE_SUBSCRIPTION_PLAN_SUCCESS = 'subscribe/update-subscription-plan-success';
export const UPDATE_SUBSCRIPTION_PLAN_FAIL = 'subscribe/update-subscription-plan-fail';
export const UPDATE_SUBSCRIPTION_PLAN_RESET = 'subscribe/update-subscription-plan-reset';

export const GET_BRAINTREE_CLIENT_TOKEN = 'subscribe/get-braintree-client-token';
export const GET_BRAINTREE_CLIENT_TOKEN_SUCCESS = 'subscribe/get-braintree-client-token-success';
export const GET_BRAINTREE_CLIENT_TOKEN_FAIL = 'subscribe/get-braintree-client-token-fail';
export const GET_BRAINTREE_CLIENT_TOKEN_RESET = 'subscribe/get-braintree-client-token-reset';

export const GET_SUBSCRIPTIONS = 'subscribe/get-subscriptions';
export const GET_SUBSCRIPTIONS_SUCCESS = 'subscribe/get-subscriptions-success';
export const GET_SUBSCRIPTIONS_FAIL = 'subscribe/get-subscriptions-fail';
export const GET_SUBSCRIPTIONS_RESET = 'subscribe/get-subscriptions-reset';

export const CANCEL_SUBSCRIPTION = 'subscribe/cancel-subscriptions';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'subscribe/cancel-subscriptions-success';
export const CANCEL_SUBSCRIPTION_FAIL = 'subscribe/cancel-subscriptions-fail';
export const CANCEL_SUBSCRIPTION_RESET = 'subscribe/cancel-subscriptions-reset';