import * as services from './services';
import * as actionCreators from './actions';


export const updateWebhookForLicenseeCampaign = (licenseeCampaignId, endpoint) => dispatch => {
    dispatch(actionCreators.updateWebhookForLicenseeCampaign(licenseeCampaignId, endpoint));
    return services.updateWebhookForLicenseeCampaign(licenseeCampaignId, endpoint)
        .then(data => dispatch(actionCreators.updateWebhookForLicenseeCampaignSuccess(data.data)))
        .catch(e => dispatch(actionCreators.updateWebhookForLicenseeCampaignFail(e)));
}

export const updateWebhooksForLicensee = (licenseeId, endpoint) => dispatch => {
    dispatch(actionCreators.updateWebhooksForLicensee(licenseeId, endpoint));
    return services.updateWebhooksForLicensee(licenseeId, endpoint)
        .then(data => dispatch(actionCreators.updateWebhooksForLicenseeSuccess(data.data)))
        .catch(e => dispatch(actionCreators.updateWebhooksForLicenseeFail(e)));
}
