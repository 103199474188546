import React from 'react';
import { withRouter } from 'react-router-dom';
import Register from '../auth/Register';

class RegisterPage extends React.Component {

    handleRegisterSuccess = (res) => {
        this.props.history.push('/overview');
    }

    handleRegisterError = (e) => {
    }

    render() {
        return(
            <Register />
        );
    }
}

export default withRouter(RegisterPage);