import React from 'react';
import { connect } from 'react-redux';
import { DashboardContent, Container } from '../../tailwind';
import { 
    getWebsiteForLicensee, 
    createWebsiteForLicensee, 
    updateWebsiteForLicensee, 
    resetStripeConnectedAccount,
    getStripeConnectedAccount,
    createStripeConnectedAccount,
    linkStripeConnectedAccount
} from '../../../actions/website';
import { selectors as websiteSelectors } from '../../../reducers/website';
import Loading from '../../common/Loading';
import { Button, LearnMore } from '../../common';
import { Banner } from '../../common/banner';
import StripeButton from '../../common/stripe/StripeButton';

class WebsitePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            website: null,
            selectedMainTab: 1,
            stripeEmail: null,
            changeStripeEmail: false,
            subdomain: this.initializeSubdomainName()
        }
    };

    initializeSubdomainName = () => {
        const { authUser, website } = this.props;
        console.log("website: ", website);
        return website !== null 
            ? website.subdomain
            : this.sanitizeUrl(`${authUser.f_name}-${authUser.l_name}`);
    }

    isValidSubdomain = (value) => {
        const invalid = [
            'swingbot',
            'golf',
            'swing',
            'lessons',
            'api',
            'blog',
            'docs',
            'account'
        ];
        return value.length > 4 && invalid.indexOf(value) < 0;
    }

    initForm = () => {
        const { website, authUser } = this.props;
        if (website !== null) {
            this.setState({
                website: website
            });
        } else {
            this.props.getWebsiteForLicensee(authUser.licensee_id);
        }
    }

    componentDidMount = () => {
        const { authUser } = this.props;
        console.log('history ', this.props.history);
        const licenseeId = authUser.licensee_id;
        this.props.resetStripeConnectedAccount();
        this.props.getWebsiteForLicensee(licenseeId);
    }

    componentDidUpdate = (nextProps) => {
        const { website, authUser } = this.props;
        if (website !== null && website !== nextProps.website && this.state.website === null) {
            this.initForm();
            console.log(website);
            if (website.stripe_acct_id !== null && website.stripe_acct_id !== '') {
                const sandbox = process.env.REACT_APP_ENV === 'development';
                console.log('getting connected account');
                this.props.getStripeConnectedAccount(
                    authUser.licensee_id, 
                    website.stripe_acct_id, 
                    sandbox
                );
            }
        }
    }

    sanitizeUrl = (url) => {
        return url.replace(/[^\w\s]/gi, '').toLowerCase().trim();
    }

    handleChangeDomain = (e) => {
        console.log(e.target.value);
        this.setState({
            subdomain: this.sanitizeUrl(e.target.value)
        });
    }

    handleCreateWebsite = () => {
        const { authUser, history } = this.props;
        const { subdomain } = this.state;
        const licenseeId = authUser.licensee_id;
        const displayName = `${authUser.f_name} ${authUser.l_name}`;
        const subdomainURL = `${subdomain}`.toLowerCase();

        console.log(subdomainURL);
        if (this.isValidSubdomain(subdomain) === true) {
            this.props.createWebsiteForLicensee(subdomainURL, licenseeId, displayName, history);
        } else {
            console.log('Must be more than 5 characters');
        }
    }

    handleCopyToClipboard = () => {
        /* Get the text field */
        var copyText = document.getElementById("siteUrl");
        /* Select the text field */
        copyText.select(); 
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/
        /* Copy the text inside the text field */
        document.execCommand("copy");
        /* Alert the copied text */
        alert("Copied the text: " + copyText.value);
    }

    handleVisitWebsite = (url) => {
        window.open(url, '_blank');
    }

    handleFieldChange = (e) => {
        let w = this.state.website;
        w[e.target.id] = e.target.value;
        this.setState({
            website: w
        });
    }

    handleSaveChanges = () => {
        const {
            title, theme_color, logo_url, job_title, display_name, twitter_url, linkedin_url, profile_image_url, bio, licensee_id
        } = this.state.website;
        this.props.updateWebsiteForLicensee(title, theme_color, logo_url, job_title, display_name, twitter_url, linkedin_url, profile_image_url, bio, licensee_id);
    }

    handleConnectWithStripeClick = () => {
        const { authUser, stripeAccount} = this.props;
        const { stripeEmail } = this.state;
        // lets use the user entered email if the user has changed it.
        // const email = stripeEmail !== null ? stripeEmail : authUser.email;
        const email = stripeEmail === null 
            ? (stripeAccount.success !== null ? stripeAccount.success.email : authUser.email) 
            : stripeEmail;

        const sandbox = process.env.REACT_APP_ENV === 'development';
        this.setState({
            changeStripeEmail: false,
        });
        this.props.createStripeConnectedAccount(authUser.licensee_id, email, sandbox);
    }

    handleLinkWithStripeClick = () => {
        const { authUser, stripeAccount, stripeConnect } = this.props;
        const account = stripeAccount.success !== null 
            ? stripeAccount.success 
            : (stripeConnect.success !== null ? stripeConnect.success : null);
        const sandbox = process.env.REACT_APP_ENV === 'development';
        this.props.linkStripeConnectedAccount(
            authUser.licensee_id, 
            account.id,
            sandbox
        );
    }

    handleStripeEmailChange = (e) => {
        this.setState({
            stripeEmail: e.target.value,
        });
    }

    handleChangeStripeClick = () => {
        this.setState({
            changeStripeEmail: true,
        });
    }

    handleCancelChangeStripeClick = () => {
        this.setState({
            changeStripeEmail: false,
        });
    }

    renderFormComplete = () => {
        const { website } = this.state;
        if (website !== null) {
            Object.keys(website).forEach(k => {
                if (website[k] === null) {
                    website[k] = '';
                }
            });
        }

        return website !== null ? (
            <div>
            <div>
                <div className="border-gray-200">
                <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Website Configuration
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                    Fill in the fields for your website display/template. <LearnMore docPage="/doc/website" />
                    </p>
                </div>

                <div className="mt-6 sm:mt-5">

                    <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="title" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                            Title
                        </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                        <input id="title" value={website['title']} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" onChange={this.handleFieldChange} />
                        </div>
                    </div>
                    </div>

                    <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="theme_color" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                        Theme Color
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                        <select id="theme_color" className="block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" value={website['theme_color']} onChange={this.handleFieldChange}>
                            <option value="green">Green</option>
                            <option value="blue">Blue</option>
                            <option value="gray">Gray</option>
                        </select>
                        </div>
                    </div>
                    </div>

                    <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="logo_url" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                        Logo URL
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                        <input id="logo_url" value={website['logo_url']} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" onChange={this.handleFieldChange} />
                        </div>
                    </div>
                    </div>

                    <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="profile_image_url" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                        Profile Image URL
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                        <input id="profile_image_url" value={website['profile_image_url']} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" onChange={this.handleFieldChange} />
                        </div>
                    </div>
                    </div>

                    <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="twitter_url" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                        Twitter URL
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                        <input id="twitter_url" value={website['twitter_url']} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" onChange={this.handleFieldChange} />
                        </div>
                    </div>
                    </div>

                    <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="linkedin_url" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                        LinkedIn URL
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                        <input id="linkedin_url" value={website['linkedin_url']} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" onChange={this.handleFieldChange} />
                        </div>
                    </div>
                    </div>

                    <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="display_name" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                        Display Name (your name)
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                        <input id="display_name" value={website['display_name']} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" onChange={this.handleFieldChange} />
                        </div>
                    </div>
                    </div>

                    <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="job_title" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                        Job Title
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                        <input id="job_title" value={website['job_title']} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" onChange={this.handleFieldChange} />
                        </div>
                    </div>
                    </div>

                    <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="bio" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                            Profile Bio
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="max-w-lg flex rounded-md shadow-sm">
                            <textarea id="bio" rows="3" className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" value={website['bio']} onChange={this.handleFieldChange}></textarea>
                            </div>
                            <p className="mt-2 text-sm text-gray-500">Write a few sentences about yourself.</p>
                        </div>
                        </div>

                </div>
                </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-5">
                <div className="flex justify-end">
                        <Button 
                            onClick={this.handleSaveChanges}
                            title="Save Changes"
                            bgColor="green"
                            hoverColor="green"
                            block
                            size="lg"
                        />
                </div>
            </div>
        </div>
        ) : null;
    };


    renderMainTabsSide = () => {
        const { selectedMainTab } = this.state;
        const currentClass = "font-bold bg-gray-100 text-gray-700 flex items-center px-3 py-2 text-sm font-medium rounded-md";
        const defaultClass = "cursor-pointer text-gray-600 hover:bg-gray-50 hover:text-gray-900 flex items-center px-3 py-2 text-sm font-medium rounded-md";
        return (
            <div className="bg-white text-sm rounded-lg shadow p-4">
                <div className="">
                    <nav className="-mb-px flex flex-col">
                        <div className={selectedMainTab === 1 ? currentClass : defaultClass } onClick={() => this.setState({ selectedMainTab: 1 })}>
                            Site Information
                        </div>
                        <div className={selectedMainTab === 2 ? currentClass : defaultClass } onClick={() => this.setState({ selectedMainTab: 2 })}>
                            Accept Payments
                        </div>
                    </nav>
                </div>
            </div>
        );
    }

    renderHasNoStripeAccount = () => {
        const { website, stripeConnect, authUser } = this.props;
        const { stripeEmail } = this.state;
        const email = stripeEmail === null ? authUser.email : stripeEmail;
        return website !== null ? (
            <div className="w-full">
                {website !== null && stripeConnect.loading === true && <Loading title="Creating Stripe Account" />}
                {website !== null && stripeConnect.loading === false && stripeConnect.success === null && (
                    <div className="w-full">
                        <div className="border-gray-200 w-full">
                            <div className="">
                                <Banner
                                    text="SwingbotPro will automatically accept payments from your students directly into your Stripe account."
                                    color="indigo"
                                    textColor="white"
                                />
                            </div>
                            <div className="">
                                <label htmlFor="last_name" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2 mb-2">
                                    Email Address<br /><span className="text-sm text-gray-500">If you already have a Stripe account you wish to use please enter it below.</span>
                                </label>
                                <div className="mt-1 sm:mt-0">
                                    <div className="flex flex-row space-x-2 rounded-md shadow-sm">
                                        <input id="stripe_email" value={email} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 mb-2 bg-indigo-200" onChange={this.handleStripeEmailChange} />
                                        <StripeButton onClick={this.handleConnectWithStripeClick} title="Connect with" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                </div>
        ) : <Loading />;
    }

    renderHasStripeAccount = () => {
        const { currentStripeAccount, stripeAccount } = this.props;
        // const account = stripeAccount.success !== null 
        //     ? stripeAccount.success 
        //     : (stripeConnect.success !== null ? stripeConnect.success : null);
        const chargeEnabled = currentStripeAccount !== null ? 
            currentStripeAccount.charges_enabled : false;
        const email = currentStripeAccount !== null 
            ? currentStripeAccount.email || null
            : null;
            console.log('current account ', currentStripeAccount, stripeAccount);
        
        return chargeEnabled === false ? (
            <div className="w-full">
                <div className="border-gray-200 w-full">
                    <div className="">
                        <div className="p-4">
                            <p className="text-gray-700 mb-3">To complete your Stripe account setup for <strong>{email}</strong>, please click the link below.</p>
                            <p className="text-gray-700">You will be taken to the Stripe.com website to complete the connection between SwingbotPro and Stripe.</p><br />
                            <Button 
                                title="Click to Complete Stripe Setup" 
                                bgColor="indigo"
                                hoverColor="indigo"
                                size="lg"
                                onClick={this.handleLinkWithStripeClick}
                            /><br />
                            <div className="text-sm text-gray-400">
                                You're Stripe account is using <strong>{email}</strong>, <span className="underline text-indigo-500 cursor-pointer" onClick={this.handleChangeStripeClick}>click here to change your Stripe account</span>.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div className="w-full">
                <div className="border-gray-200 w-full">
                    <div className="">
                        <Banner
                            color="light-gray"
                            textColor="indigo"
                            buttonTitle="Change"
                            onClick={this.handleChangeStripeClick}
                        >
                            <span className="font-bold">{email}</span> is the email associated with your Stripe account
                        </Banner>
                    </div>
                </div>
            </div>
        );
    }

    renderChangeStripeAccount = () => {
        const { 
            website, 
            stripeConnect,
            authUser, 
            currentStripeAccount 
        } = this.props;
        const { stripeEmail } = this.state;
        const email = stripeEmail === null 
            ? ((currentStripeAccount !== null && ('email' in currentStripeAccount)) ? currentStripeAccount.email : authUser.email)
            : stripeEmail;
        return website !== null ? (
            <div className="w-full">
                {website !== null && stripeConnect.loading === true && <Loading title="Creating Stripe Account" />}
                {website !== null && stripeConnect.loading === false && (
                    <div className="w-full">
                        <div className="border-gray-200 w-full">
                            <div className="p-4">
                                <div className="mb-6">
                                    <p className="text-gray-700">To change your Stripe account from <strong>{((currentStripeAccount !== null && ('email' in currentStripeAccount)) ? currentStripeAccount.email : authUser.email)}</strong> to a new account, please click the link below.</p>
                                </div>
                                <div className="">
                                    <label htmlFor="last_name" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2 mb-2">
                                        Email Address<br /><span className="text-sm text-gray-500">Please enter the email address for your Stripe account.</span>
                                    </label>
                                    <div className="mt-1 sm:mt-0">
                                        <div className="flex flex-row space-x-2 rounded-md shadow-sm">
                                            <input id="stripe_email" value={email} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 mb-2 bg-indigo-200" onChange={this.handleStripeEmailChange} />
                                            <StripeButton onClick={this.handleConnectWithStripeClick} title="Connect with" />
                                        </div>
                                        <br />
                                        <div className="text-sm underline text-indigo-500 cursor-pointer" onClick={this.handleCancelChangeStripeClick}>
                                            Click to Cancel
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                )}
                </div>
        ) : <Loading />;
    }

    render() {
        const { 
            website, 
            getWebsiteLoading, 
            createWebsiteLoading,
            updateWebsiteLoading,
            hasStripeAccount,
            stripeAccount,
            stripeConnect,
            stripeLink
        } = this.props;
        const { subdomain } = this.state;
        const { selectedMainTab, changeStripeEmail } = this.state;
        const siteUrl = website !== null ? `https://${website.subdomain}`.toLowerCase() : 'test';
        const stripeLoading = stripeAccount.loading === true || stripeConnect.loading === true;
        const stripeError = (stripeAccount.error === true || stripeConnect.error === true || stripeLink.error === true);
        return (
            <DashboardContent title="Website">
                {getWebsiteLoading === true && <Loading />}
                {createWebsiteLoading === true && <Loading title="Creating Website" />}
                {getWebsiteLoading === false && createWebsiteLoading === false && website === null && (
                    <Container className="bg-gray-800 w-full">
                        <div className="w-full">
                            <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                                <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10 text-white">
                                Your Website Awaits</h2>
                                <br />
                                <div className="text-gray-300 text-xl font-normal space-y-2">Enter the name you would like for your website. It will be accessible on the web to your students.</div>
                                {/* <h2 className="text-xl leading-9 font-extrabold tracking-tight sm:text-xl sm:leading-10 text-gray-200">
                                {siteUrl}
                                </h2> */}
                                <div className="flex flex-row align-middle items-center space-x-2 justify-center p-5 bg-gray-700 mt-2 rounded-lg">
                                    <h2 className="text-xl text-white">https://</h2>
                                    <input 
                                        type="text" 
                                        value={this.sanitizeUrl(subdomain)} 
                                        className={`text-lg text-gray-200 rounded bg-gray-800 border focus:outline-none outline-none ${this.isValidSubdomain(this.sanitizeUrl(subdomain)) ? "focus:border-transparent border-transparent":"focus:border-red-500 border-red-500"}`}
                                        onChange={this.handleChangeDomain}
                                    />
                                    <h2 className="text-xl text-white">.swingbotpro.com</h2>
                                </div>
                                <div className="mt-8 flex justify-center">
                                <div className="inline-flex rounded-md shadow">
                                    <Button 
                                        title="Click to Create Your Website" 
                                        onClick={this.handleCreateWebsite} 
                                        bgColor="green"
                                        hoverColor="green"
                                        size="xl"
                                        disabled={!this.isValidSubdomain(subdomain)}
                                    />
                                </div>
                                </div>
                            </div>
                            </div>
                    </Container>
                )}
                {getWebsiteLoading === false && website !== null && (
                    <div className="w-full">
                        <div className="bg-white shadow overflow-hidden  sm:rounded-lg mb-4 w-full">
                            <div className="px-4 py-5 border-b border-gray-200 sm:px-6 flex justify-between flex-col md:flex-row">
                                <div>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">{siteUrl}</h3>
                                    <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                                        The following web address is the home of your SwingbotPro website.
                                    </p>
                                </div>
                                <Button bgColor="indigo" hoverColor="indigo" title="Visit Website" onClick={() => this.handleVisitWebsite(siteUrl)}/>
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row w-full space-x-0 lg:space-x-4 xl:space-x-4">
                            <div className="w-full xl:w-1/4 lg:w-1/4 space-y-4 text-sm text-gray-600 mb-4">
                                {this.renderMainTabsSide()}
                            </div>
                            {selectedMainTab === 1 && updateWebsiteLoading === false && (
                                <Container>
                                    {this.renderFormComplete()}
                                </Container>
                            )}
                            {selectedMainTab === 1 && updateWebsiteLoading === true && (
                                <Container>
                                    <Loading title="Saving Changes" />
                                </Container>
                            )}
                            {selectedMainTab === 2 && (
                                <div className="w-full">
                                    <Container fullHeight={false}>
                                        <div className="w-full">
                                            <div className="border-gray-200 w-full">
                                                <div className="w-full">
                                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                    Accept Payments with Stripe
                                                    </h3>
                                                    <div className="w-full">
                                                        <p className="mt-1 text-sm leading-5 text-gray-500">
                                                        Please specify your <a className="text-green-600 underline" target="blank" href="https://stripe.com">Stripe Account</a>, or create an account to use for lesson payment from your students.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Container>
                                    <Container fullHeight={false}>
                                        {stripeError === true && (
                                            <Banner
                                                text="There was an error connecting to Stripe, please try again in a moment."
                                                color="red"
                                                textColor="white"
                                            />
                                        )}
                                        {stripeLoading === true && <Loading />}
                                        {stripeLoading === false && hasStripeAccount === true && changeStripeEmail === false && this.renderHasStripeAccount()}
                                        {stripeLoading === false && hasStripeAccount === false && changeStripeEmail === false && this.renderHasNoStripeAccount()}
                                        {stripeLoading === false && changeStripeEmail === true && this.renderChangeStripeAccount()}
                                    </Container>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </DashboardContent>
        );
    }
}

const mapStateToProps = ({ auth, website }) => {
    const {
        getWebsiteSuccess,
        getWebsiteLoading,
        getWebsiteError,
        updateWebsiteSuccess,
        updateWebsiteLoading,
        updateWebsiteError,
        createWebsiteSuccess,
        createWebsiteLoading,
        createWebsiteError
    } = website;
    const { authUser } = auth;
    return {
        website: websiteSelectors.website(website),
        currentStripeAccount: websiteSelectors.currentStripeAccount(website),
        hasStripeAccount: websiteSelectors.hasStripeAccount(website),
        stripeAccount: websiteSelectors.stripeAccount(website),
        stripeConnect: websiteSelectors.stripeConnect(website),
        stripeLink: websiteSelectors.stripeLink(website),
        authUser,
        getWebsiteSuccess,
        getWebsiteLoading,
        getWebsiteError,
        updateWebsiteSuccess,
        updateWebsiteLoading,
        updateWebsiteError,
        createWebsiteSuccess,
        createWebsiteLoading,
        createWebsiteError
    };
};

const mapDispatchToProps = {
    getWebsiteForLicensee,
    createWebsiteForLicensee,
    updateWebsiteForLicensee,
    resetStripeConnectedAccount,
    getStripeConnectedAccount,
    createStripeConnectedAccount,
    linkStripeConnectedAccount
}

export default connect(mapStateToProps, mapDispatchToProps)(WebsitePage);