import React from 'react';
import { connect } from 'react-redux';
import { Container } from '../../tailwind';
import { 
    equationOperands, 
    generatePseudo, 
    convertOperandToEnglish 
} from '../../../util/swingbot';
import { convertEquationToEnglish } from '../../../actions/analyze';
import SelectedPosition from './SelectedPosition';
import Loading from '../../common/Loading';
class TargetEquationOperand extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            operandSelected: props.operand
        };
    }
    
    componentDidMount = () => {
        // shall we calculate the equation in english?
        this.convertToEnglish();
    }

    componentDidUpdate = (nextProps) => {
        if (nextProps.bodyPoints1 !== this.props.bodyPoints1 ||
            nextProps.bodyPoints2 !== this.props.bodyPoints2 ||
            nextProps.position1 !== this.props.position1 ||
            nextProps.position2 !== this.props.position2 ||
            ((nextProps.constantValue1 !== this.props.constantValue1) && this.props.constantValue1 !== undefined) ||
            ((nextProps.constantValue2 !== this.props.constantValue2) && this.props.constantValue2 !== undefined) || 
            nextProps.operand !== this.props.operand) {

                this.setState({
                    operandSelected: this.props.operand
                }, () => {
                    this.convertToEnglish();
                })
            }
    }

    handleConfirm = (e) => {
        this.props.onClick({
            operand: this.state.operandSelected
        });
    }

    handleSelectOperand = (operand) => {
        this.setState({ operandSelected: operand });
        this.props.onClick({ operand: operand });
    }

    convertToEnglish = () => {
        try {
            const { 
                position1, 
                position2, 
                bodyPoints1, 
                bodyPoints2, 
                calculationType1,
                calculationType2,
                constantValue1,
                constantValue2,
            } = this.props;


            if (this.state.operandSelected !== null && position1 !== null && (position2 !== null || (constantValue2 !== undefined && constantValue2 !== null))) {

                const pseudo1 = generatePseudo(
                    calculationType1, 
                    bodyPoints1, 
                    position1[1] || null, 
                    constantValue1
                );

                const pseudo2 = generatePseudo(
                    calculationType2, 
                    bodyPoints2, 
                    (constantValue2 !== undefined && constantValue2 !== null) ? constantValue2 : position2[1], 
                    constantValue2
                );


                if (pseudo1 !== null && pseudo1 !== '' && pseudo2 !== null && pseudo2 !== '') {
                    this.props.convertEquationToEnglish(
                        pseudo1, 
                        this.state.operandSelected.pseudo, 
                        pseudo2
                    );
                }
            }
        } catch(e) {
            console.log(e.message);
        }
    }

    renderOperands = () => {
        return equationOperands.map(op => {
            const selectedClass = op === this.state.operandSelected
                ? "bg-green-700 text-white active:text-white"
                : "bg-blue-700 text-white hover:bg-green-700";
            return this.props.readOnly === false ? (
                <div key={op.pseudo} className="mb-2 xs:w-full">
                    <button 
                        onClick={() => this.handleSelectOperand(op)} 
                        type="submit" 
                        className={`${selectedClass} w-full flex justify-center py-2 px-4 text-sm font-medium rounded-md bg-white hover:text-white focus:outline-none active:bg-green-700 transition duration-150 ease-in-out`}
                        disabled={this.props.readOnly}
                    >
                        is {op.display}
                    </button>
                </div>
            ) : (
                <div key={op.pseudo} className="mb-2 xs:w-full">
                    <button 
                        type="submit" 
                        className={`${selectedClass} cursor-not-allowed w-full flex justify-center py-2 px-4 text-sm font-medium rounded-md bg-white focus:outline-none focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out`}
                        disabled={this.props.readOnly}
                    >
                        is {op.display}
                    </button>
                </div>
            );
        })
    }

    renderCalculationText = (code) => {
        const { 
            calculationType1,
            calculationType2
        } = this.props;

        const text = `How does the ${calculationType1.display.toLowerCase()} in #1 compare with ${calculationType2.display.toLowerCase()} in #2?`;

        const codeText = code.substr(0,1) === 'P' 
            ? 'pass'
            : 'fail';
        const codeClass = code.substr(0,1) === 'P' 
            ? "text-green-500 font-bold"
            : "text-red-500 font-bold";

        return (
            <div className="rounded-md bg-blue-50 p-4 mb-4">
                <div className="flex items-center justify-center">
                    <div className="flex-shrink-0">
                        <svg className="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"/>
                        </svg>
                    </div>
                    <div className="ml-3 flex">
                        <p className="text-sm leading-5 text-blue-700 text-center">
                            In order for this rule to <span className={codeClass}>{codeText}</span>, {text}
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    renderConstantContainer = (value) => {
        return (
            <div className="">
                <div className="text-6xl font-bold text-white rounded-full">{value}</div>
            </div>
        )
    }

    getImageUrlForPosition = (results, position, number) => {
        if (results !== null) {
            if (results.frame_images !== null) {
                if (position !== null) {
                    if (Object.keys(position).length > 0) {
                        if (position[number]) {
                            return results.frame_images[position[number].name];
                        }
                    }
                }
            }
        }
        return null;
    }

    renderEnglish1 = () => {
        const {
            convertEquationToEnglishLoading,
            convertEquationToEnglishSuccess,
        } = this.props;
        return convertEquationToEnglishSuccess !== null && convertEquationToEnglishLoading === false
            ? convertEquationToEnglishSuccess.english1
            : <Loading showSpinner={false} textColorClass="text-white" />;
    }

    renderEnglish2 = () => {
        const { convertEquationToEnglishSuccess } = this.props;

        return convertEquationToEnglishSuccess !== null 
            ? convertEquationToEnglishSuccess.english2.toLowerCase()
            : <Loading showSpinner={false} textColorClass="text-white" />;
    }

    renderEnglishOperand = () => {
        const {
            convertEquationToEnglishLoading,
            convertEquationToEnglishSuccess,
        } = this.props;
        return convertEquationToEnglishSuccess !== null && convertEquationToEnglishLoading === false
            ? convertOperandToEnglish(convertEquationToEnglishSuccess.operand)
            : <Loading />;
    }

    renderOperandOptions = () => {
        return equationOperands.map(op => {
            const selected = op === this.state.operandSelected;
            return (<option value={op} selected={selected}>is {op.display.toLowerCase()}</option>);
        });
    }

    render() {
        const { 
            isEditable,
            show,
            constantValue2,
            code,
            calculationType,
            position1,
            position2,
            analysisResults,
            bodyPoints1,
            bodyPoints2
        } = this.props;
        const passingText = code !== null 
            ? (code.substr(0,1) === 'P' ? 'passing' : 'failing') 
            : null;
        return show === true ? (
            <Container className="bg-gray-800">
                {isEditable === true && (
                    <div className="rounded grid grid-cols-3 bg-gray-800 text-gray-100">
                        {/* {readOnly === true && ( */}
                            <div className="text-3xl font-medium text-white text-center mb-6 col-span-3">The following is true for a <span className="underline font-bold">{passingText}</span> analysis.</div>
                        {/* )} */}
                        <div className="col-span-1 rounded justify-center text-center text-white">
                            <div className="flex flex-col justify-center items-center">
                                <div className="p-2 font-medium">
                                    Assessment 1
                                </div>
                                <SelectedPosition
                                    isEditable={isEditable}
                                    calculationType={calculationType}
                                    position={position1}
                                    analysisResults={analysisResults}
                                    bodyPoints={bodyPoints1}
                                    // ratio={.85}
                                />
                            </div>
                        </div>
                        <div className="text-center m-4">
                            <div className="flex justify-center my-2 flex-col w-full h-full">
                                <div className="p-6 mb-4">{this.renderEnglish1()}...</div>
                                {this.renderOperands()}
                                <div className="p-6 mt-4">
                                    {(constantValue2 === null || constantValue2 === undefined) && (
                                        <div className="">
                                            ...{this.renderEnglish2()}
                                        </div>
                                    )}
                                    {(constantValue2 !== null && constantValue2 !== undefined) && (
                                        <div className="flex text-gray-100 w-full h-full items-center justify-center text-center">
                                            The value of the number {constantValue2}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-span-1 rounded justify-center text-center text-white">
                            <div className="flex flex-1 flex-col justify-center items-center h-full">
                                <div className="p-2 font-medium">
                                    Assessment 2
                                </div>
                                {(constantValue2 === null || constantValue2 === undefined) && (
                                    <SelectedPosition
                                        isEditable={isEditable}
                                        calculationType={calculationType}
                                        position={position2}
                                        analysisResults={analysisResults}
                                        bodyPoints={bodyPoints2}
                                        // ratio={.85}
                                    />
                                )}
                                {(constantValue2 !== null && constantValue2 !== undefined) && (
                                    <div className="flex text-gray-100 text-6xl font-bold w-full h-full items-center justify-center text-center bg-gray-700 rounded">
                                        {constantValue2}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>    
                )}
                {isEditable === false && this.state.operandSelected && (
                    <div className="py-4 rounded grid grid-cols-3 bg-gray-800 mb-4">
                        {/* {readOnly === true && ( */}
                            <div className="col-span-3 text-2xl font-bold text-white text-center py-4">The following statement is true for the {passingText} analysis.</div>
                        {/* )} */}
                        <div className="col-span-1">
                            <div className="m-4 flex flex-col items-center rounded p-2 text-white justify-center">
                                <div className="p-2 font-medium">
                                    Assessment 1
                                </div>
                                <SelectedPosition
                                    isEditable={isEditable}
                                    calculationType={this.props.calculationType}
                                    position={this.props.position1}
                                    analysisResults={this.props.analysisResults}
                                    bodyPoints={this.props.bodyPoints1}
                                    ratio={.85}
                                />
                            </div>
                        </div>
                        <div className="col-span-1 text-xl p-4 text-white items-center justify-center flex flex-col text-center">
                            {`${this.renderEnglish1()} `}<span className="font-bold">is {this.state.operandSelected.display.toLowerCase()}</span> {this.renderEnglish2()}
                        </div>
                        <div className="col-span-1">
                            <div className="m-4 flex flex-col items-center rounded p-2 text-white justify-center">
                                <div className="p-2 font-medium">
                                    Assessment 2
                                </div>
                                <SelectedPosition
                                    isEditable={isEditable}
                                    calculationType={this.props.calculationType}
                                    position={this.props.position2}
                                    analysisResults={this.props.analysisResults}
                                    bodyPoints={this.props.bodyPoints2}
                                    ratio={.85}
                                />
                            </div>
                        </div>
                    </div>    
                )}
            </Container>
        ) : null;
    }
}

TargetEquationOperand.defaultProps = {
    isEditable: false,
    show: false,
    readOnly: false,
    calculationType: '',
    operand: '',
    position1: {},
    bodyPoints1: {},
    position2: {},
    bodyPoints2: {},
    onClick(){},
    analysisResults: {},
    code: null,
    run: 0
}

const mapStateToProps = ({ analyze }) => {
    const {
        convertEquationToEnglishLoading,
        convertEquationToEnglishSuccess,
        convertEquationToEnglishError
    } = analyze;
    return {
        convertEquationToEnglishLoading,
        convertEquationToEnglishSuccess,
        convertEquationToEnglishError
    };
}

const mapDispatchToProps = {
    convertEquationToEnglish
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetEquationOperand);