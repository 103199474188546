export const DocumentationMenuData = [
  { 
    name: 'What is Swingbot Pro?', 
    route: '/what-is-swingbot-pro', 
    component: 'WhatIsSwingbotProSection', 
    style: 'h1',
  },
  { 
    name: 'Lesson Programs', 
    route: '/lesson-program', 
    component: 'LessonSection',
    style: 'h2'
  },
  { 
    name: 'Lesson Targets', 
    route: '/lesson-target',
    component: 'LessonTargetSection',
    style: 'h2',
  },
  { 
    name: 'Golfers', 
    route: '/golfers',
    component: 'UserSection',
    style: 'h2',
  },
  {
    name: 'Website', 
    route: '/website',
    component: 'WebsiteSection',
    style: 'h2',
  },
  { 
    name: 'Lesson Credits', 
    route: '/lesson-credits', 
    component: 'LessonCreditSection',
    style: 'h2'
  },
  { 
    name: 'Swingbot Pro SDK', 
    route: '/sdk', 
    component: 'SDKSection',
    style: 'h1',
    next: {
      route: '/init',
      title: 'Initializing the SDK Client'
    },
  },
  { 
    name: 'Uploading a Video', 
    route: '/sdk/upload-video', 
    component: 'SDKSection',
    style: 'h2',
  },
  { 
    name: 'Display a Golfer\'s Videos', 
    route: '/sdk/display-videos', 
    component: 'SDKGetVideosSection',
    style: 'h2',
  },
  { 
    name: 'Display the Analysis Results', 
    route: '/sdk/display-analysis-results', 
    component: 'SDKGetAnalysisResultsSection',
    style: 'h2',
  },
];
