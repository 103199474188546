import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { 
  duplicateLicenseeCampaign, 
  resetDuplicateLicenseeCampaign 
} from '../../../actions/licenseeCampaign';
import { selectors as licenseeCampaignSelectors } from '../../../reducers/licenseeCampaign';
import ModalConfirmAction from '../../common/modal/ModalConfirmAction';

class LessonDuplicateModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        show: false,
        displayName: props.licenseeCampaignDisplayName,
        status: 'INACTIVE'
    }
  }

  componentDidMount = () => {
    this.props.resetDuplicateLicenseeCampaign();
  }

  handleFieldChange = (e) => {
    this.setState({
        [e.target.id]: e.target.value
    });
  }

  handleCameraAngle = (swingTypeId) => {
    this.setState({
        swingTypeId: swingTypeId
    });
  }

  handleDuplicateLicenseeCampaign = () => {

  }

  handleConfirmDuplicateLicenseeCampaign = () => {
    const { authUser, history, licenseeCampaignId, duplicateLicenseeCampaign } = this.props;
    const licenseeId = authUser['licensee_id'];
    const userId = authUser['user_id']; 
    const { status } = this.state;

    console.log({
      licenseeCampaignId, 
        licenseeId, 
        userId, 
        status, 
        history
    });

    duplicateLicenseeCampaign(
      licenseeCampaignId, 
      licenseeId, 
      userId, 
      status, 
      history
    );
  }

  render() {
      const { duplicateLesson, show, history, onClickCancel, buttonConfirmColor } = this.props;
      return (
        <ModalConfirmAction 
            show={show} 
            title="Confirm Duplicate"
            buttonTitleConfirm="Yes, Duplicate Lesson Program"
            buttonTitleCancel="Cancel"
            buttonConfirmColor={buttonConfirmColor}
            body="Are you sure you wish to make a copy of this Lesson Program?"
            history={history}
            onCancel={onClickCancel}
            onConfirm={this.handleConfirmDuplicateLicenseeCampaign}
            isLoading={duplicateLesson.loading}
            isSuccess={duplicateLesson.success}
            isError={duplicateLesson.error}
        >
            <ul>
                <li>This will make a copy of the program so you may edit the program as you wish.</li>
            </ul>
        </ModalConfirmAction>
      );
  }
}

LessonDuplicateModal.defaultProps = {
  licenseeCampaignId: null,
  licenseeCampaignDisplayName: '',
  onClickCancel(){},
  show: false,
  buttonConfirmColor: 'red'
}

const mapStateToProps = ({ auth, licenseeCampaign }) => {
    const { authUser } = auth;
    return { 
        authUser, 
        duplicateLesson: licenseeCampaignSelectors.duplicateLicenseeCampaign(licenseeCampaign)
    };
}

const mapDispatchToProps = {
    duplicateLicenseeCampaign,
    resetDuplicateLicenseeCampaign
}

const LessonDuplicateWithRouter = withRouter(LessonDuplicateModal);
export default connect(mapStateToProps, mapDispatchToProps)(LessonDuplicateWithRouter);