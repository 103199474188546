import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DashboardContent, Container } from '../tailwind';
import { createLicenseeCampaign, resetCreateLicenseeCampaign } from '../../actions/licenseeCampaign';

class LessonProgramCreatePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount = () => {
    this.props.resetCreateLicenseeCampaign();
  }

  handleFieldChange = (e) => {
    this.setState({
        [e.target.id]: e.target.value
    });
  }

  handleCreateLessonProgram = () => {
    const { authUser } = this.props;
    const userId = authUser.user_id;
    const licenseeId = authUser.licensee_id;
    const name = this.state.name;
    const description = this.state.description;
    // lets try and create the target...
    this.props.createLicenseeCampaign(licenseeId, userId, name, description, this.props.history);
  }

  cancelTarget = () => {
    this.props.history.goBack();
  }

  render() {
      const { 
        createLicenseeCampaignSuccess,
        createLicenseeCampaignLoading,
       } = this.props;
      return (
        <DashboardContent 
            backTitle="Back to Lesson"
            title={'New Lesson Program'}
            subtitle={'Add a new Lesson Program'}
        >
             {createLicenseeCampaignSuccess === null && (
                 <Container>
                <div>
                    <div>
                        <div className="p-2 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                            <label htmlFor="name" className="block text-sm font-medium leading-5 text-gray-800">
                                Display Name
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <input 
                                    id="name" 
                                    className="flex-1 form-input block w-full min-w-0 rounded transition duration-150 ease-in-out sm:text-sm sm:leading-5  bg-gray-50" 
                                    placeholder="My Lesson Program"
                                    onChange={this.handleFieldChange}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-6">
                            <label htmlFor="about" className="block text-sm font-medium leading-5 text-gray-700">
                                Description
                            </label>
                            <div className="mt-1 rounded-md shadow-sm">
                                <textarea 
                                    id="description" 
                                    onChange={this.handleFieldChange} 
                                    rows="3" 
                                    className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-gray-800 bg-gray-50" 
                                    placeholder="A brief description of your lesson program." />
                            </div>
                            <p className="mt-2 text-sm text-gray-500">Write a few sentences describing your lesson program.
                            </p>
                        </div>
                    </div>
                </div>
                    <div className="mt-8 border-t border-gray-200 pt-5">
                        {createLicenseeCampaignLoading === false && (
                        <div className="flex justify-end">
                            <span className="inline-flex rounded-md shadow-sm">
                                <button 
                                    type="button" 
                                    className="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
                                    onClick={this.cancelTarget}
                                >
                                Cancel
                                </button>
                            </span>
                            <span className="ml-3 inline-flex rounded-md shadow-sm">
                                <button 
                                    type="button"
                                    className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out"
                                    onClick={this.handleCreateLessonProgram}
                                >
                                Save
                                </button>
                            </span>
                        </div>
                        )}
                        {createLicenseeCampaignLoading === true && (
                            <div>Loading</div>
                        )}
                    </div>
                </div>
                </Container>
             )}
             {createLicenseeCampaignSuccess !== null && (
                 <div className="rounded-md bg-green-50 p-4 mb-4 border-green-200 border-2">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <svg className="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"/>
                            </svg>
                        </div>
                        <div className="ml-3 flex-1 md:flex">
                            <p className="text-lg font-bold leading-5 text-green-700">
                                Lesson Program Created Successfully
                            </p>
                        </div>
                    </div>
                    <div className="pt-4 text-green-700">
                        <button 
                            type="button"
                            className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out"
                            onClick={() => this.props.history.goBack()}
                        >
                            Back to Lesson
                        </button>
                    </div>
                </div>                
             )}
        </DashboardContent>
      );
  }
}

const mapStateToProps = ({ auth, licenseeCampaign }) => {
    const { authUser } = auth;
    const {
        createLicenseeCampaignSuccess,
        createLicenseeCampaignLoading,
        createLicenseeCampaignError,
    } = licenseeCampaign;
    return { 
        authUser, 
        createLicenseeCampaignSuccess,
        createLicenseeCampaignLoading,
        createLicenseeCampaignError,
    };
}

const mapDispatchToProps = {
    createLicenseeCampaign,
    resetCreateLicenseeCampaign
}

const LessonProgramCreatePageWithRouter = withRouter(LessonProgramCreatePage);
export default connect(mapStateToProps, mapDispatchToProps)(LessonProgramCreatePageWithRouter);