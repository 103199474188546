/**
 * Login component
 */
import React from 'react';
import { connect } from 'react-redux';
import { activateLicensee } from '../../actions/auth';
import { withRouter } from 'react-router-dom';

class Activate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount = () => {
        const { code } = this.props;
        if (code !== null) {
            // lets activate!
            this.props.activateLicensee(this.props.match.params.code, this.props.history);
        }
    }

    render() {
        const {
            activateLicenseeSuccess,
            loadingActivateUser,
            activateLicenseeError,
            code,
            registerUserSuccess
        } = this.props;
        console.log(this.props);
        return (
            <div className="min-h-screen bg-gray-800  flex flex-col justify-center py-6 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <img className="mx-auto h-12 w-auto mb-2" src={require('../../img/logo.png')} alt="Swingbot SaaS" />

                    {code === null && registerUserSuccess === null && activateLicenseeSuccess === null && (
                        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
                            <div className="text-center">
                                <h2 className="mt-6 mb-2 text-center text-3xl leading-9 font-extrabold text-gray-900">
                                Pending Approval
                                </h2>
                                <p className="mb-4 text-sm text-gray-500">Your application is currently pending approval. We will notify you when your account is ready.</p>
                            </div>
                        </div>
                        /*{</div>
                            <form onSubmit={this.handleLogin}>
                                <div>
                                <div className="mt-1 rounded-md shadow-sm">
                                    <input 
                                        id="code" 
                                        type="text" 
                                        autoComplete="code" 
                                        placeholder="12345"
                                        required 
                                        onChange={this.handleCodeKeypress}
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                                </div>
                                </div>
                            </form>
                        </div>}*/
                    )}

                    {/* {loadingActivateUser === false && activateLicenseeSuccess === null && activateLicenseeError === null && (
                        <div className="text-center">
                        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                            Please check your email.
                        </h2>
                        <p className="mb-4 text-sm text-gray-500">We have sent you an activation email. Please click the link in the email to complete your registration.</p>
                        <div className="mt-6">
                                <span className="block w-full rounded-md shadow-sm">
                                    <button onClick={() => this.props.history.push('/login')} type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out">
                                        Back to login
                                    </button>
                                </span>
                            </div>
                        </div>
                    )} */}

                    {loadingActivateUser === true && (
                        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                            Activating Your Account...
                        </h2>
                    )}

                    {loadingActivateUser === false && activateLicenseeError !== false && code !== null && activateLicenseeSuccess === null && (
                        <div className="text-center">
                            <h2 className="mt-4 mb-2 text-center text-3xl leading-9 font-extrabold text-gray-900">
                                There was an error
                            </h2>
                            <p className="mb-4 text-sm text-gray-500">There was an error activating your account. Please check the activation code again from your email.</p>
                            <p className="mb-4 text-sm text-gray-500">If you think there is another issue with your account, please <a className="text-green-500" href="https://swingbot.uservoice.com">contact support</a>.</p>
                        </div>
                    )}
                    {activateLicenseeSuccess && activateLicenseeError === null && loadingActivateUser === false && (
                        <div className="text-center">
                            <h2 className="mt-6 mb-2 text-center text-3xl leading-9 font-extrabold text-gray-900">
                                Your Account is Active
                            </h2>
                            <p className="mb-4 text-sm text-gray-500">Your account has been activated.</p>
                            <div className="mt-6">
                                <span className="block w-full rounded-md shadow-sm">
                                    <button onClick={() => this.props.history.push('/login')} type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out">
                                        Click here to login
                                    </button>
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

Activate.defaultProps = {
    code: null
}

const mapStatetoProps = ({ auth }) => {
    const { 
        activateLicenseeSuccess,
        loadingActivateUser,
        activateLicenseeError,
        registerUserSuccess
    } = auth;
    return { 
        activateLicenseeSuccess,
        loadingActivateUser,
        activateLicenseeError,
        registerUserSuccess
    };
};

const mapDispatchToProps = {
    activateLicensee
}
const activateWithRouter = withRouter(Activate);
export default connect(mapStatetoProps, mapDispatchToProps)(activateWithRouter);