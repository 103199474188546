import React from 'react';
import { hyphenate } from '../../../util/strings';

class DocumentationFooter extends React.Component {
  handleClick = (link) => {
    if (this.props.history) {
      this.props.history.push(link);
    }
  }

  render() {
    return this.props.items.map(item => {
      const link = item.link !== undefined  
        ? item.link
        : `#${hyphenate(item.title)}`
      return (
          <div key={item.link} className="flex flex-col">
          <div className="flex text-xl font-bold p-4">Next Up:</div>
          <div className="rounded-md bg-blue-50">
            <div className="flex flex-col">
              <div className="mx-3 flex-1 md:flex md:justify-between px-6">
                <p className="mt-3 text-base leading-5 md:mt-0 font-bold">
                  <a 
                    href={link} 
                    className="whitespace-no-wrap font-medium text-blue-700 hover:underline transition ease-in-out duration-150" alt="">{item.title}</a>
                </p>
                <p className="hidden md:flex mt-3 text-base leading-5 md:mt-0">
                  <a href={link} className="whitespace-no-wrap font-medium text-blue-700 hover:text-blue-600 transition ease-in-out duration-150" alt="">
                    &rarr;
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }
}

DocumentationFooter.defaultProps = {
  intro: 'Related Articles:'
}

export default DocumentationFooter;