import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DashboardContent } from '../tailwind';
import { updateWebhooksForLicensee } from '../../actions/settings';
class SettingsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      webhook: '',
    }
  }

  handleSaveWebhook = (e) => {}

  handleKeyPressWebhook = (e) => {
    this.setState({
      webhook: e.target.value
    });
  }

  render() {
      return (
          <DashboardContent title={'Settings'}>
              <div>
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <div className="px-4 sm:px-0">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">Receive Data from API</h3>
                      <p className="mt-1 text-sm leading-5 text-gray-600">
                        How do you want to receive your data after the video has processed
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 md:mt-0 md:col-span-2">
                      <div className="shadow sm:rounded-md sm:overflow-hidden">
                        <div className="px-4 py-5 bg-white sm:p-6">
                          <div className="grid grid-cols-3 gap-6">
                            <div className="col-span-3 sm:col-span-2">
                              <label htmlFor="company_website" className="block text-sm font-medium leading-5 text-gray-700">
                                Webhook
                              </label>
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                                  https://
                                </span>
                                <input onChange={this.handleKeyPressWebhook} id="webhook-endpoint" className="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="www.example.com/swingbot-api-post" />
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                          <span className="inline-flex rounded-md shadow-sm">
                            <button onClick={this.handleSaveWebhook} className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                              Save
                            </button>
                          </span>
                        </div>
                      </div>
                  </div>
                </div>
              </div>

              <div className="hidden sm:block">
                <div className="py-5">
                  <div className="border-t border-gray-200"></div>
                </div>
              </div>
            </DashboardContent>
        )
    }
}

const mapStateToProps = ({ settings, licensee }) => {
  const { webhookUpdateSuccess } = settings;
  const { licenseeData } = licensee;
  return { webhookUpdateSuccess, licenseeData };
};


const mapDispatchToProps = {
  updateWebhooksForLicensee
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SettingsPage));