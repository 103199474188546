import React from 'react';
import { connect } from 'react-redux';
import { DashboardContent } from '../../../tailwind';
import Container from '../../../tailwind/Container';
import Loading from '../../../common/Loading';
import queryString from 'query-string';
import { verifySlackCode, saveSlackWebhookForLicensee, resetSlackWebhook } from '../../../../actions/slack';
import { selectors as slackSelectors } from '../../../../reducers/slack';
class SlackRedirect extends React.Component {

    componentDidMount = () => {
      this.props.resetSlackWebhook();
      // get the code from the Slack Oauth redirect...
      const { location, authUser, history } = this.props;
      if ('search' in location) {
        const query = queryString.parse(location.search);
        const code = query.code || null;
        const licenseeId = authUser.licensee_id;
        this.props.verifySlackCode(code, licenseeId, history);
      }
    }
  
    render() {
        const { 
          createSlackWebhook, 
          slackWebhook, 
          slackVerifyCode 
        } = this.props;
        console.log('slack redirect ', createSlackWebhook, slackWebhook, slackVerifyCode);
        return (
            <DashboardContent
                title={'Slack Integration'} 
            >
                <Container>
                  {slackVerifyCode.loading === true && <Loading />}
                  {createSlackWebhook.loading === true && <Loading />}
                  {createSlackWebhook.success === true && (
                    <div className="">DONE</div>
                  )}
                  {slackVerifyCode.error !== null && (
                    <div className="">
                      <h1>Error Integrating with Slack</h1>
                      <p>There was an error integrating with Slack. please try again later, or contact us for support.</p>
                    </div>
                  )}
                  {slackVerifyCode.error === null && createSlackWebhook.error !== null && (
                    <div className="">
                      <h1>Error Processing the Request</h1>
                      <p>There was an error integrating with Slack. please try again later, or contact us for support.</p>
                    </div>
                  )}
                </Container>
            </DashboardContent>
        )
    }
}

const mapStateToProps = ({ licensee, auth, slack }) => {
    const { authUser } = auth;
    const { 
        licenseeData,
        loadingLicenseeData,
        loadingLicenseeDataError
    } = licensee;
    
    return {
      slackWebhook: slackSelectors.slackWebhook(slack),
      createSlackWebhook: slackSelectors.createSlackWebhook(slack),
      slackVerifyCode: slackSelectors.slackOauth(slack),
      authUser,
      licenseeData,
      loadingLicenseeData,
      loadingLicenseeDataError,
    };
}

const mapDispatchToProps = {
  resetSlackWebhook,
  verifySlackCode,
  saveSlackWebhookForLicensee
};

export default connect(mapStateToProps, mapDispatchToProps)(SlackRedirect);