import { swingbotApi } from '../../util/api';

export const statisticsByLicenseeId = (licenseeId, startDate) => {
    return swingbotApi(`licensee/${licenseeId}/statistics?startDate=${startDate}`, {}, 'get', true, 'licensee')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const getLicenseeDataByUserId = (userId) => {
    return swingbotApi(`user/${userId}/licensee?debug=1`, {}, 'get', true, 'licensee')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const getLicenseeDataByEmail = (email) => {
    return swingbotApi(`licensee/email/${email}?debug=1`, {}, 'get', true, 'licensee-2')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const getLicenseeAnalyses = (licenseeId, limit, offset) => {
    return swingbotApi(`licensee/${licenseeId}/credits?limit=${limit}&offset=${offset}`, {}, 'get', true, 'licensee')
        .then(response => response)
        .catch(err => Promise.reject(err));
}

export const getLicenseeAnalysesForUser = (licenseeId, userId, limit, offset) => {
    return swingbotApi(`licensee/${licenseeId}/user/${userId}/analyses?limit=${limit}&offset=${offset}`, {}, 'get', true, 'licensee-2')
        .then(response => response)
        .catch(err => Promise.reject(err));
}


export const getLessonCountsByDay = (licenseeId) => {
    return swingbotApi(`licensee/${licenseeId}/statistics-day`, {}, 'get', true, 'licensee-2')
        .then(response => response)
        .catch(err => Promise.reject(err));
}