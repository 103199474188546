import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Activate from '../auth/Activate';
import { activateLicensee } from '../../actions/auth';

class ActivatePage extends React.Component {

    handleActivateSuccess = (res) => {
        this.props.history.push('/overview');
    }

    handleActivateError = (e) => {}

    render() {
        // check to see if we have a code...
        const code = ('code' in this.props.match.params)
            ? this.props.match.params.code
            : null;
        return (
                <Activate 
                    code={code} 
                    onActivateSuccess={this.handleActivateSuccess} 
                    onActivateError={this.handleActivateError}
                />
        );
    }
}

const mapStateToProps = ({ auth }) => {
    const {
        activateLicenseeSuccess,
        loadingActivateUser,
        activateLicenseeError,
        registerUserSuccess
    } = auth;
    return {
        activateLicenseeSuccess,
        loadingActivateUser,
        activateLicenseeError,
        registerUserSuccess
    }
}

const mapDispatchToProps = {
    activateLicensee
}

const activateWithRouter = withRouter(ActivatePage);
export default connect(mapStateToProps, mapDispatchToProps)(activateWithRouter);