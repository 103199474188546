export const GET_STATISTICS = 'licensee/get-statistics';
export const GET_STATISTICS_SUCCESS = 'licensee/get-statistics-success';
export const GET_STATISTICS_FAIL = 'licensee/get-statistics-fail';

export const GET_LICENSEE_DATA = 'licensee/get-licensee-data';
export const GET_LICENSEE_DATA_SUCCESS = 'licensee/get-licensee-data-success';
export const GET_LICENSEE_DATA_FAIL = 'licensee/get-licensee-data-fail';

export const GET_LICENSEE_DATA_BY_EMAIL = 'licensee/get-licensee-data-by-email';
export const GET_LICENSEE_DATA_BY_EMAIL_SUCCESS = 'licensee/get-licensee-data-by-email-success';
export const GET_LICENSEE_DATA_BY_EMAIL_FAIL = 'licensee/get-licensee-data-by-email-fail';

export const GET_LICENSEE_ANALYSES = 'licensee/get-licensee-analyses';
export const GET_LICENSEE_ANALYSES_SUCCESS = 'licensee/get-licensee-analyses-success';
export const GET_LICENSEE_ANALYSES_FAIL = 'licensee/get-licensee-analyses-fail';

export const GET_LICENSEE_ANALYSES_FOR_USER = 'licensee/get-licensee-analyses-for-user';
export const GET_LICENSEE_ANALYSES_FOR_USER_SUCCESS = 'licensee/get-licensee-analyses-for-user-success';
export const GET_LICENSEE_ANALYSES_FOR_USER_FAIL = 'licensee/get-licensee-analyses-for-user-fail';

export const UPDATE_API_KEY_VALUE = 'licensee/update-api-key-value';

export const GET_COUNTS_BY_DAY = 'licensee/get-day-statistics';
export const GET_COUNTS_BY_DAY_SUCCESS = 'licensee/get-day-statistics-success';
export const GET_COUNTS_BY_DAY_FAIL = 'licensee/get-day-statistics-fail';