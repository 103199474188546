import React from 'react';

class Banner extends React.Component {
  getBgColor = (color) => {
    let bgColor = null;
    switch(color) {
        case "green":
            bgColor = "bg-green-500";
        break;
        case "red":
            bgColor = "bg-red-500";
        break;
        case "blue":
            bgColor = "bg-blue-500";
        break;
        case "lightblue":
            bgColor = "bg-blue-200";
        break;
        case "light-gray":
        case "lightgray":
            bgColor = "bg-gray-100"
        break;
        case "indigo":
            bgColor = "bg-indigo-400"
        break;
        case "white":
          bgColor = "bg-white";
        break;
        default:
            bgColor = "bg-green-500";
        break;
    }
    return bgColor;
}

getHoverColor = (color) => {
    let hoverColor = null;
    switch(color) {
        case "green":
            hoverColor = "bg-green-600";
        break;
        case "red":
            hoverColor = "bg-red-700";
        break;
        case "light-gray":
            hoverColor = "bg-gray-200";
        break;
        case "blue":
            hoverColor = "bg-blue-200";
        break;
        default:
            hoverColor = "bg-green-600";
        break;
    }
    return hoverColor;
}

getTextColor = (color) => {
    let textColor = null;
    switch(color) {
        case "white":
            textColor = "text-white";
        break;
        case "green":
            textColor = "text-green-800";
        break;
        case "red":
            textColor = "text-red-800";
        break;
        case "lightblue":
            textColor = "text-blue-600";
        break;
        case "blue":
            textColor = "text-blue-800";
        break;
        case "gray":
            textColor = "text-gray-800";
        break;
        case "lightgray":
            textColor = "text-gray-600";
        break;
        case "indigo":
            textColor = "text-indigo-600";
        break;
        case "indigolight":
            textColor = "text-indigo-200";
        break;
        default:
            textColor = null;
        break;
    }
    return textColor;
}
  render() {
    const { color, textColor, text, buttonTitle, onClick } = this.props;
    const textColorClass = this.getTextColor(textColor);
    const colorClass = this.getBgColor(color);
    return (
      <div className={`rounded-md ${colorClass} p-4 shadow w-full`}>
        <div className="flex">
            <div className="flex-shrink-0">
            <svg className={`h-5 w-5 ${textColorClass}`} viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
            </svg>
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              {this.props.text !== '' && (
                <p className={`text-sm leading-5 ${textColorClass}`}>
                    {text}
                </p>
              )}
              {this.props.text === '' && (
                <p className={`text-sm leading-5 ${textColorClass}`}>
                  {this.props.children}
                </p>
              )}
            {buttonTitle !== null && (
                <div className="mt-3 text-sm leading-5 md:mt-0 md:ml-6">
                    <div 
                      onClick={onClick}className={`whitespace-no-wrap font-medium ${textColorClass} transition ease-in-out duration-150 cursor-pointer`}>
                      {buttonTitle} &rarr;
                    </div>
                </div>
            )}
            </div>
        </div>
    </div>
    );
  }
}

Banner.defaultProps = {
  buttonTitle: null,
  onClick(){},
  text: '',
  color: 'blue',
  textColor: 'blue'
}

export default Banner;