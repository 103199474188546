import React from 'react';
import { LessonStatusBubble } from '../../common';

class LessonCreditCard extends React.Component {
  handleLessonCreditClick = (videoId, lessonCredit) => {
    console.log('clicked');
  }

  render() {
    const { lessonCredit } = this.props;
    return lessonCredit !== null ? (
      <div 
        className="flex flex-row p-4 justify-between items-center space-x-2 rounded border mb-2 shadow-xs" 
        onClick={() => this.handleLessonCreditClick(lessonCredit.videoData.video_id, lessonCredit)}
      >
          <div className="flex flex-col">
              <p className="text-base font-bold text-gray-600">{lessonCredit['licensee_campaign_display_name']}</p>
              <p className="text-gray-500 text-xs">{lessonCredit['credit_code']}</p>
              
          </div>
          <LessonStatusBubble record={lessonCredit} />
      </div>
    ) : null;
  }
}

LessonCreditCard.defaultProps = {
  lessonCredit: null,
};

export default LessonCreditCard;