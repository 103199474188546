import React from 'react';
import { 
  HeaderContainer,
  LeadMagnetRequestDemo,
  Footer,
} from './';


class GetStartedPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        }
    }

    render() {
        return (
            <div className="flex flex-col">
              <HeaderContainer bgClassName="bg-gray-800" showPattern="false">
                <LeadMagnetRequestDemo />
                <Footer />
                {/* <GetStartedHorizontal /> */}
              </HeaderContainer>
            </div>
        )
    }
}

export default GetStartedPage;