import React from 'react';

class ModalTW extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    }
  }

  handleConfirm = () => {
    this.props.onConfirm();
  }

  handleCancel = () => {
    this.props.onCancel();
  }

  getSizeClass = (size) => {
    let sizeClass = 'max-w-3xl';
    switch(size) {
      case 'base':
        sizeClass = 'max-w-3xl w-1/2 min-w-1/2';
      break;

      case 'lg':
        sizeClass = 'lg:max-w-7xl lg:w-3/4';
      break;

      default:
        sizeClass = 'w-full';
      break;
    }
    return sizeClass;
  }

  render() {
    return this.props.show === true ? (
      <div className="flex">
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className={`relative my-6 mx-auto w-full md:max-w-3xl md:w-1/2 md:min-w-1/2 lg:max-w-7xl lg:w-3/4`}>
              <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="relative p-6 flex-auto w-full">
                  {this.props.children}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-gray-800"></div>
          </div>
    ) : null;
  }
}

ModalTW.defaultProps = {
  size: 'base'
}

export default ModalTW;