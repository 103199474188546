
import * as actionTypes from '../actions/settings/types';
import * as authActionTypes from '../actions/auth/actionTypes';

const initialState = {
    webhooksUpdateSuccess: null,
    updateWebhooksLoading: false,
    updateWebhooksError: false,

    webhookUpdateSuccess: null,
    updateWebhookLoading: false,
    updateWebhookError: false,
};

function settings(state = initialState, action) {
    switch(action.type) {

        case authActionTypes.LOGOUT_USER:
            return {
                ...state,
                webhooksUpdateSuccess: null,
                updateWebhooksLoading: false,
                updateWebhooksError: false,
            
                webhookUpdateSuccess: null,
                updateWebhookLoading: false,
                updateWebhookError: false,
            }

        case actionTypes.UPDATE_WEBHOOK:
            return {
                ...state,
                webhooksUpdateSuccess: null,
                updateWebhooksLoading: true,
                updateWebhooksError: false,
            };

        case actionTypes.UPDATE_WEBHOOK_SUCCESS:
            return {
                ...state,
                webhooksUpdateSuccess: action.payload,
                updateWebhooksLoading: false,
                updateWebhooksError: false,
            };

        case actionTypes.UPDATE_WEBHOOK_FAIL:
            return {
                ...state,
                webhooksUpdateSuccess: null,
                updateWebhooksLoading: false,
                updateWebhooksError: action.payload,
            };


        case actionTypes.UPDATE_WEBHOOK_CAMPAIGN:
            return {
                ...state,
                webhookUpdateSuccess: null,
                updateWebhookLoading: false,
                updateWebhookError: false,
            };

        case actionTypes.UPDATE_WEBHOOK_CAMPAIGN_SUCCESS:
            return {
                ...state,
                webhookUpdateSuccess: action.payload,
                updateWebhookLoading: false,
                updateWebhookError: false,
            };

        case actionTypes.UPDATE_WEBHOOK_CAMPAIGN_FAIL:
            return {
                ...state,
                webhookUpdateSuccess: null,
                updateWebhookLoading: false,
                updateWebhookError: action.payload,
            };

        default:
            return state;
    }
}

export default settings;