export const GET_PROGRAMS = 'program/get';
export const GET_PROGRAMS_SUCCESS = 'program/get-success';
export const GET_PROGRAMS_FAIL = 'program/get-fail';

export const GET_PROGRAMS_FOR_CAMPAIGN = 'program/get-programs-for-campaign';
export const GET_PROGRAMS_FOR_CAMPAIGN_SUCCESS = 'program/get-programs-for-campaign-success';
export const GET_PROGRAMS_FOR_CAMPAIGN_FAIL = 'program/get-programs-for-campaign-fail';

export const GET_PROGRAM_DATA = 'program/get-data';
export const GET_PROGRAM_DATA_SUCCESS = 'program/get-data-success';
export const GET_PROGRAM_DATA_FAIL = 'program/get-data-fail';

export const CREATE_PROGRAM = 'program/create';
export const CREATE_PROGRAM_SUCCESS = 'program/create-success';
export const CREATE_PROGRAM_FAIL = 'program/create-fail';

export const DUPLICATE_PROGRAM = 'program/copy';
export const DUPLICATE_PROGRAM_SUCCESS = 'program/copy-success';
export const DUPLICATE_PROGRAM_FAIL = 'program/copy-fail';

export const REMOVE_TARGET = 'program/remove-target';
export const REMOVE_TARGET_SUCCESS = 'program/remove-target-success';
export const REMOVE_TARGET_FAIL = 'program/remove-target-fail';
export const REMOVE_TARGET_RESET = 'program/remove-target-reset';

export const REMOVE_TARGETS = 'program/remove-targets';
export const REMOVE_TARGETS_SUCCESS = 'program/remove-targets-success';
export const REMOVE_TARGETS_FAIL = 'program/remove-targets-fail';

export const GET_PROGRAM_TARGETS = 'program/targets';
export const GET_PROGRAM_TARGETS_SUCCESS = 'program/targets-success';
export const GET_PROGRAM_TARGETS_FAIL = 'program/targets-fail';