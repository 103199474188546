import React from 'react';
import { DragAndDrop } from '../tailwind';

const SwingbotProSDK = require('swingbot-pro-sdk');

class DragDropFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploading: false,
            files: [],
            file: '',
        }
    }

    handleDrop = (files) => {
        const { email, apiKey, licenseeCampaignId} = this.props;
        // now let's upload it!
        const file = files[0];
        // use the SDK to send the video up to the cloud!
        this.setState({
            uploading: true
        });
        if (email && apiKey && licenseeCampaignId) {
            const client = SwingbotProSDK.init(apiKey, 'secret');
            client.uploadVideo(file, email, licenseeCampaignId)
                .then(result => {
                    this.setState({ 
                        uploading: false, 
                        uploadResult: result 
                    }, () => {});
                }).catch(e => {
                    this.setState({ uploading: false, uploadError: e });
                });
        }
    }

    render() {
        const {
            email,
            licenseeCampaignId
        } = this.props;
        return (
            <DragAndDrop 
                handleDrop={this.handleDrop} 
                uploading={this.state.uploading}
                email={email}
                licenseeCampaignId={licenseeCampaignId}
            >
                <div>
                    {this.state.uploading === true && this.state.files
                        .map((file, i) => <div key={i}>{file}</div>
                    )}
                </div>
            </DragAndDrop>
        );
    }
}

DragDropFile.defaultProps = {
    email: null,
    licenseeCampaignId: null,
    onError(){},
    onSuccess(){}
}

export default DragDropFile;