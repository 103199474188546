import React from 'react';
import {
  GettingStartedSection,
  SDKSection,
  LessonTargetSection,
  LessonCreditSection,
  LessonSection,
  LessonCreateSection,
  SDKUploadVideoSection,
  SDKGetVideosSection,
  SDKUsage,
  SDKGetAnalysisResultsSection,
  UserSection,
  WebsiteSection
} from '../../components/documentation/Documentation';

const Components = {
  '/getting-started': { 'component': GettingStartedSection },
  '/what-is-swingbot-pro': { 'component': GettingStartedSection },
  '/golfers': { 'component': UserSection },
  '/website': { 'component': WebsiteSection },

  '/sdk': { 'component': SDKSection },
  '/sdk/upload-video': { 'component': SDKUploadVideoSection },
  '/sdk/display-videos': { 'component': SDKGetVideosSection },
  '/sdk/display-analysis-results': { 'component': SDKGetAnalysisResultsSection },
  '/sdk/usage': { 'component': SDKUsage },
  
  '/lesson-program': { 'component': LessonSection },
  '/lesson-program/create': { 'component': LessonCreateSection },
  '/lesson-program/results': { 'component': LessonSection },
  '/lesson-target': { 'component': LessonTargetSection },
  '/lesson-credits': { 'component': LessonCreditSection }
};

export default block => {
  const cmp = Components[block.route];
  // component does exist
  if (typeof cmp !== "undefined") {
    return cmp.component;
  }

  // component doesn't exist yet
  return React.createElement(
    () => <div>The component {block.component} has not been created yet.</div>,
    { key: block._uid }
  );
}