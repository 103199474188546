import * as actionTypes from './types';

export const updateWebhooksForLicensee = (licenseeId, endpoint) => ({
    type: actionTypes.UPDATE_WEBHOOK,
    payload: { licenseeId, endpoint }
});

export const updateWebhooksForLicenseeSuccess = (data) => ({
    type: actionTypes.UPDATE_WEBHOOK_SUCCESS,
    payload: data
});

export const updateWebhooksForLicenseeFail = (error) => ({
    type: actionTypes.UPDATE_WEBHOOK_FAIL,
    payload: error
});

export const updateWebhookForLicenseeCampaign = (licenseeCampaignId, endpoint) => ({
    type: actionTypes.UPDATE_WEBHOOK_CAMPAIGN,
    payload: { licenseeCampaignId, endpoint }
});

export const updateWebhookForLicenseeCampaignSuccess = (data) => ({
    type: actionTypes.UPDATE_WEBHOOK_CAMPAIGN_SUCCESS,
    payload: data
});

export const updateWebhookForLicenseeCampaignFail = (error) => ({
    type: actionTypes.UPDATE_WEBHOOK_CAMPAIGN_FAIL,
    payload: error
});