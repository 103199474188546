import React from 'react';
import WizardContainer from './WizardContainer';
class WizardOptions extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            dirty: false,
        }
    }

    handleChange = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        this.setState({
            [e.target.id]: e.target.value
        }, () => {
            this.props.onChange({ name, value });
        });
    }

    handleNextStep = () => {
        if (this.state[this.props.name] !== undefined && 
            this.state[this.props.name] !== null && 
            this.state[this.props.name] !== '') {
            // go to the next step...
            this.props.nextStep();
        }
    }

    render() {
        // const cssColor = this.props.color;
        return (
            <WizardContainer 
                title={this.props.title} 
                text={this.props.text} 
                step={this.props.step}
                previousStep={this.props.previousStep}
                nextStep={this.props.nextStep}
            >
                <div className="w-full">
                    <select 
                        id={this.props.name} onChange={this.handleChange}
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 my-4 text-lg font-bold text-gray-800"
                        // className="w-full border-b-2 my-4 p-4 text-lg font-bold text-gray-800"
                    >
                        <option value="none">None</option>
                        {this.props.options.map(option => {
                            return (<option key={option.name} value={option.value}>{option.name}</option>);
                        })}
                    </select>
                    <div className="flex flex-row justify-start items-center">
                        <div 
                            disabled={this.state[this.props.name] === undefined}
                            onClick={this.handleNextStep} 
                            className="whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition ease-in-out duration-150 mr-2"
                        >
                            Next
                        </div>
                            <div className="text-xs text-gray-500">or <span className="font-bold">press Enter</span></div>
                        </div>
                    
                </div>
            </WizardContainer>
        );
    }
}

WizardOptions.defaultProps = {
    step: 1,
    name: 'input',
    type: 'text',
    placeholder: '',
    title: '',
    color: 'green',
    options: [{name: 'Select', value: ''}],
    onChange(){}
}

export default WizardOptions;