import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import { 
    faFolderOpen,
    faArchive,
    faUpload,
    faCopy,
    faAngleLeft, 
    faAngleRight, 
    faTimes, 
    faCheck, 
    faPlusCircle,
    faExclamationCircle,
    faTrash
} from '@fortawesome/free-solid-svg-icons';
import { buttonColor } from '../../util/web';
class Button extends React.Component {

    getBgColor = (color) => {
        let bgColor = null;
        switch(color) {
            case "green":
                bgColor = "bg-green-500";
            break;
            case "red":
                bgColor = "bg-red-500";
            break;
            case "blue":
                bgColor = "bg-blue-500";
            break;
            case "light-gray":
                bgColor = "bg-gray-400"
            break;
            case "indigo":
                bgColor = "bg-indigo-400"
            break;
            default:
                bgColor = "bg-green-500";
            break;
        }
        return bgColor;
    }

    getHoverColor = (color) => {
        let hoverColor = null;
        switch(color) {
            case "green":
                hoverColor = "bg-green-600";
            break;
            case "red":
                hoverColor = "bg-red-700";
            break;
            case "light-gray":
                hoverColor = "bg-gray-200";
            break;
            case "blue":
                hoverColor = "bg-blue-200";
            break;
            default:
                hoverColor = "bg-green-600";
            break;
        }
        return hoverColor;
    }

    getTextColor = (color) => {
        let textColor = null;
        switch(color) {
            case "white":
                textColor = "text-white";
            break;
            case "green":
                textColor = "text-green-800";
            break;
            case "red":
                textColor = "text-red-800";
            break;
            case "lightblue":
                textColor = "text-blue-600";
            break;
            case "blue":
                textColor = "text-blue-800";
            break;
            case "gray":
                textColor = "text-gray-800";
            break;
            case "lightgray":
                textColor = "text-gray-600";
            break;
            case "indigo":
                textColor = "text-indigo-600";
            break;
            default:
                textColor = null;
            break;
        }
        return textColor;
    }

    getIcon = (icon) => {
        let fontIcon = null;
        if (icon !== null) {
            switch(icon) {
                case 'previous':
                    fontIcon = <FontAwesomeIcon icon={faAngleLeft} className="group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />;
                break;
                case 'next':
                    fontIcon = <FontAwesomeIcon icon={faAngleRight} className="group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />;
                break;
                case 'pass':
                    fontIcon = <FontAwesomeIcon icon={faCheck} className="group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />;
                break;
                case 'fail':
                    fontIcon = <FontAwesomeIcon icon={faTimes} className="group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />;
                break;
                case 'new':
                    fontIcon = <FontAwesomeIcon icon={faPlusCircle} className="group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />;
                break;
                case 'danger':
                    fontIcon = <FontAwesomeIcon icon={faExclamationCircle} className="group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />;
                break;
                case 'duplicate':
                    fontIcon = <FontAwesomeIcon icon={faCopy} className="group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />;
                break;
                case 'upload':
                    fontIcon = <FontAwesomeIcon icon={faUpload} className="group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />;
                break;
                case 'archive':
                    fontIcon = <FontAwesomeIcon icon={faArchive} className="group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />;
                break;
                case 'open':
                    fontIcon = <FontAwesomeIcon icon={faFolderOpen} className="group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />;
                break;
                case 'trash':
                    fontIcon = <FontAwesomeIcon icon={faTrash} className="group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />;
                break;
                default:
                break;
            }
        }
        return fontIcon;
    }

    render() {
        const { 
            block,
            textColor, 
            hoverTextColor,
            bgColor, 
            hoverColor, 
            className, 
            size, 
            iconLeft, 
            iconRight,
            disabled,
            disabledColor,
            dotted,
            // outlineColor,
            borderColor,
            title,
            icon,
            tooltip,
            shadow,
            border,
            submit
        } = this.props;

        const textColorClass = this.getTextColor(disabled === true ? "white" : textColor);
        const hoverTextColorClass = this.getTextColor(disabled === true ? "white" : hoverTextColor);
        const bgColorObject = buttonColor(disabled === true ? disabledColor : bgColor);
        const hoverColorObject = buttonColor(hoverColor);
        const outlineColorObject = buttonColor(borderColor);

        let sizeClass = '';

        switch(size) {
            case 'base':
                sizeClass = 'px-4 py-2 text-sm leading-5 font-medium';
            break;

            case 'sm':
                sizeClass = 'px-2 py-1 text-xs leading-5 font-medium';
            break;

            case 'lg':
                sizeClass = 'px-6 py-4 text-lg leading-5 font-medium';
            break;

            case 'xl':
                sizeClass = 'px-8 py-6 text-2xl leading-5 font-medium';
            break;

            default:
                sizeClass = 'px-4 py-2 text-sm leading-5 font-medium';
            break;
        }

        const tooltipText = tooltip !== null 
            ? tooltip
            : (title !== undefined ? title : '');
        return (
            <div className={`flex flex-row ${block === true ? 'w-full':''}`}>
                <button 
                    data-tip={tooltipText}
                    onClick={this.props.onClick}
                    type={submit === true ? 'submit' : 'button'}
                    className={`${className} ${block === true ? 'w-full justify-center flex':'inline-flex flex-0'} items-center ${sizeClass} rounded-md ${textColorClass} ${bgColorObject.bgColor} ${disabled === false && `hover:${hoverColorObject.hoverColor}`} ${hoverTextColorClass && `hover:${hoverTextColorClass}`} focus:outline-none transition duration-150 ease-in-out whitespace-no-wrap lg:inline-flex ${border === true && `border ${outlineColorObject.outlineColor}`} ${dotted === true && `border border-dashed ${outlineColorObject.outlineColor}`} ${shadow === true && `shadow`} ${disabled === false ? 'cursor-pointer':'cursor-not-allowed'}`}
                    
                    disabled={disabled}
                >
                        {iconLeft !== null && (<div className="mr-2">{this.getIcon(iconLeft)}</div>)}
                        {icon !== null && (<div className="">{this.getIcon(icon)}</div>)}
                        {title !== null && title}
                        {iconRight !== null && (<div className="ml-2">{this.getIcon(iconRight)}</div>)}
                </button>
                <ReactTooltip 
                    backgroundColor="white"
                    textColor="text-gray-500"
                    borderColor="gray"
                    border={true}
                    place="top"
                />
            </div>
        )
    }
}

Button.defaultProps = {
    tooltip: null,
    block: false,
    iconLeft: null,
    iconRight: null,
    icon: null,
    size: 'base',
    textColor: "white",
    hoverTextColor: null,
    bgColor: "transparent",
    hoverColor: "transparent",
    disabledColor: "lightgray",
    outlineColor: 'lightgray',
    borderColor: 'transparent',
    className: "",
    disabled: false,
    dotted: false,
    shadow: true,
    border: false,
    submit: false,
}

export default Button;