/**
 * Website
 */
import * as actionTypes from '../actions/website/types';
import * as authActionTypes from '../actions/auth/actionTypes';

  // set the initial state of the reducer
    const INITIAL_STATE = {
      createWebsiteSuccess: null,
      createWebsiteLoading: false,
      createWebsiteError: null,

      getWebsiteSuccess: null,
      getWebsiteLoading: false,
      getWebsiteError: null,

      updateWebsiteSuccess: null,
      updateWebsiteLoading: false,
      updateWebsiteError: null,

      getConnectedStripeAccountSuccess: null,
      getConnectedStripeAccountLoading: false,
      getConnectedStripeAccountError: null,

      createConnectedStripeAccountSuccess: null,
      createConnectedStripeAccountLoading: false,
      createConnectedStripeAccountError: null,

      linkConnectedStripeAccountSuccess: null,
      linkConnectedStripeAccountLoading: true,
      linkConnectedStripeAccountError: null,
    };
  
   export default (state = INITIAL_STATE, action) => {
     switch (action.type) {

         case authActionTypes.LOGOUT_USER:
            return {
               ...state,
               createWebsiteSuccess: null,
               createWebsiteLoading: false,
               createWebsiteError: null,
       
               getWebsiteSuccess: null,
               getWebsiteLoading: false,
               getWebsiteError: null,
       
               updateWebsiteSuccess: null,
               updateWebsiteLoading: false,
               updateWebsiteError: null,       
            }

         case actionTypes.CREATE_WEBSITE:
         return {
            ...state,
            createWebsiteSuccess: null,
            createWebsiteLoading: true,
            createWebsiteError: null,
         }

       case actionTypes.CREATE_WEBSITE_SUCCESS:
         return {
            ...state,
            createWebsiteSuccess: action.payload,
            createWebsiteLoading: false,
            createWebsiteError: null,
         }

       case actionTypes.CREATE_WEBSITE_FAIL:
         return {
            ...state,
            createWebsiteSuccess: null,
            createWebsiteLoading: false,
            createWebsiteError: action.payload,
         }

        case actionTypes.GET_WEBSITE:
         return {
            ...state,
            getWebsiteSuccess: null,
            getWebsiteLoading: true,
            getWebsiteError: null,
            updateWebsiteSuccess: null,
            updateWebsiteLoading: false,
            updateWebsiteError: null,
            createWebsiteSuccess: null,
            createWebsiteLoading: false,
            createWebsiteError: null,
         }

        case actionTypes.GET_WEBSITE_SUCCESS:
         return {
            ...state,
            getWebsiteSuccess: action.payload,
            getWebsiteLoading: false,
            getWebsiteError: null,
         }

        case actionTypes.GET_WEBSITE_FAIL:
          return {
            ...state,
            getWebsiteSuccess: null,
            getWebsiteLoading: false,
            getWebsiteError: action.payload,
         }

         case actionTypes.UPDATE_WEBSITE:
            return {
               ...state,
               updateWebsiteSuccess: null,
               updateWebsiteLoading: true,
               updateWebsiteError: null,
            }
   
           case actionTypes.UPDATE_WEBSITE_SUCCESS:
            return {
               ...state,
               updateWebsiteSuccess: action.payload,
               updateWebsiteLoading: false,
               updateWebsiteError: null,
            }
   
           case actionTypes.UPDATE_WEBSITE_FAIL:
             return {
               ...state,
               updateWebsiteSuccess: null,
               updateWebsiteLoading: false,
               updateWebsiteError: action.payload,
            }
         
         case actionTypes.RESET_STRIPE_CONNECT_ACCOUNT:
            return {
               ...state,
               getConnectedStripeAccountSuccess: null,
               getConnectedStripeAccountLoading: false,
               getConnectedStripeAccountError: null,
               linkConnectedStripeAccountSuccess: null,
               linkConnectedStripeAccountLoading: false,
               linkConnectedStripeAccountError: null,
               createConnectedStripeAccountSuccess: null,
               createConnectedStripeAccountLoading: false,
               createConnectedStripeAccountError: null,
            }

         case actionTypes.GET_STRIPE_ACCOUNT:
            return {
               ...state,
               getConnectedStripeAccountSuccess: null,
               getConnectedStripeAccountLoading: true,
               getConnectedStripeAccountError: null,
            }
         case actionTypes.GET_STRIPE_ACCOUNT_SUCCESS:
            return {
               ...state,
               getConnectedStripeAccountSuccess: action.payload,
               getConnectedStripeAccountLoading: false,
               getConnectedStripeAccountError: null,
            }
         case actionTypes.GET_STRIPE_ACCOUNT_FAIL:
            return {
               ...state,
               getConnectedStripeAccountSuccess: null,
               getConnectedStripeAccountLoading: false,
               getConnectedStripeAccountError: action.payload,
            }

         case actionTypes.CREATE_STRIPE_ACCOUNT:
            return {
               ...state,
               createConnectedStripeAccountSuccess: null,
               createConnectedStripeAccountLoading: true,
               createConnectedStripeAccountError: null,
               // nullify these...
               getConnectedStripeAccountSuccess: null,
               getConnectedStripeAccountLoading: false,
               getConnectedStripeAccountError: null,
            }
         case actionTypes.CREATE_STRIPE_ACCOUNT_SUCCESS:
            return {
               ...state,
               createConnectedStripeAccountSuccess: action.payload,
               createConnectedStripeAccountLoading: false,
               createConnectedStripeAccountError: null,
            }
         case actionTypes.CREATE_STRIPE_ACCOUNT_FAIL:
            return {
               ...state,
               createConnectedStripeAccountSuccess: null,
               createConnectedStripeAccountLoading: false,
               createConnectedStripeAccountError: action.payload,
            }

         case actionTypes.LINK_STRIPE_ACCOUNT:
            return {
               ...state,
               linkConnectedStripeAccountSuccess: null,
               linkConnectedStripeAccountLoading: true,
               linkConnectedStripeAccountError: null,
            }
         case actionTypes.LINK_STRIPE_ACCOUNT_SUCCESS:
            return {
               ...state,
               linkConnectedStripeAccountSuccess: action.payload,
               linkConnectedStripeAccountLoading: false,
               linkConnectedStripeAccountError: null,
            }
         case actionTypes.LINK_STRIPE_ACCOUNT_FAIL:
            return {
               ...state,
               linkConnectedStripeAccountSuccess: null,
               linkConnectedStripeAccountLoading: false,
               linkConnectedStripeAccountError: action.payload,
            }
       default:
         return state;
     }
   };
  
   export const selectors = {
    website:(state) => {
     return state.getWebsiteSuccess !== null && state.getWebsiteLoading === false
       ? (state.getWebsiteSuccess && state.getWebsiteSuccess.length > 0 ? state.getWebsiteSuccess[0] : null)
       : null;
    },
    websiteConfig: (state) => {
      return {
         loading: state.getWebsiteLoading,
         success: state.getWebsiteSuccess,
         error: state.getWebsiteError
      }
    },
    stripeConnect:(state) => {
       return {
          loading: state.createConnectedStripeAccountLoading,
          error: state.createConnectedStripeAccountError,
          success: state.createConnectedStripeAccountSuccess !== null && state.createConnectedStripeAccountSuccess !== undefined ? {
             id: state.createConnectedStripeAccountSuccess.data.id,
             email: state.createConnectedStripeAccountSuccess.data.email,
             charges_enabled: state.createConnectedStripeAccountSuccess.data.charges_enabled,
          } : null
       }
    },
    stripeLink:(state) => {
       return state;
    },
    stripeAccount:(state) => {
       console.log(state);
       return {
          loading: state.getConnectedStripeAccountLoading,
          error: state.getConnectedStripeAccountError,
          success: state.getConnectedStripeAccountSuccess !== null && state.getConnectedStripeAccountSuccess !== undefined
            ? (('data' in state.getConnectedStripeAccountSuccess) ? {
               ...state.getConnectedStripeAccountSuccess.data
               } : null) 
            : null
       }
    },
    hasStripeAccount:(state) => {
       console.log('has stripe account ', state);
       if (
            state.getWebsiteSuccess !== null && ( 
            state.getConnectedStripeAccountSuccess ||
            state.createConnectedStripeAccountSuccess)
      ) {
         // check the website config
         if (
            state.getWebsiteSuccess[0].stripe_acct_id !== null && 
            state.getWebsiteSuccess[0].stripe_acct_id !== '') {
            return true;
         } 
         // check the connection (did we just connect?)
         if (state.createConnectedStripeAccountSuccess !== null && 
               'data' in state.createConnectedStripeAccountSuccess &&
               state.createConnectedStripeAccountSuccess !== undefined
            ) {
            return ('id' in state.createConnectedStripeAccountSuccess.data);
         }

         if (state.getConnectedStripeAccountSuccess !== null && 'data' in state.getConnectedStripeAccountSuccess) {
            return ('id' in state.getConnectedStripeAccountSuccess.data);
         }
       }
       // all else...
       return false;
    },
    currentStripeAccount: (state) => {
      if (
         state.createConnectedStripeAccountSuccess !== null && 
         state.createConnectedStripeAccountSuccess !== undefined
      ) {
         if ('data' in state.createConnectedStripeAccountSuccess) {
            return state.createConnectedStripeAccountSuccess.data;
         }
      } else if (
         state.getConnectedStripeAccountSuccess !== null && 
         state.getConnectedStripeAccountSuccess !== undefined
      ) {
         if ('data' in state.getConnectedStripeAccountSuccess) {
            return state.getConnectedStripeAccountSuccess.data;
         }
      } else {
         return null;
      }
      return null;
    }
  };