import React from 'react';
import { connect } from 'react-redux';
import Button from '../common/Button';
import Container from '../tailwind/Container';
import { selectors as authSelectors } from '../../reducers/auth';

class NotFoundPage extends React.Component {

    componentDidMount = () => {
        // const { authUser } = this.props;
    }

    render() {
        return (
            <div className="min-h-screen bg-gray-800 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <Container>
                    <h1 className="font-bold text-2xl">Lost Ball</h1>
                    <p>It seems we can't find that ball (ahem...page).</p>
                    <div className="py-4">
                        <Button 
                        size="lg"
                        bgColor="green"
                        title="Back to the Tee"
                        onClick={() => this.props.history.push({ pathname: '/' })}
                        />
                    </div>
                    </Container>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => {
    const { authUser } = auth;
    return {
        planInformation: authSelectors.planInformation(auth),
        authUser,
    };
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(NotFoundPage);