import React from 'react';
import { connect } from 'react-redux';
import { generatePseudo, convertOperandToEnglish } from '../../../util/swingbot';
import { convertEquationToEnglish } from '../../../actions/analyze';
import Loading from '../Loading';
class TargetPreview extends React.Component {

    componentDidMount = () => {
        this.convertToEnglish();
    }

    renderEnglish1 = () => {
        const {
            convertEquationToEnglishLoading,
            convertEquationToEnglishSuccess,
        } = this.props;
        return convertEquationToEnglishSuccess !== null && convertEquationToEnglishLoading === false
            ? convertEquationToEnglishSuccess.english1
            : null;
    }

    renderEnglish2 = () => {
        const { convertEquationToEnglishSuccess } = this.props;
        return convertEquationToEnglishSuccess !== null 
            ? convertEquationToEnglishSuccess.english2.toLowerCase()
            : null;
    }

    renderEnglishOperand = () => {
        const {
            convertEquationToEnglishLoading,
            convertEquationToEnglishSuccess,
        } = this.props;
        return convertEquationToEnglishSuccess !== null && convertEquationToEnglishLoading === false
            ? convertOperandToEnglish(convertEquationToEnglishSuccess.operand)
            : <Loading />;
    }

    convertToEnglish = () => {
        try {
            const { 
                position1, 
                position2, 
                bodyPoints1, 
                bodyPoints2, 
                calculationType1,
                calculationType2,
                constantValue1,
                constantValue2
            } = this.props;

            if (this.state.operandSelected !== null && position1 !== null && (position2 !== null || constantValue2 !== null)) {
                const pseudo1 = generatePseudo(
                    calculationType1, 
                    bodyPoints1, 
                    position1[1] || null, 
                    constantValue1
                );

                const pseudo2 = generatePseudo(
                    calculationType2, 
                    bodyPoints2, 
                    constantValue2 !== null ? constantValue2 : position2[1], 
                    constantValue2
                );

                if (pseudo1 !== null && pseudo1 !== '' && pseudo2 !== null && pseudo2 !== '') {
                    this.props.convertEquationToEnglish(
                        pseudo1, 
                        this.state.operandSelected.pseudo, pseudo2
                    );
                }
            }
        } catch(e) {
        }
    }

    render() {
        const {
            convertEquationToEnglishSuccess,
            createTargetInterpretationLoading,
            summary
        } = this.props;
        return createTargetInterpretationLoading === false ? (
            <div className="rounded shadow p-4">
                {convertEquationToEnglishSuccess && (
                    <div className="rounded p-4 mb-2 flex flex-col h-auto">
                        <h2 className="text-lg font-bold text-gray-800">Condition</h2>
                        <p className="text-lg">{`${this.renderEnglish1()} is ${this.renderEnglishOperand()} ${this.renderEnglish2()}`}</p>
                    </div>
                )}
                {summary !== null && (
                    <div className="rounded p-4 flex flex-col h-auto">
                        <h2 className="text-lg font-bold text-gray-800">Summary</h2>
                        <p className="text-lg">{summary}</p>
                    </div>
                )}
            </div>
        ) : (<Loading />);
    }
}

TargetPreview.defaultProps = {
    readOnly: true,
    show: false,
    targetInterpretationData: null,
    analysisResults: null,
    onClickSaveAndContinue(){},
    isEditable: false,
    run: null,
    code: null,
    calculationType: null,
    calculationType1: null,
    calculationType2: null,
    constantValue1: null,
    constantValue2: null,
    position1: null,
    position2: null,
    bodyPoints1: null,
    bodyPoints2: null,
    constant1: null,
    constant2: null,
    operand: null,
    onClick: null,
    summary: null,
}

const mapStateToProps = ({ analyze, targetInterpretation }) => {
    const {
        convertEquationToEnglishLoading,
        convertEquationToEnglishSuccess,
        convertEquationToEnglishError
    } = analyze;
    const {
        createTargetInterpretationLoading,
        createTargetInterpretationSuccess,
        createTargetInterpretationError
    } = targetInterpretation;
    return {
        convertEquationToEnglishLoading,
        convertEquationToEnglishSuccess,
        convertEquationToEnglishError,
        createTargetInterpretationLoading,
        createTargetInterpretationSuccess,
        createTargetInterpretationError
    };
}

const mapDispatchToProps = {
    convertEquationToEnglish
};

export default connect(mapStateToProps, mapDispatchToProps)(TargetPreview);