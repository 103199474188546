import React from 'react';

class FAQ extends React.Component {
    render() {
        return (
            <div id="faq" className="bg-white">
            <div className="max-w-screen-lg mx-auto pt-12 pb-16 sm:pt-16 sm:pb-20 px-4 sm:px-6 lg:pt-20 lg:pb-28 lg:px-8">
                <h2 className="text-3xl leading-9 font-extrabold text-gray-900">
                Frequently asked questions
                </h2>
                <div className="mt-6 pt-10">
                <dl className="md:grid md:grid-cols-2 md:gap-8">
                    <div>
                    <div>
                        <dt className="text-xl font-bold leading-6 text-gray-700">
                        What's a Lesson Program?
                        </dt>
                        <dd className="mt-2">
                        <p className="text-base leading-6 text-gray-500">
                            A Lesson Program is a collection of items to analyze in the golf swing. We call these items "targets". 
                        </p>
                        </dd>
                    </div>
                    <div className="mt-12">
                        <dt className="text-xl font-bold leading-6 text-gray-700">
                        What is a Lesson Target?
                        </dt>
                        <dd className="mt-2">
                        <p className="text-base leading-6 text-gray-500">
                            A Lesson Target is anything you wish to analyze in the golf swing! Stance at Address, Weight Shift, are both examples of Lesson Targets.
                        </p>
                        </dd>
                    </div>
                    <div className="mt-12">
                        <dt className="text-xl font-bold leading-6 text-gray-700">
                        How fast is the processing of the video?
                        </dt>
                        <dd className="mt-2">
                        <p className="text-base leading-6 text-gray-500">
                            About 2 minutes on average, but this can be faster, contact us for details.
                        </p>
                        </dd>
                    </div>
                    </div>
                    <div className="mt-12 md:mt-0">
                    <div>
                        <dt className="text-xl font-bold leading-6 text-gray-700">
                        Does this use Artificial Intelligence?
                        </dt>
                        <dd className="mt-2">
                        <p className="text-base leading-6 text-gray-500">
                            Sure does! But not for the analysis, that's where you come in. We use AI to prepare the video for analysis.
                        </p>
                        </dd>
                    </div>
                    <div className="mt-12">
                        <dt className="text-xl font-bold leading-6 text-gray-700">
                        Can I create more than one Lesson Program?
                        </dt>
                        <dd className="mt-2">
                        <p className="text-base leading-6 text-gray-500">
                            Absolutely, check out our feature packs for more information on Lesson count, and Lesson Target counts. 
                            {/* <a href="/features" className="underline text-gray-800">Learn More about Feature packs</a> */}
                        </p>
                        </dd>
                    </div>
                    <div className="mt-12">
                        <dt className="text-xl leading-6 font-bold text-gray-700">
                        Is there an API for uploading video?
                        </dt>
                        <dd className="mt-2">
                        <p className="text-base leading-6 text-gray-500">
                            Yep. The API has a method you can use to upload your videos directly to SwingbotPro. We got you covered. <a href="https://www.npmjs.com/package/swingbot-pro-sdk" target="blank" className="underline text-gray-800">Check out the API</a>
                        </p>
                        </dd>
                    </div>
                    </div>
                </dl>
                </div>
            </div>
            </div>
        )
    }
}

export default FAQ;