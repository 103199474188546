import React from 'react';
import { connect } from 'react-redux';
import { Button } from '../../../common';
import TargetLinePanel from '../TargetLinePanel';
import ImageDrawItem from '../ImageDrawItem';
import { removeLineFromInterpretation } from '../../../../actions/line';
import { 
    autoGenerateLines, 
    getPositionsFromPositions, 
    getPositionsFromLines,
    generateLineFromPosition 
} from '../../../../util/swingbot';
import { Container } from '../../../tailwind';

// containers
import { 
  TargetLineCreatePanel, 
  TargetLineNavigationBar,
  TargetLinePositionNavigationBar
} from './index';

class TargetLinesContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lines: this.props.lines,
            recommendedLines: this.recommendLines(),
            selectedLine: 0,
            showAll: true,
            originPosition: this.setOriginPosition(this.props.position1)
        }
    }

    componentDidUpdate = (nextProps) => {
        if (nextProps.position1 !== this.props.position1 || nextProps.position2 !== this.props.position2 || nextProps.bodyPoints1 !== this.props.bodyPoints1 || nextProps.bodyPoints2 !== this.props.bodyPoints2 || nextProps.calculationType1 !== this.props.calculationType1 || nextProps.calculationType2 !== this.props.calculationType2 || nextProps.stateData !== this.props.stateData) {
            if (this.props.position1 !== null) {
                const proposedOrigin = this.setOriginPosition(this.props.position1);
                this.setState({
                    lines: this.props.lines,
                    originPosition: proposedOrigin,
                    recommendedLines: this.recommendLines(proposedOrigin)
                });
            }
        }
    }

    recommendLines = () => {
        try {
            const { 
                position1, 
                position2, 
                bodyPoints1, 
                bodyPoints2, 
                calculationType1, 
                calculationType2 
            } = this.props;

            // based on the calculation type we have to make the proposed lines for each A and B comparison
            let linesGenerated1, linesGenerated2 = null;
            if (calculationType1) {
                linesGenerated1 = this.generateLinesForComparison(calculationType1, position1, bodyPoints1);
            }
            if (calculationType2) {
                linesGenerated2 = this.generateLinesForComparison(calculationType2, position2, bodyPoints2);
            }
            if (linesGenerated1 !== null && linesGenerated2 !== null) {
                // we need to merge these into one
                const positionsToMerge = [...new Set(Object.keys(linesGenerated1).concat(Object.keys(linesGenerated2)))];
                const linesMergedObject = {};
                // add the positions to the object
                positionsToMerge.forEach(position => {
                    linesMergedObject[position] = [];
                });
                Object.keys(linesGenerated1).forEach(lines1Key => {
                    linesGenerated1[lines1Key].forEach(lineGenerated1 => {
                        linesMergedObject[lines1Key].push(lineGenerated1);
                    });
                });
                Object.keys(linesGenerated2).forEach(lines2Key => {
                    linesGenerated2[lines2Key].forEach(lineGenerated2 => {
                        linesMergedObject[lines2Key].push(lineGenerated2);
                    });
                });
                return linesMergedObject;
            }
        } catch(e) {
            throw e;
        }
    }

    generateLinesForComparison = (calculationType, positionObject, bodyPointsObject) => {
        try {
            let initialLines = {};
            if (calculationType){
                const pointsForComparison = calculationType.points;
                if (pointsForComparison > 0) {
                    const positionName = positionObject[1].name;
                    const lineType = "p2p";
                    const lineColor = "#76EE00";
                    const lineStyle = "solid";
                    const lines = autoGenerateLines(positionName, bodyPointsObject, lineType, lineColor, lineStyle);
                    return lines;
                }
            }
            return initialLines;
        } catch(e) {
            return {};
        }
    }

    setOriginPosition = (position) => {
        if (position) {
            if (Object.keys(position).length > 0) {
                return position[1].name;
            }
        }
        return null;
    }

    setOriginPositionFromLines = (lines) => {
        if (Object.keys(lines).length > 0) {
            return Object.keys(lines)[0];
        }
        return null;
    }

    handleLineChange = (line) => {
        this.setState({
            selectedLine: line
        });
    }

    handleLineCreate = () => {
        try {
            const { 
                position1, 
                bodyPoints1, 
            } = this.props;

            // generate the line
            const line = generateLineFromPosition(
                position1[1].name, 
                position1[1].name, 
                bodyPoints1[1].name, 
                bodyPoints1[1].name, 
                "vertical", 
                "#76EE00", 
                "solid"
            );

            this.handleAddLine(line, position1);
        } catch(e) {
            console.log(e.message);
        } 
    }

    /**
     * handleClickOriginPosition
     * We want to place ALL of the lines in ONE origin
     * SO, we have to have the object position consolidated into 
     * the one selected.
     * @param {string} position 
     */
    handleClickOriginPosition = (position) => {
        const { lines } = this.state;
        const currentLines = lines;
        const linesInState = [];
        Object.keys(currentLines).forEach(p => {
            const linesInPosition = currentLines[p];
            linesInPosition.forEach(line => {
                // add the line to the mix...
                linesInState.push(line);
            });
        });
        
        const newLines = { [position]: linesInState };
        this.setState({
            lines: newLines,
            originPosition: position
        });
         // send this up to the parent....
         this.props.onChange(newLines);
    }

    /**
     * handleAddLine
     * Add a new line from the options
     * @param {object} line 
     */
    handleAddLine = (line, position) => {
        try {
            const { lines, originPosition } = this.state;
            const currentLines = lines;
            
            // we want to use the origin line selected.
            // if there are no lines yet, we have to create one
            if (Object.keys(currentLines).indexOf(originPosition) < 0) {
                currentLines[originPosition] = [];
            }
            // add the line to the array
            currentLines[originPosition].push(line);
            this.setState({
                lines: currentLines,
                selectedLine: currentLines[originPosition].length - 1
            });
            // send this up to the parent....
            this.props.onChange(currentLines);
        } catch(e) {
            throw e;
        }
    }

    handleDeleteLine = (number) => {
        const { originPosition, lines } = this.state;
        const currentLines = lines;
        if (currentLines[originPosition][number]) {
            // ok lets check to see if we have the line!
            const lineToDelete = currentLines[originPosition][number];

            // delete the line!
            this.props.removeLineFromInterpretation(lineToDelete.ref_id);

            // remove the line from state
            currentLines[originPosition].splice(number, 1);
            this.setState({
                lines: currentLines,
                selectedLine: 0
            }, () => {
                this.setState({ lines: currentLines });
            });        
            // send up the lines to the parent
            this.props.onChange(currentLines);
        }
    }

    handleCreateLine = () => {
        const { lines, originPosition } = this.state;
        let linesObject = lines && Object.keys(lines).length > 0 ? lines : { 'address': []};
        
        const line = {
            color: "#76EE00",
            p1_lexicon: "left_shoulder",
            p2_lexicon: "right_shoulder",
            phase: originPosition,
            phase_origin: originPosition,
            phase_origin_2: originPosition,
            style: "solid",
            time: "00:00:00",
            type: "p2p",
        };
        
        // lets add the line...
        linesObject[originPosition].push(line);

        // send this up to the parent....
        this.props.onChange(linesObject);

        this.setState({
            lines: linesObject,
            selectedLine: linesObject[originPosition].length - 1 // current line just created
        }, () => {});
    }

    /**
     * handleLineTypeChange
     * User has changed the line "type" and we have to reset the lines
     * array with the new value
     * @param {event} e 
     * @param {int} selectedLineIndex the line index we are working on
     */
    handleLineTypeChange = (e, selectedLineIndex) => {
        try {
            const { originPosition } = this.state;
            const currentLines = this.state.lines;
            currentLines[originPosition][selectedLineIndex]['type'] = e.target.value;
            
            this.setState({
                lines: null
            }, () => {
                this.setState({ lines: currentLines });
            });
            // send to the parent
            this.props.onChange(currentLines);
        } catch(e) {
            throw e;
        }
    }

    /**
     * handleLineColorChange
     * User has changed the color of the line
     * 
     */
    handleLineColorChange = (e, selectedLineIndex) => {
        try {
            const { originPosition } = this.state;
            const currentLines = this.state.lines;
            currentLines[originPosition][selectedLineIndex]['color'] = e.target.value;
            this.setState({
                lines: null
            }, () => {
                this.setState({ lines: currentLines });
            });
            // send to the parent
            this.props.onChange(currentLines);
        } catch(e) {
            throw e;
        }
    }

    handlePosition1Change = (e, selectedLineIndex) => {
        try {
            const { originPosition } = this.state;
            const currentLines = this.state.lines;
            currentLines[originPosition][selectedLineIndex]['phase'] = e.target.value;
            currentLines[originPosition][selectedLineIndex]['phase_origin'] = e.target.value;
            this.setState({
                lines: null
            }, () => {
                this.setState({ lines: currentLines });
            });
            // send to the parent
            this.props.onChange(currentLines);
        } catch(e) {
            throw e;
        }
    }

    handlePosition2Change = (e, number) => {
        const { originPosition } = this.state;
        const currentLines = this.state.lines;
        currentLines[originPosition][number]['phase_origin_2'] = e.target.value;
        this.setState({
            lines: null
        }, () => {
            this.setState({ lines: currentLines });
        });
        // send to the parent
        this.props.onChange(currentLines);
    }

    handleBodyPoint1Change = (e, number) => {
        const { originPosition } = this.state;
        const currentLines = this.state.lines;
        currentLines[originPosition][number]['p1_lexicon'] = e.target.value;
        this.setState({
            lines: null
        }, () => {
            this.setState({ lines: currentLines });
        });
        // send to the parent
        this.props.onChange(currentLines);
    }

    handleBodyPoint2Change = (e, number) => {
        const { originPosition } = this.state;
        const currentLines = this.state.lines;
        currentLines[originPosition][number]['p2_lexicon'] = e.target.value;
        this.setState({
            lines: null
        }, () => {
            this.setState({ lines: currentLines });
        });
        // send to the parent
        this.props.onChange(currentLines);
    }

    renderLineRows = (lines) => {
        try {
            const { analysisResults, readOnly } = this.props;
            const positionName = lines !== null ? Object.keys(lines)[0] : null;
            const line = positionName !== null 
                ? lines[positionName][this.state.selectedLine]
                : null;
            return line !== null ? (
                <TargetLinePanel
                    readOnly={readOnly}
                    position={positionName} 
                    analysisResults={analysisResults}
                    line={line}
                    lineIndex={this.state.selectedLine}
                    onLineTypeChange={this.handleLineTypeChange}
                    onLineColorChange={this.handleLineColorChange}
                    onPosition1Change={this.handlePosition1Change}
                    onPosition2Change={this.handlePosition2Change}
                    onBodyPoint1Change={this.handleBodyPoint1Change}
                    onBodyPoint2Change={this.handleBodyPoint2Change}
                    onDelete={this.handleDeleteLine}
                    onCreate={this.handleCreateLine}
                />
            ) : null;
        } catch (e) {
        }
    }
    
    renderImageItem = (lines, position) => {
        try {
            const { bodyPointsToRender } = this.props;
            const { analysisResults } = this.props;
            const { showAll } = this.state;
            const positionName = position;

            const line = (lines !== null && lines !== undefined)
                ? (lines[positionName] !== undefined ? lines[positionName][this.state.selectedLine] : null)
                : null;
            
            const linesExist = lines[positionName] !== undefined;
            const imageUrl = this.getImageUrlForPosition(analysisResults, positionName, 1);

            let linesToShow = [];
            if (showAll === true) {
                if (linesExist === true) {
                    linesToShow = lines[positionName];
                } else {
                    linesToShow = [];
                }
            } else {
                // we only want to show the SINGLE line
                if (line !== null) {
                    linesToShow = [line];
                }
            }

            this.renderImagePosition(line, position, imageUrl);
            return imageUrl !== null ? (
                <ImageDrawItem
                    position={position}
                    imageUrl={imageUrl}
                    points={analysisResults.points}
                    rightHanded={analysisResults.right_handed}
                    bodyPointsToRender={bodyPointsToRender}
                    lines={linesToShow}
                    ratio={0.85}
                />
            ): null
        } catch(e) {
            throw e;
        }
    }

    renderImagePosition = (line, position, imageUrl) => {
        try {
            const { bodyPointsToRender } = this.props;
            const { analysisResults } = this.props;
            // just show the one line!
            const linesToShow = [line];
            return imageUrl !== null ? (
                <ImageDrawItem
                    position={position}
                    imageUrl={imageUrl}
                    points={analysisResults.points}
                    rightHanded={analysisResults.right_handed}
                    bodyPointsToRender={bodyPointsToRender}
                    lines={linesToShow}
                    ratio={0.85}
                />
            ): null
        } catch(e) {
        }
    }

    getImageUrlForPosition = (results, position, number) => {
        if (results !== null) {
            if (results.frame_images !== null) {
                if (position !== null) {
                    return results.frame_images[position];
                }
            }
        }
        return null;
    }
    
    handleChangePosition = (position) => {
        this.setState({
            originPosition: position
        });
    }

    handleSetRecommendedLines = () => {
        if (this.state.recommendedLines) {
            this.props.onChange(this.state.recommendedLines);
        }
    }

    handleDontSetRecommendedLines = () => {
        this.props.onChange({});
    }

    render() {
        const { 
            position1, 
            position2, 
            bodyPoints1, 
            bodyPoints2,
            analysisResults,
            readOnly,
            targetInterpretationData,
            onLineChange,
            lines
        } = this.props;
        const { recommendedLines, selectedLine, originPosition } = this.state;
        const positionsFromPositions = getPositionsFromPositions(position1, position2);
        const positionsFromLines = getPositionsFromLines(lines);
        const positions = positionsFromLines && positionsFromPositions ? [...new Set(positionsFromPositions.concat(positionsFromLines))] : [];

        return position1 !== null && bodyPoints1 && originPosition && (lines !== null && Object.keys(lines).length > 0) && recommendedLines ? (
          <Container className="border">
            <div className="flex flex-col">
              <h1>Line Annotations</h1>
              <div className="text-md font-normal text-gray-600 mb-4">Line Annotations will be added to the images upon processing the video. These annotations will be shown to the user to help explain the analysis.</div>
              <div className="flex flex-row">
                <TargetLinePositionNavigationBar 
                  positions={positions} 
                  originPosition={originPosition}
                  onClick={this.handleChangePosition}
                  lines={lines}
                />
              </div>
              <div className="flex flex-row space-x-2">
                <div className="flex">
                  {this.renderImageItem(lines, originPosition)}
                </div>
                <div className="flex flex-col overflow-y-auto px-2">
                    <TargetLineNavigationBar 
                        readOnly={readOnly}
                        recommendedLines={recommendedLines}
                        lines={lines !== null && lines !== undefined ? lines[originPosition] : null}
                        currentLineIndex={selectedLine} 
                        onLineChange={this.handleLineChange}
                        onLineCreate={this.handleLineCreate}
                    />
                </div>
                <div className="flex flex-grow">
                    <TargetLineCreatePanel 
                        readOnly={readOnly}
                        show={true}
                        lineIndex={selectedLine}
                        lines={lines}
                        targetInterpretationData={targetInterpretationData} 
                        analysisResults={analysisResults}
                        position1={position1}
                        position2={position2}
                        bodyPoints1={bodyPoints1 || null}
                        bodyPoints2={bodyPoints2 || null}
                        onChange={onLineChange}
                        originPosition={originPosition}
                        onLineTypeChange={this.handleLineTypeChange}
                        onLineColorChange={this.handleLineColorChange}
                        onPosition1Change={this.handlePosition1Change}
                        onPosition2Change={this.handlePosition2Change}
                        onBodyPoint1Change={this.handleBodyPoint1Change}
                        onBodyPoint2Change={this.handleBodyPoint2Change}
                        onDelete={this.handleDeleteLine}
                    />
                </div>
              </div>
            </div>
          </Container>
        ) : (
            <Container className="bg-gray-800">
                <div className="flex flex-col w-full">
                    <div className="flex flex-col w-full text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                        <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10 text-white">
                        Line Annotations
                        <br />
                        <span className="text-gray-300 text-xl font-normal">Line Annotations will be added to the images upon processing the video. These annotations will be shown to the user to help explain the analysis.</span>
                        </h2>
                        <div className="mt-8 flex justify-center">
                            <div className="inline-flex rounded-md shadow space-x-2">
                            <Button 
                                title="Click to Use Recommended Annotations" 
                                onClick={this.handleSetRecommendedLines} 
                                bgColor="green"
                                hoverColor="green"
                                size="lg"
                            />
                            <Button 
                                title="I don't need help" 
                                onClick={this.handleDontSetRecommendedLines} 
                                bgColor="indigo"
                                hoverColor="indigo"
                                size="lg"
                            />
                        </div>
                        </div>
                    </div>
                    </div>
            </Container>
        );
    }
}

TargetLinesContainer.defaultProps = {
    show: false,
    readOnly: false,
    textMessage: '',
    lines: null,
    position1: null,
    bodyPoints1: null,
    position2: null,
    bodyPoints2: null,
    originPosition: null,
    analysisResults: null,
    onCreate(){}, 
    onChange(){} // sets the lines
}

const mapStateToProps = ({ line }) => {
    return line;
}

const mapDispatchToProps = {
    removeLineFromInterpretation
}


export default connect(mapStateToProps, mapDispatchToProps)(TargetLinesContainer);