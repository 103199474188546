/**
 * jwt actions
 */
const jwt = require('jsonwebtoken');
/**
 * verifyToken
 */
export const verifyToken = (token) => {
  return new Promise(function(resolve, reject){
    if (token) {
      return jwt.verify(token, process.env.REACT_APP_JWT_SECRET, function(err, decoded){
        if (err) {
          reject(err);
        } else {
          resolve(decoded);
        }
      })
    } else {
      return reject(new Error('null token'))
    }
  });
};

export const verifyTokenSync = (token) => {
    try {
        return jwt.verify(token, process.env.REACT_APP_JWT_SECRET);
    } catch (e) {
        return false;
    }
}

export const timeUntilExpiration = (token) => {
  const decoded = jwt.decode(token, process.env.REACT_APP_JWT_SECRET);
  if (decoded !== null) {
    const now = new Date().getTime() / 1000;
    const diff = (decoded.exp - now);
    const diffMinutes = diff / 60; 
    return {
      expires: decoded.exp,
      now,
      diff: decoded.exp - now,
      minutesLeft: diffMinutes
    };
  }
  return null;
}

export const isTokenExpired = (token) => {
  return jwt.decode(token, process.env.REACT_APP_JWT_SECRET, function(err, decoded) {
    if (err) {
      // lets determine if this is a expiration error or not...
      return err;
    } else{
      return decoded;
    }
  });
}

export const saveToken = (token) => {
    return window.sessionStorage.setItem('user', JSON.stringify(token));
}

export const getTokenAsync = () => {
    const tokenData = window.sessionStorage.getItem('user') || null;
    return Promise.resolve(JSON.parse(tokenData));
}

export const getToken = () => {
    return JSON.parse(window.sessionStorage.getItem('user'));
}

export const removeToken = () => {
  return window.sessionStorage.setItem('user', null);
}

export const decodeToken = (token) => {
    try {
        return jwt.decode(token, process.env.REACT_APP_JWT_SECRET);
    } catch (e) {
        return false;
    }
}
