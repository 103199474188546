import React from 'react';

class Pricing extends React.Component {
    render() {
        return (
            <div className="bg-white">
                <div className="max-w-screen-lg mx-auto pt-12 pb-16 sm:pt-16 sm:pb-20 px-4 sm:px-6 lg:pt-20 lg:pb-28 lg:px-8">
                    pricing
                </div>
            </div>
        )
    }
}

export default Pricing;