import React from 'react';
import { Container } from '../../tailwind';
import { Button, Loading, LearnMore } from '../../common';

class Onboarding extends React.Component {

  render() {
    const { website, lessons, history } = this.props;
    const hasLessons = lessons !== null && lessons.length > 0;
    const hasWebsite = website.success !== null && website.success.length > 0;
    const step1Class = hasLessons === true
      ? 'font-bold text-gray-700 text-lg mb-2 line-through'
      : 'font-bold text-gray-700 text-lg mb-2 cursor-pointer';
    const step2Class = hasWebsite === true
      ? 'font-bold text-gray-700 text-lg mb-2 line-through'
      : 'font-bold text-gray-700 text-lg mb-2 cursor-pointer';
    return website.loading === false && lessons !== null ? (
      <Container>
            <div className="flex max-w-screen-xl mx-auto py-0 sm:py-16 px-4 sm:px-6 lg:px-8 flex-col lg:flex-row space-x-4">
              <div className="flex flex-0 p-2 mb-4 lg:mb-0">
                <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-700 sm:text-4xl sm:leading-10 flex-shrink-0">
                    Welcome to SwingbotPro
                    <br />
                    <span className="text-green-700">Let's get started.</span>
                </h2>
              </div>
              <div id="tasks" className="flex flex-1 flex-col">
                <div className="rounded border-b pb-4 px-0 lg:px-6">
                  <p className={step1Class} onClick={() => history.push('/lessons')}>Step 1: Create a Lesson Program</p>
                  <p className="text-gray-500">When users upload their videos to your account, they will be processed and analyzed using the Lesson Programs you create.</p>
                  {hasLessons === false && (
                    <div className="flex flex-row align-middle space-x-4 py-2 items-center">
                      <Button 
                        title="Click to go to Lessons" 
                        bgColor="green"
                        onClick={() => history.push('/lessons')}
                      />
                      <LearnMore docPage="/doc/lesson-program" />
                    </div>
                  )}
                </div>
                <div className="rounded border-b py-4 px-0 lg:px-6">
                  <p className={step2Class}>Step 2: Configure your Website</p>
                  <p className="text-gray-500">Choose a name, and we will generate a website that can be used to promote your lessons and allow users to upload and review their analysis. You can even accept payments.</p>
                  {hasWebsite === false && (
                    <div className="flex flex-row align-middle space-x-4 py-2 items-center">
                      <Button 
                        title="Click to Configure Website" 
                        bgColor="green"
                        onClick={() => history.push('/website')}
                      />
                      <LearnMore docPage="/doc/website" />
                    </div>
                  )}
                </div>                
              </div>
            </div>
      </Container>
    ) : (
      <Container>
        <Loading />
      </Container>
    );
  }
}

Onboarding.defaultProps = {
  website: null,
}

export default Onboarding;