import React from 'react';
import { capitalizeFirstLetter } from '../../util/strings';

class LessonStatusBubble extends React.Component {
  render() {
    const { record } = this.props;
    let bgColor = null;
    let textColor = null;
    let date = null;
    
    const dateCreated = new Date(record.date_created_timestamp);
    const dateUploaded = new Date(record.date_uploaded_timestamp);
    const dateProcessing = new Date(record.date_processing_timestamp);
    const dateCompleted = record.date_processed_timestamp > 0 
        ? new Date(record.date_processed_timestamp) 
        : null;

    switch(record.status.toLowerCase()) {
        case 'completed':
            bgColor = 'bg-green-200';
            textColor = 'text-green-800';
            date = dateCompleted;
        break;
        case 'detecting':
            bgColor = 'bg-blue-200';
            textColor = 'text-blue-800';
            date = dateProcessing;
        break;
        case 'uploaded':
            bgColor = 'bg-gray-200';
            textColor = 'text-gray-800';
            date = dateUploaded;
        break;

        case 'assigned':
          bgColor = 'bg-indigo-200';
          textColor = 'text-indigo-800';
          date = dateCreated;
        break;

        default:
        break;
    }

    return (
        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded ${bgColor} ${textColor}`}>
            {capitalizeFirstLetter(record.status)}{date ? ` on ${date.toLocaleDateString()}` : null}
        </span>
    );
  }
}

export default LessonStatusBubble;