export const GET_TARGET_INTERPRETATIONS = 'target-interpretation/get';
export const GET_TARGET_INTERPRETATIONS_SUCCESS = 'target-interpretation/get-success';
export const GET_TARGET_INTERPRETATIONS_FAIL = 'target-interpretation/get-fail';

export const GET_TARGET_INTERPRETATIONS_BY_PROGRAM = 'target-interpretation/get-by-program';
export const GET_TARGET_INTERPRETATIONS_BY_PROGRAM_SUCCESS = 'target-interpretation/get-by-program-success';
export const GET_TARGET_INTERPRETATIONS_BY_PROGRAM_FAIL = 'target-interpretation/get-by-program-fail';

export const GET_TARGET_INTERPRETATIONS_BY_TARGET = 'target-interpretation/get-by-target';
export const GET_TARGET_INTERPRETATIONS_BY_TARGET_SUCCESS = 'target-interpretation/get-by-target-success';
export const GET_TARGET_INTERPRETATIONS_BY_TARGET_FAIL = 'target-interpretation/get-by-target-fail';

export const CREATE_TARGET_INTERPRETATION = 'target-interpretation/create';
export const CREATE_TARGET_INTERPRETATION_SUCCESS = 'target-interpretation/create-success';
export const CREATE_TARGET_INTERPRETATION_FAIL = 'target-interpretation/create-fail';
export const RESET_CREATE_TARGET_INTERPRETATION = 'target-interpretation/reset';

export const REMOVE_TARGET_INTERPRETATION = 'target-interpretation/remove';
export const REMOVE_TARGET_INTERPRETATION_SUCCESS = 'target-interpretation/remove-success';
export const REMOVE_TARGET_INTERPRETATION_FAIL = 'target-interpretation/remove-fail';
export const REMOVE_TARGET_INTERPRETATION_RESET = 'target-interpretation/remove-reset';