import React from 'react';
import { connect } from 'react-redux';
import { selectors as authSelectors } from '../../../reducers/auth';
import Banner from './Banner';
import { Button } from '../../common';

class LessonProgramsBanner extends Banner {
  render() {
    const { color, textColor, buttonTitle, onClick } = this.props;
    const textColorClass = this.getTextColor(textColor);
    const colorClass = this.getBgColor(color);
    return (
      <div className={`rounded-md ${colorClass} p-4 shadow w-full`}>
          <div className="flex items-center">
          <div className="flex-shrink-0">
            <svg className={`h-5 w-5 ${textColorClass}`} viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
            </svg>
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between items-center">
              <p className={`text-sm leading-5 ${textColorClass}`}>
                  You have used <span className="font-bold">{this.props.lessonsUsed}</span> of your <span className="font-bold">{this.props.lessonsAllowed}</span> Lesson Programs available.
              </p>
            </div>
            {buttonTitle !== null && (
                <div className="mt-3 text-sm leading-5 md:mt-0 md:ml-6 justify-center items-center">
                    <Button 
                      onClick={onClick}
                      title={`${buttonTitle}`}
                      size="sm"
                      bgColor="indigo"
                      hoverColor="indigo"
                    />
                </div>
            )}
          </div>
      </div>
  );
  }
}

LessonProgramsBanner.defaultProps = {
  lessonsAllowed: 0,
  lessonsUsed: 0,
  textColor: 'lightblue',
  color: 'white'
};

const mapStateToProps = ({ auth, plan, licensee, subscribe }) => {
  const { authUser } = auth;
  const {
      plans,
      getPlansSuccess,
      getPlansError,
      getPlansLoading
  } = plan;
  const {
      clientToken,
      subscriptionSuccess,
      cancelSubscriptionLoading,
      cancelSubscriptionSuccess,
      cancelSubscriptionError,
  } = subscribe;
  return {
      planInformation: authSelectors.planInformation(auth),
      // licenseeData: licenseeSelectors.licenseeData(licensee),
      authUser,
      plans,
      getPlansError,
      getPlansSuccess,
      getPlansLoading,
      clientToken,
      subscriptionSuccess,
      cancelSubscriptionLoading,
      cancelSubscriptionSuccess,
      cancelSubscriptionError,
  };
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(LessonProgramsBanner);