import * as services from './services';
import * as actionCreators from './actions';


export const getPlans = () => dispatch => {
    dispatch(actionCreators.getPlans());
    return services.getPlans()
        .then(data => dispatch(actionCreators.getPlansSuccess(data.data)))
        .catch(e => dispatch(actionCreators.getPlansFail(e)));
}
