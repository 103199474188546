import React from 'react';
import { DashboardContent } from '../tailwind';
class ProgramTargetDetailPage extends React.Component {
    render() {
        return (
            <DashboardContent title={'Lesson Target Detail'}>
                title
            </DashboardContent>
        )
    }
}
export default ProgramTargetDetailPage;