import React from 'react';
import { connect } from 'react-redux';
import { Container } from '../../tailwind';
import DisabledTargetContainer from '../analysis/DisabledTargetContainer';
import ImageDrawItem from './ImageDrawItem';
import { removeLineFromInterpretation } from '../../../actions/line';
import { getPositionByName } from '../../../util/swingbot';
class TargetLinePositionOptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          lines: this.props.lines,
          positionSelected: this.props.position1[1].name || null,
        }
    }

    handleChooseLinePosition = (position) => {
      this.props.onClickPosition(position);
    }
    /**
     * renderImageItem
     * We will use the lines to determine if we already have a line selected
     * 
     * @param {object} lines 
     * @returns 
     */
    renderImageItem = (lines, originPosition) => {
        try {
            const { position1, position2, bodyPointsToRender } = this.props;
            const { analysisResults } = this.props;

            const positions = [];
            if (Object.keys(position1).length > 0) {
              Object.keys(position1).forEach(k => {
                positions.push(position1[k].name);
              })
            }
            
            if (position2 !== null && position2[1]) {
              positions.push(position2[1].name);
            }
            if (position2 !== null && position2[2]) {
              positions.push(position2[2].name);
            }
            
            let uniquePositions = [...new Set(positions)];
            
            // we have to figure out how many positions we have in the main comparison...
            // and allow the user to choose the main position that we will use to draw the lines...
            // this is only to choose the position for the lines!

            return analysisResults !== null ? uniquePositions.map((position, index) => {
              const imageUrl = this.getImageUrlForPosition(analysisResults, position, 1);
              const positionName = getPositionByName(position);
              const css = originPosition === position ? 'opacity-100' : 'opacity-70 hover:opacity-100';
              return (
                <div className="col-span-1 cursor-pointer rounded-lg overflow-clip border shadow" key={`line-option-${position}-${index}`} onClick={() => this.handleChooseLinePosition(position)}>
                  <div className={`flex justify-center align-middle p-4 bg-white rounded-t-lg ${css}`}>
                    <ImageDrawItem
                        position={position}
                        imageUrl={imageUrl}
                        points={analysisResults.points}
                        rightHanded={analysisResults.right_handed}
                        bodyPointsToRender={bodyPointsToRender}
                        lines={[]}
                        ratio={.60}
                        className="rounded-lg object-cover justify-center items-center"
                    />
                  </div>
                  <div className="bg-gray-100 rounded-b-lg p-4 text-sm border-t text-gray-700">
                    <span className="">{positionName.display}</span>
                    {/* {this.renderPositionTabs()} */}
                  </div>
                </div>
              );
            }) : null;
        } catch(e) {
          throw e;
        }
    }

    getImageUrlForPosition = (results, position, number) => {
        if (results !== null) {
            if (results.frame_images !== null) {
                if (position !== null) {
                    return results.frame_images[position];
                }
            }
        }
        return null;
    }
    
    // renderPositionTabs = (positions) => {
    //   return (
    //     <span className="relative z-0 inline-flex shadow-sm rounded-md">
    //     <button type="button" className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
    //       Save changes
    //     </button>
    //     <span className="-ml-px relative block">
    //       <button type="button" className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" id="option-menu-button" aria-expanded="true" aria-haspopup="true">
    //         <span className="sr-only">Open options</span>
    //         <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
    //           <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
    //         </svg>
    //       </button>
      
    //       <div className="origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="option-menu-button" tabindex="-1">
    //         <div className="py-1" role="none">
              
    //           <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="option-menu-item-0">
    //             Save and schedule
    //           </a>
      
    //           <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="option-menu-item-1">
    //             Save and publish
    //           </a>
      
    //           <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="option-menu-item-2">
    //             Export PDF
    //           </a>
    //         </div>
    //       </div>
    //     </span>
    //   </span>
    //   )
    // }


    render() {
        const { position1, bodyPoints1, originPosition } = this.props;
        const { lines } = this.state;
        return position1 !== null && bodyPoints1 ? (
          <Container key={lines}>
            <div className="">
                <div className="w-full">
                    <div className="mt-4 flex flex-row">
                        <div className="grid grid-flow-row grid-cols-1 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 auto-cols-max w-full col-gap-3 row-gap-3">
                            {lines && this.renderImageItem(lines, originPosition)}
                        </div>
                    </div>
                </div>
            </div>
          </Container>
        ) : (<DisabledTargetContainer onClick={this.handleCreateLine} title="Click to Create Line Annotation" />);
    }
}

TargetLinePositionOptions.defaultProps = {
    show: false,
    readOnly: false,
    textMessage: '',
    lines: null,
    position1: null,
    bodyPoints1: null,
    position2: null,
    bodyPoints2: null,
    originPosition: null,
    onCreate(){},
    onChange(){},
    onClick(){},
    onClickPosition(){}
}

const mapStateToProps = ({ line }) => {
    return line;
}

const mapDispatchToProps = {
    removeLineFromInterpretation
}


export default connect(mapStateToProps, mapDispatchToProps)(TargetLinePositionOptions);