import { swingbotApi } from '../../util/api';

export const updateWebhookForLicenseeCampaign = (licenseeCampaignId, endpoint) => {
    return swingbotApi(`licensee-campaign/${licenseeCampaignId}/webhooks`, { endpoint }, 'put', true, 'licensee')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const updateWebhooksForLicensee = (licenseeId, endpoint) => {
    return swingbotApi(`licensee/${licenseeId}/webhooks`, { endpoint }, 'put', true, 'licensee')
        .then(response => response)
        .catch(err => Promise.reject(err));
};