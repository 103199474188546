import React from 'react';
import { 
    HeaderContainer,
    Footer,
    HeroPricing,
    Pricing
} from '../home';

class PricingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        }
    }

    render() {
        return (
            <div>
            <HeaderContainer>
                <HeroPricing />
                <Pricing />
                <Footer />
            </HeaderContainer>
            </div>
        );
    }
}

export default PricingPage;