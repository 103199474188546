import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { PageHeader, DashboardHeader } from './';
import { selectors as authSelectors } from '../../reducers/auth';
class DashboardContent extends React.Component {
    handleBack = () => {
       this.props.history.goBack();
    }

    render() {
        const { 
            title, 
            buttonTitle, 
            onClick, 
            buttonClassColor, 
            subtitle, 
            backTitle, 
            onClickBackButton,
            docPage,
        } = this.props;
        return (
            <div className="bg-gray-200 p-0 m-0 flex-1 flex-col h-full min-h-screen">
                <DashboardHeader />
                {this.props.title !== null && (
                    <PageHeader 
                        back={backTitle !== null}
                        title={title} 
                        subtitle={subtitle}
                        buttonTitle={buttonTitle}
                        onClick={onClick}
                        onClickBackButton={onClickBackButton}
                        buttonClassColor={buttonClassColor}
                        docPage={docPage}
                    />
                )}
                <main className="flex-1 flex-col relative z-0 overflow-y-auto pb-6 focus:outline-none min-h-full w-full h-full" tabIndex="0">
                    <div className="flex flex-1 flex-col w-full max-w-7xl mx-auto px-0 lg:px-4 py-4 min-h-full">
                        {this.props.children}
                    </div>
                </main>
                {/* <DashboardFooter /> */}
                </div>
        )
    }
}

DashboardContent.defaultProps = {
    title: null,
    subtitle: null,
    children: [],
    backTitle: null,
    buttonTitle: null,
    buttonClassColor: 'green',
    onClick(){},
    onClickBackButton: null,
    debugHeader: true,
    docPage: null
}

const mapStateToProps = ({ auth }) => {
    return {
        planInformation: authSelectors.planInformation(auth)
    };
};

// export default withRouter(DashboardContent);
export default connect(mapStateToProps, {})(withRouter(DashboardContent));