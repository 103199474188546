/**
 * AuthReducer
 *
 * Fetch the list of users from the database
 */
import * as actionTypes from '../actions/auth/actionTypes';

  // set the initial state of the reducer
    const INITIAL_STATE = {
        authUser:{},
        loadingLoginUser: false,
        loginUserError: null,

        loadingRegisterUser: false,
        registerUserError: null,
        registerUserSuccess: null,

        activateLicenseeSuccess: null,
        loadingActivateUser: true,
        loadingActivateUserError: null,

        registerAndActivateLicenseeComplete: null,
        registerAndActivateLicenseeLoading: false,
        registerAndActivateLicenseeError: null,

        token: '',

        checkingToken: null,
        checkingTokenError: null,

        forgotPasswordSuccess: null,
        forgotPasswordLoading: false,
        forgotPasswordError: null,

        resetPasswordSuccess: null,
        resetPasswordLoading: false,
        resetPasswordError: null,
    };
  
   export default (state = INITIAL_STATE, action) => {
     switch (action.type) {
       /**
        * checking token...
        */
       case actionTypes.AUTH_CHECK_TOKEN:
         return {
           ...state,
           checkingToken: true,
           checkingTokenError: null
         }
       /**
        * check token success!
        */
       case actionTypes.AUTH_CHECK_TOKEN_SUCCESS:
         return {
           ...state,
           authUser: action.payload.data,
           checkingToken: null,
           checkingTokenError: null
         }
       /**
        * check token fail
        */
       case actionTypes.AUTH_CHECK_TOKEN_FAIL:
         return {
           ...state,
           authUser: {},
           checkingToken: null,
           checkingTokenError: action.payload
         }
       /**
        * login user
        */
       case actionTypes.LOGIN_USER_RESET:
        return {
          ...state,
          // same data as logout...
          authUser: {},
          activateLicenseeSuccess: null,
          loadingActivateUser: false,
          activateLicenseeError: null,
          loadingLoginUser: false,
          loginUserError: null,
          token: null,
        }

       case actionTypes.LOGIN_USER:
         return {
           ...state,
           authUser: {},
           loadingLoginUser: true,
           loginUserError: null,
           // reset these in case!
            activateLicenseeSuccess: null,
            loadingActivateUser: false,
            loadingActivateUserError: null,
         }
  
       case actionTypes.LOGIN_USER_SUCCESS:
        return {
          ...state,
          authUser: action.payload,
          loadingLoginUser: false,
          loginUserError: null,
          token: action.payload.token,
          activateLicenseeSuccess: null,
          loadingActivateUser: false,
          activateLicenseeError: null,
        }

       case actionTypes.LOGIN_USER_FAIL:
        return {
          ...state,
          authUser: {},
          loadingLoginUser: false,
          loginUserError: action.payload,
          // reset these in case!
          activateLicenseeSuccess: null,
          loadingActivateUser: false,
          loadingActivateUserError: null,
        }

        case actionTypes.LOGOUT_USER:
          return {
              ...state,
              authUser: {},
              activateLicenseeSuccess: null,
              loadingActivateUser: false,
              activateLicenseeError: null,

              loadingLoginUser: false,
              loginUserError: null,
              token: null,
          }

        case actionTypes.REGISTER_LICENSEE:
         return {
          ...state,
          authUser: {},
          loadingRegisterUser: true,
          registerUserError: null,
          registerUserSuccess: null,
          // reset
          activateLicenseeSuccess: null,
          loadingActivateUser: false,
          activateLicenseeError: null,
         }
  
       case actionTypes.REGISTER_LICENSEE_SUCCESS:
        return {
          ...state,
          authUser: action.payload,
          loadingRegisterUser: false,
          registerUserError: null,
          registerUserSuccess: true,
          token: action.payload.token,
          // reset
          activateLicenseeSuccess: null,
          loadingActivateUser: false,
          activateLicenseeError: null,
        }

       case actionTypes.REGISTER_LICENSEE_FAIL:
        return {
          ...state,
          authUser: {},
          loadingRegisterUser: false,
          registerUserError: action.payload,
          registerUserSuccess: null,
        }

        case actionTypes.REGISTER_LICENSEE_RESET:
          return {
            ...state,
            authUser: {},
            loadingRegisterUser: false,
            registerUserError: null
          }


          case actionTypes.REGISTER_AND_ACTIVATE_LICENSEE:
            return {
              ...state,
              authUser: {},
              registerAndActivateLicenseeComplete: null,
              registerAndActivateLicenseeLoading: true,
              registerAndActivateLicenseeError: null,
              // reset
              activateLicenseeSuccess: null,
              loadingActivateUser: false,
              activateLicenseeError: null,
            }
     
          case actionTypes.REGISTER_AND_ACTIVATE_LICENSEE_SUCCESS:
           return {
             ...state,
             authUser: action.payload.data,
             registerAndActivateLicenseeComplete: action.payload,
             registerAndActivateLicenseeLoading: false,
             registerAndActivateLicenseeError: null,
             // set the token here as well
             token: action.payload.token,
             // reset
             activateLicenseeSuccess: null,
             loadingActivateUser: false,
             activateLicenseeError: null,
           }
   
          case actionTypes.REGISTER_AND_ACTIVATE_LICENSEE_FAIL:
           return {
             ...state,
             authUser: {},
             registerAndActivateLicenseeComplete: null,
             registerAndActivateLicenseeLoading: false,
             registerAndActivateLicenseeError: action.payload,
           }
   
           case actionTypes.REGISTER_AND_ACTIVATE_LICENSEE_RESET:
             return {
              ...state,
              authUser: {},
              registerAndActivateLicenseeComplete: null,
              registerAndActivateLicenseeLoading: false,
              registerAndActivateLicenseeError: null,
             }

        case actionTypes.ACTIVATE_LICENSEE:
         return {
           ...state,
           activateLicenseeSuccess: null,
           loadingActivateUser: true,
           activateLicenseeError: null
         }
  
       case actionTypes.ACTIVATE_LICENSEE_SUCCESS:
        return {
          ...state,
          activateLicenseeSuccess: action.payload,
          loadingActivateUser: false,
          activateLicenseeError: null,
        }

       case actionTypes.ACTIVATE_LICENSEE_FAIL:
        return {
          ...state,
          activateLicenseeSuccess: null,
          loadingActivateUser: false,
          activateLicenseeError: action.payload
        }

        case actionTypes.FORGOT_PASSWORD:
         return {
           ...state,
            forgotPasswordSuccess: null,
            forgotPasswordLoading: true,
            forgotPasswordError: null,
         }
  
       case actionTypes.FORGOT_PASSWORD_SUCCESS:
        return {
          ...state,
          forgotPasswordSuccess: action.payload,
          forgotPasswordLoading: false,
          forgotPasswordError: null,
        }

       case actionTypes.FORGOT_PASSWORD_FAIL:
        return {
          ...state,
          forgotPasswordSuccess: null,
          forgotPasswordLoading: false,
          forgotPasswordError: action.payload,
        }

      case actionTypes.FORGOT_PASSWORD_RESET:
        return {
          ...state,
            forgotPasswordSuccess: null,
            forgotPasswordLoading: false,
            forgotPasswordError: null,
        }

      case actionTypes.RESET_PASSWORD:
         return {
           ...state,
            resetPasswordSuccess: null,
            resetPasswordLoading: true,
            resetPasswordError: null,
         }
  
       case actionTypes.RESET_PASSWORD_SUCCESS:
        return {
          ...state,
          resetPasswordSuccess: action.payload,
          resetPasswordLoading: false,
          resetPasswordError: null,
        }

       case actionTypes.RESET_PASSWORD_FAIL:
        return {
          ...state,
          resetPasswordSuccess: null,
          resetPasswordLoading: false,
          resetPasswordError: action.payload,
        }

      case actionTypes.RESET_PASSWORD_RESET:
        return {
          ...state,
          resetPasswordSuccess: null,
          resetPasswordLoading: false,
          resetPasswordError: null,
        }

      case actionTypes.USER_SUBSCRIPTION_UPDATE:
        return {
          ...state,
          authUser: action.payload
        };

       default:
         return state;
     }
   };
  
   /**
    * selectors
    */
   export const selectors = {
     /**
      * planInformation
      * This will return the plan information for the user
      * It is here that we can alter to run tests...
      * @param {object} state 
      * @returns 
      */
     planInformation:(state) => {
      return state.authUser 
          ? {
            lessonsAllowed: state.authUser.plan_lessons_num,
            targetsAllowed: state.authUser.plan_lesson_targets_num,
            videosPerMonth: state.authUser.plan_videos_per_month,
            plan: state.authUser.plan_name,
            term: state.authUser.plan_term,
            planName: state.authUser.plan_video_package_name,
            planFeatureName: state.authUser.plan_feature_package_name,
            planNextBillingDate: state.authUser.plan_next_billing_date,
            planNextBillAmount: state.authUser.plan_next_bill_amount,
            planPaidThroughDate: state.authUser.plan_paid_through_date,
            subscriptionId: state.authUser.plan_subscription_id,
            subscriptionStatus: state.authUser.plan_subscription_status === 'Active',
            isCurrent: state.authUser.is_current
          } 
        : null;
     },
     isActive:(state) => {
       return state.authUser !== null 
        ? ('licensee_status' in state.authUser ? state.authUser.licensee_status === 'ACTIVE' : false)
        : false;
     }
   }