import React, { Component } from 'react'
class DragAndDrop extends Component {
  constructor(props) {
    super(props);
    this.hiddenFileInput = React.createRef();
  }
  state = {
    drag: false
  }
  dropRef = React.createRef()
  handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter++
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({drag: true})
    }
  }

  handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter--
    if (this.dragCounter === 0) {
      this.setState({drag: false})
    }
  }
  handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({drag: false})
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e.dataTransfer.files)
      e.dataTransfer.clearData()
      this.dragCounter = 0    
    }
  }
  componentDidMount() {
    let div = this.dropRef.current
    div.addEventListener('dragenter', this.handleDragIn)
    div.addEventListener('dragleave', this.handleDragOut)
    div.addEventListener('dragover', this.handleDrag)
    div.addEventListener('drop', this.handleDrop)
  }
  componentWillUnmount() {
    let div = this.dropRef.current
    div.removeEventListener('dragenter', this.handleDragIn)
    div.removeEventListener('dragleave', this.handleDragOut)
    div.removeEventListener('dragover', this.handleDrag)
    div.removeEventListener('drop', this.handleDrop)
  }

  handleClick = (event) => {
    this.hiddenFileInput.current.click();
  };

  handleCaptureChange = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const fileUploaded = e.target.files;
    const filesize = fileUploaded[0]['size'];
    if (filesize < 10000000) {
      this.setState({drag: false})
      if (fileUploaded) {
        this.props.handleDrop(fileUploaded)
        this.dragCounter = 0    
      }
    } else {
      console.log('error, file is too large');
    }
  }

  render() {
    const { uploading } = this.props;
    return uploading === false ? (
        <div ref={this.dropRef} className="w-full">
          <div className="sm:mt-0">
            <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
              <div className="text-center">
                <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48">
                  <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className="mt-1 text-sm text-gray-600">
                  {/* <button type="button" className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out">
                    Upload a file
                  </button> */}
                  <button type="button" onClick={this.handleClick} className="font-medium text-indigo-500 hover:text-indigo-700 focus:outline-none focus:underline transition duration-150 ease-in-out">
                    Upload a file
                  </button>
                  <input type="file" accept="video/*;capture=camcorder" onChange={this.handleCaptureChange} ref={this.hiddenFileInput} style={{ display: 'none' }} />
                  &nbsp;or drag and drop
                  &nbsp;or drag and drop
                </p>
                <p className="mt-1 text-xs text-gray-500">
                  Video File up to 10MB, 12 second max
                </p>
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
    ) : (
      <div className="w-full">
          <div className="sm:mt-0">
            <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
              <div className="text-center">
                <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48">
                  <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className="mt-1 text-xs text-indigo-700">
                  Uploading...
                </p>
              </div>
            </div>
          </div>
        </div>
    );
  }
}
DragAndDrop.defaultProps = {
  uploading: false,
}

export default DragAndDrop