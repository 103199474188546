import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools, faUpload, faThumbsUp, faArrowRight } from '@fortawesome/free-solid-svg-icons';
// import { handleScrollTo } from '../../../util/web';

class HowItWorks extends React.Component {

    render() {
        return (
            <div className="py-12 bg-gray-800">
                <div className="max-w-lg mx-auto px-4 sm:px-6 lg:max-w-screen-lg lg:px-8">
                    <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                    <div>
                        <div className="flex flex-col">
                            <div className="py-4 justify-start items-center">
                                <FontAwesomeIcon icon={faTools} className="text-gray-50 text-4xl transition ease-in-out duration-150" />
                            </div>
                            <div className="">
                                <h5 className="text-lg leading-6 text-gray-50 font-bold">Create a Lesson Program</h5>
                                <p className="mt-2 text-base leading-6 text-gray-200">
                                    Build your lesson from scratch or from our library of prebuilt analysis targets.
                                </p>
                                <div
                                    // onClick={() => handleScrollTo('features')}  
                                    className="flex flex-row justify-start items-center py-2"
                                >
                                    <p className="cursor-pointer mt-2 underline text-gray-400 text-md"><a href="/doc/lesson-program">Learn More</a></p>
                                    <FontAwesomeIcon icon={faArrowRight} className="mt-2 mx-2 text-gray-400 text-md transition ease-in-out duration-150" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-10 lg:mt-0">
                    <div className="flex flex-col">
                        <div className="py-4 justify-start items-center">
                            <FontAwesomeIcon icon={faUpload} className="text-gray-50 text-4xl transition ease-in-out duration-150" />
                        </div>
                        <div className="">
                            <h5 className="text-lg leading-6 text-gray-50 font-bold">Upload Swing Videos</h5>
                            <p className="mt-2 text-base leading-6 text-gray-200">
                                Use our <a href="/doc/sdk" className="text-green-100 underline">SDK</a> to send videos directly to SwingbotPro for analysis.
                            </p>
                            <div
                                // onClick={() => handleScrollTo('results')}  
                                className="flex flex-row justify-start items-center py-2"
                            >
                                <p className="cursor-pointer mt-2 underline text-gray-400 text-md"><a href="/doc/sdk/upload-video">Learn More</a></p>
                                <FontAwesomeIcon icon={faArrowRight} className="mt-2 mx-2 text-gray-400 text-md transition ease-in-out duration-150" />
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="mt-10 lg:mt-0">
                    <div className="flex flex-col">
                        <div className="py-4 justify-start items-center">
                            <FontAwesomeIcon icon={faThumbsUp} className="text-gray-50 text-4xl transition ease-in-out duration-150" />
                        </div>
                        <div className="">
                            <h5 className="text-lg leading-6 text-gray-50 font-bold">Get the Results</h5>
                            <p className="mt-2 text-base leading-6 text-gray-200">
                                Receive the results via email and to your web server in minutes.
                            </p>
                            <div 
                                // onClick={() => handleScrollTo('results')} 
                                className="flex flex-row justify-start items-center py-2"
                            >
                                <p className="cursor-pointer mt-2 underline text-gray-400 text-md"><a href="/doc/lesson-program#receiving-analysis-results">Learn More</a></p>
                                <FontAwesomeIcon icon={faArrowRight} className="mt-2 mx-2 text-gray-300 text-md transition ease-in-out duration-150" />
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HowItWorks;