import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DashboardContent } from '../tailwind';
import { getLicenseeAnalyses } from '../../actions/licensee';
import { selectors as licenseeSelectors } from '../../reducers/licensee';
import { Loading, Pagination } from '../common';
import SearchBar from '../common/SearchBar';
import VideoPanel from './video/VideoPanel';

import { capitalizeFirstLetter } from '../../util/strings';
class VideosPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            limit: 50,
            offset: 0,
            pageNum: 1,
            term: '',
            lessonProgramFilter: null,
            statusFilter: null
        }
    }

    componentDidMount = () => {
        const { authUser } = this.props;
        const { limit, offset } = this.state;
        this.props.getLicenseeAnalyses(authUser.licensee_id, limit, offset);
    }

    handlePageClick = (pageNum) => {
        if (pageNum > 0) {
            const { limit } = this.state;
            const offset = (limit * pageNum) - limit;
            const { authUser } = this.props;
            this.setState({ pageNum, offset });
            this.props.getLicenseeAnalyses(authUser.licensee_id, limit, offset);
        }
    }

    handleVideoClick = (videoId, videoData) => {
        this.props.history.push({
            pathname: `/videos/${videoId}`,
            state: { detail: videoData }   
        });
    }

    // handleUserClick = (userId) => {
    //     this.props.history.push({
    //         pathname: `/users/${userId}`,
    //         state: { userId: userId }
    //     });
    // }

    handleUserClick = (userId) => {
        const { authUser } = this.props;
        this.props.history.push({
            pathname: `/users/${userId}`,
            state: { 
                userId: userId,
                licenseeId: authUser.licensee_id
            }
        });
    }

    handleCampaignClick = (data) => {
        this.props.history.push({
            pathname: `lessons/${data.licensee_campaign_id}`,
            state: { data }
        })
    }

    handleSearch = (e) => {
        this.setState({
            term: e.target.value
        });
    }

    renderPanels = () => {
        const { analysesData } = this.props;
        return analysesData.data ?
            analysesData.data
                .filter(record => record.email.toLowerCase().indexOf(this.state.term) > -1)
                .filter(record => {
                    if (this.state.lessonProgramFilter !== null) {
                        return record['licensee_campaign_display_name'] === this.state.lessonProgramFilter;
                    } else {
                        return true;
                    }
                })
                .filter(record => {
                    if (this.state.statusFilter !== null) {
                        return record['status'].toLowerCase() === this.state.statusFilter;
                    } else {
                        return true;
                    }
                })
                .map((record, index) => {
                    return (
                        <VideoPanel 
                            key={`video-${index}`}
                            record={record}
                            onVideoClick={this.handleVideoClick}
                            onUserClick={this.handleUserClick}
                        />
                    );
            }) : null;
    }

    goToVideoDocumentation = () => {
        this.props.history.push({
            pathname: '/doc/sdk/upload-video'
        });
    }

    renderLessonProgramFilters = () => {
        const programs = [];
        const programCount = {};
        const { analysesData } = this.props;
        if (analysesData !== null) {
            analysesData.data.forEach(rec => {
              if (programs.indexOf(rec['licensee_campaign_display_name']) < 0) {
                  programs.push(rec['licensee_campaign_display_name']);
                  programCount[rec['licensee_campaign_display_name']] = 1;
              } else {
                programCount[rec['licensee_campaign_display_name']] += 1;
              }
            });
            return programs.map((program, index) => {

                console.log(program);
                return (
                    <li 
                        key={`${program}-key`}
                        onClick={() => this.setState({ lessonProgramFilter: program })}
                        className="hover:underline cursor-pointer"
                    >
                        {program} ({programCount[program]})
                    </li>
                );
            });
        }
        return null;
    }

    renderLessonStatusFilters = () => {
        const statuses = [];
        const statusCount = {};
        const { analysesData } = this.props;
        if (analysesData !== null) {
            analysesData.data.forEach(rec => {
              if (statuses.indexOf(rec['status']) < 0) {
                  statuses.push(rec['status']);
                  statusCount[rec['status']] = 1;
              } else {
                statusCount[rec['status']] += 1;
              }
            });
            return statuses.map(status => {
                return (
                    <li
                        key={`${status}-key`}
                        onClick={() => this.setState({ statusFilter: status.toLowerCase() })}
                        className="hover:underline cursor-pointer"
                    >
                        {capitalizeFirstLetter(status.toLowerCase())} ({statusCount[status]})
                    </li>
                );
            });
        }
        return null;
    }


    render() {
        const {
            analysesData
        } = this.props;
        return (
            <DashboardContent title="Swings">
                {/* <div className="flex flex-col w-full flex-0">
                    <SearchBar term={this.state.term} onChange={this.handleSearch} />
                    <div className="mt-4 bg-white rounded shadow">
                        {analysesData !== null && (<Pagination 
                            total={analysesData.count}
                            offset={this.state.offset}
                            limit={this.state.limit}
                            pageNum={this.state.pageNum}
                            next={this.handlePageClick}
                            prev={this.handlePageClick}
                        />)}
                    </div>
                </div> */}
                {analysesData !== null && analysesData.loading === false && (
                    <div className="flex flex-col w-full">
                    <div className="flex flex-col mt-4 w-full">
                        <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        {analysesData.data !== null && analysesData.data.length > 0 && (
                            <div className="flex flex-col lg:flex-row w-full space-x-0 lg:space-x-4 xl:space-x-4">
                                    <div className="w-full xl:w-1/4 lg:w-1/4 space-y-4 text-sm text-gray-600 mb-4">
                                        <div className="bg-white text-sm rounded shadow p-4">
                                            <h3 className="font-bold">Lesson Programs</h3>
                                            <ul>
                                                <li 
                                                    onClick={() => this.setState({ lessonProgramFilter: null })}
                                                    className="hover:underline cursor-pointer"
                                                >
                                                    All ({analysesData.data.length})
                                                </li>
                                                {this.renderLessonProgramFilters()}
                                            </ul>
                                        </div>
                                        <div className="bg-white text-sm rounded shadow p-4">
                                            <h3 className="font-bold">Status</h3>
                                            <ul>
                                                <li 
                                                    onClick={() => this.setState({ statusFilter: null })}
                                                    className="hover:underline cursor-pointer"
                                                >
                                                    All ({analysesData.data.length})
                                                </li>
                                                {this.renderLessonStatusFilters()}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="flex w-full lg:w-3/4 xl:w-3/4">
                                        <div className="grid grid-flow-row grid-cols-1 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 auto-cols-max w-full col-gap-3 row-gap-3">
                                            {this.renderPanels()}
                                        </div>
                                    </div>
                            </div>
                        )}
                        {analysesData !== null && analysesData.length === 0 && (
                                    <div className="bg-white rounded border border-gray-200 mt-4 shadow">
                                    <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                                    <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-700 sm:text-4xl sm:leading-10">
                                        No Swings Found
                                    </h2>
                                    <h4 className="text-xl leading-9 font-bold tracking-tight text-gray-500 sm:text-4xl sm:leading-10">Swings will appear here when you upload videos for processing.</h4>
                                    
                                        <div className="mt-8 flex justify-center">
                                            <div className="inline-flex rounded-md shadow">
                                                <div onClick={this.goToVideoDocumentation} className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out cursor-pointer">
                                                    Learn More about Uploading Video
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="mt-4 bg-white rounded shadow">
                        <Pagination 
                            total={analysesData.count}
                            offset={this.state.offset}
                            limit={this.state.limit}
                            pageNum={this.state.pageNum}
                            next={this.handlePageClick}
                            prev={this.handlePageClick}
                        />
                    </div>
                    </div>
                )}
                {analysesData.loading === true && <Loading title="Loading Swing Videos" />}
            </DashboardContent>
        )
    }
}

const mapStateToProps = ({ licensee, auth }) => {
    const { authUser } = auth;
    return {
        authUser,
        analysesData: licenseeSelectors.analysesData(licensee),
    };
}

const mapDispatchToProps = {
    getLicenseeAnalyses
};
const videosWithRouter = withRouter(VideosPage);
export default connect(mapStateToProps, mapDispatchToProps)(videosWithRouter);