
import * as actionTypes from '../actions/targetInterpretation/types';
import * as authActionTypes from '../actions/auth/actionTypes';

const initialState = {
    targetInterpretations: null,
    getTargetInterpretationsLoading: false,
    getTargetInterpretationsError: false,

    targetInterpretationProgramData: null,
    getTargetInterpretationProgramLoading: false,
    getTargetInterpretationProgramError: false,

    targetInterpretationData: null,
    getTargetInterpretationTargetLoading: false,
    getTargetInterpretationTargetError: false,

    createTargetInterpretationSuccess: null,
    createTargetInterpretationLoading: false,
    createTargetInterpretationError: false,

    removeTargetInterpretationSuccess: null,
    removeTargetInterpretationLoading: true,
    removeTargetInterpretationError: false,
};

function targetInterpretation(state = initialState, action) {
    switch(action.type) {

        case authActionTypes.LOGOUT_USER:
            return {
                ...state,
                targetInterpretations: null,
                getTargetInterpretationsLoading: false,
                getTargetInterpretationsError: false,
            
                targetInterpretationProgramData: null,
                getTargetInterpretationProgramLoading: false,
                getTargetInterpretationProgramError: false,
            
                targetInterpretationData: null,
                getTargetInterpretationTargetLoading: false,
                getTargetInterpretationTargetError: false,
            
                createTargetInterpretationSuccess: null,
                createTargetInterpretationLoading: false,
                createTargetInterpretationError: false,

                removeTargetInterpretationSuccess: null,
                removeTargetInterpretationLoading: true,
                removeTargetInterpretationError: false,
            }

        case actionTypes.GET_TARGET_INTERPRETATIONS:
            return {
                ...state,
                targetInterpretations: null,
                getTargetInterpretationsLoading: true,
                getTargetInterpretationsError: false,
            };

        case actionTypes.GET_TARGET_INTERPRETATIONS_SUCCESS:
            return {
                ...state,
                targetInterpretations: action.payload,
                getTargetInterpretationsLoading: false,
                getTargetInterpretationsError: false,
            };

        case actionTypes.GET_TARGET_INTERPRETATIONS_FAIL:
            return {
                ...state,
                targetInterpretations: null,
                getTargetInterpretationsLoading: false,
                getTargetInterpretationsError: action.payload,
            };

        case actionTypes.GET_TARGET_INTERPRETATIONS_BY_PROGRAM:
            return {
                ...state,
                targetInterpretationProgramData: null,
                getTargetInterpretationProgramLoading: true,
                getTargetInterpretationProgramError: false,
            }

        case actionTypes.GET_TARGET_INTERPRETATIONS_BY_PROGRAM_SUCCESS:
            return {
                ...state,
                targetInterpretationProgramData: action.payload,
                getTargetInterpretationProgramLoading: false,
                getTargetInterpretationProgramError: false,
            }

        case actionTypes.GET_TARGET_INTERPRETATIONS_BY_PROGRAM_FAIL:
            return {
                ...state,
                targetInterpretationProgramData: null,
                getTargetInterpretationProgramLoading: false,
                getTargetInterpretationProgramError: action.payload,
            }

        case actionTypes.GET_TARGET_INTERPRETATIONS_BY_TARGET:
            return {
                ...state,
                targetInterpretationTargetData: null,
                getTargetInterpretationTargetLoading: true,
                getTargetInterpretationTargetError: false,
            }

        case actionTypes.GET_TARGET_INTERPRETATIONS_BY_TARGET_SUCCESS:
            return {
                ...state,
                targetInterpretationTargetData: action.payload,
                getTargetInterpretationTargetLoading: false,
                getTargetInterpretationTargetError: false,
            }

        case actionTypes.GET_TARGET_INTERPRETATIONS_BY_TARGET_FAIL:
            return {
                ...state,
                targetInterpretationTargetData: null,
                getTargetInterpretationTargetLoading: false,
                getTargetInterpretationTargetError: action.payload,
            }

        case actionTypes.CREATE_TARGET_INTERPRETATION:
            return {
                ...state,
                createTargetInterpretationSuccess: null,
                createTargetInterpretationLoading: true,
                createTargetInterpretationError: false,
            }

        case actionTypes.CREATE_TARGET_INTERPRETATION_SUCCESS:
            return {
                ...state,
                createTargetInterpretationSuccess: action.payload,
                createTargetInterpretationLoading: false,
                createTargetInterpretationError: false,
            }

        case actionTypes.CREATE_TARGET_INTERPRETATION_FAIL:
            return {
                ...state,
                createTargetInterpretationSuccess: null,
                createTargetInterpretationLoading: false,
                createTargetInterpretationError: action.payload,
            }

        case actionTypes.RESET_CREATE_TARGET_INTERPRETATION:
            return {
                ...state,
                createTargetInterpretationSuccess: null,
                createTargetInterpretationLoading: false,
                createTargetInterpretationError: false,
            }
        
        case actionTypes.REMOVE_TARGET_INTERPRETATION_RESET:
            return {
                ...state,
                removeTargetInterpretationSuccess: null,
                removeTargetInterpretationLoading: false,
                removeTargetInterpretationError: null,
            }
            
        case actionTypes.REMOVE_TARGET_INTERPRETATION:
            return {
                ...state,
                removeTargetInterpretationSuccess: null,
                removeTargetInterpretationLoading: true,
                removeTargetInterpretationError: false,
            }

        case actionTypes.REMOVE_TARGET_INTERPRETATION_SUCCESS:
            return {
                ...state,
                removeTargetInterpretationSuccess: action.payload,
                removeTargetInterpretationLoading: false,
                removeTargetInterpretationError: false,
            }

        case actionTypes.REMOVE_TARGET_INTERPRETATION_FAIL:
            return {
                ...state,
                removeTargetInterpretationSuccess: null,
                removeTargetInterpretationLoading: false,
                removeTargetInterpretationError: action.payload,
            }

        
        default:
            return state;
    }
}

export default targetInterpretation;