import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { registerLicensee, resetRegisterLicensee } from '../../actions/auth';
import { getLicenseeDataByEmail } from '../../actions/licensee';

import queryString from 'query-string';
import Loading from '../common/Loading';
import Button from '../common/Button';
class ConfirmCreatePassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            terms: null,
            error: null,
        };
    }

    componentDidMount = () => {
        const queryParams = queryString.parse(this.props.location.search);
        // set the email here in the state
        this.setState({ email: queryParams.email });
        // this.props.getLicenseeDataByEmail(queryParams.email);
        // lets reset redux...
        this.props.resetRegisterLicensee();
    }

    handleOnChangeEmail = (e) => {
        this.props.resetRegisterLicensee();
        this.setState({
            email: e.target.value
        });
    }

    handleOnChangePassword = (e) => {
        this.setState({
            password: e.target.value
        });
    }

    handleTerms = (e) => {
      this.setState({
        terms: e.target.checked
      });
    }

    handleRegister = (e) => {
        e.preventDefault();
        const { email, password, terms } = this.state;
        if (email !== '' && password !== '' && terms !== null) {
            this.props.registerLicensee(email, password, this.props.history);
        } else {
            this.setState({
              error: true
            });
        }
    }

    handleGoogleLogin = (e) => {
        e.preventDefault();
    }

    render () {
        const {
          loadingRegisterUser,
          registerUserError,
        } = this.props;
        
        return this.state.email !== '' ? (
            <div className="min-h-screen bg-gray-800 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <img className="mx-auto h-12 w-auto rounded" src={require('../../img/logo.png')} alt="Swingbot" />
              <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-white">
                You're Almost Done!
              </h2>
              <p className="mt-2 text-center text-sm leading-5 text-gray-200 max-w">
                Choose a password to create your free Swingbot Pro account.
              </p>
            </div>
          
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

                  {(registerUserError !== null || this.state.error !== null) && (
                      <div className="rounded-md bg-red-50 p-4 mb-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <svg className="h-5 w-5 text-red-400" fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd"/>
                          </svg>
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm leading-5 font-medium text-red-800">
                            {registerUserError.error === 'Email already exists' && (
                              <p>You already have an account, <Link to="/login">click here to login</Link>.</p>
                            )}
                          </h3>
                        </div>
                      </div>
                    </div>
                  )}
                <form action="#" method="POST">
                  <input type="text" hidden autoComplete="username" value={this.state.email} readOnly/>
                  <div className="mt-0">
                    <label htmlFor="new-password" className="block text-sm font-medium leading-5 text-gray-700">
                      Password
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input onChange={this.handleOnChangePassword} id="new-password" name="new-password" autoComplete="new-password" type="password" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                    </div>
                  </div>
                {loadingRegisterUser === false && (
                    <div className="mt-6">
                        <Button 
                          onClick={this.handleRegister}
                          title="Click to Create Free Account"
                          bgColor="green"
                          hoverColor="green"
                          block
                        />
                    </div>
                )}
                {loadingRegisterUser === true && (
                    <div className="mt-6">Creating account...</div>
                )}
                </form>

                <div className="mt-6 flex items-center justify-between">
                    <div className="flex items-start">
                      <input id="terms" type="checkbox" onChange={this.handleTerms} className="form-checkbox h-4 w-4 text-green-600 transition duration-150 ease-in-out mt-1" />
                      <label htmlFor="remember_me" className="ml-2 block text-xs leading-5 text-gray-500">
                        By creating an account I agree to the <Link to="/terms" className="text-green-400">Terms of Service</Link>.
                      </label>
                    </div>
                  </div>


              </div>
            </div>
          </div>
        ) : <Loading />;
    }
}

ConfirmCreatePassword.defaultProps = {
    onClick() {}
}

const mapStateToProps = ({ auth, licensee }) => {
    const {
      authUser,
      loadingRegisterUser,
      registerUserError,
    } = auth;
    const {
      licenseeDataByEmail,
      loadingLicenseeDataByEmail,
      loadingLicenseeDataByEmailError
    } = licensee;
    return {
        authUser,
        loadingRegisterUser,
        registerUserError,
        licenseeDataByEmail,
        loadingLicenseeDataByEmail,
        loadingLicenseeDataByEmailError
    }
}

const mapDispatchToProps = {
    registerLicensee,
    resetRegisterLicensee,
    getLicenseeDataByEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfirmCreatePassword));