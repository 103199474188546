import React from 'react';

class Features extends React.Component {
    render() {
        return (
            <div id="features" className="bg-white border border-b-1 border-gray-100">
                <div className="max-w-screen-lg mx-auto pt-12 pb-16 sm:pt-16 sm:pb-20 px-4 sm:px-6 lg:pt-20 lg:pb-28 lg:px-8">
                    <div className="w-full">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
                            <div className="col-span-2 lg:p-6">
                                <div className="prose prose-lg">
                                    <h1>
                                        Custom Lesson Programs
                                    </h1>
                                    {/* <h4>Our software will detect the body points and positions, and you control the rest. </h4> */}
                                    <h4>Create Lesson Programs for anything you desire, from Fixing a slice to gaining distance off the tee, it's up to you.</h4>
                                    <p>We want you to be in control of the entire analysis from start to finish for your students, we just manage the technology so you don't have to.</p>
                                </div>
                                <div className="rounded-md text-white mt-12">
                                    <a href="#get-started" className="items-center justify-center px-8 py-3 leading-6 rounded-md text-white bg-green-600 hover:bg-green-700 transition duration-150 ease-in-out text-lg md:py-4 xl:text-xl md:text-lg md:px-10 cursor-pointer font-bold no-underline">
                                    Click to Get Started
                                    </a>
                                </div>
                            </div>
                            {/* `<div className="flex-1 col-span-1 justify-center text-center align-middle">
                                <img className="object-contain" src={require('../../../img/screenshots/program-home.png')} alt="features" />
                            </div>` */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Features;