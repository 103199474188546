export const GET_WEBSITE = 'website/get';
export const GET_WEBSITE_SUCCESS = 'website/get-success';
export const GET_WEBSITE_FAIL = 'website/get-fail';

export const UPDATE_WEBSITE = 'website/update-website';
export const UPDATE_WEBSITE_SUCCESS = 'website/update-website-success';
export const UPDATE_WEBSITE_FAIL = 'website/update-website-fail';

export const CREATE_WEBSITE = 'website/create';
export const CREATE_WEBSITE_SUCCESS = 'website/create-success';
export const CREATE_WEBSITE_FAIL = 'website/create-fail';

export const RESET_STRIPE_CONNECT_ACCOUNT = 'website/reset-stripe-account';

export const GET_STRIPE_ACCOUNT = 'website/get-stripe-account';
export const GET_STRIPE_ACCOUNT_SUCCESS = 'website/get-stripe-account-success';
export const GET_STRIPE_ACCOUNT_FAIL = 'website/get-stripe-account-fail';

export const CREATE_STRIPE_ACCOUNT = 'website/create-stripe-account';
export const CREATE_STRIPE_ACCOUNT_SUCCESS = 'website/create-stripe-account-success';
export const CREATE_STRIPE_ACCOUNT_FAIL = 'website/create-stripe-account-fail';

export const LINK_STRIPE_ACCOUNT = 'website/link-stripe-account';
export const LINK_STRIPE_ACCOUNT_SUCCESS = 'website/link-stripe-account-success';
export const LINK_STRIPE_ACCOUNT_FAIL = 'website/link-stripe-account-fail';