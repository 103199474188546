import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container } from '../../tailwind';
import { createTarget, resetCreateTarget } from '../../../actions/target';
class LessonTargetCreate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        swingTypeId: 1,
    }
  }

  componentDidMount = () => {
    this.props.resetCreateTarget();
  }

  handleFieldChange = (e) => {
    this.setState({
        [e.target.id]: e.target.value
    });
  }

  handleCameraAngle = (swingTypeId) => {
    this.setState({
        swingTypeId: swingTypeId
    });
  }

  handleCreateTarget = () => {
    const { authUser } = this.props;
    const userId = authUser.user_id;
    const textDefinition = this.state.textDefinition;
    
    const swingTypeId = this.props.cameraAngle;//this.state.swingTypeId;
    const displayName = this.state.displayName;
    const programId = this.props.programId;
    // console.log('creating for program : ' , this.props.program);
    // lets try and create the target...

    this.props.createTarget(
        programId, 
        userId, 
        displayName, 
        swingTypeId, 
        textDefinition, 
        this.props.history
    );
  }

  cancelTarget = () => {
    this.props.onClickCancel();
  }

  render() {
      const { createTargetLoading, createTargetSuccess } = this.props;
      const { cameraAngle } = this.props;
      console.log(this.props);
      return (
        <div className="flex-1">
             {createTargetSuccess === null && (
                 <Container className="flex flex-col w-full flex-1">
                  <div className="flex flex-col w-full p-2">
                        <div className="flex flex-col flex-1 w-full ">
                        <div className="flex flex-col flex-1 p-2 w-full ">
                            <h3 className="text-2xl font-bold leading-6 text-gray-900">
                            Create New Lesson Target
                            </h3>
                            <p className="mt-1 text-sm leading-5 text-gray-500">
                            Create a new target to be analyzed. Example: "Stance at Address"
                            </p>
                        </div>
                        <div className="p-2 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                            <label htmlFor="name" className="block text-sm font-medium leading-5 text-gray-700">
                                Display Name
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <input 
                                    id="displayName" 
                                    className="flex-1 form-input block w-full min-w-0 rounded transition duration-150 ease-in-out sm:text-sm sm:leading-5  bg-gray-50" 
                                    placeholder="Stance at Address"
                                    onChange={this.handleFieldChange}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-6">
                            <label htmlFor="about" className="block text-sm font-medium leading-5 text-gray-700">
                                Definition
                            </label>
                            <div className="mt-1 rounded-md shadow-sm">
                                <textarea 
                                    id="textDefinition" 
                                    onChange={this.handleFieldChange} 
                                    rows="3" 
                                    className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-gray-600 bg-gray-50" 
                                    placeholder="Example: For this analysis, we will be analyzing your stance at address..." />
                            </div>
                            <p className="mt-2 text-sm text-gray-500">Write a few sentences describing what is being analyzed.
                            </p>
                        </div>
                        {cameraAngle === null && (
                        <div className="sm:col-span-6">
                            <label htmlFor="name" className="block text-sm font-medium leading-5 text-gray-700">
                                Camera Angle
                            </label>
                            <div className="mt-4 flex items-center hover:bg-gray-100 rounded p-4">
                                <input id="draw" name="form-input lesson_output" type="radio" className="form-radio h-4 w-4 text-green-600 transition duration-150 ease-in-out" checked={this.state.swingTypeId === 1} onChange={() => this.handleCameraAngle(1)} />
                                <label htmlFor="draw" className="ml-3">
                                    <span className="block text-base leading-5 text-gray-600 font-bold">Face On Camera Angle</span>
                                </label>
                            </div>
                            <div className="mt-4 flex items-center hover:bg-gray-100 rounded p-4">
                                <input id="draw" name="form-input lesson_output" type="radio" className="form-radio h-4 w-4 text-green-600 transition duration-150 ease-in-out" checked={this.state.swingTypeId === 2} onChange={() => this.handleCameraAngle(2)} />
                                <label htmlFor="draw" className="ml-3">
                                    <span className="block text-base leading-5 text-gray-600 font-bold">Down the Line Camera Angle</span>
                                </label>
                            </div>
                        </div>
                        )}
                    </div>
                </div>
                    <div className="mt-8 border-t border-gray-200 pt-5">
                        {createTargetLoading === false && (
                        <div className="flex justify-end">
                            <span className="inline-flex rounded-md shadow-sm">
                                <button 
                                    type="button" 
                                    className="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
                                    onClick={this.cancelTarget}
                                >
                                Cancel
                                </button>
                            </span>
                            <span className="ml-3 inline-flex rounded-md shadow-sm">
                                <button 
                                    type="button"
                                    className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out"
                                    onClick={this.handleCreateTarget}
                                >
                                Create New Target
                                </button>
                            </span>
                        </div>
                        )}
                        {createTargetLoading === true && (
                            <div>Loading</div>
                        )}
                    </div>
                </div>
                </Container>
             )}
             {createTargetSuccess !== null && (
                 <div className="rounded-md bg-green-50 p-4 mb-4 border-green-200 border-2">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <svg className="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"/>
                            </svg>
                        </div>
                        <div className="ml-3 flex-1 md:flex">
                            <p className="text-lg font-bold leading-5 text-green-700">
                                Target Created Successfully
                            </p>
                        </div>
                    </div>
                    <div className="pt-4 text-green-700">
                        <button 
                            type="button"
                            className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out"
                            onClick={() => this.props.onClickCancel()}
                        >
                            Back to Lesson
                        </button>
                    </div>
                </div>                
             )}
             </div>
      );
  }
}

LessonTargetCreate.defaultProps = {
  onClickCancel(){},
}

const mapStateToProps = ({ auth, target }) => {
    const { authUser } = auth;
    const {
        createTargetLoading,
        createTargetSuccess,
        createTargetError,
    } = target;
    return { 
        authUser, 
        createTargetLoading,
        createTargetSuccess,
        createTargetError
    };
}

const mapDispatchToProps = {
    createTarget,
    resetCreateTarget,
}

const LessonTargetCreateWithRouter = withRouter(LessonTargetCreate);
export default connect(mapStateToProps, mapDispatchToProps)(LessonTargetCreateWithRouter);