import React from 'react';
import ImageWithError from '../../common/ImageWithError';

class UserCard extends React.Component {
  render() {
    const { user, onClick } = this.props;
    const dateCreated = new Date(user.date_timestamp);
    return user !== null ? (
      <div 
        className="bg-white rounded p-6 col-span-1 flex flex-col cursor-pointer border-3 hover:border-gray-500" 
        key={user.user_id} 
        onClick={() => onClick(user.user_id)}
      >
        <div className="flex flex-row space-x-4 items-center">
          <div className="flex-shrink-0 h-16 w-16">
              <ImageWithError 
                  className="h-16 w-16 rounded-full" 
                  src={user.avatar} 
                  alt={user.full_name} 
                  errorSrc={require('../../../img/user.png')}
              />
          </div>
          <div className="flex flex-col">
            <div className="leading-5">
                <div className="text-lg text-gray-800 font-bold">
                    {user.email}
                </div>
                <div className="text-sm leading-5 text-gray-500">
                  Registered on {dateCreated.toLocaleDateString()}
                </div>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

UserCard.defaultProps = {
  user: null,
  onClick(){}
}

export default UserCard;