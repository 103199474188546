import React from 'react';
import { getDisplayForLineType } from '../../../../util/swingbot';

/**
 * TargetLineNavigationBar
 * Render the line numbers in "tabs" so the user can select them.
 * Also render a + button to create a new line
 */
class TargetLineNavigationBar extends React.Component {

  handleClickLine = (lineIndex) => {
    this.props.onLineChange(lineIndex);
  }

  handleCreateLine = () => {
    this.props.onLineCreate();
  }

  renderLineOptions = (lines, currentLineIndex) => {
    return lines.map((line, index) => (<option key={`${index}-line-option`}>{index + 1}</option>));
  }

  /**
   * renderLineMenu
   * @param {array} lines 
   * @param {int} currentLineIndex 
   * @returns 
   */
  renderLineMenu = (lines, currentLineIndex) => {
    return lines !== null ? lines.map((line, index) => {
      const css = index === currentLineIndex
        ? 'bg-gray-100 flex items-center text-gray-900 flex items-center px-3 py-2 text-sm font-medium rounded-md justify-between space-x-2 border'
        : 'text-gray-600 flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-gray-50 hover:text-gray-900 cursor-pointer justify-between space-x-2 border border-transparent';
      return (
        <div 
          onClick={() => this.handleClickLine(index)}
          className={css} 
          aria-current="page"
          key={`line-option-${index}`}
        >
          <span className="truncate text-xs">
            {getDisplayForLineType(line.type)}
          </span>
          <span className="bg-white ml-auto inline-block py-0.5 px-3 text-xs rounded-full" style={{ backgroundColor: line.color}}>
              &nbsp;
            </span>
        </div>
      );
    }) : null;
  }

  render () {
    const { lines, currentLineIndex, readOnly } = this.props;
    return (
    <div className="flex flex-row w-full min-w-64">
      <div className="hidden sm:block w-full">
        <div className="">
          <nav className="space-y-1 min-w-full" aria-label="Sidebar">
            {readOnly === false && (
              <div 
                onClick={() => this.handleCreateLine()} 
                className="text-white flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-blue-700 hover:text-white cursor-pointer border bg-blue-700" 
                aria-current="page"
              >
                <span className="truncate">
                  Create Line Annotation
                </span>
              </div>
            )}
            {lines !== null && this.renderLineMenu(lines, currentLineIndex)}
          </nav>
        </div>
      </div>
    </div>
    
    );
  }
}

TargetLineNavigationBar.defaultProps = {
  lines: [],
  currentLineIndex: 0,
  onLineCreate(){}
};

export default TargetLineNavigationBar;