import React from 'react';
import { 
    HeaderContainer,
    Footer,
    HeroApi,
    AboutApi
} from '.';

class ApiPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        }
    }

    render() {
        return (
            <div>
            <HeaderContainer>
              <HeroApi />
              <AboutApi />
              <Footer />
            </HeaderContainer>
            </div>
        );
    }
}

export default ApiPage;