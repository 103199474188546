
import * as actionTypes from '../actions/api/types';
import * as authActionTypes from '../actions/auth/actionTypes';

const initialState = {
    createApiKeyLoading: false,
    createApiKeySuccess: null,
    createApiKeyError: null
};

function api(state = initialState, action) {
    switch(action.type) {

        case authActionTypes.LOGOUT_USER:
            return {
                ...state,
                createApiKeyLoading: false,
                createApiKeySuccess: null,
                createApiKeyError: null
            }

        case actionTypes.CREATE_API_KEY:
            return {
                ...state,
                createApiKeyLoading: true,
                createApiKeySuccess: null,
                createApiKeyError: null
            };

        case actionTypes.CREATE_API_KEY_SUCCESS:
            return {
                ...state,
                createApiKeyLoading: false,
                createApiKeySuccess: action.payload,
                createApiKeyError: null
            };

        case actionTypes.CREATE_API_KEY_FAIL:
            return {
                ...state,
                createApiKeyLoading: false,
                createApiKeySuccess: null,
                createApiKeyError: action.payload
            };

        case actionTypes.CREATE_API_KEY_RESET:
            return {
                ...state,
                createApiKeyLoading: false,
                createApiKeySuccess: null,
                createApiKeyError: null
            };
        default:
            return state;
    }
}

export default api;