import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { registerLicensee, resetRegisterLicensee } from '../../actions/auth';
import { getLicenseeDataByEmail } from '../../actions/licensee';

import queryString from 'query-string';
import Loading from '../common/Loading';
import Button from '../common/Button';
class ConfirmEmailComplete extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            terms: null,
            error: null,
        };
    }

    componentDidMount = () => {
        const queryParams = queryString.parse(this.props.location.search);
        // set the email here in the state
        this.setState({ email: queryParams.email });
        // this.props.getLicenseeDataByEmail(queryParams.email);
        // lets reset redux...
        this.props.resetRegisterLicensee();
    }

    handleOnChangeEmail = (e) => {
        this.props.resetRegisterLicensee();
        this.setState({
            email: e.target.value
        });
    }

    handleOnChangePassword = (e) => {
        this.setState({
            password: e.target.value
        });
    }

    handleTerms = (e) => {
      this.setState({
        terms: e.target.checked
      });
    }

    handleRegister = (e) => {
        e.preventDefault();
        const { email, password, terms } = this.state;
        if (email !== '' && password !== '' && terms !== null) {
            this.props.registerLicensee(email, password, this.props.history);
        } else {
            this.setState({
              error: true
            });
        }
    }

    handleGoogleLogin = (e) => {
        e.preventDefault();
    }

    render () {
        return this.state.email !== '' ? (
            <div className="min-h-screen bg-gray-800 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <img className="mx-auto h-12 w-auto rounded" src={require('../../img/logo.png')} alt="Swingbot" />
              <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-white">
                You email is confirmed
              </h2>
            </div>
          
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <p className="my-4">Thank you for your interest in Swingbot Pro.</p>
              <Button 
                onClick={() => this.props.history.push('/login')} 
                title="Click to Login" 
                bgColor="green"
                hoverColor="green"
                block
              />
              </div>
            </div>
          </div>
        ) : <Loading />;
    }
}

ConfirmEmailComplete.defaultProps = {
    onClick() {}
}

const mapStateToProps = ({ auth, licensee }) => {
    const {
        authUser,
        loadingRegisterUser,
        registerUserError,
    } = auth;
    const {
      licenseeDataByEmail,
      loadingLicenseeDataByEmail,
      loadingLicenseeDataByEmailError
    } = licensee;
    return {
        authUser,
        loadingRegisterUser,
        registerUserError,
        licenseeDataByEmail,
        loadingLicenseeDataByEmail,
        loadingLicenseeDataByEmailError
    }
}

const mapDispatchToProps = {
    registerLicensee,
    resetRegisterLicensee,
    getLicenseeDataByEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfirmEmailComplete));