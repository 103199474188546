import * as actionTypes from './types';

export const analyzeVideo = (videoId) => ({
    type: actionTypes.ANALYZE_VIDEO,
    payload: videoId
});

export const analyzeVideoSuccess = (data) => ({
    type: actionTypes.ANALYZE_VIDEO_SUCCESS,
    payload: data
});

export const analyzeVideoFail = (error) => ({
    type: actionTypes.ANALYZE_VIDEO_FAIL,
    payload: error
});

export const resetAnalyzeVideo = () => ({
    type: actionTypes.ANALYZE_VIDEO_RESET,
    payload: null
});

/**
 * convert calculation to english
 */

export const convertCalculationToEnglish = (pseudo) => ({
    type: actionTypes.CONVERT_CALCULATION_TO_ENGLISH,
    payload: pseudo
});

export const convertCalculationToEnglishSuccess = (data) => ({
    type: actionTypes.CONVERT_CALCULATION_TO_ENGLISH_SUCCESS,
    payload: data
});

export const convertCalculationToEnglishFail = (error) => ({
    type: actionTypes.CONVERT_CALCULATION_TO_ENGLISH_FAIL,
    payload: error
});

export const convertEquationToEnglish = (pseudo1, operand, pseudo2) => ({
    type: actionTypes.CONVERT_EQUATION_TO_ENGLISH,
    payload: { pseudo1, operand, pseudo2 }
});

export const convertEquationToEnglishSuccess = (data) => ({
    type: actionTypes.CONVERT_EQUATION_TO_ENGLISH_SUCCESS,
    payload: data
});

export const convertEquationToEnglishFail = (error) => ({
    type: actionTypes.CONVERT_EQUATION_TO_ENGLISH_FAIL,
    payload: error
});