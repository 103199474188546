import React from 'react';
import WizardContainer from './WizardContainer';
class WizardTextInput extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            dirty: false,
        }
    }

    componentDidMount = () => {
        if (this.props.currentStep === this.props.step) {
            // focus this step...
            this[this.props.name].focus();
        }
    }

    componentDidUpdate = (nextProps) => {
        // we have to do this here because the step component
        // is already mounted...
        if (this.props.step === this.props.currentStep) {
            if (this.props.currentStep === this.props.step) {
                // need timer here...
                setTimeout(() => {
                    this[this.props.name].focus();
                }, 300)
            }
        }
    }

    handleKeyPress = (e) => {
        if (e.charCode === 13) {
            e.preventDefault();
            this.handleNextStep();
        }
    }

    handleKeyChange = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        let isValidEmail = false;
        if (this.props.type === 'email') {
            isValidEmail = this.validateEmail(value);
        }
        this.setState({
            [e.target.id]: e.target.value,
            isValidEmail // just in case this is an email
        }, () => {
            this.props.onChange({
                name,
                value
            });
        });
    }

    validateEmail = (value) => {
        // regex
        if (value) {
            var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            return value.match(mailformat) ? true : false;
        }
        return false;
    }


    handleNextStep = () => {
        if (this.props.optional === false) {
            if (this.state[this.props.name] !== undefined && this.state[this.props.name] !== null && this.state[this.props.name] !== '') {
                if (this.props.type === 'email' && this.state.isValidEmail === true) {
                    this.props.nextStep()
                } else if(this.props.type === 'text') {
                    // go to the next step...
                    this.props.nextStep();
                } else {
                    // nada...
                }
            } 
        } else {
            this.props.nextStep();
        }
    }

    render() {
        let cssColor = this.props.color;
        let isValidEmail = false;
        if (this.props.type === 'email' && this.state[this.props.name]) {
            isValidEmail = this.validateEmail(this.state[this.props.name]);
            cssColor = isValidEmail === true ? cssColor : 'red';
        }

        return (
            <WizardContainer 
                title={this.props.title} 
                text={this.props.text} 
                step={this.props.step}
                previousStep={this.props.previousStep}
                nextStep={this.props.nextStep}
            >
                <input 
                    id={this.props.name} 
                    ref={input => this[this.props.name] = input}
                    type={this.props.type}
                    required 
                    placeholder={this.props.placeholder}
                    onKeyPress={this.handleKeyPress}
                    onChange={this.handleKeyChange}
                    className={`w-full text-${cssColor}-600 mr-3 leading-tight focus:outline-none border-b-2 border-${cssColor}-500 text-2xl font-bold rounded-tr-lg rounded-tl-lg p-2 placeholder-gray-200 mb-4`} />
        
                <div className="flex flex-row justify-start items-center">
                <div 
                    disabled={this.state[this.props.name] === undefined}
                    onClick={this.handleNextStep} 
                    className="whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition ease-in-out duration-150 mr-2"
                >
                    Next
                </div>
                    <div className="text-xs text-gray-500">or <span className="font-bold">press Enter</span></div>
                </div>
            </WizardContainer>     
        );
    }
}

WizardTextInput.defaultProps = {
    step: 1,
    name: 'input',
    type: 'text',
    placeholder: '',
    title: '',
    color: 'green',
    optional: false,
    onChange(){}
}

export default WizardTextInput;