import React from 'react';
import { connect } from 'react-redux';
import { DashboardContent, Container } from '../../tailwind';
import { selectors as websiteSelectors } from '../../../reducers/website';
import { getStripeConnectedAccount } from '../../../actions/website';
import Loading from '../../common/Loading';
import Button from '../../common/Button';

class StripeLinkReturnPage extends React.Component {

    componentDidMount = () => {
        const { authUser, website } = this.props;
        const licenseeId = authUser.licensee_id;
        const accountId = website.stripe_acct_id;
        const sandbox = process.env.REACT_APP_ENV === 'development';
        console.log(licenseeId, accountId, sandbox);
        this.props.getStripeConnectedAccount(licenseeId, accountId, sandbox);
    }

    render() {
        const { stripeAccount } = this.props;
        console.log(stripeAccount);
        return (
            <DashboardContent title="Stripe Connection">
                <Container className="bg-indigo-600 w-full">
                    <div className="w-full">
                        {stripeAccount.loading === true && <Loading />}
                        {stripeAccount.success !== null && stripeAccount.loading === false && (
                            <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                                {stripeAccount.success.details_submitted === true && stripeAccount.success.charges_enabled === true && (
                                    <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10 text-white">
                                        Connection Complete
                                        <br />
                                        <span className="text-gray-300 text-xl font-normal">You are now able to accept payments.</span>
                                    </h2>
                                )}
                                {(stripeAccount.success.details_submitted === false || stripeAccount.success.charges_enabled === false) && stripeAccount.loading === false && (
                                    <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10 text-white">
                                        Connection Incomplete
                                        <br />
                                        <span className="text-gray-300 text-xl font-normal">Please visit the website to configure your Stripe account.</span>
                                    </h2>
                                )}
                                <div className="mt-8 flex justify-center">
                                <div className="inline-flex rounded-md shadow">
                                    <Button 
                                        title="Click to Return to Website" 
                                        onClick={() => this.props.history.push({
                                            pathname : '/website'
                                        })} 
                                        size="lg"
                                        bgColor="green"
                                        hoverColor="green"
                                    />
                                </div>
                            </div>
                        </div>
                        )}
                        {stripeAccount.success === null && stripeAccount.loading === false && (
                            <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                                <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10 text-white">
                                        Stripe Connection Incomplete
                                        <br />
                                        <span className="text-gray-300 text-xl font-normal">If you just connected your account, this may take a few moments to become live.</span>
                                </h2>
                                
                                <div className="mt-8 flex justify-center">
                                <div className="inline-flex rounded-md shadow">
                                    <Button 
                                        title="Click to Return to Website" 
                                        onClick={() => this.props.history.push({
                                            pathname : '/website'
                                        })} 
                                        size="lg"
                                        bgColor="green"
                                        hoverColor="green"
                                    />
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                </Container>
            </DashboardContent>
        );
    }
}

const mapStateToProps = ({ auth, website }) => {
    const { authUser } = auth;
    return {
        website: websiteSelectors.website(website),
        stripeAccount: websiteSelectors.stripeAccount(website),
        stripeConnect: websiteSelectors.stripeConnect(website),
        stripeLink: websiteSelectors.stripeLink(website),
        authUser,
    };
};

const mapDispatchToProps = {
    getStripeConnectedAccount
}

export default connect(mapStateToProps, mapDispatchToProps)(StripeLinkReturnPage);