import React from 'react';

class Pagination extends React.Component {
  render() {
    const { limit, offset, total, pageNum } = this.props;
    return (
      <nav className="bg-gray-800 px-2 py-3 flex items-center justify-between sm:px-4 rounded" aria-label="Pagination">
        <div className="hidden sm:block">
          <p className="text-sm text-gray-200 space-x-1">
            <span className="font-medium">Showing</span>
            <span className="font-bold">{ offset }</span>
            <span className="font-medium">to</span>
            <span className="font-bold">{ offset + limit  }</span>
            <span className="font-medium">of</span>
            <span className="font-bold">{ total }</span>
            <span className="font-medium">results</span>
          </p>
        </div>
        <div className="flex-1 flex justify-between sm:justify-end">
          <div 
            className="relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 cursor-pointer"
            onClick={() => this.props.prev(pageNum - 1)}
          >
            Previous
          </div>
          <div 
            className="ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 cursor-pointer"
            onClick={() => this.props.next(pageNum + 1)}
          >
            Next
          </div>
        </div>
      </nav>
      );
    }
}

Pagination.defaultProps = {
  total: 0,
  offset: 0,
  limit: 0,
  pageNum: 0,
  next(){},
  prev(){}
}

export default Pagination;