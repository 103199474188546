import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DashboardContent, PageHeader } from '../tailwind';
import LessonTargetInterpretationPanel from './LessonTargetInterpretationPanel';
import { getProgramsForLicenseeCampaign } from '../../actions/program';
import { getTargetInterpretations, getTargetInterpretationsForProgram } from '../../actions/targetInterpretation';

class LessonProgramDetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 1,
            selectedMainTab: 1,
        }
    }

    componentDidMount = () => {
        // get the licensee campaign id
        const programId = this.props.location.state.programId;
        // fetch the programs for this campaign
        this.props.getTargetInterpretationsForProgram(programId);
    }


    handleChangeCameraAngle = (e) => {
        this.setState({
            selectedTab: parseInt(e.target.value)
        });
    }

    handleClickTarget = (targetData) => {
        const targetId = targetData.target_id;
        this.props.history.push({
            pathname: `/lessons/targets/${targetId}`,
            state: { targetData }
        });
    }

    handleCreateTarget = (e) => {
        this.props.history.push({
            pathname: '/lessons/targets/create',
            state: { 
                targetInterpretationData: this.props.targetInterpretationData,
                programId: this.props.location.state.programId
            }
        });
    }

    handleClickTargetInterpretation = (targetInterpretationData) => {
        // navigate to the LessonTargetDetailPage
        this.props.history.push({
            pathname: `/lessons/targets/${targetInterpretationData.target_id}`,
            state: {
                targetInterpretationData
            }
        })

    }
    renderPanels = () => {
        return this.props.targetInterpretationProgramData
            ? Object.keys(this.props.targetInterpretationProgramData).map(key => {
                return (
                    <LessonTargetInterpretationPanel 
                        targetInterpretationData={this.props.targetInterpretationProgramData[key]}
                        onClick={this.handleClickTargetInterpretation}
                    />
                )
            }) : null;
    }

    render() {
        const licenseeCampaignData = this.props.location.state.licenseeCampaignData;
        const cameraAngle = this.props.location.state.cameraAngle;
        console.log('licensee program detail page ', licenseeCampaignData);
        return(
            <DashboardContent title={null} backTitle="Back to Lessons">
                <PageHeader 
                    title={`${licenseeCampaignData.display_name}`}
                    subtitle={`${cameraAngle}`}
                    backTitle="Back to Lessons"
                    onClick={() => this.handleCreateTarget()}
                    buttonTitle="Create Target"
                />
                
                <div className="mt-2 mb-4">
                    <div>
                        {this.renderPanels()}
                    </div>
                </div>
            </DashboardContent>
        );
    }
}

const mapStateToProps = ({ program, auth, targetInterpretation, licensee }) => {
    const { 
        programData,
        programsForCampaign,
        getProgramsForCampaignLoading,
        getProgramsForCampaignError,
    } = program;
    const { 
        licenseeData
    } = licensee;
    const { 
        targetInterpretations,
        targetInterpretationProgramData,
        getTargetInterpretationProgramLoading,
        getTargetInterpretationProgramError
    } = targetInterpretation;
    const { authUser } = auth;
    return {
        programData,
        programsForCampaign,
        getProgramsForCampaignLoading,
        getProgramsForCampaignError,
        authUser,
        targetInterpretations,
        targetInterpretationProgramData,
        getTargetInterpretationProgramLoading,
        getTargetInterpretationProgramError,
        licenseeData
    };
}

const mapDispatchToProps = {
    getProgramsForLicenseeCampaign,
    getTargetInterpretations,
    getTargetInterpretationsForProgram
}
const lessonDetailPageWithRouter = withRouter(LessonProgramDetailPage);
export default connect(mapStateToProps, mapDispatchToProps)(lessonDetailPageWithRouter);