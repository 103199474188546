import * as services from './services';
import * as actionCreators from './actions';


export const verifySlackCode = (code, licenseeId, history=null) => dispatch => {
    dispatch(actionCreators.verifySlackCode(code));
    return services.verifySlackCode(code)
        .then(data => {
            dispatch(actionCreators.verifySlackCodeSuccess(data));
            const slackData = data.data;
            if (slackData.ok === true) {
                // can we save this now?
                const incomingWebhook = slackData.incoming_webhook;
                const channel = incomingWebhook.channel;
                const webhook = incomingWebhook.url;
                console.log('SAVE HERE', licenseeId, incomingWebhook.channel, incomingWebhook.url);
                return services.saveSlackWebhookForLicensee(licenseeId, channel, webhook)
                    .then(saveData => {
                        dispatch(actionCreators.saveSlackWebhookForLicenseeSuccess(saveData));
                        // redirect the user to the account page?
                        history.push({
                            pathname: '/account'
                        });
                    }).catch(e => dispatch(actionCreators.saveSlackWebhookForLicenseeFail(e)));
            } else {
                dispatch(actionCreators.verifySlackCodeFail(new Error(data.error)));
            }
        })
        .catch(e => dispatch(actionCreators.verifySlackCodeFail(e)));
}

export const saveSlackWebhookForLicensee = (licenseeId, channel, webhook) => dispatch => {
    dispatch(actionCreators.saveSlackWebhookForLicensee(licenseeId, channel, webhook));
    return services.saveSlackWebhookForLicensee(licenseeId, channel, webhook)
        .then(data => dispatch(actionCreators.saveSlackWebhookForLicenseeSuccess(data)))
        .catch(e => dispatch(actionCreators.saveSlackWebhookForLicenseeFail(e)));
}

export const getSlackWebhookForLicensee = (licenseeId) => dispatch => {
    dispatch(actionCreators.getSlackWebhookForLicensee(licenseeId));
    return services.getSlackWebhookForLicensee(licenseeId)
        .then(data => {
            console.log('SUCCESS ', data);
            dispatch(actionCreators.getSlackWebhookForLicenseeSuccess(data));
        })
        .catch(e => dispatch(actionCreators.getSlackWebhookForLicenseeFail(e)));
}

export const resetSlackWebhook = () => dispatch => {
    dispatch(actionCreators.resetSlackWebhook());
}
