import React from 'react';
import Loading from '../Loading';
import Button from '../Button';
import ModalTW from './ModalTW';
import './ModalConfirmAction.css';
class ModalConfirmAction extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    }
  }

  handleConfirm = () => {
    this.props.onConfirm();
  }

  handleCancel = () => {
    this.props.onCancel();
  }

  render() {
    const { 
      title, 
      buttonTitleCancel,
      buttonTitleConfirm,
      body, 
      successTitle, 
      successBody, 
      isLoading, 
      isSuccess, 
      loadingTitle, 
      history, 
      show,
      buttonConfirmColor
    } = this.props;

    return (
        <ModalTW show={show}>
            {isLoading === false && isSuccess === null && (
            <div className="flex flex-col justify-between p-6 space-y-4">
                <div className="flex flex-col mb-6 space-y-2">
                    <h2 className="text-xl font-bold mb-2">{title}</h2>
                    <p className="font-medium">{body}</p>
                    {this.props.children}
                </div>
                <div className="flex justify-end space-x-2">
                    <Button 
                        bgColor="gray"
                        hoverColor="gray"
                        onClick={this.handleCancel}
                        title={buttonTitleCancel}
                    />    
                    <Button 
                        bgColor={buttonConfirmColor}
                        hoverColor={buttonConfirmColor}
                        onClick={this.handleConfirm}
                        title={buttonTitleConfirm}
                    />    
                </div>
            </div>
            )}
            {isLoading === true && (
                <Loading title={loadingTitle} />
            )}
            {isSuccess !== null && (
                <div className="flex flex-col justify-between p-6 space-y-4">
                    <div className="flex flex-col mb-6 space-y-2">
                        <h2 className="text-xl font-bold mb-2">{successTitle}</h2>
                        <p>{successBody}</p>
                    </div>
                    <div className="flex justify-end space-x-2">
                        <Button 
                            bgColor="gray"
                            hoverColor="gray"
                            onClick={() => {
                                if (history) history.go();
                                this.handleCancel();
                            }}
                            title="Close Window"
                        />    
                    </div>
                </div>
            )}
        </ModalTW>
    );
  }
}

ModalConfirmAction.defaultProps = {
  title: '',
  body: '',
  buttonTitleConfirm: 'Yes',
  buttonTitleCancel: 'No thanks',
  buttonConfirmColor: 'red',
  successTitle: 'Success!',
  successBody: 'The action was completed.',
  isLoading: false, 
  isError: null, 
  isSuccess: null, 
  contentLabel: 'Hi there', 
  loadingTitle: 'Loading',
  onCancel(){},
  onConfirm(){}
}

export default ModalConfirmAction;