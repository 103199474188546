import { swingbotApi, post } from '../../util/api';

export const getLicenseeCampaignsByLicenseeId = (licenseeId) => {
    return swingbotApi(`licensee/${licenseeId}/campaigns`, {}, 'get', true, 'licensee')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const createLicenseeCampaign = (licenseeId, userId, name, description) => {
    return swingbotApi(`licensee/${licenseeId}/campaigns`, { userId, name, description }, 'post', true, 'licensee-2')
        .then(response => response)
        .catch(err => Promise.reject(err));
}

export const duplicateLicenseeCampaign = (licenseeCampaignId, licenseeId, userId, status) => {
    return swingbotApi(`licensee-campaign/${licenseeCampaignId}/duplicate`, { licenseeCampaignId, licenseeId, userId, status }, 'post', true, 'licensee')
        .then(response => response)
        .catch(err => Promise.reject(err));
}

export const updateLicenseeCampaignStatus = (licenseeCampaignId, licenseeId, status) => {
    return swingbotApi(`licensee-campaign/${licenseeCampaignId}/status`, { licenseeCampaignId, licenseeId, status }, 'put', true, 'licensee-2')
        .then(response => response)
        .catch(err => Promise.reject(err));
}
// licenseeId, licenseeCampaignId, title, price, licenseeCampaignType, status, description=""
export const updateLicenseeCampaign = (licenseeId, licenseeCampaignId, title, price, licenseeCampaignType, status, lessonOutput, endpoint, description) => {
    return swingbotApi(`licensee/${licenseeId}/campaigns/${licenseeCampaignId}`, { 
        title, 
        price,
        description,
        status,
        campaign_type: licenseeCampaignType,
        lesson_output: lessonOutput,
        endpoint
    }, 'put', true, 'licensee-3')
        .then(response => response)
        .catch(err => Promise.reject(err));
}

export const sendToWebhook = (data, url) => {
    // lets simply send data to the endpoint
    return post(data, url)
        .then(data => data)
        .catch(e => Promise.reject(e));
}
