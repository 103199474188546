export const REGISTER_LICENSEE = 'auth/register-licensee';
export const REGISTER_LICENSEE_SUCCESS = 'auth/register-licensee-success';
export const REGISTER_LICENSEE_FAIL = 'auth/register-licensee-fail';
export const REGISTER_LICENSEE_RESET = 'auth/register-licensee-reset';

export const REGISTER_AND_ACTIVATE_LICENSEE = 'auth/register-and-activate-licensee';
export const REGISTER_AND_ACTIVATE_LICENSEE_SUCCESS = 'auth/register-and-activate-licensee-success';
export const REGISTER_AND_ACTIVATE_LICENSEE_FAIL = 'auth/register-and-activate-licensee-fail';
export const REGISTER_AND_ACTIVATE_LICENSEE_RESET = 'auth/register-and-activate-licensee-reset';

export const ACTIVATE_LICENSEE = 'auth/activate-licensee';
export const ACTIVATE_LICENSEE_SUCCESS = 'auth/activate-licensee-success';
export const ACTIVATE_LICENSEE_FAIL = 'auth/activate-licensee-fail';

export const LOGIN_USER = 'auth/login-user';
export const LOGIN_USER_SUCCESS = 'auth/login-user-success';
export const LOGIN_USER_FAIL = 'auth/login-user-fail';
export const LOGIN_USER_RESET = 'auth/login-user-reset';

export const FORGOT_PASSWORD = 'auth/forgot-password';
export const FORGOT_PASSWORD_SUCCESS = 'auth/forgot-password-success';
export const FORGOT_PASSWORD_FAIL = 'auth/forgot-password-fail';
export const FORGOT_PASSWORD_RESET = 'auth/forgot-password-reset';

export const RESET_PASSWORD = 'auth/reset-password';
export const RESET_PASSWORD_SUCCESS = 'auth/reset-password-success';
export const RESET_PASSWORD_FAIL = 'auth/reset-password-fail';
export const RESET_PASSWORD_RESET = 'auth/reset-password-reset';

export const LOGOUT_USER = 'auth/logout-user';

export const AUTH_CHECK_TOKEN = 'auth/check-token';
export const AUTH_CHECK_TOKEN_SUCCESS = 'auth/check-token-success';
export const AUTH_CHECK_TOKEN_FAIL = 'auth/check-token-fail';

// we want to call this so that we can update the user login auth
export const USER_SUBSCRIPTION_UPDATE = '/auth/user-subscription-update';