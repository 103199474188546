import React from 'react';
import { Container } from '../../tailwind';
import DisabledTargetContainer from '../analysis/DisabledTargetContainer';

class TargetSummary extends React.Component {
    handleOnChange = (e) => {
        this.props.onChange(e.target.value);
    }

    componentDidMount = () => {
        this.summaryInput.focus();
    }

    render() {
        const { readOnly, summary } = this.props;
        const classDisabled = readOnly === true ? "cursor-not-allowed":"";
        const classEmpty = this.props.summary === "" ? "border-2 border-red-500 bg-red-100":" border-2 border-gray-200 bg-gray-100";
        return summary !== null ? (
            <Container className="border">
                <div className="w-full h-full flex-1 flex-col">
                    <h1>Analysis Summary</h1>
                    <div className="text-md font-normal text-gray-600 mb-2">A brief explanation of the analysis results for this condition that will be displayed to the user.</div>
                    <div className="flex-1 h-full w-full mt-4">
                        <textarea 
                            ref={(input) => { this.summaryInput = input; }} 
                            onChange={this.handleOnChange} 
                            className={`w-full h-64 rounded p-4 text-gray-800 text-lg ${classDisabled} ${classEmpty} focus:outline-none outline-none`}
                            value={this.props.summary || ""} 
                            placeholder="Enter a brief description of this analysis that will be displayed to the golfer." 
                            disabled={this.props.readOnly}
                        />
                    </div>
                </div>
            </Container>
        ) : (<DisabledTargetContainer title="Summary" />);
    }
}

TargetSummary.defaultProps = {
    readOnly: false,
    show: false,
    textMessage: '',
    summary: '',
}

export default TargetSummary;