export const diffDates = (date2, date1) => {
    let delta = (date2 - date1) / (1000)// ms
        // calculate (and subtract) whole days
        var days = Math.floor(delta / 86400);
        delta -= days * 86400;

        // calculate (and subtract) whole hours
        var hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;

        // calculate (and subtract) whole minutes
        var minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;

        // what's left is seconds
        var seconds = delta % 60;  
        return {
            seconds,
            minutes,
            hours,
            days
        }
}

/**
 * createDateTimeStringFromDate
 * Helper method to create a date string that we usually pass to the date converter method
 * @param {Date} date 
 * @returns 
 */
export const createDateTimeStringFromDate = (date) => {
    return date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
};

export const convertDateTimeToDate = (dateStringToConvert, timezoneIn='America/New_York', timezoneOut='America/New_York') => {
    try {
        if (dateStringToConvert) {
            var t = dateStringToConvert.split(/T/);
            if (t[0]) {
                // check for the existence of hyphen or slash
                const dateParts = t[0].indexOf('-') ? t[0].split('-') : t[0].split('/');
                // Apply each element to the Date function
                // need to subtract 1 from month (Js starts at 0 for months)
                var d = new Date(parseFloat(dateParts[0]), parseFloat(dateParts[1]) - 1, parseFloat(dateParts[2]));
                // let's set this to noon
                d.setHours(12);
                // add the timezone of New York to the date...
                const date = new Date(d.toLocaleString("en-US", { timeZone: timezoneIn}));
                const invdate = new Date(d.toLocaleString("en-US", { timeZone: timezoneOut}));
                
                // and the diff is 5 hours
                var diff = date.getTime() - invdate.getTime();

                // so 12:00 in Toronto is 17:00 UTC
                return new Date(date.getTime() - diff);//.toLocaleDateString(); // needs to substract
            }
            return null;
        } else {
            return null;
        }
    } catch(e) {
        return null;
    }
};



export const isEarlierThanToday = (date) => {
    const today = new Date();
    return (today.getTime() - date.getTime()) < 0;
}