import React from 'react';
// import ImageDrawItem from './ImageDrawItem';
import DisabledTargetContainer from './DisabledTargetContainer';
import { 
    bodyPointOptions,
    positionOptions,
    lineTypeOptions,
    lineColorOptions
} from '../DropDowns';

class TargetLinePanel extends React.Component {

    handleLineTypeChange = (e, number) => {
        this.props.onLineTypeChange(e, number);
    }

    handleLineColorChange = (e, number) => {
        this.props.onLineColorChange(e, number);
    }

    handlePosition1Change = (e, number) => {
        this.props.onPosition1Change(e, number);
    }

    handlePosition2Change = (e, number) => {
        this.props.onPosition2Change(e, number);
    }

    handleBodyPoint1Change = (e, number) => {
        this.props.onBodyPoint1Change(e, number);
    }

    handleBodyPoint2Change = (e, number) => {
        this.props.onBodyPoint2Change(e, number);
    }

    handleDeleteLine = (number) => {
        this.props.onDelete(number);
    }

    handleCreateLine = () => {
        this.props.onCreate();
    }

    renderLines = () => {
        try {
            const { line, lineIndex, readOnly } = this.props;
            const disabledClass = readOnly === true ? "bg-gray-100 cursor-not-allowed":"";
            return line !== null ? (
                <div className="rounded m-2 grid grid-cols-1 w-full">
                    <div className="grid lg:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1">
                        <div className="col-span-1 max-w-lg rounded-md sm:max-w-full w-full mb-1">
                            <label htmlFor="country" className="block text-sm font-bold leading-5 text-gray-700 sm:mt-px sm:pt-2 mb-1">
                                Line Type
                            </label>
                            <div className="col-span-2 max-w-lg rounded-md sm:max-w-full w-full mb-1">
                                <select 
                                    onChange={(e) => this.handleLineTypeChange(e, lineIndex)}
                                    className={`block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${disabledClass}`}
                                    disabled={readOnly}
                                    value={line.type}
                                >
                                    {lineTypeOptions(line.type)}
                                </select>
                            </div>
                        </div>
                        <div className="col-span-1 max-w-lg rounded-md sm:max-w-full w-full mb-1">
                            <label htmlFor="country" className="block text-sm font-bold leading-5 text-gray-700 sm:mt-px sm:pt-2 mb-1">
                                Line Color
                            </label>
                            <div className="col-span-2 max-w-lg rounded-md sm:max-w-full w-full mb-1">
                                <select 
                                    value={line.color}
                                    onChange={(e) => this.handleLineColorChange(e, lineIndex)}
                                    className={`block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${disabledClass}`}
                                    disabled={readOnly}
                                >
                                    {lineColorOptions(line.color)}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="grid lg:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1">
                        <div className="col-span-1 max-w-lg rounded-md sm:max-w-full w-full mb-1">
                            <label htmlFor="country" className="block text-sm font-bold leading-5 text-gray-700 sm:mt-px sm:pt-2 mb-1">
                                Body Point #1
                            </label>
                            <select 
                                value={line.p1_lexicon}
                                onChange={(e) => this.handleBodyPoint1Change(e, lineIndex)}
                                className={`form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${disabledClass}`}
                                disabled={readOnly}
                            >
                                <option value="">Select a Body Point</option>
                                {bodyPointOptions(line.p1_lexicon)}
                            </select>
                        </div>
                        <div className="col-span-1 max-w-lg rounded-md sm:max-w-full w-full mb-1">
                        <label htmlFor="country" className="block text-sm font-bold leading-5 text-gray-700 sm:mt-px sm:pt-2 mb-1">
                            in Position
                        </label>
                            <select 
                                value={line.phase_origin}
                                disabled={readOnly}
                                onChange={(e) => this.handlePosition1Change(e, lineIndex)}
                                className={`form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${disabledClass}`}
                            >
                                <option value="">Select a Position</option>
                                {positionOptions(line.phase_origin)}
                            </select>
                        </div>
                    </div>
                    {line.type === 'p2p' && (
                        <div className="grid lg:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1">
                            <div className="col-span-1 max-w-lg rounded-md sm:max-w-full w-full mb-1">
                                <label htmlFor="country" className="block text-sm font-bold leading-5 text-gray-700 sm:mt-px sm:pt-2 mb-1">
                                    Body Point #2
                                </label>
                                <select 
                                    value={line.p2_lexicon}
                                    onChange={(e) => this.handleBodyPoint2Change(e, lineIndex)}
                                    className={`block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${disabledClass}`}
                                    disabled={readOnly}
                                >
                                    <option value="">Select a Body Point</option>
                                    {bodyPointOptions(line.p2_lexicon)}
                                </select>
                            </div>
                            <div className="col-span-1 max-w-lg rounded-md sm:max-w-full w-full mb-1">
                                <label htmlFor="country" className="block text-sm font-bold leading-5 text-gray-700 sm:mt-px sm:pt-2 mb-1">
                                    in Position
                                </label>
                                <select 
                                    value={line.phase_origin_2}
                                    onChange={(e) => this.handlePosition2Change(e, lineIndex)}
                                    className={`block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${disabledClass}`}
                                    disabled={readOnly}
                                >
                                    <option value="">Select a Position</option>
                                    {positionOptions(line.phase_origin_2)}
                                </select>
                            </div>
                        </div>
                    )}

                    <div className="grid lg:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 justify-end">
                        <div className="col-span-1 max-w-lg rounded-md sm:max-w-full w-full mb-1 justify-end flex py-4">
                            {readOnly === false && (
                                <button onClick={() => this.handleDeleteLine(lineIndex)} type="submit" className="flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out">
                                    Delete Line
                                </button>
                            )}
                        </div>
                    </div>

                </div>
            ) : (
                <DisabledTargetContainer 
                    title="Create new Line" 
                    onClick={this.handleCreateLine} 
                />
            );
        } catch(e) {
        }
    }

    render() {
        const { analysisResults } = this.props;
        return analysisResults !== null ? (
            <div className="flex w-full">
                <div className="flex w-full p-4 flex-col">
                    <div className="grid grid-cols-1 gap-3 w-full">
                        {this.renderLines()}
                    </div>
                </div>
            </div>
        ) : (<div className="flex w-full"></div>);
    }
}

TargetLinePanel.defaultProps = {
    readOnly: false,
    position: null,
    bodyPointsToRender: null,
    analysisResults: null,
    line: null,
    lineIndex: 0,
    onLineTypeChange(){},
    onLineColorChange(){},
    onPosition1Change(){},
    onPosition2Change(){},
    onBodyPoint1Change(){},
    onBodyPoint2Change(){},
    onDelete(){},
    onCreate(){}
}

export default TargetLinePanel;