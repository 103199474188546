import * as services from './services';
import * as actionCreators from './actions';
import { saveToken, removeToken } from '../../util/jwt';


export const loginUser = (email, password, history, sandbox=false) => dispatch => {
    const sandbox = process.env.REACT_APP_ENV === 'development';
    dispatch(actionCreators.loginUser(email, password));
    return services.loginUser(email, password, sandbox)
        .then(data => {
            if (data.success === false) {
                dispatch(actionCreators.loginUserFail("There was an error singning into your account"));
            } else {
                saveToken({ token: data.token, refreshToken: data.refreshToken });
                // if we have been successful we have to store the token
                // into the window.session using our jwt util library
                dispatch(actionCreators.loginUserSuccess(data.data));
                // navigate
                history.push('/overview');
            }
        })
        .catch(e => {
            // console.log(e);
            dispatch(actionCreators.loginUserFail(e));
        });
}

export const logoutUser = () => dispatch => {
    removeToken();
    dispatch(actionCreators.logoutUser());
}

export const loginUserReset = () => dispatch => {
    dispatch(actionCreators.loginUserReset());
}

/**
 * registerLicensee
 * 
 * We want to register the user into the swingbot database 
 * and also tag the user as "applied" in mautic.
 * 
 * @param {string} email user's email
 * @param {string} password user's password
 * @param {object} history the history navigation object
 */
export const registerLicensee = (email, password, history, applicationNonce=null) => dispatch => {
    dispatch(actionCreators.registerLicensee(email, password));
    return services.registerLicensee(email, password, applicationNonce)
        .then(data => {
            saveToken({ token: data.token, refreshToken: data.refreshToken });
            // if we have been successful we have to store the token
            // into the window.session using our jwt util library
            // const userData = {
            //     data: data.data.userData,
            //     token: data.token
            // };
            dispatch(actionCreators.registerLicenseeSuccess(data.data.userData));
            // navigate
            history.push('/overview');
        })
        .catch(e => {
            // console.log('register user error ', e.response.data);
            dispatch(actionCreators.registerLicenseeFail(e.response.data));
        });
}

export const registerAndActivateLicensee = (firstName, lastName, email, password, nonce, history) => dispatch => {
    dispatch(actionCreators.registerAndActivateLicensee(firstName, lastName, email, password, nonce));
    return services.registerAndActivateLicensee(firstName, lastName, email, password, nonce)
        .then(data => {
            saveToken({ token: data.token, refreshToken: data.refreshToken });
            // if we have been successful we have to store the token
            // into the window.session using our jwt util library
            const userData = {
                data: data.data.userData,
                token: data.token
            };
            dispatch(actionCreators.registerAndActivateLicenseeSuccess(userData));
            // navigate
            if (history) {
                history.push('/overview');
            }
        }).catch(e => dispatch(actionCreators.registerAndActivateLicenseeFail(e)));
}

export const registerAndActivateLicenseeReset = () => dispatch => {
    dispatch(actionCreators.registerAndActivateLicenseeReset());
}
/**
 * activateLicensee
 * Activate the licensee's account using the nonce
 * 
 * @param {string} nonce 
 * @param {object} history 
 */
export const activateLicensee = (nonce, history) => dispatch => {
    dispatch(actionCreators.activateLicensee(nonce));
    return services.activateLicensee(nonce)
        .then(data => {
            // what do we do here...
            dispatch(actionCreators.activateLicenseeSuccess(data));
        })
        .catch(e => dispatch(actionCreators.activateLicenseeFail(e)));
};


export const resetRegisterLicensee = () => dispatch => {
    dispatch(actionCreators.resetRegisterLicensee());
}
/**
 * forgotPassword
 * @param {string} email the user's email who forgot their password
 */
export const forgotPassword = (email, history) => dispatch => {
    dispatch(actionCreators.forgotPassword(email));
    return services.forgotPassword(email)
        .then(data => {
            dispatch(actionCreators.forgotPasswordSuccess(data));
        })
        .catch(e => dispatch(actionCreators.forgotPasswordFail(e)));
}

export const forgotPasswordReset = () => dispatch => {
    dispatch(actionCreators.forgotPasswordReset());
}

/**
 * resetPassword
 * @param {string} password the new password
 * @param {string} confirmPassword confirm the new password
 * @param {string} nonce the nonce identifying the user
 */
export const resetPassword = (password, confirmPassword, nonce, history) => dispatch => {
    dispatch(actionCreators.resetPassword(password, confirmPassword, nonce));
    return services.resetPassword(password, confirmPassword, nonce)
        .then(data => {
            dispatch(actionCreators.resetPasswordSuccess(data));
            if ('success' in data) {
                if (data.success === true) {
                    history.push('/login');
                }
            }
        })
        .catch(e => dispatch(actionCreators.resetPasswordFail(e)));
};

export const resetPasswordReset = () => dispatch => {
    dispatch(actionCreators.resetPasswordReset());
}
