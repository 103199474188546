import React from 'react';

class Card extends React.Component {
    render() {
        return (
            <div className="flex flex-1 flex-col bg-white overflow-hidden shadow rounded-lg mt-4">
                <div className="border-b border-gray-200 px-4 py-5 sm:px-6 font-bold text-gray-600">
                    {this.props.title}
                </div>
                <div className="px-4 py-5 sm:p-6">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

Card.defaultProps = {
    title: 'Heading',
}

export default Card;