import React from 'react';
import { connect } from 'react-redux';
import { DashboardContent, Container } from '../../tailwind';
import { selectors as websiteSelectors } from '../../../reducers/website';
import { linkStripeConnectedAccount } from '../../../actions/website';
import Loading from '../../common/Loading';
import Button from '../../common/Button';

class StripeLinkRefreshPage extends React.Component {

    componentDidMount = () => {
        const { authUser, website } = this.props;
        const licenseeId = authUser.licensee_id;
        const accountId = website.stripe_acct_id;
        const sandbox = process.env.REACT_APP_ENV === 'development';
        this.props.linkStripeConnectedAccount(licenseeId, accountId, sandbox);
    }

    handleLinkWithStripeClick = () => {
        const { authUser, website } = this.props;
        const sandbox = process.env.REACT_APP_ENV === 'development';
        const licenseeId = authUser.licensee_id;
        const accountId = website.stripe_acct_id;
        this.props.linkStripeConnectedAccount(
            licenseeId, 
            accountId, 
            sandbox
        );
    }

    render() {
        const { stripeLink } = this.props;
        return (
            <DashboardContent title="Stripe Connection">
                <Container className="bg-indigo-600 w-full">
                    <div className="w-full">
                        {stripeLink.loading === true && <Loading />}
                        {stripeLink.success !== null && (
                            <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                                    <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10 text-white">
                                        Almost Finished
                                        <br />
                                        <span className="text-gray-300 text-xl font-normal">Stripe needs some additional information to connect your account.</span>
                                </h2>
                                <div className="mt-8 flex justify-center">
                                <div className="inline-flex rounded-md shadow">
                                    <Button 
                                        title="Click to Connect with Stripe" 
                                        onClick={this.handleLinkWithStripeClick} 
                                        size="lg"
                                        bgColor="green"
                                        hoverColor="green"
                                    />
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                </Container>
            </DashboardContent>
        );
    }
}

const mapStateToProps = ({ auth, website }) => {
    const { authUser } = auth;
    return {
        website: websiteSelectors.website(website),
        stripeAccount: websiteSelectors.stripeAccount(website),
        stripeConnect: websiteSelectors.stripeConnect(website),
        stripeLink: websiteSelectors.stripeLink(website),
        authUser,
    };
};

const mapDispatchToProps = {
    linkStripeConnectedAccount
}

export default connect(mapStateToProps, mapDispatchToProps)(StripeLinkRefreshPage);