import * as actionTypes from './actionTypes';

/**
 * getLicenseeCampaignsByLicenseeId
 * Get all of the campaigns for the licensee
 * @param {int} userId 
 */
export const getLicenseeCampaignsByLicenseeId = (licenseeId) => ({
    type: actionTypes.GET_LICENSEE_CAMPAIGNS,
    payload: licenseeId
});

export const getLicenseeCampaignsByLicenseeIdSuccess = (data) => ({
    type: actionTypes.GET_LICENSEE_CAMPAIGNS_SUCCESS,
    payload: data
});

export const getLicenseeCampaignsByLicenseeIdFail = (error) => ({
    type: actionTypes.GET_LICENSEE_CAMPAIGNS_FAIL,
    payload: error
});

/**
 * createLicenseeCampaign
 * @param {string} name the name of the campaign
 * @param {*} licenseeId the licensee id
 */
export const createLicenseeCampaign = (licenseeId, userId, name, description) => ({
    type: actionTypes.CREATE_LICENSEE_CAMPAIGN,
    payload: { licenseeId, userId, name, description }
});

export const createLicenseeCampaignSuccess = (data) => ({
    type: actionTypes.CREATE_LICENSEE_CAMPAIGN_SUCCESS,
    payload: data
});

export const createLicenseeCampaignFail = (error) => ({
    type: actionTypes.CREATE_LICENSEE_CAMPAIGN_FAIL,
    payload: error
});

/**
 * duplicateLicenseeCmampaign
 */
export const duplicateLicenseeCampaign = (licenseeCampaignId, licenseeId, userId) => ({
    type: actionTypes.DUPLICATE_LICENSEE_CAMPAIGN,
    payload: { licenseeCampaignId, licenseeId, userId }
});

export const duplicateLicenseeCampaignSuccess = (data) => ({
    type: actionTypes.DUPLICATE_LICENSEE_CAMPAIGN_SUCCESS,
    payload: data
});

export const duplicateLicenseeCampaignFail = (error) => ({
    type: actionTypes.DUPLICATE_LICENSEE_CAMPAIGN_FAIL,
    payload: error
});

export const resetDuplicateLicenseeCampaign = () => ({
    type: actionTypes.DUPLICATE_LICENSEE_CAMPAIGN_RESET
});

export const resetCreateLicenseeCampaign = () => ({
    type: actionTypes.CREATE_LICENSEE_CAMPAIGN_RESET,
    payload: null
});

/**
 * updateLicenseeCampaign
 * 
 * @param {int} licenseeId the licensee id
 * @param {int} licenseeCampaignId the licensee campaign
 * @param {string} title the title of the campaign
 * @param {float} price the price of the campaign (may be 0)
 * @param {string} licenseeCampginType the type of campaign, this will show/hide on the main site PUBLIC|PRIVATE
 * @param {string} description the description of the campaign (each program will use same description)
 * @param {object} history the navigation react-redux object
 * @returns 
 */
export const updateLicenseeCampaign = (licenseeId, licenseeCampaignId, title, price, licenseeCampaignType, status, description) => ({
    type: actionTypes.UPDATE_LICENSEE_CAMPAIGN,
    payload: { licenseeId, licenseeCampaignId, title, price, licenseeCampaignType, status, description }
});

export const updateLicenseeCampaignSuccess = (data) => ({
    type: actionTypes.UPDATE_LICENSEE_CAMPAIGN_SUCCESS,
    payload: data
});

export const updateLicenseeCampaignFail = (error) => ({
    type: actionTypes.UPDATE_LICENSEE_CAMPAIGN_FAIL,
    payload: error
});

export const resetUpdateLicenseeCampaign = () => ({
    type: actionTypes.UPDATE_LICENSEE_CAMPAIGN_RESET,
    payload: null
});

/**
 * Update Licensee Campaign Status
 */
export const updateLicenseeCampaignStatus = (licenseeCampaignId, licenseeId, status) => ({
    type: actionTypes.UPDATE_LICENSEE_CAMPAIGN_STATUS,
    payload: { licenseeCampaignId, licenseeId, status }
});

export const updateLicenseeCampaignStatusSuccess = (data) => ({
    type: actionTypes.UPDATE_LICENSEE_CAMPAIGN_STATUS_SUCCESS,
    payload: data
});

export const updateLicenseeCampaignStatusFail = (error) => ({
    type: actionTypes.UPDATE_LICENSEE_CAMPAIGN_STATUS_FAIL,
    payload: error
});

export const resetUpdateLicenseeCampaignStatus = () => ({
    type: actionTypes.UPDATE_LICENSEE_CAMPAIGN_STATUS_RESET
});


/**
 * send to webhook
 */
export const sendToWebhook = (data, url) => ({
    type: actionTypes.SEND_TO_WEBHOOK,
    payload: { data, url }
});

export const sendToWebhookSuccess = (data) => ({
    type: actionTypes.SEND_TO_WEBHOOK_SUCCESS,
    payload: data
});

export const sendToWebhookFail = (error) => ({
    type: actionTypes.SEND_TO_WEBHOOK_FAIL,
    payload: error
});

export const sendToWebhookReset = () => ({
    type: actionTypes.SEND_TO_WEBHOOK_RESET,
    payload: null
});
