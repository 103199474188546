import React from 'react';
import { Container } from '../tailwind';
import { convertOperandToEnglish } from '../../util/swingbot';
class LessonTargetInterpretationPanel extends React.Component {
    isFailing = (code) => {
        return code.substr(0,1).toUpperCase() === 'F' ? true : false;
    }

    handleClick = (e) => {
        this.props.onClick(this.props.targetInterpretationData);
    }

    renderPositionsMenu = () => {

    }

    renderBodyPointsMenu = () => {

    }

    renderEnglishEquation = (calc1English, operandPseudo, calc2English) => {
        const sentence = `${calc1English} is ${convertOperandToEnglish(operandPseudo)} ${calc2English}`.toLowerCase();
        return sentence[0].toUpperCase() + sentence.slice(1).concat('.');
    }

    renderEquations = (equations) => {
        return Object.keys(equations).map(key => {
            const data = equations[key][0];
            const pass = key.substr(0,1).toUpperCase() === 'F' ? false : true;
            const conditionNumber = key.substr(1,1);
            const wellClassBG = pass === true ? "bg-green-50" : "bg-red-50";
            const wellClassText = pass === true ? "text-green-900" : "text-red-900";

            return (
                <div className="py-4">
                    <div className="text-xs">
                        <div>
                        {pass === true && (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-md rounded-b-none text-sm font-bold leading-5 bg-green-50 text-green-800">
                                <h4 className="text-sm p-2">{pass === true ? 'Pass' : 'Fail'} Condition #{conditionNumber}</h4>
                            </span>
                        )}
                        {pass === false && (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-md rounded-b-none text-sm font-bold leading-5 bg-red-50 text-red-800">
                                <h4 className="text-sm p-2">{pass === true ? 'Pass' : 'Fail'} Condition #{conditionNumber}</h4>
                            </span>
                        )}
                        </div>
                        <div className={`text-gray-500 text-base italic ${wellClassText}`}>
                        <div className={`bg-gray-100 overflow-hidden rounded-lg rounded-tl-none ${wellClassBG}`}>
                            <div className="px-4 py-5 sm:p-6">
                                {`"${this.renderEnglishEquation(data.calculation_1_english, data.operand, data.calculation_2_english)}"`}
                            </div>
                        </div>
                        </div>
                        
                    </div>
                    {/* <div>
                        {data.summary}
                    </div> */}
                </div>
            )
        });
    }

    render() {
        const { targetInterpretationData } = this.props;
        return (
            <Container>
                <div className="cursor-pointer" onClick={this.handleClick}>
                    <div className={`text-gray-600 text-xl font-bold mb-2`}>{targetInterpretationData.target_display_name}</div>
                    <div className="text-gray-500 text-base mb-2">{targetInterpretationData.text_definition}</div>
                    <div>
                        {this.renderEquations(targetInterpretationData.equations)}
                    </div>
                </div>
            </Container>
        );
    }
}

LessonTargetInterpretationPanel.defaultProps = {
    code: '',
    targetInterpretationData: {},
    onClick(){}
};

export default LessonTargetInterpretationPanel;