import React from 'react';
// import QualifyWizard from '../common/wizard/qualify/QualifyWizard';
import './LeadMagnetRequestDemo.css';

class LeadMagnetRequestDemo extends React.Component {
    componentDidMount = () => {}

    render() {
        return (
          <main className="my-12 lg:my-32 h-full flex-grow">
            <section className="container mx-auto px-6">
            <div className="w-full lg:flex items-center">
              <div className="w-full lg:w-1/2">
                <h2 className="text-md lg:text-2xl text-gray-300">Automate Lessons for your students</h2>
                <h1 className="text-5xl lg:text-6xl font-bold text-gray-50 mb-2 lg:mb-6">Let's Get Started</h1>
                <p className="text-md lg:text-xl font-light text-gray-200 mb-8">Increase revenue streams, and generate more in-person leads. Integrate into your existing website quickly and easily.</p>  
              </div>
              <div className="w-full lg:w-1/2 lg:pl-24">

              
{/* <div id="mauticform_wrapper_requestademo" className="mauticform_wrapper">
    <form className="bg-gray-200 shadow-sm rounded-md p-8" autoComplete="false" method="post" action="https://mautic.swingbotpro.com/mautic/form/submit?formId=1" id="mauticform_requestademo" data-mautic-form="requestademo" enctype="multipart/form-data">
        <div className="mauticform-error" id="mauticform_requestademo_error"></div>
        <div className="mauticform-message" id="mauticform_requestademo_message"></div>
        <div className="mauticform-innerform">

            
          <div className="mauticform-page-wrapper mauticform-page-1" data-mautic-form-page="1">

            <div id="mauticform_requestademo_first_name" data-validate="first_name" data-validation-type="text" className="mauticform-row mauticform-text mauticform-field-1 mauticform-required">
                <label id="mauticform_label_requestademo_first_name" htmlFor="mauticform_input_requestademo_first_name" className="mauticform-label">First Name</label>
                <input id="mauticform_input_requestademo_first_name" name="mauticform[first_name]" value="" placeholder="First Name" className="mauticform-input" type="text" />
                
            </div>

            <div id="mauticform_requestademo_last_name" data-validate="last_name" data-validation-type="text" className="mauticform-row mauticform-text mauticform-field-2 mauticform-required">
                <label id="mauticform_label_requestademo_last_name" htmlFor="mauticform_input_requestademo_last_name" className="mauticform-label">Last Name</label>
                <input id="mauticform_input_requestademo_last_name" name="mauticform[last_name]" value="" className="mauticform-input" type="text" />
                
            </div>

            <div id="mauticform_requestademo_email_address" data-validate="email_address" data-validation-type="email" className="mauticform-row mauticform-email mauticform-field-3 mauticform-required">
                <label id="mauticform_label_requestademo_email_address" htmlFor="mauticform_input_requestademo_email_address" className="mauticform-label">Email Address</label>
                <input id="mauticform_input_requestademo_email_address" name="mauticform[email_address]" value="" className="mauticform-input" type="email" />
                
            </div>

            <div id="mauticform_requestademo_submit" className="mauticform-row mauticform-button-wrapper mauticform-field-4">
                <button type="submit" name="mauticform[submit]" id="mauticform_input_requestademo_submit" value="" className="mauticform-button btn btn-default">Submit</button>
            </div>
            </div>
        </div>

        <input type="hidden" name="mauticform[formId]" id="mauticform_requestademo_id" value="1" />
        <input type="hidden" name="mauticform[return]" id="mauticform_requestademo_return" value="" />
        <input type="hidden" name="mauticform[formName]" id="mauticform_requestademo_name" value="requestademo" />

        </form>
</div> */}

              <form action="#" className="bg-gray-200 shadow-sm rounded-md p-8">
                <div className="mb-6">
                    <label htmlFor="name" className="mb-3 block text-gray-700">Full name:</label>
                    <input type="text" id="name" className="bg-white rounded-md border border-gray-200 p-3 focus:outline-none w-full" placeholder="John Doe" required />
                </div>
                <div className="mb-6">
                    <label htmlFor="email" className="mb-3 block text-gray-700">Email address:</label>
                    <input type="email" id="email" className="bg-white rounded-md border border-gray-200 p-3 focus:outline-none w-full" placeholder="john.doe@company.com" required />
                </div>
                <div className="mb-8">
                    <label htmlFor="company_size" className="mb-3 block text-gray-700">Number of Lessons per Month:</label>
                    <select id="company_size" className="bg-white rounded-md border border-gray-200 p-3 focus:outline-none w-full" required>
                        <option value="">Select an option</option>
                        <option value="1">1-10</option>
                        <option value="2">10-50</option>
                        <option value="3">50-100</option>
                        <option value="4">100+</option>
                    </select>
                </div>
                <button type="submit" className="py-3 px-12 bg-blue-500 hover:bg-blue-600 mr-5 rounded-md text-white text-lg focus:outline-none w-full">Request Demo</button>
              </form>
              </div>
            </div>
            </section>
        </main>
        );
    }
};

export default LeadMagnetRequestDemo;