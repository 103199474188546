import React from 'react';
import { withRouter } from 'react-router-dom';
import Invitation from '../auth/Invitation';

class InvitationPage extends React.Component {

    handleRegisterSuccess = (res) => {
        this.props.history.push('/overview');
    }

    handleRegisterError = (e) => {
    }

    render() {
        return (
            <Invitation nonce={this.props.match.params.nonce} />
        );
    }
}

export default withRouter(InvitationPage);