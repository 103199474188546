import React from 'react';
import Dinero from 'dinero.js';
/**
 * capitalizeFirstLetter
 * Capitalize the first letter of a string
 *
 * @param  string string  the string to be capitalized
 * @return string   the finished string
 */
export const capitalizeFirstLetter = string => {
  return string.toLowerCase().charAt(0).toUpperCase() + string.toLowerCase().slice(1);
};

export const hyphenate = string => {
  return string.replaceAll(' ', '-').toLowerCase();
}

/**
 * formatFieldName
 * Format the field name for the service
 * @param {string} name
 */
export const formatFieldName = name => {
  return camelize(stripUnderscores(name));
};
/**
 * formatFieldNames
 * Format all of the keys to camelCase
 * @param {object} arr
 */
export const formatFieldNames = obj => {
  const formattedKeys = {};
  Object.keys(obj).forEach(k => {
    formattedKeys[formatFieldName(k)] = obj[k];
  });
  return formattedKeys;
};

export const stripUnderscores = str => {
  return str.replace(/_/g, ' ');
};
/**
 * convert to camel case
 * @param {string} str
 */
export const camelize = str => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};
/**
 * convert a standaed "word" to a lexicon word
 */
export const convertToLexicon = name => {
  return name
    .toLowerCase()
    .split(' ')
    .join('_');
};

export const convertToDisplayName = name => {
  var lower = name.toLowerCase();
  return lower.replace(/(^| )(\w)/g, function(x) {
    return x.toUpperCase();
  });
};

/**
 * generate a random string
 */
export const randomString = (length = 20) => {
  const characters = '0123456789abcdefghijklmnopqrstuvwxyz';
  let string = '';
  for (var i = 0; i < length; i++) {
    string += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return string;
};
/**
 * isError
 */
export const isError = value => {
  return value instanceof Error && typeof value.message !== 'undefined';
};
/**
 * isString
 */
export const isString = value => {
  return typeof value === 'string';
};
/**
 * isHex
 */
export const isHex = value => {
  if (isString(value)) {
    return value.substring(0, 1) === '#' && value.length === 7;
  } else {
    return false;
  }
};
/**
 * check if the code is passing or failing
 * @example "P1" is passing
 */
export const isPassing = code => {
  return code.indexOf('P') > -1 ? true : false;
};
/**
 * is object
 */
export const isObject = obj => {
  return obj === Object(obj);
};
/**
 * renderValue
 *
 * Convert the value into a link if it is deemed necessary
 */
export const convertToLink = (key, value) => {
  const lowerKey = key.toLowerCase();
  let finalValue = value;
  switch (lowerKey) {
    case 'analysis_id':
      finalValue = <a href={`/analysis/${value}`}>{value}</a>;
      break;
    case 'video_id':
      finalValue = <a href={`/videos/${value}`}>{value}</a>;
      break;
    case 'user_id':
      finalValue = <a href={`/users/${value}`}>{value}</a>;
      break;
    case 'target_id':
      finalValue = <a href={`/analysis/targets/${value}`}>{value}</a>;
      break;
    case 'program_id':
      finalValue = <a href={`/analysis/programs/${value}`}>{value}</a>;
      break;
    default:
      // check to see if this is a link or a color!
      if (isLink(value) === true) {
        finalValue = <a href={value}>{value}</a>;
      } else if (isHex(value) === true) {
        finalValue = (
          <span
            style={{
              padding: 5,
              color: '#FFFFFF',
              backgroundColor: value,
              textShadow: '0px 1px #000000'
            }}
          >
            {value}
          </span>
        );
      } else {
        finalValue = value;
      }
      break;
  }
  return <p style={{ wordBreak: 'break-all' }}>{finalValue}</p>;
};
/**
 * check if the data is a url
 */
export const isLink = data => {
  if (typeof data === 'string') {
    return data.substring(0, 4) === 'http';
  } else {
    return false;
  }
};

export const formatPrice = (price) => {
  try {
    const cents = Math.round(parseFloat(price) * 100);
    console.log(cents);
    return cents !== null 
      ? Dinero({ amount: cents, currency: 'USD'}).toFormat('$0,0.00')
      : '';
  } catch(e) {
    console.log(e.message);
  }
}