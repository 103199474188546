import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createTarget, resetCreateTarget } from '../../../actions/target';
import ModalTW from '../../common/modal/ModalTW';
import Loading from '../../common/Loading';
import Button from '../../common/Button';

class LessonTargetCreateModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        show: false,
        swingTypeId: 1,
        textDefinition: '',
        displayName: ''
    }
  }

  componentDidMount = () => {
    this.props.resetCreateTarget();
  }

  handleFieldChange = (e) => {
    this.setState({
        [e.target.id]: e.target.value
    });
  }

  handleCameraAngle = (swingTypeId) => {
    this.setState({
        swingTypeId: swingTypeId
    });
  }

  handleCreateTarget = () => {
    const { authUser, history } = this.props;
    const userId = authUser.user_id;
    const textDefinition = this.state.textDefinition;
    console.log('create target');
    const swingTypeId = this.props.cameraAngle;
    const displayName = this.state.displayName;
    const programId = this.props.programId;
    console.log(this.props);
    
    if (displayName !== '' && textDefinition !== '' && programId && swingTypeId) {
      this.props.createTarget(
          programId, 
          userId, 
          displayName, 
          swingTypeId, 
          textDefinition, 
          history
      );
    } else {
      this.setState({
        error: true
      });
    }
  }

  cancelTarget = () => {
    this.setState({
      displayName: '',
      textDefinition: '',
      show: false,
    });
    this.props.onClickCancel();
  }

  render() {
      const { createTargetLoading, createTargetSuccess } = this.props;
      const { cameraAngle, show } = this.props;
      const cameraAngleString = cameraAngle === 1 ? 'Face On' : 'Down the Line';
      const { displayName, textDefinition } = this.state;
      return (
        <ModalTW show={show}>
        <div className="flex-1">
             {createTargetSuccess === null && createTargetLoading === false && (
                  <div className="flex flex-col w-full">
                        <div className="flex flex-col flex-1 w-full space-y-4">
                          <div className="flex flex-col flex-1 w-full ">
                              <p className="font-bold text-sm text-gray-700">{cameraAngleString}</p>
                              <h3 className="text-2xl font-bold leading-6 text-gray-900">
                              New Lesson Target
                              </h3>
                              <p className="mt-1 text-sm leading-5 text-gray-500">
                              Create a new target to be analyzed. Example: "Stance at Address"
                              </p>
                          </div>
                        <div className="grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                            <label htmlFor="name" className="block text-sm font-medium leading-5 text-gray-700 w-full">
                                Display Name
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <input 
                                    id="displayName" 
                                    className="flex-1 form-input block w-full min-w-0 rounded transition duration-150 ease-in-out sm:text-sm sm:leading-5  bg-gray-50" 
                                    placeholder="Stance at Address"
                                    onChange={this.handleFieldChange}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-6">
                            <label htmlFor="about" className="block text-sm font-medium leading-5 text-gray-700">
                                Definition
                            </label>
                            <div className="mt-1 rounded-md shadow-sm">
                                <textarea 
                                    id="textDefinition" 
                                    onChange={this.handleFieldChange} 
                                    rows="3" 
                                    className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 text-gray-600 bg-gray-50" 
                                    placeholder="Example: For this analysis, we will be analyzing your stance at address..." />
                            </div>
                            <p className="mt-2 text-sm text-gray-500">Write a few sentences describing what is being analyzed.
                            </p>
                        </div>
                        
                    </div>
                </div>
                    <div className="mt-8 border-t border-gray-200 pt-5">
                        {createTargetLoading === false && (
                        <div className="flex justify-end">
                            <span className="inline-flex rounded-md shadow-sm">
                                <button 
                                    type="button" 
                                    className="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
                                    onClick={this.cancelTarget}
                                >
                                Cancel
                                </button>
                            </span>
                            <span className="ml-3 inline-flex rounded-md shadow-sm">
                                <Button 
                                    title="Create Target"
                                    disabled={displayName === '' || textDefinition === ''}
                                    bgColor="green"
                                    hoverColor="green"
                                    block
                                    onClick={this.handleCreateTarget}
                                    submit
                                />
                                {/* <button 
                                    type="button"
                                    className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out"
                                    onClick={this.handleCreateTarget}
                                    disabled={displayName === '' && textDefinition === ''}
                                >
                                Create New Target
                                </button> */}
                            </span>
                        </div>
                        )}
                        {createTargetLoading === true && (
                            <div>Loading</div>
                        )}
                    </div>
                </div>
             )}

             {createTargetLoading === true && (<Loading />)}
             </div>
             </ModalTW>
      );
  }
}

LessonTargetCreateModal.defaultProps = {
  cameraAngle: 1,
  onClickCancel(){},
  show: false
}

const mapStateToProps = ({ auth, target }) => {
    const { authUser } = auth;
    const {
        createTargetLoading,
        createTargetSuccess,
        createTargetError,
    } = target;
    return { 
        authUser, 
        createTargetLoading,
        createTargetSuccess,
        createTargetError
    };
}

const mapDispatchToProps = {
    createTarget,
    resetCreateTarget,
}

const LessonTargetCreateWithRouter = withRouter(LessonTargetCreateModal);
export default connect(mapStateToProps, mapDispatchToProps)(LessonTargetCreateWithRouter);