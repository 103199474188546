import React from 'react';

class FeaturePacks extends React.Component {
    render() {
        return (
            <div className="bg-gray-900">
                <div className="pt-12 sm:pt-16 lg:pt-24">
                    <div className="max-w-screen-xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                    <div className="max-w-3xl mx-auto lg:max-w-none">
                        <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
                       Video Packages
                        </h2>
                        <p className="mt-2 text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
                        The right amount for you, whoever you are
                        </p>
                        <p className="mt-4 text-xl leading-7 text-gray-300">
                        Choose the number of videos you would like processed each month.
                        </p>
                    </div>
                    </div>
                </div>
                <div className="mt-8 pb-12 bg-gray-50 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
                    <div className="relative">
                    <div className="absolute inset-0 h-3/4 bg-gray-900"></div>
                    <div className="relative z-10 max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="max-w-md mx-auto lg:max-w-5xl lg:grid lg:grid-cols-3 lg:gap-5">
                        <div className="mt-4 rounded-lg shadow-lg overflow-hidden">
                            <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                            <div>
                                <h3 className="inline-flex px-4 py-1 rounded-lg text-sm leading-5 font-semibold tracking-wide uppercase bg-indigo-400 text-white" id="tier-standard">
                                Standard
                                </h3>
                            </div>
                            <div className="mt-4 flex items-baseline text-6xl leading-none font-extrabold">
                                100
                                <span className="ml-1 text-2xl leading-8 font-medium text-gray-500">
                                /mo
                                </span>
                            </div>
                            <p className="mt-5 text-lg leading-7 text-gray-500">
                                Up to 100 Videos Processed
                            </p>
                            </div>
                            <div className="px-6 pt-6 pb-8 bg-gray-50 sm:p-10 sm:pt-6">
                            <ul>
                                <li className="flex items-start">
                                <div className="flex-shrink-0">
                                    <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <p className="ml-3 text-base leading-6 text-gray-700">
                                    Pariatur quod similique
                                </p>
                                </li>
                                <li className="mt-4 flex items-start">
                                <div className="flex-shrink-0">
                                    <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <p className="ml-3 text-base leading-6 text-gray-700">
                                    Sapiente libero doloribus modi nostrum
                                </p>
                                </li>
                                <li className="mt-4 flex items-start">
                                <div className="flex-shrink-0">
                                    <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <p className="ml-3 text-base leading-6 text-gray-700">
                                    Vel ipsa esse repudiandae excepturi
                                </p>
                                </li>
                                <li className="mt-4 flex items-start">
                                <div className="flex-shrink-0">
                                    <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <p className="ml-3 text-base leading-6 text-gray-700">
                                    Itaque cupiditate adipisci quibusdam
                                </p>
                                </li>
                            </ul>
                            <div className="mt-6 rounded-md shadow">
                                <a href="#get-started" className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" aria-describedby="tier-standard">
                                Get started
                                </a>
                            </div>
                            </div>
                        </div>
                    {/* second box */}
                        <div className="mt-4 rounded-lg shadow-lg overflow-hidden">
                            <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                            <div>
                                <h3 className="inline-flex px-4 py-1 rounded-lg text-sm leading-5 font-semibold tracking-wide uppercase bg-green-400 text-white" id="tier-standard">
                                Professional
                                </h3>
                            </div>
                            <div className="mt-4 flex items-baseline text-6xl leading-none font-extrabold">
                                250
                                <span className="ml-1 text-2xl leading-8 font-medium text-gray-500">
                                /mo
                                </span>
                            </div>
                            <p className="mt-5 text-lg leading-7 text-gray-500">
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                            </p>
                            </div>
                            <div className="px-6 pt-6 pb-8 bg-gray-50 sm:p-10 sm:pt-6">
                            <ul>
                                <li className="flex items-start">
                                <div className="flex-shrink-0">
                                    <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <p className="ml-3 text-base leading-6 text-gray-700">
                                    Pariatur quod similique
                                </p>
                                </li>
                                <li className="mt-4 flex items-start">
                                <div className="flex-shrink-0">
                                    <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <p className="ml-3 text-base leading-6 text-gray-700">
                                    Sapiente libero doloribus modi nostrum
                                </p>
                                </li>
                                <li className="mt-4 flex items-start">
                                <div className="flex-shrink-0">
                                    <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <p className="ml-3 text-base leading-6 text-gray-700">
                                    Vel ipsa esse repudiandae excepturi
                                </p>
                                </li>
                                <li className="mt-4 flex items-start">
                                <div className="flex-shrink-0">
                                    <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <p className="ml-3 text-base leading-6 text-gray-700">
                                    Itaque cupiditate adipisci quibusdam
                                </p>
                                </li>
                            </ul>
                            <div className="mt-6 rounded-md shadow">
                                <div className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" aria-describedby="tier-standard">
                                Get started
                                </div>
                            </div>
                            </div>
                        </div>
                        {/* third box */}
                        <div className="mt-4 rounded-lg shadow-lg overflow-hidden">
                            <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                            <div className="mt-4 flex items-center text-6xl leading-none font-extrabold justify-center w-full">
                                1000
                                {/* <span className="ml-1 text-2xl leading-8 font-medium text-gray-500">
                                /mo
                                </span> */}
                            </div>
                            <div className="mt-6">
                                <h3 className="inline-flex flex-1 px-4 py-1 rounded-lg text-sm leading-5 font-semibold tracking-wide uppercase bg-red-400 text-white justify-center w-full" id="tier-enterprise">
                                Enterprise
                                </h3>
                            </div>
                            <p className="mt-5 text-lg leading-7 text-gray-500">
                                Up to 1000 videos processed per month
                            </p>
                            </div>
                            <div className="px-6 pt-6 pb-8 bg-gray-50 sm:p-10 sm:pt-6">
                            <ul>
                                <li className="flex items-start">
                                <div className="flex-shrink-0">
                                    <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <p className="ml-3 text-base leading-6 text-gray-700">
                                    Pariatur quod similique
                                </p>
                                </li>
                                <li className="mt-4 flex items-start">
                                <div className="flex-shrink-0">
                                    <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <p className="ml-3 text-base leading-6 text-gray-700">
                                    Sapiente libero doloribus modi nostrum
                                </p>
                                </li>
                                <li className="mt-4 flex items-start">
                                <div className="flex-shrink-0">
                                    <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <p className="ml-3 text-base leading-6 text-gray-700">
                                    Vel ipsa esse repudiandae excepturi
                                </p>
                                </li>
                                <li className="mt-4 flex items-start">
                                <div className="flex-shrink-0">
                                    <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <p className="ml-3 text-base leading-6 text-gray-700">
                                    Itaque cupiditate adipisci quibusdam
                                </p>
                                </li>
                            </ul>
                            <div className="mt-6 rounded-md shadow">
                                <div className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" aria-describedby="tier-enterprise">
                                Get started
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="mt-4 relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
                    <div className="max-w-md mx-auto lg:max-w-5xl">
                        <div className="rounded-lg bg-gray-100 px-6 py-8 sm:p-10 lg:flex lg:items-center">
                        <div className="flex-1">
                            <div>
                            <h3 className="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-white text-gray-800">
                                Discounted
                            </h3>
                            </div>
                            <div className="mt-4 text-lg leading-7 text-gray-600">
                            Get full access to all of standard license features for solo projects that make less than $20k gross revenue for
                            <strong className="font-semibold text-gray-900">
                                $29
                            </strong>
                            .
                            </div>
                        </div>
                        <div className="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
                            <div className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-700 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                            Buy Discounted License
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

        )
    }
}

export default FeaturePacks;