import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Line, Circle, Set, Text } from 'react-raphael';
import { getPointsForPosition } from '../../../util/swingbot';

// import { Image } from 'react-bootstrap';

class ImagePointsItem extends React.Component {
  state = {
    dimensions: {
      width: 0,
      height: 0
    },
    strokeWidth: 5,
    points: {},
    ratio: this.props.ratio
  };
  componentDidMount = () => {
    this.setState({
      points: this.flipBodyPoints()
    });
  };
  /**
   * flip the right to left for left handed golfers.
   */
  flipBodyPoints = () => {
    const { rightHanded, points } = this.props;
    if (rightHanded === 'N') {
      let flipPoints = {};
      const phaseKeys = Object.keys(points);
      for (let i = 0; i < phaseKeys.length; i++) {
        const phase = phaseKeys[i];
        flipPoints[phase] = {};
        const bodyPointKeys = Object.keys(points[phase]);
        for (let j = 0; j < bodyPointKeys.length; j++) {
          const bodyPoint = bodyPointKeys[j];
          let pointData = points[phase][bodyPoint];
          // switch to right!
          if (bodyPoint.indexOf('left') > -1) {
            const parts = bodyPoint.split('_');
            pointData = points[phase][`right_${parts[1]}`];
          }
          // switch to left!
          if (bodyPoint.indexOf('right') > -1) {
            const parts = bodyPoint.split('_');
            pointData = points[phase][`right_${parts[1]}`];
          }
          // deal with "hands"
          if (bodyPoint === 'hands') {
            // console.log('HANDS');
          }
          // add the pointdata to the new object...
          flipPoints[phase][bodyPoint] = pointData;
        }
      }
      return flipPoints;
    } else {
      return points;
    }
  };

  getColorForLine = () => {
    const { code } = this.props;
    return code.substr(0, 1) === 'P' ? '#00FF00' : 'red';
  };

  handleOnLoad = ({ target: img }) => {
    this.setState({
      dimensions: {
        height: img.naturalHeight,
        width: img.naturalWidth
      }
    });
  };

  handleOnError = () => {};

  parsePoint1 = (line, points) => {
    return points && line['p1_lexicon'] in points ? points[line['p1_lexicon']] : false;
  };

  parsePoint2 = (line, points) => {
    return points && line['p1_lexicon'] in points ? points[line['p2_lexicon']] : false;
  };

  renderLine = (line, points) => {
    let lines = null;
    switch (line.type) {
      case 'horizontal':
        lines = this.drawHorizontalLine(line, points);
        break;
      case 'p2p':
        lines = this.drawP2PLine(line, points);
        break;
      case 'vertical':
        lines = this.drawVerticalLine(line, points);
        break;
      default:
        break;
    }
    return lines;
  };

  drawHorizontalLine = (line, points) => {
    const p1 = this.parsePoint1(line, points);
    const { dimensions, strokeWidth } = this.state;
    return p1 ? (
      <Line
        key={line.line_id}
        x1={0}
        y1={Math.round(p1.y_pos)}
        x2={dimensions.width}
        y2={Math.round(p1.y_pos)}
        attr={{ stroke: line.color, 'stroke-width': strokeWidth }}
      />
    ) : null;
  };

  drawVerticalLine = (line, points) => {
    const p1 = this.parsePoint1(line, points);
    const { dimensions, strokeWidth } = this.state;
    return p1 ? (
      <Line
        key={line.line_id}
        x1={Math.round(p1.x_pos)}
        y1={0}
        x2={Math.round(p1.x_pos)}
        y2={dimensions.height}
        attr={{ stroke: line.color, 'stroke-width': strokeWidth }}
      />
    ) : null;
  };

  drawP2PLine = (line, points) => {
    const p1 = this.parsePoint1(line, points);
    const p2 = this.parsePoint2(line, points);
    const { strokeWidth } = this.state;
    return p1 && p2 ? (
      <Line
        key={line.line_id}
        x1={Math.round(p1.x_pos)}
        y1={Math.round(p1.y_pos)}
        x2={Math.round(p2.x_pos)}
        y2={Math.round(p2.y_pos)}
        attr={{ stroke: line.color, 'stroke-width': strokeWidth }}
      />
    ) : null;
  };

  /**
   * 
   */
    drawCircle = (point, radius = 3, attr = null) => {
        const attributes = attr || { "stroke":"#FFFF00", "stroke-width":6 };
        return (
            <Circle 
                key={`key-circle-${point.x}-${point.y}-${Math.random()}`}
                x={point.x} 
                y={point.y} 
                r={radius}
                attr={attributes} 
            />
        );
  }

  drawLine = (point1, point2, attr=null) => {  
      const attributes = attr || { "stroke":"#00FF00", "stroke-width":4 };
        return (
            <Line
                key={`key-line-${point1.x}-${point1.y}-${Math.random()}`}
                x1={Math.round(point1.x)}
                y1={Math.round(point1.y)}
                x2={Math.round(point2.x)}
                y2={Math.round(point2.y)}
                attr={attributes}
            />
        );
  }

  drawText = (x, y, text, attr={"fill":"FFFFFF"}) => {
    const attributes = attr || { "stroke":"#00FF00", "stroke-width":4 };
        return (
            <Text
                key={`key-text-${x}-${y}`}
                x={Math.round(x)}
                y={Math.round(y)}
                attr={attributes}
                text={text}
            />
        );
  }
  pointsForLine = (points, line) => {
    // we have to check each phase for the point...
    const phase = line.phase_origin ? line.phase_origin : line.phase;
    return points[phase];
  };

  renderLines = () => {
    const { lines } = this.props;
    const { points } = this.state;
    return lines !== null && points !== null
      ? lines.map(line => {
          const pointsForLine = this.pointsForLine(points, line);
          return this.renderLine(line, pointsForLine);
        })
      : null;
  };

  renderPointsForPosition = (position, bodyPointsToRenderArray) => {
        const { points, ratio } = this.state;
        // now we can render some points on the image...
        try {
            return bodyPointsToRenderArray !== null && bodyPointsToRenderArray !== undefined
                ? bodyPointsToRenderArray.map((bp, index) => {
                    const pointsForPosition = getPointsForPosition(position[index+1].name, points);
                    const p = {
                        x: pointsForPosition[0][bp]['x_pos'] * ratio,
                        y: pointsForPosition[0][bp]['y_pos'] * ratio
                    };
                    return this.drawCircle(p);
                }) : null;
        } catch(e) {
            return null;
        }
  }

  renderLinesForPosition = (position, bodyPointsToRenderArray) => {
    const { points, ratio } = this.state;
    try {
        const pointsForPosition1 = getPointsForPosition(position[1].name, points);
        if (pointsForPosition1 !== null && bodyPointsToRenderArray.length > 0) {
            // now we can render some points on the image...
            let lines = [];
            for( var i = 0; i < bodyPointsToRenderArray.length; i++) {
                const p1 = {
                    x: pointsForPosition1[0][bodyPointsToRenderArray[i]]['x_pos'] * ratio,
                    y: pointsForPosition1[0][bodyPointsToRenderArray[i]]['y_pos'] * ratio
                };
                let p2 = null;
                if (bodyPointsToRenderArray[i+1]) {
                    const pointsForPosition2 = getPointsForPosition(position[2].name, points);
                    p2 = {
                        x: pointsForPosition2[0][bodyPointsToRenderArray[i+1]]['x_pos'] * ratio,
                        y: pointsForPosition2[0][bodyPointsToRenderArray[i+1]]['y_pos'] * ratio
                    };  
                }
                if (p1 !== null && p2 !== null) {
                    lines.push(this.drawLine(p1, p2));
                }
            }
            return lines.map(line => line);
        }
    } catch(e) {
        return null;
    }
}

renderGuidelinesForPosition = (position) => {
    const { guidelines, ratio } = this.props;

    // lets draw a line!
    return guidelines.map(line => {
        const p1Ratio = {
          x: line.p1['x'] * ratio,
          y: line.p1['y'] * ratio,
        };
        const p2Ratio = {
          x: line.p2['x'] * ratio,
          y: line.p2['y'] * ratio,
        };
        return this.drawLine(
            p1Ratio, 
            p2Ratio, {
                "stroke-dasharray":"-", 
                "stroke":"#FFFF00", 
                "stroke-width": 2
            });
    });
}

renderGuidelineText = (position) => {
    const { guidelineText, ratio } = this.props;
    // lets draw a line!

    return guidelineText.map(line => {
        return this.drawText(
            line.x * ratio, 
            line.y * ratio, 
            line.text, {
                "stroke-width": 4,
                "fill":"#FFFFFF",
                "font-size": 24,
                "font-style": "bold",
                "font-family": "Helvetica"
            });
    });
}

  render() {
    const { imageUrl } = this.props;
    const { ratio } = this.state;
    const { width, height } = this.state.dimensions;
    
    const newWidth = width * ratio;
    const newHeight = height * ratio;
    const wrapperStyle = {
      position: 'relative',
      width: newWidth,
      height: newHeight
    };
    const imageStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      width: newWidth,
      height: newHeight
    };
    const paperStyle = {
      position: 'absolute',
      top: 0,
      left: 0
    };

    return (
      <div style={wrapperStyle}>
        <img
            className="opacity-100 xs:rounded sm:rounded"
            src={imageUrl}
            style={imageStyle}
            onLoad={this.handleOnLoad}
            onError={this.handleOnError}
            alt="draw_point_image"
        />
        {width !== 0 &&
          height !== 0 && (
            <Paper style={paperStyle} width={newWidth} height={newHeight}>
              <Set>
                {this.props.guidelines !== null && this.renderGuidelinesForPosition(this.props.position)}
                {this.props.guidelineText !== null && this.renderGuidelineText(this.props.position)}
                {this.props.position !== null && this.renderPointsForPosition(this.props.position, this.props.bodyPointsToRender)}
                {this.props.bodyPointsToRender && this.renderLinesForPosition(this.props.position, this.props.bodyPointsToRender)}
            </Set>
            </Paper>
          )}
      </div>
    );
  }
}

ImagePointsItem.propTypes = {
    position: PropTypes.object,
    imageUrl: PropTypes.string,
    lines: PropTypes.array,
    points: PropTypes.object,
    bodyPointsToRender: PropTypes.array,
    guidelines: PropTypes.array
};

ImagePointsItem.defaultProps = {
    bodyPointsToRender: [],
    guidelines: null,
    guidelineText: null,
    ratio: 1
}

export default ImagePointsItem;
