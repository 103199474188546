import * as actionTypes from './types';

export const getProgramsByLicenseeId = (licenseeId) => ({
    type: actionTypes.GET_PROGRAMS,
    payload: licenseeId
});

export const getProgramsByLicenseeIdSuccess = (data) => ({
    type: actionTypes.GET_PROGRAMS_SUCCESS,
    payload: data
});

export const getProgramsByLicenseeIdFail = (error) => ({
    type: actionTypes.GET_PROGRAMS_FAIL,
    payload: error
});

/**
 * getProgramsForLicenseeCampaign
 */
export const getProgramsForLicenseeCampaign = (licenseeCampaignId, licenseeId) => ({
    type: actionTypes.GET_PROGRAMS_FOR_CAMPAIGN,
    payload: {licenseeCampaignId, licenseeId}
});

export const getProgramsForLicenseeCampaignSuccess = (data) => ({
    type: actionTypes.GET_PROGRAMS_FOR_CAMPAIGN_SUCCESS,
    payload: data
});

export const getProgramsForLicenseeCampaignFail = (error) => ({
    type: actionTypes.GET_PROGRAMS_FOR_CAMPAIGN_FAIL,
    payload: error
});


export const getProgramData = (programId, userId) => ({
    type: actionTypes.GET_PROGRAM_DATA,
    payload: { programId, userId }
});

export const getProgramDataSuccess = (data) => ({
    type: actionTypes.GET_PROGRAM_DATA_SUCCESS,
    payload: data
});

export const getProgramDataFail = (error) => ({
    type: actionTypes.GET_PROGRAM_DATA_FAIL,
    payload: error
});

export const createProgram = (name, description, userId, duration, swingTypeId, status) => ({
    type: actionTypes.CREATE_PROGRAM,
    payload: { name, description, userId, duration, swingTypeId, status }
});

export const createProgramSuccess = (data) => ({
    type: actionTypes.CREATE_PROGRAM_SUCCESS,
    payload: data
});

export const createProgramFail = (error) => ({
    type: actionTypes.CREATE_PROGRAM_FAIL,
    payload: error
});

export const duplicateProgram = (programId, userId) => ({
    type: actionTypes.DUPLICATE_PROGRAM,
    payload: { programId, userId }
});

export const duplicateProgramSuccess = (data) => ({
    type: actionTypes.DUPLICATE_PROGRAM_SUCCESS,
    payload: data
});

export const duplicateProgramFail = (error) => ({
    type: actionTypes.DUPLICATE_PROGRAM_FAIL,
    payload: error
});

export const removeTargetFromProgram = (targetId, programId, userId) => ({
    type: actionTypes.REMOVE_TARGET,
    payload: { targetId, programId, userId }
});

export const resetRemoveTargetFromProgram = () => ({
    type: actionTypes.REMOVE_TARGET_RESET,
    payload: null
});

export const removeTargetFromProgramSuccess = (data) => ({
    type: actionTypes.REMOVE_TARGET_SUCCESS,
    payload: data
});

export const removeTargetFromProgramFail = (error) => ({
    type: actionTypes.REMOVE_TARGET_FAIL,
    payload: error
});

export const removeTargetsFromProgram = (targetId, programId, userId) => ({
    type: actionTypes.REMOVE_TARGETS,
    payload: { targetId, programId, userId }
});

export const removeTargetsFromProgramSuccess = (data) => ({
    type: actionTypes.REMOVE_TARGETS_SUCCESS,
    payload: data
});

export const removeTargetsFromProgramFail = (error) => ({
    type: actionTypes.REMOVE_TARGETS_FAIL,
    payload: error
});



export const getTargetsForProgram = (programId, userId) => ({
    type: actionTypes.GET_PROGRAM_TARGETS,
    payload: { programId, userId }
});

export const getTargetsForProgramSuccess = (data) => ({
    type: actionTypes.GET_PROGRAM_TARGETS_SUCCESS,
    payload: data
});

export const getTargetsForProgramFail = (error) => ({
    type: actionTypes.GET_PROGRAM_TARGETS_FAIL,
    payload: error
});