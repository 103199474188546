import React from 'react';
import { Route, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { getToken, decodeToken, verifyTokenSync } from '../util/jwt';
import { selectors as authSelectors } from '../reducers/auth';

const AuthorizedRoute = ({ component: Component, ...rest }) => {
    try {
        // let's check the token, we DELETE the token upon logout
        // and we can also check the expiration in this step...
        const tokenData = getToken();
        const decoded = decodeToken(tokenData.token);
        // maybe we have to check the token for admin level?
        const verified = verifyTokenSync(tokenData.token);
        console.log(decoded.data);
        // if the user token is verified, let's push them through!
        return (rest.isActive && verified && decoded.data && decoded.data.licensee_status === 'ACTIVE') 
            ? <Route {...rest} component={Component} />
            : decoded.data.licensee_status === 'INACTIVE'
                ? (
                    <Redirect
                        to={{
                            pathname: '/activate',
                            state: { from: rest.location }
                        }}
                    />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: rest.location }
                        }}
                    />
                );
    } catch (e) {
        return (
            <Redirect
                to={{
                    pathname: '/login',
                    state: { from: rest.location }
                }}
            />
        );
    }
};

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
    return { 
      authUser,
      isActive: authSelectors.isActive(auth)
    };
};

export default connect(mapStateToProps, null, null, { pure: false })(AuthorizedRoute);
