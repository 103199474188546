import * as services from './services';
import * as programServices from '../program/services';
import * as actionCreators from './actions';

/**
 * createTarget
 * Create a new target in the system
 * 
 * @param {int} userId the user id creating the target
 * @param {*} displayName the display name for the target
 * @param {*} swingTypeId the swing type id (1|2)
 * @param {*} textDefinition the definition for the target (what is being analyzed)
 */
export const createTarget = (programId, userId, displayName, swingTypeId, textDefinition, history=null) => dispatch => {
    dispatch(actionCreators.createTarget(programId, userId, displayName, swingTypeId, textDefinition));
    return services.createTarget(programId, userId, displayName, swingTypeId, textDefinition)
        .then(data => {
            // we want to send the user to the lesson target page...
            // /lessons/targets/75
            dispatch(actionCreators.createTargetSuccess(data.data));
            // navigate back to the program
            if (history && data) {
                // we WANT To do this, but need to set MANY things in the state
                // to achieve this. Need to make this page query for the target detail
                // so that we do not depend on the state.
                history.push({
                    pathname: `/lessons/targets/${data.data.target.insertId}`,
                    state: {
                        target: data.data.target.insertId,
                        targetData: {
                            'display_name': displayName,
                            swing_type_id: swingTypeId,
                            'text_definition': textDefinition,
                            program_id: programId
                        },
                        readOnly: false,
                        status: 'INACTIVE'
                    }
                })
            }
        }).catch(e => dispatch(actionCreators.createTargetFail(e)));
}

export const resetCreateTarget = () => dispatch => {
    dispatch(actionCreators.resetCreateTarget());
}

export const getTargetsForUser = (userId) => dispatch => {
    dispatch(actionCreators.getTargetsForUser(userId));
    return services.getTargetsForUser(userId)
        .then(data => dispatch(actionCreators.getTargetsForUserSuccess(data.data)))
        .catch(e => dispatch(actionCreators.getTargetsForUserFail(e)));
}

export const getTargetLibrary = () => dispatch => {
    dispatch(actionCreators.getTargetLibrary());
    return programServices.getProgramsForLicenseeCampaign(65)
        .then(data => dispatch(actionCreators.getTargetLibrarySuccess(data.data)))
        .catch(e => dispatch(actionCreators.getTargetLibraryFail(e)));
}

export const addTargetFromLibrary = (targetId, programId, userId, history = null, historyPage = null) => dispatch => {
    dispatch(actionCreators.addTargetFromLibrary(targetId, programId, userId));
    return services.addTargetFromLibrary(targetId, programId, userId)
        .then(data => { 
            dispatch(actionCreators.addTargetFromLibrarySuccess(data.data));
            if (history) {
                if (historyPage !== null) {
                    history.go(-historyPage);
                } else {
                    // go to the previous page...
                    history.goBack();
                }
            }
        })
        .catch(e => dispatch(actionCreators.addTargetFromLibraryFail(e)));
}

/**
 * toggleDisplayForTarget
 * This allow the instructor to show/hide the target in a lesson
 *
 * @param {int} targetId the target id we want to hide/show
 * @returns 
 */
export const toggleDisplayForTarget = (targetId) => dispatch => {

};

