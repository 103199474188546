import React from 'react';

class Results extends React.Component {
    render() {
        return (
            <div id="results" className="bg-white border border-b-2 border-gray-200">
                <div className="max-w-screen-lg mx-auto pt-12 pb-16 sm:pt-16 sm:pb-20 px-4 sm:px-6 lg:pt-20 lg:pb-28 lg:px-8">
                    <div className="w-full">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 py-6">
                        <div className="flex col-span-1 justify-center text-center">
                            <img className="object-contain" src={require('../../img/screenshots/results-sample.png')} alt="results" />
                        </div>
                        <div className="col-span-1 lg:p-6">
                            <div className="prose prose-lg">
                                <h1>Receive the Results</h1>
                                <h4>Receive the results securely on your own webserver</h4>
                                <p>When the video has been processed we will post the results to your web server via a secure connection.</p>
                                <p>You can choose what data you wish to receive in our feature packs, from body points and positions, to images with line annotations and more.</p>
                                {/* <div className="flex flex-row justify-start items-center py-2">
                                    <p className="cursor-pointer mt-2 underline text-green-600 text-md font-bold">Learn More About Feature Packs</p>
                                    <FontAwesomeIcon icon={faArrowRight} className="mt-2 mx-2 text-gray-400 text-md transition ease-in-out duration-150" />
                                </div> */}
                            </div>
                            <div className="rounded-md text-white mt-12">
                                <a href="#get-started" className="items-center justify-center px-8 py-3 leading-6 rounded-md text-white bg-green-600 hover:bg-green-700 transition duration-150 ease-in-out text-lg md:py-4 xl:text-xl md:text-lg md:px-10 cursor-pointer font-bold no-underline">
                                Click to Get Started
                                </a>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Results;