import React from 'react';
import Container from '../../tailwind/Container';
import { Button, LearnMore, Loading } from '../../common';
import { connect } from 'react-redux';
import { liveOptions } from '../../../util/swingbot';
import { updateLicenseeCampaign, resetUpdateLicenseeCampaign } from '../../../actions/licenseeCampaign';
import { selectors as authSelectors } from '../../../reducers/auth';
import { selectors as licenseeCampaignSelectors } from '../../../reducers/licenseeCampaign';
import isNumber from 'is-number';
import { formatPrice } from '../../../util/strings';

class LessonInfoPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      description: props.description,
      status: props.status,
      campaign_type: props.campaignType === '' ? 'PRIVATE' : props.campaignType,
      lesson_output: props.lessonOutput === '' ? 'ONLINE' : props.lessonOutput,
      endpoint: props.endpoint,
      price: props.price
    }
  }

  componentDidMount = () => {
    this.props.resetUpdateLicenseeCampaign();
  }

  handleFieldOptionChange = (e) => {
    console.log(e.target.value, e.target.id);
    this.setState({
      [e.target.id]: e.target.value, 
    })
  }

  handleFieldChange = (e) => {
    console.log(e.target.value, e.target.id);
    this.setState({
      [e.target.id]: e.target.value, 
    });
  }

  handlePriceChange = (e) => {
    // make sure this is a number
    if (isNumber(e.target.value) === true || e.target.value === '') {
      // we have to check that the integer in front of the decimal is 
      // greater than 4
      const digits = e.target.value.indexOf('.') > -1 ? (e.target.value.split('.')) : null;
      const pre = digits !== null ? digits[0] : null; // limit to 3 (hundreds)
      const post = digits !== null ? digits[1] : null; // limit to 2


      if (((pre === null && e.target.value.length < 4) || (pre !== null && pre.length < 4 && pre > 2)) && e.target.value !== 0) {
        const price = (post !== null) 
          ? ((post.length < 3) ? e.target.value : this.state.price)
          : e.target.value;
        this.setState({
          price: e.target.value !== '' ? price : ''
        });
      } else {
        // console.log('not valid ', e.target.value, e.target.value.length);
        this.setState({
          price: e.target.value.length < 4 ? e.target.value : this.state.price,
        })
      }
    }
  }

  handleSaveInfo = () => {
    console.log(this.state);
    const {
      title,
      price,
      campaign_type,
      status,
      description,
      lesson_output,
      endpoint
    } = this.state;
    const { authUser, licenseeCampaignId, history} = this.props;
    if (title && campaign_type && status && description) {
      // licenseeId, licenseeCampaignId, title, price, licenseeCampaignType, status, lessonOutput, endpoint, description, history
      this.props.updateLicenseeCampaign(authUser.licensee_id, licenseeCampaignId, title, price, campaign_type, status, lesson_output, endpoint, description, history);
    }
  }

  render() {
    const { 
      title,
      status,
      price,
      campaign_type
    } = this.state;
    console.log(this.state);
    const { updateCampaign } = this.props;
    console.log('UPDATE CAMPAIGN: ', updateCampaign);
    // if the user is able to update the fields, we can change the css to reflect that 
    const inputDisabledCSS = status === 'INACTIVE' 
      ? 'bg-white text-gray-800'
      : 'bg-gray-200 text-gray-500';
      const displayPrice = formatPrice(price);
    return (
      <Container className="p-2">
        <div>
            <div>
                <div className="border-gray-200">
                  <div>
                      <h3 className="text-lg leading-6 font-bold text-gray-800">
                      Lesson Information
                      </h3>
                      <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                      Fill in the fields for your Lesson Program
                      </p>
                  </div>
                {updateCampaign.loading === true && (
                  <Loading title="Saving Changes" />
                )}
                {updateCampaign.loading === false && (
                  <div className="mt-6 sm:mt-5">
                    <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="title" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                            Title
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                              <input 
                                id="title" 
                                value={title} 
                                className={`form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${inputDisabledCSS}`} 
                                onChange={this.handleFieldChange} 
                                disabled={status !== 'INACTIVE'}
                              />
                            </div>
                        </div>
                    </div>
                    
                      <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="country" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                            Lesson Website Status
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                            <select 
                              id="campaign_type" 
                              className={`block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${inputDisabledCSS}`}
                              value={campaign_type} 
                              onChange={this.handleFieldChange}
                              disabled={status !== 'INACTIVE'}
                            >
                              {liveOptions && (liveOptions.map(o => {
                                console.log(o);
                                return <option key={o.name} value={o.value}>{o.name}</option>
                              }))}
                            </select>
                            </div>
                        </div>
                      </div>


                    {/* <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlhtmlFor="about" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                            Description
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="max-w-lg flex rounded-md shadow-sm">
                            <textarea id="description" rows="3" className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" value={description} onChange={this.handleFieldChange}></textarea>
                            </div>
                            <p className="mt-2 text-sm text-gray-500">Write a few sentences about your lesson program.</p>
                        </div>
                    </div> */}

                    <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="price" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                            Website Price ({`${displayPrice !== undefined ? displayPrice : 0.00} USD`})<br />
                            <span className="text-gray-500 text-xs">Price must be at least $3.00 USD</span><br />
                            <LearnMore docPage="/doc/website#accepting-payments-with-stripe" />
                        </label>
                        <div className="mt-1 relative sm:mt-0 sm:col-span-2 sm:max-w-xs border-gray-200 max-w-xl">
                            <div className="max-w-lg rounded-md shadow-sm sm:max-w-xl">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none rounded-md">
                              <span className="text-gray-500 sm:text-sm">
                                $
                              </span>
                            </div>
                              <input 
                                id="price" 
                                value={price} 
                                className={`form-input pl-7 pr-12 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${inputDisabledCSS}`}
                                onChange={this.handlePriceChange}
                                disabled={status !== 'INACTIVE'}
                              />
                              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm" id="price-currency">
                                  USD
                                </span>
                              </div>
                            </div>
                        </div>
                    </div>
                  </div>
                )}
                </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-5">
                <div className="flex justify-end">
                    <Button 
                        onClick={this.handleSaveInfo}
                        title="Save Changes"
                        bgColor="green"
                        hoverColor="green"
                        block
                        size="lg"
                        disabled={updateCampaign.loading || status !== 'INACTIVE'}
                    />
                </div>
            </div>
        </div>
      </Container>
    );
  };
}

LessonInfoPanel.defaultProps = {
  title: 'title',
  description: 'description',
  status: 'INACTIVE',
  price: 0.00,
  campaignType: 'PRIVATE',
  licenseeCampaignId: null,
  lessonOutput: 'ONLINE',
};

const mapStateToProps = ({ program, licensee, targetInterpretation, auth, licenseeCampaign }) => {
  const { 
    programData,
    programsForCampaign,
    getProgramsForCampaignLoading,
    getProgramsForCampaignError,
  } = program;
  const { 
      licenseeData
  } = licensee;
  const { 
      licenseeCampaigns,
      loadingLicenseeCampaigns,
      loadingLicenseeCampaignsError,
      duplicateLicenseeCampaignSuccess,
      duplicateLicenseeCampaignLoading,
      duplicateLicenseeCampaignError,
      updateLicenseeCampaignStatusSuccess,
      updateLicenseeCampaignStatusLoading,
      updateLicenseeCampaignStatusError
  } = licenseeCampaign;
  const { authUser } = auth;
  return {
      updateCampaign: licenseeCampaignSelectors.updateLicenseeCampaign(licenseeCampaign),
      planInformation: authSelectors.planInformation(auth),
      programData,
      programsForCampaign,
      getProgramsForCampaignLoading,
      getProgramsForCampaignError,
      authUser,
      licenseeData,
      licenseeCampaigns,
      loadingLicenseeCampaigns,
      loadingLicenseeCampaignsError,
      duplicateLicenseeCampaignSuccess,
      duplicateLicenseeCampaignLoading,
      duplicateLicenseeCampaignError,
      updateLicenseeCampaignStatusSuccess,
      updateLicenseeCampaignStatusLoading,
      updateLicenseeCampaignStatusError
  };
};

const mapDispatchToProps = {
  updateLicenseeCampaign,
  resetUpdateLicenseeCampaign
};

export default connect(mapStateToProps, mapDispatchToProps)(LessonInfoPanel);