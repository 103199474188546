import React from 'react';
import { Container } from '../../tailwind';
import {
  isPassing
} from '../../../util/strings';
/**
 * class AnalysisResultsPanel
 */
class AnalysisResultPanel extends React.Component {
  handleEdit = () => {
    this.props.onEdit(this.props.data);
  };

  handleDelete = () => {
    this.props.onDelete(this.props.data);
  };
  /**
   * request the stitch video
   */
  handleRequestStitch = () => {};
  /**
   * handle adding this video and code to the qa table
   */

  /**
   * renderTable by looping through the keys
   */
  renderTable() {
    const { data, panelHeader } = this.props;
    const passedAnalysis = isPassing(this.props.data.code);

    return (
      <Container>
      <div className="grid lg:grid-cols-3 sm:grid-cols-1 grid-cols-1 mt-2">
        <div className="flex justify-center align-center lg:col-span-1 sm:col-span-1">
          <img className="rounded-md" src={this.props.data.image} alt="imageswing" />
        </div>
        <div className="lg:col-span-2 sm:col-span-1">
            <h2 className="text-2xl font-bold text-gray-800 space-x-1">{panelHeader}</h2>
            <div className="flex flex-row space-x-2">
              {passedAnalysis === true && (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium leading-4 bg-green-100 text-green-800">
                  Passed
                </span>
              )}
              {passedAnalysis === false && (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium leading-4 bg-red-100 text-red-800">
                  Failed
                </span>
              )}
              <span className="inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium leading-4 bg-blue-100 text-blue-800">
                Score: {data['score']}
              </span>
              <span className="inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium leading-4 bg-blue-100 text-blue-800">
                Detection Accuracy: {data['probability_low']}%
              </span>
            </div>
            <div className="text-lg mt-4">
              <div className="text-md font-bold text-gray-800 mb-2">Definition</div>
              <p className="text-base text-gray-700">{data['def']}</p>
            </div>
            <div className="text-lg mt-4">
              <div className="text-md font-bold text-gray-800 mb-2">Result Summary</div>
              <p className="text-base text-gray-700">{data['msg']}</p>
            </div>
        </div>
      </div>
      </Container>
    );
  }

  render() {
    return this.renderTable();
  }
}

export default AnalysisResultPanel;
