import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { DashboardContent } from '../../tailwind';
import { 
    getProgramsForLicenseeCampaign,  
    resetRemoveTargetFromProgram
} from '../../../actions/program';
import { getTargetInterpretations } from '../../../actions/targetInterpretation';
import { 
    analyzeVideo,
    resetAnalyzeVideo 
} from '../../../actions/analyze';
import Loading from '../../common/Loading';
import ModalConfirmAction from '../../common/modal/ModalConfirmAction';
import TargetConditionsContainer from '../../common/analysis/TargetConditionsContainer';
import TargetCodes from '../../common/analysis/TargetCodes';
import { selectors as authSelectors } from '../../../reducers/auth';

import { 
    getOperandByPseudo, 
    deconstructPseudo, 
    getMaxTargetCondition,
    renderCodeFormat,
    convertOperandToEnglish
} from '../../../util/swingbot';
import { 
    getTargetInterpretationsForProgram, 
    createTargetInterpretationData,
    resetCreateTargetInterpretationData,
    getTargetInterpretationsForTarget,
} from '../../../actions/targetInterpretation';
import { removeLineFromInterpretation } from '../../../actions/line';
import { removeTargetFromProgram } from '../../../actions/program';
import { addTargetFromLibrary } from '../../../actions/target';
// we have to analyze a video to get the points for both FO and DTL....
import Container from '../../tailwind/Container';

class LessonTargetListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            readOnly: this.setReadOnly(),
            isEditable: this.setIsEditable(),
            selectedTab: null,
            showRemoveTargetModal: false,
            selectedMainTab: null,
            selectedBodyPointTab: 1,
            selectedMenuItem: 1, // for target condition
            createNewCondition: null,
            ...this.initialState()
        }
    }

    setReadOnly = () => {
        try {
            return this.props.location.state.readOnly;
        } catch(e) {
            return false;
        }
    }

    setIsEditable = () => {
        try {
            return this.props.location.state.isEditable;
        } catch(e) {
            return false;
        }
    }

    initialState = () => {
        const { state } = this.props.location;
        return {
            definition: (state && 'targetData' in state) ? state.targetData.text_definition : '',
            calculationConditionType: null,
            calculationConditionType1: null,
            calculationConditionType2: null,
            constantValue1: null, // only need 2, not allowed on 1
            constantValue2: null, // only need 2, not allowed on 1
            position1: null,
            position1Selected: null,
            bodyPoints1: null,
            position2: null,
            position2Selected: null,
            bodyPoints2: null,
            equationOperand: null,
            summary: '',
            lines: {},
            operand: null
        }
    }

    setTabContent = () => {
        try {
            // we have to set the first tab...
            const targetInterpretationData = this.getTargetInterpretationData();
            if (targetInterpretationData !== undefined && targetInterpretationData !== null) {
                const code = this.state.selectedTab === null
                    ? Object.keys(targetInterpretationData.equations)[0]
                    : this.state.selectedTab;
                const deconstructed = this.deconstructTargetInterpretation(targetInterpretationData, code);
                
                const equation = targetInterpretationData.equations[code];
                const lines = targetInterpretationData.lines[code];
                const summary = equation[0].summary;
                // set the state
                this.setState({ 
                    selectedTab: code,
                    bodyPoints1: deconstructed[0].bodyPoints1.bodyPoints,
                    bodyPoints2: deconstructed[0].bodyPoints2.bodyPoints,
                    position1: deconstructed[0].bodyPoints1.positions,
                    position2: deconstructed[0].bodyPoints2.positions,
                    calculationConditionType: deconstructed[0].bodyPoints1.calculationType,
                    calculationConditionType1: deconstructed[0].bodyPoints1.calculationType,
                    calculationConditionType2: deconstructed[0].bodyPoints2.calculationType,
                    operand: getOperandByPseudo(equation[0].operand),
                    constantValue1: deconstructed[0].bodyPoints1.constantValue, // only need 2, not allowed on 1
                    constantValue2: deconstructed[0].bodyPoints2.constantValue, // only need 2, not allowed on 1
                    summary: summary,
                    lines: lines
                });
            }
        } catch(e) {
        }
    }

    componentDidCatch = (e) => {
        this.props.history.push({
            pathname: '/500',
            state: e
        });
    }
    
    componentDidMount = () => {
        this.props.resetCreateTargetInterpretationData();
        this.props.resetAnalyzeVideo();
        this.props.resetRemoveTargetFromProgram();
        // we have to fetch the program target interpretations
        const targetId = this.props.location.state.targetId;
        if (targetId) {
            this.props.getTargetInterpretationsForTarget(targetId);
        }
    }

    componentDidUpdate = (nextProps) => {
        /**
         * we have to analyze the video!!!!
         */
        if (this.props.targetInterpretationData !== nextProps.targetInterpretationData) {
            const { targetData } = this.props.location.state;
            if (targetData !== null && targetData !== undefined) {
                const swingTypeId = targetData.swing_type_id;
                if (swingTypeId === 1) {
                    // face on lesson, lets analyze the following video
                    if (this.props.analyzeVideoLoading === false) {
                        this.props.analyzeVideo(22936);
                    }
                } else {
                    // for down the line, we have to choose a video...
                    if (this.props.analyzeVideoLoading === false) {
                        this.props.analyzeVideo(23016);
                    }
                }
                this.setTabContent();
            }
        }
    }

    ensureActiveItemVisible() {
        var itemComponent = this.refs.activeItem;
        if (itemComponent) {
          var domNode = React.findDOMNode(itemComponent);
          this.scrollElementIntoViewIfNeeded(domNode);
        }
    }

    handleRemoveProgram = () => {
        this.setState({
            showRemoveTargetModal: true,
        });
    }

    handleRemoveProgramConfirm = () => {
        const { authUser, history } = this.props;
        const { targetData, programId } = this.props.location.state;
        const targetId = targetData.target_id || null;
        const userId = authUser.user_id;
        const historyPage = 1;
        if (targetId && programId && userId) {
            this.props.removeTargetFromProgram(
                targetId, 
                programId, 
                userId, 
                history, 
                historyPage
            );
        }
    }

    handleDefinitionChange = (definition) => {
        this.setState({
            definition: definition
        });
    }

    /**
     * The main FIRST calculation selected. 
     * This will set the tone for the rest of the application.
     */
    handleCalculationConditionChange = (calculationType) => {
        this.setState({
            calculationConditionType: calculationType,
            calculationConditionType1: calculationType,
            calculationConditionType2: calculationType,
            position1Selected: null,
            position2Selected: null,
            position1: null,
            bodyPoints1: null,
            position2: null,
            bodyPoints2: null,
            constantValue1: null,
            constantValue2: null,
            equationOperand: null,
            summary: '',
            lines: []
        });
    }
    /**
     * from body points component
     */
    handleCalculationTypeChange = (calculationType, number) => {
        if (calculationType !== null) {
            const isConstant = calculationType.pseudo === 'CONSTANT';
            if (isConstant === true) {
                this.setState({
                    [`calculationConditionType${number}`]: calculationType,
                    [`constantValue${number}`]: null
                });
            } else {
                this.setState({
                    [`calculationConditionType${number}`]: calculationType,
                    [`constantValue${number}`]: null
                });
            }
        } else {
            this.setState({
                [`calculationConditionType${number}`]: null,
                [`constantValue${number}`]: null
            });
        }

    }

    handleClickPosition = (positionData) => {
        const { number, position } = positionData;
        this.setState({
            [`position${number}`]: position,
        })
    }

    handleConfirmBodyPoints = (data) => {
        try {
            const number = data.number;
            this.setState({
                [`calculationConditionType${number}`]: data.calculationType,
                [`bodyPoints${number}`]: data.bodyPoints,
                [`position${number}`]: data.positions,
                [`pseudo${number}`]: data.pseudo,
                [`constantValue${number}`]: data.constantValue,
                run: new Date().getTime()
            }, () => {});
        } catch (e) {
        }
    }

    handleConfirmEquation = (equationData) => {
        this.setState({
            operand: equationData.operand,
        });
    }

    handleConstantChange = (value, number) => {
        if (value !== undefined && value !== null) {
            this.setState({
                [`constantValue${number}`]: value,
            });
        } else {
            this.setState({
                [`constantValue${number}`]: null,
            });
        }
    }

    handleSummaryOnChange = (text) => {
        this.setState({
            summary: text
        });
    }

    handleLineChange = (lines) => {
        this.setState({
            lines: lines
        });
    }

    handleSaveAndContinue = () => {
        try {
            // get this from previous page
            const { 
                targetData, 
                readOnly, 
                sourceProgramId, 
            } = this.props.location.state;
            const targetId = targetData.target_id || null;
            const swingTypeId = targetData.swing_type_id || null;
            const targetLexiconName = targetData.lexicon_name;
            const userId = this.props.authUser.user_id;
            const {
                definition,
                // calculationConditionType1,
                // calculationConditionType2,
                constantValue1,
                constantValue2,
                position1,
                bodyPoints1,
                position2,
                bodyPoints2,
                operand,
                summary,
                lines,
                pseudo1, 
                pseudo2
            } = this.state;
            const { history } = this.props;

            // we need to generate the pseudo for the calculations...
            // const pseudo1 = generatePseudo(
            //     calculationConditionType1, 
            //     bodyPoints1, 
            //     position1[1], 
            //     constantValue1
            // );
            // const pseudo2 = generatePseudo(
            //     calculationConditionType2, 
            //     bodyPoints2, 
            //     constantValue2 !== null ? constantValue2 : position2[1], 
            //     constantValue2
            // );

            // create the object to pass to the createTargetInterpretation object...
            const dataToSend = {
                definition,
                targetId,
                userId,
                targetLexiconName,
                swingTypeId,
                constantValue1,
                constantValue2,
                position1,
                bodyPoints1,
                position2,
                bodyPoints2,
                equationOperand: operand,
                summary,
                lines,
                code: this.state.selectedTab,
                pseudo1,
                pseudo2
            };
            
            if (readOnly === false) {
                // send the data to the service....
                const historyPage = 0;
                this.props.createTargetInterpretationData(dataToSend, history, historyPage);
            } else {
                const historyPage = 2; // go back 1 page...
                this.props.addTargetFromLibrary(
                    targetId, 
                    sourceProgramId,
                    userId, 
                    history, 
                    historyPage
                );
            }

        } catch(e) {
        }
    }

    handleChangeTab = (code) => {
      // we really want to navigate to the detail page for this code.

      this.props.history.push({
        pathname: `/lessons/targets/${this.props.location.state.targetId}/${code}`,
        state: this.props.location.state,
        status: this.props.location.state.status,
        readOnly: this.props.location.state.status
      });
        // this.setState({
        //     selectedTab: code,
        //     createNewCondition: null,
        //     calculationConditionType: null,
        //     calculationConditionType1: null,
        //     calculationConditionType2: null,
        //     constantValue1: null,
        //     constantValue2: null,
        //     position1: null,
        //     bodyPoints1: null,
        //     position2: null,
        //     bodyPoints2: null,
        //     equationOperand: null,
        //     summary: null,
        //     lines: []
        // }, () => {
        //     this.setTabContent();
        // });
    }

    handleCreateTab = (type) => {
        const targetInterpretationData = this.getTargetInterpretationData();
        // we have to determine WHAT 
        let newCondition = null;
        newCondition = getMaxTargetCondition(type, targetInterpretationData);

        this.props.history.push({
          pathname: `/lessons/targets/${this.props.location.state.targetId}/${newCondition.code}`,
          state: this.props.location.state
        });

        // // make sure we have a condition here 
        // if (newCondition !== null) {
        //     this.setState({
        //         selectedTab: newCondition.code,
        //         selectedMainTab: this.state.selectedMainTab,
        //         createNewCondition: newCondition,
        //         ...this.initialState()
        //     });
        // }
    }

    /**
     * based on the calculation type selected, we do NOT 
     * want the user to select particular things...
     * 
     * for example, if "compare-distance", we want to keep the SAME
     * body points for both comparisons, and different positions...
     */
    determineDataForCalculationType = (data) => data;

    hasCode = (code, targetInterpretationData) => {
        if (targetInterpretationData !== undefined && targetInterpretationData !== null) {
            if( code in targetInterpretationData.equations) {
                return true;
            }
        }
        return false;
    }
    
    renderTargetInterpretationData = (data, code = null) => {
        const themeCss = code === null ? null : (code === 'P' ? 'bg-green-100' : 'bg-red-100');
        const themeTextColor = code === null ? null : (code === 'P' ? 'text-green-800' : 'text-red-800');
      return data !== undefined && data !== null ? 
        Object.keys(data.equations)
            .filter(k => {
                // let's show either the passing or failing
                if (code !== null) {
                    if (k.substr(0,1) === code.toUpperCase()) {
                        return true;
                    } else {
                        return false;
                    }
                }
                // otherwise just return true...
                return true;
            })
            .map(d => {
          return (
            <Container key={`container-${d}`} className={`${themeCss}`}>
              <div className="flex flex-1 flex-col rounded">
                <div className={`text-xl font-bold ${themeTextColor}`}>{renderCodeFormat(d)}</div>
                <div className="flex-1 h-full">
                  {data.equations[d].map(s => {
                    const equation = `${s.calculation_1_english} is ${convertOperandToEnglish(s.operand)} ${s.calculation_2_english}.`.toLowerCase();
                    const equationSentence = equation.charAt(0).toUpperCase() + equation.slice(1);
                    return (
                      <div key={`${d}-${s}`} className="flex-1 cursor-pointer" onClick={() => this.handleChangeTab(d)}>
                        <p className={themeTextColor}>{equationSentence}</p>
                        {/* <p>{s.summary}</p> */}                    
                      </div>
                    );
                  })}
                </div>
              </div>
            </Container>
          );
        }) : null;
    }

    renderTargetEquations = (codeForTabSelected, isLoading) => {
        const { readOnly } = this.state;
        const targetInterpretationData = this.getTargetInterpretationData();

        // manipulate the data properly based on the calculation type
        const stateData = this.determineDataForCalculationType(this.state);
        const code = this.state.selectedTab;
        return (
            stateData !== null && 
            this.props.analyzeVideoSuccess !== null && 
            this.props.analyzeVideoSuccess !== undefined && 
            code !== null
        )
            ? (
                <div key={code} className="flex flex-col h-full w-full">
                    <TargetCodes
                        readOnly={readOnly}
                        code={code}
                        targetInterpretationData={targetInterpretationData}
                        onSelectCode={this.handleChangeTab}
                        onCreateCode={this.handleCreateTab}
                        newCondition={this.state.createNewCondition}
                    />
                    {this.renderTargetInterpretationData(targetInterpretationData)}
                </div>
            ) : (
                <div className="h-full w-full flex-grow flex flex-col">
                    {code !== null && (
                        <TargetCodes
                            readOnly={readOnly}
                            code={code}
                            targetInterpretationData={targetInterpretationData}
                            onSelectCode={this.handleChangeTab}
                            onCreateCode={this.handleCreateTab}
                            newCondition={this.state.createNewCondition}
                        />
                    )}
                    {code === null && isLoading === false && (
                        <div>
                            {this.renderNoTargets()}
                        </div>
                    )}
                </div>
            );
    }

    renderTargetCodes = () => {
        const { readOnly, isEditable } = this.state;
        const targetInterpretationData = this.getTargetInterpretationData();
        
        // manipulate the data properly based on the calculation type
        const stateData = this.determineDataForCalculationType(this.state);

        const { history } = this.props;
        return (
            stateData !== null && 
            this.props.analyzeVideoSuccess !== null && 
            this.props.analyzeVideoSuccess !== undefined
        )
            ? Object.keys(targetInterpretationData.equations)
                .sort()
                .map(code => (
                    <div key={code} className="">
                        <TargetConditionsContainer 
                            code={code}
                            history={history}
                            readOnly={readOnly} 
                            stateData={stateData} 
                            isEditable={isEditable}
                            targetInterpretationData={targetInterpretationData}
                            analysisResults={this.props.analyzeVideoSuccess}
                            onConditionCreate={this.handleCreateTab}
                            onConstantChange={this.handleConstantChange}
                            onConfirmBodyPoints={this.handleConfirmBodyPoints}
                            onCalculationTypeChange={this.handleCalculationTypeChange}
                            onConfirmEquation={this.handleConfirmEquation}
                            onSelectCode={this.handleChangeTab}
                            onLineChange={this.handleLineChange}
                            onSummaryChange={this.handleSummaryOnChange}
                            onClickSaveAndContinue={this.handleSaveAndContinue}
                        />
                    </div>
                )
            ) : null;
    }
    /**
     * we want to rip apart the target interpretation in order to 
     * populate the panels for editing...if applicable.
     */
    deconstructTargetInterpretation = (targetData, code) => {
        // we want to deconstruct the equations pseudo for each calculation..
        const equations = targetData.equations;
        const deconstructed = equations[code].map(equation => {
            const calculation_1 = equation.calculation_1_pseudo;
            const calculation_2 = equation.calculation_2_pseudo;
            const bodyPoints1 = deconstructPseudo(calculation_1);
            const bodyPoints2 = deconstructPseudo(calculation_2);
            return { bodyPoints1, bodyPoints2 };
        });
        return deconstructed;
    }

    getTargetInterpretationData = (data = null) => {
        const { targetInterpretationData } = this.props;
        const { targetData } = this.props.location.state;
        if (targetInterpretationData !== null && Object.keys(targetInterpretationData).length > 0) {
            return targetInterpretationData[targetData.lexicon_name];
        }
        return null;
    }

    handleClickConditionMenu = (itemIndex) => {
        this.setState({
            selectedMenuItem: itemIndex
        });
    }

    renderMainTabs = () => {
        const selectedClass = 'group inline-flex items-center py-4 px-1 border-b-2 border-green-600 font-bold text-base leading-5 text-gray-700 focus:outline-none focus:text-gray-700 focus:border-gray-300 cursor-pointer';
        const unselectedClass = 'group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-base leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 cursor-pointer';
             
        return (
            <div className="flex flex-row rounded mb-4">                        
                <div className="sm:hidden">
                    <select aria-label="Selected tab" className="form-select block w-full">
                        <option>Target Conditions</option>
                        <option>Target Definition</option>
                    </select>
                </div>
                <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                    <nav className="flex -mb-px">
                        <div onClick={() => this.handleClickConditionMenu(2)} className={`ml-8 ${this.state.selectedMenuItem === 2 ? selectedClass : unselectedClass}`}>
                            <svg className="-ml-0.5 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clipRule="evenodd" />
                            </svg>
                            <span>Target Conditions</span>
                        </div>
                        <div onClick={() => this.handleClickConditionMenu(1)} className={`${this.state.selectedMenuItem === 1 ? selectedClass : unselectedClass}`}>
                            <svg className="-ml-0.5 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                            </svg>
                            <span>Target Definition</span>
                        </div>
                    </nav>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * renderAddTargetButton
     * This will render the button the user can press for either creating the p|f condition
     * @param {string} targetType either passing or failing, P || F
     * @returns 
     */
    renderAddTargetButton = (targetType = 'P') => {
        const addType = targetType === 'P' ? 'pass' : 'fail';
        const text = targetType === 'P' ? 'Passing' : 'Failing';
        return (
            <Container key={`container-add-target-${targetType}`} className="border-dashed border-2 border-gray-300 cursor-pointer bg-white flex">
              <div className="flex flex-row align-middle items-center space-x-2" onClick={() => this.handleCreateTab(addType)} >
                <FontAwesomeIcon icon={faPlus} className="text-gray-500 transition ease-in-out duration-150" />
                <div className="text-xl font-bold text-gray-500">{`New ${text} Condition`}</div>
              </div>
            </Container>
        )
    }

    /**
     * renderTargets
     * Render the targets in the list for either passing or failing
     * @param {string} codeForTabSelected P|F
     * @returns 
     */
    renderTargets = (codeForTabSelected = 'P') => {
        const targetInterpretationData = this.getTargetInterpretationData();
        // manipulate the data properly based on the calculation type
        const stateData = this.determineDataForCalculationType(this.state);
        const { analyzeVideoSuccess } = this.props;
        const { readOnly, status } = this.props.location.state;
        return (
            stateData !== null && 
            analyzeVideoSuccess !== null && 
            analyzeVideoSuccess !== undefined
        )
            ? (
                <div key={'F-code'} className="flex flex-col w-full">
                    {readOnly === false && status.toLowerCase() === 'inactive' && (
                        <div className="flex">{this.renderAddTargetButton(codeForTabSelected)}</div>
                    )}
                    <div className="flex-1">{this.renderTargetInterpretationData(targetInterpretationData, codeForTabSelected)}</div>
                </div>
            ) : (
                <div className="h-full w-full flex-grow flex flex-col">
                    {readOnly === false && status.toLowerCase() === 'inactive' && this.renderAddTargetButton(codeForTabSelected)}
                </div>
            );
    }

    render() {
        const { targetData, readOnly, status } = this.props.location.state;
        const { 
            getTargetInterpretationProgramLoading,
            createTargetInterpretationLoading,
            getTargetInterpretationTargetLoading,
            removeTargetFromProgramError,
            removeTargetFromProgramLoading,
            removeTargetFromProgramSuccess
        } = this.props;
        const isLoading = (getTargetInterpretationProgramLoading === true || createTargetInterpretationLoading === true || getTargetInterpretationTargetLoading === true);

        return  (
            <DashboardContent 
                title={isLoading === true ? null : targetData.display_name} 
                backTitle="back"
                buttonTitle={readOnly === false ? `Remove Target from Program` : null}
                buttonClassColor="red"
                onClick={status.toLowerCase() === 'inactive' ? this.handleRemoveProgram : null}
            >
                {isLoading === false && (
                    <div className="flex w-full h-full">
                        <div className="flex w-full h-full">
                            <div className="flex flex-col min-h-full w-full h-full">
                                <div className="flex-1 flex-col h-full">
                                    <h1 className="p-4 text-3xl font-bold text-gray-800">Conditions</h1>
                                    <Container>
                                        <p>Each <span className="font-bold">Lesson Target</span> has both Passing and Failing conditions. Create the conditions that must be met for the Lesson Target to pass and also the possible failing conditions if the golfer has not met the passing criteria.</p>
                                    </Container>
                                    <div className="grid grid-cols-2 gap-2">
                                        <div className="p-4 bg-white rounded-lg">
                                            {this.renderTargets('P', isLoading)}
                                        </div>
                                        <div className="p-4 bg-white rounded-lg flex-1">
                                            {this.renderTargets('F', isLoading)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {isLoading === true && (<Container><Loading /></Container>)}
                <ModalConfirmAction 
                    show={this.state.showRemoveTargetModal}
                    title="Confirm Target Removal"
                    buttonTitleConfirm="Yes, delete this Lesson Target"
                    buttonTitleCancel="Cancel"
                    body="Are you sure you wish to delete this Lesson Target?"
                    history={this.props.history}
                    onCancel={() => {
                        this.setState({
                            showRemoveTargetModal: false
                        });
                    }}
                    onConfirm={() => {
                        this.handleRemoveProgramConfirm();
                    }}
                    isLoading={removeTargetFromProgramLoading}
                    isSuccess={removeTargetFromProgramSuccess}
                    isError={removeTargetFromProgramError}
                >
                    <ul>
                        <li>This Lesson Target will be removed from your Lesson Program</li>
                        <li>This action cannot be undone.</li>
                    </ul>
                </ModalConfirmAction>
            </DashboardContent>
        );
    }
}

const mapStateToProps = ({ program, auth, targetInterpretation, licensee, analyze, line }) => {
    const { 
        programData,
        programsForCampaign,
        getProgramsForCampaignLoading,
        getProgramsForCampaignError,
        removeTargetFromProgramLoading,
        removeTargetFromProgramSuccess,
        removeTargetFromProgramError
    } = program;
    const {
        analyzeVideoLoading,
        analyzeVideoSuccess,
        analyzeVideoError
    } = analyze;
    const { 
        licenseeData
    } = licensee;
    const { 
        targetInterpretations,
        getTargetInterpretationProgramLoading,
        getTargetInterpretationProgramError,
        createTargetInterpretationSuccess,
        createTargetInterpretationLoading,
        createTargetInterpretationError,
        targetInterpretationTargetData,
        getTargetInterpretationTargetLoading,
        getTargetInterpretationTargetError
     } = targetInterpretation;
    const { authUser } = auth;
    const { 
        removeLineFromInterpretationLoading,
        removeLineFromInterpretationSuccess,
        removeLineFromInterpretationError
    } = line;
    return {
        planInformation: authSelectors.planInformation(auth),
        programData,
        programsForCampaign,
        getProgramsForCampaignLoading,
        getProgramsForCampaignError,
        authUser,
        targetInterpretations,
        targetInterpretationData: targetInterpretationTargetData,
        getTargetInterpretationProgramLoading,
        getTargetInterpretationProgramError,
        licenseeData,
        analyzeVideoLoading,
        analyzeVideoSuccess,
        analyzeVideoError,
        createTargetInterpretationSuccess,
        createTargetInterpretationLoading,
        createTargetInterpretationError,
        removeLineFromInterpretationLoading,
        removeLineFromInterpretationSuccess,
        removeLineFromInterpretationError,
        targetInterpretationTargetData,
        getTargetInterpretationTargetLoading,
        getTargetInterpretationTargetError,
        removeTargetFromProgramLoading,
        removeTargetFromProgramSuccess,
        removeTargetFromProgramError
    };
}

LessonTargetListPage.defaultProps = {
    readOnly: false, // this should be false always...
    targetInterpretationData: null,
}

const mapDispatchToProps = {
    getProgramsForLicenseeCampaign,
    getTargetInterpretations,
    analyzeVideo,
    getTargetInterpretationsForProgram,
    createTargetInterpretationData,
    removeTargetFromProgram,
    resetRemoveTargetFromProgram,
    resetCreateTargetInterpretationData,
    resetAnalyzeVideo,
    removeLineFromInterpretation,
    addTargetFromLibrary,
    getTargetInterpretationsForTarget
}

const lessonTargetListPageWithRouter = withRouter(LessonTargetListPage);
export default connect(mapStateToProps, mapDispatchToProps)(lessonTargetListPageWithRouter);