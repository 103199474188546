import * as services from './services';
import * as actionCreators from './actions';

export const getProgramsByLicenseeId = (licenseeId) => dispatch => {
    dispatch(actionCreators.getProgramsByLicenseeId(licenseeId));
    return services.getProgramsByLicenseeId(licenseeId)
        .then(data => dispatch(actionCreators.getProgramsByLicenseeIdSuccess(data.data)))
        .catch(e => dispatch(actionCreators.getProgramsByLicenseeIdFail(e)));
}
/**
 * getProgramsForLicenseeCampaign
 * 
 * Get all of the programs and targets
 * 
 * @param {int} licenseeCampaignId 
 * @param {int} licenseeId
 */
export const getProgramsForLicenseeCampaign = (licenseeCampaignId, licenseeId) => dispatch => {
    dispatch(actionCreators.getProgramsForLicenseeCampaign(licenseeCampaignId, licenseeId));
    return services.getProgramsForLicenseeCampaign(licenseeCampaignId, licenseeId)
        .then(data => dispatch(actionCreators.getProgramsForLicenseeCampaignSuccess(data.data)))
        .catch(e => dispatch(actionCreators.getProgramsForLicenseeCampaignFail(e)));
}

// this.props.getProgramData(this.props.location.state.programId, authUser.user_id);

export const getProgramsWithTargetsForLicenseeCampaign = (licenseeCampaignId, licenseeId) => dispatch => {
    dispatch(actionCreators.getProgramsForLicenseeCampaign(licenseeCampaignId, licenseeId));
    return services.getProgramsForLicenseeCampaign(licenseeCampaignId, licenseeId)
        .then(data => {
            // now we have to get the targets for each program..
            
            // dispatch(actionCreators.getProgramsForLicenseeCampaignSuccess(data.data))
        })
        .catch(e => dispatch(actionCreators.getProgramsForLicenseeCampaignFail(e)));
}

export const getProgramData = (programId, userId) => dispatch => {
    dispatch(actionCreators.getProgramData(programId, userId));
    return services.getProgramData(programId, userId)
        .then(data => dispatch(actionCreators.getProgramDataSuccess(data.data)))
        .catch(e => dispatch(actionCreators.getProgramDataFail(e)));
}

export const getTargetsForProgram = (programId, userId) => dispatch => {
    dispatch(actionCreators.getTargetsForProgram(programId, userId));
    return services.getTargetsForProgram(programId, userId)
        .then(data => dispatch(actionCreators.getTargetsForProgramSuccess(data.data)))
        .catch(e => dispatch(actionCreators.getTargetsForProgramFail(e)));
}

/**
 * createProgram
 * @param {string} name the program name
 * @param {string} description the description of the program (displayed)
 * @param {int} userId the user id authoring the program
 * @param {int} duration the number of days for the program will last (optional)
 * @param {int} swingTypeId the swing type (1 = full swing, etc)
 * @param {string} status the status ACTIVE|INACTIVE
 */
export const createProgram = (name, description, userId, duration, swingTypeId, status = 'INACTIVE') => dispatch => {
    dispatch(actionCreators.createProgram(name, description, userId, duration, swingTypeId, status));
    return services.createProgram(name, description, userId, duration, swingTypeId, status)
        .then(data => dispatch(actionCreators.createProgramSuccess(data.data)))
        .catch(e => dispatch(actionCreators.createProgramFail(e)));
}

/**
 * duplicateProgram
 * Copy ALL targets, summaries, lines, etc to a new program
 * @param {int} programId 
 * @param {int} userId 
 */
export const duplicateProgram = (programId, userId) => dispatch => {
    dispatch(actionCreators.duplicateProgram(programId, userId));
    return services.duplicateProgram(programId, userId)
        .then(data => dispatch(actionCreators.duplicateProgramSuccess(data.data)))
        .catch(e => dispatch(actionCreators.duplicateProgramFail(e)));
}

export const removeTargetFromProgram = (targetId, programId, userId, history = null, historyPage = null) => dispatch => {
    dispatch(actionCreators.removeTargetFromProgram(targetId, programId, userId));
    return services.removeTargetFromProgram(targetId, programId, userId)
        .then(data => {
            dispatch(actionCreators.removeTargetFromProgramSuccess(data.data));
            if (history) {
                if (historyPage !== null) {
                    history.go(-historyPage);
                } else {
                    // go to the previous page...
                    history.goBack();
                }
            }
        })
        .catch(e => dispatch(actionCreators.removeTargetFromProgramFail(e)));
}

export const resetRemoveTargetFromProgram = () => dispatch => {
    dispatch(actionCreators.resetRemoveTargetFromProgram());
};

export const removeAllTargetsFromProgram = (programId, userId) => dispatch => {
    dispatch(actionCreators.removeTargetFromProgram(programId, userId));
    return services.removeTargetFromProgram(programId, userId)
        .then(data => dispatch(actionCreators.removeTargetFromProgramSuccess(data.data)))
        .catch(e => dispatch(actionCreators.removeTargetFromProgramFail(e)));
}
