/**
 * LicenseeReducer
 *
 * Fetch the list of users from the database
 */
 import * as actionTypes from '../actions/slack/types';
 
   // set the initial state of the reducer
     const INITIAL_STATE = {
      verifySlackCodeSuccess: null,
      verifySlackCodeLoading: false,
      verifySlackCodeError: null,

      createSlackWebhookForLicenseeSuccess: null,
      createSlackWebhookForLicenseeLoading: false,
      createSlackWebhookForLicenseeError: null,

      getSlackWebhookForLicenseeSuccess: null,
      getSlackWebhookForLicenseeLoading: false,
      getSlackWebhookForLicenseeError: null,
     };
   
    export default (state = INITIAL_STATE, action) => {
      switch (action.type) {

        case actionTypes.RESET_SLACK_WEBHOOK:
          return {
            ...state,
            verifySlackCodeSuccess: null,
            verifySlackCodeLoading: true,
            verifySlackCodeError: null,
            createSlackWebhookForLicenseeSuccess: null,
            createSlackWebhookForLicenseeLoading: true,
            createSlackWebhookForLicenseeError: null,
          }

        case actionTypes.VERIFY_SLACK_CODE:
          return {
            ...state,
            verifySlackCodeSuccess: null,
            verifySlackCodeLoading: true,
            verifySlackCodeError: null,
          }
 
        case actionTypes.VERIFY_SLACK_CODE_SUCCESS:
          return {
            ...state,
            verifySlackCodeSuccess: action.payload,
            verifySlackCodeLoading: false,
            verifySlackCodeError: null,
          }
 
        case actionTypes.VERIFY_SLACK_CODE_FAIL:
          return {
            ...state,
            verifySlackCodeSuccess: null,
            verifySlackCodeLoading: false,
            verifySlackCodeError: action.payload,
          }
       /**
        * create slack webhook
        */
        case actionTypes.CREATE_SLACK_WEBHOOK:
          return {
            ...state,
            createSlackWebhookForLicenseeSuccess: null,
            createSlackWebhookForLicenseeLoading: true,
            createSlackWebhookForLicenseeError: null,
          }
 
        case actionTypes.CREATE_SLACK_WEBHOOK_SUCCESS:
          return {
            ...state,
            createSlackWebhookForLicenseeSuccess: action.payload,
            createSlackWebhookForLicenseeLoading: false,
            createSlackWebhookForLicenseeError: null,
          }
 
        case actionTypes.CREATE_SLACK_WEBHOOK_FAIL:
          return {
            ...state,
            createSlackWebhookForLicenseeSuccess: null,
            createSlackWebhookForLicenseeLoading: false,
            createSlackWebhookForLicenseeError: action.payload,
          }
         
          case actionTypes.GET_SLACK_WEBHOOK:
            return {
              ...state,
              getSlackWebhookForLicenseeSuccess: null,
              getSlackWebhookForLicenseeLoading: true,
              getSlackWebhookForLicenseeError: null,
            }
   
          case actionTypes.GET_SLACK_WEBHOOK_SUCCESS:
            console.log('success slack webhook ', action.payload);
            return {
              ...state,
              getSlackWebhookForLicenseeSuccess: action.payload,
              getSlackWebhookForLicenseeLoading: false,
              getSlackWebhookForLicenseeError: null,
            }
   
          case actionTypes.GET_SLACK_WEBHOOK_FAIL:
            return {
              ...state,
              getSlackWebhookForLicenseeSuccess: null,
              getSlackWebhookForLicenseeLoading: false,
              getSlackWebhookForLicenseeError: action.payload,
            }
           
 
        default:
          return state;
      }
    };
   
 
    export const selectors = {
      slackOauth:(state) => {
        console.log('slack verify success ', state.verifySlackCodeSuccess);
        return state.verifySlackCodeSuccess !== null && state.verifySlackCodeSuccess.data.ok === true
        ? {
            success: state.verifySlackCodeSuccess !== null,
            loading: state.verifySlackCodeLoading,
            error: state.verifySlackCodeError,
            channel: state.verifySlackCodeSuccess.data.incoming_webhook.channel || null,
            webhook: state.verifySlackCodeSuccess.data.incoming_webhook.url || null,
        } : {
          success: state.verifySlackCodeSuccess,
          loading: state.verifySlackCodeLoading,
          error: state.verifySlackCodeError,
          channel: null,
          webhook: null
        };
      },
      slackWebhook:(state) => {
        console.log('selector: ', state.getSlackWebhookForLicenseeSuccess);
        return {
              success: state.getSlackWebhookForLicenseeSuccess !== null ? state.getSlackWebhookForLicenseeSuccess.success : null,
              channel: state.getSlackWebhookForLicenseeSuccess !== null ? state.getSlackWebhookForLicenseeSuccess.data.channel : null,
              webhook_url: state.getSlackWebhookForLicenseeSuccess !== null ? state.getSlackWebhookForLicenseeSuccess.data.webhook : null,
              loading: state.getSlackWebhookForLicenseeLoading,
              error: state.getSlackWebhookForLicenseeError
          };
       },
       createSlackWebhook:(state) => {
        return {
            success: state.createSlackWebhookForLicenseeSuccess,
            loading: state.createSlackWebhookForLicenseeLoading,
            error: state.createSlackWebhookForLicenseeError
          };
       },
     }