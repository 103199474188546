/**
 * ResetPassword component
 */
import React from 'react';
import { connect } from 'react-redux';
import { resetPassword } from '../../actions/auth';
import { withRouter, Link } from 'react-router-dom';

class ResetPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            confirmPassword: ''
        }
    }

    componentDidMount = () => {

    }

    handleKeypress = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    handleResetPassword = (e) => {
        try {
            e.preventDefault();
            const nonce = this.props.match.params.nonce;
            const { password, confirmPassword } = this.state;
            this.props.resetPassword(password, confirmPassword, nonce, this.props.history);
        } catch(e) {
            
        }
    }

    render() {
        const { 
            // resetPasswordSuccess,
            resetPasswordLoading,
            // resetPasswordError
        } = this.props;
        return (
            <div className="min-h-screen bg-gray-800 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img className="mx-auto h-12 w-auto" src={require('../../img/logo.png')} alt="Swingbot SaaS" />
                <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-white">
                Reset Password
                </h2>
                <p className="mt-2 text-center text-sm leading-5 text-gray-200 max-w">
                or&nbsp;
                <Link to="/login" className="font-medium text-green-600 hover:text-green-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                    sign in
                </Link>
                </p>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form onSubmit={this.handleResetPassword}>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                            New Password
                        </label>
                        <div className="mt-1 rounded-md shadow-sm">
                            <input 
                                id="password" 
                                type="password" 
                                required 
                                onChange={this.handleKeypress}
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                        </div>
                    </div>

                    <div className="mt-4">
                        <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                            Confirm New Password
                        </label>
                        <div className="mt-1 rounded-md shadow-sm">
                            <input 
                                id="confirmPassword" 
                                type="password" 
                                required 
                                onChange={this.handleKeypress}
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                        </div>
                    </div>
                    {resetPasswordLoading !== true && (
                        <div className="mt-6">
                            <span className="block w-full rounded-md shadow-sm">
                                <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out">
                                Submit
                                </button>
                            </span>
                        </div>
                    )}
                </form>
                </div>
            </div>
            </div>
        );
    }
}

const mapStatetoProps = ({ auth }) => {
    const { 
        authUser,
        token,
        resetPasswordSuccess,
        resetPasswordLoading,
        resetPasswordError,
    } = auth;
    return { 
        authUser,
        token,
        resetPasswordSuccess,
        resetPasswordLoading,
        resetPasswordError,
    };
};

const mapDispatchToProps = {
    resetPassword
}
const resetPasswordWithRouter = withRouter(ResetPassword);
export default connect(mapStatetoProps, mapDispatchToProps)(resetPasswordWithRouter);