import React from 'react';
import { DashboardContent, Container } from '../../tailwind';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Loading from '../../common/Loading';
import Button from '../../common/Button';
import AccountRenewalBanner from '../../common/banner/AccountRenewalBanner';

import './CartPage.css';
// braintree
import DropIn from "braintree-web-drop-in-react";
import { getPlans } from '../../../actions/plan';
import {
    subscribeToPlan,
    subscribeToPlanReset,
    createBraintreeCustomerGetToken
} from '../../../actions/subscribe';
import ReactSlider from 'react-slider';

class CartPage extends React.Component {
    // braintree instance
    instance;

    constructor(props) {
        super(props);
        this.state = {
            sandbox: process.env.REACT_APP_BRAINTREE_SANDBOX,
            planSelected: 'basic',
            featurePackage: 'standard',
            videoPackage: 'basic',
            term: 'monthly',
            maxVideos: 1000,
            videos: 100
        }
    }

    componentDidMount = () => {
        // const isSandbox = this.state.sandbox === 'true' ? true : false;
        this.props.subscribeToPlanReset();
        // if (this.props.authUser) {
        //     // const { user_id, f_name, l_name, email } = this.props.authUser;
        //     // this.props.createBraintreeCustomerGetToken(
        //     //     user_id, 
        //     //     f_name, 
        //     //     l_name, 
        //     //     email, 
        //     //     isSandbox // this.state.sandbox
        //     // );
        // }
        this.props.getPlans();
    }
    
    handleSelectFeaturePackage = (packageName) => {
        this.setState({
            featurePackage: packageName
        });
    }

    handleSelectVideoPackage = (packageName) => {
        this.setState({
            videoPackage: packageName
        });
    }

    handleVideoChange = (e) => {
        this.setState({
            videos: e
        });
    }

    handleSelectTerm = (term) => {
        this.setState({
            term: term,
            maxVideos: term === 'annually' ? 12000 : 1000,
            videos: term === 'annually' ? 1200 : 100
        });
    }

    handleContactUsPricing = () => {
        console.log('contact us');
    }
    
    /**
     * handleBuyButton
     * 
     * 1. Request a payment nonce from braintree based on the 
     *      payment information that we have typed in.
     * 2. Receive a nonce from braintree if all is well with the payment
     *      information, and send that nonce along with the plan information
     *      to our server to make a subscription purchase.
     */
    handleBuyButton = () => {
        const planData = this.getPlanDataPackage(
            this.state.featurePackage, 
            this.state.term, 
            this.state.videos
        );
        
        this.props.history.push({
            pathname: '/checkout',
            state: {
                planData,
                sandbox: this.state.sandbox
            }
        });
        
        // // 1. Request the nonce from braintree
        // this.instance.requestPaymentMethod()
        //     .then(data => {
        //         const paymentNonce = data.nonce;
        //         if (paymentNonce) {
        //             // get the plan name from the plans object
        //             let planName = planData.name;
        //             // get the user id as well
        //             const userId = this.props.authUser.user_id;
        //             // now we can make the request to our server
        //             // to make the purchase.
        //             this.props.subscribeToPlan(
        //                 userId, 
        //                 paymentNonce, 
        //                 planName, 
        //                 this.state.sandbox
        //             );
        //         }
        //     })
        //     .catch(e => {
        //         this.setState({
        //             paymentNonce: null
        //         })
        //     });
    }

    renderPlans = () => {
        const { plans } = this.props;
        return plans !== null
            ? plans.map(plan => {
                return (
                    <div className="">
                        {JSON.stringify(plan)}          
                    </div>
                )
            })
            : (<Loading />);
    }

    renderBuyButton = () => {
        const planData = this.getPlanDataPackage(
            this.state.featurePackage, 
            this.state.term, 
            this.state.videos
        );
        const {
            subscriptionSuccess,
            subscriptionLoading,
            subscriptionError
        } = this.props;
        return planData.price_plan < 10000 && subscriptionLoading === false && subscriptionSuccess === null && subscriptionError === null ? (
            <button 
                onClick={this.handleBuyButton} 
                type="button" 
                className="block w-full text-center rounded-lg border border-transparent bg-gray-700 px-6 py-4 text-2xl leading-6 font-medium text-white hover:bg-gray-800 focus:outline-none focus:shadow-outline transition ease-in-out duration-150 cursor-pointer"
            >
                Buy Now
            </button>
        ) : <Loading />;
    };

    renderBraintree = () => {
        const planData = this.getPlanDataPackage(
            this.state.featurePackage, 
            this.state.term, 
            this.state.videos
        );
        const {
            subscriptionSuccess,
            subscriptionLoading,
            subscriptionError
        } = this.props;
        return this.props.clientToken !== null && planData !== null && planData.price_plan < 10000 ? ( 
                <Container>
                    <div id="braintree">
                    {subscriptionError === null && subscriptionLoading === false && subscriptionSuccess === null && (
                        <DropIn
                            options={{ 
                                authorization: this.props.clientToken,
                                paypal: {
                                    flow: 'checkout',
                                    amount: planData.price_plan,
                                    currency: 'USD'
                                },
                                planId: planData.name
                            }}
                            onInstance={instance => (this.instance = instance)}
                        />
                    )}
                    {subscriptionError === null && subscriptionLoading === false && subscriptionSuccess === null && this.renderBuyButton()}

                    {subscriptionLoading === true && <Loading />}

                    {subscriptionSuccess !== null && (
                        <div className="">
                            <h3 className="text-2xl font-bold text-green-700">Your transaction was successful!</h3>
                            <p className="text-lg text-gray-600">Your transaction id is: <span className="font-bold">{subscriptionSuccess.data.data.subscription.id}</span></p>
                        </div>
                    )}

                    {subscriptionError !== null && (
                        <div className="">
                            <h3 className="text-2xl font-bold text-gray-800">There was an error processing your payment, pelase try again later.</h3>
                        </div>
                    )}


                    </div>
                </Container>
        ) : <Loading />;
    }

    renderTermType = () => {
        return (
            <div className="mb-4 text-center">
                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                    <button 
                        onClick={() => this.handleSelectTerm('monthly')} 
                        type="button" 
                        className={`relative inline-flex items-center px-8 py-4 rounded-l-md text-xl leading-5 font-medium focus:z-10 transition ease-in-out duration-150 outline-none ${this.state.term === 'monthly' ? 'bg-gray-800 text-white' : 'bg-white text-gray-700'}`}
                    >
                        Monthly
                    </button>
                    <button type="button" onClick={() => this.handleSelectTerm('annually')} className={`relative inline-flex items-center px-8 py-4 rounded-r-md text-lg leading-5 font-medium focus:z-10 transition ease-in-out duration-150 outline-none ${this.state.term === 'annually' ? 'bg-gray-800 text-white' : 'bg-white text-gray-700'}`}>
                        Annually
                    </button>
                </span>
            </div>
        )
    }

    getPricePerVideoForPackage = (packageName, term) => {
        const { plans } = this.props;
        let price = null;
        if (plans !== null) {
            plans.forEach(plan => {
                if (plan.feature_package_name.toLowerCase() === packageName.toLowerCase() &&
                    plan.term === term) {
                    // set the plan price per video
                    price = plan.price_per_video;
                }
            });
        }
        return price;
    }

    getTargetsPerPlan = (packageName, videoPackageName = "basic", term = "monthly") => {
        const { plans } = this.props;
        let targets = null;
        if (plans !== null) {
            plans.forEach(plan => {
                if (plan.feature_package_name.toLowerCase() === packageName.toLowerCase() &&
                    plan.video_package_name.toLowerCase() === videoPackageName.toLowerCase() &&
                    plan.term === term
                ) {
                    // set the plan price per video
                    targets = plan.lesson_targets_num;
                }
            });
        }
        return targets;
    }

    getLessonProgramsPerPlan = (packageName, videoPackageName = "basic", term = "monthly") => {
        const { plans } = this.props;
        let lessons = null;
        if (plans !== null) {
            plans.forEach(plan => {
                if (plan.feature_package_name.toLowerCase() === packageName.toLowerCase() &&
                    plan.video_package_name.toLowerCase() === videoPackageName.toLowerCase() &&
                    plan.term === term) {
                    // set the plan price per video
                    lessons = plan.lessons_num;
                }
            });
        }
        return lessons;
    }

    getPlanDataPackage = (packageName, term, videos) => {
        const { plans } = this.props;
        let planSelected = null;
        const termMultiplier = term === 'annually' ? 12 : 1;
        if (plans !== null) {
            plans.forEach((plan,index) => {
                if (plan.feature_package_name.toLowerCase() === packageName.toLowerCase() && plan.term === term) {
                    if (planSelected === null) {
                        if (videos <= (plan.videos_per_month * termMultiplier)) {
                            planSelected = plan;
                        }
                    } else {
                        if (videos <= (plan.videos_per_month * termMultiplier) && (plan.videos_per_month * termMultiplier) < (planSelected.videos_per_month * termMultiplier)) {
                            planSelected = plan;
                        }
                    }
                }
            });
        }
        return planSelected;
    }


    getTotalPrice = (packageName) => {
        const { term, videos } = this.state;
        const { plans } = this.props;
        const multiplier = term === 'annually' ? 12 : 1;
        if (plans !== null) {
            const finalPlan = plans.filter(plan => {
                return (
                    plan.feature_package_name.toLowerCase() === packageName.toLowerCase() &&
                    plan.term === term &&
                    videos <= (plan.videos_per_month * multiplier)
                );
            })[0];
            if (finalPlan) {
                const finalNumber = Math.round(finalPlan.price_per_video * (finalPlan.videos_per_month * multiplier),2);
                return {
                    'string': finalNumber.toLocaleString(),
                    'number': finalNumber
                };
            }
        }
        return null;
    }
    
    renderPrice = () => {
        const { 
            featurePackage,
            videoPackage,
            term
         } = this.state;
        const { plans } = this.props;
        if (plans) {
            const filtered = plans.filter(plan => {
                return (plan.video_package_name.toLowerCase() === videoPackage.toLowerCase() && plan.feature_package_name.toLowerCase() === featurePackage.toLowerCase() && plan.term === term);
            });
            return filtered.length > 0 ? filtered[0] : null;
        }
        return null;
    }

    renderVideoPackages = () => {
        const priceData = this.renderPrice();
        const { term } = this.state;
        return priceData !== null ? (
            <Container className="bg-white shadow mb-4">
                <div className="flex flex-col w-full">
                    <div className="flex p-4 items-center justify-center w-5/12">
                        <p className="text-4xl text-gray-800 font-extrabold">{this.state.videos} videos {term}</p>
                    </div>
                    <div className="flex-1 items-center justify-center mr-8 p-4">
                        <ReactSlider
                            className="horizontal-slider"
                            thumbClassName="thumb-slider bg-gray-800 text-white font-bold text-xl text-center rounded-full h-12 w-12"
                            trackClassName="example-track bg-gray-200"
                            renderThumb={(props, state) => <div {...props}>&nbsp;</div>}
                            max={this.state.maxVideos}
                            onChange={this.handleVideoChange}
                            defaultValue={this.state.videos}
                            value={this.state.videos}
                        />
                    </div>
                </div>
            </Container>
        ) : null;
    }

    renderFeaturePackages = () => {
        const planData = this.getPlanDataPackage(
            this.state.featurePackage, 
            this.state.term, 
            this.state.videos
        );
        let standardBorder;
        let premiumBorder;
        let eliteBorder;
        let isStandard;
        let isPremium;
        let isElite;

        const { 
            videoPackage,
            term 
        } = this.state;

        if (planData) {
            standardBorder = planData.feature_package_name === 'Standard' ? 'border-green-600' : 'border-gray-200 hover:border-green-600 cursor-pointer';
            premiumBorder = planData.feature_package_name === 'Premium' ? 'border-green-600' : 'border-gray-200 hover:border-green-600 cursor-pointer';
            eliteBorder = planData.feature_package_name === 'Elite' ? 'border-green-600' : 'border-gray-200 hover:border-green-600 cursor-pointer';
            isStandard = planData.feature_package_name === 'Standard' ? true : false;
            isPremium = planData.feature_package_name === 'Premium' ? true : false;
            isElite = planData.feature_package_name === 'Elite' ? true : false;
        }

        return planData !== null ? (
            <div className="">
                <div className="grid grid-cols-1 lg:grid-cols-3 sm:grid-cols-1 gap-6">
                    <div 
                        className={`flex-1 flex flex-col rounded overflow-hidden shadow border-2 ${standardBorder}`}
                        onClick={() => this.handleSelectFeaturePackage('standard')}
                    >
                        <div className="bg-white px-6 py-10">
                        <div>
                            <h3 className="text-center text-2xl leading-8 font-medium text-gray-900" id="tier-hobby">
                            Standard
                            </h3>
                            <div className="mt-4 flex items-center justify-center">
                            <span className="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900">
                                <span className="mt-2 mr-2 text-4xl font-medium">
                                $
                                </span>
                                <span className="font-extrabold">
                                    {this.getPricePerVideoForPackage('standard', term)}
                                </span>
                            </span>
                            <span className="text-xl leading-7 font-medium text-gray-500">
                                /video
                            </span>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                    <ul>
                        <li className="flex items-start">
                        <div className="flex-shrink-0">
                            <FontAwesomeIcon icon={faCheck} className="text-green-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            {this.getLessonProgramsPerPlan('standard', videoPackage, term)} Lesson Programs
                        </p>
                        </li>
                        <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                            <FontAwesomeIcon icon={faCheck} className="text-green-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            {this.getTargetsPerPlan('standard', videoPackage, term)} Lesson Targets per Program
                        </p>
                        </li>
                        {/* <li className="mt-4 flex items-start">
                            <div className="flex-shrink-0">
                                <FontAwesomeIcon icon={faCheck} className="text-green-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                            </div>
                            <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                                Standard Analysis Results
                            </p>
                        </li> */}
                        <li className="mt-4 flex items-start">
                            <div className="flex-shrink-0">
                                <FontAwesomeIcon icon={faTimes} className="text-red-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                            </div>
                            <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                                Images with Line Annotations
                            </p>
                        </li>
                        <li className="mt-4 flex items-start">
                            <div className="flex-shrink-0">
                                <FontAwesomeIcon icon={faTimes} className="text-red-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                            </div>
                            <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                                Swing Tempo Data
                            </p>
                        </li>
                        <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                            <FontAwesomeIcon icon={faTimes} className="text-red-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Body Point Result Data
                        </p>
                        </li>
                        <li className="mt-4 flex items-start">
                            <div className="flex-shrink-0">
                                <FontAwesomeIcon icon={faTimes} className="text-red-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                            </div>
                            <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                                Swing Position Result Data
                            </p>
                        </li>
                        <li className="mt-4 flex items-start">
                            <div className="flex-shrink-0">
                                <FontAwesomeIcon icon={faTimes} className="text-red-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                            </div>
                            <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                                Custom Lesson Targets
                            </p>
                        </li>
                    </ul>
                    <div className="mt-8">
                        {isStandard === false && (
                            <div className="rounded-lg shadow-md">
                                <div onClick={() => this.handleSelectFeaturePackage('standard')} className="block w-full text-center rounded-lg border border-transparent bg-gray-700 px-6 py-3 text-base leading-6 font-medium text-white hover:bg-gray-800 focus:outline-none focus:shadow-outline transition ease-in-out duration-150 cursor-pointer" aria-describedby="tier-hobby">
                                    Select Package
                                </div>
                            </div>
                        )}
                        {isStandard === true && (
                            <div className="rounded-lg shadow-md">
                                <div onClick={() => this.handleSelectFeaturePackage('standard')} className="block w-full text-center rounded-lg border border-transparent bg-green-600 px-6 py-3 text-base leading-6 font-medium text-white hover:bg-green-800 focus:outline-none focus:shadow-outline transition ease-in-out duration-150 disabled" aria-describedby="tier-hobby">
                                    Selected
                                </div>
                            </div>
                        )}
                    </div>
                    </div>
                </div>

                <div 
                    className={`flex-1 flex flex-col rounded overflow-hidden shadow border-2 ${premiumBorder}`}
                    onClick={() => this.handleSelectFeaturePackage('premium')}
                >
                <div className="bg-white px-6 py-10">
                  <div>
                    <h3 className="text-center text-2xl leading-8 font-medium text-gray-900" id="tier-hobby">
                      Premium
                    </h3>
                    <div className="mt-4 flex items-center justify-center">
                      <span className="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900">
                        <span className="mt-2 mr-2 text-4xl font-medium">
                          $
                        </span>
                        <span className="font-extrabold">
                        {this.getPricePerVideoForPackage('premium', this.state.term)}
                        </span>
                      </span>
                      <span className="text-xl leading-7 font-medium text-gray-500">
                        /video
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                <ul>
                    <li className="flex items-start">
                        <div className="flex-shrink-0">
                            <FontAwesomeIcon icon={faCheck} className="text-green-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            {this.getLessonProgramsPerPlan('premium', videoPackage, term)} Lesson Programs
                        </p>
                    </li>
                    <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                            <FontAwesomeIcon icon={faCheck} className="text-green-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            {this.getTargetsPerPlan('premium', videoPackage, term)} Lesson Targets per Program
                        </p>
                    </li>
                    {/* <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                            <FontAwesomeIcon icon={faCheck} className="text-green-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Premium Analysis Results
                        </p>
                    </li> */}
                    <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                            <FontAwesomeIcon icon={faCheck} className="text-green-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Images with Line Annotations
                        </p>
                    </li>
                    <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                            <FontAwesomeIcon icon={faTimes} className="text-red-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Swing Tempo Data
                        </p>
                    </li>
                    <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                            <FontAwesomeIcon icon={faTimes} className="text-red-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Body Point Result Data
                        </p>
                    </li>
                    <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                            <FontAwesomeIcon icon={faTimes} className="text-red-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Swing Position Result Data
                        </p>
                    </li>
                    <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                            <FontAwesomeIcon icon={faTimes} className="text-red-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Custom Lesson Targets
                        </p>
                    </li>
                </ul>
                  <div className="mt-8">
                      {isPremium === false && (
                        <div className="rounded-lg shadow-md">
                            <div onClick={() => this.handleSelectFeaturePackage('premium')}  className="block w-full text-center rounded-lg border border-transparent bg-gray-700 px-6 py-3 text-base leading-6 font-medium text-white hover:bg-gray-800 focus:outline-none focus:shadow-outline transition ease-in-out duration-150 cursor-pointer" aria-describedby="tier-hobby">
                            Select Package
                            </div>
                        </div>
                      )}
                      {isPremium === true && (
                            <div className="rounded-lg shadow-md">
                                <div onClick={() => this.handleSelectFeaturePackage('premium')} className="block w-full text-center rounded-lg border border-transparent bg-green-600 px-6 py-3 text-base leading-6 font-medium text-white hover:bg-green-800 focus:outline-none focus:shadow-outline transition ease-in-out duration-150 disabled" aria-describedby="tier-hobby">
                                    Selected
                                </div>
                            </div>
                        )}
                  </div>
                </div>
                </div>
                
                <div 
                    className={`flex-1 flex flex-col rounded overflow-hidden shadow border-2 ${eliteBorder}`}
                    onClick={() => this.handleSelectFeaturePackage('elite')}
                >
                <div className="bg-white px-6 py-10">
                  <div>
                    <h3 className="text-center text-2xl leading-8 font-medium text-gray-900" id="tier-hobby">
                      Elite
                    </h3>
                    <div className="mt-4 flex items-center justify-center">
                      <span className="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900">
                        <span className="mt-2 mr-2 text-4xl font-medium">
                          $
                        </span>
                        <span className="font-extrabold">
                            {this.getPricePerVideoForPackage('elite', term)}
                        </span>
                      </span>
                      <span className="text-xl leading-7 font-medium text-gray-500">
                        /video
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                <ul>
                    <li className="flex items-start">
                    <div className="flex-shrink-0">
                        <FontAwesomeIcon icon={faCheck} className="text-green-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                    </div>
                    <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                        {this.getLessonProgramsPerPlan('elite', videoPackage, term)} Lesson Programs
                    </p>
                    </li>
                    <li className="mt-4 flex items-start">
                    <div className="flex-shrink-0">
                        <FontAwesomeIcon icon={faCheck} className="text-green-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                    </div>
                    <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                        {this.getTargetsPerPlan('elite', videoPackage, term)} Lesson Targets per Program
                    </p>
                    </li>
                    {/* <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                            <FontAwesomeIcon icon={faCheck} className="text-green-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Elite Analysis Results
                        </p>
                    </li> */}
                    <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                            <FontAwesomeIcon icon={faCheck} className="text-green-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Images with Line Annotations
                        </p>
                    </li>
                    <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                            <FontAwesomeIcon icon={faCheck} className="text-green-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Swing Tempo Data
                        </p>
                    </li>
                    <li className="mt-4 flex items-start">
                    <div className="flex-shrink-0">
                        <FontAwesomeIcon icon={faCheck} className="text-green-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                    </div>
                    <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                        Body Point Result Data
                    </p>
                    </li>
                    <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                            <FontAwesomeIcon icon={faCheck} className="text-green-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Swing Position Result Data
                        </p>
                    </li>
                    <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                            <FontAwesomeIcon icon={faCheck} className="text-green-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                        </div>
                        <p className="ml-3 text-base leading-6 font-medium text-gray-500">
                            Custom Lesson Targets
                        </p>
                    </li>
                </ul>
                  <div className="mt-8">
                      {isElite === false && (
                        <div className="rounded-lg shadow-md">
                            <div onClick={() => this.handleSelectFeaturePackage('elite')} className="block w-full text-center rounded-lg border border-transparent bg-gray-700 px-6 py-3 text-base leading-6 font-medium text-white hover:bg-gray-800 focus:outline-none focus:shadow-outline transition ease-in-out duration-150 cursor-pointer" aria-describedby="tier-hobby">
                            Select Package
                            </div>
                        </div>
                      )}
                      {isElite === true && (
                            <div className="rounded-lg shadow-md">
                                <div onClick={() => this.handleSelectFeaturePackage('elite')} className="block w-full text-center rounded-lg border border-transparent bg-green-600 px-6 py-3 text-base leading-6 font-medium text-white hover:bg-green-800 focus:outline-none focus:shadow-outline transition ease-in-out duration-150 disabled" aria-describedby="tier-hobby">
                                    Selected
                                </div>
                            </div>
                        )}
                  </div>
                </div>
                </div>
                
                </div>
            </div>
        ) : null;
    }

    renderPriceSheet = () => {
        const planData = this.getPlanDataPackage(this.state.featurePackage, this.state.term, this.state.videos);
        const price = this.getPricePerVideoForPackage(this.state.featurePackage, this.state.term);
        const termMultiplier = this.state.term === 'annually' ? 12 : 1;
        const totalPrice = this.getTotalPrice(this.state.featurePackage);
        return (
            <div>
                <div className="bg-green-600 px-6 py-10 mt-4 rounded shadow">
                  <div className="grid grid-cols-2 gap-8 p-8">
                    <div className="px-6 col-span-1">
                        {planData && (
                            <div>
                                <h1 
                                    className="text-3xl text-white font-bold"
                                >
                                    {planData.video_package_name} {planData.feature_package_name}
                                </h1>
                                <h2 
                                    className="text-2xl font-md text-gray-200"
                                >
                                    {planData.videos_per_month * termMultiplier} videos{this.state.term === 'annually' ? ' analyzed per year' : ' analyzed per month'}
                                </h2>
                            </div>
                        )}
                    </div>
                    {totalPrice !== null && totalPrice.number < 10000 && (
                        <div className="flex items-center justify-end px-6">
                        <span className="px-3 flex items-start text-6xl leading-none tracking-tight text-white">
                            <span className="mt-2 mr-2 text-4xl font-medium">
                            $
                            </span>
                            <span className="font-extrabold">
                                {planData.price_plan}
                            </span>
                        </span>
                        <span className="text-xl leading-7 font-medium text-gray-200">
                            {this.state.term === 'annually' ? '/yr' : '/mo'}
                        </span>
                        </div>
                    )}
                    {totalPrice !== null && totalPrice.number > 10000 && (
                        <div className="flex items-center justify-end px-6">
                        <span className="px-3 flex items-start text-6xl leading-none tracking-tight text-white flex-col">
                            <div className="my-2 mr-2">
                                <Button
                                    title="Contact for Pricing"
                                    bgColor="blue"
                                    hoverColor="blue"
                                    size="lg"
                                    onClick={this.handleContactUsPricing}
                                />
                            </div>
                        </span>
                        </div>
                    )}
                  </div>
                  <div className="px-6 py-4">
                    <ReactSlider
                        className="horizontal-slider"
                        thumbClassName="thumb-slider bg-gray-800 text-white font-bold text-xl text-center rounded-lg h-10 w-10 border-transparent outline-none"
                        trackClassName="example-track bg-gray-200 rounded-full"
                        renderThumb={(props, state) => <div {...props}>&nbsp;</div>}
                        max={this.state.maxVideos}
                        onChange={this.handleVideoChange}
                        defaultValue={this.state.videos}
                        value={this.state.videos}
                        step={this.state.term === 'annually' ? 200 : 100}
                        withBars
                    />
                  </div>
                  {planData && (
                      <div className="px-6 py-2">
                        <p className="text-gray-200 text-sm">This subscription will recur {this.state.term}. You may cancel anytime. All lessons per package must be used in the term of the subscription. Video processing credits may not be redeemed for cash value.  All videos will be processed at a price of <span className="font-bold">${price}</span> per video. This account will process up to <span className="font-bold">{planData.videos_per_month}</span> videos.</p>
                    </div>
                  )}
                </div>

                </div>
        );
    }

    render() {
        const {
            plans,
            getPlansLoading,
            subscriptionSuccess,
            subscriptionError,
        } = this.props;
        const showCheckout = true;
        return (
            <DashboardContent 
                backTitle="Back"
                title={'Choose a Plan'} 
            >
                <AccountRenewalBanner />
                {plans !== null && getPlansLoading === false && (
                    <Container className="bg-gray-50">
                        {subscriptionError === null && subscriptionSuccess === null && this.renderTermType()}
                        {subscriptionError === null && subscriptionSuccess === null && this.renderFeaturePackages()}
                        {subscriptionError === null && subscriptionSuccess === null && this.renderPriceSheet()}
                        {showCheckout && (
                            <div className="py-4">
                                <Button 
                                    title="Proceed to Checkout"
                                    bgColor="blue"
                                    hoverColor="blue"
                                    onClick={this.handleBuyButton}
                                    size="xl"
                                    block
                                />
                            </div>
                        )}
                    </Container>
                )}
                {getPlansLoading === true && <Loading />}
            </DashboardContent>
        );
    }
}

const mapStateToProps = ({ auth, plan, licensee, subscribe }) => {
    const { licenseeData } = licensee;
    const { authUser } = auth;
    const {
        plans,
        getPlansSuccess,
        getPlansError,
        getPlansLoading
    } = plan;
    const {
        clientToken,
        subscriptionSuccess,
        subscriptionLoading,
        subscriptionError,
    } = subscribe;
    return {
        authUser,
        plans,
        getPlansError,
        getPlansSuccess,
        getPlansLoading,
        licenseeData,
        clientToken,
        subscriptionSuccess,
        subscriptionLoading,
        subscriptionError,
    };
}

const mapDispatchToProps = {
    subscribeToPlan,
    subscribeToPlanReset,
    createBraintreeCustomerGetToken,
    getPlans
};



export default connect(mapStateToProps, mapDispatchToProps)(CartPage);