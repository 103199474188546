export const RESET_SLACK_WEBHOOK = 'slack/reset-webhook';

export const VERIFY_SLACK_CODE = 'slack/verify-code';
export const VERIFY_SLACK_CODE_SUCCESS = 'slack/verify-code-success';
export const VERIFY_SLACK_CODE_FAIL = 'slack/verify-code-fail';

export const CREATE_SLACK_WEBHOOK = 'slack/create-webhook';
export const CREATE_SLACK_WEBHOOK_SUCCESS = 'slack/create-webhook-success';
export const CREATE_SLACK_WEBHOOK_FAIL = 'slack/create-webhook-fail';

export const GET_SLACK_WEBHOOK = 'slack/get-webhook';
export const GET_SLACK_WEBHOOK_SUCCESS = 'slack/get-webhook-success';
export const GET_SLACK_WEBHOOK_FAIL = 'slack/get-webhook-fail';