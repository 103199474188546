import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DashboardContent } from '../tailwind';
import { getProgramsByLicenseeId } from '../../actions/program';

class ProgramsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            limit: 50,
            offset: 0,
            pageNum: 1
        }
    }

    componentDidMount = () => {
        const { authUser } = this.props;
        const { limit, offset } = this.state;
        this.props.getProgramsByLicenseeId(authUser.licensee_id, limit, offset);
    }

    handleClick = (programId) => {
        this.props.history.push({
            pathname: `/lessons/${programId}`,
            state: { programId }
        });
    }

    handleClickCreateTarget = (e) => {

    }
    
    /*
    date_created: "2015-05-05T23:35:58.000Z"
description: "This program will analyze the fundamentals of the golf swing using a face-on camera angle."
duration: "month"
licensee_id: 1
name: "Fundamentals Face On "
program_id: 9
status: "ACTIVE"
swing_type_id: 1
user_id: 1
*/
    renderRows = () => {
        const { programs } = this.props;
        return programs 
            ? programs.map(record => {
                // get the date...
                // const dateCreated = new Date(record.date_timestamp);
                return (
                    <tr key={record.user_id}>
                        <td className="px-6 py-4 border-b border-gray-200">
                            <div className="flex items-center">
                                <div className="ml-4">
                                    <div className="text-xl font-bold leading-5 font-medium text-gray-900 mb-1">
                                        <div onClick={() => this.handleClick(record.program_id)} className="text-xl text-gray-600 cursor-pointer">
                                            {record.name}
                                        </div>
                                        {/* <div className="text-xs leading-5 text-gray-500">{dateCreated.toString()}</div> */}
                                    </div>
                                    <div className="text-sm text-gray-400">{record.description}</div>
                                </div>
                            </div>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            {record.status.toLowerCase() === 'active' ? (
                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Active
                                </span>
                            ):(
                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                    Inactive
                                </span>
                            )}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            {record.swing_type_id === 1 ? (
                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                                    Face On
                                </span>
                            ):(
                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-purple-100 text-purple-800">
                                    Down the Line
                                </span>
                            )}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                            <button 
                                onClick={() => this.handleClick(record.program_id)} 
                                type="button" 
                                className="text-white inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-white bg-gray-400 hover:text-white focus:outline-none focus:shadow-outline-red focus:border-gray-300 active:text-white active:bg-gray transition duration-150 ease-in-out"
                            >
                                View Details
                            </button>
                            {/* <a  onClick={() => this.handleUserClick(record.user_id)} className="text-indigo-600 hover:text-indigo-900 cursor-pointer">View Details</a> */}
                        </td>
                    </tr>
            );
        }) : null;
    }

    render() {
        return (
            <DashboardContent title={'Lesson Programs'}>
                {/* begin table */}
                <div className="flex flex-col mt-4">
                <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                        <thead>
                        <tr>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Name
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Status
                            </th>
                            <th className="px-6 py-3 whitespace-no-wrap border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Camera Angle
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                        </tr>
                        </thead>
                        <tbody className="bg-white">
                            {this.renderRows()}
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>
                {/* end table */}
            </DashboardContent>
        )
    }
}
const mapStateToProps = ({ program, auth }) => {
    const { authUser } = auth;
    const { 
        programs,
        getProgramsLoading,
        getProgramsError,
    } = program;
    return {
        authUser,
        programs,
        getProgramsLoading,
        getProgramsError,
    };
}

const mapDispatchToProps = {
    getProgramsByLicenseeId
};

const programsPageWithRouter = withRouter(ProgramsPage);
export default connect(mapStateToProps, mapDispatchToProps)(programsPageWithRouter);