import React from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
class TargetConditionSteps extends React.Component {

    renderLastStep = (number, title) => {
        return (
            <li key={`steps-${number}`} className="flex md:flex-1 md:flex border-r border-white whitespace-nowrap">
                <div className="group flex items-center bg-gray-300">
                    <div className="px-4 py-4 flex items-center text-sm leading-5 font-medium space-x-4 whitespace-nowrap">
                        <div className="flex-shrink-0 w-6 h-6 flex items-center justify-center border-2 border-gray-400 rounded-full group-hover:border-gray-400 transition ease-in-out duration-150 whitespace-nowrap">
                            <span className="text-gray-600 group-hover:text-gray-900 transition ease-in-out duration-150">{number}</span>
                        </div>
                        <p className="text-sm leading-5 font-medium text-gray-800 group-hover:text-gray-900 transition ease-in-out duration-150">{title}</p>
                    </div>
                </div>
            </li>
        )
    }

    renderFutureStep = (number, title) => {
        return (
            <li key={`steps-${number}`} className="flex md:flex-1 md:flex border-r border-red">
                <div className="group flex items-center bg-gray-300">
                    <div className="px-4 py-2 flex items-center text-sm leading-5 font-medium space-x-4">
                        <div className="flex-shrink-0 w-6 h-6 flex items-center justify-center border-2 border-gray-400 rounded-full group-hover:border-gray-400 transition ease-in-out duration-150">
                            <span className="text-gray-600 group-hover:text-gray-600 transition ease-in-out duration-150 text-xs">{number}</span>
                        </div>
                        <p className="text-sm leading-5 font-medium text-gray-600 group-hover:text-gray-900 transition ease-in-out duration-150">{title}</p>
                    </div>
                </div>
            </li>
        )
    }

    renderSelectedStep = (number, title) => {
        return (
            <li key={`steps-${number}`} className="flex md:flex-1 md:flex border-r border-white">
                <div className="group flex items-center w-full bg-gray-700">
                    <div className="px-6 py-4 flex items-center text-sm leading-5 font-medium space-x-4">
                        <div className="flex-shrink-0 w-6 h-6 flex items-center justify-center  rounded-full bg-gray-600 border border-gray-200">
                            <p className="text-white text-xs">{number}</p>
                        </div>
                        <p className="text-sm leading-5 font-medium text-white">{title}</p>
                    </div>
                </div>
            </li>
        );
    }

    renderCompletedStep = (number, title) => {
        return (
            <li key={`steps-${number}`} className="flex md:flex-1 md:flex border-r border-white">
            <div className="group flex items-center w-full bg-gray-500">
                <div className="px-6 py-4 flex items-center text-sm leading-5 font-medium space-x-4">
                    <div className="flex-shrink-0 w-6 h-6 flex items-center justify-center bg-gray-600 rounded-full group-hover:bg-indigo-800 transition ease-in-out duration-150 text-xs text-white">
                        <FontAwesomeIcon icon={faCheck} />
                    </div>
                    <p className="text-sm leading-5 font-medium text-white">{title}</p>
                </div>
            </div>
        </li>
        );
    }

    render() {
        const { steps, currentStep } = this.props;
        return steps !== null ? (
            <nav className="flex-1">
                <ul className="flex-1 w-full border border-white rounded-md divide-y divide-white md:flex md:divide-y-0 overflow-hidden bg-gray-300 justify-between">
                    {steps.map(step => {
                        let stepContent = null;
                        if (step.number < currentStep) {
                            stepContent = this.renderCompletedStep(step.number, step.title);
                        } else if (step.number === currentStep) {
                            stepContent = this.renderSelectedStep(step.number, step.title);
                        } else if (step.number === steps.length) {
                            stepContent = this.renderLastStep(step.number, step.title);
                        } else {
                            stepContent = this.renderFutureStep(step.number, step.title);
                        }
                        return stepContent;
                    })}
                </ul>
            </nav>
        ) : null;
    }
}

TargetConditionSteps.defaultProps = {
    currentStep: 1,
    steps: [
        {
            type: 'completed',
            number: 1,
            title: 'Condition A',
        },
        {
            type: 'current',
            number: 2,
            title: 'Condition B',
        },
        {
            type: 'current',
            number: 3,
            title: 'Compare',
        },
        {
            type: 'future',
            number: 4,
            title: 'Summary',
        },
        {
            type: 'future',
            number: 5,
            title: 'Lines',
        },
        {
            type: 'future',
            number: 6,
            title: 'Preview',
        }
    ],
    onChangeStep(){},
}

export default TargetConditionSteps;