import React from 'react';
import { Container } from '../../tailwind';
import TargetBodyPoints from './TargetBodyPoints';

class TargetBodyPointsContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedBodyPointTab: 1,
        }
    }

    handleConfirmBodyPoints = (data) => {
        this.props.onConfirmBodyPoints(data);
    }

    renderBodyPointTabs = () => {
        const { selectedBodyPointTab } = this.state;
        return (
            <div className="w-full p-0 mt-4 rounded overflow-hidden">
                <div className="sm:hidden">
                    <select 
                        aria-label="Selected tab" 
                        className="form-select block w-full"
                        value={selectedBodyPointTab === 1 ? 'A':'B'}
                    >
                        <option>A</option>
                        <option>B</option>
                    </select>
                </div>
                <div className="hidden sm:block w-full">
                    <nav className="-mb-px flex flex-1 w-full p-2 bg-white rounded border shadow">
                        <div 
                            className={`flex flex-1 justify-center py-4 px-1 text-center text-2xl font-bold leading-5 text-white rounded-tl rounded ${selectedBodyPointTab === 1 ? 'bg-gray-400' : 'bg-gray-50 cursor-pointer hover:bg-gray-400 text-gray-400'} mr-2`} 
                            aria-current="page"
                            onClick={() => this.setState({ selectedBodyPointTab: 1 })}
                        >
                        A
                        </div>
                        <div 
                            className={`flex flex-1 justify-center py-4 px-1 text-center text-2xl font-bold leading-5 text-white rounded ${selectedBodyPointTab === 2 ? 'bg-gray-400' : 'bg-gray-200 cursor-pointer hover:bg-gray-400'}`}
                            onClick={() => this.setState({ selectedBodyPointTab: 2 })}
                        >
                        B
                        </div>
                    </nav>
                </div>
                </div>
        );
    }

    render() {
        const { 
            code, 
            isEditable, 
            readOnly, 
            stateData, 
            targetInterpretationData,
            analysisResults,
            number
        } = this.props;
        return code !== null ? (
            <div className="flex flex-col mb-4">
                <div className="py-2 px-2">
                    <h1>Assessment {this.props.number}</h1>
                </div>
                {number === 1 && (
                    <div className={`w-full h-full`}>
                            <Container className="border border-gray-300 bg-gray-200 h-full">
                                <TargetBodyPoints 
                                    readOnly={readOnly}
                                    isEditable={isEditable}
                                    run={new Date().getTime()}
                                    show={true}
                                    number={number}
                                    calculationType={stateData.calculationConditionType1}
                                    constantValue={stateData.constantValue1}
                                    onCalculationChange={this.props.onCalculationTypeChange}
                                    onConstantChange={this.props.onConstantChange}
                                    bodyPoints={stateData.bodyPoints1} // contains ALL points based on calculation1
                                    positions={stateData.position1} // contains ALL positions based on calculation1
                                    positionSelected={stateData.position1}
                                    targetInterpretationData={targetInterpretationData} 
                                    onConfirm={this.handleConfirmBodyPoints}
                                    analysisResults={analysisResults}
                                    suggestedBodyPoints={stateData.bodyPoints1}
                                />
                            </Container>
                    </div>
                )}
                {number === 2 && (
                    <div className={`w-full h-full`}> 
                        <Container className="border border-gray-300 bg-gray-200 h-full">
                            <TargetBodyPoints
                                readOnly={readOnly}
                                isEditable={isEditable}
                                run={new Date().getTime()}
                                show={true}
                                number={2}
                                calculationType={stateData.calculationConditionType2}
                                constantValue={stateData.constantValue2}
                                onCalculationChange={this.onCalculationTypeChange}
                                onConstantChange={this.props.onConstantChange}
                                bodyPoints={stateData.bodyPoints2}
                                positionSelected={stateData.position2}
                                positions={stateData.position2}
                                targetInterpretationData={targetInterpretationData} 
                                onConfirm={this.props.onConfirmBodyPoints}
                                analysisResults={analysisResults}
                                suggestedBodyPoints={stateData.bodyPoints2}
                            />
                        </Container>
                    </div>
                )}
            </div>
        ) : null;
    }
}

TargetBodyPointsContainer.defaultProps = {
    number: 1,
    analysisResults: null,
    code: null,
    stateData: null,
    readOnly: true,
    isEditable: false,
    targetInterpretationData: null,
    onConstantChange(){},
    onConfirmBodyPoints(){},
    onCalculationTypeChange(){}
}

export default TargetBodyPointsContainer;