export const GET_LICENSEE_CAMPAIGNS = 'licensee/get-licensee-campaigns';
export const GET_LICENSEE_CAMPAIGNS_SUCCESS = 'licensee/get-licensee-campaigns-success';
export const GET_LICENSEE_CAMPAIGNS_FAIL = 'licensee/get-licensee-campaigns-fail';

export const CREATE_LICENSEE_CAMPAIGN = 'licensee/create-licensee-campaign';
export const CREATE_LICENSEE_CAMPAIGN_SUCCESS = 'licensee/create-licensee-campaign-success';
export const CREATE_LICENSEE_CAMPAIGN_FAIL = 'licensee/create-licensee-campaign-fail';
export const CREATE_LICENSEE_CAMPAIGN_RESET = 'licensee/create-licensee-campaign-reset';

export const DUPLICATE_LICENSEE_CAMPAIGN = 'licensee-campaign/duplicate';
export const DUPLICATE_LICENSEE_CAMPAIGN_SUCCESS = 'licensee-campaign/duplicate-success';
export const DUPLICATE_LICENSEE_CAMPAIGN_FAIL = 'licensee-campaign/duplicate-fail';
export const DUPLICATE_LICENSEE_CAMPAIGN_RESET = 'licensee-campaign/duplicate-reset';

export const UPDATE_LICENSEE_CAMPAIGN = 'licensee/update-licensee-campaign';
export const UPDATE_LICENSEE_CAMPAIGN_SUCCESS = 'licensee/update-licensee-campaign-success';
export const UPDATE_LICENSEE_CAMPAIGN_FAIL = 'licensee/update-licensee-campaign-fail';
export const UPDATE_LICENSEE_CAMPAIGN_RESET = 'licensee/update-licensee-campaign-reset';

export const UPDATE_LICENSEE_CAMPAIGN_STATUS = 'licensee-campaign/update-status';
export const UPDATE_LICENSEE_CAMPAIGN_STATUS_SUCCESS = 'licensee/update-status-success';
export const UPDATE_LICENSEE_CAMPAIGN_STATUS_FAIL = 'licensee/update-status-fail';
export const UPDATE_LICENSEE_CAMPAIGN_STATUS_RESET = 'licensee/update-status-reset';

export const SEND_TO_WEBHOOK = 'licensee-campaign/send-to-webhook';
export const SEND_TO_WEBHOOK_SUCCESS = 'licensee/send-to-webhook-success';
export const SEND_TO_WEBHOOK_FAIL = 'licensee/send-to-webhook-fail';
export const SEND_TO_WEBHOOK_RESET = 'licensee/send-to-webhook-reset';