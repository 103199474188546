import React from 'react';
import Button from '../common/Button';
class CameraAngleNav extends React.Component {
    render() {
        const { 
            cameraAngle, 
            targetLibrary,
            filter,
            onChangeCameraAngle,
            onChangeTargetLibrary,
            onChangeFilter,
            onCreateNewTarget,
            showSwingFilters,
            status
        } = this.props;
        console.log('status: ', status);
        return (
            <div className="py-4 w-full">
                <div className="sm:hidden">
                    {cameraAngle && (
                        <div>
                            <select 
                                onChange={onChangeCameraAngle} 
                                aria-label="Selected tab" 
                                className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-blue-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150"
                                value={cameraAngle}
                            >
                                <option value={1}>Face On</option>
                                <option value={2}>Down the Line</option>
                            </select>
                        </div>
                    )}
                    <div className="mr-2 sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start sm:pt-5">
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="max-w-lg rounded-md shadow-sm sm:max-w-sm">
                            <input 
                                id="search-mobile" 
                                type="text"
                                className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 mr-4" 
                                onChange={onChangeFilter}
                                placeholder="Search"
                                value={filter}
                            />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sm:block flex flex-row w-full justify-between">
                    <div className="flex justify-between sm:flex-col">
                    <nav className="flex flex-row w-full">
                        <div className="w-full flex justify-between ">
                        <div className="flex flex-row">
                        <div className="mr-2">
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <div className="max-w-lg rounded-md shadow-sm sm:max-w-sm">
                                <input 
                                    id="search" 
                                    type="text"
                                    className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 mr-4" 
                                    onChange={onChangeFilter}
                                    placeholder="Search"
                                    value={filter}
                                />
                                </div>
                            </div>
                        </div>
                        {showSwingFilters === true && (
                            <div className="mr-2">
                                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                                    <button 
                                        type="button" 
                                        className={`relative inline-flex items-center px-4 py-2 rounded-l-md border border-blue-600 text-sm leading-5 font-medium focus:z-10 focus:outline-none active:bg-blue-100 active:text-blue-300 transition ease-in-out duration-150 ${cameraAngle === 1 ? "bg-gray-600 text-white" : "bg-white text-gray-700 hover:text-gray-500"}`}
                                        onClick={onChangeCameraAngle}
                                    >
                                        Face On
                                    </button>
                                    <button 
                                        type="button" 
                                        className={`-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-600 text-sm leading-5 font-medium focus:z-10 focus:outline-none active:bg-gray-100 active:text-gray-600 transition ease-in-out duration-150 ${cameraAngle === 2 ? "bg-gray-600 text-white" : "bg-white text-gray-700 hover:text-gray-500"}`}
                                        onClick={onChangeCameraAngle}
                                    >
                                        Down the Line
                                    </button>
                                </span>
                            </div>
                        )}
                        {targetLibrary !== null && (
                            <div className="">
                            <span className="relative z-0 inline-flex shadow-sm rounded-md">
                                <button 
                                    type="button" 
                                    className={`relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium focus:z-10 focus:outline-none focus:border-gray-300 focus:shadow-outline-gray active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150 ${targetLibrary === 1 ? "bg-gray-600 text-white" : "bg-white text-gray-700 hover:text-gray-500"}`}
                                    onClick={onChangeTargetLibrary}
                                >
                                    My Targets
                                </button>
                                <button 
                                    type="button" 
                                    className={`-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium focus:z-10 focus:outline-none focus:border-gray-300 focus:shadow-outline-gray active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150 ${targetLibrary === 2 ? "bg-gray-600 text-white" : "bg-white text-gray-700 hover:text-gray-500"}`}
                                    onClick={onChangeTargetLibrary}
                                >
                                    Swingbot Targets
                                </button>
                            </span>
                            </div>
                        )}
                        </div>
                        
                        {onCreateNewTarget !== null && status === 'INACTIVE' && (
                            <div className="flex">
                                <div className="mt-4 flex md:mt-0">
                                    <span className="shadow-sm rounded-md">
                                        <Button
                                            bgColor="green"
                                            hoverColor="green"
                                            onClick={onCreateNewTarget}
                                            title={cameraAngle === 1 ? 'Add Face On Lesson Target' : 'Add Down the Line Lesson Target'}
                                            iconLeft="new"
                                        />
                                    </span>
                                </div>
                            </div>
                        )}
                        </div>
                    </nav>
                    </div>
                </div>
            </div>
        );
    }
}

CameraAngleNav.defaultProps = {
    cameraAngle: null,
    targetLibrary: null,
    filter: '',
    showSwingFilters: true,
    onChangeTargetLibrary(){},
    onChangeCameraAngle(){},
    onChangeFilter(){},
    onCreateNewTarget: null
}

export default CameraAngleNav;