import React from 'react';
import { hyphenate } from '../../../util/strings';

class DocumentationTOC extends React.Component {
  render() {
    return (
      <div className="flex flex-col">
        <h4 className="text-gray-400">{this.props.intro}</h4>
        {this.props.items.map(item => {
          const link = item.link !== undefined  
            ? item.link
            : `#${hyphenate(item.title)}`
          return (
            <div className="text-green-500 border-b p-2" key={item.title}>
              <a className="text-blue-600 no-underline" href={link}>{item.title}</a>
            </div>
          )
        })}
      </div>
    );
  }
}

DocumentationTOC.defaultProps = {
  intro: 'What is Covered on this page:'
}

export default DocumentationTOC;