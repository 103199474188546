import React from 'react';
import { connect } from 'react-redux';
import StepWizard from 'react-step-wizard';
import WizardTextInput from '../WizardTextInput';
import WizardYesNo from '../WizardYesNo';
import WizardComplete from '../WizardComplete';
import WizardOptions from '../WizardOptions';
import { applyToSwingbot, applyToSwingbotReset } from '../../../../actions/user';

import './animate.custom.css';

class QualifyWizard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {}
        }
    }

    componentDidMount = () => {
        this.props.applyToSwingbotReset();
    }

    handleChange = (data) => {
        const stateData = this.state.data;
        stateData[data.name] = data.value;
        this.setState({
            data: stateData,
        });
    }

    handleSubmit = () => {
        const stateData = this.state.data;
        const email = stateData.email;
        const websiteAddress = stateData.website;
        const paymentCollectionMethod = stateData.collect_payment;
        const isGolfInstructor = stateData.golf_instructor;
        const instagramHandle = stateData.instagram || "";
        const twitterHandle = stateData.twitter || ""; 
        const facebookPage = stateData.facebook || "";
        const firstName = stateData.first_name || "";
        const lastName = stateData.last_name || "";
        // submit this data
        this.props.applyToSwingbot(
            email, 
            firstName,
            lastName,
            websiteAddress, 
            paymentCollectionMethod, 
            isGolfInstructor, 
            instagramHandle, 
            twitterHandle, 
            facebookPage
        );
    }

    render() {
        let custom = {
            enterRight: `animated fadeInRight`,
            enterLeft: `animated fadeInLeft`,
            exitRight: `animated fadeOutRight`,
            exitLeft: `animated fadeOutLeft`,
        };
        const firstName = ('first_name' in this.state.data) ? this.state.data.first_name : null;
        const {
            applyToSwingbotComplete,
            applyToSwingbotLoading,
            applyToSwingbotError
        } = this.props;
        return (
            <div className="flex w-full justify-center items-centera overflow-hidden">
                <StepWizard 
                    transitions={custom} 
                    className="flex-1 justify-center items-center relative bg-white overflow-hidden rounded-lg"
                >
                    <WizardYesNo
                        step={1}
                        name="golf_instructor"
                        text="If you are actively teaching students please click yes."
                        title="Are you a golf instructor?"
                        onChange={(data) => this.handleChange(data) }
                    />
                    <WizardTextInput
                        step={2}
                        name="first_name"
                        title="What is your first name?"
                        placeholder="Type your first name here"
                        onChange={(data) => this.handleChange(data) }
                    />
                    <WizardTextInput
                        step={3}
                        name="last_name"
                        title={`Hi ${firstName}, what is your last name?`}
                        placeholder="Type your last name here"
                        onChange={(data) => this.handleChange(data) }
                    />
                    <WizardTextInput
                        step={4}
                        type="email"
                        name="email"
                        title="What is your email address?"
                        text="Let us know how to contact you."
                        placeholder="Type your email here"
                        onChange={(data) => this.handleChange(data) }
                    />
                    <WizardTextInput
                        step={5}
                        name="website"
                        text="Type your full website address below."
                        title="What is your website address?"
                        placeholder="Type your web address here"
                        onChange={(data) => this.handleChange(data) }
                        optional={true}
                    />
                    <WizardOptions
                        step={6}
                        name="collect_payment"
                        text="Do you have a way to collect payments?"
                        title="Please select the method of payment you use from the list."
                        onChange={(data) => this.handleChange(data) }
                        options={[
                            {
                                name: 'Braintree',
                                value: 'braintree'
                            },
                            {
                                name: 'Paypal',
                                value: 'paypal'
                            },
                            {
                                name: 'Apple Pay',
                                value: 'apple'
                            },
                            {
                                name: 'Other',
                                value: 'other'
                            }
                        ]}
                    />
                    <WizardComplete
                        step={7}
                        name="submit"
                        text="Please click finish to submit your application to the Swingbot Pro program."
                        title="Final Step"
                        onSubmit={this.handleSubmit}
                        loading={applyToSwingbotLoading}
                        complete={applyToSwingbotComplete}
                        error={applyToSwingbotError}
                    />
                </StepWizard>
            </div>
        );
    }
}


QualifyWizard.defaultProps = {
    onSubmit(){}
}

const mapStateToProps = ({ user }) => {
    const {
        applyToSwingbotComplete,
        applyToSwingbotLoading,
        applyToSwingbotError
    } = user;
    return {
        applyToSwingbotComplete,
        applyToSwingbotLoading,
        applyToSwingbotError
    };
}

const mapDispatchForProps = {
    applyToSwingbot,
    applyToSwingbotReset,
}

export default connect(mapStateToProps, mapDispatchForProps)(QualifyWizard);