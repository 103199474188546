import React from 'react';
import { Link } from 'react-router-dom';

class HeaderContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        }
    }

    toggleMenu = () => {
        const isOpen = this.state.isOpen;
        this.setState({
            isOpen: !isOpen,
        })
    }
    render() {
        return (
            <div className="relative bg-gray-50 overflow-hidden">
            <div className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full">
              <div className="relative h-full max-w-screen-xl mx-auto">
                <svg className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2" width="404" height="784" fill="none" viewBox="0 0 404 784">
                  <defs>
                    <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                      <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width="404" height="784" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                </svg>
                <svg className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2" width="404" height="784" fill="none" viewBox="0 0 404 784">
                  <defs>
                    <pattern id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                      <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width="404" height="784" fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)" />
                </svg>
              </div>
            </div>
          
            <div className="relative pt-6">
              <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
                <nav className="relative flex items-center justify-between sm:h-10 md:justify-center">
                  <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                    <div className="flex items-center justify-between w-full md:w-auto">
                      <a href="/" aria-label="Home">
                        <img className="h-8 w-auto sm:h-10" src={require('../../../img/logo.png')} alt="SwingbotPro" />
                      </a>
                      <div className="-mr-2 flex items-center md:hidden">
                        <button onClick={this.toggleMenu} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" id="main-menu" aria-label="Main menu" aria-haspopup="true">
                          <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:flex md:space-x-10">
                    <a href="/#about" className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">About</a>
                    <a href="/#features" className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">Features</a>
                    <a href="/#results" className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">Analysis Results</a>
                    <a href="/#faq" className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">FAQ</a>
                    <a href="/#get-started" className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">Get Started</a>
                    <a href="/doc" className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">Documentation</a>
                  </div>
                  <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
                    <span className="inline-flex rounded-md shadow">
                      <Link to="/login" className="inline-flex items-center px-4 py-2 text-base leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none transition duration-150 ease-in-out">
                        Login
                      </Link>
                    </span>
                  </div>
                </nav>
              </div>
          
              {/* <!--
                Mobile menu, show/hide based on menu open state.
          
                Entering: "duration-150 ease-out"
                  From: "opacity-0 scale-95"
                  To: "opacity-100 scale-100"
                Leaving: "duration-100 ease-in"
                  From: "opacity-100 scale-100"
                  To: "opacity-0 scale-95"
              --> */}
              <div className={`absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden ${this.state.isOpen === false ? 'hidden' : null}`}>
                <div className="rounded-lg shadow-md">
                  <div className="rounded-lg bg-white shadow-xs overflow-hidden" role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
                    <div className="px-5 pt-4 flex items-center justify-between">
                      <div>
                        <img className="h-8 w-auto" src={require('../../../img/logo.png')} alt="SwingbotPro" />
                      </div>
                      <div className="-mr-2">
                        <button onClick={this.toggleMenu} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" aria-label="Close menu">
                          <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="px-2 pt-2 pb-3">
                      <a href="#about" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">About</a>
                      <a href="#features" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Features</a>
                      <a href="#results" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Analysis Results</a>
                      <a href="#faq" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">FAQ</a>
                      <a href="#get-started" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Get Started</a>
                      <a href="/doc" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Documentation</a>
                    </div>
                    <div>
                      <a href="/login" className="block w-full px-5 py-3 text-center font-medium text-white bg-green-600 hover:bg-green-800 hover:text-white focus:outline-none focus:bg-green-600 focus:text-white transition duration-150 ease-in-out" role="menuitem">
                        Log in
                      </a>
                    </div>
                  </div>
                </div>
              </div>
                {this.props.children}
            </div>
            </div>
        )
    }
}

export default HeaderContainer;