import React from 'react';
import { connect } from 'react-redux';
import { Container } from '../../tailwind';
import TargetBodyPointsContainer from './TargetBodyPointsContainer';
import TargetEquationOperand from './TargetEquationOperand';
import TargetSummary from './TargetSummary';
import { TargetLinesContainer } from './lines';
import Button from '../Button';
import { removeTargetInterpretationFromTarget } from '../../../actions/targetInterpretation';
const steps = [
    {
        type: 'completed',
        number: 1,
        title: 'Condition A',
    },
    {
        type: 'current',
        number: 2,
        title: 'Condition B',
    },
    {
        type: 'future',
        number: 3,
        title: 'Compare',
    },
    {
        type: 'future',
        number: 4,
        title: 'Summary',
    },
    {
        type: 'future',
        number: 5,
        title: 'Lines',
    },
    {
        type: 'future',
        number: 6,
        title: 'Preview',
    }
];

class TargetConditionsContainerList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            conditionDropDownOpen: false,
            step: 1,
        }
    }

    toggleDropDown = () => {
        this.setState({
            conditionDropDownOpen: !this.state.conditionDropDownOpen
        });
    }

    handleChangeStep = (step) => {
        this.setState({
            step: step
        });
    }

    handleConditionCreate = (name) => {
        this.toggleDropDown();
        this.props.onConditionCreate(name);
    }

    handleRemoveCondition = (targetSummaryId) => {
        const { authUser } = this.props;
        this.props.removeTargetInterpretationFromTarget(
            authUser.user_id, 
            targetSummaryId, 
            this.props.history,
            0 // history page
        );
    }

    handlePreviousStep = () => {
        const step = this.state.step;
        if (step - 1 > 0) {
            this.setState({
                step: step - 1
            })
        }
    }

    handleNextStep = (stateData) => {
        const step = this.state.step;
        const {
            constantValue1,
            constantValue2,
            calculationConditionType1,
            calculationConditionType2,
            position1,
            position2,
            bodyPoints1,
            bodyPoints2,
            operand,
            summary,
            lines
        } = stateData;

        // we should check here to make sure that the values are selected!
        if (step === 1) {
            // we have to check for either the constant or the calculation
            if ((constantValue1 === undefined || constantValue1 === null) && calculationConditionType1 === null) return;
            if ((constantValue1 === undefined || constantValue1 === null) && calculationConditionType1 !== null && (position1 === null || bodyPoints1 === null)) return;
            if (calculationConditionType1 !== null) {
                if (calculationConditionType1.points === 2) {
                    if ((constantValue1 === undefined || constantValue1 === null) && calculationConditionType1 !== null && (position1[2] === undefined || bodyPoints1[2] === undefined)) return;
                }
            }
        }

        if (step === 2) {
            // we have to check for either the constant or the calculation
            if ((constantValue2 === undefined || constantValue2 === null) && calculationConditionType2 === null) return;
            if ((constantValue2 === undefined || constantValue2 === null) && calculationConditionType2 !== null && (position2 === null || bodyPoints2 === null)) return;
            if (calculationConditionType2 !== null) {
                if (calculationConditionType2.points === 2) {
                    if ((constantValue2 === undefined || constantValue2 === null) && calculationConditionType2 !== null && (position2[2] === undefined || bodyPoints2[2] === undefined)) return;
                }
            }

        }

        if (step === 3 && operand === null) return;
        if (step === 4 && summary === "") return;

        // Line check, must have lines!
        if (step === 5 && Object.keys(lines).length === 0) {
            return;
        }

        if (step < steps.length) {
            this.setState({
                step: step + 1
            })
        }
    }

    getTargetSummaryId = () => {
        const { targetInterpretationData, code } = this.props;
        if (targetInterpretationData !== null && targetInterpretationData !== undefined) {
            if ('equations' in targetInterpretationData) {
                const equation = targetInterpretationData.equations[code];
                if (equation !== undefined) {
                    return equation[0].target_summary_id;
                }
            }
        }
        return null;
    }

    render() {
        const { 
            code,
            isEditable, 
            readOnly, 
            stateData, 
            targetInterpretationData,
            analysisResults,
            onConfirmBodyPoints,
            onConstantChange,
            onCalculationTypeChange,
            onLineChange,
            onClickSaveAndContinue,
            onConfirmEquation,
            // createTargetInterpretationLoading,
            // createTargetInterpretationSuccess,
            // createTargetInterpretationError
        } = this.props;
        // const targetSummaryId = this.getTargetSummaryId();
        // const { step } = this.state;

        // const stepCheck = {
        //     1: true,
        //     2: true,
        //     3: (stateData.bodyPoints2 !== null || stateData.constantValue2 !== null) && stateData.bodyPoints1 !== null && stateData.position1 !== null && (stateData.position2 !== null || stateData.constantValue2 !== null) && this.props.analyzeVideoSuccess !== null,
        //     4: stateData.operand !== null && stateData.operand !== undefined,
        //     5: stateData.summary !== ''
        // };
        return readOnly === false ? (
            <Container className="w-full h-full flex-1">
                <div className="flex flex-col min-h-full justify-between">
                    <div className="flex-1">
                        <TargetBodyPointsContainer 
                            code={code}
                            number={1}
                            isEditable={isEditable}
                            readOnly={readOnly}
                            stateData={stateData}
                            targetInterpretationData={targetInterpretationData}
                            analysisResults={analysisResults}
                            onConstantChange={onConstantChange}
                            onConfirmBodyPoints={onConfirmBodyPoints}
                            onCalculationTypeChange={onCalculationTypeChange}
                        />
                        <TargetBodyPointsContainer 
                            code={code}
                            number={2}
                            isEditable={isEditable}
                            readOnly={readOnly}
                            stateData={stateData}
                            targetInterpretationData={targetInterpretationData}
                            analysisResults={analysisResults}
                            onConstantChange={onConstantChange}
                            onConfirmBodyPoints={onConfirmBodyPoints}
                            onCalculationTypeChange={onCalculationTypeChange}
                        />
                        <TargetEquationOperand 
                            isEditable={isEditable}
                            readOnly={readOnly}
                            run={new Date().getTime()} // need to do to update!
                            code={code}
                            calculationType={stateData.calculationConditionType}
                            calculationType1={stateData.calculationConditionType1}
                            calculationType2={stateData.calculationConditionType2}
                            constantValue1={stateData.constantValue1}
                            constantValue2={stateData.constantValue2}
                            position1={stateData.position1}
                            position2={stateData.position2}
                            bodyPoints1={stateData.bodyPoints1 || null}
                            bodyPoints2={stateData.bodyPoints2 || null}
                            constant1={stateData.constantValue1}
                            constant2={stateData.constantValue2}
                            show={(stateData.bodyPoints2 !== null || stateData.constantValue2 !== null) && stateData.bodyPoints1 !== null && stateData.position1 !== null && (stateData.position2 !== null || stateData.constantValue2 !== null) && this.props.analyzeVideoSuccess !== null}
                            operand={stateData.operand}
                            onClick={onConfirmEquation}
                            analysisResults={analysisResults}
                        />
                        <TargetSummary 
                            readOnly={readOnly}
                            show={stateData.operand !== null && stateData.operand !== undefined}
                            targetInterpretationData={targetInterpretationData} 
                            analysisResults={this.props.analyzeVideoSuccess}
                            onChange={this.props.onSummaryChange}
                            summary={stateData.summary}
                        />
                        <TargetLinesContainer
                            stateData={stateData}
                            run={new Date().getTime()} // need to do to update!
                            readOnly={readOnly}
                            show={true}
                            lines={stateData.lines}
                            calculationType={stateData.calculationConditionType}
                            calculationType1={stateData.calculationConditionType1}
                            calculationType2={stateData.calculationConditionType2}
                            targetInterpretationData={targetInterpretationData} 
                            analysisResults={analysisResults}
                            position1={stateData.position1}
                            position2={stateData.position2}
                            bodyPoints1={stateData.bodyPoints1 || null}
                            bodyPoints2={stateData.bodyPoints2 || null}
                            onChange={onLineChange}
                            originPosition={stateData.position1 !== null ? stateData.position1[1].name : null}
                        />
                    {/* {step === 6 && ( */}
                        {/* <TargetPreview
                            readOnly={readOnly}
                            show={true}
                            targetInterpretationData={targetInterpretationData}
                            analysisResults={analysisResults}
                            isEditable={isEditable}
                            run={new Date().getTime()} // need to do to update!
                            code={code}
                            calculationType={stateData.calculationConditionType}
                            calculationType1={stateData.calculationConditionType1}
                            calculationType2={stateData.calculationConditionType2}
                            constantValue1={stateData.constantValue1}
                            constantValue2={stateData.constantValue2}
                            position1={stateData.position1}
                            position2={stateData.position2}
                            bodyPoints1={stateData.bodyPoints1 || null}
                            bodyPoints2={stateData.bodyPoints2 || null}
                            constant1={stateData.constantValue1}
                            constant2={stateData.constantValue2}
                            operand={stateData.operand}
                            onClick={onConfirmEquation}
                            summary={stateData.summary}
                        /> */}
                    {/* )} */}
                    </div>
                    <div className="flex flex-row py-4 w-full space-x-2 align-bottom">
                        <Button 
                            title={`Save this Condition`}
                            size="lg"
                            bgColor="green"
                            hoverColor="green"
                            onClick={onClickSaveAndContinue} 
                            block={true}
                            disabled={!(
                                stateData.lines !== undefined && 
                                stateData.lines !== {} && 
                                stateData.lines !== null && 
                                stateData.summary !== '' &&
                                stateData.position1 !== null && 
                                stateData.bodyPoints2 !== null
                            )}
                        />
                    </div>
                </div>
            </Container>
        ) : (
            <Container>
                <Container>
                    <TargetEquationOperand 
                        isEditable={isEditable}
                        readOnly={readOnly}
                        run={new Date().getTime()} // need to do to update!
                        code={code}
                        calculationType={stateData.calculationConditionType}
                        calculationType1={stateData.calculationConditionType1}
                        calculationType2={stateData.calculationConditionType2}
                        constantValue1={stateData.constantValue1}
                        constantValue2={stateData.constantValue2}
                        position1={stateData.position1}
                        position2={stateData.position2}
                        bodyPoints1={stateData.bodyPoints1 || null}
                        bodyPoints2={stateData.bodyPoints2 || null}
                        constant1={stateData.constantValue1}
                        constant2={stateData.constantValue2}
                        show={(stateData.bodyPoints2 !== null || stateData.constantValue2 !== null) && stateData.bodyPoints1 !== null && stateData.position1 !== null && (stateData.position2 !== null || stateData.constantValue2 !== null) && this.props.analyzeVideoSuccess !== null}
                        operand={stateData.operand}
                        onClick={onConfirmEquation}
                        analysisResults={analysisResults}
                    />
                </Container>
                <Container>
                    <TargetSummary 
                        readOnly={readOnly}
                        show={stateData.operand !== null && stateData.operand !== undefined}
                        targetInterpretationData={targetInterpretationData} 
                        analysisResults={this.props.analyzeVideoSuccess}
                        onChange={this.props.onSummaryChange}
                        summary={stateData.summary}
                    />
                </Container>
                <Container>
                    <TargetLinesContainer
                        stateData={stateData}
                        run={new Date().getTime()} // need to do to update!
                        readOnly={readOnly}
                        show={true}
                        lines={stateData.lines}
                        calculationType={stateData.calculationConditionType}
                        calculationType1={stateData.calculationConditionType1}
                        calculationType2={stateData.calculationConditionType2}
                        targetInterpretationData={targetInterpretationData} 
                        analysisResults={analysisResults}
                        position1={stateData.position1}
                        position2={stateData.position2}
                        bodyPoints1={stateData.bodyPoints1 || null}
                        bodyPoints2={stateData.bodyPoints2 || null}
                        onChange={onLineChange}
                        originPosition={stateData.position1 !== null ? stateData.position1[1].name : null}
                    />
                </Container>
                {this.props.licenseeCampaignStatus === 'INACTIVE' && (
                    <div className="flex flex-1 flex-row py-4 justify-end w-full space-x-2">
                        <Button 
                            title={`Click to Add to Lesson Program`} 
                            bgColor="indigo"
                            hoverColor="indigo"
                            block={true}
                            size="lg"
                            onClick={this.props.onClickSaveAndContinue} 
                        />
                    </div>
                )}
            </Container>
        );
    }
}

TargetConditionsContainerList.defaultProps = {
    code: null,
    stateData: null,
    readOnly: true,
    isEditable: false,
    targetInterpretationData: null,
    analysisResults: null,
    history: null,
    onConditionCreate(){},
    onConstantChange(){},
    onConfirmBodyPoints(){},
    onCalculationTypeChange(){},
    onConfirmEquation(){},
    onSelectCode(){},
    onLineChange(){},
    onSummaryChange(){},
    onClickSaveAndContinue(){},
}

const mapStateToProps = ({ auth, targetInterpretation }) => {
    const { authUser } = auth;
    return {
        authUser,
        targetInterpretation,
    }
}

const mapDispatchToProps = {
    removeTargetInterpretationFromTarget
};

export default connect(mapStateToProps, mapDispatchToProps)(TargetConditionsContainerList);