import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { registerAndActivateLicensee, registerAndActivateLicenseeReset } from '../../actions/auth';
import { getApplicationWithNonce } from '../../actions/user';
import { selectors as userSelectors } from '../../reducers/user';
import { Button, Loading } from '../common';
/**
 * Invitation
 * 
 * 1. User applies from the home page
 * 2. Application gets stored into the database
 * 3. An email gets sent to ME with the application information
 *      including a link to "accept" and "deny" the application.
 * 4. Upon accept, we will send the applicant an invitation email
 *      with a link to the /invitation/nonce page
 * 4a. Upon deny, the user will receive an email letting them know to hang tight
 *      and we will let them know if anything changes...
 * 5. On this invitation page, we will fetch the applicant information
 *      and display that to the page "Welcome John, you have been selected to 
 *      become a member"...
 * 6. Allow the user to register their account!
 */
class Invitation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            passwordConfirm: '',
            terms: false,
            error: null,
        };
    }

    componentDidMount = () => {
        // lets reset redux...
        this.props.registerAndActivateLicenseeReset();
        try {
            const nonce = this.props.nonce;
            console.log('NONCE:', nonce);
            this.props.getApplicationWithNonce(nonce);
        } catch(e) {
        }
    }

    componentDidUpdate = (nextProps) => {
        if (nextProps.applicationInfo !== this.props.applicationInfo && this.props.applicationInfo !== null) {
            this.setState({
                email: this.props.applicationInfo.email
            }, () => {
                this.password.focus();
            });
        }
    }

    handleOnChangePassword = (e) => {
        this.setState({
            password: e.target.value
        });
    }

    handleOnChangePasswordConfirm = (e) => {
      this.setState({
          passwordConfirm: e.target.value
      });
    }

    handleTerms = (e) => {
      this.setState({
        terms: e.target.checked
      });
    }

    handleRegister = (e) => {
        e.preventDefault();
        const { applicationInfo } = this.props;
        const { password, terms } = this.state;
        if (password !== '' && terms !== false) {
            this.props.registerAndActivateLicensee(
              applicationInfo.firstName, 
              applicationInfo.lastName, 
              applicationInfo.email, 
              password, 
              applicationInfo.nonce, 
              this.props.history
            );
        } else {
            this.setState({
              error: true
            });
        }
    }

    handleGoogleLogin = (e) => {
        e.preventDefault();
    }

    passwordCheck = (password, passwordConfirm) => {
      return password !== '' && passwordConfirm !== '' && (password === passwordConfirm);
    }

    isDisabled = () => {
      const { terms, password, passwordConfirm } = this.state;
      if (terms === true) {
        if (this.passwordCheck(password, passwordConfirm) === true) {
          return false;
        }
      }
      return true;
    }

    render () {
        const {
            registerAndActivateLicenseeError,
            registerAndActivateLicenseeLoading,
            applicationInfo
        } = this.props;
        
        return applicationInfo !== null ? (
            <div className="min-h-screen bg-gray-800 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <img className="mx-auto h-12 w-auto rounded" src={require('../../img/logo.png')} alt="Swingbot" />
              <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-white">
                Hi {applicationInfo.firstName}, let's get started.
              </h2>
              <p className="mt-2 text-center text-sm leading-5 text-gray-200 max-w">
                Please choose a password to create your account.
              </p>
            </div>
          
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                  {(registerAndActivateLicenseeError !== null || this.state.error !== null) && (
                      <div className="rounded-md bg-red-50 p-4 mb-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <svg className="h-5 w-5 text-red-400" fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd"/>
                          </svg>
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm leading-5 font-medium text-red-800">
                            There was an error creating your account. Please make sure all the fields are filled in and you have accepted the terms of service.
                          </h3>
                        </div>
                      </div>
                    </div>
                  )}
                <form action="#" method="POST" className="space-y-4">
                  <div className="">
                    <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
                      Password
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input 
                        ref = {(input) => this.password = input}
                        onChange={this.handleOnChangePassword} 
                        id="password" 
                        type="password" 
                        required 
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" 
                      />
                    </div>
                  </div>

                  <div className="">
                    <label htmlFor="confirm-password" className="block text-sm font-medium leading-5 text-gray-700">
                      Confirm Password
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input 
                        ref = {(input) => this.passwordConfirm = input}
                        onChange={this.handleOnChangePasswordConfirm} 
                        id="confirm-password" 
                        type="password" 
                        required 
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                    </div>
                  </div>
                    
                  <div className="mt-6 flex items-center justify-between">
                    <div className="flex items-start">
                      <input id="terms" type="checkbox" onChange={this.handleTerms} className="form-checkbox h-4 w-4 text-green-600 transition duration-150 ease-in-out mt-1" />
                      <label htmlFor="remember_me" className="ml-2 block text-xs leading-5 text-gray-500">
                        By creating an account, I am agreeing to the <a href="/terms" target="blank" className="text-green-600">Terms of Service</a>.
                      </label>
                    </div>
                  </div>

                {registerAndActivateLicenseeLoading === false && (
                    <div className="mt-6">
                        <span className="block w-full rounded-md shadow-sm">
                          <Button 
                              onClick={this.handleRegister}
                              title="Create Account"
                              bgColor="green"
                              hoverColor="green"
                              block
                              size="lg"
                              disabled={this.isDisabled()}
                          />
                        </span>
                    </div>
                )}
                {registerAndActivateLicenseeLoading === true && (
                    <div className="mt-6">Creating account...</div>
                )}
                </form>


              </div>
            </div>
          </div>
        ) : <Loading />;
    }
}

Invitation.defaultProps = {
    onClick() {}
}

const mapStateToProps = ({ auth, user }) => {
    const {
        authUser,
        loadingRegisterUser,
        registerUserError,
        registerAndActivateLicenseeComplete,
        registerAndActivateLicenseeLoading,
        registerAndActivateLicenseeError
    } = auth;
    const {
        getApplicationWithNonceError,
        getApplicationWithNonceComplete,
        getApplicationWithNonceLoading
    } = user;
    return {
        applicationInfo: userSelectors.applicationInfo(user),
        authUser,
        loadingRegisterUser,
        registerUserError,
        registerAndActivateLicenseeComplete,
        registerAndActivateLicenseeLoading,
        registerAndActivateLicenseeError,
        getApplicationWithNonceError,
        getApplicationWithNonceComplete,
        getApplicationWithNonceLoading
    }
}

const mapDispatchToProps = {
    registerAndActivateLicensee,
    registerAndActivateLicenseeReset,
    getApplicationWithNonce
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Invitation));