import React from 'react';
import WizardContainer from './WizardContainer';
class WizardYesNo extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            dirty: false,
        }
    }

    handleKeyPress = (e) => {
        if (e.charCode === 13) {
            e.preventDefault();
            this.setState({
                [this.props.name]: 'Y'
            }, () => {
                this.props.onChange({
                    name: this.props.name,
                    value: 'Y'
                });
                this.handleNextStep();
            });
        }
    }

    handleClick = (answer) => {
        // go to the next step...
        this.setState({
            [this.props.name]: answer
        }, () => {
            this.props.onChange({
                name: this.props.name,
                value: answer
            });
            this.handleNextStep();
        });
    }

    handleNextStep = () => {
        this.props.nextStep();
    }

    handlePrevStep = () => {
        this.props.previousStep();
    }

    render() {
        return (
            <WizardContainer 
                title={this.props.title} 
                text={this.props.text} 
                step={this.props.step}
                previousStep={this.props.previousStep}
                nextStep={this.props.nextStep}
            >
                <div className="flex flex-row justify-start items-center mt-4">
                    <div 
                        disabled={this.state[this.props.name] === undefined}
                        onClick={() => this.handleClick('Y')} 
                        className="py-4 px-8 whitespace-no-wrap inline-flex items-center justify-center border-green-500 text-2xl leading-6 font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition ease-in-out duration-150 mr-2 cursor-pointer"
                    >
                        Yes
                    </div>
                    <div 
                        disabled={this.state[this.props.name] === undefined}
                        onClick={() => this.handleClick('N')}
                        className="whitespace-no-wrap inline-flex items-center justify-center py-4 px-8 border-gray-500 text-2xl leading-6 font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700 transition ease-in-out duration-150 mr-2 cursor-pointer"
                    >
                        No
                    </div>
                </div>
            </WizardContainer>
        );
    }
}

WizardYesNo.defaultProps = {
    step: 1,
    name: 'input',
    type: 'text',
    placeholder: '',
    title: '',
    color: 'green',
    onChange(){}
}

export default WizardYesNo;