import React from 'react';
import { handleScrollTo } from '../../../util/web';
import ReactPlayer from 'react-player';
class Hero2 extends React.Component {
    
    render() {
        return (
            <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
            <div className="flex lg:flex-row flex-col space-y-3 space-x-6 items-center">
                <div className="mb-4 flex-1 flex-col w-full items-center justify-center">
                    <h2 className="text-center lg:text-left text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                    Analyze Videos
                    <br className="" />
                    <span className="text-green-600">while you sleep</span>
                    </h2>
                    <p className="text-center mx-auto lg:text-left mt-3 text-base text-gray-600 font-bold sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                    Earn more revenue, attract more students, 24 hours a day.
                    </p>
                    <div className="justify-center md:justify-center lg:justify-start xl:justify-start mt-5 md:mt-8 flex">
                    <div className="rounded-md shadow">
                        <div onClick={() => handleScrollTo('get-started')} className="w-full flex items-center justify-center px-8 py-3 border border-transparent leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 transition duration-150 ease-in-out text-lg md:py-4 xl:text-xl md:text-lg md:px-10 cursor-pointer">
                        Click to Get Started
                        </div>
                    </div>
                    {/* <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                        <a href="#" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-green-600 bg-white hover:text-green-500 focus:outline-none focus:border-green-300 focus:shadow-outline-green transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                        Live demo
                        </a>
                    </div> */}
                    </div>
                </div>
                <div className="flex flex-0 justify-center sm:justify-center sm:mx-auto md:justify-center rounded bg-gray-800 p-2">
                    <ReactPlayer url="https://youtu.be/1p969tVtFqc" controls />
                </div>
            </div>
            </main>

        );
    }
};

export default Hero2;