import React from 'react';

class MyStory extends React.Component {
    render() {
        return (
                <div className="py-16 bg-gray-50 overflow-hidden">
                    <div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="text-base max-w-prose mx-auto lg:max-w-none">
                            <p className="text-base leading-6 text-green-600 font-semibold tracking-wide uppercase">My Story</p>
                            <h1 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">Good, Better, Best</h1>
                        </div>
                        <div className="relative z-10 prose prose-lg text-base max-w-prose mx-auto mb-8 lg:max-w-5xl lg:mx-0 lg:pr-72">
                            <p className="text-lg text-gray-500 leading-7">In 2009 I started a project called Swingbot that would automate the analysis of a golf swing from a simple video. It took a few years, but I finally got it right. The biggest thing that was missing was the instructors, lots of them.</p>
                        </div>
                        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
                        <div className="relative z-10 mb-12 lg:mb-0">
                            <div className="mb-10 prose text-gray-500 mx-auto lg:max-w-none">
                            <p>We feel that golf instructors are the true teachers of the game, no computer can replace them. A computer, if used properly, can aid the instruction, but the teacher must teach the machine.</p>
                            <p>Instructors can use SwingbotPro to add their teaching knowledge into our analysis system and within minutes provide their students with automated golf analysis.</p>
                            <p>We encourage you to create an account and teach more golfers than ever before, around the world, 24 hours a day.</p>
                            {/* <h2>We’re here to help</h2>
                            <p>Tincidunt integer commodo, cursus etiam aliquam neque, et. Consectetur pretium in volutpat, diam. Montes, magna cursus nulla feugiat dignissim id lobortis amet. Laoreet sem est phasellus eu proin massa, lectus. Diam rutrum posuere donec ultricies non morbi. Mi a platea auctor mi.</p>*/}
                            </div>
                            {/* <div className="flex text-base max-w-prose mx-auto lg:max-w-none"> */}
                            {/* <div className="rounded-md shadow">
                                <a href="#" className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition duration-150 ease-in-out">
                                Contact sales
                                </a>
                            </div>
                            <div className="rounded-md shadow ml-4">
                                <a href="#" className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-green-600 bg-white hover:text-green-500 focus:outline-none focus:border-green-300 focus:shadow-outline-green transition duration-150 ease-in-out">
                                Learn more
                                </a>
                            </div> */}
                            {/* </div> */}
                        </div>
                        <div className="relative text-base max-w-prose mx-auto lg:max-w-none">
                            <svg className="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384">
                            <defs>
                                <pattern id="bedc54bc-7371-44a2-a2bc-dc68d819ae60" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width="404" height="384" fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)" />
                            </svg>
                            <blockquote className="relative bg-white rounded-lg shadow-lg">
                            <div className="rounded-t-lg px-6 py-8 sm:px-10 sm:pt-10 sm:pb-8">
                                {/* <img src={require('../../../img/logo.png')} alt="Swingbot" className="h-10" /> */}
                                <div className="relative text-lg text-gray-700 leading-7 font-medium mt-8">
                                <svg className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-200" fill="currentColor" viewBox="0 0 32 32">
                                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                                </svg>
                                <p className="relative">I wanted to offer automated golf swing analysis as a tool for golf instructors around the world. Nothing can replace a professional, but with the right lesson analysis, SwingbotPro can provide the next best thing.</p>
                                </div>
                            </div>
                            <cite className="flex items-center sm:items-start bg-green-600 rounded-b-lg not-italic py-5 px-6 sm:py-5 sm:pl-12 sm:pr-10 sm:mt-10">
                                <div className="rounded-full border-2 border-white mr-6 sm:-mt-15 sm:mr-6">
                                <img className="w-12 h-12 sm:w-20 sm:h-20 rounded-full bg-green-300" src={require('../../../img/logo.png')} alt="" />
                                </div>
                                <span className="text-green-300 font-semibold leading-6">
                                <strong className="text-white font-semibold">John Giatropoulos</strong>
                                <br className="sm:hidden" />
                                &nbsp;CEO at SwingbotPro
                                </span>
                            </cite>
                            </blockquote>
                        </div>
                        </div>
                    </div>
                </div>
        );
    }
}

export default MyStory;