/**
 * ForgotPassword component
 */
import React from 'react';
import { connect } from 'react-redux';
import { forgotPassword, forgotPasswordReset } from '../../actions/auth';
import { withRouter, Link } from 'react-router-dom';
import { Button, Loading } from '../common';
import { Container } from '../tailwind';
class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
        }
    }

    componentDidMount = () => {
        this.props.forgotPasswordReset();
    }

    handleEmailKeypress = (e) => {
        this.setState({
            email: e.target.value
        });
    }

    handleForgotPassword = (e) => {
        e.preventDefault();
        const { email } = this.state;
        this.props.forgotPassword(email, this.props.history);
    }

    render() {
        const {
            forgotPasswordSuccess,
            forgotPasswordLoading,
        } = this.props;
        return (
            <div className="min-h-screen bg-gray-800 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img className="mx-auto h-12 w-auto" src={require('../../img/logo.png')} alt="Swingbot SaaS" />
                <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-white">
                Forgot Password?
                </h2>
                <p className="mt-2 text-center text-sm leading-5 text-gray-200 max-w">
                or&nbsp;
                <Link to="/login" className="font-medium text-green-600 hover:text-green-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                    sign in
                </Link>
                </p>
            </div>
            
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    {forgotPasswordLoading === false && forgotPasswordSuccess === null && (
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <p className="text-sm text-gray-700 mb-4">Please enter your email address and we will send you an email with reset instructions.</p>
                    <form onSubmit={this.handleForgotPassword}>
                        <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                            Email address
                        </label>
                        <div className="mt-1 rounded-md shadow-sm">
                            <input 
                                id="email" 
                                type="email" 
                                autoComplete="username" 
                                required 
                                onChange={this.handleEmailKeypress}
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                        </div>
                        </div>

                        <div className="mt-6">
                            <span className="block w-full rounded-md shadow-sm">
                                <Button
                                    title="Submit Email"
                                    bgColor="green"
                                    submit
                                    block
                                    onClick={this.handleForgotPassword}
                                />
                            </span>
                        </div>
                    </form>
                    </div>
                    )}
                    {forgotPasswordLoading === true && (
                        <Container>
                            <Loading />
                        </Container>
                    )}
                    {forgotPasswordSuccess !== null && forgotPasswordLoading === false && (
                        <Container>
                            <h2 className="font-bold">Success</h2>
                            <p>We have sent you an email with instructions for resetting your password.</p>
                            <div className="mt-4">
                                <Button
                                    title="Back to Login"
                                    bgColor="green"
                                    submit
                                    block
                                    onClick={() => this.props.history.push('/login')}
                                />
                            </div>
                        </Container>
                    )}
                </div>
            
            </div>
        );
    }
}

const mapStatetoProps = ({ auth }) => {
    const { 
        authUser,
        token,
        forgotPasswordSuccess,
        forgotPasswordLoading,
        forgotPasswordError,
    } = auth;
    return { 
        authUser,
        token,
        forgotPasswordSuccess,
        forgotPasswordLoading,
        forgotPasswordError,
    };
};

const mapDispatchToProps = {
    forgotPassword,
    forgotPasswordReset
}
const forgotPasswordWithRouter = withRouter(ForgotPassword);
export default connect(mapStatetoProps, mapDispatchToProps)(forgotPasswordWithRouter);