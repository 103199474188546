import React from 'react';
import { connect } from 'react-redux';
import { DashboardContent, Container } from '../tailwind';
import { logoutUser } from '../../actions/auth';
import { getUserDataById } from '../../actions/user';
import { getPlans } from '../../actions/plan';
import { selectors as authSelectors } from '../../reducers/auth';
import { capitalizeFirstLetter } from '../../util/strings';
import { Button } from '../common'
import { selectors as licenseeSelectors } from '../../reducers/licensee';
import ModalConfirmAction from '../common/modal/ModalConfirmAction';
import ImageWithError from '../common/ImageWithError';
import AccountRenewalBanner from '../common/banner/AccountRenewalBanner';
import SlackIntegrationPanel from './integrations/slack/SlackIntegrationPanel';

// braintree
import {
    subscribeToPlan,
    subscribeToPlanReset,
    createBraintreeCustomerGetToken,
    cancelSubscription
} from '../../actions/subscribe';

class AccountPage extends React.Component {

    // braintree instance
    instance;

    constructor(props) {
        super(props);
        this.state = {
            sandbox: true,
            planSelected: 'basic',
        }
    }

    componentDidMount() {
        this.props.subscribeToPlanReset();
        const { authUser } = this.props;
        if (authUser) {
            const userId = authUser.user_id;
            const licenseeId = authUser.licensee_id;
            const sandbox = process.env.REACT_APP_ENV === 'development';
            this.props.getUserDataById(userId, licenseeId, sandbox);
        }
        // this.props.getPlans();
    }

    handleLogout = () => {
        this.props.history.push('/logout');
    }

    handleCancelSubscriptionConfirm = (subscriptionId) => {
        if (this.props.authUser) {
            const userId = this.props.authUser.user_id;
            const sandbox = process.env.REACT_APP_ENV === 'development';
            this.props.cancelSubscription(userId, subscriptionId, sandbox);
        }
    }
    handleCancelSubscription = (subscriptionId) => {
        this.setState({
            showCancelModal: true,
        });
    }

    renderPlans = () => {
        const { planInformation } = this.props;
        const planStatus = planInformation.isCurrent;
        console.log(planInformation);
        return (
            <Container>
                <div className="flex flex-col w-full">
                    <div className="w-full">
                        {planStatus === true && (
                            <p className="pb-4">You are subscribed to the <span className="font-bold">{planInformation.planName} {planInformation.planFeatureName}</span> subscription plan.</p>
                        )}
                        {planStatus === false && (
                            <p className="pb-4">You are <span className="font-bold">no longer subscribed</span> to the <span className="font-bold">{planInformation.planName} {planInformation.planFeatureName}</span> subscription plan.</p>
                        )}
                        <ul className="list-disc pl-8">
                            {planStatus === true && (
                                <li>This plan will renew <span className="font-bold">{planInformation.term}</span></li>
                            )}
                            <li>You may process <span className="font-bold">{planInformation.videosPerMonth}</span> videos per month</li>
                            <li>You can create <span className="font-bold">{planInformation.lessonsAllowed}</span> Lesson Programs</li>
                            <li>You can create <span className="font-bold">{planInformation.targetsAllowed}</span> Lesson Targets per Lesson Program</li>
                        </ul>
                    </div>
                </div>
            </Container>
        )
    }

    renderTransactions = () => {
        const { licenseeData } = this.props;
        return licenseeData !== null ? (
            
            licenseeData.transactions.map(transaction => {
                return (
                    <div className="grid grid-cols-5 w-full gap-2 p-4 border-b items-center justify-center" key={transaction.order_id}>
                        <div className="font-bold">{transaction.order_id}</div>
                        <div className="font-bold">{transaction.subscription.status}</div>
                        <div className="font-bold">{transaction.subscription.planId}</div>
                        <div>${transaction.amount}</div>
                        <div className="flex flex-row justify-end">
                            {transaction.subscription.status !== 'Canceled' && (
                                <Button
                                    bgColor="red"
                                    hoverColor="red"
                                    onClick={() => this.handleCancelSubscription(transaction.subscription.id)}
                                    title="Cancel"
                                    size="sm"
                                /> 
                            )}
                        </div>
                    </div>
                );
            })
        ) : null;
    }

    renderCancelPlan = () => {
        const { planInformation } = this.props;
        const isFreePlan = planInformation.planName.substring(0,4).toLowerCase() === 'free';
        return isFreePlan === false 
            ? (
                <Container>
                    <div className="flex flex-col">
                        <div className="flex flex-row items-center justify-between">
                            <div className="flex">
                                <h2 className="text-2xl font-bold text-gray-700 mb-4">Plan Cancellation</h2>
                            </div>
                            <div className="flex items-center">
                                <Button
                                    textColor="white"
                                    bgColor="red"
                                    hoverColor="red"
                                    onClick={null}
                                    title="Cancel Plan"
                                    className="flex-0"
                                /> 
                            </div>
                        </div>
                        <div className="text-gray-600 text-base mt-4">
                            You may cancel your plan at anytime. By cancelling your plan you will no longer have access to any of the content you have created in SwingbotPro. You will still have access to the dashboard but certain areas of the site will be locked.
                        </div>
                    </div>
                </Container>
            ) : null;
    }

    render() {
        const { 
            planInformation, 
            authUser,
            cancelSubscriptionLoading,
            cancelSubscriptionSuccess,
            cancelSubscriptionError
        } = this.props;
        console.log(planInformation);
        const planStatus = planInformation.isCurrent;
        return (
            <DashboardContent
                title={'Account'} 
                backTitle="Logout"
                buttonTitle="Logout"
                buttonClassColor="red"
                onClick={this.handleLogout}
            >
                <Container className="bg-gray-800 text-white">
                    <div className="flex w-full">
                    <div className="flex flex-row w-full item-center text-sm space-x-2">
                        <div className="p-1 bg-white rounded-full shadow mr-2">
                        <ImageWithError 
                            className="h-16 w-16 rounded-full" 
                            src={authUser.avatar} 
                            alt="" 
                            errorSrc={require('../../img/user.png')}
                        />
                      </div>
                      <div className="flex flex-col justify-center ml-4">
                        <div className="flex items-center text-lg font-bold">{authUser.full_name}</div>
                        <div className="flex items-center text-sm">{authUser.email}</div>
                    </div>
                    </div>
                    </div>
                </Container>
                {/* {this.renderBillingInfo()} */}
                <AccountRenewalBanner />
                {(planStatus === true || (planInformation.isEnterprise === true || planInformation.isFree === true)) && (
                    <div className="w-full">
                        <div className="flex flex-row items-center justify-between px-2 py-2">
                            <h1 className="text-2xl text-gray-700 font-bold">{planInformation.planName} {planInformation.planFeatureName} {capitalizeFirstLetter(planInformation.term)} Subscription</h1>
                            <div className="flex items-center space-x-2">
                                    <Button
                                        onClick={() => this.props.history.push('/cart')}
                                        title={'View Plans'}
                                        bgColor="blue"
                                        hoverColor="blue"
                                        size="base"
                                    />
                                    {planStatus === true && (
                                        <Button
                                            onClick={() => this.handleCancelSubscription()}
                                            title={'Cancel'}
                                            bgColor="red"
                                            hoverColor="red"
                                            size="base"
                                        />
                                    )}
                                </div>
                            </div>
                            {this.renderPlans()}
                    </div>
                )}
                {(planStatus === false && (planInformation.isEnterprise === false && planInformation.isFree === true)) && (
                    <div className="bg-white rounded border border-gray-200 shadow w-full">
                        <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                        <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-700 sm:text-4xl sm:leading-10">
                            Ready to get started?
                            <br />
                            Pick the plan that's right for you.
                        </h2>
                            <div className="mt-8 flex justify-center">
                                <div className="inline-flex rounded-md shadow">
                                    <div 
                                    onClick={() => this.props.history.push('/cart')}
                                    className="inline-flex items-center justify-center px-5 py-3 border border-transparent leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out cursor-pointer text-xl">
                                        Click to View Plans
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {authUser.user_id === 1 && (
                    <div className="flex flex-col mt-4 w-full">
                        <div className="px-2 text-gray-800">
                            <h1>Integrations</h1>
                        </div>
                        <SlackIntegrationPanel />
                    </div>
                )}
                
                {/* <div className="">
                    <h1 className="text-2xl text-gray-700 font-bold ml-4 py-2">Subscriptions</h1>
                    <Container>
                        {this.renderTransactions()}
                    </Container>
                </div> */}
                <ModalConfirmAction 
                    ariaHideApp={false}
                    show={this.state.showCancelModal}
                    title="Confirm Cancel Plan"
                    body="Are you sure you wish to cancel your plan?"
                    buttonTitleConfirm="Yes, cancel my plan"
                    buttonTitleCancel="Cancel"
                    history={this.props.history}
                    onCancel={() => {
                        this.setState({ showCancelModal: false });
                    }}
                    onConfirm={() => {
                        this.handleCancelSubscriptionConfirm(planInformation.subscriptionId);
                    }}
                    isLoading={cancelSubscriptionLoading}
                    isSuccess={cancelSubscriptionSuccess}
                    isError={cancelSubscriptionError}
                >
                    <ul>
                        <li>This action cannot be undone.</li>
                    </ul>
                </ModalConfirmAction>
            </DashboardContent>
        )
    }
}

const mapStateToProps = ({ auth, plan, licensee, subscribe }) => {
    const { authUser } = auth;
    const {
        plans,
        getPlansSuccess,
        getPlansError,
        getPlansLoading
    } = plan;
    const {
        clientToken,
        subscriptionSuccess,
        cancelSubscriptionLoading,
        cancelSubscriptionSuccess,
        cancelSubscriptionError,
    } = subscribe;
    return {
        planInformation: authSelectors.planInformation(auth),
        licenseeData: licenseeSelectors.licenseeData(licensee),
        authUser,
        plans,
        getPlansError,
        getPlansSuccess,
        getPlansLoading,
        clientToken,
        subscriptionSuccess,
        cancelSubscriptionLoading,
        cancelSubscriptionSuccess,
        cancelSubscriptionError,
    };
}

const mapDispatchToProps = {
    logoutUser,
    getUserDataById,
    getPlans,
    createBraintreeCustomerGetToken,
    subscribeToPlan,
    subscribeToPlanReset,
    cancelSubscription
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);