import React from 'react';
import { connect } from 'react-redux';
import { getSlackWebhookForLicensee } from '../../../../actions/slack';
import { selectors as slackSelectors } from '../../../../reducers/slack';
import Container from '../../../tailwind/Container';
import { Button, Loading } from '../../../common';

class SlackIntegrationPanel extends React.Component {

  componentDidMount = () => {
    const { authUser } = this.props;
    if ('licensee_id' in authUser) {
      const licenseeId = authUser.licensee_id;
      this.props.getSlackWebhookForLicensee(licenseeId);
    }
  }

  render() {
    const { slackWebhook } = this.props;
    console.log('slack webhook ', slackWebhook);
    return (
      <Container className="mt-4">
          {slackWebhook.loading === true && <Loading />}
          {slackWebhook.loading === false && (
            <div className="flex justify-between items-center w-full">
                <div className="flex flex-col w-full">

                  <div className="flex flex-row w-full items-center justify-between mb-4">
                    <div className="mb-2">
                      <h2 className="font-bold">Slack</h2>
                      <p className="text-gray-600">Receive notifications directly into your Slack Workspace by integrating the SwingbotPro Slack App.</p>
                    </div>
                    <div className="">
                      <Button
                          onClick={() => window.location = "https://slack.com/oauth/v2/authorize?client_id=1508845747552.2214588460547&scope=incoming-webhook&user_scope="}
                          title={'Edit Slack Integration'}
                          bgColor="blue"
                          hoverColor="blue"
                          size="base"
                      />
                    </div>
                  </div>

                  <div className="rounded border bg-gray-50 p-4">
                    <div className="flex flex-row w-full text-gray-600 mb-2">
                      <div className="flex flex-col w-full">
                        <p className="font-bold">Webhook</p>
                        <input 
                            id="slack-webhook" 
                            value={slackWebhook.webhook_url || ''} className="form-input block w-full rounded transition ease-in-out duration-150 sm:text-sm sm:leading-5 text-cool-gray-500" 
                            placeholder="Api Key" 
                            readOnly
                        />
                      </div>
                    </div>

                    <div className="flex flex-row w-full text-gray-600">
                      <div className="flex flex-col w-full">
                        <p className="font-bold">Channel</p>
                        <input 
                            id="slack-channel" 
                            value={slackWebhook.channel} className="form-input block w-full rounded transition ease-in-out duration-150 sm:text-sm sm:leading-5 text-cool-gray-500" 
                            placeholder="Api Key" 
                            readOnly
                        />
                      </div>
                    </div>
                </div>
                </div>
            </div>
          )}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, slack }) => {
  const authUser = auth.authUser;
  return {
    authUser,
    slackWebhook: slackSelectors.slackWebhook(slack)
  }
};

const mapDispatchToProps = {
  getSlackWebhookForLicensee
};

export default connect(mapStateToProps, mapDispatchToProps)(SlackIntegrationPanel);