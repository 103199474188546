import React from 'react';
import { connect } from 'react-redux';
import { withRouter, } from "react-router-dom";
import ImageWithError from '../common/ImageWithError';
import { selectors as authSelectors } from '../../reducers/auth';
class DashboardFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          activeRoute: '/',
          userProfilePanelOpen: false,
          menuPanelOpen: false,
        }
    }
  
    handleClick = (route) => {
        this.setState({ activeRoute: route });
        this.props.history.push(route);
    }

    activeClass = (route) => {
        const pathParts = this.props.location.pathname.split('/');
        const rootPath = "/" + pathParts[1];
        return rootPath === route
          ? 'bg-gray-900 cursor-pointer focus:outline-none '
          : 'hover:bg-gray-700 cursor-pointer focus:outline-none '
    }

    render2() {
        const { authUser } = this.props;
        return (
            <nav className="bg-gray-800 p-2">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="relative h-16">
                    <div className="flex">
                        <div className="-ml-2 mr-2 flex items-center justify-center md:hidden">
                        
                        <button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out" aria-label="Main menu" aria-expanded="false">
                            
                            <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                            
                            <svg className="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        </div>
                        <div className="flex-shrink-0 flex items-center">
                        <img className="block lg:hidden h-8 w-auto" src={require('../../img/logo.png')} alt="Swingbot Pro logo" />
                        <img className="hidden lg:block h-8 w-auto" src={require('../../img/logo.png')} alt="Swingbot Pro logo" />
                        </div>
                        <div className="hidden sm:block sm:ml-6">
                        <div className="flex">
                                <div onClick={() => this.handleClick('/overview')} className={`${this.activeClass('/overview')} mt-1 group flex items-center px-4 py-2 text-sm leading-6 font-normal rounded-md text-white focus:outline-none transition ease-in-out duration-150`}>
                                    Overview
                                </div>
                                <div onClick={() => this.handleClick('/lessons')} className={`${this.activeClass('/lessons')} mt-1 group flex items-center px-4 py-2 text-sm leading-6 font-normal rounded-md text-gray-300 hover:text-white focus:bg-gray-700 focus:outline-none focus:text-white transition ease-in-out duration-150`}>
                                    Lessons
                                </div>
                                <div onClick={() => this.handleClick('/users')} className={`${this.activeClass('/users')}mt-1 group flex items-center px-4 py-2 text-sm leading-6 font-normal rounded-md text-gray-300 hover:text-white focus:bg-gray-700 focus:outline-none focus:text-white transition ease-in-out duration-150`}>
                                    Golfers
                                </div>
                                <div onClick={() => this.handleClick('/videos')} className={`${this.activeClass('/videos')}mt-1 group flex items-center px-4 py-2 text-sm leading-6 font-normal rounded-md text-gray-300 hover:text-white focus:bg-gray-700 focus:outline-none focus:text-white transition ease-in-out duration-150`}>
                                    Swings
                                </div>
                                <div onClick={() => this.handleClick('/api-keys')} className={`${this.activeClass('/api-keys')}mt-1 group flex items-center px-4 py-2 text-sm leading-6 font-normal rounded-md text-gray-300 hover:text-white focus:bg-gray-700 focus:outline-none focus:text-white transition ease-in-out duration-150`}>
                                    Api Key
                                </div>
                        </div>
                        </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                        {/* <button className="p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out" aria-label="Notifications">
                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                        </svg>
                        </button> */}

                        {/* profile dropdown */}
                        <div className="ml-3 relative">
                        <div className="inline-flex items-center text-white">
                            <button 
                                className="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-white transition duration-150 ease-in-out" 
                                id="user-menu" 
                                aria-label="User menu" 
                                aria-haspopup="true"
                                onClick={() => this.setState({ userProfilePanelOpen: !this.state.userProfilePanelOpen })}
                            >
                            <ImageWithError 
                              className="h-8 w-8 rounded-full" 
                              src={authUser.avatar}
                              alt="User Avatar" 
                              errorSrc={require("../../img/user.png")}
                            />
                            </button>
                            <div className="ml-1 text-sm text-white">
                                {authUser.full_name}
                            </div>
                        </div>
                        {/*
                            // Profile dropdown panel, show/hide based on dropdown state.

                            // Entering: "transition ease-out duration-100"
                            //   From: "transform opacity-0 scale-95"
                            //   To: "transform opacity-100 scale-100"
                            // Leaving: "transition ease-in duration-75"
                            //   From: "transform opacity-100 scale-100"
                            //   To: "transform opacity-0 scale-95"
                        */}
                            <div className={`${this.state.userProfilePanelOpen === true ? 'block' : 'hidden'} origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-50`}>
                                <div className="py-1 rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                                <div 
                                    onClick={() => this.props.history.push('/account')} 
                                    className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer" 
                                    role="menuitem"
                                >
                                        Manage Account
                                </div>
                                <div 
                                    onClick={() => this.props.history.push('/logout')} 
                                    className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer" 
                                    role="menuitem"
                                >
                                    Sign out
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

                {/*
                //     Mobile menu, toggle classes based on menu state.

                //     Menu open: "block", Menu closed: "hidden"
                */}

                <div className={`${this.state.menuPanelOpen === true ? 'block': 'hidden'} sm:hidden`}>
                    <div className="px-2 pt-2 pb-3">
                    <div onClick={() => this.handleClick('/overview')} className={`${this.activeClass('/overview')} mt-1 group flex items-center px-4 py-2 text-sm leading-6 font-normal rounded-md text-white focus:outline-none transition ease-in-out duration-150`}>
                        Overview
                    </div>
                    <div onClick={() => this.handleClick('/lessons')} className={`${this.activeClass('/lessons')} mt-1 group flex items-center px-4 py-2 text-sm leading-6 font-normal rounded-md text-gray-300 hover:text-white focus:bg-gray-700 focus:outline-none focus:text-white transition ease-in-out duration-150`}>
                        Lessons
                    </div>
                    <div onClick={() => this.handleClick('/users')} className={`${this.activeClass('/users')}mt-1 group flex items-center px-4 py-2 text-sm leading-6 font-normal rounded-md text-gray-300 hover:text-white focus:bg-gray-700 focus:outline-none focus:text-white transition ease-in-out duration-150`}>
                        Golfers
                    </div>
                    <div onClick={() => this.handleClick('/videos')} className={`${this.activeClass('/videos')}mt-1 group flex items-center px-4 py-2 text-sm leading-6 font-normal rounded-md text-gray-300 hover:text-white focus:bg-gray-700 focus:outline-none focus:text-white transition ease-in-out duration-150`}>
                        Swings
                    </div>
                    <div onClick={() => this.handleClick('/api-keys')} className={`${this.activeClass('/api-keys')}mt-1 group flex items-center px-4 py-2 text-sm leading-6 font-normal rounded-md text-gray-300 hover:text-white focus:bg-gray-700 focus:outline-none focus:text-white transition ease-in-out duration-150`}>
                        Api Key
                    </div>
                    </div>
                </div>
            </nav>
        );
    }

    renderDebug = (debugObject) => {
      return Object.keys(debugObject).map(key => {
        return (
          <div 
            key={key}
            className="p-2 m-2 cursor-pointer rounded bg-gray-600 text-white text-sm" 
            onClick={() => { 
              if (this.state.debugOpen !== key) { 
                this.setState({ debugOpen: key }); 
              } else { 
                this.setState({ debugOpen: null });
              }}}>
            {key}
            <div className={`bg-gray-600 text-gray-300 text-xs ${this.state.debugOpen === key ? '':'hidden'}`}>
              <pre>
                {JSON.stringify(debugObject[key], null, "\t")}
              </pre>
            </div>
          </div>
        )
      });
    }

    render() {
        const debug = process.env.REACT_APP_ENV === 'development';
        return debug === true ? (
          <div className="bg-gray-500 p-2 m-0 flex-1 flex-col h-full min-h-screen">
              {debug === true && this.renderDebug(this.props.allState)}
          </div>
        ) : null;
            
    }
}

const mapStateToProps = (state) => {
    const {
      api,
      auth,
      routing,
      licensee,
      user,
      analyze,
      program,
      licenseeCampaign,
      targetInterpretation,
      settings,
      plan,
      target,
      line,
      subscribe,
      website,
    } = state;
    const { authUser } = state.auth;
    return {
      allState: {
        api,
        auth,
        routing,
        licensee,
        user,
        analyze,
        program,
        licenseeCampaign,
        targetInterpretation,
        settings,
        plan,
        target,
        line,
        subscribe,
        website,
      },
      authUser,
      planInformation: authSelectors.planInformation(auth)
    };
  }
  
const mapDispatchToProps = {}

const DashboardFooterWithRouter = withRouter(DashboardFooter);
export default connect(mapStateToProps, mapDispatchToProps)(DashboardFooterWithRouter);