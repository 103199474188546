import React from 'react';
import { connect } from 'react-redux';
import DisabledTargetContainer from '../analysis/DisabledTargetContainer';
import SelectedPosition from './SelectedPosition';
import { convertCalculationToEnglish } from '../../../actions/analyze';
import { 
    bodyPoints, 
    positions,
    calculationTypes,
    getBodyPointByName,
    getPositionByName,
    getCalculationInstructions,
} from '../../../util/swingbot';

class TargetBodyPoints extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            calculationType: this.props.calculationType || null,
            disabled: this.props.readOnly
        }
    }

    setStatePositions = (calculationType, positions) => {
        this.setState({ positions });
    }

    setStateBodyPoints = (calculationType, bodyPoints) => {
        this.setState({ bodyPoints });
    }

    getInitialBodyPoints = (props) => {
        if (props.bodyPoints === null) {
            if ( props.suggestedBodyPoints !== null) {
                return props.suggestedBodyPoints;
            }
        } else {
            return props.bodyPoints;
        }
        return null;
    }

    // confirmAnalysis = (data) => {
    //     if (this.props.calculationType !== null) {
    //         const numberOfPoints = this.props.calculationType.points;
    //         if (this.props.bodyPoints !== null && this.props.positions !== null) {
    //             if (Object.keys(this.props.bodyPoints).length === numberOfPoints && 
    //                 Object.keys(this.props.positions).length === numberOfPoints
    //                 ) {
    //                 let pseudo = this.props.calculationType.pseudo + '=';
    //                 const { bodyPoints, positions } = this.props;
    //                 const numberOfPoints = this.props.calculationType.points;
    //                 // loop and create the pseudo
    //                 for(var i = 1; i < numberOfPoints + 1; i++) {
    //                     pseudo = pseudo
    //                         .concat(bodyPoints[i].name)
    //                         .concat('@')
    //                         .concat(positions[i].name)
    //                         .concat('|');
    //                 }
    //                 // strip off the trailing pipe, and tack on ABS...
    //                 pseudo = pseudo.substr(0, pseudo.length - 1);
    //                 // need to format this into something similar to results
    //                 const data = {
    //                     number: this.props.number,
    //                     calculationType: this.props.calculationType,
    //                     pseudo,
    //                     positions,
    //                     bodyPoints,
    //                     constantValue: this.state.constantValue
    //                 }
    //                 // confirm it
    //                 this.props.onConfirm(data);
    //             } else {
    //                 this.props.onConfirm({
    //                     number: this.props.number,
    //                     positions,
    //                     bodyPoints
    //                 });
    //             }
    //         } else {
    //             this.props.onConfirm({
    //                 number: this.props.number,
    //                 positions,
    //                 bodyPoints
    //             });
    //         }
    //     } else {
    //             this.props.onConfirm({
    //                 number: this.props.number,
    //                 calculationType: null,
    //                 positions,
    //                 bodyPoints
    //             });
    //     }
    // }

    confirmAnalysisPayload = (data) => {
        // positions: this.props.positions,
        // bodyPoints: currentBodyPoints,
        // calculationType: this.props.calculationType,
        // number: this.props.number,
        // constantValue: null
        
        if (data.calculationType !== null) {
            const numberOfPoints = data.calculationType.points;
            if (data.bodyPoints !== null && data.positions !== null) {
                if (Object.keys(data.bodyPoints).length === numberOfPoints && 
                    Object.keys(data.positions).length === numberOfPoints
                    ) {
                    let pseudo = data.calculationType.pseudo + '=';
                    const { bodyPoints, positions } = data;
                    const numberOfPoints = data.calculationType.points;
                    // loop and create the pseudo
                    for(var i = 1; i < numberOfPoints + 1; i++) {
                        pseudo = pseudo
                            .concat(bodyPoints[i].name)
                            .concat('@')
                            .concat(positions[i].name)
                            .concat('|');
                    }
                    // strip off the trailing pipe, and tack on ABS...
                    pseudo = pseudo.substr(0, pseudo.length - 1);
                    // need to format this into something similar to results
                    const dataToSend = {
                        number: data.number,
                        calculationType: data.calculationType,
                        pseudo,
                        positions,
                        bodyPoints,
                        constantValue: this.state.constantValue
                    }
                    // confirm it
                    this.props.onConfirm(dataToSend);
                } else {
                    this.props.onConfirm({
                        number: data.number,
                        positions: data.positions,
                        bodyPoints: data.bodyPoints,
                        calculationType: data.calculationType || null
                    });
                }
            } else {
                // if there is a constant we should pass back a default 
                // value for the constant
                if (data.calculationType.name === 'constant') {
                    this.props.onConfirm({
                        number: data.number,
                        positions: data.positions,
                        bodyPoints: data.bodyPoints,
                        calculationType: data.calculationType || null,
                        constantValue: 1
                    });
                } else {
                    this.props.onConfirm({
                        number: data.number,
                        positions: data.positions,
                        bodyPoints: data.bodyPoints,
                        calculationType: data.calculationType || null,
                    });
                }
            }
        } else {
                this.props.onConfirm({
                    number: data.number,
                    calculationType: data.calculationType,
                    positions: data.positions,
                    bodyPoints: data.bodyPoints
                });
        }
    }

    handleBodyPointChange = (e) => {
        if (e.target.value !== '') {
            const number = e.target.id.substr(e.target.id.length - 1, 1);
            const selectedBodyPoint = getBodyPointByName(e.target.value);
            let currentBodyPoints = this.props.bodyPoints;
            if (currentBodyPoints === null) {
                currentBodyPoints = {};
                currentBodyPoints[number] = selectedBodyPoint;
            } else {
                currentBodyPoints[number] = selectedBodyPoint;
            }
            const dataToSend = {
                positions: this.props.positions,
                bodyPoints: currentBodyPoints,
                calculationType: this.props.calculationType,
                number: this.props.number,
                constantValue: null
            };
            this.confirmAnalysisPayload(dataToSend);
        }
    }

    handlePositionChange = (e) => {
        if (e.target.value !== '') {
            const number = e.target.id.substr(e.target.id.length - 1, 1);
            const selected = getPositionByName(e.target.value);
            let currentPositions = this.props.positions;
            if (currentPositions === null) {
                currentPositions = {};
                currentPositions[number] = selected;
            } else {
                currentPositions[number] = selected;
            }

            const dataToSend = {
                positions: currentPositions,
                bodyPoints: this.props.bodyPoints,
                calculationType: this.props.calculationType,
                number: this.props.number
            };
            this.confirmAnalysisPayload(dataToSend);
        } else {
            // this.confirmAnalysis();
        }
    }

    handleConstantKeypress = (e) => {
        const constantValue = e.target.value !== '' ? parseInt(e.target.value, 0) : null;
        // how do we store this as the comparison value in the equation...
        this.props.onConstantChange(constantValue, this.props.number);
    }

    generatePayload = () => {

    }

    renderPointsToSelect = () => {
        // first get the number of points to loop through
        // from the calculation types
        if (this.props.calculationType !== null) {
            if (this.props.calculationType.pseudo !== 'CONSTANT') {
                const numberOfPoints = this.props.calculationType.points;
                let divs = [];
                for( var i = 0; i < numberOfPoints; i++) {
                    divs.push(this.renderBodyPointMenuRaw(i+1))
                }
                return divs.map(d => d);
            } else {
                // render the constant input form
                return this.renderConstantInput();
            }
        }
    }

    renderConstantInput = () => {
        const disabledClass = this.props.readOnly === true ? "bg-gray-200" : "";
        return (
            <div className="h-full">
            <label htmlFor="constant" className="block text-lg font-bold leading-5 text-gray-800">
                Enter a number 
            </label>
            <div className="mt-1 rounded-md shadow-sm">
                <input 
                    id={`constant${this.props.number}`} 
                    type="number" 
                    autoComplete="number" 
                    required 
                    onChange={this.handleConstantKeypress}
                    className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${disabledClass}`} 
                    placeholder={100}
                    value={this.props.constantValue || 1}
                    disabled={this.props.readOnly}
                />
            </div>
            </div>
        )
    }

    isBodyPointSelected = (bodyPoint) => {
        const currentBodyPoints = this.setInitialBodyPoints(this.props);
        return currentBodyPoints 
            ? currentBodyPoints.name === bodyPoint.name
            : false;
    }

    bodyPointSelected = (number) => {
        let bodyPointName = "";
        if (this.props.bodyPoints !== null) {
            if (number in this.props.bodyPoints) {
                bodyPointName = this.props.bodyPoints[number].name
            }
        }
        return bodyPointName;
    }

    positionSelected = (number) => {
        let positionName = "";
        if (this.props.positions !== null) {
            if (number in this.props.positions) {
                positionName = this.props.positions[number].name
            }
        }
        return positionName;
    }

    renderBodyPointMenuRaw = (number) => {
        const disabledClass = this.props.disableBodyPoints === true || this.props.readOnly === true ? "bg-gray-100 cursor-not-allowed": "";
        const positionSelected = this.positionSelected(number);
        const bodyPointSelected = this.bodyPointSelected(number);
        const cssBorder = bodyPointSelected === "" ? "border-red-500 border-2" : "border-gray-200 border-2";
        const cssBorderPosition = positionSelected === "" ? "border-red-500 border-2" : "border-gray-200 border-2";
        return (
            <div key={number}>
                {this.getLabel(this.props.number,`Body Point and Position ${number}`, `bodyPoint${number}`)}
                <div className="grid lg:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 gap-2">
                    <div className="col-span-1 max-w-lg rounded-md sm:max-w-full w-full mb-2 min-h-64">
                        <select 
                            id={`bodyPoint${number}`} 
                            onChange={this.handleBodyPointChange} 
                            value={bodyPointSelected}
                            className={`block form-select transition duration-150 ease-in-out sm:text-sm sm:leading-5 w-full ${disabledClass} ${cssBorder}`}
                            disabled={this.props.readOnly}
                        >
                            <option value="">Select a Body Point</option>
                            {this.renderBodyPointMenu(number)}
                        </select>
                    </div>
                    <div className="col-span-1 max-w-lg rounded-md sm:max-w-full w-full min-h-64">
                        <select 
                            id={`position${number}`} 
                            onChange={this.handlePositionChange} 
                            value={positionSelected}
                            className={`block form-select transition duration-150 ease-in-out sm:text-sm sm:leading-5 w-full ${disabledClass} ${cssBorderPosition}`}
                            disabled={this.props.readOnly}
                        >
                            <option value="">Select a Position</option>
                            {this.renderPositionMenu(number)}
                        </select>
                    </div>
                </div>
            </div>
        );
    }

    renderBodyPointMenu = () => {
        return bodyPoints.map(bp => (<option key={bp.name} value={bp.name}>{bp.display}</option>));
    }

    renderPositionMenu = () => {
        return positions.map(p => (<option key={p.name} value={p.name}>at {p.display}</option>));
    }

    renderCalculationTypeText = () => getCalculationInstructions(this.props.calculationType.pseudo);

    isDisabled = () => {
        if (this.props.calculationType !== null) {
            if ('points' in this.props.calculationType) {
                return !(Object.keys(this.props.bodyPoints).length === this.props.calculationType.points)
            }
        }
        return true;
    }

    handleCalculationTypeChange = (e) => {
        if (e.target.value !== "") {
            const calculationType = calculationTypes.filter(calc => calc.pseudo === e.target.value)[0];
            this.confirmAnalysisPayload({
                number: this.props.number,
                calculationType,
                positions: null,
                bodyPoints: null
            })
        } else {
            this.confirmAnalysisPayload({
                number: this.props.number,
                calculationType: null,
                positions: null,
                bodyPoints: null
            });
        }
    }

    isCalculationSelected = (calc) => {
        return calc === this.props.calculationType;
    }

    renderCalculationTypes = () => {
        try {
            if (calculationTypes) {
                const { number } = this.props;
                const types = number === 1 
                    ? calculationTypes.filter(calc => calc.pseudo !== 'CONSTANT')
                    : calculationTypes;
                const options = types.map(calc => {
                    return (
                        <option key={calc.name} value={calc.pseudo}>
                            {calc.display}
                        </option>
                    )
                }).filter(res => res !== null);
                
                // if the menu has not been selected, lets turn it red...
                let classDisabled = this.props.readOnly === true
                    ? "bg-gray-100 cursor-not-allowed"
                    : "";
                const selectedValue = this.props.calculationType !== null
                    ? this.props.calculationType.pseudo
                    : "";
                const cssBorder = selectedValue === "" ? "border-red-500 border-2" : "border-gray-200 border-2";
                return this.props.calculationType !== 'CONSTANT' ? (
                    <select 
                        onChange={this.handleCalculationTypeChange} 
                        id={`calculation${this.props.number}`} 
                        value={selectedValue}
                        className={`block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${classDisabled} ${cssBorder}`}
                        disabled={this.props.readOnly}
                    >
                        <option 
                            value="" 
                            className="text-xl text-gray-500"
                        >Select an Analysis Comparison</option>
                        {options}
                    </select>
                ) : null;
            }
        } catch(e) {
        }
    }

    getLabel = (number, text, htmlFor) => {
        const classText = number === 1
            ? "text-gray-800 text-lg"
            : "text-gray-800 text-lg";
        return (
            <label htmlFor={htmlFor} className={`block text-sm font-bold leading-5 text-gray-600 sm:mt-px sm:pt-2 my-2 ${classText}`}>
                {text}
            </label>
        );
    }

    renderHeading = (number) => {
        return (
            <div className="col-span-3 flex justify-center items-center">
                <div className="flex items-center justify-center rounded-full h-32 w-32">
                    <h1 className="text-8xl font-bold text-white">{number === 1 ? 'A' : 'B'}</h1>
                </div>
            </div>
        );
    }

    render() {
        const { 
            number, 
            bodyPoints, 
            calculationType, 
            analysisResults, 
            positions,
            constantValue 
        } = this.props;
        
        return this.props.show === true ? (
            <div className="my-0 h-full min-h-64">
                <div className="flex flex-col md:flex-row lg:flex-row h-full">

                    <div className="flex h-full w-full lg:w-1/2 md:w-1/2 flex-col p-4">
                        <div className="mt-0 max-w-lg rounded-md sm:max-w-full mb-4">
                            {this.getLabel(number, 'What are you assessing?', `calculation${number}`)}
                            {this.renderCalculationTypes()}
                        </div>
                        {this.renderPointsToSelect()}
                    </div>

                    <div className="flex flex-col w-full md:w-1/2 lg:w-1/2 min-h-64">
                        {bodyPoints && positions && calculationType.name.toLowerCase() !== 'constant' && (
                            <div className="flex justify-center mt-2 rounded h-full bg-gray-800 p-2 m-2">
                                <SelectedPosition
                                    calculationType={calculationType}
                                    position={positions}
                                    analysisResults={analysisResults}
                                    bodyPoints={bodyPoints}
                                    ratio={.85}
                                />
                            </div>
                        )}
                        {calculationType && calculationType.name.toLowerCase() === 'constant' && (
                            <div className="flex justify-center mt-2 rounded h-full bg-gray-800 p-2 m-2">
                                <div className="flex items-center justify-center h-64 text-3xl text-white bg-gray-800 w-full rounded">
                                    <h2 className="text-6xl font-bold">{constantValue}</h2>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            </div>
        ) : (<DisabledTargetContainer title="Select a Position" />);
    }
}

TargetBodyPoints.defaultProps = {
    show: false,
    readOnly: true, // shut it down on default
    canEdit: false, // shut it down on default
    image: '',
    frameImage: '',
    positionSelected: null,
    bodyPoints: null,
    constantValue: null,
    number: 1,
    onConfirm(){},
    onCalculationChange(){},
    suggestedBodyPoints: null,
    analysisResults: {},
    disableBodyPoints: false,
    calculationType: null,
}

const mapStateToProps = ({ analyze }) => {
    const { convertCalculationToEnglishSuccess, convertCalculationToEnglishLoading, convertCalculationToEnglishError } = analyze;
    return {
        convertCalculationToEnglishSuccess,
        convertCalculationToEnglishLoading,
        convertCalculationToEnglishError
    };
};

const mapDispatchToProps = {
    convertCalculationToEnglish
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetBodyPoints);