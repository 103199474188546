import React from 'react';
import { Button } from './common';
import { Container } from './tailwind';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    console.log(info);
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
          <div className="min-h-screen bg-gray-800 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <Container>
                <h1 className="font-bold text-2xl">Mulligan Time</h1>
                <p>Something went wrong.</p>
                {/* <div className="py-4">
                    <Button 
                    size="lg"
                    bgColor="green"
                    title="Back to the Tee"
                    onClick={() => this.props.history.push({ pathname: '/' })}
                    />
                </div> */}
              </Container>
            </div>
          </div>
        );
      }
      return this.props.children;
  }
}

export default ErrorBoundary;