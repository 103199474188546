import React from 'react';
import { connect } from 'react-redux';
import DisabledTargetContainer from '../analysis/DisabledTargetContainer';
import TargetLinePanel from './TargetLinePanel';
import ImageDrawItem from './ImageDrawItem';
import { removeLineFromInterpretation } from '../../../actions/line';
import { autoGenerateLines } from '../../../util/swingbot';
import TargetLineOptions from './TargetLineOptions';
import TargetLinePositionOptions from './TargetLinePositionOptions';
class TargetLines extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lines: this.initialLines(this.props.lines),
            selectedLine: 0,
            showAll: true,
            originPosition: this.setOriginPosition(this.props.lines, this.props.position1)
        }
    }

    initialLines = (lines) => {
        if (Object.keys(lines).length > 0) {
            return lines;
        }
        return null;
    }

    setOriginPosition = (lines, position) => {
        if (Object.keys(lines).length > 0) {
            return Object.keys(lines)[0];
        }
        return null;
    }

    

    handleLineChange = (line) => {}

    /**
     * handleClickOriginPosition
     * We want to place ALL of the lines in ONE origin
     * SO, we have to have the object position consolidated into 
     * the one selected.
     * @param {string} position 
     */
    handleClickOriginPosition = (position) => {
        const { lines } = this.state;
        const currentLines = lines;
        const linesInState = [];
        Object.keys(currentLines).forEach(p => {
            const linesInPosition = currentLines[p];
            linesInPosition.forEach(line => {
                // add the line to the mix...
                linesInState.push(line);
            });
        });
        
        const newLines = { [position]: linesInState };
        this.setState({
            lines: newLines,
            originPosition: position
        });
         // send this up to the parent....
         this.props.onChange(newLines);
    }
    /**
     * handleAddLine
     * Add a new line from the options
     * @param {object} line 
     */
    handleAddLine = (line, position) => {
        const { lines } = this.state;
        const currentLines = lines;
        // we want to use the origin line selected.
        currentLines[this.state.originPosition].push(line);
        this.setState({
            lines: currentLines,
        });
        // send this up to the parent....
        this.props.onChange(currentLines);
    }

    handleDeleteLine = (number) => {
        const { lines } = this.state;
        const position = lines !== null ? Object.keys(lines)[0] : null;
        const currentLines = this.state.lines;
        if (currentLines[position][number]) {
            // ok lets check to see if we have the line!
            const lineToDelete = currentLines[position][number];

            // delete the line!
            this.props.removeLineFromInterpretation(lineToDelete.ref_id);

            // remove the line from state
            currentLines[position].splice(number, 1);
            this.setState({
                lines: currentLines,
                selectedLine: 0
            }, () => {
                this.setState({ lines: currentLines });
            });        
            // send up the lines to the parent
            this.props.onChange(currentLines);
        }
    }

    handleCreateLine = () => {
        const { lines } = this.state;
        let linesObject = lines && Object.keys(lines).length > 0 ? lines : { 'address': []};
        const position = linesObject !== null ? Object.keys(linesObject)[0] : 'address';
        
        const line = {
            color: "#76EE00",
            p1_lexicon: "left_shoulder",
            p2_lexicon: "right_shoulder",
            phase: position,
            phase_origin: position,
            phase_origin_2: position,
            style: "solid",
            time: "00:00:00",
            type: "p2p",
        };
        
        // lets add the line...
        linesObject[position].push(line);

        // send this up to the parent....
        this.props.onChange(linesObject);

        this.setState({
            lines: linesObject,
            selectedLine: linesObject[position].length - 1 // current line just created
        }, () => {});
    }

    handleNext = () => {
        try {
            const { lines } = this.props;
            const positionName = lines !== null ? Object.keys(lines)[0] : null;
            if (this.state.selectedLine + 1 < this.state.lines[positionName].length) {
                const lines = this.state.lines;
                this.setState({
                    selectedLine: this.state.selectedLine + 1
                }, () => {
                    this.setState({
                        lines
                    });
                });
            }
        } catch(e) {
        }
    }

    handlePrevious = () => {
        try {
            if (this.state.selectedLine - 1 > -1) {
                const lines = this.state.lines;
                this.setState({
                    selectedLine: this.state.selectedLine - 1
                }, () => {
                    this.setState({
                        lines
                    });
                });
            }
        } catch(e) {
        }
    }

    handleShowAll = () => {
        const lines = this.state.lines;
        this.setState({
            lines: null
        }, () => {
            this.setState({
                showAll: !this.state.showAll,
                lines
            })
        })
    }

    handleLineTypeChange = (e, number) => {
        const { lines } = this.state;
        const positionName = lines !== null ? Object.keys(lines)[0] : null;
        const currentLines = this.state.lines;
        currentLines[positionName][number]['type'] = e.target.value;
        this.setState({
            lines: null
        }, () => {
            this.setState({ lines: currentLines });
        });
        // send to the parent
        this.props.onChange(currentLines);
    }

    handleLineColorChange = (e, number) => {
        const { lines } = this.state;
        const positionName = lines !== null ? Object.keys(lines)[0] : null;
        const currentLines = this.state.lines;
        currentLines[positionName][number]['color'] = e.target.value;
        this.setState({
            lines: null
        }, () => {
            this.setState({ lines: currentLines });
        });
        // send to the parent
        this.props.onChange(currentLines);
    }

    handlePosition1Change = (e, number) => {
        const { lines } = this.state;
        const positionName = lines !== null ? Object.keys(lines)[0] : null;
        const currentLines = this.state.lines;
        currentLines[positionName][number]['phase'] = e.target.value;
        currentLines[positionName][number]['phase_origin'] = e.target.value;
        this.setState({
            lines: null
        }, () => {
            this.setState({ lines: currentLines });
        });
        // send to the parent
        this.props.onChange(currentLines);
    }

    handlePosition2Change = (e, number) => {
        const { lines } = this.state;
        const positionName = lines !== null ? Object.keys(lines)[0] : null;
        const currentLines = this.state.lines;
        currentLines[positionName][number]['phase_origin_2'] = e.target.value;
        this.setState({
            lines: null
        }, () => {
            this.setState({ lines: currentLines });
        });
        // send to the parent
        this.props.onChange(currentLines);
    }

    handleBodyPoint1Change = (e, number) => {
        const { lines } = this.state;
        const positionName = lines !== null ? Object.keys(lines)[0] : null;
        const currentLines = this.state.lines;
        currentLines[positionName][number]['p1_lexicon'] = e.target.value;
        this.setState({
            lines: null
        }, () => {
            this.setState({ lines: currentLines });
        });
        // send to the parent
        this.props.onChange(currentLines);
    }

    handleBodyPoint2Change = (e, number) => {
        const { lines } = this.state;
        const positionName = lines !== null ? Object.keys(lines)[0] : null;
        const currentLines = this.state.lines;
        currentLines[positionName][number]['p2_lexicon'] = e.target.value;
        this.setState({
            lines: null
        }, () => {
            this.setState({ lines: currentLines });
        });
        // send to the parent
        this.props.onChange(currentLines);
    }

    renderLineRows = (lines) => {
        try {
            const { analysisResults, readOnly } = this.props;
            const positionName = lines !== null ? Object.keys(lines)[0] : null;
            const line = positionName !== null 
                ? lines[positionName][this.state.selectedLine]
                : null;
            return line !== null ? (
                <TargetLinePanel
                    readOnly={readOnly}
                    position={positionName} 
                    analysisResults={analysisResults}
                    line={line}
                    lineIndex={this.state.selectedLine}
                    onLineTypeChange={this.handleLineTypeChange}
                    onLineColorChange={this.handleLineColorChange}
                    onPosition1Change={this.handlePosition1Change}
                    onPosition2Change={this.handlePosition2Change}
                    onBodyPoint1Change={this.handleBodyPoint1Change}
                    onBodyPoint2Change={this.handleBodyPoint2Change}
                    onDelete={this.handleDeleteLine}
                    onCreate={this.handleCreateLine}
                />
            ) : null;//this.renderDefaultLine();
        } catch (e) {
        }
    }

    renderLinePanels = (lines) => {
        try {
            const { analysisResults, readOnly } = this.props;

            return lines !== null ? Object.keys(lines)
                .map((key) => {
                    const positionName = key;
                    const imageUrl = this.getImageUrlForPosition(analysisResults, positionName, 1);
                    return lines[key].map((line, index) => {
                        return (
                            <div className="mt-4 flex flex-row bg-gray-100 p-4 rounded border border-gray-200">
                                <div className="flex flex-col">
                                    {lines && this.renderImagePosition(line, positionName, imageUrl)}
                                </div>
                                <div className="flex flex-col">
                                    <div className="my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                                        <div className="align-middle inline-block min-w-full overflow-hidden sm:rounded-lg">
                                        <TargetLinePanel
                                            key={`line-panel-${index}`}
                                            readOnly={readOnly}
                                            position={positionName} 
                                            analysisResults={analysisResults}
                                            line={lines[key]}
                                            lineIndex={index}
                                            onLineTypeChange={this.handleLineTypeChange}
                                            onLineColorChange={this.handleLineColorChange}
                                            onPosition1Change={this.handlePosition1Change}
                                            onPosition2Change={this.handlePosition2Change}
                                            onBodyPoint1Change={this.handleBodyPoint1Change}
                                            onBodyPoint2Change={this.handleBodyPoint2Change}
                                            onDelete={this.handleDeleteLine}
                                            onCreate={this.handleCreateLine}
                                        />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                    );
                }) : null;
        } catch (e) {
        }
    }

    renderImageItem = (lines) => {
        try {
            const { position, bodyPointsToRender } = this.props;
            const { analysisResults } = this.props;
            const { showAll } = this.state;
            const positionName = lines !== null ? Object.keys(lines)[0] : null;
            const line = lines[positionName][this.state.selectedLine];
            const imageUrl = this.getImageUrlForPosition(analysisResults, positionName, 1);
            const linesToShow = showAll === true ? lines[positionName] : [line];
            this.renderImagePosition(line, position, imageUrl);
            return imageUrl !== null ? (
                <ImageDrawItem
                    position={position}
                    imageUrl={imageUrl}
                    points={analysisResults.points}
                    rightHanded={analysisResults.right_handed}
                    bodyPointsToRender={bodyPointsToRender}
                    lines={linesToShow}
                    ratio={0.85}
                />
            ): null
        } catch(e) {
        }
    }

    renderImagePosition = (line, position, imageUrl) => {
        try {
            const { bodyPointsToRender } = this.props;
            const { analysisResults } = this.props;
            // just show the one line!
            const linesToShow = [line];
            return imageUrl !== null ? (
                <ImageDrawItem
                    position={position}
                    imageUrl={imageUrl}
                    points={analysisResults.points}
                    rightHanded={analysisResults.right_handed}
                    bodyPointsToRender={bodyPointsToRender}
                    lines={linesToShow}
                    ratio={0.85}
                />
            ): null
        } catch(e) {
        }
    }

    getImageUrlForPosition = (results, position, number) => {
        if (results !== null) {
            if (results.frame_images !== null) {
                if (position !== null) {
                    return results.frame_images[position];
                }
            }
        }
        return null;
    }

    setDefaultLine = (props) => {
        const { position1, bodyPoints1 } = props;

        const lines = autoGenerateLines(position1, bodyPoints1);
        return lines;
    }

    renderNavbar = (lines) => {
        const { position1, readOnly } = this.props;
        return position1 !== null ? (
            <div className="bg-white flex items-center justify-end px-2">
                <div className="flex-1 flex justify-between sm:hidden">
                    <div className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                    Previous
                    </div>
                    <div className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                    Next
                    </div>
                </div>
                {lines !== null && (
                    <div className="hidden sm:flex-1 sm:flex">
                        <div className="grid grid-cols-2 w-full">
                            <div className="col-span-1 m-0 p-0 text-xl font-bold text-gray-800">
                                Line {this.state.selectedLine + 1}
                            </div>
                            <div className="col-span-1 flex justify-end">
                            <nav className="relative z-0 inline-flex space-x-1">
                                <button type="button" className="mr-2 inline-flex items-center px-4 py-2 rounded-md text-sm leading-5 font-medium text-white-500 hover:bg-blue-600 focus:z-10 transition ease-in-out duration-150 bg-blue-500 text-white focus:outline-none" aria-label="Show All" onClick={this.handleShowAll}>
                                    {this.state.showAll === false ? 'Show All Lines' : 'Hide Others'}
                                </button>
                                
                                <button type="button" className="relative inline-flex items-center px-2 py-2 rounded-l-md text-sm leading-5 font-medium hover:bg-blue-600 focus:z-10 transition ease-in-out duration-150 text-white bg-blue-500 focus:outline-none" aria-label="Previous" onClick={this.handlePrevious}>
                                    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd"/>
                                    </svg> Previous Line
                                </button>
                                <button type="button" className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md text-sm leading-5 font-medium hover:bg-blue-600 focus:z-10 transition ease-in-out duration-150 text-white bg-blue-500 focus:outline-none" aria-label="Next" onClick={this.handleNext}>
                                    Next Line
                                    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"/>
                                    </svg>
                                </button>
                                {readOnly === false && (
                                    <button 
                                        type="button" 
                                        className="ml-2 relative inline-flex items-center px-4 py-2 rounded text-sm leading-5 font-medium focus:z-10 transition ease-in-out duration-150 text-white bg-green-500 hover:bg-green-600 focus:outline-none" 
                                        aria-label="Next" 
                                        onClick={this.handleCreateLine}
                                    >
                                    Create New
                                    </button>
                                )}
                            </nav>
                            </div>
                        </div>
                    </div>
                )}
                {!lines && (
                    <div className="hidden sm:flex-1 sm:flex">
                        <div className="grid grid-cols-2 w-full">
                            <div className="col-span-1 m-0 p-0 text-xl font-bold text-gray-600">
                                Line {this.state.selectedLine + 1}
                            </div>
                            <div className="col-span-1 flex justify-end">
                            <nav className="relative z-0 inline-flex">
                                {/* <button type="button" className="mr-2 inline-flex items-center px-4 py-2 rounded-md text-sm leading-5 font-medium text-white-500 hover:bg-blue-600 focus:z-10 transition ease-in-out duration-150 bg-blue-500 text-white focus:outline-none" aria-label="Show All" onClick={this.handleShowAll}>
                                    {this.state.showAll === false ? 'Show All Lines' : 'Hide Others'}
                                </button> */}
                                {/* <button type="button" className="relative inline-flex items-center px-2 py-2 rounded-l-md text-sm leading-5 font-medium hover:bg-blue-600 focus:z-10 transition ease-in-out duration-150 text-white bg-blue-500 focus:outline-none" aria-label="Previous" onClick={this.handlePrevious}>
                                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd"/>
                                </svg>
                                </button> */}
                                {/* <button type="button" className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md text-sm leading-5 font-medium hover:bg-blue-600 focus:z-10 transition ease-in-out duration-150 text-white bg-blue-500 focus:outline-none" aria-label="Next" onClick={this.handleNext}>
                                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"/>
                                </svg>
                                </button> */}
                                {readOnly === false && (
                                    <button 
                                        type="button" 
                                        className="ml-2 relative inline-flex items-center px-4 py-2 rounded text-sm leading-5 font-medium focus:z-10 transition ease-in-out duration-150 text-white bg-green-500 hover:bg-green-600 focus:outline-none" 
                                        aria-label="Next" 
                                        onClick={this.handleCreateLine}
                                    >
                                    Create New
                                    </button>
                                )}
                            </nav>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        ) : null;
    }

    render() {
        const { position1, position2, bodyPoints1, bodyPoints2, show, analysisResults, bodyPointsToRender } = this.props;
        const { lines } = this.state;
        
        let autolines = {};
        if (position1 && bodyPoints1 && lines === null) {
            autolines = autoGenerateLines(position1, bodyPoints1);
        }
        const linesToUse = lines === null ? autolines : lines;
        const origin = this.state.originPosition !== null ? this.state.originPosition : (position1 !== null ? position1[1].name : null);
        return show === true && position1 !== null && bodyPoints1 && linesToUse !== null ? (
            <div>
                {linesToUse && (
                    <div>
                    <TargetLinePositionOptions 
                        lines={linesToUse} 
                        show={true}
                        position1={position1}
                        position2={position2}
                        bodyPoints1={bodyPoints1}
                        bodyPoints2={bodyPoints2}
                        analysisResults={analysisResults}
                        bodyPointsToRender={bodyPointsToRender}
                        onClick={this.handleAddLine}
                        onClickPosition={this.handleClickOriginPosition}
                        originPosition={origin}
                    />
                    <TargetLineOptions
                        key={linesToUse} 
                        lines={linesToUse} 
                        show={true}
                        position1={position1}
                        position2={position2}
                        bodyPoints1={bodyPoints1}
                        bodyPoints2={bodyPoints2}
                        analysisResults={analysisResults}
                        bodyPointsToRender={bodyPointsToRender}
                        onCreate={this.handleAddLine}
                        onDelete={this.handleDeleteLine}
                        originPosition={origin}
                    />
                    </div>
                )}
            </div>
        ) : (<DisabledTargetContainer onClick={this.handleCreateLine} title="Click to Create Line Annotation" />);
    }
}

TargetLines.defaultProps = {
    show: false,
    readOnly: false,
    textMessage: '',
    lines: null,
    position1: null,
    bodyPoints1: null,
    position2: null,
    bodyPoints2: null,
    onCreate(){},
    onChange(){}
}

const mapStateToProps = ({ line }) => {
    return line;
}

const mapDispatchToProps = {
    removeLineFromInterpretation
}


export default connect(mapStateToProps, mapDispatchToProps)(TargetLines);