import * as actionTypes from './types';

export const getWebsiteForLicensee = (licenseeId) => ({
    type: actionTypes.GET_WEBSITE,
    payload: licenseeId
});

export const getWebsiteForLicenseeSuccess = (data) => ({
    type: actionTypes.GET_WEBSITE_SUCCESS,
    payload: data
});

export const getWebsiteForLicenseeFail = (error) => ({
    type: actionTypes.GET_WEBSITE_FAIL,
    payload: error
});

export const updateWebsiteForLicensee = (config) => ({
    type: actionTypes.UPDATE_WEBSITE,
    payload: config
});

export const updateWebsiteForLicenseeSuccess = (data) => ({
    type: actionTypes.UPDATE_WEBSITE_SUCCESS,
    payload: data
});

export const updateWebsiteForLicenseeFail = (error) => ({
    type: actionTypes.UPDATE_WEBSITE_FAIL,
    payload: error
});


export const createWebsiteForLicensee = (subdomain, licenseeId) => ({
    type: actionTypes.CREATE_WEBSITE,
    payload: { subdomain, licenseeId }
});

export const createWebsiteForLicenseeSuccess = (data) => ({
    type: actionTypes.CREATE_WEBSITE_SUCCESS,
    payload: data
});

export const createWebsiteForLicenseeFail = (error) => ({
    type: actionTypes.CREATE_WEBSITE_FAIL,
    payload: error
});

/**
 * resetStripeConnectedAccount
 */
export const resetStripeConnectedAccount = (email) => ({
    type: actionTypes.RESET_STRIPE_CONNECT_ACCOUNT,
    payload: null
});

/**
 * getStripeConnectedAccount
 */
 export const getStripeConnectedAccount = (licenseeId, account, sandbox) => ({
    type: actionTypes.GET_STRIPE_ACCOUNT,
    payload: { licenseeId, account, sandbox }
});

export const getStripeConnectedAccountSuccess = (data) => ({
    type: actionTypes.GET_STRIPE_ACCOUNT_SUCCESS,
    payload: data
});

export const getStripeConnectedAccountFail = (error) => ({
    type: actionTypes.GET_STRIPE_ACCOUNT_FAIL,
    payload: error
});


/**
 * createStripeConnectedAccount
 */
export const createStripeConnectedAccount = (licenseeId, email, sandbox) => ({
    type: actionTypes.CREATE_STRIPE_ACCOUNT,
    payload: { licenseeId, email, sandbox }
});

export const createStripeConnectedAccountSuccess = (data) => ({
    type: actionTypes.CREATE_STRIPE_ACCOUNT_SUCCESS,
    payload: data
});

export const createStripeConnectedAccountFail = (error) => ({
    type: actionTypes.CREATE_STRIPE_ACCOUNT_FAIL,
    payload: error
});

/**
 * linkStripeConnectedAccount
 */
 export const linkStripeConnectedAccount = (licenseeId, accountId, sandbox) => ({
    type: actionTypes.LINK_STRIPE_ACCOUNT,
    payload: { licenseeId, accountId ,sandbox }
});

export const linkStripeConnectedAccountSuccess = (data) => ({
    type: actionTypes.LINK_STRIPE_ACCOUNT_SUCCESS,
    payload: data
});

export const linkStripeConnectedAccountFail = (error) => ({
    type: actionTypes.LINK_STRIPE_ACCOUNT_FAIL,
    payload: error
});


