import React from 'react';

class HeroPricing extends React.Component {
    
    render() {
        return (
            <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
            <div className="text-center">
                <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                Flex Pricing
                <br className="xl:hidden" />
                <span className="text-green-600">pick your plan</span>
                </h2>
                <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                Choose how many videos to process per month, and choose your package.
                </p>
                <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                </div>
            </div>
            </main>
        );
    }
};

export default HeroPricing;