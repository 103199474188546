export const handleScrollTo = (hash) => {
    window.location.hash = "#" + hash;
    const el = document.getElementById(hash);
    el.scrollIntoView({
            behavior: 'smooth', // smooth scroll
            block: 'start' // the upper border of the element will be aligned at the top of the visible part of the window of the scrollable area.
    });
}

export const buttonColor = (color, hover, outline) => {
    const colorObject = {};
    switch(color.toLowerCase()) {
        case 'gray':
            colorObject.bgColor = 'bg-gray-600';
            colorObject.hoverColor = 'bg-gray-700';
            colorObject.outlineColor = 'border-gray-700';
        break;
        case 'green':
            colorObject.bgColor = 'bg-green-600';
            colorObject.hoverColor = 'bg-green-700';
            colorObject.outlineColor = 'border-green-700';
        break;
        case 'lightgreen':
            colorObject.bgColor = 'bg-green-400';
            colorObject.hoverColor = 'bg-green-500';
            colorObject.outlineColor = 'border-green-500';
        break;
        case 'blue':
            colorObject.bgColor = 'bg-blue-600';
            colorObject.hoverColor = 'bg-blue-700';
            colorObject.outlineColor = 'border-gray-700';
        break;
        case 'lightblue':
            colorObject.bgColor = 'bg-blue-400';
            colorObject.hoverColor = 'bg-blue-500';
            colorObject.outlineColor = 'border-blue-500';
            colorObject.borderColor = 'border-blue-500';
        break;
        case 'lightgray':
            colorObject.bgColor = 'bg-gray-500';
            colorObject.hoverColor = 'bg-gray-600';
            colorObject.outlineColor = 'border-gray-500';
            colorObject.borderColor = 'border-gray-600';
        break;
        case 'darkgray':
            colorObject.bgColor = 'bg-gray-700';
            colorObject.hoverColor = 'bg-gray-800';
            colorObject.outlineColor = 'border-gray-800';
            colorObject.borderColor = 'border-gray-800';
        break;
        case 'indigo':
            colorObject.bgColor = 'bg-indigo-600';
            colorObject.hoverColor = 'bg-indigo-700';
            colorObject.outlineColor = 'border-indigo-700';
        break
        case 'lightindigo':
            colorObject.bgColor = 'bg-indigo-500';
            colorObject.hoverColor = 'bg-indigo-600';
            colorObject.outlineColor = 'border-indigo-600';
        break
        case 'red':
            colorObject.bgColor = 'bg-red-600';
            colorObject.hoverColor = 'bg-red-700';
            colorObject.outlineColor = 'border-red-700';
        break;
        case 'lightred':
            colorObject.bgColor = 'bg-red-400';
            colorObject.hoverColor = 'bg-red-500';
            colorObject.outlineColor = 'border-red-500';
        break;
        case 'orange':
            colorObject.bgColor = 'bg-orange-600';
            colorObject.hoverColor = 'bg-orange-700';
            colorObject.outlineColor = 'border-orange-700';
        break;
        default:
            colorObject.bgColor = 'bg-transparent';
            colorObject.hoverColor = 'bg-transparent';
            colorObject.outlineColor = 'border-transparent';
        break;
    }
    return colorObject;
}