/**
 * LicenseeReducer
 *
 * Fetch the list of users from the database
 */
import * as actionTypes from '../actions/user/actionTypes';
import * as authActionTypes from '../actions/auth/actionTypes';

  // set the initial state of the reducer
    const INITIAL_STATE = {
        userData: null,
        loadingUserData: false,
        loadingUserDataError: null,

        licenseeUserData: null,
        loadingLicenseeUserData: true,
        loadingLicenseeUserDataError: null,

        users: null,
        loadingUsers: false,
        loadingUserError: null,

        applyToSwingbotComplete: null,
        applyToSwingbotLoading: false,
        applyToSwingbotError: null,

        getApplicationWithNonceComplete: null,
        getApplicationWithNonceLoading: false,
        getApplicationWithNonceError: null,

        acceptApplicationComplete: null,
        acceptApplicationLoading: false,
        acceptApplicationError: null,

        denyApplicationComplete: null,
        denyApplicationLoading: false,
        denyApplicationError: null,

        assignCreditToUserComplete: null,
        assignCreditToUserLoading: false,
        assignCreditToUserError: null,

        inviteCreditForUserComplete: null,
        inviteCreditForUserLoading: false,
        inviteCreditForUserError: null,

        updateUserProfileComplete: null,
        updateUserProfileLoading: true,
        updateUserProfileError: null,
    };
  
   export default (state = INITIAL_STATE, action) => {
     switch (action.type) {

        case authActionTypes.LOGOUT_USER:
            return {
                ...state,
                userData: null,
                loadingUserData: false,
                loadingUserDataError: null,
        
                users: null,
                loadingUsers: false,
                loadingUserError: null,
        
                applyToSwingbotComplete: null,
                applyToSwingbotLoading: false,
                applyToSwingbotError: null,
        
                getApplicationWithNonceComplete: null,
                getApplicationWithNonceLoading: false,
                getApplicationWithNonceError: null,
        
                acceptApplicationComplete: null,
                acceptApplicationLoading: false,
                acceptApplicationError: null,
        
                denyApplicationComplete: null,
                denyApplicationLoading: false,
                denyApplicationError: null,
            }

        case actionTypes.GET_USER_DATA_BY_ID:
            return {
                ...state,
                userData: null,
                loadingUserData: true,
                loadingUserDataError: null,
            }

       case actionTypes.GET_USER_DATA_BY_ID_SUCCESS:
         return {
            ...state,
            userData: action.payload,
            loadingUserData: false,
            loadingUserDataError: null,
         }

       case actionTypes.GET_USER_DATA_BY_ID_FAIL:
            return {
                ...state,
                userData: null,
                loadingUserData: false,
                loadingUserDataError: action.payload,
            }


        case actionTypes.GET_LICENSEE_USER_DATA_BY_ID:
            return {
                ...state,
                licenseeUserData: null,
                loadingLicenseeUserData: true,
                loadingLicenseeUserDataError: null,
            }
    
        case actionTypes.GET_LICENSEE_USER_DATA_BY_ID_SUCCESS:
            return {
                ...state,
                licenseeUserData: action.payload,
                loadingLicenseeUserData: false,
                loadingLicenseeUserDataError: null,
            }
    
        case actionTypes.GET_LICENSEE_USER_DATA_BY_ID_FAIL:
             return {
                ...state,
                licenseeUserData: null,
                loadingUserData: false,
                loadingUserDataError: action.payload,
             }

        case actionTypes.GET_LICENSEE_USER_DATA_BY_ID_RESET:
            console.log('RESET LICENSEE USER DATA');
            return {
                ...state,
                licenseeUserData: null,
                loadingUserData: false,
                loadingUserDataError: null,
            }

        case actionTypes.GET_USERS_BY_LICENSEE_ID:
            return {
                ...state,
                users: null,
                loadingUsers: true,
                loadingUserError: null,
            }
        case actionTypes.GET_USERS_BY_LICENSEE_ID_SUCCESS:
            return {
                ...state,
                users: action.payload,
                loadingUsers: false,
                loadingUserError: null,
            }
        case actionTypes.GET_USERS_BY_LICENSEE_ID_FAIL:
            return {
                ...state,
                users: null,
                loadingUsers: false,
                loadingUserError: action.payload,
            }

        case actionTypes.APPLY_TO_SWINGBOT:
            return {
                ...state,
                applyToSwingbotComplete: null,
                applyToSwingbotLoading: true,
                applyToSwingbotError: null,
            }
        case actionTypes.APPLY_TO_SWINGBOT_SUCCESS:
            return {
                ...state,
                applyToSwingbotComplete: action.payload,
                applyToSwingbotLoading: false,
                applyToSwingbotError: null,
            }
        case actionTypes.APPLY_TO_SWINGBOT_FAIL:
            return {
                ...state,
                applyToSwingbotComplete: null,
                applyToSwingbotLoading: false,
                applyToSwingbotError: action.payload,
            }
        case actionTypes.APPLY_TO_SWINGBOT_RESET:
          return {
              ...state,
              applyToSwingbotComplete: null,
              applyToSwingbotLoading: false,
              applyToSwingbotError: null,
          }

        case actionTypes.GET_APPLICATION_WITH_NONCE:
            return {
                ...state,
                getApplicationWithNonceComplete: null,
                getApplicationWithNonceLoading: true,
                getApplicationWithNonceError: null,
            }
        case actionTypes.GET_APPLICATION_WITH_NONCE_SUCCESS:
            return {
                ...state,
                getApplicationWithNonceComplete: action.payload,
                getApplicationWithNonceLoading: false,
                getApplicationWithNonceError: null,
            }
        case actionTypes.GET_APPLICATION_WITH_NONCE_FAIL:
            return {
                ...state,
                getApplicationWithNonceComplete: null,
                getApplicationWithNonceLoading: false,
                getApplicationWithNonceError: action.payload,
            }

        case actionTypes.ACCEPT_APPLICATION:
            return {
                ...state,
                acceptApplicationComplete: null,
                acceptApplicationLoading: true,
                acceptApplicationError: null,
            }
        case actionTypes.ACCEPT_APPLICATION_SUCCESS:
            return {
                ...state,
                acceptApplicationComplete: action.payload,
                acceptApplicationLoading: false,
                acceptApplicationError: null,
            }
        case actionTypes.ACCEPT_APPLICATION_FAIL:
            return {
                ...state,
                acceptApplicationComplete: null,
                acceptApplicationLoading: false,
                acceptApplicationError: action.payload,
            }

        case actionTypes.DENY_APPLICATION:
            return {
                ...state,
                denyApplicationComplete: null,
                denyApplicationLoading: true,
                denyApplicationError: null,
            }
        case actionTypes.DENY_APPLICATION_SUCCESS:
            return {
                ...state,
                denyApplicationComplete: action.payload,
                denyApplicationLoading: false,
                denyApplicationError: null,
            }
        case actionTypes.DENY_APPLICATION_FAIL:
            return {
                ...state,
                denyApplicationComplete: null,
                denyApplicationLoading: false,
                denyApplicationError: action.payload,
            }


        case actionTypes.ASSIGN_CREDIT_TO_USER:
            return {
                ...state,
                assignCreditToUserComplete: null,
                assignCreditToUserLoading: true,
                assignCreditToUserError: null,
            }
        case actionTypes.ASSIGN_CREDIT_TO_USER_SUCCESS:
            return {
                ...state,
                assignCreditToUserComplete: action.payload,
                assignCreditToUserLoading: false,
                assignCreditToUserError: null,
            }
        case actionTypes.ASSIGN_CREDIT_TO_USER_FAIL:
            return {
                ...state,
                assignCreditToUserComplete: null,
                assignCreditToUserLoading: false,
                assignCreditToUserError: action.payload,
            }
        case actionTypes.ASSIGN_CREDIT_TO_USER_RESET:
            return {
                ...state,
                assignCreditToUserComplete: null,
                assignCreditToUserLoading: false,
                assignCreditToUserError: null,
            }

        case actionTypes.INVITE_CREDIT_FOR_USER:
            return {
                ...state,
                inviteCreditForUserComplete: null,
                inviteCreditForUserLoading: true,
                inviteCreditForUserError: null,
            }
        case actionTypes.INVITE_CREDIT_FOR_USER_SUCCESS:
            return {
                ...state,
                inviteCreditForUserComplete: action.payload,
                inviteCreditForUserLoading: false,
                inviteCreditForUserError: null,
            }
        case actionTypes.INVITE_CREDIT_FOR_USER_FAIL:
            return {
                ...state,
                inviteCreditForUserComplete: null,
                inviteCreditForUserLoading: false,
                inviteCreditForUserError: action.payload,
            }
        case actionTypes.INVITE_CREDIT_FOR_USER_RESET:
            return {
                ...state,
                inviteCreditForUserComplete: null,
                inviteCreditForUserLoading: false,
                inviteCreditForUserError: null,
            }

        case actionTypes.UPDATE_USER_PROFILE:
            return {
                ...state,
                updateUserProfileComplete: null,
                updateUserProfileLoading: true,
                updateUserProfileError: null,
            }
        case actionTypes.UPDATE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                updateUserProfileComplete: action.payload,
                updateUserProfileLoading: false,
                updateUserProfileError: null,
            }
        case actionTypes.UPDATE_USER_PROFILE_FAIL:
            return {
                ...state,
                updateUserProfileComplete: null,
                updateUserProfileLoading: false,
                updateUserProfileError: action.payload,
            }
        case actionTypes.UPDATE_USER_PROFILE_RESET:
            return {
                ...state,
                updateUserProfileComplete: null,
                updateUserProfileLoading: false,
                updateUserProfileError: null,
            }

       default:
         return state;
     }
   };
  
   export const selectors = {
    applicationInfo:(state) => {
     return state.getApplicationWithNonceComplete
       ? {
        accepted: state.getApplicationWithNonceComplete.accepted,
        email: state.getApplicationWithNonceComplete.email,
        firstName: state.getApplicationWithNonceComplete.first_name,
        isGolfInstructor: state.getApplicationWithNonceComplete.is_golf_instructor,
        lastName: state.getApplicationWithNonceComplete.last_name,
        // user_application_id: 10
        website: state.getApplicationWithNonceComplete.website,
       } : null;
    },
    userDetails:(state) => {
        return state.userData !== null ? {
            email: state.userData.email,
            firstName: state.userData.f_name,
            lastName: state.userData.l_name,
            loading: state.loadingUserData
        } : {
            email: null,
            firstName: null,
            lastName: null,
            loading: state.loadingUserData
        };
    },
    updateUserDetails: (state) => {
        return {
            complete: state.updateUserProfileComplete,
            loading: state.updateUserProfileLoading,
            error: state.updateUserProfileError
        }
    },
    licenseeUserDetails:(state) => {
        return state.licenseeUserData !== null && state.licenseeUserData !== undefined ? {
            email: state.licenseeUserData.email,
            firstName: state.licenseeUserData.f_name,
            lastName: state.licenseeUserData.l_name,
            loading: state.loadingLicenseeUserData,
            data: state.licenseeUserData
        } : {
            email: null,
            firstName: null,
            lastName: null,
            loading: state.loadingLicenseeUserData,
            data: null
        };
    },
    assignCreditData: (state) => {
        return {
            success: state.assignCreditToUserComplete,
            loading: state.assignCreditToUserLoading,
            error: state.assignCreditToUserError
        }
    },
    inviteCredit: (state) => {
        return {
            success: state.inviteCreditForUserComplete,
            loading: state.inviteCreditForUserLoading,
            error: state.inviteCreditForUserError
        }
    },
    users: (state) => {
        return {
            count: state.users ? state.users.count : 0,
            data: state.users ? state.users.data : [],
            loading: state.loadingUsers,
            error: state.loadingUserError,
        }
    }

  }