/**
 * LessonsPage
 * 
 * This will list out all of the licensee campaigns
 * that have been created by the Pro.
 * 
 * Example: "Slice", "Memorial Day Lesson", etc.
 * 
 * For the purposes of this SAAS product, when the user creates a new Lesson "campaign"
 * we will automatically create 2 programs, 1 for face on and 1 for down the line.
 * 
 */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DashboardContent } from '../tailwind';
import { 
    getLicenseeCampaignsByLicenseeId, 
    duplicateLicenseeCampaign, 
    updateLicenseeCampaignStatus,
    resetUpdateLicenseeCampaignStatus
} from '../../actions/licenseeCampaign';
import Loading from '../common/Loading';
import { selectors as authSelectors } from '../../reducers/auth';
import { selectors as licenseeCampaignSelectors } from '../../reducers/licenseeCampaign';
import { Button } from '../common';
import { LessonProgramsBanner } from '../common/banner';
import ModalTW from '../common/modal/ModalTW';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faArchive,
    faPencilAlt,
    faLock
} from '@fortawesome/free-solid-svg-icons';
import { convertDateTimeToDate } from '../../util/dates';
import { getOutputStatus, getLiveStatus } from '../../util/swingbot';

import '../common/Overlay.css';
class LessonsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            term: '',
            type: 'active',
            showArchiveModal: false,
            showPublishModal: false,
            showDuplicateModal: false,
            modalRecord: null
        };
    }

    componentDidCatch = (e) => {
        this.props.history.push({
            pathname: '/500',
            state: e
        });
    }

    componentDidMount = () => {
        const { authUser } = this.props;
        this.props.getLicenseeCampaignsByLicenseeId(authUser.licensee_id);
        this.props.resetUpdateLicenseeCampaignStatus();
    }

    /**
     * handleClick
     * View the details of the campaign
     * @param {object} licenseeCampaign 
     */
    handleClick = (licenseeCampaign) => {
        this.props.history.push({
            pathname: `/lessons/${licenseeCampaign.licensee_campaign_id}`,
            state: { 
                licenseeCampaign: licenseeCampaign,
                licenseeCampaignId: licenseeCampaign.licensee_campaign_id 
            }
        })
    }

    handleClickActivate = (record) => {
        this.setState({ 
            showPublishModal: true,
            modalRecord: record
        });
    }

    handleConfirmClickActivate = (record) => {
        const { authUser, history } = this.props;
        const licenseeId = authUser.licensee_id;
        const status = 'ACTIVE';
        const licenseeCampaignId = this.state.modalRecord.licensee_campaign_id;
        this.props.updateLicenseeCampaignStatus(licenseeCampaignId, licenseeId, status, history);
    }

    handleCloseActivateModal = () => {
        this.setState({ 
            showPublishModal: false,
        });
    }

    handleClickArchive = (record) => {
        this.setState({ 
            showArchiveModal: true,
            modalRecord: record
        });
    }

    handleConfirmClickArchive = () => {
        const { authUser, history } = this.props;
        const licenseeId = authUser.licensee_id;
        const status = 'ARCHIVED';
        const licenseeCampaignId = this.state.modalRecord.licensee_campaign_id;
        this.props.updateLicenseeCampaignStatus(licenseeCampaignId, licenseeId, status, history);
    }

    handleClickDuplicate = (record) => {
        if (this.isAllowed() === true) {
            this.setState({ 
                showDuplicateModal: true,
                modalRecord: record
            });
        } else {
            this.props.history.push({
                pathname: `/cart`,
                state: {}
            })
        }
    }

    handleConfirmClickDuplicate = () => {
        const { authUser } = this.props;
        const licenseeId = authUser.licensee_id;
        const userId = authUser.user_id;
        const licenseeCampaignId = this.state.modalRecord.licensee_campaign_id;

        this.props.duplicateLicenseeCampaign(
            licenseeCampaignId, 
            licenseeId, 
            userId, 
            'INACTIVE', 
            this.props.history
        );
    }

    handleCreateNew = () => {
        this.props.history.push({
            pathname: `/lessons/create`,
            state: { }
        })
    }

    handleUpdatePlan = () => {
        this.props.history.push({
            pathname: '/cart'
        });
    }

    handleCloseModal = () => {
        this.setState({ isOpen: false });
        this.props.resetUpdateLicenseeCampaignStatus();
    }

    handleCreateDuplicate = () => {
        const { authUser } = this.props;
        const licenseeId = authUser.licensee_id;
        const userId = authUser.user_id;
        this.props.duplicateLicenseeCampaign(50, licenseeId, userId, 'ACTIVE', this.props.history);
    }
    
    handleSearch = (term) => {
        this.setState({ term: term.target.value });
    }

    handleCloseModal = () => {
        this.setState({
            showDuplicateModal: false,
            showArchiveModal: false,
            showPublishModal: false,
            modalRecord: null
        });
    }

    isAllowed = () => {
        const { licenseeCampaigns, planInformation, currentActiveLessons } = this.props;
        const isAllowed = licenseeCampaigns !== null && currentActiveLessons !== null
            ? (planInformation.lessonsAllowed > licenseeCampaigns.filter(lesson => lesson.status === 'ACTIVE').length)
            : false;
        return isAllowed;
    }

    renderRows = () => {
        const { licenseeCampaigns, planInformation, currentActiveLessons } = this.props;
        const searchTerm = this.state.term;
        const isAllowed = licenseeCampaigns !== null && currentActiveLessons !== null
            ? (planInformation.lessonsAllowed > licenseeCampaigns.filter(lesson => lesson.status === 'ACTIVE').length)
            : false;

        return licenseeCampaigns 
            ? licenseeCampaigns
                .filter(record => record.display_name.toLowerCase().indexOf(searchTerm) > -1)
                .filter(record => this.state.type !== 'all' ? record.licensee_campaign_status.toLowerCase() === this.state.type : true)
                .sort((a,b) => (a.lexicon_name > b.lexicon_name) ? 1 : -1)
                .map((record, index) => {
                // get the date...
                const status = record.licensee_campaign_status;
                return (
                    <tr 
                        key={index} 
                        className="mr-4 space-x-2"
                        onMouseOver={() => this.setState({ hoverId: `index-${index}`})}
                        onMouseOut={() => this.setState({ hoverId: null })}
                    >
                        <td 
                            className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 flex flex-row cursor-pointer"
                            onClick={() => this.handleClick(record)}
                        >
                            <div className="">
                                {status === 'ARCHIVED' && (
                                    <div className="text-red-500 text-xl">
                                        <FontAwesomeIcon icon={faArchive} className="group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                                    </div>
                                )}
                                {status === 'ACTIVE' && (
                                    <div className="text-green-500 text-xl">
                                        <FontAwesomeIcon icon={faLock} className="group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                                    </div>
                                )}
                                {status === 'INACTIVE' && (
                                    <div className="text-gray-500 text-xl">
                                        <FontAwesomeIcon icon={faPencilAlt} className="group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                                    </div>
                                )}
                            </div>
                            <div className="flex items-center">
                                <div className="ml-4">
                                    <div className="text-lg whitespace-no-wrap leading-5 font-medium text-gray-900">
                                        <div 
                                            onClick={() => this.handleClick(record)}
                                            className="text-gray-700 cursor-pointer font-bold mb-2"
                                        >
                                            <p>{record.display_name}</p>
                                        </div>
                                        <div className="text-gray-500 font-normal text-sm">
                                            Lesson Program Id: <span className="font-bold">{record.licensee_campaign_id}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="py-4 pl-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium w-0 my-2 pr-4">
                            <div 
                                className={this.state.hoverId === `index-${index}` ? `flex flex-row space-x-2`: `flex flex-row space-x-2 lg:hidden`}
                            >
                                <Button 
                                    onClick={() => this.handleClick(record)} 
                                    icon="open"
                                    bgColor="blue"
                                    tooltip="View Details"
                                    textColor="white"
                                    shadow
                                    size="base"
                                />
                                <Button 
                                    onClick={() => this.handleClickDuplicate(record)} 
                                    textColor="white"
                                    bgColor={isAllowed ? 'indigo' : 'gray'}
                                    icon="duplicate"
                                    tooltip={isAllowed ? "Duplicate Lesson Program" : "Upgrade Plan to Duplicate Lesson" }
                                    shadow
                                    size="base"
                                    disabled={isAllowed}
                                />
                                
                                {status === 'INACTIVE' && (
                                    <Button 
                                        onClick={() => this.handleClickActivate(record)} 
                                        textColor="white"
                                        bgColor="green"
                                        icon="upload"
                                        tooltip="Publish Lesson Program"
                                        shadow
                                        size="base"
                                    />
                                )}
                                {status === 'ACTIVE' && (
                                    <Button 
                                        onClick={() => this.handleClickArchive(record)} 
                                        icon="archive"
                                        bgColor="red"
                                        textColor="white"
                                        tooltip="Archive Lesson Program"
                                        shadow
                                        size="base"
                                    />
                                )}
                            </div>
                        </td>
                    </tr>
            );
        }) : null;
    }   

    getBgColorForStatus = (status) => {
        if (status === 'ARCHIVED') return 'bg-gray-500';
        if (status === 'ACTIVE') return 'bg-green-600';
        if (status === 'INACTIVE') return 'bg-blue-500';
        return null;
    }

    renderPrograms = (programs) => {
        if (programs) {
            return programs.map(p => {
                return (
                    <div className="p-4 rounded border mb-2">
                        {/* <span className="text-xs text-gray-600 font-bold uppercase">{p.swing_type_id === 1 ? 'Face On' : 'Down the Line'}</span> */}
                        <p className="text-gray-600 text-sm leading-tight"><span className="text-gray-600 font-bold uppercase leading-normal">{p.swing_type_id === 1 ? 'Face On' : 'Down the Line'}</span><br />{p.description}</p>
                    </div>
                )
            })
        }
        return null;
    }


    renderGrid = () => {
        const { licenseeCampaigns } = this.props;
        const searchTerm = this.state.term;
        // const isAllowed = licenseeCampaigns !== null && currentActiveLessons !== null
        //     ? (planInformation.lessonsAllowed > licenseeCampaigns.filter(lesson => lesson.status === 'ACTIVE').length)
        //     : false;

        return licenseeCampaigns 
            ? licenseeCampaigns
                .filter(record => record.display_name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
                .filter(record => this.state.type !== 'all' ? record.licensee_campaign_status.toLowerCase() === this.state.type : true)
                .sort((a,b) => (a.lexicon_name > b.lexicon_name) ? 1 : -1)
                .map((record, index) => {
                // get the date...
                const bgColor = this.getBgColorForStatus(record.licensee_campaign_status);
                // const programCheck = lessonContainsBothSwingTypes(record.programs);
                let icon = faArchive;
                switch(record.licensee_campaign_status) {
                    case 'ARCHIVED':
                        icon = faArchive;
                    break;
                    case 'ACTIVE':
                        icon = faLock;
                    break;
                    case 'INACTIVE':
                        icon = faPencilAlt;
                    break;

                    default:
                        icon = faPencilAlt;
                }

                return (
                    <div className={`flex flex-col cursor-pointer bg-white rounded overflow-hidden shadow justify-start`} key={index} onClick={() => this.handleClick(record)}>
                        <div className={`flex flex-col md:flex-row justify-start rounded-lg overflow-hidden w-full h-full md:justify-between`}>
                            <div className="flex flex-row space-x-4 overflow-ellipsis p-4 align-middle h-full">
                                <div className={`flex ${bgColor} rounded-md w-14 h-14 text-white text-center justify-items-center align-middle justify-center items-center text-xl`}>
                                    <FontAwesomeIcon icon={icon} className="group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                                </div>
                                <div className="flex flex-col text-xs align-middle justify-center">
                                    <div className="text-xl text-gray-800 font-bold whitespace-normal justify-start overflow-clip overflow-hidden">
                                        <p className="whitespace-nowrap truncate overflow-clip overflow-hidden">{record.display_name}</p>
                                    </div>
                                    <div className="text-xs text-gray-400">{convertDateTimeToDate(record.date_created).toLocaleDateString()} (<span className="font-bold text-gray-700"># {record.licensee_campaign_id}</span>)</div>
                                </div>
                            </div>
                            <div className="flex flex-col space-x-0 md:space-x-4 p-4 align-middle justify-start md:justify-end text-right">
                                <div className="text-xl text-gray-700 font-bold whitespace-normal text-left md:text-right">
                                    <p className="whitespace-normal justify-start md:justify-end text-left md:text-right">${record.licensee_campaign_price} USD</p>
                                </div>
                                <div className="flex flex-col justify-start md:justify-end whitespace-nowrap text-xs text-gray-400 p-0 text-left md:text-right">{getOutputStatus(record.licensee_campaign_status)} and {getLiveStatus(record.licensee_campaign_type)}</div>
                            </div>
                        </div>
                        <div className="flex border-t p-4 bg-gray-100 justify-start">
                            <div className="flex flex-row space-x-2 text-xs text-gray-600">
                                <span className="font-bold">{record.completed_count}</span>&nbsp;completed<br />
                                <span className="font-bold">{record.processing_count}</span>&nbsp;processing
                            </div>
                        </div>
                </div>
            );
        }) : null;
    }   

    renderArchiveModal = () => {
        const { 
            updateLicenseeCampaignStatusSuccess,
            updateLicenseeCampaignStatusLoading,
        } = this.props;
        return (
            <ModalTW 
                show={this.state.showArchiveModal}
            >
                {updateLicenseeCampaignStatusLoading === false && updateLicenseeCampaignStatusSuccess === null && (
                <div className="flex flex-col justify-between p-6 space-y-4">
                    <div className="flex flex-col mb-6 space-y-2">
                        {this.state.modalRecord && (
                            <h2 className="text-xl font-bold mb-2">Confirm Archive "{this.state.modalRecord.display_name}"</h2>
                        )}
                        <p className="font-medium">Are you sure you wish to Archive this Lesson Program?</p>
                        <ul className="list-inside list-disc">
                            <li>You will not be able to process videos using this Lesson Program</li>
                            <li>You will be able to Duplicate this Lesson Program</li>
                        </ul>
                    </div>
                    <div className="flex justify-end space-x-2">
                        <Button 
                            bgColor="gray"
                            hoverColor="gray"
                            onClick={this.handleCloseModal}
                            title="No, thanks"
                        />    
                        <Button 
                            bgColor="red"
                            hoverColor="red"
                            onClick={this.handleConfirmClickArchive}
                            title="Yes, Archive this Lesson Program"
                        />    
                    </div>
                </div>
                )}
                {updateLicenseeCampaignStatusLoading === true && (
                    <Loading title="Archiving Lesson Program" />
                )}
                {updateLicenseeCampaignStatusSuccess !== null && (
                    <div className="flex flex-col justify-between p-6 space-y-4">
                        <div className="flex flex-col mb-6 space-y-2">
                            <h2 className="text-xl font-bold mb-2">Archive Complete</h2>
                            <p>Your Lesson Program is now archived and can no longer process videos.</p>
                        </div>
                        <div className="flex justify-end space-x-2">
                            <Button 
                                bgColor="gray"
                                hoverColor="gray"
                                onClick={() => {
                                    this.props.history.go();
                                    this.handleCloseModal();
                                }}
                                title="Close Window"
                            />    
                        </div>
                    </div>
                )}
            </ModalTW>
        );
    }

    renderPublishModal = () => {
        const { 
            planInformation, 
            licenseeCampaigns,
            updateLicenseeCampaignStatusSuccess,
            updateLicenseeCampaignStatusLoading,
        } = this.props;
        const currentActiveLessons = licenseeCampaigns 
          ? licenseeCampaigns.filter(lesson => lesson.status === 'ACTIVE').length
          : null;
        const isAllowed = licenseeCampaigns !== null && currentActiveLessons !== null
          ? (planInformation.lessonsAllowed > licenseeCampaigns.filter(lesson => lesson.status === 'ACTIVE').length)
          : false;
        
        return (
            <ModalTW show={this.state.showPublishModal}>
                {isAllowed && updateLicenseeCampaignStatusLoading === false && updateLicenseeCampaignStatusSuccess === null && (
                    <div className="flex flex-col justify-between p-6 space-y-4">
                        <div className="flex flex-col mb-6 space-y-2">
                            <h2 className="text-xl font-bold mb-2">Confirm Publish "{this.state.modalRecord &&this.state.modalRecord.display_name}"</h2>
                            <p className="font-medium">Are you sure you wish to Publish this Lesson Program?</p>
                            <ul className="list-inside list-disc">
                                <li>You will not be able to make further changes.</li>
                                <li>You will be able to process videos with this Lesson Program</li>
                            </ul>
                        </div>
                        <div className="flex justify-end space-x-2">
                            <Button 
                                bgColor="gray"
                                hoverColor="gray"
                                onClick={this.handleCloseModal}
                                title="No, thanks"
                            />    
                            <Button 
                                bgColor="green"
                                hoverColor="green"
                                onClick={this.handleConfirmClickActivate}
                                title="Yes, Publish this Lesson Program"
                            />    
                        </div>
                    </div>
                )}
                {updateLicenseeCampaignStatusLoading === true && (
                    <Loading />
                )}
                {updateLicenseeCampaignStatusSuccess !== null && (
                    <div className="flex flex-col justify-between p-6 space-y-4">
                        <div className="flex flex-col mb-6 space-y-2">
                            <h2 className="text-xl font-bold mb-2">Publish Complete</h2>
                            <p>Your Lesson Program is now published and can process videos.</p>
                        </div>
                        <div className="flex justify-end space-x-2">
                            <Button 
                                bgColor="gray"
                                hoverColor="gray"
                                onClick={() => {
                                    this.props.history.go();
                                    this.handleCloseModal();
                                }}
                                title="Close Window"
                            />    
                        </div>
                    </div>
                )}
            </ModalTW>
        );
    }

    renderDuplicateModal = () => {
        const { 
            duplicateLicenseeCampaignLoading, 
            duplicateLicenseeCampaignSuccess,
            // duplicateLicenseeCampaignError,
            planInformation,
            licenseeCampaigns
        } = this.props;          
        const currentActiveLessons = licenseeCampaigns 
            ? licenseeCampaigns.filter(lesson => lesson.status === 'ACTIVE').length
            : null;
        const isAllowed = licenseeCampaigns !== null && currentActiveLessons !== null
            ? (planInformation.lessonsAllowed > licenseeCampaigns.filter(lesson => lesson.status === 'ACTIVE').length)
            : false;

        return (
            <ModalTW show={this.state.showDuplicateModal}>
                {duplicateLicenseeCampaignLoading === false && isAllowed === true && duplicateLicenseeCampaignSuccess === null && (
                    <div className="flex flex-col justify-between p-6 space-y-4">
                        <div className="flex flex-col mb-6 space-y-2">
                            {this.state.modalRecord && (
                                <h2 className="text-xl font-bold mb-2">Confirm Duplicate "{this.state.modalRecord.display_name}"</h2>
                            )}
                            <p className="font-medium">Are you sure you wish to Duplicate this Lesson Program?</p>
                            <ul className="list-inside list-disc">
                                <li>This will create an Unpublished copy of this Lesson Program</li>
                            </ul>
                        </div>
                        <div className="flex justify-end space-x-2">
                            <Button 
                                bgColor="gray"
                                hoverColor="gray"
                                onClick={this.handleCloseModal}
                                title="No, thanks"
                            />    
                            <Button 
                                bgColor="green"
                                hoverColor="green"
                                onClick={this.handleConfirmClickDuplicate}
                                title="Yes, Duplicate this Lesson Program"
                            />    
                        </div>
                    </div>
                )}
                {duplicateLicenseeCampaignLoading === true && (
                    <Loading />
                )}
                {duplicateLicenseeCampaignSuccess !== null && (
                    <div className="flex flex-col justify-between p-6 space-y-4">
                        <div className="flex flex-col mb-6 space-y-2">
                            <h2 className="text-xl font-bold mb-2">Duplication Complete</h2>
                        </div>
                        <div className="flex justify-end space-x-2">
                            <Button 
                                bgColor="gray"
                                hoverColor="gray"
                                onClick={() => {
                                    this.props.history.go();
                                    this.handleCloseModal();
                                }}
                                title="Close Window"
                            />    
                        </div>
                    </div>
                )}

            </ModalTW>
        );
    }

    renderLessonNavBar = () => {
        const { licenseeCampaigns } = this.props;
        const { type } = this.state;
        const types = {
            all: 0,
            active: 0,
            inactive: 0,
            archived: 0
        };

        if (licenseeCampaigns !== null) {
            licenseeCampaigns.forEach(lc => {
                if (lc !== undefined && 'licensee_campaign_status' in lc) {
                    types[lc.licensee_campaign_status.toLowerCase()] += 1;
                    types['all'] += 1;
                }
            });
        }

        return (
            <div className="flex flex-row space-x-2 mt-4">
                <div 
                    onClick={() => this.setState({ type: 'all' })} 
                    className={`rounded px-4 py-1 font-bold text-white text-xs cursor-pointer ${type === 'all' ? 'bg-blue-500': 'bg-gray-500'}`}
                >
                    All Programs {types.all}
                </div>
                <div 
                    onClick={() => this.setState({ type: 'active' })} 
                    className={`rounded px-4 py-1 font-bold text-white text-xs cursor-pointer ${type === 'active' ? 'bg-green-600': 'bg-gray-500'}`}
                >
                    Live Programs {types.active}
                </div>
                <div 
                    onClick={() => this.setState({ type: 'inactive' })} 
                    className={`rounded px-4 py-1 font-bold text-white text-xs cursor-pointer ${type === 'inactive' ? 'bg-blue-500': 'bg-gray-500'}`}
                >
                    Lessons in Progress {types.inactive}
                </div>
                <div 
                    onClick={() => this.setState({ type: 'archived' })} 
                    className={`rounded px-4 py-1 font-bold text-white text-xs cursor-pointer ${type === 'archived' ? 'bg-gray-500': 'bg-gray-500'}`}
                >
                    Archived {types.archived}
                </div>
            </div>
        );
    }

    renderLessonSideBar = () => {
        const { licenseeCampaigns } = this.props;
        const { type } = this.state;
        const types = {
            all: 0,
            active: 0,
            inactive: 0,
            archived: 0
        };

        if (licenseeCampaigns !== null) {
            licenseeCampaigns.forEach(lc => {
                if (lc !== undefined && 'licensee_campaign_status' in lc) {
                    types[lc.licensee_campaign_status.toLowerCase()] += 1;
                    types['all'] += 1;
                }
            });
        }

        return (
            <div className="flex flex-col space-y-2 p-2">
                {/* <div 
                    onClick={() => this.setState({ type: 'all' })} 
                    className={`rounded py-1 text-gray-500 text-sm cursor-pointer ${type === 'all' ? 'font-bold': 'font-medium hover:underline'}`}
                >
                    <FontAwesomeIcon icon={faArchive} className="group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150 mr-3" />
                    All Programs ({types.all})
                </div> */}
                <div 
                    onClick={() => this.setState({ type: 'active' })} 
                    className={`flex flex-row justify-between rounded py-1 text-gray-500 text-sm cursor-pointer ${type === 'active' ? 'font-bold': 'font-medium hover:underline'}`}
                >
                    <div className="flex flex-row align-middle items-center space-x-2">
                        <div className={`flex bg-green-500 rounded w-6 h-6 text-white text-center justify-items-center align-middle justify-center items-center text-xs`}>
                            <FontAwesomeIcon icon={faLock} className="group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                        </div>
                        <p>Live Programs ({types.active})</p>
                    </div>
                </div>
                <div 
                    onClick={() => this.setState({ type: 'inactive' })} 
                    className={`rounded py-1 text-gray-500 text-sm cursor-pointer ${type === 'inactive' ? 'font-bold': 'font-medium hover:underline'}`}
                >
                    <div className="flex flex-row align-middle items-center space-x-2">
                        <div className={`flex bg-blue-500 rounded w-6 h-6 text-white text-center justify-items-center align-middle justify-center items-center text-xs`}>
                            <FontAwesomeIcon icon={faPencilAlt} className="group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                        </div>
                        <p>Lessons in Progress ({types.inactive})</p>
                    </div>
                </div>
                <div 
                    onClick={() => this.setState({ type: 'archived' })} 
                    className={`rounded py-1 text-gray-500 text-sm cursor-pointer ${type === 'archived' ? 'font-bold': 'font-medium hover:underline'}`}
                >
                    <div className="flex flex-row align-middle items-center space-x-2">
                        <div className={`flex bg-gray-500 rounded w-6 h-6 text-white text-center justify-items-center align-middle justify-center items-center text-xs`}>
                            <FontAwesomeIcon icon={faArchive} className="group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                        </div>
                        <p>Archived ({types.archived})</p>
                    </div>
                </div>
            </div>
        );
    }

    getLoadingTitle = () => {
        const { 
            loadingLicenseeCampaigns,
            duplicateLicenseeCampaignLoading,
            updateLicenseeCampaignStatusLoading,
        } = this.props;
        let loadingTitle = "Loading";
        if (loadingLicenseeCampaigns === true) return "Loading Programs";
        if (duplicateLicenseeCampaignLoading === true) return "Creating Lesson Program";
        if (updateLicenseeCampaignStatusLoading === true) return "Updating Program";

        return loadingTitle;
    }

    render() {
        const { 
            licenseeCampaigns,
            loadingLicenseeCampaigns,
            duplicateLicenseeCampaignLoading,
            duplicateLicenseeCampaignSuccess,
            updateLicenseeCampaignStatusLoading,
            planInformation,
        } = this.props;
        const isLoading = (loadingLicenseeCampaigns === true || updateLicenseeCampaignStatusLoading === true || duplicateLicenseeCampaignLoading === true);
        console.log(planInformation);
        // button handler
        const dashboardAction = planInformation !== null && licenseeCampaigns !== null
            ? (planInformation.lessonsAllowed > licenseeCampaigns.length && planInformation.lessonsAllowed !== undefined
                ? this.handleCreateNew 
                : this.handleUpdatePlan
            ) : null;
        return (
            <DashboardContent 
                title="Lesson Programs"
                buttonTitle="New Lesson Program"
                buttonClassName="bg-green-300"
                onClick={dashboardAction}
                docPage={'/doc/lesson-program'}
            >
                <div className="flex flex-col w-full">
                {/* <SearchBar onChange={this.handleSearch} term={this.state.term} /> */}
                {planInformation !== null && licenseeCampaigns !== null && (
                    <LessonProgramsBanner 
                        textColor="gray"
                        color="lightgray"
                        buttonTitle="View Plan"
                        onClick={() => this.props.history.push({ pathname: '/account' })}
                        lessonsUsed={licenseeCampaigns.length}
                        lessonsAllowed={planInformation.lessonsAllowed}
                    />
                )}
                {licenseeCampaigns !== null && licenseeCampaigns.length > 0 && isLoading === false && (
                    <div className="flex flex-col lg:flex-row w-full space-x-0 lg:space-x-4 lg:space-x-4 mt-4">
                        <div className="w-full xl:w-1/4 lg:w-1/4 space-y-4 text-sm text-gray-600 mb-4">
                            <div className="bg-white text-sm rounded shadow p-4">
                                {this.renderLessonSideBar()}
                            </div>
                        </div>
                        <div className="flex flex-0 w-full lg:w-3/4 xl:w-3/4">
                            <div className="w-full flex space-y-4 flex-col">
                                {this.renderGrid()}
                            </div>
                        </div>
                    </div>
                )}
                {isLoading && (
                    <div className="mt-8 flex justify-center">
                        <Loading title={this.getLoadingTitle()} />
                    </div>
                )}
                {/* begin modal */}
                {licenseeCampaigns !== null && licenseeCampaigns.length === 0 && duplicateLicenseeCampaignLoading === false && (
                    <div className="bg-white rounded border border-gray-200 mt-4 shadow">
                        <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                        <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-700 sm:text-4xl sm:leading-10">
                            Ready to get started?
                            <br />
                            Create your first lesson program.
                        </h2>
                        {duplicateLicenseeCampaignLoading === false && duplicateLicenseeCampaignSuccess === null && (
                            <div className="mt-8 flex justify-center">
                                <div className="inline-flex rounded-md shadow">
                                <div onClick={this.handleCreateDuplicate} className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out cursor-pointer">
                                    Copy Our Lesson Program
                                </div>
                                </div>
                                <div className="ml-3 inline-flex">
                                <div onClick={this.handleCreateNew} className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-green-700 bg-green-100 hover:text-green-600 hover:bg-green-50 focus:outline-none focus:shadow-outline focus:border-green-300 transition duration-150 ease-in-out cursor-pointer">
                                    Create Your Own
                                </div>
                                </div>
                            </div>
                        )}
                        </div>
                    </div>
                )}
                </div>
                {this.renderArchiveModal()}
                {this.renderPublishModal()}
                {this.renderDuplicateModal()}
            </DashboardContent>
        );
    }
}

const mapStateToProps = ({ licenseeCampaign, auth }) => {
    const { authUser } = auth;
    const { 
        licenseeCampaigns,
        loadingLicenseeCampaigns,
        loadingLicenseeCampaignsError,
        duplicateLicenseeCampaignSuccess,
        duplicateLicenseeCampaignLoading,
        duplicateLicenseeCampaignError,
        updateLicenseeCampaignStatusSuccess,
        updateLicenseeCampaignStatusLoading,
        updateLicenseeCampaignStatusError
    } = licenseeCampaign;
    return {
        planInformation: authSelectors.planInformation(auth),
        lessons: licenseeCampaignSelectors.lessons(licenseeCampaign),
        authUser,
        licenseeCampaigns,
        duplicateLicenseeCampaignSuccess,
        duplicateLicenseeCampaignLoading,
        duplicateLicenseeCampaignError,
        loadingLicenseeCampaigns,
        loadingLicenseeCampaignsError,
        updateLicenseeCampaignStatusSuccess,
        updateLicenseeCampaignStatusLoading,
        updateLicenseeCampaignStatusError
    }
}

const mapDispatchToProps = {
    getLicenseeCampaignsByLicenseeId,
    duplicateLicenseeCampaign,
    updateLicenseeCampaignStatus,
    resetUpdateLicenseeCampaignStatus
}
const lessonsPageWithRouter = withRouter(LessonsPage);
export default connect(mapStateToProps, mapDispatchToProps)(lessonsPageWithRouter);
