import * as actionCreators from './actions';
import * as licenseeActionCreators from '../licensee/actions';
import * as services from './services';

export const createApiKey = (licenseeId) => dispatch => {
    dispatch(actionCreators.createApiKey(licenseeId));
    return services.createApiKey(licenseeId)
        .then(response => {
            // update the api redux
            dispatch(actionCreators.createApiKeySuccess(response.data));
            // also have to update the licensee data redux?
            dispatch(licenseeActionCreators.updateApiKeyValue(response.data.data));
        })
        .catch(e => dispatch(actionCreators.createApiKeyFail(e)));    
}

export const createApiKeyReset = () => dispatch => {
    dispatch(actionCreators.createApiKeyReset());
}