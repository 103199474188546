import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DashboardContent } from '../tailwind';
import { getUsersByLicenseeId } from '../../actions/user';
import { Loading, Pagination } from '../common';
import SearchBar from '../common/SearchBar';
import UserCard from './user/UserCard';
import { selectors as userSelectors } from '../../reducers/user';

// import { AutocompleteBar } from '../common/algolia/AutocompleteBar';
// import { UserItem } from '../common/algolia/templates/UserItem';
class UsersPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            limit: 50,
            offset: 0,
            pageNum: 1,
            term: ''
        }
    }

    componentDidMount = () => {
        const { authUser } = this.props;
        const { limit, offset } = this.state;
        this.props.getUsersByLicenseeId(authUser.licensee_id, limit, offset);
    }

    mysqlTimeStampToDate = (timestamp) => {
        // 2020-04-25T17:12:26.000Z
        // Split timestamp into [ Y, M, D, h, m, s ]
        var t = timestamp.split(/[- :]/);
        // Apply each element to the Date function
        var d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
        return d;
    }

    handlePageClick = (pageNum) => {
        if (pageNum > 0) {
            const { limit } = this.state;
            const offset = (limit * pageNum) - limit;
            const { authUser } = this.props;
            this.setState({ pageNum, offset });
            this.props.getUsersByLicenseeId(authUser.licensee_id, limit, offset);
        }
    }

    handleUserClick = (userId) => {
        const { authUser } = this.props;
        console.log('user click', userId);
        this.props.history.push({
            pathname: `/users/${userId}`,
            state: { 
                userId: userId,
                licenseeId: authUser.licensee_id
            }
        });
    }

    handleSearch = (e) => {
        this.setState({
            term: e.target.value
        });
    }

    /**
     * 
     *  cognito_id: ""
        date_created: "2020-05-20T15:01:10.000Z"
        email: "langolierlc@gmail.com"
        fb_token: ""
        licensee_id: 1
        mautic_id: 0
        nonce: "5dsuy9vc7n9x06vabuq6"
        oauth_id: ""
        oauth_provider: ""
        password: "d8402bede753b4ae25b292e32d197fd8a2f28b0d3511c3238f1f5600eaaf3441601ef7eff48271e951065e513dbd05b0e711f592551d57d2384aba7b0bd7d8dc"
        status: 1
        subscription_date: "2020-05-20T15:01:10.000Z"
        subscription_type_id: 1
        uploaded_filename: "48153_1590153833848_video.mp4"
        user_facebook_id: 0
        user_google_plus_id: 0
        user_id: 48153
        user_type_id: 1
        username: "cgordon"
     */
    
    renderGrid = (users) => {
        return users 
            ? users
                .filter(record => record.email.toLowerCase().indexOf(this.state.term) > -1)
                .map(record => <UserCard key={record.user_id} user={record} onClick={this.handleUserClick} />)
            : null;
    }

    goToUserDocumentation = () => {
        this.props.history.push({
            pathname: '/doc/golfers'
        });
    }


    handleSearchChange = (e) => {
        this.setState({
            term: e.state.query
        });
    }

    render() {
        const {
            users,
        } = this.props;
        return (
            <DashboardContent title="Golfers" docPage="/doc/golfers">
                {/* <AutocompleteBar
                    openOnFocus={false}
                    shouldPanelOpen={false}
                    onStateChange={(e) => console.log(e)}
                    getSources={({ query }) => [{
                        sourceId: 'users',
                        getItems() {
                            return users.filter(user => {
                                const firstName = user.f_name || "";
                                const email = user.email || "";
                                return (email.indexOf(query) > -1 || firstName.indexOf(query) > -1);
                            });
                        },
                        templates: {
                            item({ item, components }) {
                                return (
                                    <UserItem 
                                        email={item.email}
                                        userId={item.user_id}
                                        components={components} 
                                        history={userProps.history}
                                        licenseeId={licenseeId}
                                    />);
                            },
                        },
                    },
                    ]}
                /> */}
                {/* <SearchBar onChange={this.handleSearch} term={this.state.term} /> */}
                {users.loading === false && users.data !== null && (
                    <div>
                        {/* {users.data.length > 0 && (
                            <div className="mt-4">
                                <Pagination 
                                    total={users.count || 0}
                                    offset={this.state.offset}
                                    limit={this.state.limit}
                                    pageNum={this.state.pageNum}
                                    next={this.handlePageClick}
                                    prev={this.handlePageClick}
                                />
                            </div>
                        )} */}
                        {users.data.length > 0 && (
                            <div className="flex flex-col w-full mt-4">
                                <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                                    <div className="align-middle inline-block min-w-full overflow-hidden sm:rounded-lg">
                                    {users.data.length > 0 && (
                                        <div className="grid grid-cols-1 lg:grid-cols-1 gap-4">
                                            {this.renderGrid(users.data)}
                                        </div>
                                    )}
                                    {users.data.length === 0 && (
                                        <div className="bg-white rounded border border-gray-200 mt-4 shadow">
                                        <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                                        <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-700 sm:text-4xl sm:leading-10">
                                            No Golfers Found
                                        </h2>
                                        <h4 className="text-2xl leading-9 font-bold tracking-tight text-gray-500 sm:text-4xl sm:leading-10">A Golfer is associated with a video upload.</h4>
                                            <div className="mt-8 flex justify-center">
                                                <div className="inline-flex rounded-md shadow">
                                                    <div onClick={this.goToUserDocumentation} className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out cursor-pointer">
                                                        Learn More about Golfers
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {users.data.length > 0 && (
                            <div className="mt-4">
                                <Pagination 
                                    total={users.count || 0}
                                    offset={this.state.offset}
                                    limit={this.state.limit}
                                    pageNum={this.state.pageNum}
                                    next={this.handlePageClick}
                                    prev={this.handlePageClick}
                                />
                            </div>
                        )}
                    </div>
                )}
                {users.loading === true && <Loading />}
            </DashboardContent>
        )
    }
}
const mapStateToProps = ({ user, auth }) => {
    const { authUser } = auth;
    return {
        authUser,
        users: userSelectors.users(user),
    };
}

const mapDispatchToProps = {
    getUsersByLicenseeId
};
const usersPageWithRouter = withRouter(UsersPage);
export default connect(mapStateToProps, mapDispatchToProps)(usersPageWithRouter);