import * as services from './services';
import * as actionCreators from './actions';

export const getTargetInterpretations = () => dispatch => {
    dispatch(actionCreators.getTargetInterpretations());
    return services.getTargetInterpretations()
        .then(data => dispatch(actionCreators.getTargetInterpretationsSuccess(data.data)))
        .catch(e => dispatch(actionCreators.getTargetInterpretationsFail(e)));
}

export const getTargetInterpretationsForProgram = (programId) => dispatch => {
    dispatch(actionCreators.getTargetInterpretationsForProgram(programId));
    return services.getTargetInterpretationsForProgram(programId)
        .then(data => dispatch(actionCreators.getTargetInterpretationsForProgramSuccess(data.data)))
        .catch(e => dispatch(actionCreators.getTargetInterpretationsForProgramFail(e)));
}

export const getTargetInterpretationsForTarget = (targetId) => dispatch => {
    dispatch(actionCreators.getTargetInterpretationsForTarget(targetId));
    return services.getTargetInterpretationsForTarget(targetId)
        .then(data => dispatch(actionCreators.getTargetInterpretationsForTargetSuccess(data.data)))
        .catch(e => dispatch(actionCreators.getTargetInterpretationsForTargetFail(e)));
}
/**
 * createTargetInterpretationData
 * @param {object} data 
 */
export const createTargetInterpretationData = (data, history=null, historyPage=null) => dispatch => {
    dispatch(actionCreators.createTargetInterpretationData(data));
    return services.createTargetInterpretationData(data)
        .then(data => {
            dispatch(actionCreators.createTargetInterpretationDataSuccess(data.data));
            if (history) {
                if (historyPage !== null) {
                    history.goBack();
                } else {
                    // go to the previous page...
                    history.goBack();
                }
                // history.go(0);
            }
        })
        .catch(e => dispatch(actionCreators.createTargetInterpretationDataFail(e)));
}

export const resetCreateTargetInterpretationData = () => dispatch => {
    dispatch(actionCreators.resetCreateTargetInterpretationData());
}

export const resetRemoveTargetInterpretationFromTarget = () => dispatch => {
    dispatch(actionCreators.resetRemoveTargetInterpretationFromTarget());
}
/**
 * removeTargetInterpretation
 * We want to remove the target interpretation from a target
 * 
 * @param {int} licenseeId the id of the licensee
 * @param {int} programId the id of the program to remove from
 * @param {int} targetSummaryId the id of the target interpretation
 */
export const removeTargetInterpretationFromTarget = (userId, targetSummaryId, history = null, historyPage = 0) => dispatch => {
    dispatch(actionCreators.removeTargetInterpretationFromTarget(userId, targetSummaryId));
    return services.removeTargetInterpretationFromTarget(userId, targetSummaryId)
        .then(data => {
            dispatch(actionCreators.removeTargetInterpretationFromTargetSuccess(data.data));
            if (history !== null) {
                if (historyPage !== null) {
                    history.go(historyPage);
                } else {
                    // go to the previous page...
                    history.goBack();
                }
                // history.go(0);
            }
        }).catch(e => dispatch(actionCreators.removeTargetInterpretationFromTargetFail(e)));
}
