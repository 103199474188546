import React from 'react';
import {
    bodyPoints, 
    positions,
    lineTypes,
    lineColors,
} from '../../util/swingbot';


export const bodyPointOptions = (selectedBodyPointName = null, number = 1) => {
    return bodyPoints.map(bp => {
        return (
            <option 
                key={bp.name} 
                value={bp.name} 
            >
                {bp.display}
            </option>
        );
    });
}

export const positionOptions = (selectedPositionName = null, number = 1) => {
    return positions.map(p => {
        return (
            <option 
                key={p.name} 
                value={p.name} 
            >
                {p.display}
            </option>
        );
    });
}

export const lineTypeOptions = (selectedName = null, number = 1) => {
    return lineTypes.map(p => {
        // const selected = selectedName !== null 
        //     ? p.name === selectedName
        //     : false;
        // console.log('selected ', selected);
        return (
            <option 
                key={p.name} 
                value={p.name} 
                // selected={selected}
            >
                {p.display}
            </option>
        );
    });
}

export const lineColorOptions = (selectedName = null, number = 1) => {
    return lineColors.map(p => {
        // const selected = selectedName !== null 
        //     ? p.name === selectedName
        //     : false;
        return (
            <option 
                key={p.name} 
                value={p.name} 
                // selected={selected}
            >
                {p.display}
            </option>
        );
    });
}