import React, { useEffect } from 'react';
import { 
  AccountPage,
  ApiKeysPage, 
  VideosPage, 
  HomePage, 
  UsersPage, 
  SettingsPage,
  LoginPage,
  VideoDetailPage,
  UserDetailPage,
  ProgramDetailPage,
  LessonsPage,
  LessonDetailPage,
  OverviewPage,
  ActivatePage,
  TermsPage,
  LessonTargetDetailPage,
  LessonTargetCreatePage,
  LessonTargetLibraryPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  LessonProgramCreatePage,
  LogoutPage,
  InvitationPage,
  ApplicationAcceptPage,
  ApplicationDenyPage,
  CartPage,
  CheckoutPage,
  NotFoundPage,
  ErrorPage,
  SlackRedirect,
} from './components/dashboard';

import {
  WebsitePage,
  StripeLinkRefreshPage,
  StripeLinkReturnPage
} from './components/dashboard/website';

// Lessons
import {
  LessonTargetListPage
} from './components/dashboard/lesson';

import {
  GetStartedPage
} from './components/home';

import {
  GettingStartedSection,
} from './components/documentation/Documentation';

import block from './util/docs/components';

import {
  Switch,
  Route,
  useLocation
} from "react-router-dom";

import {
  CreateTrialEmail,
  CreateTrialPassword,
  ConfirmEmailAndRegister,
  ConfirmEmailPage
  // TemplateThirds
} from './components/mautic';

import TagManager from 'react-gtm-module'

// if the user is not authorized, send them to the login
import AuthorizedRoute from './components/AuthorizedRoute';
import AuthorizedRouteNoSub from './components/AuthorizedRouteNoSub';

// the menu structure that pulls in the documentation components
import { DocumentationMenuData } from './util/docs/documentation';

// mautic
import mautic from 'mautic-tracking';
mautic.initialize('https://mautic.swingbotpro.com/mautic/mtc.js');

// /**
//  * Google Analytics
//  */
TagManager.initialize({
  gtmId: process.env.REACT_APP_GA_TRACKING_ID
});

function usePageViews() {
  let location = useLocation()
  useEffect(
    () => {
      // add to the datalayer?
      TagManager.dataLayer({
        dataLayer: {
          page: location.pathname
        }
      });
      // Mautic tracking
      mautic.pageView({ page: encodeURIComponent(location.pathname) });
    },
    [location]
  )
}

function App() {
  usePageViews();
  return (
      <div className="App">
          <Switch>
            <Route path={'/doc'} exact={true} component={GettingStartedSection} />
            {DocumentationMenuData.map(menuItem => {
              return (
                <Route 
                  key={menuItem.route}
                  path={`/doc${menuItem.route}`} 
                  exact={true} 
                  component={block(menuItem)} 
                />
              );
            }).filter(item => item !== null)}
            {/* <Route path={'/t'} exact={true} component={TemplateThirds} /> */}
            <Route path={'/start'} exact={true} component={CreateTrialEmail} />
            <Route path={'/choose-password'} exact={true} component={CreateTrialPassword} />
            <Route path={'/confirm-email'} exact={true} component={ConfirmEmailPage} />
            <Route path={'/confirm-email-newsletter'} exact={true} component={ConfirmEmailAndRegister} />

            <Route path={'/logout'} exact={true} component={LogoutPage} />
            <Route path={'/login'} exact={true} component={LoginPage} />
            <Route path={'/register'} exact={true} component={LoginPage} />
            <Route path={'/forgot'} exact={true} component={ForgotPasswordPage} />
            <Route path={'/reset/:nonce'} exact={true} component={ResetPasswordPage} />
            <Route path={'/invitation/:nonce'} exact={true} component={InvitationPage} />
            <Route path={'/application/accept/:code'} exact={true} component={ApplicationAcceptPage} />
            <Route path={'/application/deny/:code'} exact={true} component={ApplicationDenyPage} />
            <Route path={'/activate'} exact={true} component={ActivatePage} />
            <Route path={'/activate/:code'} exact={true} component={ActivatePage} />
            <Route path={'/terms'} exact={true} component={TermsPage} />
            <AuthorizedRoute path={'/users' } exact={true} component={UsersPage} />
            <AuthorizedRoute path={'/users/:userId' } exact={true} component={UserDetailPage} />
            <AuthorizedRoute path={'/videos' } exact={true} component={VideosPage} />
            <AuthorizedRoute path={'/videos/:videoId' } exact={true} component={VideoDetailPage} />
            <AuthorizedRoute path={'/lessons' } exact={true} component={LessonsPage} />
            <AuthorizedRoute path={'/lessons/create' } exact={true} component={LessonProgramCreatePage} />
            <AuthorizedRoute path={'/lessons/:lessonId' } exact={true} component={LessonDetailPage} />
            <AuthorizedRoute path={'/lessons/targets/library' } exact={true} component={LessonTargetLibraryPage} />
            <AuthorizedRoute path={'/lessons/targets/create' } exact={true} component={LessonTargetCreatePage} />
            <AuthorizedRoute path={'/lessons/targets/:targetId' } exact={true} component={LessonTargetListPage} />
            <AuthorizedRoute path={'/lessons/targets/:targetId/:code' } exact={true} component={LessonTargetDetailPage} />
            <AuthorizedRoute path={'/lessons/:lessonId/:programId' } exact={true} component={ProgramDetailPage} />
            <AuthorizedRoute path={'/settings' } exact={true} component={SettingsPage} />
            <AuthorizedRoute path={'/api-keys' } exact={true} component={ApiKeysPage} />
            <AuthorizedRoute path={'/overview' } exact={true} component={OverviewPage} />
            <AuthorizedRouteNoSub path={'/account' } exact={true} component={AccountPage} />
            <AuthorizedRouteNoSub path={'/cart' } exact={true} component={CartPage} />
            <AuthorizedRouteNoSub path={'/checkout' } exact={true} component={CheckoutPage} />
            <AuthorizedRoute path={'/integrations/slack/oauth'} exact={true} component={SlackRedirect} />
            <AuthorizedRoute path={'/website' } exact={true} component={WebsitePage} />
            <Route path={'/website/stripe/return' } exact={true} component={StripeLinkReturnPage} />
            <Route path={'/website/stripe/refresh' } exact={true} component={StripeLinkRefreshPage} />
            <Route path={'/get-started'} exact={true} component={GetStartedPage} />
            <Route path={'/'} exact={true} component={HomePage} />
            <Route path={'/500'} exact={true} component={ErrorPage} />
            <Route component={NotFoundPage} />
        </Switch>
    </div>
  );
}

export default App;
