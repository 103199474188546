import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DashboardContent } from '../tailwind';
import { getProgramsForLicenseeCampaign } from '../../actions/program';
import { getTargetInterpretations } from '../../actions/targetInterpretation';
import { 
    analyzeVideo,
    resetAnalyzeVideo 
} from '../../actions/analyze';
import Loading from '../common/Loading';
import ModalConfirmAction from '../common/modal/ModalConfirmAction';
import TargetConditionsContainerList from '../common/analysis/TargetConditionsContainerList';
import { selectors as authSelectors } from '../../reducers/auth';

import { 
    getOperandByPseudo, 
    deconstructPseudo, 
    generatePseudo,
    getMaxTargetCondition,
    renderCodeFormat,
    getTargetSummaryIdFromTargetInterpretationData
} from '../../util/swingbot';
import { 
    getTargetInterpretationsForProgram, 
    createTargetInterpretationData,
    resetCreateTargetInterpretationData,
    getTargetInterpretationsForTarget,
} from '../../actions/targetInterpretation';
import { removeLineFromInterpretation } from '../../actions/line';
import { 
    removeTargetInterpretationFromTarget,
    resetRemoveTargetInterpretationFromTarget
} from '../../actions/targetInterpretation';
import { removeTargetFromProgram } from '../../actions/program';
import { addTargetFromLibrary } from '../../actions/target';
// we have to analyze a video to get the points for both FO and DTL....
import { Button } from '../common';
import Container from '../tailwind/Container';

class LessonTargetDetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            readOnly: this.setReadOnly(),
            isEditable: this.setIsEditable(),
            showRemoveConditionModal: false,
            selectedTab: this.props.match.params.code || null,
            selectedMainTab: null,
            selectedBodyPointTab: 1,
            selectedMenuItem: 1, // for target condition
            createNewCondition: null,
            ...this.initialState()
        }
    }

    setReadOnly = () => {
        try {
            return this.props.location.state.readOnly;
        } catch(e) {
            return false;
        }
    }

    setIsEditable = () => {
        try {
            return this.props.location.state.isEditable;
        } catch(e) {
            return false;
        }
    }

    initialState = () => {
        return {
            definition: this.props.location.state.targetData.text_definition,
            calculationConditionType: null,
            calculationConditionType1: null,
            calculationConditionType2: null,
            constantValue1: null, // only need 2, not allowed on 1
            constantValue2: null, // only need 2, not allowed on 1
            position1: null,
            position1Selected: null,
            bodyPoints1: null,
            position2: null,
            position2Selected: null,
            bodyPoints2: null,
            equationOperand: null,
            summary: '',
            lines: {},
            operand: null
        }
    }

    setTabContent = () => {
        try {
            // we have to set the first tab...
            const targetInterpretationData = this.getTargetInterpretationData();
            if (targetInterpretationData !== undefined && targetInterpretationData !== null) {
                const code = this.state.selectedTab === null
                    ? Object.keys(targetInterpretationData.equations)[0]
                    : this.state.selectedTab;
                const deconstructed = this.deconstructTargetInterpretation(targetInterpretationData, code);
                
                const equation = targetInterpretationData.equations[code];
                const lines = targetInterpretationData.lines[code];
                const summary = equation[0].summary;
                // set the state
                this.setState({ 
                    selectedTab: code,
                    bodyPoints1: deconstructed[0].bodyPoints1.bodyPoints,
                    bodyPoints2: deconstructed[0].bodyPoints2.bodyPoints,
                    position1: deconstructed[0].bodyPoints1.positions,
                    position2: deconstructed[0].bodyPoints2.positions,
                    calculationConditionType: deconstructed[0].bodyPoints1.calculationType,
                    calculationConditionType1: deconstructed[0].bodyPoints1.calculationType,
                    calculationConditionType2: deconstructed[0].bodyPoints2.calculationType,
                    operand: getOperandByPseudo(equation[0].operand),
                    constantValue1: deconstructed[0].bodyPoints1.constantValue || null, // only need 2, not allowed on 1
                    constantValue2: deconstructed[0].bodyPoints2.constantValue || null, // only need 2, not allowed on 1
                    summary: summary,
                    lines: lines
                });
            }
        } catch(e) {
        }
    }

    componentDidCatch = (e) => {
        this.props.history.push({
            pathname: '/500',
            state: e
        });
    }
    
    componentDidMount = () => {
        this.props.resetCreateTargetInterpretationData();
        this.props.resetAnalyzeVideo();
        this.props.resetRemoveTargetInterpretationFromTarget();

        // // we have to fetch the program target interpretations
        const targetId = this.props.location.state.targetId;
        if (targetId) {
            this.props.getTargetInterpretationsForTarget(targetId);
        }
    }

    componentDidUpdate = (nextProps) => {

        /**
         * we have to analyze the video!!!!
         */
        if (this.props.targetInterpretationData !== nextProps.targetInterpretationData) {
            const { targetData } = this.props.location.state;
            if (targetData !== null && targetData !== undefined) {
                const swingTypeId = targetData.swing_type_id;
                if (swingTypeId === 1) {
                    // face on lesson, lets analyze the following video
                    if (this.props.analyzeVideoLoading === false) {
                        this.props.analyzeVideo(22936);
                    }
                } else {
                    // for down the line, we have to choose a video...
                    if (this.props.analyzeVideoLoading === false) {
                        this.props.analyzeVideo(23016);
                    }
                }
                this.setTabContent();
            }
        }
    }

    ensureActiveItemVisible() {
        var itemComponent = this.refs.activeItem;
        if (itemComponent) {
          var domNode = React.findDOMNode(itemComponent);
          this.scrollElementIntoViewIfNeeded(domNode);
        }
    }

    handleRemoveProgram = () => {
        const { authUser, history } = this.props;
        const { targetData, programId } = this.props.location.state;
        const targetId = targetData.target_id || null;
        const userId = authUser.user_id;
        const historyPage = 1;
        if (targetId && programId && userId) {
            this.props.removeTargetFromProgram(
                targetId, 
                programId, 
                userId, 
                history, 
                historyPage
            );
        }
    }

    handleDefinitionChange = (definition) => {
        this.setState({
            definition: definition
        });
    }

    /**
     * The main FIRST calculation selected. 
     * This will set the tone for the rest of the application.
     */
    handleCalculationConditionChange = (calculationType) => {
        this.setState({
            calculationConditionType: calculationType,
            calculationConditionType1: calculationType,
            calculationConditionType2: calculationType,
            position1Selected: null,
            position2Selected: null,
            position1: null,
            bodyPoints1: null,
            position2: null,
            bodyPoints2: null,
            constantValue1: null,
            constantValue2: null,
            operand: null,
            equationOperand: null,
            summary: '',
            lines: []
        });
    }
    /**
     * from body points component
     */
    handleCalculationTypeChange = (calculationType, number) => {
        if (calculationType !== null) {
            const isConstant = calculationType.pseudo === 'CONSTANT';
            if (isConstant === true) {
                this.setState({
                    [`calculationConditionType${number}`]: calculationType,
                    [`constantValue${number}`]: null
                });
            } else {
                this.setState({
                    [`calculationConditionType${number}`]: calculationType,
                    [`constantValue${number}`]: null
                });
            }
        } else {
            this.setState({
                [`calculationConditionType${number}`]: null,
                [`constantValue${number}`]: null
            });
        }

    }

    handleClickPosition = (positionData) => {
        const { number, position } = positionData;
        this.setState({
            [`position${number}`]: position,
        })
    }

    handleConfirmBodyPoints = (data) => {
        try {
            const number = data.number;
            this.setState({
                [`calculationConditionType${number}`]: data.calculationType,
                [`bodyPoints${number}`]: data.bodyPoints,
                [`position${number}`]: data.positions,
                [`pseudo${number}`]: data.pseudo,
                [`constantValue${number}`]: data.constantValue,
                run: new Date().getTime()
            }, () => {});
        } catch (e) {
        }
    }

    handleConfirmEquation = (equationData) => {
        this.setState({
            operand: equationData.operand,
        });
    }

    handleConstantChange = (value, number) => {
        if (value !== undefined && value !== null) {
            this.setState({
                [`constantValue${number}`]: value,
            });
        } else {
            this.setState({
                [`constantValue${number}`]: null,
            });
        }
    }

    handleSummaryOnChange = (text) => {
        this.setState({
            summary: text
        });
    }

    handleLineChange = (lines) => {
        this.setState({
            lines: lines
        });
    }

    handleSaveAndContinue = () => {
        try {
            // get this from previous page
            const { 
                targetData, 
                readOnly, 
                sourceProgramId, 
            } = this.props.location.state;
            const targetId = targetData.target_id || null;
            const swingTypeId = targetData.swing_type_id || null;
            const targetLexiconName = targetData.lexicon_name;
            const userId = this.props.authUser.user_id;
            const {
                definition,
                calculationConditionType1,
                calculationConditionType2,
                constantValue1,
                constantValue2,
                position1,
                bodyPoints1,
                position2,
                bodyPoints2,
                operand,
                summary,
                lines,
            } = this.state;
            const { history } = this.props;

            // set the constants here
            const c1 = constantValue1 !== undefined ? constantValue1 : null;
            const c2 = constantValue2 !== undefined ? constantValue2 : null;

            // we need to generate the pseudo for the calculations...
            const pseudo1 = generatePseudo(
                calculationConditionType1, 
                bodyPoints1, 
                position1[1], 
                c1
            );
            const pseudo2 = generatePseudo(
                calculationConditionType2, 
                bodyPoints2, 
                c2 !== null ? c2 : position2[1], 
                c2
            );

            // create the object to pass to the createTargetInterpretation object...
            const dataToSend = {
                definition,
                targetId,
                userId,
                targetLexiconName,
                swingTypeId,
                constantValue1: c1,
                constantValue2: c2,
                position1,
                bodyPoints1,
                position2,
                bodyPoints2,
                equationOperand: operand,
                summary,
                lines,
                code: this.state.selectedTab,
                pseudo1,
                pseudo2
            };

            if (readOnly === false) {
                this.props.createTargetInterpretationData(dataToSend, history);
            } else {
                const historyPage = 2; // go back 1 page...
                this.props.addTargetFromLibrary(
                    targetId, 
                    sourceProgramId,
                    userId, 
                    history, 
                    historyPage
                );
            }

        } catch(e) {
        }
    }

    handleChangeTab = (code) => {
        this.setState({
            selectedTab: code,
            createNewCondition: null,
            calculationConditionType: null,
            calculationConditionType1: null,
            calculationConditionType2: null,
            constantValue1: null,
            constantValue2: null,
            position1: null,
            bodyPoints1: null,
            position2: null,
            bodyPoints2: null,
            equationOperand: null,
            summary: null,
            lines: []
        }, () => {
            this.setTabContent();
        });
    }

    handleCreateTab = (type) => {
        const targetInterpretationData = this.getTargetInterpretationData();
        // we have to determine WHAT 
        let newCondition = null;
        newCondition = getMaxTargetCondition(type, targetInterpretationData);
        // make sure we have a condition here 
        if (newCondition !== null) {
            this.setState({
                selectedTab: newCondition.code,
                selectedMainTab: this.state.selectedMainTab,
                createNewCondition: newCondition,
                ...this.initialState()
            });
        }
    }

    /**
     * handleRemoveConditionConfirm
     * @param {int} targetSummaryId the target summary id
     */
    handleRemoveConditionConfirm = (targetSummaryId) => {
        const { authUser } = this.props;
        this.props.removeTargetInterpretationFromTarget(
            authUser.user_id, 
            targetSummaryId, 
            this.props.history,
            null
        );
    }

    handleRemoveCondition = () => {
        this.setState({
            showRemoveConditionModal: true,
        });
    }


    /**
     * based on the calculation type selected, we do NOT 
     * want the user to select particular things...
     * 
     * for example, if "compare-distance", we want to keep the SAME
     * body points for both comparisons, and different positions...
     */
    determineDataForCalculationType = (data) => data;

    hasCode = (code, targetInterpretationData) => {
        if (targetInterpretationData !== undefined && targetInterpretationData !== null) {
            if( code in targetInterpretationData.equations) {
                return true;
            }
        }
        return false;
    }
    
    renderNoTargets = () => {
        return (
            <Container className="bg-gray-800 ">
                <div className="w-full">
                    <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                        <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10 text-white">
                        Lesson Target Conditions
                        <br />
                        <span className="text-gray-300 text-xl font-normal">A Lesson Target consists of at least one passing and one failing condition.</span>
                        </h2>
                        <div className="mt-8 flex justify-center">
                        <div className="inline-flex rounded-md shadow space-x-2">
                            <Button 
                                title="Click to Create Passing Condition" 
                                onClick={() => this.handleCreateTab('pass')} 
                                bgColor="green"
                                hoverColor="green"
                                size="lg"
                            />
                            <Button 
                                title="Click to Create Failing Condition" 
                                onClick={() => this.handleCreateTab('fail')}
                                bgColor="red"
                                hoverColor="red"
                                size="lg"
                            />
                        </div>
                        </div>
                    </div>
                    </div>
            </Container>
        );
    }

    renderTargetEquations = (code, isLoading) => {
        const { readOnly, isEditable } = this.state;
        const targetInterpretationData = this.getTargetInterpretationData();

        // manipulate the data properly based on the calculation type
        const stateData = this.determineDataForCalculationType(this.state);
        const { 
            history,
            createTargetInterpretationSuccess,
            createTargetInterpretationLoading,
            createTargetInterpretationError
        } = this.props;
        const passingText = code !== null 
            ? (code.substr(0,1) === 'P' ? 'passing' : 'failing') 
            : null;
        return (
            stateData !== null && 
            this.props.analyzeVideoSuccess !== null && 
            this.props.analyzeVideoSuccess !== undefined && 
            code !== null
        )
            ? (
                <div key={code} className="flex flex-col h-full w-full">
                    <Container className="border-transparent shadow-none">
                        <h1>
                            Instructions
                        </h1>
                        <div className="">
                            Compare two assessments so that when evaluated, the result is a <span className="font-bold">{passingText}</span> condition.
                        </div>
                    </Container>
                    <TargetConditionsContainerList
                        run={new Date().getTime()}
                        licenseeCampaignStatus={this.props.location.state.status}
                        code={code}
                        history={history}
                        readOnly={readOnly} 
                        stateData={stateData} 
                        isEditable={isEditable}
                        targetInterpretationData={targetInterpretationData}
                        analysisResults={this.props.analyzeVideoSuccess}
                        lines={stateData.lines}
                        onConditionCreate={this.handleCreateTab}
                        onConstantChange={this.handleConstantChange}
                        onConfirmBodyPoints={this.handleConfirmBodyPoints}
                        onCalculationTypeChange={this.handleCalculationTypeChange}
                        onConfirmEquation={this.handleConfirmEquation}
                        onSelectCode={this.handleChangeTab}
                        onLineChange={this.handleLineChange}
                        onSummaryChange={this.handleSummaryOnChange}
                        onClickSaveAndContinue={this.handleSaveAndContinue}
                        isSaving={createTargetInterpretationLoading}
                        isSavingSuccess={createTargetInterpretationSuccess}
                        isSavingError={createTargetInterpretationError}
                    />
                </div>
            ) : (
                <div className="h-full w-full flex-grow flex flex-col">
                    {code === null && isLoading === false && (
                        <div>
                            {this.renderNoTargets()}
                        </div>
                    )}
                    {code !== null && (
                        <TargetConditionsContainerList 
                            code={code}
                            history={history}
                            readOnly={readOnly} 
                            stateData={stateData} 
                            isEditable={isEditable}
                            targetInterpretationData={targetInterpretationData}
                            analysisResults={this.props.analyzeVideoSuccess}
                            lines={stateData.lines}
                            onConditionCreate={this.handleCreateTab}
                            onConstantChange={this.handleConstantChange}
                            onConfirmBodyPoints={this.handleConfirmBodyPoints}
                            onCalculationTypeChange={this.handleCalculationTypeChange}
                            onConfirmEquation={this.handleConfirmEquation}
                            onSelectCode={this.handleChangeTab}
                            onLineChange={this.handleLineChange}
                            onSummaryChange={this.handleSummaryOnChange}
                            onClickSaveAndContinue={this.handleSaveAndContinue}
                        />
                    )}
                </div>
            );
    }

    renderTargetCodes = () => {
        const { readOnly, isEditable } = this.state;
        const targetInterpretationData = this.getTargetInterpretationData();
        
        // manipulate the data properly based on the calculation type
        const stateData = this.determineDataForCalculationType(this.state);

        const { history } = this.props;
        return (
            stateData !== null && 
            this.props.analyzeVideoSuccess !== null && 
            this.props.analyzeVideoSuccess !== undefined
        )
            ? Object.keys(targetInterpretationData.equations)
                .sort()
                .map(code => (
                    <div key={code} className="">
                        <TargetConditionsContainerList
                            code={code}
                            history={history}
                            readOnly={readOnly} 
                            stateData={stateData} 
                            isEditable={isEditable}
                            targetInterpretationData={targetInterpretationData}
                            analysisResults={this.props.analyzeVideoSuccess}
                            onConditionCreate={this.handleCreateTab}
                            onConstantChange={this.handleConstantChange}
                            onConfirmBodyPoints={this.handleConfirmBodyPoints}
                            onCalculationTypeChange={this.handleCalculationTypeChange}
                            onConfirmEquation={this.handleConfirmEquation}
                            onSelectCode={this.handleChangeTab}
                            onLineChange={this.handleLineChange}
                            onSummaryChange={this.handleSummaryOnChange}
                            onClickSaveAndContinue={this.handleSaveAndContinue}
                        />
                    </div>
                )
            ) : null;
    }
    /**
     * we want to rip apart the target interpretation in order to 
     * populate the panels for editing...if applicable.
     */
    deconstructTargetInterpretation = (targetData, code) => {
        // we want to deconstruct the equations pseudo for each calculation..
        const equations = targetData.equations;
        const deconstructed = equations[code].map(equation => {
            const calculation_1 = equation.calculation_1_pseudo;
            const calculation_2 = equation.calculation_2_pseudo;
            const bodyPoints1 = deconstructPseudo(calculation_1);
            const bodyPoints2 = deconstructPseudo(calculation_2);
            return { bodyPoints1, bodyPoints2 };
        });
        return deconstructed;
    }

    getTargetInterpretationData = (data = null) => {
        const { targetInterpretationData } = this.props;
        const { targetData } = this.props.location.state;
        if (targetInterpretationData !== null && Object.keys(targetInterpretationData).length > 0) {
            return targetInterpretationData[targetData.lexicon_name];
        }
        return null;
    }

    handleClickConditionMenu = (itemIndex) => {
        this.setState({
            selectedMenuItem: itemIndex
        });
    }

    renderMainTabs = () => {
        const selectedClass = 'group inline-flex items-center py-4 px-1 border-b-2 border-green-600 font-bold text-base leading-5 text-gray-700 focus:outline-none focus:text-gray-700 focus:border-gray-300 cursor-pointer';
        const unselectedClass = 'group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-base leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 cursor-pointer';
             
        return (
            <div className="flex flex-row rounded mb-4">                        
                <div className="sm:hidden">
                    <select aria-label="Selected tab" className="form-select block w-full">
                        <option>Target Conditions</option>
                        <option>Target Definition</option>
                    </select>
                </div>
                <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                    <nav className="flex -mb-px">
                        <div onClick={() => this.handleClickConditionMenu(2)} className={`ml-8 ${this.state.selectedMenuItem === 2 ? selectedClass : unselectedClass}`}>
                            <svg className="-ml-0.5 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clipRule="evenodd" />
                            </svg>
                            <span>Target Conditions</span>
                        </div>
                        <div onClick={() => this.handleClickConditionMenu(1)} className={`${this.state.selectedMenuItem === 1 ? selectedClass : unselectedClass}`}>
                            <svg className="-ml-0.5 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                            </svg>
                            <span>Target Definition</span>
                        </div>
                    </nav>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { targetData, status } = this.props.location.state;
        const { 
            readOnly,
            getTargetInterpretationProgramLoading,
            createTargetInterpretationLoading,
            getTargetInterpretationTargetLoading,
            removeTargetInterpretationLoading,
            removeTargetInterpretationSuccess,
            removeTargetInterpretationError,
        } = this.props;
        
        const { code } = this.props.match.params;
        const codeName = renderCodeFormat(code);
        const isLoading = (getTargetInterpretationProgramLoading === true || createTargetInterpretationLoading === true || getTargetInterpretationTargetLoading === true || removeTargetInterpretationLoading === true);
        const targetInterpretationData = this.getTargetInterpretationData();
        const targetSummaryId = getTargetSummaryIdFromTargetInterpretationData(targetInterpretationData, code);

            return  (
            <DashboardContent 
                title={isLoading === true ? null : targetData.display_name} 
                subtitle={codeName}
                backTitle="back"
                buttonTitle={readOnly === false && status.toLowerCase() === 'inactive' ? `Remove ${codeName}` : null}
                buttonClassColor="red"
                onClick={() => this.handleRemoveCondition(targetSummaryId)}
            >
                <div className="flex w-full h-full">
                    <div className="flex w-full h-full">
                        <div className="flex flex-col min-h-full w-full h-full">
                            <div className="flex-1 flex-col h-full">
                                {this.renderTargetEquations(code, isLoading)}
                            </div>
                        </div>
                    </div>
                </div>
                {isLoading === true && (<Container><Loading /></Container>)}
                <ModalConfirmAction 
                    show={this.state.showRemoveConditionModal}
                    title="Confirm Target Condition Removal"
                    buttonTitleConfirm="Yes, delete this Target Condition"
                    buttonTitleCancel="Cancel"
                    body="Are you sure you wish to delete this Target Condition? This action cannot be undone."
                    history={this.props.history}
                    onCancel={() => {
                        this.setState({
                            showRemoveConditionModal: false
                        });
                    }}
                    onConfirm={() => {
                        this.handleRemoveConditionConfirm(targetSummaryId);
                    }}
                    isLoading={removeTargetInterpretationLoading}
                    isSuccess={removeTargetInterpretationSuccess}
                    isError={removeTargetInterpretationError}
                />
            </DashboardContent>
        );
    }
}

const mapStateToProps = ({ program, auth, targetInterpretation, licensee, analyze, line }) => {
    const { 
        programData,
        programsForCampaign,
        getProgramsForCampaignLoading,
        getProgramsForCampaignError,
    } = program;
    const {
        analyzeVideoLoading,
        analyzeVideoSuccess,
        analyzeVideoError
    } = analyze;
    const { 
        licenseeData
    } = licensee;
    const { 
        targetInterpretations,
        getTargetInterpretationProgramLoading,
        getTargetInterpretationProgramError,
        createTargetInterpretationSuccess,
        createTargetInterpretationLoading,
        createTargetInterpretationError,
        targetInterpretationTargetData,
        getTargetInterpretationTargetLoading,
        getTargetInterpretationTargetError,
        removeTargetInterpretationSuccess,
        removeTargetInterpretationLoading,
        removeTargetInterpretationError,
     } = targetInterpretation;
    const { authUser } = auth;
    const { 
        removeLineFromInterpretationLoading,
        removeLineFromInterpretationSuccess,
        removeLineFromInterpretationError
    } = line;
    return {
        planInformation: authSelectors.planInformation(auth),
        programData,
        programsForCampaign,
        getProgramsForCampaignLoading,
        getProgramsForCampaignError,
        authUser,
        targetInterpretations,
        targetInterpretationData: targetInterpretationTargetData,
        getTargetInterpretationProgramLoading,
        getTargetInterpretationProgramError,
        licenseeData,
        analyzeVideoLoading,
        analyzeVideoSuccess,
        analyzeVideoError,
        createTargetInterpretationSuccess,
        createTargetInterpretationLoading,
        createTargetInterpretationError,
        removeLineFromInterpretationLoading,
        removeLineFromInterpretationSuccess,
        removeLineFromInterpretationError,
        targetInterpretationTargetData,
        getTargetInterpretationTargetLoading,
        getTargetInterpretationTargetError,
        removeTargetInterpretationSuccess,
        removeTargetInterpretationLoading,
        removeTargetInterpretationError,
    };
}

LessonTargetDetailPage.defaultProps = {
    readOnly: false, // this should be false always...
    targetInterpretationData: null,
}

const mapDispatchToProps = {
    getProgramsForLicenseeCampaign,
    getTargetInterpretations,
    analyzeVideo,
    getTargetInterpretationsForProgram,
    createTargetInterpretationData,
    removeTargetFromProgram,
    resetCreateTargetInterpretationData,
    resetAnalyzeVideo,
    removeLineFromInterpretation,
    addTargetFromLibrary,
    getTargetInterpretationsForTarget,
    removeTargetInterpretationFromTarget,
    resetRemoveTargetInterpretationFromTarget
}

const lessonTargetDetailPageWithRouter = withRouter(LessonTargetDetailPage);
export default connect(mapStateToProps, mapDispatchToProps)(lessonTargetDetailPageWithRouter);