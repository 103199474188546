import React from 'react';

class ImageWithError extends React.Component {
    render() {
        const { 
            className,
            src,
            alt,
            errorSrc 
        } = this.props;
        return (
            <img 
                className={className} 
                src={src} 
                alt={alt}
                onError={(e)=>{
                    e.target.onerror = null; 
                    e.target.src = errorSrc;
                }}
            />
        );
    }
}

ImageWithError.defaultProps = {
    errorSrc: require("../../img/logo.png"),
}

export default ImageWithError;