
import * as actionTypes from '../actions/analyze/types';
import * as authActionTypes from '../actions/auth/actionTypes';

const initialState = {
    analyzeVideoLoading: false,
    analyzeVideoSuccess: null,
    analyzeVideoError: null,

    convertCalculationToEnglishLoading: false,
    convertCalculationToEnglishSuccess: null,
    convertCalculationToEnglishError: null,

    convertEquationToEnglishLoading: false,
    convertEquationToEnglishSuccess: null,
    convertEquationToEnglishError: null
};

function analyze(state = initialState, action) {
    switch(action.type) {

        case authActionTypes.LOGOUT_USER:
            return {
                ...state,
                analyzeVideoLoading: false,
                analyzeVideoSuccess: null,
                analyzeVideoError: null,

                convertCalculationToEnglishLoading: false,
                convertCalculationToEnglishSuccess: null,
                convertCalculationToEnglishError: null,

                convertEquationToEnglishLoading: false,
                convertEquationToEnglishSuccess: null,
                convertEquationToEnglishError: null
            }

        case actionTypes.ANALYZE_VIDEO:
            return {
                ...state,
                analyzeVideoLoading: true,
                analyzeVideoSuccess: null,
                analyzeVideoError: null
            };

        case actionTypes.ANALYZE_VIDEO_SUCCESS:
            return {
                ...state,
                analyzeVideoLoading: false,
                analyzeVideoSuccess: action.payload,
                analyzeVideoError: null
            };

        case actionTypes.ANALYZE_VIDEO_FAIL:
            return {
                ...state,
                analyzeVideoLoading: false,
                analyzeVideoSuccess: null,
                analyzeVideoError: action.payload
            };

        case actionTypes.ANALYZE_VIDEO_RESET:
            return {
                ...state,
                analyzeVideoLoading: false,
                analyzeVideoSuccess: null,
                analyzeVideoError: null
            };

        case actionTypes.CONVERT_CALCULATION_TO_ENGLISH:
            return {
                ...state,
                convertCalculationToEnglishLoading: false,
                convertCalculationToEnglishSuccess: null,
                convertCalculationToEnglishError: null
            };

        case actionTypes.CONVERT_CALCULATION_TO_ENGLISH_SUCCESS:
            return {
                ...state,
                convertCalculationToEnglishLoading: false,
                convertCalculationToEnglishSuccess: action.payload,
                convertCalculationToEnglishError: null
            };

        case actionTypes.CONVERT_CALCULATION_TO_ENGLISH_FAIL:
            return {
                ...state,
                convertCalculationToEnglishLoading: false,
                convertCalculationToEnglishSuccess: null,
                convertCalculationToEnglishError: action.payload
            };
        
        case actionTypes.CONVERT_EQUATION_TO_ENGLISH:
            return {
                ...state,
                convertEquationToEnglishLoading: false,
                convertEquationToEnglishSuccess: null,
                convertEquationToEnglishError: null
            };

        case actionTypes.CONVERT_EQUATION_TO_ENGLISH_SUCCESS:
            return {
                ...state,
                convertEquationToEnglishLoading: false,
                convertEquationToEnglishSuccess: action.payload,
                convertEquationToEnglishError: null
            };

        case actionTypes.CONVERT_EQUATION_TO_ENGLISH_FAIL:
            return {
                ...state,
                convertEquationToEnglishLoading: false,
                convertEquationToEnglishSuccess: null,
                convertEquationToEnglishError: action.payload
            };
        default:
            return state;
    }
}

export default analyze;