import React from 'react';
import { connect } from 'react-redux';
import { DashboardContent, Container } from '../tailwind';
import { getUserDataById } from '../../actions/user';
import { getLicenseeAnalysesForUser } from '../../actions/licensee';
import { updateUserProfile, getLicenseeUserDataById, getLicenseeUserDataByIdReset } from '../../actions/user';
import { getLicenseeCampaignsByLicenseeId } from '../../actions/licenseeCampaign';
import { selectors as userSelectors } from '../../reducers/user';
import { selectors as licenseeSelectors } from '../../reducers/licensee';
import { selectors as licenseeCampaignSelectors } from '../../reducers/licenseeCampaign';
import { Button, LearnMore, Loading } from '../common';
import VideoPanel from './video/VideoPanel';
import ModalAssignLessonCredit from './modals/ModalAssignLessonCredit';
import LessonCreditCard from './lesson/LessonCreditCard';
class UserDetailPage extends React.Component {

    constructor(props) {
        super(props);
        console.log('constructor ', props);
        this.state = {
            selectedMainTab: 'details',
            modalAssignCredit: false,
            assignLessonSelected: null,
            userDetails: props.userDetails,
            userDetailsDirty: {
                firstNameDirty: '',
                lastNameDirty: ''
            },
            firstNameDirty: '',
            lastNameDirty: '',
            email: props.userDetails['email'] || ''
        }
    }

    componentDidUpdate = (nextProps) => {
        if (nextProps.userDetails !== this.props.userDetails) {
            this.setState({
                userDetails: this.props.userDetails,
                userDetailsDirty: this.props.userDetails,
                firstNameDirty: this.props.userDetails['firstName'],
                lastNameDirty: this.props.userDetails['lastName'],
                email: this.props.userDetails['email']
            });
        }
    }

    componentDidMount = () => {
        // fetch the user based on the state!
        const { authUser } = this.props;
        const { userId } = this.props.match.params;
        const licenseeId = authUser['licensee_id'];
        // we want to use a different method to get this user id
        // we want to reset the details prior 
        this.props.getLicenseeUserDataByIdReset();
        this.props.getLicenseeAnalysesForUser(licenseeId, userId);
        this.props.getLicenseeUserDataById(userId, licenseeId);
    }

    componentWillUnmount = () => {
        this.props.getLicenseeUserDataByIdReset();
    }

    handleVideoClick = (videoId, videoData) => {
        this.props.history.push({
            pathname: `/videos/${videoId}`,
            state: { detail: videoData }   
        });
    }

    renderPanels = () => {
        const { analysesForUser } = this.props;
        return analysesForUser ?
            analysesForUser.data
                .filter(record => record.status === 'COMPLETED')
                .map((record, index) => {
                    return (
                        <VideoPanel
                            key={record.video_id}
                            record={record}
                            onVideoClick={this.handleVideoClick}
                        />
                    )
                }) : null;
    }

    handleLessonCreditClick = (videoId, videoData) => {
        this.props.history.push({
            pathname: `/videos/${videoId}`,
            state: { detail: videoData }   
        });
    }

    renderLessonCredits = () => {
        const { analysesForUser } = this.props;
        console.log(analysesForUser);
        const filtered = analysesForUser.data !== undefined 
            ? analysesForUser.data.filter(record => record.status !== 'COMPLETED')
            : [];
        return filtered.length > 0 
            ? filtered.map(record => <LessonCreditCard key={record.credit_code} lessonCredit={record} />) 
            : (
                <div className="bg-gray-800 rounded mt-4">
                    <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                    <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-white sm:text-4xl sm:leading-10 mb-2">
                        No Lesson Credits Available
                    </h2>
                    <p className="text-base text-gray-200">By assigning a Lesson Credit, this user will be able to upload their swing video for analysis with the Lesson Program selected.</p>
                        <div className="mt-8 flex justify-center">
                            <div className="inline-flex rounded-md shadow">
                                <Button
                                    title="Click to Assign a Credit"
                                    bgColor="green"
                                    hoverColor="green"
                                    size="lg"
                                    onClick={this.handleAssignCredit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
    }

    getCounts = () => {
        const { analysesForUser } = this.props;
        if (analysesForUser !== null && analysesForUser !== undefined) {
            const completedCount = analysesForUser && analysesForUser.data.length > 0
            ? analysesForUser.data.filter(record => record.status === 'COMPLETED').length
            : 0;
            const creditCount = analysesForUser && analysesForUser.data.length > 0
                ? analysesForUser.data.filter(record => record.status !== 'COMPLETED').length
                : 0;
            return {
                completed: completedCount,
                credits: creditCount
            }
        }
        return null;
    }

    renderMainTabsSide = () => {
        const { selectedMainTab } = this.state;
        const counts = this.getCounts();
        const currentClass = "font-bold bg-gray-100 text-gray-700 flex items-center px-3 py-2 text-sm font-medium rounded-md";
        const defaultClass = "cursor-pointer text-gray-600 hover:bg-gray-50 hover:text-gray-900 flex items-center px-3 py-2 text-sm font-medium rounded-md";
        return counts !== null ? (
            <div className="bg-white text-sm rounded-lg shadow p-4">
                <div className="">
                    <nav className="-mb-px flex flex-col">
                        <div className={selectedMainTab === 'details' ? currentClass : defaultClass } onClick={() => this.setState({ selectedMainTab: 'details' })}>
                            User Profile
                        </div>
                        {/* <div className={selectedMainTab === 'messages' ? currentClass : defaultClass } onClick={() => this.setState({ selectedMainTab: 'messages' })}>
                            Messages
                        </div> */}
                        <div className={selectedMainTab === 'videos' ? currentClass : defaultClass } onClick={() => this.setState({ selectedMainTab: 'videos' })}>
                            Processed Videos ({counts['completed']})
                        </div>
                        <div className={selectedMainTab === 'credits' ? currentClass : defaultClass } onClick={() => this.setState({ selectedMainTab: 'credits' })}>
                            Lesson Credits ({counts['credits']})
                        </div>
                        {/* <div className={selectedMainTab === 'admin' ? currentClass : defaultClass } onClick={() => this.setState({ selectedMainTab: 'admin' })}>
                            Administration
                        </div> */}
                    </nav>
                    <div className="border-t border-gray-200 mt-4 pt-4">
                        <Button 
                            title="Assign Credit"
                            onClick={this.handleAssignCredit}
                            bgColor="green"
                            size="sm"
                            block
                        />
                    </div>
                </div>
            </div>
        ) : null;
    }

    handleSaveChangesProfile = () => {
        const { data } = this.state.userDetails;
        const { firstNameDirty, lastNameDirty } = this.state.userDetailsDirty;

        this.props.updateUserProfile(data['user_id'], firstNameDirty, lastNameDirty, this.props.history);
    }

    handleFieldChange = (e) => {
        let userDetailsDirty = this.state.userDetailsDirty;
        const firstName = document.getElementById('firstNameDirty').value;
        const lastName = document.getElementById('lastNameDirty').value;

        userDetailsDirty["firstNameDirty"] = firstName;
        userDetailsDirty["lastNameDirty"] = lastName;

        this.setState({
            "firstNameDirty": firstName,
            "lastNameDirty": lastName
        });
    }

    handleAssignCredit = () => {
        this.setState({
            modalAssignCredit: true,
        });
    }

    handleAssignCreditConfirm = () => {
        const { assignLessonSelected } = this.state;
        console.log(assignLessonSelected);
    }

    handleChooseLessonProgram = (e) => {
        console.log(e.target.value);
    }

    handleAssignCreditClose = () => {
        this.setState({
            modalAssignCredit: false,
        });
    }

    renderFormComplete = () => {
        const { userDetails, firstNameDirty, lastNameDirty } = this.state;
        console.log(firstNameDirty, lastNameDirty, userDetails.firstName, userDetails.lastName);
        const saveDisabled = (userDetails.firstName === firstNameDirty) && (userDetails.lastName === lastNameDirty);
        return firstNameDirty !== null && lastNameDirty !== null ? (
            <div>
            <div>
                <div className="border-gray-200">
                <div>
                    <h2 className="text-lg font-bold">User Profile</h2>
                        <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                        Fill in the fields for the Golfer. <LearnMore docPage="/doc/golfers" />
                        </p>
                    </div>

                <div className="mt-6 sm:mt-5">

                    <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                            Email
                        </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                            <input 
                                id="email" 
                                
                                value={this.state.email} 
                                className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" 
                                disabled 
                            />
                        </div>
                    </div>
                    </div>

                    <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="firstName" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                            First Name
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                                <input 
                                    id="firstNameDirty" 
                                    value={this.state.firstNameDirty || ''} 
                                    className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" 
                                    onChange={this.handleFieldChange} 
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="lastName" className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                        Last Name
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                            <input 
                                id="lastNameDirty" 
                                
                                value={lastNameDirty || ''} 
                                className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" 
                                onChange={this.handleFieldChange} 
                            />
                        </div>
                    </div>
                    </div>

                </div>
                </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-5">
                <div className="flex justify-end">
                        <Button 
                            onClick={this.handleSaveChangesProfile}
                            title="Save Changes"
                            bgColor="green"
                            hoverColor="green"
                            block
                            size="lg"
                            disabled={saveDisabled}
                        />
                </div>
            </div>
        </div>
        ) : null;
    };

    assignLessonProgramOptions = () => {
        const { lessons } = this.props;
        if (lessons !== null) { 
            return lessons
                .filter(lesson => lesson.licensee_campaign_status === 'ACTIVE')
                .map(lesson => {
                console.log(lesson);
                return (
                    <option value="green">{lesson.display_name}</option>    
                );
            })
        }
        return null;
    }

    render() {
        const { userDetails } = this.props;
        const { selectedMainTab } = this.state;
        const {
            userId,
            licenseeId
        } = this.props.location.state;
        return(
            <DashboardContent title={`User Details`} backTitle={'Back to Users'}>
                    
                <div className="w-full">
                    <div className="p-4">
                        <h1>{userDetails.email}</h1>
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row w-full space-x-0 lg:space-x-4 xl:space-x-4">

                        <div className="w-full xl:w-1/4 lg:w-1/4 space-y-4 text-sm text-gray-600 mb-4">
                            {this.renderMainTabsSide()}
                        </div>

                        {selectedMainTab === 'details' && (
                            <Container>
                                {userDetails && userDetails.loading === false && this.renderFormComplete()}
                                {userDetails.loading === true && <Loading />}
                            </Container>
                        )}

                        {selectedMainTab === 'messages' && (
                            <Container>
                                <h2 className="text-lg font-bold">Messages</h2>
                            </Container>
                        )}

                        {selectedMainTab === 'credits' && (
                            <Container>
                                <div className="flex flex-row justify-between">
                                    <div>
                                        <h2 className="text-lg font-bold">Lesson Credits Available</h2>
                                        <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                                        All available Lesson Program Credits (unused) <LearnMore docPage="/doc/credits" />
                                        </p>
                                    </div>
                                    <div className="flex flex-col items-center justify-center">
                                        <Button 
                                            title="Assign Credit" 
                                            bgColor="green" 
                                            hoverColor="green"
                                            size="base"
                                            onClick={this.handleAssignCredit}
                                        />
                                    </div>
                                </div>
                                <div className="mt-6 sm:mt-5">
                                    {this.renderLessonCredits()}
                                </div>
                            </Container>
                        )}

                        {selectedMainTab === 'admin' && (
                            <Container>
                                <h2 className="text-lg font-bold">Administration</h2>
                            </Container>
                        )}

                        {selectedMainTab === 'videos' && (
                            <Container>
                                {/* <div className="mb-2">
                                    <h2 className="text-lg font-bold">Processed Videos</h2>
                                </div> */}
                                <div>
                                    <h2 className="text-lg font-bold">Processed Videos</h2>
                                    <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                                    The following videos have been processed and are "complete".
                                    </p>
                                </div>
                                <div className="flex flex-1 w-full mt-6 sm:mt-5">
                                    <div className="grid grid-flow-row grid-cols-1 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 auto-cols-max w-full col-gap-3 row-gap-3">
                                        {this.renderPanels()}
                                    </div>
                                </div>
                            </Container>
                        )}
                </div>
                <ModalAssignLessonCredit 
                    show={this.state.modalAssignCredit} 
                    userId={userId}
                    licenseeId={licenseeId}
                    onClose={this.handleAssignCreditClose}
                    onComplete={() => this.props.history.go(0)}
                    size="base"
                />
            </DashboardContent>
        );
    }
}

UserDetailPage.defaultProps = {
    user: null
}

const mapStateToProps = ({ auth, user, licensee, licenseeCampaign }) => {
    const { authUser } = auth;
    return {
        authUser,
        userDetails: userSelectors.licenseeUserDetails(user),
        analysesForUser: licenseeSelectors.analysesForUser(licensee),
        lessons: licenseeCampaignSelectors.lessons(licenseeCampaign),
    };
}

const mapDispatchToProps = {
    getUserDataById,
    getLicenseeUserDataById,
    getLicenseeUserDataByIdReset,
    getLicenseeAnalysesForUser,
    getLicenseeCampaignsByLicenseeId,
    updateUserProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailPage);