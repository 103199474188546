import * as actionTypes from './types';

export const getTargetInterpretations = () => ({
    type: actionTypes.GET_TARGET_INTERPRETATIONS,
    payload: null
});

export const getTargetInterpretationsSuccess = (data) => ({
    type: actionTypes.GET_TARGET_INTERPRETATIONS_SUCCESS,
    payload: data
});

export const getTargetInterpretationsFail = (error) => ({
    type: actionTypes.GET_TARGET_INTERPRETATIONS_FAIL,
    payload: error
});

/**
 * by program (full detail)
 */
export const getTargetInterpretationsForProgram = () => ({
    type: actionTypes.GET_TARGET_INTERPRETATIONS_BY_PROGRAM,
    payload: null
});

export const getTargetInterpretationsForProgramSuccess = (data) => ({
    type: actionTypes.GET_TARGET_INTERPRETATIONS_BY_PROGRAM_SUCCESS,
    payload: data
});

export const getTargetInterpretationsForProgramFail = (error) => ({
    type: actionTypes.GET_TARGET_INTERPRETATIONS_BY_PROGRAM_FAIL,
    payload: error
});

/**
 * by target
 */
export const getTargetInterpretationsForTarget = () => ({
    type: actionTypes.GET_TARGET_INTERPRETATIONS_BY_TARGET,
    payload: null
});

export const getTargetInterpretationsForTargetSuccess = (data) => ({
    type: actionTypes.GET_TARGET_INTERPRETATIONS_BY_TARGET_SUCCESS,
    payload: data
});

export const getTargetInterpretationsForTargetFail = (error) => ({
    type: actionTypes.GET_TARGET_INTERPRETATIONS_BY_TARGET_FAIL,
    payload: error
});
/**
 * createTargetInterpretationData
 */
export const createTargetInterpretationData = (data) => ({
    type: actionTypes.CREATE_TARGET_INTERPRETATION,
    payload: data
});

export const createTargetInterpretationDataSuccess = (data) => ({
    type: actionTypes.CREATE_TARGET_INTERPRETATION_SUCCESS,
    payload: data
});

export const createTargetInterpretationDataFail = (error) => ({
    type: actionTypes.CREATE_TARGET_INTERPRETATION_FAIL,
    payload: error
});

export const resetCreateTargetInterpretationData = () => ({
    type: actionTypes.RESET_CREATE_TARGET_INTERPRETATION,
    payload: null
});

export const resetRemoveTargetInterpretationFromTarget = () => ({
    type: actionTypes.REMOVE_TARGET_INTERPRETATION_RESET,
    payload: null
});

export const removeTargetInterpretationFromTarget = (userId, targetSummaryId) => ({
    type: actionTypes.REMOVE_TARGET_INTERPRETATION,
    payload: { userId, targetSummaryId }
});

export const removeTargetInterpretationFromTargetSuccess = (data) => ({
    type: actionTypes.REMOVE_TARGET_INTERPRETATION_SUCCESS,
    payload: data
});

export const removeTargetInterpretationFromTargetFail = (error) => ({
    type: actionTypes.REMOVE_TARGET_INTERPRETATION_FAIL,
    payload: error
});

