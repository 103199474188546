import React from 'react';
import { connect } from 'react-redux';
import Container from '../tailwind/Container';
import Button from '../common/Button';
import { selectors as authSelectors } from '../../reducers/auth';

class ErrorPage extends React.Component {

    render() {
        return (
          <div className="min-h-screen bg-gray-800 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <Container>
                  <h1 className="font-bold text-2xl">It's Mulligan Time</h1>
                  <p>Things happen. Let's tee up another and try that again.</p>
                  <div className="py-4">
                    <Button 
                      size="lg"
                      bgColor="green"
                      title="Back to the Tee"
                      onClick={() => this.props.history.push({ pathname: '/' })}
                    />
                  </div>
                </Container>
            </div>
          </div>
        );
    }
}

const mapStateToProps = ({ auth }) => {
    const { authUser } = auth;
    return {
        planInformation: authSelectors.planInformation(auth),
        authUser,
    };
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);