import React from 'react';
import { withRouter } from 'react-router-dom';
import ResetPassword from '../auth/ResetPassword';

class ResetPasswordPage extends React.Component {

    handleSuccess = (res) => {
        this.props.history.push('/login');
    }

    handleError = (e) => {
        console.log(e);
    }

    render() {
        return(<ResetPassword onLoginSuccess={this.handleSuccess} onError={this.handleError} />);
    }
}

export default withRouter(ResetPasswordPage);