import { swingbotApi } from '../../util/api';

// Stripe integration
// const stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);

export const getWebsiteForLicensee = (licenseeId) => {
    return swingbotApi(`licensee/${licenseeId}/website`, {}, 'get', true, 'licensee-2')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const createWebsiteForLicensee = (subdomain, licenseeId, displayName) => {
    return swingbotApi(`licensee/${licenseeId}/website`, { subdomain, displayName }, 'post', true, 'licensee-2')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const updateWebsiteForLicensee = (title, theme_color, logo_url, job_title, display_name, twitter_url, linkedin_url, profile_image_url, bio, licenseeId) => {
    return swingbotApi(`licensee/${licenseeId}/website`, { title, theme_color, logo_url, job_title, display_name, twitter_url, linkedin_url, profile_image_url, bio }, 'put', true, 'licensee-2')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const getStripeConnectedAccount = (licenseeId, account, debug=true) => {
    const debugString = debug === true ? '?debug=1': '';
    return swingbotApi(`licensee/${licenseeId}/website/stripe/connect/${account}${debugString}`, {}, 'get', true, 'licensee-3')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const createStripeConnectedAccount = (licenseeId, email, debug=true) => {
    const debugString = debug === true ? '?debug=1': '';
    return swingbotApi(`licensee/${licenseeId}/website/stripe/connect${debugString}`, { email }, 'post', true, 'licensee-3')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const linkStripeConnectedAccount = (licenseeId, accountId, debug=true) => {
    const debugString = debug === true ? '?debug=1': '';
    return swingbotApi(`licensee/${licenseeId}/website/stripe/link${debugString}`, { account_id: accountId }, 'post', true, 'licensee-3')
        .then(response => response)
        .catch(err => Promise.reject(err));
};


