import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DashboardContent, Container } from '../tailwind';
import { analyzeVideo } from '../../actions/analyze';
import AnalysisResultPanel from '../common/analysis/AnalysisResultPanel';
import AnalysisFrames from '../common/analysis/AnalysisFrames';
import { isPassing } from '../../util/strings';
import { diffDates } from '../../util/dates';
import Loading from '../common/Loading';
import Button from '../common/Button';
class VideoDetailPage extends React.Component {
    componentDidMount = () => {
        const record = this.props.location.state.detail;
        this.props.analyzeVideo(record.videoData.video_id);
    }

    handleUserClick = (userId) => {
        this.props.history.push({
            pathname: `/users/${userId}`,
            state: { userId: userId }
        });
    }

    handleClickLessonProgram = (licenseeCampaignId) => {
        this.props.history.push({
            pathname: `/lessons/${licenseeCampaignId}`,
            state: { licenseeCampaignId }
        })
    }

    handleSendToEndpoint = (videoId) => {
        alert('coming soon');
    }
    
    renderAnalysisPanelDetail = () => {
        const { analyzeVideoSuccess } = this.props;
        return analyzeVideoSuccess !== null ? 
            Object.keys(analyzeVideoSuccess)
            .filter(key => {
                // check the keys...
                return (
                key !== 'camera_angle' &&
                key !== 'frame_images' &&
                key !== 'success' &&
                key !== 'credit_data' &&
                key !== 'analysis_data' &&
                key !== 'frames' &&
                key !== 'points' &&
                key !== 'tempo'
                );
            })
            .map(key => {
                const {
                code,
                def,
                msg,
                //   video_id,
                target_data,
                //   target_summary_id,
                image_lines_url_png,
                probability_pass,
                probability_low,
                //   equation_results
                } = analyzeVideoSuccess[key];
                const panelStyle = isPassing(code) ? 'success' : 'danger';
                // const equationResultsForCode = equation_results['key'];
                return (
                <AnalysisResultPanel
                    key={key}
                    panelHeader={target_data.display_name}
                    panelStyle={panelStyle}
                    data={{
                    code,
                    def,
                    msg,
                    // video_id,
                    display_name: target_data.display_name,
                    score: target_data.percent_score,
                    image: image_lines_url_png,
                    // target_summary_id,
                    // target_id: target_data.target_id,
                    // licensee_credit_id: credit_data.licensee_credit_id,
                    // analysis_id: analysis_data.analysis_id,
                    probability_low,
                    probability_pass // ,
                    // calculation_1_pseudo: equationResultsForCode.equation_result.calculation_1_pseudo,
                    // calculation_1_result: JSON.stringify(equationResultsForCode.calculation_1_result),
                    // calculation_2_pseudo: equationResultsForCode.equation_result.calculation_2_pseudo,
                    // calculation_2_result: JSON.stringify(equationResultsForCode.calculation_2_result)
        
                    //equationResults: equationResultsForCode.equation_result
                    }}
                />
                );
            }) : (<Container className="w-full"><Loading /></Container>);
      };

    renderTimeToProcess = () => {
        const record = this.props.location.state.detail;
        const dateProcessing = record.date_processing_timestamp;
        const dateCompleted = record.date_processed_timestamp;
        const { seconds, minutes, hours, days } = diffDates(dateCompleted, dateProcessing);
        
        if (days > 0) {
            return (<div>Processed in {days} days {hours} hours {minutes} minutes and {seconds} seconds</div>);
        }

        if (hours > 0) {
            return (<div>Processed in {hours} hours {minutes} minutes and {seconds} seconds</div>);
        }

        if (minutes > 0) {
            return (<div>Processed in {minutes} minutes and {seconds} seconds</div>);
        }

        if (seconds > 0) {
            return (<div>Processed in {seconds} seconds</div>);
        }
    }
    render() {
        const { 
            analyzeVideoLoading,
            analyzeVideoSuccess
        } = this.props;
        const record = this.props.location.state.detail;
        // const dateCreated = record.date_created_timestamp;
        // const dateProcessing = record.date_processing_timestamp;
        const dateCompleted = record.date_processed_timestamp;
        // const { seconds, minutes, hours, days } = diffDates(dateCompleted, dateProcessing);
        return(
            <DashboardContent title={'Video Details'} backTitle={'Back to Videos'}>
                <Container>
                    <div className="grid lg:grid-cols-2 grid-cols-1">
                        <div className="flex col-span-1 lg:col-span-1 sm:col-span-2 sm:justify-center bg-black">
                            <video controls>
                            <source src={record.videoData.transcoded_mp4} type="video/mp4" />
                            Your browser does not support the video tag.
                            </video>
                        </div>
                        <div className="lg:col-span-1 sm:col-span-2 lg:pl-10 sm:p-2 text-2xl font-bold">
                            {record.credit_code}
                            <div className="text-xs font-normal text-gray-500">
                                <span>Uploaded on {record.videoData.date_uploaded} by&nbsp;</span>
                                <div onClick={() => this.handleUserClick(record.user_id)} className="underline text-cool-gray-800">{record.videoData.email}</div>
                                {dateCompleted > 0 && this.renderTimeToProcess()}
                            </div>
                            <div className="flex flex-row space-x-2 mt-2">
                                <Button 
                                    onClick={() => this.handleClickLessonProgram(record.licensee_campaign_id)} 
                                    bgColor="green"
                                    hoverColor="green"
                                    title="View Lesson Program"
                                />
                                <Button 
                                    onClick={() => this.handleUserClick(record.user_id)} 
                                    bgColor="blue"
                                    hoverColor="blue"
                                    title="View User Profile"
                                />
                                {/* <button 
                                    onClick={() => this.handleClickLessonProgram(record.licensee_campaign_id)} 
                                    type="button" 
                                    className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-white bg-blue-500 hover:text-white focus:outline-none focus:shadow-outline-blue focus:border-blue-400 active:text-white active:bg-blue transition duration-150 ease-in-out"
                                >
                                    View Lesson Program
                                </button> */}
                                {/* <button 
                                    onClick={() => this.handleSendToEndpoint(record.video_id)} 
                                    type="button" 
                                    className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-white bg-green-500 hover:text-white focus:outline-none focus:shadow-outline-green focus:border-green-400 active:text-white active:bg-green transition duration-150 ease-in-out"
                                >
                                    Resend Results to Webhook
                                </button> */}
                            </div>
                            {/* <div className="mt-2 font-bold text-lg">Started on {new Date(dateProcessing).toString()}</div>
                            <div className="mt-2 font-bold text-lg">Completed on {new Date(dateCompleted).toString()}</div> */}
                        </div>
                    </div>
                </Container>
                <Container>
                    <AnalysisFrames 
                        loading={analyzeVideoLoading} 
                        analysisResults={analyzeVideoSuccess} 
                    />
                </Container>
                <Container>
                    <div className="text-xl text-gray-800 font-bold">Analysis Results</div>
                    <div className="text-base text-gray-700">The following are the results of the analysis for the program selected during upload.</div>
                </Container>
                <div className="w-full">{this.renderAnalysisPanelDetail()}</div>
                
            </DashboardContent>
        );
    }
}

const mapStateToProps = ({ analyze }) => {
    const {
        analyzeVideoSuccess,
        analyzeVideoLoading,
        analyzeVideoError
    } = analyze;
    return {
        analyzeVideoSuccess,
        analyzeVideoLoading,
        analyzeVideoError
    };
};
const mapDispatchToProps = {
    analyzeVideo
};

const videoDetailPageWithRouter = withRouter(VideoDetailPage);
export default connect(mapStateToProps, mapDispatchToProps)(videoDetailPageWithRouter);