import React from 'react';
import { withRouter } from 'react-router-dom';
import Login from '../auth/Login';

class LoginPage extends React.Component {
    handleLoginSuccess = (res) => {
        this.props.history.push('/overview');
    }

    handleLoginError = (e) => {
        // console.log(e);
    }

    render() {
        return(<Login onLoginSuccess={this.handleLoginSuccess} onLoginError={this.handleLoginError} />);
    }
}

export default withRouter(LoginPage);