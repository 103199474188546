/**
 * LicenseeReducer
 *
 * Fetch the list of users from the database
 */
import * as actionTypes from '../actions/licenseeCampaign/actionTypes';
import * as authActionTypes from '../actions/auth/actionTypes';

  // set the initial state of the reducer
    const INITIAL_STATE = {
      licenseeCampaigns: null,
      loadingLicenseeCampaigns: false,
      loadingLicenseeCampaignsError: null,

      createLicenseeCampaign: null,
      createLicenseeCampaignLoading: false,
      createLicenseeCampaignError: null,

      duplicateLicenseeCampaignSuccess: null,
      duplicateLicenseeCampaignLoading: false,
      duplicateLicenseeCampaignError: null,

      sendToWebhookLoading: false,
      sendToWebhookSuccess: null,
      sendToWebhookError: null,

      updateLicenseeCampaignStatusSuccess: null,
      updateLicenseeCampaignStatusLoading: false,
      updateLicenseeCampaignStatusError: null,

      updateLicenseeCampaignSuccess: null,
      updateLicenseeCampaignLoading: true,
      updateLicenseeCampaignError: null,
    };
  
   export default (state = INITIAL_STATE, action) => {
     switch (action.type) {
      case authActionTypes.LOGOUT_USER:
         return {
            ...state,

            licenseeCampaigns: null,
            loadingLicenseeCampaigns: false,
            loadingLicenseeCampaignsError: null,

            createLicenseeCampaign: null,
            createLicenseeCampaignLoading: false,
            createLicenseeCampaignError: null,

            duplicateLicenseeCampaignSuccess: null,
            duplicateLicenseeCampaignLoading: false,
            duplicateLicenseeCampaignError: null,

            sendToWebhookLoading: false,
            sendToWebhookSuccess: null,
            sendToWebhookError: null,

            updateLicenseeCampaignStatusSuccess: null,
            updateLicenseeCampaignStatusLoading: false,
            updateLicenseeCampaignStatusError: null,
         }

       case actionTypes.GET_LICENSEE_CAMPAIGNS:
         return {
            ...state,
            licenseeCampaigns: null,
            loadingLicenseeCampaigns: true,
            loadingLicenseeCampaignsError: null,
         }

       case actionTypes.GET_LICENSEE_CAMPAIGNS_SUCCESS:
         return {
            ...state,
            licenseeCampaigns: action.payload,
            loadingLicenseeCampaigns: false,
            loadingLicenseeCampaignsError: null,
         }

       case actionTypes.GET_LICENSEE_CAMPAIGNS_FAIL:
         return {
            ...state,
            licenseeCampaigns: null,
            loadingLicenseeCampaigns: false,
            loadingLicenseeCampaignsError: action.payload,
         }

      case actionTypes.CREATE_LICENSEE_CAMPAIGN:
         return {
            ...state,
            createLicenseeCampaign: null,
            createLicenseeCampaignLoading: true,
            createLicenseeCampaignError: null,
         }

      case actionTypes.CREATE_LICENSEE_CAMPAIGN_SUCCESS:
         return {
            ...state,
            createLicenseeCampaign: action.payload,
            createLicenseeCampaignLoading: false,
            createLicenseeCampaignError: null,
         }

      case actionTypes.CREATE_LICENSEE_CAMPAIGN_FAIL:
         return {
            ...state,
            createLicenseeCampaign: null,
            createLicenseeCampaignLoading: false,
            createLicenseeCampaignError: action.payload,
         }

      case actionTypes.CREATE_LICENSEE_CAMPAIGN_RESET:
         return {
            ...state,
            createLicenseeCampaignSuccess: null,
            createLicenseeCampaignLoading: false,
            createLicenseeCampaignError: null,
         }

      case actionTypes.DUPLICATE_LICENSEE_CAMPAIGN:
         return {
            ...state,
            duplicateLicenseeCampaignSuccess: null,
            duplicateLicenseeCampaignLoading: true,
            duplicateLicenseeCampaignError: null,
         }

       case actionTypes.DUPLICATE_LICENSEE_CAMPAIGN_SUCCESS:
         return {
            ...state,
            duplicateLicenseeCampaigns: action.payload,
            duplicateLicenseeCampaignLoading: false,
            duplicateLicenseeCampaignError: null,
         }

       case actionTypes.DUPLICATE_LICENSEE_CAMPAIGN_FAIL:
         return {
            ...state,
            duplicateLicenseeCampaignSuccess: null,
            duplicateLicenseeCampaignLoading: false,
            duplicateLicenseeCampaignError: action.payload,
         }


      case actionTypes.UPDATE_LICENSEE_CAMPAIGN:
         return {
            ...state,
            updateLicenseeCampaignSuccess: null,
            updateLicenseeCampaignLoading: true,
            updateLicenseeCampaignError: null,
         }
   
      case actionTypes.UPDATE_LICENSEE_CAMPAIGN_SUCCESS:
         return {
            ...state,
            updateLicenseeCampaignSuccess: action.payload,
            updateLicenseeCampaignLoading: false,
            updateLicenseeCampaignError: null,
         }

      case actionTypes.UPDATE_LICENSEE_CAMPAIGN_FAIL:
         return {
            ...state,
            updateLicenseeCampaignSuccess: null,
            updateLicenseeCampaignLoading: false,
            updateLicenseeCampaignError: action.payload,
         }
         
      case actionTypes.UPDATE_LICENSEE_CAMPAIGN_RESET:
         return {
            ...state,
            updateLicenseeCampaignSuccess: null,
            updateLicenseeCampaignLoading: false,
            updateLicenseeCampaignError: null
         }

      case actionTypes.UPDATE_LICENSEE_CAMPAIGN_STATUS:
         return {
            ...state,
            updateLicenseeCampaignStatusSuccess: null,
            updateLicenseeCampaignStatusLoading: true,
            updateLicenseeCampaignStatusError: null,
         }

       case actionTypes.UPDATE_LICENSEE_CAMPAIGN_STATUS_SUCCESS:
         return {
            ...state,
            updateLicenseeCampaignStatusSuccess: action.payload,
            updateLicenseeCampaignStatusLoading: false,
            updateLicenseeCampaignStatusError: null,
         }

       case actionTypes.UPDATE_LICENSEE_CAMPAIGN_STATUS_FAIL:
         return {
            ...state,
            updateLicenseeCampaignStatusSuccess: null,
            updateLicenseeCampaignStatusLoading: false,
            updateLicenseeCampaignStatusError: action.payload,
         }
         
      case actionTypes.UPDATE_LICENSEE_CAMPAIGN_STATUS_RESET:
         return {
            ...state,
            updateLicenseeCampaignStatusSuccess: null,
            updateLicenseeCampaignStatusLoading: false,
            updateLicenseeCampaignStatusError: null
         }

      case actionTypes.SEND_TO_WEBHOOK:
         return {
            ...state,
            sendToWebhookLoading: true,
            sendToWebhookSuccess: null,
            sendToWebhookError: null
         }

       case actionTypes.SEND_TO_WEBHOOK_SUCCESS:
         return {
            ...state,
            sendToWebhookLoading: false,
            sendToWebhookSuccess: action.payload,
            sendToWebhookError: null
         }

       case actionTypes.SEND_TO_WEBHOOK_FAIL:
         return {
            ...state,
            sendToWebhookLoading: false,
            sendToWebhookSuccess: null,
            sendToWebhookError: action.payload
         }

      case actionTypes.SEND_TO_WEBHOOK_RESET:
         return {
            ...state,
            sendToWebhookLoading: false,
            sendToWebhookSuccess: null,
            sendToWebhookError: null
         }

       default:
         return state;
     }
   };
  
   export const selectors = {
      lessons: (state) => {
         return state.licenseeCampaigns 
            ? state.licenseeCampaigns : null;
      },
      updateLicenseeCampaign: (state) => {
         return {
            loading: state.updateLicenseeCampaignLoading,
            success: state.updateLicenseeCampaignSuccess,
            error: state.updateLicenseeCampaignError
         };
      },
      duplicateLicenseeCampaign: (state) => {
         return {
            success: state.duplicateLicenseeCampaignSuccess,
            loading: state.duplicateLicenseeCampaignLoading,
            error: state.duplicateLicenseeCampaignError
         }
      }
   }