import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faVideo, faHome, faUsers, faTools } from '@fortawesome/free-solid-svg-icons';
import { withRouter, } from "react-router-dom";

class DashboardMenu extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        activeRoute: '/'
      }
    }

    handleClick = (route) => {
        this.props.onClick();
        this.setState({ activeRoute: route });
        this.props.history.push(route);
    }

    activeClass = (route) => {
      const pathParts = this.props.location.pathname.split('/');
      const rootPath = "/" + pathParts[1];
      return rootPath === route
        ? 'bg-gray-900 cursor-pointer focus:outline-none '
        : 'hover:bg-gray-700 cursor-pointer focus:outline-none '
    }

    render() {
        return (
            <nav className="mt-5 flex-1 px-2 bg-gray-800">
                <div onClick={() => this.handleClick('/overview')} className={`${this.activeClass('/overview')}group flex items-center px-2 py-2 text-sm leading-6 font-normal rounded-md text-white focus:outline-none transition ease-in-out duration-150`}>
                  <FontAwesomeIcon icon={faHome} className="mx-2 h-6 w-6 text-gray-300 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />Overview
                </div>
                <div onClick={() => this.handleClick('/lessons')} className={`${this.activeClass('/lessons')}mt-1 group flex items-center px-2 py-2 text-sm leading-6 font-normal rounded-md text-gray-300 hover:text-white focus:bg-gray-700 focus:outline-none focus:text-white transition ease-in-out duration-150`}>
                    <FontAwesomeIcon icon={faTools} className="mx-2 h-6 w-6 text-gray-300 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />Lesson Programs
                </div>
                <div onClick={() => this.handleClick('/users')} className={`${this.activeClass('/users')}mt-1 group flex items-center px-2 py-2 text-sm leading-6 font-normal rounded-md text-gray-300 hover:text-white focus:bg-gray-700 focus:outline-none focus:text-white transition ease-in-out duration-150`}>
                    <FontAwesomeIcon icon={faUsers} className="mx-2 h-6 w-6 text-gray-300 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />Golfers
                </div>
                <div onClick={() => this.handleClick('/videos')} className={`${this.activeClass('/videos')}mt-1 group flex items-center px-2 py-2 text-sm leading-6 font-normal rounded-md text-gray-300 hover:text-white focus:bg-gray-700 focus:outline-none focus:text-white transition ease-in-out duration-150`}>
                    <FontAwesomeIcon icon={faVideo} className="mx-2 h-6 w-6 text-gray-300 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />Swing Videos
                </div>
                <div onClick={() => this.handleClick('/api-keys')} className={`${this.activeClass('/api-keys')}mt-1 group flex items-center px-2 py-2 text-sm leading-6 font-normal rounded-md text-gray-300 hover:text-white focus:bg-gray-700 focus:outline-none focus:text-white transition ease-in-out duration-150`}>
                    <FontAwesomeIcon icon={faKey} className="mx-2 h-6 w-6 text-gray-300 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />Api Key
                </div>
                {/* <div onClick={() => this.handleClick('/webhook')} className={`${this.activeClass('/webhook')}mt-1 group flex items-center px-2 py-2 text-sm leading-6 font-normal rounded-md text-gray-300 hover:text-white focus:bg-gray-700 focus:outline-none focus:text-white transition ease-in-out duration-150`}>
                    <FontAwesomeIcon icon={faGlobe} className="mx-2 h-6 w-6 text-gray-300 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />Webhook
                </div> */}
                {/* <div onClick={() => this.handleClick('/settings')} className={`${this.activeClass('/settings')}mt-1 group flex items-center px-2 py-2 text-sm leading-6 font-normal rounded-md text-gray-300 hover:text-white focus:bg-gray-700 focus:outline-none focus:text-white transition ease-in-out duration-150`}>
                    <FontAwesomeIcon icon={faCog} className="mx-2 h-6 w-6 text-gray-300 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />Settings
                </div> */}
                {/* <div onClick={() => this.handleClick('/terms')} className={`${this.activeClass('/settings')}mt-1 group flex items-center px-2 py-2 text-sm leading-6 font-normal rounded-md text-gray-300 hover:text-white focus:bg-gray-700 focus:outline-none focus:text-white transition ease-in-out duration-150`}>
                    <FontAwesomeIcon icon={faFileAlt} className="mx-2 h-6 w-6 text-gray-300 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />Terms and Conditions
                </div> */}
              </nav>
        )
    }
}

DashboardMenu.defaultProps = {
  onClick() {}  // tell the parent so we can close the menu
}

export default withRouter(DashboardMenu);
