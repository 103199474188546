import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ApplicationAccept from '../auth/ApplicationAccept';
import { acceptApplication } from '../../actions/user';

class ApplicationAcceptPage extends React.Component {

    handleSuccess = (res) => {
        this.props.history.push('/overview');
    }

    handleError = (e) => {}

    render() {
        // check to see if we have a code...
        const code = ('code' in this.props.match.params)
            ? this.props.match.params.code
            : null;
        return (
                <ApplicationAccept
                    code={code} 
                    onActivateSuccess={this.handleActivateSuccess} 
                    onActivateError={this.handleActivateError}
                />
        );
    }
}

const mapStateToProps = ({ auth }) => {
    const {
        activateLicenseeSuccess,
        loadingActivateUser,
        activateLicenseeError,
        registerUserSuccess
    } = auth;
    return {
        activateLicenseeSuccess,
        loadingActivateUser,
        activateLicenseeError,
        registerUserSuccess
    }
}

const mapDispatchToProps = {
    acceptApplication
}

const applicationAcceptWithRouter = withRouter(ApplicationAcceptPage);
export default connect(mapStateToProps, mapDispatchToProps)(applicationAcceptWithRouter);