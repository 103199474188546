import React from 'react';
import ImagePointsItem from './ImagePointsItem';
import { getBodyPointsToRenderArray } from '../../../util/swingbot';
import { 
    getPointsForPosition, 
    getPointsForBodyPoint, 
    getBodyPointByName,
    getPositionByName 
} from '../../../util/swingbot';

class SelectedPosition extends React.Component {

    getImageUrlForPosition = (results, position, number) => {
        if (results !== null) {
            if (results.frame_images !== null) {
                if (position !== null) {
                    if (Object.keys(position).length > 0) {
                        if (position[number]) {
                            return results.frame_images[position[number].name];
                        }
                    }
                }
            }
        }
        return null;
    }

    getGuidelinesForCalculationType = (calculationType, bodyPointsToRender, position, points) => {
        let guidelines = [];

        try {
            if (calculationType !== null && points !== null && position !== null) {

                const pointsForPosition = getPointsForPosition(position[1].name, points);
                const pointsForBodyPoint = getPointsForBodyPoint(bodyPointsToRender[0], pointsForPosition);

                switch(calculationType.pseudo.toLowerCase()) {
                    case 'value-x':
                        // lets create the point we wish to use for the x position guideline
                        guidelines.push({ 
                            p1: { x: 0, y: pointsForBodyPoint[0]['y_pos']}, 
                            p2: { x: pointsForBodyPoint[0]['x_pos'], y: pointsForBodyPoint[0]['y_pos']}
                        });
                    break;

                    case 'value-y':
                        // lets create the point we wish to use for the x position guideline
                        guidelines.push({ 
                            p1: { x: pointsForBodyPoint[0]['x_pos'], y: 0}, 
                            p2: { x: pointsForBodyPoint[0]['x_pos'], y: pointsForBodyPoint[0]['y_pos']}
                        });
                    break;


                    default:
                    break;
                }
            }
            return guidelines;
        } catch(e) {
            return [];
        }
    }


    getGuidelineTextForCalculationType = (calculationType, bodyPointsToRender, position, points) => {
        let guidelineText = [];

        try {
            if (calculationType !== null && points !== null && position !== null) {

                const pointsForPosition = getPointsForPosition(position[1].name, points);
                const pointsForBodyPoint = getPointsForBodyPoint(bodyPointsToRender[0], pointsForPosition);

                switch(calculationType.pseudo.toLowerCase()) {
                    case 'value-x':
                        // lets create the point we wish to use for the x position guideline
                        guidelineText.push({ 
                            x: pointsForBodyPoint[0]['y_pos'] / 2, 
                            y: pointsForBodyPoint[0]['y_pos'] - 20,
                            text: 'Value'
                        });
                    break;

                    case 'value-y':
                        // lets create the point we wish to use for the x position guideline
                        guidelineText.push({ 
                            x: pointsForBodyPoint[0]['x_pos'], 
                            y: pointsForBodyPoint[0]['y_pos'] / 2,
                            text: 'Value'
                        });
                    break;


                    default:
                    break;
                }
            }
            return guidelineText;
        } catch(e) {
            return [];
        }
    }

    getCurrentValue = (position, bodyPoints, points) => {
        try {
            const paragraph = [];
            Object.keys(position).forEach((pos, index) => {
                var pointsForPosition = getPointsForPosition(position[pos].name, points);
                var pointsForBodyPoint = getPointsForBodyPoint(bodyPoints[index], pointsForPosition);
                var x = Math.round(pointsForBodyPoint[0].x_pos, 2);
                var y = Math.round(pointsForBodyPoint[0].y_pos, 2);
                paragraph.push(`${getBodyPointByName(bodyPoints[index]).display} at ${getPositionByName(position[pos].name).display} is (${x}, ${y})\n`);
            })
            return paragraph.map((sent, index) => {
                return (<div key={`para-${index}`}>{sent}</div>);
            });
        } catch(e) {
            return "";
        }
    }

    render() {
        const {
            position,
            bodyPoints,
            analysisResults,
            isEditable,
            showCoords
        } = this.props;

        
        let bodyPointsToRender = null;
        if (bodyPoints !== null) {
            bodyPointsToRender = getBodyPointsToRenderArray(bodyPoints);
        }

        let points, rightHanded;
        if (analysisResults !== null) {
            points = analysisResults.points;
            rightHanded = analysisResults.right_handed
        }
        const imageUrl = this.getImageUrlForPosition(analysisResults, position, 1); 

        const guidelines = bodyPointsToRender ? this.getGuidelinesForCalculationType(
            this.props.calculationType, 
            bodyPointsToRender, 
            position, 
            points
        ) : null;

        const guidelineText = bodyPointsToRender ? this.getGuidelineTextForCalculationType(
            this.props.calculationType, 
            bodyPointsToRender, 
            position, 
            points
        ) : null;

        return imageUrl !== null ? (
            <div className="flex justify-center flex-col">
                <ImagePointsItem
                    position={position}
                    imageUrl={imageUrl}
                    points={points} 
                    rightHanded={rightHanded}
                    bodyPointsToRender={bodyPointsToRender}
                    guidelines={guidelines}
                    guidelineText={guidelineText}
                    ratio={this.props.ratio}
                />
                {isEditable === true && showCoords === true && (
                    <div className="text-white p-4 text-center">
                        {this.getCurrentValue(position, bodyPointsToRender, points)}
                    </div>
                )}
            </div>
        ) : null;
    }
}

SelectedPosition.defaultProps = {
    showCoords: false,
    isEditable: false,
    position: { 1: { name: 'address', display: 'Address', number: 1 }},
    points: null,
    rightHanded: null,
    bodyPointsToRender: null,
    analysisResults: null,
    calculationType: null,
    ratio: 1,
}

export default SelectedPosition;