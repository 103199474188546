import React from 'react';
import { hyphenate } from '../../../util/strings';

class DocumentationTitle extends React.Component {
    render() {
        const anchorLink = this.props.anchor !== null 
            ? this.props.anchor 
            : hyphenate(this.props.title); 
        return (
            <div id={anchorLink} className="">
                <h3>{this.props.title}</h3>
            </div>
        );
    }
}

DocumentationTitle.defaultProps = {
    anchor: null,
}
export default DocumentationTitle;