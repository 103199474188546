import React from 'react';
import { withRouter } from 'react-router-dom';
import ForgotPassword from '../auth/ForgotPassword';

class ForgotPasswordPage extends React.Component {

    handleSuccess = (res) => {
        this.props.history.push('/login');
    }

    handleError = (e) => {
    }

    render() {
        return(<ForgotPassword onLoginSuccess={this.handleSuccess} onError={this.handleError} />);
    }
}

export default withRouter(ForgotPasswordPage);