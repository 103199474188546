import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Line, Circle, Set } from 'react-raphael';
import { getPointsForPosition } from '../../../util/swingbot';

class ImageDrawItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dimensions: {
              width: 0,
              height: 0
            },
            strokeWidth: 5,
            points: {},
            hasDrawn: false,
        };
        this.set = React.createRef();
    }

  componentDidMount = () => {
    this.setState({
      points: this.flipBodyPoints()
    });
  };
  /**
   * flip the right to left for left handed golfers.
   */
  flipBodyPoints = () => {
    const { rightHanded, points } = this.props;
    if (rightHanded === 'N') {
      let flipPoints = {};
      const phaseKeys = Object.keys(points);
      for (let i = 0; i < phaseKeys.length; i++) {
        const phase = phaseKeys[i];
        flipPoints[phase] = {};
        const bodyPointKeys = Object.keys(points[phase]);
        for (let j = 0; j < bodyPointKeys.length; j++) {
          const bodyPoint = bodyPointKeys[j];
          let pointData = points[phase][bodyPoint];
          // switch to right!
          if (bodyPoint.indexOf('left') > -1) {
            const parts = bodyPoint.split('_');
            pointData = points[phase][`right_${parts[1]}`];
          }
          // switch to left!
          if (bodyPoint.indexOf('right') > -1) {
            const parts = bodyPoint.split('_');
            pointData = points[phase][`right_${parts[1]}`];
          }
          // deal with "hands"
          if (bodyPoint === 'hands') {
            
          }
          // add the pointdata to the new object...
          flipPoints[phase][bodyPoint] = pointData;
        }
      }
      return flipPoints;
    } else {
      return points;
    }
  };

  getColorForLine = () => {
    const { code } = this.props;
    return code.substr(0, 1) === 'P' ? '#00FF00' : 'red';
  };

  handleOnLoad = ({ target: img }) => {
    this.setState({
      dimensions: {
        height: img.naturalHeight,
        width: img.naturalWidth
      }
    });
  };

  handleOnError = () => {};

  parsePoint1 = (line, points) => {
    return points && line['p1_lexicon'] in points ? points[line['p1_lexicon']] : false;
  };

  parsePoint2 = (line, points) => {
    return points && line['p1_lexicon'] in points ? points[line['p2_lexicon']] : false;
  };

  renderLine = (line, points) => {
    let lines = null;
    switch (line.type) {
      case 'horizontal':
        lines = this.drawHorizontalLine(line, points);
        break;
      case 'p2p':
        lines = this.drawP2PLine(line, points);
        break;
      case 'vertical':
        lines = this.drawVerticalLine(line, points);
        break;
      default:
        break;
    }
    return lines;
  };

  drawHorizontalLine = (line, points) => {
    const p1 = this.parsePoint1(line, points);
    const { dimensions, strokeWidth } = this.state;
    return p1 ? (
      <Line
        key={line.line_id}
        x1={0}
        y1={Math.round(p1.y_pos)}
        x2={dimensions.width}
        y2={Math.round(p1.y_pos)}
        attr={{ stroke: line.color, 'stroke-width': strokeWidth }}
      />
    ) : null;
  };

  drawVerticalLine = (line, points) => {
    const p1 = this.parsePoint1(line, points);
    const { dimensions, strokeWidth } = this.state;
    return p1 ? (
      <Line
        key={line.line_id}
        x1={Math.round(p1.x_pos)}
        y1={0}
        x2={Math.round(p1.x_pos)}
        y2={dimensions.height}
        attr={{ stroke: line.color, 'stroke-width': strokeWidth }}
      />
    ) : null;
  };

  drawP2PLine = (line, points) => {
    const p1 = this.parsePoint1(line, points);
    const p2 = this.parsePoint2(line, points);
    const { strokeWidth } = this.state;
    return p1 && p2 ? (
      <Line
        key={line.line_id}
        x1={Math.round(p1.x_pos)}
        y1={Math.round(p1.y_pos)}
        x2={Math.round(p2.x_pos)}
        y2={Math.round(p2.y_pos)}
        attr={{ stroke: line.color, 'stroke-width': strokeWidth }}
      />
    ) : null;
  };

  /**
   * 
   */
    drawCircle = (point, radius = 3, attr = null) => {
        const attributes = attr || { "stroke":"#FFFF00", "stroke-width":6 };
        return (
            <Circle 
                key={`key-circle-${point.x}-${point.y}`}
                x={point.x} 
                y={point.y} 
                r={radius}
                attr={attributes} 
                animate={false} 
            />
        );
  }

  drawLine = (point1, point2, color, attr = null) => {    
        const attributes = attr || { "stroke": color, "stroke-width":4 };
        const random = Math.random();
        return (
            <Line
                key={`key-line-${random}`}
                x1={Math.round(point1.x)}
                y1={Math.round(point1.y)}
                x2={Math.round(point2.x)}
                y2={Math.round(point2.y)}
                attr={attributes}
            />
        );
  }

  pointsForLine = (points, line) => {
    // we have to check each phase for the point...
    const phase = line.phase_origin ? line.phase_origin : line.phase;
    return points[phase];
  };

//   renderLines = () => {
//     const { lines } = this.props;
//     const { points } = this.state;
//     return lines !== null && points !== null
//       ? lines.map(line => {
//           const pointsForLine = this.pointsForLine(points, line);
//           return this.renderLine(line, pointsForLine);
//         })
//       : null;
//   };

  renderPointsForPosition = (position, bodyPointsToRenderArray) => {
    const { points } = this.state;
    const { ratio } = this.props;
    // now we can render some points on the image...
    return bodyPointsToRenderArray.map((bp, index) => {
        const pointsForPosition = getPointsForPosition(position[index+1].name, points);
        const p = {
            x: pointsForPosition[0][bp]['x_pos'] * ratio,
            y: pointsForPosition[0][bp]['y_pos'] * ratio
        };
        return this.drawCircle(p);
    });
  }

  renderLines = (position, points) => {
    const { ratio, lines } = this.props;
    const { width, height } = this.state.dimensions;
    return lines !== null
        ? lines.map((line, index) => {
            try {
                // const pointsFromAnalysis = getPointsForPosition(position, points);
                const points1FromAnalysis = getPointsForPosition(line.phase_origin, points);
                const points2FromAnalysis = getPointsForPosition(line.phase_origin_2, points);
                const points1 = points1FromAnalysis[0][line.p1_lexicon];
                const points2 = points2FromAnalysis[0][line.p2_lexicon];
                const p1 = {
                    x: points1['x_pos'] * ratio,
                    y: points1['y_pos'] * ratio
                };
                let p2 = null;
                // make sure points 2 is defined...
                if (points2) {
                    p2 = {
                        x: points2['x_pos'] * ratio,
                        y: points2['y_pos'] * ratio
                    };
                }
                let draw = null;
                // point to point    
                if (line.type === 'p2p') {
                    draw = this.drawLine(p1, p2, line.color)
                } else if (line.type === 'vertical') {
                    draw = this.drawLine({ x: p1.x, y: 0 }, { x: p1.x, y: height }, line.color);
                } else if (line.type === 'horizontal') {
                    draw = this.drawLine({ x: 0, y: p1.y }, { x: width, y: p1.y }, line.color);
                } else {
                    draw = null;
                }
                return draw;
            } catch(e) {
                return null;
            }
        }) : null;
  }

  renderPaper = (paperStyle, width, height) => {    
      return (
        <Paper key={'test'} style={paperStyle} width={width} height={height}>
            <Set ref={this.set}>
                {this.renderLines(this.props.position, this.props.points)}
            </Set>
        </Paper>
      );
  }

  render() {
    const { imageUrl, ratio } = this.props;
    const { width, height } = this.state.dimensions;
    const newWidth = width * ratio;
    const newHeight = height * ratio;
    const wrapperStyle = {
      position: 'relative',
      width: newWidth,
      height: newHeight
    };
    const imageStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      width: newWidth,
      height: newHeight
    };
    const paperStyle = {
      position: 'absolute',
      top: 0,
      left: 0
    };

    return (
      <div style={wrapperStyle}>
        <img
            className={this.props.className}
            src={imageUrl}
            style={imageStyle}
            onLoad={this.handleOnLoad}
            onError={this.handleOnError}
            alt="draw_point_image"
        />
        {width !== 0 && height !== 0 && this.props.lines !== null && (
            this.renderPaper(paperStyle, newWidth, newHeight)
        )}
      </div>
    );
  }
}

ImageDrawItem.propTypes = {
    className: PropTypes.string,
    position: PropTypes.string,
    imageUrl: PropTypes.string,
    lines: PropTypes.array,
    points: PropTypes.object,
    bodyPointsToRender: PropTypes.array,
    ratio: PropTypes.number
};

ImageDrawItem.defaultProps = {
    bodyPointsToRender: [],
    ratio: 1,
    className: 'rounded'
}

export default ImageDrawItem;
