import React from 'react';
import WizardContainer from './WizardContainer';
class WizardComplete extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            dirty: false,
        }
    }

    handleFinish = () => {
        // go to the next step...
        this.props.onSubmit();
    }

    render() {
        const { 
            complete,
            loading,
            error,
            title,
            text,
            step,
            previousStep,
            nextStep
        } = this.props;
        return (
            <WizardContainer 
                title={title} 
                text={text} 
                step={step}
                previousStep={previousStep}
                nextStep={nextStep}
            >   
                {complete !== null && (
                    <div className="text-lg font-bold text-green-600">Your application has been submitted! We will contact you shortly.</div>
                )}
                {loading === true && (
                    <div className="text-lg font-bold text-gray-600">Submitting...</div>
                )}
                {error !== null && (
                    <div className="text-lg font-bold text-red-600">There was an error submitting your application.</div>
                )}
                {loading === false && complete === null && error === null && (
                    <div className="flex flex-row justify-start items-center mt-4">
                        <div 
                            disabled={this.props.loading === true || this.props.complete !== null}
                            onClick={this.handleFinish} 
                            className="py-4 px-8 whitespace-no-wrap inline-flex items-center justify-center border-green-500 text-2xl font-bold leading-6 font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition ease-in-out duration-150 mr-2 cursor-pointer"
                        >
                            Finish
                        </div>
                    </div>    
                )}
            </WizardContainer>
        );
    }
}

WizardComplete.defaultProps = {
    step: 1,
    name: 'input',
    type: 'text',
    placeholder: '',
    title: '',
    color: 'green',
    onSubmit(){},
    loading: false,
    complete: null,
    error: null
}

export default WizardComplete;