import React from 'react';
import { DashboardContent } from '../../tailwind';
import { DocumentationMenu } from './DocumentationMenu';

// styles
import './DocumentationPage.css';

class DocumentationPageHalf extends React.Component {
    render() {
        return (
          <DashboardContent>
            <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-2 p-2">
              <div className="">
                <div className="flex-1 overflow-y-auto rounded p-5">
                  <DocumentationMenu />
                </div>
              </div>
              <div className="w-full md:w-3/4 px-0 sm:px-10 bg-white rounded min-h-screen">
                <div className="prose prose-md mb-10 p-4 max-w-prose">
                  {this.props.children}
                </div>
              </div>
            </div>
          </DashboardContent>
        )
    }
}

export default DocumentationPageHalf;