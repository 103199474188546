import React from 'react';

class DisabledTargetContainer extends React.Component {

    handleOnClick = (e) => {
        this.props.onClick();
    }

    render() {
        return (
            <div onClick={this.handleOnClick} className="flex h-48 border-8 border-dashed border-gray-200 text-3xl text-center text-gray-300 justify-center items-center rounded-lg font-bold mt-6 cursor-pointer">
                {this.props.title}
            </div>
        )
    }
}

DisabledTargetContainer.defaultProps = {
    title: '',
    onClick(){}
}

export default DisabledTargetContainer;