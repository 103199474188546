import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { registerLicensee, resetRegisterLicensee } from '../../actions/auth';

class Register extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            terms: false,
            error: null,
        };
    }

    componentDidMount = () => {
        // lets reset redux...
        this.props.resetRegisterLicensee();
    }

    handleOnChangeEmail = (e) => {
        this.props.resetRegisterLicensee();
        this.setState({
            email: e.target.value
        });
    }

    handleOnChangePassword = (e) => {
        this.setState({
            password: e.target.value
        });
    }

    handleTerms = (e) => {
      this.setState({
        terms: e.target.checked
      });
    }

    handleRegister = (e) => {
        e.preventDefault();
        const { email, password, terms } = this.state;
        if (email !== '' && password !== '' && terms !== '') {
            this.props.registerLicensee(email, password, this.props.history);
        } else {
            this.setState({
              error: true
            });
        }
    }

    handleGoogleLogin = (e) => {
        e.preventDefault();
    }

    render () {
        const {
            loadingRegisterUser,
            registerUserError
        } = this.props;
        return (
            <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <img className="mx-auto h-12 w-auto rounded" src={require('../../img/logo.png')} alt="Swingbot" />
              <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                Create your free account
              </h2>
              <p className="mt-2 text-center text-sm leading-5 text-gray-600 max-w">
                or&nbsp;
                <Link to="/login" className="font-medium text-green-600 hover:text-green-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                  login to your account
                </Link>
              </p>
            </div>
          
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                  {(registerUserError !== null || this.state.error !== null) && (
                      <div className="rounded-md bg-red-50 p-4 mb-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <svg className="h-5 w-5 text-red-400" fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd"/>
                          </svg>
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm leading-5 font-medium text-red-800">
                            There was an error creating your account. Please make sure all the fields are filled in and you have accepted the terms of service.
                          </h3>
                        </div>
                      </div>
                    </div>
                  )}
                <form action="#" method="POST">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                      Email address
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input onChange={this.handleOnChangeEmail} id="email" type="email" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                    </div>
                  </div>
          
                  <div className="mt-6">
                    <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
                      Password
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input onChange={this.handleOnChangePassword} id="password" type="password" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                    </div>
                  </div>
                
                {loadingRegisterUser === false && (
                    <div className="mt-6">
                        <span className="block w-full rounded-md shadow-sm">
                        <button type="submit" disabled={loadingRegisterUser} onClick={this.handleRegister} className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out">
                            Create Account
                        </button>
                        </span>
                    </div>
                )}
                {loadingRegisterUser === true && (
                    <div className="mt-6">Creating account...</div>
                )}
                </form>
          
                {/* <div className="mt-6">
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-gray-300"></div>
                    </div>
                    <div className="relative flex justify-center text-sm leading-5">
                      <span className="px-2 bg-white text-gray-500">
                        Or continue with
                      </span>
                    </div>
                  </div>
          
                  <div className="mt-6 grid grid-cols-1 gap-3">
                    <div>
                      <span className="w-full inline-flex rounded-md shadow-sm">
                        <button onClick={this.handleGoogleLogin} type="button" className="py-2 px-4 w-full inline-flex justify-center border border-red-300 rounded-md bg-red-500 text-sm leading-5 font-medium text-gray-500 hover:text-white focus:outline-none focus:border-red-300 focus:shadow-outline-blue transition duration-150 ease-in-out hover:bg-red-400" aria-label="Sign in with Google">
                            <FontAwesomeIcon icon={faGoogle} className="text-white transition ease-in-out duration-150" />
                        </button>
                      </span>
                    </div>
                  </div>

                </div> */}

                <div className="mt-6 flex items-center justify-between">
                    <div className="flex items-start">
                      <input id="terms" type="checkbox" onChange={this.handleTerms} className="form-checkbox h-4 w-4 text-green-600 transition duration-150 ease-in-out mt-1" />
                      <label htmlFor="remember_me" className="ml-2 block text-xs leading-5 text-gray-500">
                        By creating an account I am agreeing to the <Link to="/terms" className="text-green-400">Terms of Service</Link>.
                      </label>
                    </div>
                  </div>


              </div>
            </div>
          </div>
        );
    }
}

Register.defaultProps = {
    onClick() {}
}

const mapStateToProps = ({ auth }) => {
    const {
        authUser,
        loadingRegisterUser,
        registerUserError
    } = auth;
    return {
        authUser,
        loadingRegisterUser,
        registerUserError
    }
}

const mapDispatchToProps = {
    registerLicensee,
    resetRegisterLicensee
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Register));