
import * as actionTypes from '../actions/line/types';
import * as authActionTypes from '../actions/auth/actionTypes';

const initialState = {
    removeLineFromInterpretationLoading: false,
    removeLineFromInterpretationSuccess: false,
    removeLineFromInterpretationError: null,
};

function line(state = initialState, action) {
    switch(action.type) {

        case authActionTypes.LOGOUT_USER:
            return {
                ...state,
                removeLineFromInterpretationLoading: false,
                removeLineFromInterpretationSuccess: false,
                removeLineFromInterpretationError: null,
            }

        case actionTypes.REMOVE_LINE:
            return {
                ...state,
                removeLineFromInterpretationLoading: true,
                removeLineFromInterpretationSuccess: false,
                removeLineFromInterpretationError: null
            };

        case actionTypes.REMOVE_LINE_SUCCESS:
            return {
                ...state,
                removeLineFromInterpretationLoading: false,
                removeLineFromInterpretationSuccess: action.payload,
                removeLineFromInterpretationError: null
            };

        case actionTypes.REMOVE_LINE_FAIL:
            return {
                ...state,
                removeLineFromInterpretationLoading: false,
                removeLineFromInterpretationSuccess: false,
                removeLineFromInterpretationError: action.payload
            };

        default:
            return state;
    }
}

export default line;