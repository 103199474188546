import * as actionTypes from './types';

export const removeLineFromInterpretation = (lineRefId) => ({
    type: actionTypes.REMOVE_LINE,
    payload: { lineRefId }
});

export const removeLineFromInterpretationSuccess = (data) => ({
    type: actionTypes.REMOVE_LINE_SUCCESS,
    payload: data
});

export const removeLineFromInterpretationFail = (error) => ({
    type: actionTypes.REMOVE_LINE_FAIL,
    payload: error
});