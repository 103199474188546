import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import { routerMiddleware } from 'react-router-redux';
import swingbotSaasApp from '../reducers'
import ReduxThunk from 'redux-thunk';

// Example, not using this
// function logger({ getState }) {
//     return next => action => {
//       // Call the next dispatch method in the middleware chain.
//       const returnValue = next(action)
//       // This will likely be the action itself, unless
//       // a middleware further in chain changed it.
//       return returnValue
//     }
//   }

// Middleware
const middleware = () => applyMiddleware(ReduxThunk);
// persist
const persistConfig = {
  key: 'root',
  storage
};
// create the persisted reducer
const persistedReducer = persistReducer(persistConfig, swingbotSaasApp);
let store = createStore(persistedReducer, middleware());
let persistor = persistStore(store);

export {
  store, 
  persistor
}