import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'; 
import Transition from './Transition';
import { DashboardMenu } from './index';

class DashboardSidebar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    }
  }

  handleCloseMenu = (e) => {
    this.setState({
      isOpen: false,
    });
  }

  handleOpenMenu = (e) => {
    this.setState({
      isOpen: true,
    })
  }

  render() {
    const { auth } = this.props;
    const cssClasses = this.state.isOpen === true ? 'transition-opacity ease-linear duration-300 opacity-100' : 'transition-opacity ease-linear duration-300 opacity-0 hidden';
    return (
      <div className="h-screen flex overflow-hidden bg-gray-100">
      {/* <!-- Off-canvas menu for mobile --> */}
      <div className={`${cssClasses}`}>
        <div className="fixed inset-0 flex z-40">
          {/* this is the background overlay */}
          <Transition
            show={this.state.isOpen}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            // leave="transition-opacity ease-linear duration-300"
            // leaveFrom="opacity-100"
            // leaveTo="opacity-100"
          >
          <div className="fixed inset-0">
            <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
          </div>
          </Transition>
          {/* this is the menu itself */}
          
          <Transition
            show={this.state.isOpen}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
          <div className="relative flex-0 flex flex-col max-w-xs w-full bg-gray-800">
            <div className="absolute top-0 right-0 -mr-14 p-1">
              <button id="close-icon" onClick={this.handleCloseMenu} className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600" aria-label="Close sidebar">
                <svg className="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div className="flex-shrink-0 flex items-center px-4">
                <img className="h-8 w-auto" src={require('../../img/logo.png')} alt="Workflow" />
                <p className="text-xl text-gray-400 ml-2">SwingbotPro</p>
              </div>
              <DashboardMenu onClick={this.handleCloseMenu} />
            </div>
            <div className="flex-shrink-0 flex bg-gray-700 p-4 cursor-pointer">
              <div onClick={() => this.props.history.push('/account')}  className="flex-shrink-0 group block cursor-pointer" alt="">
                <div className="flex items-center">
                  <div>
                    <img className="inline-block h-9 w-9 rounded-full" src={auth.authUser.avatar} alt="user" />
                  </div>
                  {this.props.authUser && (
                    <div className="ml-3">
                      <p className="text-base leading-6 font-medium text-white">
                        {this.props.authUser.full_name}
                      </p>
                      <p className="text-sm leading-5 font-medium text-gray-400 group-hover:text-gray-300 transition ease-in-out duration-150">
                        View profile
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          </Transition>
          <div className="flex-shrink-0 w-14">
            {/* <!-- Force sidebar to shrink to fit close icon --> */}
          </div>
        </div>
      </div>
    
      {/* <!-- Static sidebar for desktop --> */}
      <div id="static" className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64 bg-gray-800">
          <div className="h-0 flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img className="h-8 w-auto rounded-lg" src={require('../../img/logo.png')} alt="Swingbot Pro" />
              <p className="text-xl text-gray-400 ml-2">SwingbotPro</p>
            </div>
            {/* <!-- Sidebar component, swap this element with another sidebar if you like --> */}
            <DashboardMenu />
          </div>
          {auth.authUser && (
            <div className="flex-shrink-0 flex bg-gray-700 p-4 cursor-pointer">
              <div onClick={() => this.props.history.push('/account')} className="flex-shrink-0 w-full group block">
                <div className="flex items-center">
                  <div>
                    <img className="inline-block h-9 w-9 rounded-full" src={auth.authUser.avatar} alt="" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm leading-5 font-medium text-white">
                      {auth.authUser.f_name}
                    </p>
                    <p className="text-xs leading-4 font-medium text-gray-300 group-hover:text-gray-200 transition ease-in-out duration-150">
                      View profile
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button onClick={this.handleOpenMenu} className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150" aria-label="Open sidebar">
            <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"/>
            </svg>
          </button>
        </div>
          {/* content goes here */}
          {this.props.children}
          {/* end content */}
      </div>
    </div>     
    )
  }
}

DashboardSidebar.defaultProps = {
  children: [],
  title: 'Dashboard Title'
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return {
    auth,
    authUser
  };
}

const mapDispatchToProps = {}

const dashboardWithRouter = withRouter(DashboardSidebar);
export default connect(mapStateToProps, mapDispatchToProps)(dashboardWithRouter);