/**
 * LicenseeReducer
 *
 * Fetch the list of users from the database
 */
import * as actionTypes from '../actions/licensee/actionTypes';
import * as authActionTypes from '../actions/auth/actionTypes';

  // set the initial state of the reducer
    const INITIAL_STATE = {
        licenseeStatistics: null,
        loadingLicenseeStatistics: false,
        loadingLicenseeStatisticsError: null,

        licenseeData: null,
        loadingLicenseeData: false,
        loadingLicenseeDataError: null,

        licenseeDataByEmail: null,
        loadingLicenseeDataByEmail: false,
        loadingLicenseeDataByEmailError: null,

        analysesData: null,
        loadingAnalysesData: false,
        loadingAnalysesDataError: null,

        analysesDataForUser: [],
        analysesDataForUserLoading: false,
        analysesDataForUserError: null,

        countsByDay: null,
        countsByDayLoading: false,
        countsByDayError: null,
    };
  
   export default (state = INITIAL_STATE, action) => {
     switch (action.type) {

      case authActionTypes.LOGOUT_USER:
         return {
            ...state,
            
            licenseeStatistics: null,
            loadingLicenseeStatistics: false,
            loadingLicenseeStatisticsError: null,
    
            licenseeData: null,
            loadingLicenseeData: false,
            loadingLicenseeDataError: null,
    
            analysesData: null,
            loadingAnalysesData: false,
            loadingAnalysesDataError: null,
    
            countsByDay: null,
            countsByDayLoading: false,
            countsByDayError: null,

            licenseeDataByEmail: null,
            loadingLicenseeDataByEmail: false,
            loadingLicenseeDataByEmailError: null,
         }
      /**
       * statistics
       */
      case actionTypes.GET_STATISTICS:
         return {
            ...state,
            licenseeStatistics: null,
            loadingLicenseeStatistics: true,
            loadingLicenseeStatisticsError: null,
         }

       case actionTypes.GET_STATISTICS_SUCCESS:
         return {
            ...state,
            licenseeStatistics: action.payload[0],
            loadingLicenseeStatistics: false,
            loadingLicenseeStatisticsError: null,
         }

       case actionTypes.GET_STATISTICS_FAIL:
         return {
            ...state,
            licenseeStatistics: null,
            loadingLicenseeStatistics: false,
            loadingLicenseeStatisticsError: action.payload,
         }
         /**
          * licensee data
          */
       case actionTypes.GET_LICENSEE_DATA:
         return {
            ...state,
            licenseeData: null,
            loadingLicenseeData: true,
            loadingLicenseeDataError: null,
         }

       case actionTypes.GET_LICENSEE_DATA_SUCCESS:
         return {
            ...state,
            licenseeData: action.payload,
            loadingLicenseeData: false,
            loadingLicenseeDataError: null,
         }

       case actionTypes.GET_LICENSEE_DATA_FAIL:
         return {
            ...state,
            licenseeData: null,
            loadingLicenseeData: false,
            loadingLicenseeDataError: action.payload,
         }

      case actionTypes.GET_LICENSEE_DATA_BY_EMAIL:
         return {
            ...state,
            licenseeDataByEmail: null,
            loadingLicenseeDataByEmail: true,
            loadingLicenseeDataByEmailError: null,
         }

      case actionTypes.GET_LICENSEE_DATA_BY_EMAIL_SUCCESS:
         return {
            ...state,
            licenseeDataByEmail: action.payload,
            loadingLicenseeDataByEmail: false,
            loadingLicenseeDataByEmailError: null
         }

      case actionTypes.GET_LICENSEE_DATA_BY_EMAIL_FAIL:
         return {
            ...state,
            licenseeDataByEmail: null,
            loadingLicenseeDataByEmail: false,
            loadingLicenseeDataByEmailError: action.payload,
         }

      case actionTypes.GET_LICENSEE_ANALYSES:
         return {
            ...state,
            analysesData: null,
            loadingAnalysesData: true,
            loadingAnalysesDataError: null,
         }

       case actionTypes.GET_LICENSEE_ANALYSES_SUCCESS:
         return {
            ...state,
            analysesData: action.payload,
            loadingAnalysesData: false,
            loadingAnalysesDataError: null,
         }

       case actionTypes.GET_LICENSEE_ANALYSES_FAIL:
         return {
            ...state,
            analysesData: null,
            loadingAnalysesData: false,
            loadingAnalysesDataError: action.payload,
         }

         case actionTypes.GET_LICENSEE_ANALYSES_FOR_USER:
            return {
               ...state,
               analysesDataForUser: [],
               analysesDataForUserLoading: true,
               analysesDataForUserError: null,
            }
   
          case actionTypes.GET_LICENSEE_ANALYSES_FOR_USER_SUCCESS:
            return {
               ...state,
               analysesDataForUser: action.payload,
               analysesDataForUserLoading: false,
               analysesDataForUserError: null,
            }
   
          case actionTypes.GET_LICENSEE_ANALYSES_FOR_USER_FAIL:
            return {
               ...state,
               analysesDataForUser: [],
               analysesDataForUserLoading: false,
               analysesDataForUserError: action.payload,
            }

        case actionTypes.UPDATE_API_KEY_VALUE:
            // set the new api key here...
            const newLicenseeData = state.licenseeData
            newLicenseeData.api_key = action.payload;
         return {
            ...state,
            licenseeData: newLicenseeData
         }

         case actionTypes.GET_COUNTS_BY_DAY:
         return {
            ...state,
            countsByDay: null,
            countsByDayLoading: true,
            countsByDayError: null,
         }

         case actionTypes.GET_COUNTS_BY_DAY_SUCCESS:
            return {
               ...state,
               countsByDay: action.payload,
               countsByDayLoading: false,
               countsByDayError: null,
            }

         case actionTypes.GET_COUNTS_BY_DAY_FAIL:
            return {
               ...state,
               countsByDay: null,
               countsByDayLoading: false,
               countsByDayError: action.payload,
            }

       default:
         return state;
     }
   };
  

   export const selectors = {
      licenseeDataByEmail:(state) => {
       return state.licenseeDataByEmail
         ? {
            isLicensee: state.licenseeDataByEmail.licensee_id !== null,
            isUser: state.licenseeDataByEmail.user_id !== null,
            email: state.licenseeDataByEmail.user !== null ? state.licenseeDataByEmail.user.email : null,
            firstName: state.licenseeDataByEmail.user !== null ? state.licenseeDataByEmail.user.f_name : null,
            lastName: state.licenseeDataByEmail.user !== null ? state.licenseeDataByEmail.user.l_name : null,
         } : {
            isUser: false,
            isLicensee: false
         };
      },
      licenseeData:(state) => {
         const transactions = state.licenseeData 
            ? state.licenseeData.transactions.map(t => {
               const subscription = t.subscriptionData;
               // const receipt = {
               //    orderId: t.receipt_data.id,
               //    price: t.receipt_data.price,
               //    plan: t.receipt_data.planId
               // };
               //t.receipt = receipt;
               // delete t['receipt_data'];
               t.subscription = subscription;
               return t;
            }) : [];
       return state.licenseeData 
         ? {
            ...state.licenseeData,
            t: transactions
         }
         : null;
      },
      analysesForUser: (state) => {
         return {
            data: state.analysesDataForUser,
            loading: state.analysesDataForUserLoading,
            error: state.analysesDataForUserError
         };
      },
      analysesData: (state) => {
         return state.analysesData !== null ? {
            count: state.analysesData.count,
            data: state.analysesData.data,
            loading: state.loadingAnalysesData,
            error: state.loadingAnalysesDataError
         } : {
            count: 0,
            data: null,
            loading: state.loadingAnalysesData,
            error: state.loadingAnalysesDataError
         }
      }
    }