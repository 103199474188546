import * as actionCreators from './actions';
import * as services from './services';

export const analyzeVideo = (videoId) => dispatch => {
    dispatch(actionCreators.analyzeVideo(videoId));
    return services.analyzeVideo(videoId)
        .then(response => dispatch(actionCreators.analyzeVideoSuccess(response.data)))
        .catch(e => dispatch(actionCreators.analyzeVideoFail(e)));
}

export const resetAnalyzeVideo = () => dispatch => {
    dispatch(actionCreators.resetAnalyzeVideo());
}

export const convertCalculationToEnglish = (pseudo) => dispatch => {
    dispatch(actionCreators.convertCalculationToEnglish(pseudo));
    return services.convertCalculationToEnglish(pseudo)
        .then(response => dispatch(actionCreators.convertCalculationToEnglishSuccess(response.data)))
        .catch(e => dispatch(actionCreators.convertCalculationToEnglishFail(e)));
}

export const convertEquationToEnglish = (pseudo1, operand, pseudo2) => dispatch => {
    dispatch(actionCreators.convertEquationToEnglish(pseudo1, operand, pseudo2));
    return services.convertEquationToEnglish(pseudo1, operand, pseudo2)
        .then(response => dispatch(actionCreators.convertEquationToEnglishSuccess(response.data)))
        .catch(e => dispatch(actionCreators.convertEquationToEnglishFail(e)));
}