import React from 'react';
import { withRouter } from 'react-router-dom'; 
import { Button, LearnMore } from '../common';
class PageHeader extends React.Component {
    
    handleClickBackButton = () => {
        if (this.props.onClickBackButton !== null) {
            this.props.onClickBackButton();
        } else {
            this.props.history.goBack();
        }
    }

    render() {
        const { 
            back,
            buttonClassColor, 
            buttonTitle, 
            buttonDisabled,
            subtitle, 
            title, 
            onClick,
            docPage
        } = this.props;
        return (
            <div className="bg-white py-6 w-full border-b border-t shadow px-4">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    {back && (
                        <div>
                        <nav className="mb-1">
                            <div onClick={this.handleClickBackButton} className="flex items-center text-sm leading-5 font-medium text-gray-600 hover:text-gray-700 transition duration-150 ease-in-out cursor-pointer">
                                <svg className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                </svg>
                                Back
                            </div>
                            </nav>
                        </div>
                    )}
                <div className="md:flex md:justify-between">
                    <div className="flex-1 min-w-0">
                        <h2 className="text-2xl font-bold leading-7 text-gray-800 sm:text-3xl sm:leading-9 sm:truncate">
                            {title}{docPage !== null ? (
                                <LearnMore docPage={docPage} className="px-2" />
                             ) : null}
                        </h2>
                        {subtitle !== null && (
                            <p className="text-gray-600 font-medium pt-1">{subtitle}</p>
                        )}
                    </div>
                    {buttonTitle !== null && (
                        <div className="flex-shrink-0 flex md:mt-0 items-center justify-center">
                            <Button 
                                title={buttonTitle}
                                onClick={onClick}
                                bgColor={buttonClassColor}
                                hoverColor={buttonClassColor}
                                disabled={buttonDisabled}
                            />
                        </div>
                    )}
                    </div>
                </div>
            </div>
        );
    }
}


            
PageHeader.defaultProps = {
    title: '',
    back: false,
    subtitle: null,
    buttonTitle: null,
    buttonClassColor: "green",
    buttonDisabled: false,
    handleButtonClick(){},
    onClickBackButton: null,
    docPage: null
}

export default withRouter(PageHeader);