/**
 * Login component
 */
import React from 'react';
import { connect } from 'react-redux';
import { loginUser, loginUserReset } from '../../actions/auth';
import { withRouter, Link } from 'react-router-dom';
import Loading from '../common/Loading';
import Button from '../common/Button';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        }
    }

    componentDidMount = () => {
        this.props.loginUserReset();
    }

    handleEmailKeypress = (e) => {
        this.setState({
            email: e.target.value
        });
    }

    handlePasswordKeypress = (e) => {
        this.setState({
            password: e.target.value
        });
    }

    handleLogin = (e) => {
        e.preventDefault();
        const { email, password } = this.state;
        if (email !== "" && password !== "") {
            this.props.loginUser(email, password, this.props.history);
        }
    }

    render() {
        const { loadingLoginUser } = this.props;
        return (
            <div className="min-h-screen bg-gray-800 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-200">
                Sign in to your account
                </h2>
                <p className="mt-2 text-center text-sm leading-5 text-gray-200 max-w">
                or&nbsp;
                <Link to="/" className="font-medium text-green-500 hover:text-green-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                    create your free account
                </Link>
                </p>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    {/* <div className="py-2">
                        <img className="mx-auto h-12 w-auto" src={require('../../img/logo.png')} alt="Swingbot SaaS" />
                    </div> */}
                    {this.props.loginUserError !== null && (
                        <div className="rounded-md bg-red-50 p-4">
                            <div className="flex">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd"/>
                                </svg>
                            </div>
                            <div className="ml-3">
                                <h3 className="text-sm leading-5 font-medium text-red-800">
                                {/* {this.props.loginUserError} */}
                                </h3>
                                {/* <div className="mt-2 text-sm leading-5 text-red-700">
                                <ul className="list-disc pl-5">
                                    <li>
                                    Your password must be at least 8 characters
                                    </li>
                                    <li className="mt-1">
                                    Your password must included at least one pro wrestling finishing move
                                    </li>
                                </ul>
                                </div> */}
                            </div>
                            </div>
                        </div>
                    )}
                {loadingLoginUser === false && (
                    <form onSubmit={this.handleLogin}>
                        <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                            Email address
                        </label>
                        <div className="mt-1 rounded-md shadow-sm">
                            <input 
                                id="email" 
                                type="email" 
                                autoComplete="username" 
                                required 
                                onChange={this.handleEmailKeypress}
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                        </div>
                        </div>

                        <div className="mt-6">
                        <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
                            Password
                        </label>
                        <div className="mt-1 rounded-md shadow-sm">
                            <input 
                                id="password" 
                                type="password" 
                                autoComplete="current-password" 
                                required 
                                onChange={this.handlePasswordKeypress}
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                        </div>
                        </div>

                        <div className="mt-6 flex items-center justify-end">
                            <div className="text-sm leading-5">
                                <Link to="/forgot" className="font-medium text-green-600 hover:text-green-500 focus:outline-none focus:underline transition ease-in-out duration-150 text-sm">
                                Forgot your password?
                                </Link>
                            </div>
                        </div>

                        <div className="mt-6">
                            <span className="block w-full rounded-md shadow-sm">
                                <Button 
                                    title="Sign in"
                                    disabled={this.props.loadingLoginUser === true}
                                    bgColor="green"
                                    hoverColor="green"
                                    block
                                    onClick={this.handleLogin}
                                    submit
                                />
                        </span>
                        </div>
                    </form>
                )}
                {loadingLoginUser === true && <Loading title="Signing In" />}
                <p className="text-xs text-gray-200 mt-2 text-center">{`version ${process.env.REACT_APP_VERSION}`}</p>
                </div>
            </div>
            </div>
        );
    }
}

const mapStatetoProps = ({ auth }) => {
    const { 
        authUser,
        loadingLoginUser,
        loginUserError,
        token,
        checkingToken,
        checkingTokenError, 
    } = auth;
    return { 
        authUser,
        loadingLoginUser,
        loginUserError,
        token,
        checkingToken,
        checkingTokenError
    };
};

const mapDispatchToProps = {
    loginUser,
    loginUserReset
}
const loginWithRouter = withRouter(Login);
export default connect(mapStatetoProps, mapDispatchToProps)(loginWithRouter);