import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DashboardContent } from '../tailwind';
import { getLicenseeDataByUserId } from '../../actions/licensee';
import { createApiKey, createApiKeyReset } from '../../actions/api';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { Loading, Button } from '../common';
import { Container } from '../tailwind';
import ModalTW from '../common/modal/ModalTW';
import ApiSample from '../common/ApiSample';
class ApiKeysPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            file: '',
            uploading: false,
            confirmApiKeyModalShow: false,
        }
    }

    componentDidMount = () => {
        const { authUser } = this.props;
        this.props.getLicenseeDataByUserId(authUser.user_id);
        this.props.createApiKeyReset();
    }
/*
address: "8580 Niblick Drive"
api_key: "1-CUUYX8CEUPGO-FNH2COV3JQR"
business_display_name: "Swingbot"
business_lexicon_name: "swingbot"
city: "Alpharetta"
contact_email: "john@swingbot.com"
contact_fname: "John"
contact_lname: "Giatropoulos"
country: "USA"
credits_available: 15
date_created: "0000-00-00 00:00:00"
licensee_id: 1
logo_url: ""
phone: ""
state: "GA"
status: "ACTIVE"
user_id: 1
zip: 30022
*/

    renderName = (licenseeData) => {
        return  licenseeData
            ? `${licenseeData.api_key}`
            : null;
    }

    handleUploadError = (e) => {}

    handleUploadSuccess = (result) => {
        // console.log(result);
    }

    handleCreateApiKey = () => {
        this.setState({
            confirmApiKeyModalShow: true,
        })
    }

    handleCloseModal = () => {
        this.setState({
            confirmApiKeyModalShow: false
        });
    }

    handleConfirmCreateApiKey = () => {
        const { authUser } = this.props;
        this.props.createApiKey(authUser.licensee_id);
    }

    handleCopyToClipboard = () => {
        /* Get the text field */
        var copyText = document.getElementById("api-key");
        /* Select the text field */
        copyText.select(); 
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/
        /* Copy the text inside the text field */
        document.execCommand("copy");
        /* Alert the copied text */
        alert("Copied " + copyText.value);
    }

    renderConfirmModal = () => {
        const {
            createApiKeySuccess,
            createApiKeyLoading,
            createApiKeyError
        } = this.props;
        console.log(createApiKeyError, createApiKeyLoading, createApiKeySuccess);
        return (
            <ModalTW show={this.state.confirmApiKeyModalShow}>
                {createApiKeyLoading === false && createApiKeySuccess === null && (
                    <div className="flex flex-col justify-between p-6 space-y-4">
                        <div className="flex flex-col mb-6 space-y-2">
                            <h2 className="text-xl font-bold mb-2">Confirm Generate New Key</h2>
                                <p className="font-medium">Are you sure you wish to Generate a new Api Key?</p>
                                <ul className="list-inside list-disc">
                                    <li>This will invalidate your current key.</li>
                                    <li>You will need to replace the keys in your applications.</li>
                                </ul>
                        </div>
                        <div className="flex justify-end space-x-2">
                            <Button 
                                bgColor="gray"
                                hoverColor="gray"
                                onClick={this.handleCloseModal}
                                title="No, thanks"
                            />    
                            <Button 
                                bgColor="red"
                                hoverColor="red"
                                onClick={this.handleConfirmCreateApiKey}
                                title="Yes, Generate a new API Key"
                            />    
                        </div>
                    </div>
                )}
                {createApiKeyLoading === true && (
                    <Loading />
                )}
                {createApiKeySuccess !== null && (
                    <div className="flex flex-col justify-between p-6 space-y-4">
                        <div className="flex flex-col mb-6 space-y-2">
                            <h2 className="text-xl font-bold mb-2">Key Generation Complete</h2>
                        </div>
                        <div className="flex justify-end space-x-2">
                            <Button 
                                bgColor="gray"
                                hoverColor="gray"
                                onClick={() => {
                                    this.props.history.go();
                                    this.handleCloseModal();
                                }}
                                title="Close Window"
                            />    
                        </div>
                    </div>
                )}
            </ModalTW>
        )
    };

    render() {
        const { 
            licenseeData,
            loadingLicenseeData,
            loadingLicenseeDataError,
            authUser
        } = this.props;
        return (
            <DashboardContent 
                title="Api Key"
                docPage={'/doc/sdk'}
            >
                {loadingLicenseeData === true && !licenseeData && <Loading />}
                {loadingLicenseeData === false && !licenseeData && (
                    // <div className="bg-white shadow overflow-hidden sm:rounded-lg w-full">
                    <Container>
                        <div className="px-4 py-5 border-b border-gray-200 sm:px-6 w-full">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Your Account Information
                            </h3>
                            <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-700">
                            Personal details and application.
                            </p>
                        </div>
                        <div className="px-4 py-5 sm:p-0">
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <h2>No data to display</h2>
                            </div>
                        </div>
                    </Container>
                )}
                {(loadingLicenseeData === false && loadingLicenseeDataError === null && licenseeData) && (
                    <div className="flex flex-col w-full">
                        <Container>
                            <div className="flex flex-col md:flex-row w-full space-x-0 md:space-x-4 p-4">
                                <div className="flex flex-col md:w-1/2">
                                    <p className="mt-1 max-w-2xl text-lg leading-6 text-gray-700 mb-4"><span className="font-bold underline">Do not share this key with anyone</span>. You MUST keep this key secret as this is the key that will allow your developer to access the Swingbot Pro API.
                                    </p>
                                    <p className="mt-1 max-w-2xl text-lg leading-6 text-gray-700">If you feel your API Key has been lost or compromised, please <div className="underline cursor-pointer" onClick={this.handleCreateApiKey}>generate a new key</div>
                                    </p>
                                </div>
                                <div className="flex-col flex-1 mt-1 leading-5 text-gray-900 sm:mt-0 font-bold w-full">
                                    <div className="mt-1 flex flex-grow rounded-md shadow-sm">
                                        <div className="relative flex-grow focus-within:z-10">
                                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <FontAwesomeIcon icon={faKey} className="text-gray-300 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                                            </div>
                                            <input 
                                                id="api-key" 
                                                value={this.renderName(licenseeData)} 
                                                className="form-input pl-10 block w-full rounded-none rounded-l-md transition ease-in-out duration-150 sm:text-sm sm:leading-5 text-white font-bold bg-indigo-700" 
                                                placeholder="Api Key" 
                                                readOnly
                                            />
                                        </div>
                                        <button onClick={this.handleCopyToClipboard} className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                                            <FontAwesomeIcon icon={faClipboard} className="text-gray-600 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
                                        </button>
                                    </div>
                                    <div className="mt-4 text-right">
                                        <div
                                            onClick={this.handleCreateApiKey} 
                                            className="text-sm text-gray-500 underline font-normal cursor-pointer"
                                        >
                                            Generate New Key
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                        

                <Container>
                    <ApiSample 
                        campaignId={`'<lessonid>'`} 
                        email={authUser.email} 
                        apiKey={this.renderName(licenseeData)}
                        title="Sample Upload Video"
                    />
                </Container>

                {/* <Container>
                    <ApiSample 
                        campaignId={`'<lessonid>'`} 
                        email={authUser.email} 
                        apiKey={licenseeData.api_key} 
                    />
                </Container> */}


                
                        </div>
                    )
                }
                {this.renderConfirmModal()}
            </DashboardContent>
        )
    }
}

const mapStateToProps = ({ licensee, auth, api }) => {
    const { authUser } = auth;
    const { 
        licenseeData,
        loadingLicenseeData,
        loadingLicenseeDataError
    } = licensee;
    const {
        createApiKeySuccess,
        createApiKeyLoading,
        createApiKeyError
    } = api;
    return {
        authUser,
        licenseeData,
        loadingLicenseeData,
        loadingLicenseeDataError,
        createApiKeyLoading,
        createApiKeyError,
        createApiKeySuccess
    };
}

const mapDispatchToProps = {
    getLicenseeDataByUserId,
    createApiKey,
    createApiKeyReset
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeysPage);