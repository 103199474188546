import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class LearnMore extends React.Component {

  handleClick = () => {
    try {
      const { history, docPage } = this.props;
      if (docPage !== '') {
        history.push(docPage);
      } else {
        history.push('/doc');
      }
    } catch(e) {
    }
  }

  render() {
    const { className } = this.props;
    return (
      <span className={`cursor-pointer text-indigo-700 font-medium underline text-sm ${className}`} onClick={this.handleClick}>Learn More</span>
    );
  }
}

LearnMore.defaultProps = {
  docPage: '/doc',
  className: null
}

const learnMoreWithRouter = withRouter(LearnMore);
export default connect(null, null)(learnMoreWithRouter);