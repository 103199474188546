import * as actionTypes from './actionTypes';
/**
 * statisticsByLicenseeId
 * @param {int} licenseeId
 */
export const statisticsByLicenseeId = (licenseeId, startDate) => ({
    type: actionTypes.GET_STATISTICS,
    payload: { licenseeId, startDate }
});

export const statisticsByLicenseeIdSuccess = (data) => ({
    type: actionTypes.GET_STATISTICS_SUCCESS,
    payload: data
});

export const statisticsByLicenseeIdFail = (error) => ({
    type: actionTypes.GET_STATISTICS_FAIL,
    payload: error
});

/**
 * get licensee data by user id
 * @param {int} userId 
 */
export const getLicenseeDataByUserId = (userId) => ({
    type: actionTypes.GET_LICENSEE_DATA,
    payload: userId
});

export const getLicenseeDataByUserIdSuccess = (data) => ({
    type: actionTypes.GET_LICENSEE_DATA_SUCCESS,
    payload: data
});

export const getLicenseeDataByUserIdFail = (error) => ({
    type: actionTypes.GET_LICENSEE_DATA_FAIL,
    payload: error
});

// get licensee data by email

export const getLicenseeDataByEmail = (email) => ({
    type: actionTypes.GET_LICENSEE_DATA_BY_EMAIL,
    payload: email
});

export const getLicenseeDataByEmailSuccess = (data) => ({
    type: actionTypes.GET_LICENSEE_DATA_BY_EMAIL_SUCCESS,
    payload: data
});

export const getLicenseeDataByEmailFail = (error) => ({
    type: actionTypes.GET_LICENSEE_DATA_BY_EMAIL_FAIL,
    payload: error
});

// get licensee analyses

export const getLicenseeAnalyses = (licenseeId) => ({
    type: actionTypes.GET_LICENSEE_ANALYSES,
    payload: licenseeId
});

export const getLicenseeAnalysesSuccess = (data) => ({
    type: actionTypes.GET_LICENSEE_ANALYSES_SUCCESS,
    payload: data
});

export const getLicenseeAnalysesFail = (error) => ({
    type: actionTypes.GET_LICENSEE_ANALYSES_FAIL,
    payload: error
});

/**
 * getLicenseeAnalysesForUser
 * @param {int} licenseeId 
 * @param {int} userId
 * @returns 
 */
export const getLicenseeAnalysesForUser = (licenseeId, userId) => ({
    type: actionTypes.GET_LICENSEE_ANALYSES_FOR_USER,
    payload: { licenseeId, userId }
});

export const getLicenseeAnalysesForUserSuccess = (data) => ({
    type: actionTypes.GET_LICENSEE_ANALYSES_FOR_USER_SUCCESS,
    payload: data
});

export const getLicenseeAnalysesForUserFail = (error) => ({
    type: actionTypes.GET_LICENSEE_ANALYSES_FOR_USER_FAIL,
    payload: error
});

export const updateApiKeyValue = (apiKey) => ({
    type: actionTypes.UPDATE_API_KEY_VALUE,
    payload: apiKey
});


export const getLessonCountsByDay = (licenseeId) => ({
    type: actionTypes.GET_COUNTS_BY_DAY,
    payload: { licenseeId }
});

export const getLessonCountsByDaySuccess = (data) => ({
    type: actionTypes.GET_COUNTS_BY_DAY_SUCCESS,
    payload: data
});

export const getLessonCountsByDayFail = (error) => ({
    type: actionTypes.GET_COUNTS_BY_DAY_FAIL,
    payload: error
});