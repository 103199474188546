import * as actionTypes from './types';

export const resetSlackWebhook = () => ({
    type: actionTypes.RESET_SLACK_WEBHOOK,
    payload: null
});

export const verifySlackCode = (code) => ({
    type: actionTypes.VERIFY_SLACK_CODE,
    payload: { code }
});

export const verifySlackCodeSuccess = (data) => ({
    type: actionTypes.VERIFY_SLACK_CODE_SUCCESS,
    payload: data
});

export const verifySlackCodeFail = (error) => ({
    type: actionTypes.VERIFY_SLACK_CODE_FAIL,
    payload: error
});

/**
 * Save Webhook for Slack
 */
export const saveSlackWebhookForLicensee = (licenseeId, channel, webhook) => ({
    type: actionTypes.CREATE_SLACK_WEBHOOK,
    payload: { licenseeId, channel, webhook }
});

export const saveSlackWebhookForLicenseeSuccess = (data) => ({
    type: actionTypes.CREATE_SLACK_WEBHOOK_SUCCESS,
    payload: data
});

export const saveSlackWebhookForLicenseeFail = (error) => ({
    type: actionTypes.CREATE_SLACK_WEBHOOK_FAIL,
    payload: error
});

/**
 * get slack webhook
 */
 export const getSlackWebhookForLicensee = (licenseeId) => ({
    type: actionTypes.GET_SLACK_WEBHOOK,
    payload: { licenseeId }
});

export const getSlackWebhookForLicenseeSuccess = (data) => ({
    type: actionTypes.GET_SLACK_WEBHOOK_SUCCESS,
    payload: data
});

export const getSlackWebhookForLicenseeFail = (error) => ({
    type: actionTypes.GET_SLACK_WEBHOOK_FAIL,
    payload: error
});