import React from 'react';
import './StripeButton.css';

class StripeButton extends React.Component {
  render() {
    return (
      <div>
        <div onClick={this.props.onClick} className="stripe-connect cursor-pointer" alt="stripe"><span>Connect with</span></div>
      </div>
    );
  }
}

StripeButton.defaultProps = {
  onClick(){},
  title: 'Connect with'
}

export default StripeButton;