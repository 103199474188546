import React from 'react';

class WhatWeDo extends React.Component {
    render() {
        return (
            <div id="about" className="bg-white border border-b-2 border-gray-200">
                <div className="max-w-screen-lg mx-auto pt-12 pb-16 sm:pt-16 sm:pb-20 px-4 sm:px-6 lg:pt-20 lg:pb-28 lg:px-8">
                    <div className="w-full">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 py-6">
                        <div className="flex col-span-1 justify-center text-center">
                            <img className="object-contain" src={require('../../../img/screenshots/results-sample.png')} alt="features" />
                        </div>
                        <div className="col-span-1 lg:p-6">
                            <div className="prose prose-lg">
                                <h1>What We Do</h1>
                                <h4>We combine your golf knowledge with artificial intelligence to create compelling golf swing analysis</h4>
                                <p>We detect the body points and positions in the golf swing, and apply algorithms that you create to detect passing and failing conditions that you set.</p>
                            </div>
                            <div className="rounded-md text-white mt-12">
                                <a href="#get-started" className="items-center justify-center px-8 py-3 leading-6 rounded-md bg-green-600 hover:bg-green-700 transition duration-150 ease-in-out text-lg md:py-4 xl:text-xl md:text-lg md:px-10 cursor-pointer font-bold text-white no-underline">
                                Click to Get Started
                                </a>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WhatWeDo;