export const outputOptions = [
    {
      name: 'In Progress',
      value: 'INACTIVE'
    },
    {
      name: 'Complete',
      value: 'ACTIVE'
    },
    {
      name: 'Archived',
      value: 'ARCHIVED'
    }
  ];
  
export const liveOptions = [
    {
      name: 'Currently Available',
      value: 'PUBLIC'
    },
    {
      name: 'Currently Not Available',
      value: 'PRIVATE'
    },
  ];

export const getOutputStatus = (value) => {
    return outputOptions.filter(o => o.value === value)[0].name || null;
};

export const getLiveStatus = (value) => {
    console.log(value);
    try {
        return value !== "" ? liveOptions.filter(o => o.value === value)[0].name : 'PRIVATE';
    } catch(e) {
        return 'PRIVATE';
    }
};

export const menuItems = [
    {
        display: 'Dashboard',
    },
    {
        display: 'Users'
    },
    {
        display: 'Videos'
    },
];

export const calculationTypes = [
    {
        name: 'distance',
        display: 'Distance Between two points',
        points: 2,
        pseudo: 'DISTANCE'
    },
    {
        name: 'value-x',
        display: 'Horizontal position of the body point selected',
        points: 1,
        pseudo: 'VALUE-X'
    },
    {
        name: 'value-y',
        display: 'Vertical position of the body point selected',
        points: 1,
        pseudo: 'VALUE-Y'
    },
    {
        name: '3-point-angle',
        display: 'Angle formed by the body points selected',
        points: 3,
        pseudo: 'ANGLE'
    },
    {
        name: '2-point-angle',
        display: 'Angle from two points selected',
        points: 2,
        pseudo: 'ANGLE-TWO-POINT'
    },
    {
        name: 'diff-y',
        display: 'The vertical difference between two body points',
        points: 2,
        pseudo: 'DIFF-Y'
    },
    {
        name: 'diff-x',
        display: 'The horizontal difference between two body points',
        points: 2,
        pseudo: 'DIFF-X'
    },
    {
        name: 'midpoint-x',
        display: 'The horizontal midpoint between two body points',
        points: 2,
        pseudo: 'MIDPOINT-X'
    },
    {
        name: 'constant',
        display: 'The number',
        points: 0,
        pseudo: 'CONSTANT'
    }
];

export const equationOperands = [
    {
        name: 'less-than',
        pseudo: 'LESS_THAN',
        display: 'Less than'
    },
    {
        name: 'less-than-or-equal',
        pseudo: 'LESS_THAN_OR_EQUAL_TO',
        display: 'Less than or Equal to'
    },
    {
        name: 'greater-than',
        pseudo: 'GREATER_THAN',
        display: 'Greater than'
    },
    {
        name: 'greater-than-equal-to',
        pseudo: 'GREATER_THAN_OR_EQUAL_TO',
        display: 'Greater than or Equal to'
    },
    {
        name: 'equal',
        pseudo: 'EQUAL_TO',
        display: 'Equal to'
    },
];

export const positions = [
    {
        name: 'address',
        display: 'Address',
        number: 1
    },
    {
        name: 'takeaway',
        display: 'Takeaway',
        number: 2
    },
    {
        name: 'backswing',
        display: 'Backswing',
        number: 3
    },
    {
        name: 'at_the_top',
        display: 'the Top',
        number: 4
    },
    {
        name: 'downswing',
        display: 'Downswing',
        number: 5
    },
    {
        name: 'impact',
        display: 'Impact',
        number: 6
    },
    {
        name: 'follow_through',
        display: 'Follow Through',
        number: 7
    },
    {
        name: 'finish',
        display: 'Finish',
        number: 8
    }
];

export const bodyPoints = [
    {
        name: 'head',
        display: 'Head',
        number: 1
    },
    {
        name: 'right_shoulder',
        display: 'Rear Shoulder',
        number: 2
    },
    {
        name: 'left_shoulder',
        display: 'Front Shoulder',
        number: 3
    },
    {
        name: 'right_elbow',
        display: 'Rear Elbow',
        number: 4
    },
    {
        name: 'left_elbow',
        display: 'Front Elbow',
        number: 5
    },
    {
        name: 'hands',
        display: 'Hands',
        number: 6
    },
    {
        name: 'left_hip',
        display: 'Front Hip',
        number: 7
    },
    {
        name: 'right_hip',
        display: 'Rear Hip',
        number: 8
    },
    {
        name: 'left_knee',
        display: 'Front Knee',
        number: 9
    },
    {
        name: 'right_knee',
        display: 'Rear Knee',
        number: 10
    },
    {
        name: 'left_foot',
        display: 'Front Ankle',
        number: 11
    },
    {
        name: 'right_foot',
        display: 'Rear Ankle',
        number: 12
    },
];

export const lineTypes = [
    {
        name: 'p2p',
        display: 'Point to Point',
    },
    {
        name: 'vertical',
        display: 'Vertical',
    },
    {
        name: 'horizontal',
        display: 'Horizontal',
    }
];

export const lineColors = [
    {
        name: '#FFFF00',
        display: 'Yellow',
    },
    {
        name: '#76EE00',
        display: 'Green',
    },
    {
        name: '#0000FF',
        display: 'Blue',
    },
    {
        name: '#FF0000',
        display: 'Red',
    }
];

export const getDisplayForLineType = (type) => {
    return lineTypes.filter(lt => lt.name === type)[0].display;
}

export const getPositionObjectFromName = (name) => {
    return positions.filter(position => position.name === name)[0];
}



export const getCalculationInstructions = (pseudo) => {
    // based on the calculation, we can display the instructions for selecting the points...
    let text = '';
    switch(pseudo) {
        case 'DISTANCE':
            text = 'Select the two body parts you would like to calculate the distance between.';
        break;

        case 'DIFF-X':
            text = 'Select the two body parts you would like to calculate the difference between.';
        break;

        case 'DIFF-Y':
            text = 'Select the two body parts you would like to calculate the difference between.';
        break;

        case 'VALUE-X':
            text = 'Select the body part you would like to use for the body part position comparison.';
        break;

        case 'VALUE-Y':
            text = 'Select the body part you would like to use for the body part position comparison.';
        break;

        case 'ANGLE':
            text = 'Select three body parts that form the angle desired. The origin of the angle will be the middle body part.';
        break;

        case 'ANGLE-TWO-POINT':
            text = 'Select two body parts to use in this angle calculation.';
        break;

        case 'CONSTANT':
            text = 'Enter a number to be used in the comparison calculation';
        break;

        default:
            break;
    }
    return text;
}
// handle the analysis results and pass the values onto the ImageLineItem to draw

export const getEquationResultCode = (equationResult) => {
    return equationResult.length > 0 && (equationResult[0].key || {})
      ? ((equationResult[0] || {}).key || {}).run_code || false
      : false;
}

export const getLinesForCode = (data, code) => {
    return code !== false
        ? data.lines.filter(line => {
            return line.code === code;
        })
        : false;
}

/**
 * getPointsForPosition
 * @param {string} position the lexicon name of the position
 * @param {*} pointsObject the object of points RAW from the analysisResults
 */
export const getPointsForPosition = (position, pointsObject) => {
    return pointsObject !== null && Object.keys(pointsObject).length > 0
        ? Object.keys(pointsObject).map(key => {
            return pointsObject[key][position];
        }).filter(res => res !== undefined)
        : null;
}

export const getPointsForBodyPoint = (bodyPoint, pointsObject) => {
    return pointsObject !== null && Object.keys(pointsObject).length > 0
        ? Object.keys(pointsObject).map(key => {
            return pointsObject[key][bodyPoint];
        }).filter(res => res !== undefined)
        : null;
}


export const deconstructPseudo = (pseudo) => {
    let bodyPoints = {};
    let positions = {};
    let calculationPseudo = '';
    let constantValue = null;
    if (pseudo !== null) {
        const parts = pseudo.split('=');
        calculationPseudo = parts[0];
        const tailQualifier = parts[1].indexOf(':') > 0 
            ? parts[1].split(':')[0] 
            : parts[1]; // possible constant value
        if (calculationPseudo !== 'CONSTANT') {
            const pieces = tailQualifier.split('|');
            // for each piece split into position and body point
            pieces.forEach((piece, index) => {
                const p = piece.split('@');
                bodyPoints[index + 1] = getBodyPointByName(p[0]);
                positions[index + 1] = getPositionByName(p[1]);
            });

        } else {
            // we have a constant!
            constantValue = tailQualifier;
        }
    }
    return { 
        bodyPoints, 
        positions, 
        calculationType: getCalculationTypeFromPseudo(calculationPseudo),
        constantValue
    }
}

export const getPositionsFromPseudo = (pseudo) => {
    return pseudo;
}

/**
 * getBodyPointsToRenderArray
 * Return an array of the lexicon body point names for utility purposes
 * @param {object} bodyPointsToRender 
 */
export const getBodyPointsToRenderArray = (bodyPointsToRender) => {
    if (bodyPointsToRender !== null) {
        return Object.keys(bodyPointsToRender).map(k => {
            if (bodyPointsToRender[k] !== null) {
                return bodyPointsToRender[k].name;
            }
            return null;
        }).filter(res => res !== null);
    }
}

export const generatePseudo = (calculationType, bodyPoints, position, constantValue) => {
    try {
        let pseudoCalculation = '';
        if ((bodyPoints !== null || constantValue !== null) && calculationType !== null) {

            const pseudo = calculationType.pseudo.toUpperCase();
            pseudoCalculation = pseudoCalculation.concat(pseudo);
            pseudoCalculation = pseudoCalculation.concat('=');
            const points = bodyPoints;

            // make sure we have a position
            if (position !== null && calculationType.pseudo.toUpperCase() !== 'CONSTANT') {
                Object.keys(points).forEach(key => {
                    pseudoCalculation = pseudoCalculation.concat(`${points[key].name}@${position.name}|`);
                });
                // strip off the trailing pipe character
                pseudoCalculation = pseudoCalculation.substr(0, pseudoCalculation.length-1);
            }

            switch(pseudo) {
                case 'DISTANCE':
                case 'ANGLE-TWO-POINT':
                case 'ANGLE':
                    pseudoCalculation = pseudoCalculation.concat(':ABS');
                break;

                case 'CONSTANT':
                    pseudoCalculation = `${pseudo}=${constantValue}`;
                break;

                default:
                break;
            }
        }
        return pseudoCalculation;
    } catch(e) {
        return null;
    }
}

export const convertOperandToEnglish = (pseudoOperand) => {
    try {
        const english = equationOperands.map(eq => {
            return eq.pseudo === pseudoOperand ? eq.display : null;
        }).filter(res => res !== null);
        if (english !== null && english.length > 0) {
            return english[0].toLowerCase();
        }
        return false;
    } catch(e) {
        return false;
    }
}

export const getBodyPointByName = (name) => {
    let bodyPoint = null;
    bodyPoints.forEach(bp => {
        if (bp.name === name) {
            bodyPoint = bp;
        }
    });
    return bodyPoint;
}

export const getPositionByName = (name) => {
    let result = null;
    positions.forEach(p => {
        if (p.name === name) {
            result = p;
        }
    });
    return result;
}

export const getOperandByPseudo = (pseudo) => {
    let result = null;
    equationOperands.forEach(eq => {
        if (eq.pseudo === pseudo) {
            result = eq;
        }
    })
    return result;
}

export const getCalculationTypeFromPseudo = (pseudo) => {
    let result = null;
    calculationTypes.forEach(c => {
        if (c.pseudo === pseudo) {
            result = c;
        }
    });
    return result;
}

export const isNumber = (value) => {
    return parseInt(value, 0) === value;
}

export const getImageUrlForPosition = (results, position) => {
    if (results !== null) {
        if (results.frame_images !== null) {
            if (position !== null) {
                return results.frame_images[position];
            }
        }
    }
    return null;
}


export const renderCodeFormat = (code) => {
    const name =  code.substr(0,1).toUpperCase() === 'F'
        ? `Failing Condition ${code.substr(1,1)}`
        : `Passing Condition ${code.substr(1,1)}`
    return name;
}

export const getMaxTargetCondition = (type, targetInterpretationData) => {
        let maxCodeNumberForType = 0;
        if (targetInterpretationData !== undefined && targetInterpretationData !== null) {
            Object.keys(targetInterpretationData.equations).forEach(code => {
                if (type === 'pass') {
                    if (code.substr(0,1).toUpperCase() === 'P') {
                        if (code.substr(1,1) > maxCodeNumberForType) {
                            maxCodeNumberForType = parseInt(code.substr(1,1));
                        }
                    }
                } else {
                    if (code.substr(0,1).toUpperCase() === 'F') {
                        if (code.substr(1,1) > maxCodeNumberForType) {
                            maxCodeNumberForType = parseInt(code.substr(1,1));
                        }
                    }
                }
            });
        }
    const code = maxCodeNumberForType > 0
        ? type === 'pass' ? `P${maxCodeNumberForType+1}` : `F${maxCodeNumberForType+1}`
        : type === 'pass' ? `P1` : `F1`;

    return {
        code: code,
        name: code !== false ? renderCodeFormat(code) : null
    };
}

export const lessonContainsBothSwingTypes = (programs) => {
    // first let's check the length of the programs
    const swingTypes = [];
    if (programs.length > 1) {
        programs.forEach(program => {
            swingTypes.push(program.swing_type_id);
        });
        // we want to return if the program contains BOTH swing types
        return swingTypes.includes(1) && swingTypes.includes(2);
    } else {
        return false;
    }
}

export const getTargetSummaryIdFromTargetInterpretationData = (targetInterpretationData, code) => {
    if (targetInterpretationData !== null && targetInterpretationData !== undefined) {
        if ('equations' in targetInterpretationData) {
            const equation = targetInterpretationData.equations[code];
            if (equation !== undefined) {
                return equation[0].target_summary_id;
            }
        }
    }
    return null;
}
/**
 * generateLineFromPosition
 * @param {object} position 
 * @param {object} bodyPoints 
 * @returns {array} an array of lines that are auto generated.
 */
export const autoGenerateLines = (positionName, bodyPoints, type="p2p", color="#76EE00", style="solid") => {
    if (positionName && bodyPoints) {
        // check if we have 3 points!
        if (Object.keys(bodyPoints).length === 3) {
            const lines = [];
            const lineType = "p2p";
            lines.push(generateLineFromPosition(positionName, positionName, bodyPoints[1].name, bodyPoints[2].name, lineType, color, style));
            lines.push(generateLineFromPosition(positionName, positionName, bodyPoints[2].name, bodyPoints[3].name, lineType, color, style));
            return { [positionName]: lines };
        }

        if (Object.keys(bodyPoints).length === 2) {
            const lines = [];
            const lineType = "p2p";
            lines.push(generateLineFromPosition(positionName, positionName, bodyPoints[1].name, bodyPoints[2].name, lineType, color, style));
            return { [positionName]: lines };
        }

        if (Object.keys(bodyPoints).length === 1) {
            const lines = [];
            const lineType = "vertical";
            lines.push(generateLineFromPosition(positionName, "", bodyPoints[1].name, "", lineType, color, style));
            return { [positionName]: lines };
        }

        return null;
    }
}

export const autoGenerateLinesWithPositionObject = (position, bodyPoints, type="p2p", color="#76EE00", style="solid") => {
    if (position && bodyPoints) {
        // check if we have 3 points!
        if (Object.keys(bodyPoints).length === 3) {
            const lines = [];
            lines.push(generateLineFromPosition(position[1].name, position[2].name, bodyPoints[1].name, bodyPoints[2].name, type, color, style));
            lines.push(generateLineFromPosition(position[2].name, position[3].name, bodyPoints[2].name, bodyPoints[3].name, type, color, style));
            return { [position[1].name]: lines };
        }

        if (Object.keys(bodyPoints).length === 2) {
            const lines = [];
            lines.push(generateLineFromPosition(position[1].name, position[2].name, bodyPoints[1].name, bodyPoints[2].name, type, color, style));
            return { [position[1].name]: lines };
        }

        if (Object.keys(bodyPoints).length === 1) {
            const lines = [];
            lines.push(generateLineFromPosition(position[1].name, "", bodyPoints[1].name, "", "vertical", color, style));
            return { [position[1].name]: lines };
        }

        return null;
    }
}

export const generateLineFromPosition = (position1, position2, bodyPoints1, bodyPoints2, type="p2p", color="#76EE00", style="solid") => {

    const line = {
        color: color,
        p1_lexicon: bodyPoints1,
        p2_lexicon: bodyPoints2,
        phase: position1,
        phase_origin: position1,
        phase_origin_2: position1,
        style: style,
        time: "00:00:00",
        type: type,
    };
    return line;
}

/**
 * getPositionsFromPositions
 * We want to return the positions IF we have them in a simple array
 * using the display name as the value
 * 
 * @param {object} position1 
 * @param {object} position2 
 */
export const getPositionsFromPositions = (position1, position2) => {
    try {
        const positionNames = [];
        if (position1 !== null) {
            if ('1' in position1) {
                const pos = getPositionByName(position1[1].name);
                positionNames.push(pos);
            }
            if ('2' in position1) {
                const pos = getPositionByName(position1[2].name);
                positionNames.push(pos);
            }
        }
        if (position2 !== null && Object.keys(position2).length > 0) {
            if ('1' in position2) {
                const pos = getPositionByName(position2[1].name);
                positionNames.push(pos);
            }
            if ('2' in position2) {
                const pos = getPositionByName(position2[2].name);
                positionNames.push(pos);
            }
        }

        // now just remove the duplicates
        return [...new Set(positionNames)];
    } catch(e) {
        return [];
    }
}

export const getPositionsFromLines = (lines) => {
    try {
        const positionNames = [];
        Object.keys(lines).forEach(lineKey => {
            positionNames.push(getPositionObjectFromName(lineKey));
        });

        // now just remove the duplicates
        return [...new Set(positionNames)];
    } catch(e) {
        return [];
    }
}
