import React from 'react';
import { hyphenate } from '../../../util/strings';
class DocumentationSection extends React.Component {
    render() {
        const { title, children } = this.props;
        return (
            <div id={hyphenate(title)}>
                <h2 className="font-bold">{title}</h2>
                <div className="">   
                    {children}
                </div>
            </div>
        )
    }
}

DocumentationSection.defaultProps = {
    title: 'Section Title',
}

export default DocumentationSection;