import { swingbotApi } from '../../util/api';

export const getTargetInterpretations = () => {
    return swingbotApi('target-interpretations', {}, 'get', true, 'licensee')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const getTargetInterpretationsForProgram = (programId) => {
    return swingbotApi(`program/${programId}/target-interpretations`, { }, 'get', true, 'licensee')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const getTargetInterpretationsForTarget = (targetId) => {
    return swingbotApi(`target/${targetId}/target-interpretations`, { }, 'get', true, 'licensee-2')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const createTargetInterpretationData = (data) => {
    return swingbotApi(`target-interpretation`, { ...data }, 'put', true, 'licensee-2')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const removeTargetInterpretationFromTarget = (userId, targetInterpretationId) => {
    return swingbotApi(`target-interpretation`, { userId, targetInterpretationId }, 'delete', true, 'licensee-2')
        .then(response => response)
        .catch(err => Promise.reject(err));
};