import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { registerLicensee, resetRegisterLicensee } from '../../actions/auth';
import { getLicenseeDataByEmail } from '../../actions/licensee';
import ConfirmCreatePassword from './ConfirmCreatePassword';
import ConfirmEmailComplete from './ConfirmEmailComplete';
import ConfirmCreateLicenseeAccount from './ConfirmCreateLicenseeAccount';
import queryString from 'query-string';
import Loading from '../common/Loading';

import { selectors as licenseeSelectors } from '../../reducers/licensee';
class ConfirmEmailAndRegister extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            terms: null,
            error: null,
        };
    }

    componentDidMount = () => {
        const queryParams = queryString.parse(this.props.location.search);
        console.log(queryParams);
        // set the email here in the state
        this.setState({ email: queryParams.email, nonce: queryParams.nonce });
        this.props.getLicenseeDataByEmail(queryParams.email);
        // lets reset redux...
        this.props.resetRegisterLicensee();
    }

    handleOnChangeEmail = (e) => {
        this.props.resetRegisterLicensee();
        this.setState({
            email: e.target.value
        });
    }

    handleOnChangePassword = (e) => {
        this.setState({
            password: e.target.value
        });
    }

    handleTerms = (e) => {
      this.setState({
        terms: e.target.checked
      });
    }

    handleRegister = (e) => {
        e.preventDefault();
        const { email, password, terms } = this.state;
        if (email !== '' && password !== '' && terms !== null) {
            this.props.registerLicensee(email, password, this.props.history);
        } else {
            this.setState({
              error: true
            });
        }
    }

    handleGoogleLogin = (e) => {
        e.preventDefault();
    }

    renderLoadingComplete = () => {
      const {
        licenseeDataByEmail,
        loadingLicenseeDataByEmail,
      } = this.props;
      console.log(licenseeDataByEmail);
      if (licenseeDataByEmail !== null) {
        // if user is NOT yet a registered user...we have to ask for a password...
        if (loadingLicenseeDataByEmail !== true && licenseeDataByEmail.isUser === false) {
          return <ConfirmCreatePassword />
        };
        
        // if a USER but NOT a licensee yet...
        // prompt the user to continue to create their free account...
        // pass along the email but dont need the password...
        if (loadingLicenseeDataByEmail !== true && licenseeDataByEmail.isUser === true && licenseeDataByEmail.isLicensee === false) {
          return <ConfirmCreateLicenseeAccount isUser={true} isLicensee={false} />
        };

        return <ConfirmEmailComplete />;
      }
      return null;
    }

    render () {
        const {
          loadingLicenseeDataByEmail,
          // loadingLicenseeDataByEmailError
        } = this.props;
        
        return loadingLicenseeDataByEmail === true ? <Loading /> : this.renderLoadingComplete();
    }
}

ConfirmEmailAndRegister.defaultProps = {
    onClick() {}
}

const mapStateToProps = ({ auth, licensee }) => {
    const {
        authUser,
        loadingRegisterUser,
        registerUserError,
    } = auth;
    const {
      // licenseeDataByEmail,
      loadingLicenseeDataByEmail,
      loadingLicenseeDataByEmailError
    } = licensee;
    return {
        authUser,
        loadingRegisterUser,
        registerUserError,
        licenseeDataByEmail: licenseeSelectors.licenseeDataByEmail(licensee),
        loadingLicenseeDataByEmail,
        loadingLicenseeDataByEmailError
    }
}

const mapDispatchToProps = {
    registerLicensee,
    resetRegisterLicensee,
    getLicenseeDataByEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfirmEmailAndRegister));