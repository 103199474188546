import React from 'react';
import DocumentationPageHalf from './common/DocumentationPageHalf';
import DocumentationSection from './common/DocumentationSection';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import DocumentationTitle from './common/DocumentationTitle';
import DocumentationTOC from './common/DocumentationTOC';
import DocumentationFooter from './common/DocumentationFooter';
import ReactPlayer from 'react-player';
import { hyphenate } from '../../util/strings';
import "./Player.css";

export const GettingStartedSection = (props) => {
    return (
        <DocumentationPageHalf>
        <DocumentationSection title="Getting Started">
            <DocumentationTOC items={[
                { title: 'What is Swingbot Pro?' },
                { title: 'How It Works' },
            ]}/>
            <DocumentationTitle title="What is Swingbot Pro?" />
            <p>Swingbot Pro is a service that allows golf instructors to provide automated golf swing analysis to their students.</p>
            <div className="player-wrapper">
                <ReactPlayer 
                    url="https://youtu.be/14E0H8W_068" 
                    controls 
                    className="react-player" 
                    width="100%"
                    height="100%"
                />
            </div>
            <DocumentationTitle title="How It Works" />
            <p>SwingbotPro is a service. This means that we provide the technology to process and analyze a video on your behalf for your students. You do not have to be a developer, or an expert in Artificial Intelligence to use this service.</p>
            <ul className="list-inside">
                <li><a href="https://www.swingbotpro.com/#get-started" target="blank" alt="Apply to Swingbot Pro">Apply to become a member of Swingbot Pro</a>. At this time we are accepting applications from golf instructors around the world.</li>
                <li>Choose a Plan that is right for you. There are multiple plans available, each giving you more control and deeper analysis results if desired.</li>
                <li>Upload a video via the SwingbotPro Admin Dashboard, or the SwingbotPro API, and specify the Lesson Program that you would like to use to analyze the video, and the user’s email address that is the “owner” of the video (student).</li>
                <li>The results of the analysis will be sent to you using a POST request to your server, and also to the email you used to register your account.</li>
            </ul>
            <div className="player-wrapper">
                <ReactPlayer 
                    url="https://youtu.be/vsNo9RmN6kI" 
                    controls 
                    className="react-player" 
                    width="100%"
                    height="100%"
                />
            </div>
            <DocumentationFooter items={[
                { title: 'Getting started with the SDK', link: '/doc/sdk'}
            ]}/>
        </DocumentationSection>
        </DocumentationPageHalf>
    );
}

export const UserSection = (props) => {
    return (
        <DocumentationPageHalf>
        <DocumentationSection title="Golfers">
            <DocumentationTOC items={[
                { title: 'What is a Golfer?' },
                { title: 'Assigning Lesson Credits'},
            ]}/>

            <DocumentationTitle title="What is a Golfer?" />
            <p>Golfers, in the SwingbotPro dashboard, are the users that are associated with the videos that you upload to SwingbotPro.</p>
            <p>For example, if you associate john@swingbotpro.com with a video upload of John, then you will see john@swingbotpro.com appear in the Golfers section.</p>

            <DocumentationTitle title="Assigning Lesson Credits" />
            <p>You may assign Lesson Credits to a golfer by clicking the "Invite User" button in their user profile page or from the Lesson Detail page.</p>
            <p>If the golfer does not have an account, you may invite a user based on their email address and we will send them an invitation email with a link inside. This link will take them to the credit page on your SwingbotPro website. The golfer will be prompted to create an account, and then accept the lesson credit.</p>

        </DocumentationSection>
        </DocumentationPageHalf>
    );
}

export const WebsiteSection = (props) => {
    return (
        <DocumentationPageHalf>
        <DocumentationSection title="Your SwingbotPro Website">
            <DocumentationTOC items={[
                { title: 'What is a SwingbotPro website?' },
                { title: 'Customzing your website' },
                { title: 'Accepting Payments with Stripe' },
            ]}/>

            <DocumentationTitle title="What is a SwingbotPro website?" />
            <p>SwingbotPro provides you with your very own website that you can customize, add payment collection with Stripe, and allow your students to sign up, sign in, and submit their swing videos for analysis.</p>
            <p>The website is created for you automatically, and requires no devleoper assistance. This option is perfect to get up and running quickly.</p>
            <p>There are a few things to setup in order to get started.</p>


            <DocumentationTitle title="Customizing your website" />
            <p>SwingbotPro provides you with your very own website that you can customize, add payment collection with Stripe, and allow your students to sign up, sign in, and submit their swing videos for analysis.</p>
            <p>There are a few things to setup in order to get started.</p>
            <ul className="text-sm">
                <li>Title - the display title for your website, used in the website's footer and other elements such as the cart pages.</li>
                <li>Theme Color - choose a theme color that will be used throughout your website.</li>
                <li>Logo URL - the url to the image you would like to use for your branding.</li>
                <li>Profile Image URL - the url to an image of your profile photo. This is used on the information/about page of your site.</li>
                <li>Twitter URL - your twitter handle</li>
                <li>LinkedIn URL - optional - the url to your LinkedIn page</li>
                <li>Display Name - Your name that you wish to display as the instructor.</li>
                <li>Job title - If you have a different title, i.e. "Director of Golf" you may enter it here.</li>
                <li>Profile Bio - A brief description about yourself and your golf instruction practices.</li>
            </ul>

            <DocumentationTitle title="Accepting payments with Stripe" />
            <p>SwingbotPro integrates with <a href="https://www.stripe.com" target="blank">Stripe payments</a> so you can begin to collect payments from your students/users. You do need a Stripe account, and we will walk you through the integration of your account on the <a href="/website">Website Configuration page</a>.</p>
            <p>Pricing of your Lesson Programs should be at least $3.00 USD. This is due to the platform fee that is assessed by SwingbotPro and Stripe. The SwingbotPro platform fee is $2.99 per Lesson Program purchased through the website.</p>
            <p>If you choose to use the API, you may charge any price you desire as we will not be associated with assisting with the collection of payments.</p>


        </DocumentationSection>
        </DocumentationPageHalf>
    );
}

export const LessonSection = (props) =>  {
    return (
        <DocumentationPageHalf>
        <DocumentationSection title="Lesson Programs">
        <DocumentationTOC items={[
            { title: 'What is a Lesson Program?' },
            { title: 'Create a Lesson Program' },
            { title: 'Receiving Analysis Results' },
        ]}/>
            <DocumentationTitle title="What is a Lesson Program?" />
            <p>A <b>Lesson Program</b> is a series of <a href="/doc/lesson-target">Lesson Targets</a> that will be analyzed to determine the issues in a golf swing.</p>
            <p>Lesson Programs can contain any number of <a href="/doc/lesson-target">Lesson Targets</a>.</p>
            <p>An example of a Lesson Program might be "Swing Fundamentals" and it may analyze things like "Stance at Address", "Weight Shift", etc.</p>

            <DocumentationTitle title="Create a Lesson Program" />
            <p>To create a Lesson Program, you need to first provide a Name and a brief Description. The Description is typically what is being analyzed.</p>
            <p>In the above example, the description may be "An analysis of the swing fundamentals including Stance at Address, Weight Shift and more."</p>
            <div>
                <ReactPlayer url="https://youtu.be/1xQzrPIaGK0" controls />
            </div>

            <DocumentationTitle title="Receiving Analysis Results" />
            <p>You can setup a Webhook to receive the analysis results from Swingbot Pro.</p>
            <p>Simply choose a url for your webhook, and we will POST the results to the endpoint that you specify.</p>
            <div className="player-wrapper">
                <ReactPlayer 
                    url="https://youtu.be/ZxfPqHYOHkw" 
                    controls 
                    className="react-player" 
                    width="100%"
                    height="100%"
                />
            </div>
        </DocumentationSection>
        </DocumentationPageHalf>
    );
}

export const LessonCreateSection = (props) =>  {
    return (
        <DocumentationPageHalf>
            <DocumentationSection title="Create a Lesson Program">
                <DocumentationTitle title="Create a Lesson Program" />
                <p>To create a Lesson Program, you need to first provide a Name and a brief Description. The Description is typically what is being analyzed.</p>
                <p>In the above example, the description may be "An analysis of the swing fundamentals including Stance at Address, Weight Shift and more."</p>
                <div className="player-wrapper">
                    <ReactPlayer 
                        url="https://youtu.be/1xQzrPIaGK0" 
                        controls 
                        className="react-player" 
                        width="100%"
                        height="100%"
                    />
                </div>
            </DocumentationSection>
        </DocumentationPageHalf>
    );
}

export const LessonTargetSection = (props) => {
    return (
        <DocumentationPageHalf>
        <DocumentationSection title="Lesson Targets">
            <DocumentationTitle title="What is a Lesson Target?" />
            <p>A Lesson Target is anything that will be analyzed in the Lesson Program. Things like "Shoulder Turn" and "Weight Shift" might be Lesson Targets.</p>
            <DocumentationTitle title="Create a Lesson Target from Library" />
            <p>To create a new lesson target from the library of targets:</p>
            <ul className="list-inside">
                <li>Click "New Lesson Target"</li>
                <li>Choose "My Targets" to select Lesson Targets from ALL of your pre-existing Lesson Programs</li>
                <li>Choose "Swingbot Targets" to select Lesson Targets from the Swingbot Lesson Program</li>
            </ul>
            <div className="player-wrapper">
                <ReactPlayer 
                    url="https://youtu.be/i5HakdpTNw4" 
                    controls 
                    className="react-player" 
                    width="100%"
                    height="100%"
                />
            </div>
            <DocumentationTitle title="Create a Custom Lesson Target" />
            <p>You can create Custom Lesson Targets from scratch with Swingbot Pro is you choose. This will provide a blank canvas to begin creating conditions for your Lesson Target.</p>
            <div className="player-wrapper">
                <ReactPlayer 
                    url="https://youtu.be/J1Wr3XPoQ5U" 
                    controls 
                    className="react-player" 
                    width="100%"
                    height="100%"
                />
            </div>
        </DocumentationSection>
        </DocumentationPageHalf>
    );
}

export const LessonCreditSection = (props) =>  {
    return (
        <DocumentationPageHalf>
            <DocumentationSection title="Lesson Credits">

                <DocumentationTOC 
                    items={[
                        { title: 'What is a Lesson Credit?' },
                        { title: 'Assigning Lesson Credits with the API'},
                        { title: 'Assign Lesson Credits from the Dashboard' },
                        { title: 'Assigning Lesson Credits from SwingbotPro Website'},
                    ]}
                />
                <DocumentationTitle title="What is a Lesson Credit?" />
                <p>Credits may be exchanged by a golfer for a swing analysis using the Lesson Program from which the credit was issued.</p>
                <p>For example, if you have a Lesson Program called "Advanced Lesson #1", you can issue a credit for this Lesson Program to a golfer.</p>
                <p>The golfer can then use their credit by uploading their golf swing video in exchange for a swing analysis for this Lesson Program.</p>

                <DocumentationTitle title="Assigning Lesson Credits with the API" />
                <p>You may assign a credit via the Swingbot Pro SDK method below:</p>
                
                <SyntaxHighlighter showLineNumbers wrapLines={true} language="html" style={github} className="text-sm">{`SwingbotProSDK.assignCredit(userId, lessonProgramId)`}</SyntaxHighlighter>

                <p>You may also utilize your <a href="/website">SwingbotPro website</a> where the credit issuance is built into the interface. The golfer can select the Lesson Program they wish to use and then either purchase or "get" a credit (if no cost).</p>
                <p>You may assign a cost for your Lesson Programs, which will be the cost of the Lesson Credit. This cost will be accounted for automatically on your SwingbotPro website.</p>
                <p>If you are using the SwingbotPro SDK, you will have to handle the management and collection of payment for the Lesson credits.</p>

                <DocumentationTitle title="Assign Lesson Credits from the Dashboard" />
                <p>To assign a Lesson Credit from the dashboard you can either do this from the User Profile page, or from the Lesson Detail page.</p>
                <p>The Lesson Program must be active in order to assign the Lesson Credits from either source.</p>
                <p>If the User does not have an account they will be prompted to create an account from a link in an invitation email sent out on your behalf. The link will take the user to your SwingbotPro website where they will be prompted to register/login and then accept the Lesson Credit.</p>
                
                <DocumentationTitle title="Assigning Lesson Credits from SwingbotPro Website" />
                <p>The golfer may also get a Lesson Credit from your SwingbotPro website.</p>
                <p>From the website, the user will either "assign" a credit for free or based on the purchase price you have assigned to the Lesson Program.</p>
                <p>The payment collection will be handled by Stripe and funds will automatically be allocated to the <a href="/doc/website#accepting-payments-with-stripe">Stripe account you have integrated</a> in the dashboard.</p>

            </DocumentationSection>
        </DocumentationPageHalf>
    );
}


export const SDKSection = (props) => {
    return (
        <DocumentationPageHalf>
        <DocumentationSection title="Swingbot Pro SDK">

        <DocumentationTOC items={[
            { title: 'Quickstart' },
            { title: 'Step 1: Install the SDK', link: '#installation'},
            { title: 'Step 2: Initialize the Swingbot SDK' },
        ]}/>

            <DocumentationTitle title="Quickstart" />
            <p>The Swingbot Pro SDK is a Software Development Kit that makes it simple to add the capabilities of Swingbot Pro into your website.</p>
            <p>There are two ways to add the SDK into your website, using the script tag, or adding the library via node.</p>

            <DocumentationTitle title="Installation" />
            <p>You can install the SDK via Node or the Javascript script tag.</p>

            <DocumentationTitle title="Node" />

            <p>You may choose to install the <a href="https://www.npmjs.com/package/swingbot-pro-sdk" target="blank">Swingbot Pro SDK</a> via node (yarn or npm)</p>
            <SyntaxHighlighter showLineNumbers wrapLines={true} language="javascript" style={github} className="text-sm">npm install swingbot-pro-sdk</SyntaxHighlighter>
            <p>or</p>
            <SyntaxHighlighter showLineNumbers wrapLines={true} language="javascript" style={github} className="text-sm">yarn add swingbot-pro-sdk</SyntaxHighlighter>
            <p>You can check out more information about the Swingbot Pro SDK by <a href="https://www.npmjs.com/package/swingbot-pro-sdk" target="blank" className="text-green-500" alt="Swingbot Pro SDK">clicking here</a>.</p>
            <SyntaxHighlighter showLineNumbers wrapLines={true} language="javascript" style={github} className="text-sm">
{`import SwingbotProSDK from 'swingbot-pro-sdk;    
const client = SwingbotProSDK.init(<YOUR_API_KEY>);`
}                            
            </SyntaxHighlighter>

            <DocumentationTitle title="Using a CDN" />
            <p>If you require a CDN to install the SDK you can do so by including the sdk from the UNPKG CDN.</p>
            <SyntaxHighlighter showLineNumbers wrapLines={true} language="html" style={github} className="text-sm">{`<script src="https://unpkg.com/swingbot-pro-sdk@1.x.x/dist/swingbot-pro-sdk.min.js"></script>`}</SyntaxHighlighter>

            <p>Here is an example of how to use the url in the script tag of your website.</p>
            <SyntaxHighlighter showLineNumbers wrapLines={true} language="html" style={github} className="text-sm">
{`<html>
<body>
<!-- your body content here -->
</body>
<script src="https://unpkg.com/swingbot-pro-sdk@1.1.4/dist/swingbot-pro-sdk.min.js" />
<script type="text/javascript">
    const client = SwingbotProSDK.init(<YOUR_API_KEY>);
</script>
</html>`
}                            
            </SyntaxHighlighter>
        </DocumentationSection>

        <DocumentationTitle title="Initialize the SDK Client" />
        <p>After you have installed the SDK, you can now Initialize the client. From here, you can access all of the methods in the SDK.</p>

        <SyntaxHighlighter showLineNumbers wrapLines={true} language="html" style={github} className="text-sm">{`const client = SwingbotProSDK.init(<YOUR_API_KEY>);`}</SyntaxHighlighter>

        <DocumentationTitle title="Methods" />

        <p>There are several methods in the SDK, all designed to allow you to integrate everything from login to uploading a video.</p>
        <p>Here is a list of the methods available. All of the methods described will return a Promise.</p>
        <DocumentationTOC 
            intro="Here are the methods available:"
            items={[
                { title: 'init' },
                { title: 'login' },
                { title: 'register' },
                { title: 'uploadVideo' },
                { title: 'getVideosByUserId' },
                { title: 'getAnalysisById' },
            ]}
        />

        <DocumentationTitle title="init" />
        <p>Initialize the SDK client.</p>
        <SyntaxHighlighter showLineNumbers wrapLines={true} language="html" style={github} className="text-sm">{`const client = SwingbotProSDK.init(<YOUR_API_KEY>);`}</SyntaxHighlighter>

        <DocumentationTitle title="login" />
        <p>Your users may login as to SwingbotPro from your website in order to access their videos and analysis.</p>
        <SyntaxHighlighter showLineNumbers wrapLines={true} language="html" style={github} className="text-sm">{`SwingbotProSDK.login(email, password)`}</SyntaxHighlighter>

        <DocumentationTitle title="register" />
        <p>Your users may register to your SwingbotPro account. You will see the user in your SwingbotPro dashboard upon registration.</p>
        <SyntaxHighlighter showLineNumbers wrapLines={true} language="html" style={github} className="text-sm">{`SwingbotProSDK.register(email, password)`}</SyntaxHighlighter>

        <DocumentationTitle title="uploadVideo" />
        <p>Upload a video to Swingbot Pro for processing. Notice that you must pass a lesson program id in the method to specify which program to use for the analysis.</p>
        <SyntaxHighlighter showLineNumbers wrapLines={true} language="html" style={github} className="text-sm">{`SwingbotProSDK.uploadVideo(file, email, lessonProgramId)`}</SyntaxHighlighter>

        <DocumentationTitle title="getVideosByUserId" />
        <p>Get the videos based on the user id specified.</p>
        <p>The id of the user can be found in the <a href="/users">Golfers</a> dashboard, and also in the result set from the login method.</p>
        <SyntaxHighlighter showLineNumbers wrapLines={true} language="html" style={github} className="text-sm">{`SwingbotProSDK.getVideosByUserId(userId)`}</SyntaxHighlighter>

        <DocumentationTitle title="getAnalysisById" />
        <p>Get the analysis result data using the id from the Swings dashboard.</p>
        <p>The id can be found in the results from the getVideosByUserId method.</p>
        <SyntaxHighlighter showLineNumbers wrapLines={true} language="html" style={github} className="text-sm">{`SwingbotProSDK.getAnalysisById(id)`}</SyntaxHighlighter>

        <DocumentationFooter intro="Getting Started with the SDK" items={[
            { 
                title: 'Uploading a Video for Analysis', 
                link: '/doc/sdk/upload-video'
            },
        ]}/>
        </DocumentationPageHalf>
    );
};


export const SDKUploadVideoSection = (props) => {
    return (
        <DocumentationPageHalf>
        <DocumentationSection title="Uploading a Video">

            <DocumentationTOC items={[
                { title: 'Step 1: Installing the SDK', link: '/doc/sdk/#installation' },
                { title: 'Step 2: HTML to Upload a File', link: '#html-file-upload' },
                { title: 'Step 3: Upload Using the SDK', link: '#upload-sdk' },
                { title: 'Sample Code', link: '#sample-code' }
            ]}/>

            <DocumentationTitle title="Step 1: Installing the SDK" />
            <p>If you have not yet installed the Swingbot Pro SDK, you can follow the instructions in the <a href="/doc/sdk#installation" target="blank">documentation here</a>.</p>

            <DocumentationTitle title="Step 2: Choose File to Upload" anchor="html-file-upload" />
            <p>We have to select a file to upload using our webpage. To do so, you can allow the user to choose a file from their computer.</p>
            <p>Keep in mind we ONLY need a video that contains a SINGLE swing. No practice swings, etc. The video should be less than 12 seconds long, and there is no need to record with slow motion or at any mega high resolution.</p>

            <DocumentationTitle title="Step 3: Upload the File using the SDK" anchor="upload-sdk" />
            <p>Now that the golfer has selected the file they wish to upload to Swingbot Pro, we can use the file they selected and pass this to the uploadVideo method of the Swingbot Pro SDK.</p>
            <div className="player-wrapper">
                <ReactPlayer 
                    url="https://youtu.be/qgj4pzsKayU" 
                    controls 
                    className="react-player" 
                    width="100%"
                    height="100%"
                />
            </div>

            <p id="sample-code">Here is the sample code that was used in the video.</p>
            <SyntaxHighlighter showLineNumbers wrapLines={true} language="html" style={github} className="text-sm">
            {`<!DOCTYPE html>
<html>
<head>
    <meta charset="UTF-8">
    <title>Upload a File</title>
</head>
<body>
    <form id="uploadForm">
        <input id="uploadInput" type="file" name="myFiles" multiple />
        <input type="button" value="Send file" onclick="uploadFile()"/>
    </form>

    <script src="https://unpkg.com/swingbot-pro-sdk@1.1.4/dist/swingbot-pro-sdk.min.js"></script>

    <script>
      /**
       * upload a video using the Swingbot Pro SDK
       */
      function uploadFile() {
        const apiKey = '<YOUR-API-KEY-HERE>';
        const lessonProgramId = '<YOUR-LESSON-PROGRAM-ID-HERE';
        const email = '<GOLFERS-EMAIL-ADDRESS-HERE>';

        // initialize the client SDK
        const client = SwingbotProSDK.init(apiKey, 'secret');

        // Get the files from the form input
        const file = document.getElementById('uploadInput').files[0];

        // call the uploadVideo method
        client.uploadVideo(file, email, lessonProgramId)
          .then(result => {
            // do something with the results
            alert('uploaded!');
          }).catch(e => {
            alert('failed to upload');
          });
      }
    </script>
</body>
</html>`}
</SyntaxHighlighter>


        </DocumentationSection>


        <DocumentationFooter intro="Getting Started with the SDK" items={[
            { 
                title: 'Displaying Uploaded Videos to Your Website', 
                link: '/doc/sdk/display-videos'
            },
        ]}/>

        </DocumentationPageHalf>
    );
};

export const SDKGetVideosSection = (props) => {
    return (
        <DocumentationPageHalf>
        <DocumentationSection title="Display a Golfer's Videos">

            <DocumentationTOC items={[
                { title: 'Step 1: Installing the SDK', link: '/doc/sdk/#installation' },
                { title: 'Step 2: Get the List of Videos with the SDK', link: '#get-video-list' },
                { title: 'Step 3: Display the Videos Sample', link: '#display-video-list' },
                { title: 'Sample Code', link: '#sample-code' }
            ]}/>

            <DocumentationTitle title="Step 1: Installing the SDK" />
            <p>If you have not yet installed the Swingbot Pro SDK, you can follow the instructions in the <a href="/doc/sdk#installation" target="blank">documentation here</a>.</p>

            <DocumentationTitle title="Step 2: Get the List of Videos with the SDK" anchor="get-video-list" />
            <p>If you would like to get a list of videos for a user, you can use the <strong>getVideosByUserId</strong> method in the Swingbot Pro SDK.</p>
            <p>Keep in mind this method will require the user's id, which can be found in the Swingbot Pro Dashboard, or if you have utilized the <strong>login</strong> method of the SDK.</p>
            <p>All of the methods in the SDK return a Promise, so you can handle the results and display the videos when the Promise resolves.</p>

            <SyntaxHighlighter showLineNumbers wrapLines={true} language="javascript" style={github} className="text-sm">
            {`const client = SwingbotSDK.init(<YOUR-API-KEY-HERE>);
client.getVideosByUserId(<USER-ID-HERE>)
    .then(results => {
        // do something with the results
    })
    .catch(e => {
        // do something with the error
    });`}
            </SyntaxHighlighter>
            <DocumentationTitle title="Step 3: Display the Videos Sample" anchor="display-video-list" />
            <p>To display the videos we can simply iterate over the results that we have received from the getVideosByUserId method in the SDK.</p>
            <p>Here is a sample video showing you how to go about displaying the results. Sample code below.</p>
            <ReactPlayer url="https://youtu.be/N-BDCCYLmPE" controls />

            <p id="sample-code">Here is the sample code that was used in the video.</p>
            <SyntaxHighlighter showLineNumbers wrapLines={true} language="html" style={github} className="text-sm">
            {`<!DOCTYPE html>
<html>
<head>
    <meta charset="UTF-8">
    <title>Get the Video List</title>
    <style>
      .flex-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
      .flex-container > div {
        background-color: #f1f1f1;
        margin: 10px;
        padding: 20px;
        font-size: 16px;
        text-align: center;
        font-family: 'Courier New', Courier, monospace;
      }
      </style>
</head>
<body>
    <div id="videos" class="flex-container"></div>
</body>
<script src="https://unpkg.com/swingbot-pro-sdk@1.1.4/dist/swingbot-pro-sdk.min.js"></script>
<script>
  const apiKey = '<YOUR-API-KEY-HERE>';
  const userId = '<GOLFER-ID-HERE>';

  // initialize the client SDK
  const client = SwingbotProSDK.init(apiKey, 'secret');

  // call the uploadVideo method
  client.getVideosByUserId(userId)
    .then(results => {
      if (results) {

        const videos = results.data.data;
        const videoDiv = document.getElementById('videos');

        // loop through the videos
        videos.records.forEach(video => {

          var newDiv = document.createElement('div');
          newDiv.id = video.video_id;

          var newImg = document.createElement('img');
          newImg.src = video.zen_thumb;
          newImg.width = 200;
          newDiv.appendChild(newImg);

          var titleDiv = document.createElement('p');
          titleDiv.innerHTML = video.credit_code;
          newDiv.appendChild(titleDiv);

          videoDiv.appendChild(newDiv);
        });
      } else {
        alert('no videos yet!');
      }
    }).catch(e => {
      alert('failed to get the video list');
    });
</script>
</html>`}
</SyntaxHighlighter>

        </DocumentationSection>

        <DocumentationFooter items={[
            { 
                title: 'Displaying Analysis Results on your Website', 
                link: '/doc/sdk/display-analysis-results'
            },
        ]}/>

        </DocumentationPageHalf>
    );
};

export const SDKGetAnalysisResultsSection = (props) => {
    return (
        <DocumentationPageHalf>
        <DocumentationSection title="Display the Analysis Results">

            <DocumentationTOC items={[
                { title: 'Step 1: Installing the SDK', link: '/doc/sdk/#installation' },
                { title: 'Step 2: Get the Analysis Results with the SDK', link: '#get-analysis-results' },
                { title: 'Step 3: Display the ANalysis Results Sample', link: '#display-analysis-results' },
                { title: 'Sample Code', link: '#sample-code' }
            ]}/>

            <DocumentationTitle title="Step 1: Installing the SDK" />
            <p>If you have not yet installed the Swingbot Pro SDK, you can follow the instructions in the <a href="/doc/sdk#installation" target="blank">documentation here</a>.</p>

            <DocumentationTitle title="Step 2: Get the Analysis Results with the SDK" anchor="get-analysis-results" />
            
            <p>If you would like to get the analysis results for a particular video, you can use the <strong>getAnalysisResultsById</strong> method in the Swingbot Pro SDK.</p>

            <p>Keep in mind this method will require the video id or "credit_code", which can be found in the Swingbot Pro Dashboard for the Video you wish to use, and can also be found in the getVideosByUserId result set attribute "credit_code".</p>

            <p>All of the methods in the SDK return a Promise, so you can handle the results and display the analysis results when the Promise resolves.</p>

            <SyntaxHighlighter showLineNumbers wrapLines={true} language="javascript" style={github} className="text-sm">
            {`const client = SwingbotSDK.init(<YOUR-API-KEY-HERE>);
client.getAnalysisById(<VIDEO-ID-HERE>)
    .then(results => {
        // do something with the results
    })
    .catch(e => {
        // do something with the error
    });`}
            </SyntaxHighlighter>
            
            <DocumentationTitle title="Step 3: Display the Analysis Results Sample" anchor="display-analysis-results" />
            
            <p>To display the analysis results we can simply iterate over the results that we have received from the <strong>getAnalysisResultsById</strong> method in the SDK.</p>

            <p>Here is a sample video showing you how to go about displaying the results. Sample code below.</p>
            <div className="player-wrapper">
                <ReactPlayer 
                    url="https://youtu.be/4DXCuz8dCFA" 
                    controls 
                    className="react-player" 
                    width="100%"
                    height="100%"
                />
            </div>

            <p id="sample-code">Here is the sample code that was used in the video.</p>
            <SyntaxHighlighter showLineNumbers wrapLines={true} language="html" style={github} className="text-sm">
            {`<!DOCTYPE html>
<html>
<head>
    <meta charset="UTF-8">
    <title>Get Analysis Results</title>
    <style>
      .flex-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
      .flex-container > div {
        background-color: #f1f1f1;
        margin: 10px;
        padding: 20px;
        font-size: 16px;
        text-align: center;
        font-family: 'Courier New', Courier, monospace;
      }
      </style>
</head>
<body>
    <div id="data" class="flex-container">
      <div>
        <p id="credit_code"></p>
        <p id="tempo"></p>
        <div id="analysis"></div>
      </div>
    </div>
</body>
<script src="https://unpkg.com/swingbot-pro-sdk@1.1.4/dist/swingbot-pro-sdk.min.js"></script>
<script>
  // api key to initialize SDK
  const apiKey = '<YOUR-API-KEY-HERE>';

  // initialize the client SDK
  const client = SwingbotProSDK.init(apiKey, 'secret');

  // get the analysis id from the url query string
  const urlParams = new URLSearchParams(window.location.search);
  const analysisId = urlParams.get('id');

  // call the getAnalysisById method to fetch the results
  client.getAnalysisById(analysisId)
    .then(results => {

      if (results) {

        const analysisResults = results.data.data;
        const id = analysisResults.id;
        const finalResults = analysisResults.analysis.results;

        // ID
        document.getElementById('credit_code').innerHTML = id;

        // Analysis Results
        const analysisDiv = document.getElementById('analysis');

        finalResults.forEach(result => {

          // pass or fail?
          const pass = result.pass;
          
          const newDiv = document.createElement('div');

          // Lesson Target Name
          const nameDiv = document.createElement('p');
          nameDiv.innerHTML = result.targetName +  'pass: ' + pass;
          newDiv.appendChild(nameDiv);

          // Image
          const img = document.createElement('img');
          img.width = 400;
          img.src = result.imageWithLines;

          // Summary 
          const summaryDiv = document.createElement('p');
          summaryDiv.innerHTML = result.summary;

          newDiv.appendChild(summaryDiv);
          newDiv.appendChild(img);

          analysisDiv.appendChild(newDiv);

        });

      } else {
        alert('no videos yet!');
      }
    }).catch(e => {
      alert('failed to get the analysis results', e.message);
    });
</script>
</html>`}
</SyntaxHighlighter>


        </DocumentationSection>


        </DocumentationPageHalf>
    );
};

export const SDKUsage = (props) => {
    return (
        <DocumentationPageHalf>

        <DocumentationSection title="Installation">
            <p>You can install the SDK via Node or the Javascript script tag.</p>

            <DocumentationTitle title="Node" />
            <p>You may choose to install the <a href="https://www.npmjs.com/package/swingbot-pro-sdk" target="blank">Swingbot Pro SDK</a> via node (yarn or npm)</p>
            <SyntaxHighlighter showLineNumbers wrapLines={true} language="javascript" style={github} className="text-sm">npm install swingbot-pro-sdk</SyntaxHighlighter>
            <SyntaxHighlighter showLineNumbers wrapLines={true} language="javascript" style={github} className="text-sm">yarn add swingbot-pro-sdk</SyntaxHighlighter>
            <p>You can check out more information about the Swingbot Pro SDK by <a href="https://www.npmjs.com/package/swingbot-pro-sdk" target="blank" className="text-green-500" alt="Swingbot Pro SDK">clicking here</a>.</p>
            <SyntaxHighlighter showLineNumbers wrapLines={true} language="javascript" style={github} className="text-sm">
{`import SwingbotProSDK from 'swingbot-pro-sdk;    
const client = SwingbotProSDK.init(<YOUR_API_KEY>);`
}                            
            </SyntaxHighlighter>

            <DocumentationTitle title="Script Tag" />
            <SyntaxHighlighter showLineNumbers wrapLines={true} language="html" style={github} className="text-sm">{`https://unpkg.com/swingbot-pro-sdk@1.1.4/dist/swingbot-pro-sdk.min.js`}</SyntaxHighlighter>

            <p>Here is an example of how to use the url in the script tag of your website.</p>
            <SyntaxHighlighter showLineNumbers wrapLines={true} language="html" style={github} className="text-sm">
{`<html>
<body>
<!-- your body content here -->
</body>
<script src="https://unpkg.com/swingbot-pro-sdk@1.1.4/dist/swingbot-pro-sdk.min.js" />
<script type="text/javascript">
    const client = SwingbotProSDK.init(<YOUR_API_KEY>);
</script>
</html>`
}                            
            </SyntaxHighlighter>
        </DocumentationSection>



        <DocumentationSection title="Methods">

            <p>There are several methods in the SDK, all designed to allow you to integrate everything from login to uploading a video.</p>
            <p>Here is a list of the methods available. All of the methods described will return a Promise.</p>
            <ul>
                <li><a href={hyphenate(`#init`)}>init</a></li>
                <li><a href={hyphenate(`#login`)}>login</a></li>
                <li><a href={hyphenate(`#register`)}>register</a></li>
                <li><a href={hyphenate(`#uploadVideo`)}>uploadVideo</a></li>
                <li><a href={hyphenate(`#getVideosByUserId`)}>getVideosByUserId</a></li>
                <li><a href={hyphenate(`#getAnalysisById`)}>getAnalysisById</a></li>
                <li></li>
            </ul>

            <DocumentationTitle title="init" />
            <p>Initialize the SDK client.</p>
            <SyntaxHighlighter showLineNumbers wrapLines={true} language="html" style={github} className="text-sm">{`const client = SwingbotProSDK.init(<YOUR_API_KEY>);`}</SyntaxHighlighter>

            <DocumentationTitle title="login" />
            <p>Your users may login as to SwingbotPro from your website in order to access their videos and analysis.</p>
            <SyntaxHighlighter showLineNumbers wrapLines={true} language="html" style={github} className="text-sm">{`SwingbotProSDK.login(email, password)`}</SyntaxHighlighter>

            <DocumentationTitle title="register" />
            <p>Your users may register to your SwingbotPro account. You will see the user in your SwingbotPro dashboard upon registration.</p>
            <SyntaxHighlighter showLineNumbers wrapLines={true} language="html" style={github} className="text-sm">{`SwingbotProSDK.register(email, password)`}</SyntaxHighlighter>

            <DocumentationTitle title="uploadVideo" />
            <p>Upload a video to Swingbot Pro for processing. Notice that you must pass a lesson program id in the method to specify which program to use for the analysis.</p>
            <SyntaxHighlighter showLineNumbers wrapLines={true} language="html" style={github} className="text-sm">{`SwingbotProSDK.uploadVideo(file, email, lessonProgramId)`}</SyntaxHighlighter>

            <DocumentationTitle title="getVideosByUserId" />
            <p>Get the videos based on the user id specified.</p>
            <p>The id of the user can be found in the <a href="/users">Golfers</a> dashboard, and also in the result set from the login method.</p>
            <SyntaxHighlighter showLineNumbers wrapLines={true} language="html" style={github} className="text-sm">{`SwingbotProSDK.getVideosByUserId(userId)`}</SyntaxHighlighter>

            <DocumentationTitle title="getAnalysisById" />
            <p>Get the analysis result data using the id from the Swings dashboard.</p>
            <p>The id can be found in the results from the getVideosByUserId method.</p>
            <SyntaxHighlighter showLineNumbers wrapLines={true} language="html" style={github} className="text-sm">{`SwingbotProSDK.getAnalysisById(id)`}</SyntaxHighlighter>

        </DocumentationSection>
        </DocumentationPageHalf>
    )
};


