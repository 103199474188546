import { swingbotApi } from '../../util/api';

export const analyzeVideo = (videoId) => {
    return swingbotApi(`analyze/${videoId}`, { videoId }, 'get', true, 'licensee-2')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const convertCalculationToEnglish = (pseudo) => {
    return swingbotApi(`calculations/english`, { calculationPseudo: pseudo }, 'post', true, 'analysis')
        .then(response => response)
        .catch(err => Promise.reject(err));   
}

export const convertEquationToEnglish = (pseudo1, operand, pseudo2) => {
    return swingbotApi(
        `equations/english`, 
        { 
            calculationPseudo1: pseudo1, 
            operand: operand, 
            calculationPseudo2: pseudo2 
        }, 
        'post', 
        true, 
        'licensee-2'
        )
            .then(response => response)
            .catch(err => Promise.reject(err));   
}