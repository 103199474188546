import React from 'react';
// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { monoBlue } from 'react-syntax-highlighter/dist/esm/styles/hljs';
// import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
// import docco from 'react-syntax-highlighter/dist/esm/styles/hljs/docco';

// SyntaxHighlighter.registerLanguage('javascript', js);

class ApiSample extends React.Component {
    renderCode = () => {
        // Source code
        let textarea = document.getElementById('jsx-code');
        if (textarea) {
            return textarea.value;
        } else {
            return null;
        }
    }

    renderForTitle = () => {
        return this.props.forTitle !== ''
            ? ` for "${this.props.forTitle}"`
            : null;
    }

    render() {
        const { email, campaignId, apiKey, title } = this.props;
        
        return (
            <div className="text-sm p-2">
                {title === null && <h3 className="text-lg text-gray-600 mb-2 font-bold">Uploading Video{this.renderForTitle()}</h3>}
                {title !== null && <h3 className="text-lg text-gray-600 mb-2 font-bold">{title}</h3>}
                <p className="text-gray-500 mb-4">The <a href="https://www.npmjs.com/package/swingbot-pro-sdk" target="blank" className="text-green-500">Swingbot Pro SDK</a> makes it easy to add the ability to upload a swing video to Swingbot. <br />To integrate the <a href="https://www.npmjs.com/package/swingbot-pro-sdk" target="blank" className="text-green-500">Swingbot Pro SDK</a> into your application, here is a sample code snippet written in Javascript.</p>
                <p className="text-gray-500 mb-4"><a href="/doc/sdk" className="text-green-500">Check out the documentation</a> for more information about using the SDK.</p>
                <div className="rounded">
                    <SyntaxHighlighter showLineNumbers wrapLines={true} language="javascript" style={monoBlue}>
                        {`// import the Swingbot SDK                    
    const SwingbotProSDK = require('swingbot-pro-sdk');
    const client = SwingbotProSDK.init(${apiKey !== null ? apiKey : '<YOUR_API_KEY>'});
    // now we can upload the video
    client.uploadVideo(file, '${email}', ${campaignId})
        .then(result => {
            // do things with your results!
        })
        .catch(error => {
            // oops, the upload failed
        });`}
                    </SyntaxHighlighter>
                </div>
            </div>
        );
    }
}

ApiSample.defaultProps = {
    campaignId: 1,
    email: 'test@me.com',
    apiKey: null,
    forTitle: '',
    title: null
}

export default ApiSample;