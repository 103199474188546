import React from 'react';
import { DocumentationMenuData } from '../../../util/docs/documentation';

export const DocumentationMenu = () => {

    // const title = (
    //     <div className="flex mt-4" key={'title'}>
    //         <div className="w-full">
    //             <a href="/" className="text-xl font-bold group flex items-center px-2 py-2 leading-5 text-gray-800 rounded-md hover:text-white focus:outline-none transition ease-in-out duration-150">
    //             Swingbot Pro
    //             </a>
    //         </div>
    //     </div>
    // );

    let menuItems = DocumentationMenuData.map((docItem, index) => {
        const style = docItem.style === 'h1'
            ? 'text-base font-bold group flex items-center px-2 py-1 leading-5 text-gray-700 rounded-md hover:underline focus:outline-none transition ease-in-out duration-150'
            : 'text-base group flex items-center px-2 py-1 leading-5 text-gray-700 rounded-md hover:underline focus:outline-none transition ease-in-out duration-150';

        return docItem.style === 'h1' ? (
            <div className="flex mt-4" key={`doc-${index}`}>
                <div className="w-full">
                    <a href={`/doc${docItem.route}`} className={style}>
                    {docItem.name}
                    </a>
                </div>
            </div>
        ) : (
            <div className="flex" key={`doc-${index}`}>
                <div className="w-full ml-4">
                    <a href={`/doc${docItem.route}`} className={style}>
                    {docItem.name}
                    </a>
                </div>
            </div>
        )
    });

    
    return menuItems;
}