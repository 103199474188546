import { swingbotApi } from '../../util/api';

export const getProgramsByLicenseeId = (licenseeId) => {
    return swingbotApi(`programs/licensee/${licenseeId}`, {}, 'get', true, 'program')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const getProgramData = (programId, userId) => {
    return swingbotApi(`programs/${programId}/targets`, {}, 'get', true, 'program')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const getTargetsForProgram = (programId, userId) => {
    return swingbotApi(`programs/${programId}/targets`, {}, 'get', true, 'program')
        .then(response => response)
        .catch(err => Promise.reject(err));
};


export const getProgramsForLicenseeCampaign = (licenseeCampaignId, licenseeId) => {
    return swingbotApi(`licensee/${licenseeId}/campaigns/${licenseeCampaignId}/programs`, {}, 'get', true, 'licensee')
        .then(response => {
            return response;
        })
        .catch(err => Promise.reject(err));
};

export const createProgram = (name, description, userId, duration, swingTypeId, status) => {
    const body = { name, description, userId, duration, swingTypeId, status };
    return swingbotApi('programs', body, 'post', true, 'program')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const duplicateProgram = (programId, programName, userId) => {
    return swingbotApi(`programs/${programId}/duplicate`, { programId, programName, userId }, 'post', true, 'program')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const removeTargetFromProgram = (targetId, programId, userId) => {
    const body = { targetId, programId, userId };
    return swingbotApi(`programs/${programId}/target`, body, 'put', true, 'program')
        .then(response => response)
        .catch(err => Promise.reject(err));
};

export const removeAllTargetsFromProgram = (targetId, programId, userId) => {
    const body = { programId, userId };
    return swingbotApi(`programs/${programId}/targets`, body, 'post', true, 'program')
        .then(response => response)
        .catch(err => Promise.reject(err));
};
