import React from 'react';
import Loading from '../../common/Loading';
class AnalysisFrames extends React.Component {
    renderFrames = () => {
        try {
            const { analysisResults } = this.props;
            return (analysisResults !== null && analysisResults !== undefined && Object.keys(analysisResults).length > 0)
            ? Object.keys(analysisResults.frame_images).map((position, v) => {
                    return (
                        <div className="col-span-2 lg:col-span-1 sm:col-span-1 xs:col-span-1 m-2" key={v}>
                            <img className="rounded" key={position} src={analysisResults.frame_images[position]} alt={position} />
                            <div className="text-sm text-center mt-1 text-gray-700">{position}</div>
                        </div>
                    )
            })
            : null;
        } catch(e) {
            return null;
        }
      };

    render() {
        return this.props.loading === false ? (
            <div>
                <div className="text-xl font-bold text-gray-800">Swing Positions</div>
                <div className="text-md text-gray-700 mb-2">The following swing positions have been detected in the video.</div>
                <div className="grid lg:grid-cols-4 grid-cols-2 gap-4">
                    {this.renderFrames()}
                </div>
            </div>
        ) : <Loading title="Loading Swing Images" />;
    }
}

AnalysisFrames.defaultProps = {
    loading: false,
}

export default AnalysisFrames;