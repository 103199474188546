import * as actionTypes from './types';

export const createApiKey = (licenseeId) => ({
    type: actionTypes.CREATE_API_KEY,
    payload: licenseeId
});

export const createApiKeySuccess = (data) => ({
    type: actionTypes.CREATE_API_KEY_SUCCESS,
    payload: data
});

export const createApiKeyFail = (error) => ({
    type: actionTypes.CREATE_API_KEY_FAIL,
    payload: error
});

export const createApiKeyReset = () => ({
    type: actionTypes.CREATE_API_KEY_RESET,
    payload: null
});

export const updateApiKey = (userId, key) => ({
    type: actionTypes.UPDATE_API_KEY,
    payload: { userId, key }
});

export const updateApiKeySuccess = (data) => ({
    type: actionTypes.UPDATE_API_KEY_SUCCESS,
    payload: data
});

export const updateApiKeyFail = (error) => ({
    type: actionTypes.UPDATE_API_KEY_FAIL,
    payload: error
});