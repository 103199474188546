/**
 * Logout component
 */
import React from 'react';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/auth';
import { withRouter, Link } from 'react-router-dom';

class Logout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount = () => {
        this.props.logoutUser();
    }

    handleLogout = (e) => {
        e.preventDefault();
        this.props.logoutUser();
    }

    render() {
        return (
            <div className="min-h-screen bg-gray-800 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img className="mx-auto h-12 w-auto" src={require('../../img/logo.png')} alt="Swingbot SaaS" />
                <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-white">
                You are logged out.
                </h2>
                <p className="mt-2 text-center text-sm leading-5 text-gray-200 max-w">
                <Link to="/login" className="font-medium text-green-600 hover:text-green-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                    click to sign in
                </Link>
                </p>
            </div>
            </div>
        );
    }
}

const mapStatetoProps = ({ auth }) => {
    const { 
        authUser,
        loadingLogoutUser,
        loginUserError,
        token,
        checkingToken,
        checkingTokenError, 
    } = auth;
    return { 
        authUser,
        loadingLogoutUser,
        loginUserError,
        token,
        checkingToken,
        checkingTokenError
    };
};

const mapDispatchToProps = {
    logoutUser
}
const logoutWithRouter = withRouter(Logout);
export default connect(mapStatetoProps, mapDispatchToProps)(logoutWithRouter);