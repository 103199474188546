import React from 'react';

class Container extends React.Component {
    render() {
        const shadow = this.props.shadow === true ? 'shadow' : '';
        const height = this.props.fullHeight === true ? 'h-full' : '';
        return (
            <div className={`flex flex-col w-full ${height} bg-white overflow-hidden sm:rounded-lg mb-4 ${this.props.className} ${shadow}`}>
                <div className={`px-4 py-5 sm:px-6 ${height}`}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

Container.defaultProps = {
    children: [],
    className: '',
    shadow: true,
    fullHeight: true,
}
export default Container;