import * as actionTypes from '../actions/subscribe/types'
import * as authActionTypes from '../actions/auth/actionTypes';

  // set the initial state of the reducer
   const INITIAL_STATE = {
        clientToken: null,
        generateClientToken: null,
        generateClientTokenError: null,

        subscribeLoading: false,
        subscriptionSuccess: null,
        subscriptionError: null,

        updateSubscriptionLoading: false,
        updateSubscriptionSuccess: null,
        updateSubscriptionError: null,

        createBraintreeCustomerLoading: null,
        createBraintreeCustomerSuccess: null,
        createBraintreeCustomerError: null,

        cancelSubscriptionLoading: false,
        cancelSubscriptionSuccess: null,
        cancelSubscriptionError: null,
        
        getSubscriptionsLoading: false,
        getSubscriptionsSuccess: null,
        getSubscriptionsError: null
   };
  
   export default (state = INITIAL_STATE, action) => {
     switch (action.type) {
        
        case authActionTypes.LOGOUT_USER:
            return {
                ...state,
                clientToken: null,
                generateClientToken: null,
                generateClientTokenError: null,
        
                subscribeLoading: false,
                subscriptionSuccess: null,
                subscriptionError: null,
        
                updateSubscriptionLoading: false,
                updateSubscriptionSuccess: null,
                updateSubscriptionError: null,
        
                createBraintreeCustomer: null,
                createBraintreeCustomerSuccess: null,
                createBraintreeCustomerError: null,
        
                cancelSubscriptionLoading: false,
                cancelSubscriptionSuccess: null,
                cancelSubscriptionError: null,
                
                getSubscriptionsLoading: false,
                getSubscriptionsSuccess: null,
                getSubscriptionsError: null
            }

        case actionTypes.CREATE_BRAINTREE_CUSTOMER:
            return {
                ...state,
                createBraintreeCustomer: true,
                createBraintreeCustomerSuccess: null,
                createBraintreeCustomerError: null,
            }

        case actionTypes.CREATE_BRAINTREE_CUSTOMER_SUCCESS:
            return {
                ...state,
                createBraintreeCustomerLoading: false,
                createBraintreeCustomerSuccess: action.payload,
                createBraintreeCustomerError: null,
            }

        case actionTypes.CREATE_BRAINTREE_CUSTOMER_FAIL:
            return {
                ...state,
                createBraintreeCustomerLoading: false,
                createBraintreeCustomerSuccess: null,
                createBraintreeCustomerError: action.payload,
            }

        case actionTypes.CREATE_BRAINTREE_CUSTOMER_RESET:
            return {
                ...state,
                createBraintreeCustomerLoading: null,
                createBraintreeCustomerSuccess: null,
                createBraintreeCustomerError: null,
            }

        case actionTypes.GET_BRAINTREE_CLIENT_TOKEN:
            return {
                ...state,
                clientToken: null,
                generateClientToken: true,
                generateClientTokenError: null
            }
  
        case actionTypes.GET_BRAINTREE_CLIENT_TOKEN_SUCCESS:
            return {
                ...state,
                clientToken: action.payload.data,
                generateClientToken: false,
                generateClientTokenError: null
            }
  
        case actionTypes.GET_BRAINTREE_CLIENT_TOKEN_FAIL:
            return {
                ...state,
                clientToken: null,
                generateClientToken: null,
                generateClientTokenError: action.payload
            }

        case actionTypes.GET_BRAINTREE_CLIENT_TOKEN_RESET:
            return {
                ...state,
                clientToken: null,
                generateClientToken: null,
                generateClientTokenError: null
            }
         
        case actionTypes.SUBSCRIBE_TO_PLAN:
            return {
                ...state,
                subscriptionLoading: true,
                subscriptionSuccess: null,
                subscriptionError: null
            }
 
        case actionTypes.SUBSCRIBE_TO_PLAN_FAIL:
            return {
                ...state,
                subscriptionLoading: false,
                subscriptionSuccess: null,
                subscriptionError: action.payload
            }

        case actionTypes.SUBSCRIBE_TO_PLAN_SUCCESS:
            return {
                ...state,
                subscriptionLoading: false,
                subscriptionSuccess: action.payload,
                subscriptionError: null,
            }

        case actionTypes.SUBSCRIBE_TO_PLAN_RESET:
           return {
                ...state,
                subscriptionLoading: false,
                subscriptionSuccess: null,
                subscriptionError: null,
                // reset the creation of the customer as well
                createBraintreeCustomer: true,
                createBraintreeCustomerSuccess: null,
                createBraintreeCustomerError: null,
                // reset getting client token
                clientToken: null,
                generateClientToken: true,
                generateClientTokenError: null
           }

        case actionTypes.UPDATE_SUBSCRIPTION_PLAN:
            return {
                ...state,
                subscriptionLoading: true,
                subscriptionSuccess: null,
                subscriptionError: null
            }
 
        case actionTypes.UPDATE_SUBSCRIPTION_PLAN_FAIL:
            return {
                ...state,
                subscriptionLoading: false,
                subscriptionSuccess: null,
                subscriptionError: action.payload
            }

        case actionTypes.UPDATE_SUBSCRIPTION_PLAN_SUCCESS:
            return {
                ...state,
                subscriptionLoading: false,
                subscriptionSuccess: action.payload,
                subscriptionError: null,
                authUser: action.payload.userData
            }

        case actionTypes.UPDATE_SUBSCRIPTION_PLAN_RESET:
           return {
                ...state,
                subscriptionLoading: false,
                subscriptionSuccess: null,
                subscriptionError: null
           }

        /**
         * Cancel Subscription
         */
        case actionTypes.CANCEL_SUBSCRIPTION:
            return {
                ...state,
                cancelSubscriptionLoading: true,
                cancelSubscriptionSuccess: null,
                cancelSubscriptionError: null
            }
 
        case actionTypes.CANCEL_SUBSCRIPTION_FAIL:
            return {
                ...state,
                cancelSubscriptionLoading: false,
                cancelSubscriptionSuccess: null,
                cancelSubscriptionError: action.payload
            }

        case actionTypes.CANCEL_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                cancelSubscriptionLoading: false,
                cancelSubscriptionSuccess: action.payload,
                cancelSubscriptionError: null
            }

        case actionTypes.CANCEL_SUBSCRIPTION_RESET:
           return {
                ...state,
                cancelSubscriptionLoading: false,
                cancelSubscriptionSuccess: null,
                cancelSubscriptionError: null
           }
        /**
         * Get subscriptions (transactions)
         */
        case actionTypes.GET_SUBSCRIPTIONS:
            return {
                ...state,
                getSubscriptionsLoading: true,
                getSubscriptionsSuccess: null,
                getSubscriptionsError: null
            }

        case actionTypes.GET_SUBSCRIPTIONS_FAIL:
            return {
                ...state,
                getSubscriptionsLoading: false,
                getSubscriptionsSuccess: null,
                getSubscriptionsError: action.payload
            }

        case actionTypes.GET_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                getSubscriptionsLoading: false,
                getSubscriptionsSuccess: action.payload.data.data,
                getSubscriptionsError: null
            }

        case actionTypes.GET_SUBSCRIPTIONS_RESET:
        return {
                ...state,
                getSubscriptionsLoading: false,
                getSubscriptionsSuccess: null,
                getSubscriptionsError: null
        }
       default:
         return state;
     }
   };
  