
import * as actionTypes from '../actions/plan/types';
import * as authActionTypes from '../actions/auth/actionTypes';

const initialState = {
    plans: [],
    getPlansLoading: false,
    getPlansError: false,
};

function plan(state = initialState, action) {
    switch(action.type) {

        case authActionTypes.LOGOUT_USER:
            return {
                ...state,
                plans: [],
                getPlansLoading: false,
                getPlansError: false,
            }
            
        case actionTypes.GET_PLANS:
            return {
                ...state,
                plans: [],
                getPlansLoading: true,
                getPlansError: false,
            };

        case actionTypes.GET_PLANS_SUCCESS:
            return {
                ...state,
                plans: action.payload,
                getPlansLoading: false,
                getPlansError: false,
            };

        case actionTypes.GET_PLANS_FAIL:
            return {
                ...state,
                plans: [],
                getPlansLoading: false,
                getPlansError: action.payload,
            };

        default:
            return state;
    }
}

export default plan;